import { isEmpty } from "./Generic";

export const isGroupAdmin = (chat) => {
    if (chat?.me_member[0]?.is_admin.toString() === '1') {
        return true;
    } else {
        return false;
    }
}

export const needToAcceptChat = (chat) => {
    if (isEmpty(chat?.me_member[0]?.chat_joined_on)) {
        return true;
    } else {
        return false;
    }
}

const isGroup = (chat) => {
    if (chat?.is_group.toString() === '1') {
        return true;
    }
    return false;
}