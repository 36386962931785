import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
  AppBar,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  Snackbar,
  Tab,
  Tabs,
  TextField,
  Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Toast from "../../utils/Toast";
import { Alert, Autocomplete, createFilterOptions, TabContext, TabPanel } from "@material-ui/lab";
import { Close } from "@material-ui/icons";
import { isEmpty, isNotEmpty, printTime, validateJOIFormField, validateJOIProperty } from "../../utils/Generic";
import * as Joi from "joi-browser";
import { updateToDo, createToDo, ToDoList, markToDoCompleted, markToDoUnCompleted, deleteToDoItem } from "../../services/admin.service";
import LoaderButton from "../../utils/Loader/LoaderButton";
import FieldError from "../../components/helperComponents/FieldError";
import moment from "moment";
import ToDoBox from "../../components/todo/ToDoBox";

const useStyles = makeStyles((theme) => ({
  root: {
    width: `calc(100% - ${300}px)`,
    marginLeft: 280,
    minHeight: "88vh",
  },
  field: {
    width: "100%",
    borderRadius: 10,
    color: theme.palette.admin.main,
  },
  Select: {
    width: "100%",
    borderRadius: 10,
    color: theme.palette.admin.main,
  },
}));

const usersFilter = createFilterOptions();

const ToDo = () => {

  const classes = useStyles();

  const toastInitialValues = {
    isOpen: false,
    isSuccess: false,
    isError: false,
    message: ""
  }

  const [toast, setToast] = useState(toastInitialValues);

  const closeToast = () => {
    return setToast((prev) => ({
      ...prev,
      isOpen: false,
      isSuccess: false,
      isError: false,
      message: ""
    }));
  };

  const showError = (errorMsg) => {
    setToast((prev) => ({
      ...prev,
      isOpen: true,
      isError: true,
      isSuccess: false,
      message: errorMsg
    }));
  }

  const showSuccess = (succMsg) => {
    setToast((prev) => ({
      ...prev,
      isOpen: true,
      isError: false,
      isSuccess: true,
      message: succMsg
    }));
  }

  useEffect(() => {
    document.title = `TruDoc | To-Do List`;
  }, []);

  const [showEventFormModal, setShowEventFormModal] = useState(false);

  const [selectedEventId, setSelectedEventId] = useState(null);
  const [completedSelectedEventId, setCompletedSelectedEventId] = useState(null);

  const initialFormFields = {
    id: null,
    name: "",
    type: "",
    time: "",
    description: ""
  };

  const [formFields, setFormFields] = useState(initialFormFields);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const [toDoList, setToDoList] = useState([]);
  const [toDoLoading, setToDoLoading] = useState(false);

  const [totalPendingToDo, setTotalPendingToDo] = useState(0);
  const [totalCompletedToDo, setTotalCompletedToDo] = useState(0);

  const schema = {
    name: Joi.string().required().label("Name"),
    type: Joi.string().required().label("Type"),
    time: Joi.string().required().label("Time")
  };

  const updateEventState = (toDoItem) => {
    const toDoListData = toDoList.map((item) => {
      if (item.id === toDoItem.id) {
        return { ...item, ...toDoItem };
      }
      return item;
    });
    setToDoList(toDoListData);
  }

  const toggleEventCompleted = (event, toDoItem) => {
    event?.preventDefault();
    event?.stopPropagation();
    let request = null;
    if (isNotEmpty(toDoItem?.completed_on)) {
      request = markToDoUnCompleted({ id: toDoItem?.id });
      toDoItem.completed_on = null;
    } else {
      request = markToDoCompleted({ id: toDoItem?.id });
      toDoItem.completed_on = new Date();
    }
    updateEventState(toDoItem);
    request.then((res) => {
      let data = res?.data;
      listEvents();
    });
  }

  const handleCompletedEventBoxClick = (toDoItem) => {
    if (completedSelectedEventId === toDoItem?.id) {
      setCompletedSelectedEventId(null);
    } else {
      setCompletedSelectedEventId(toDoItem?.id);
    }
  }

  const handleEventBoxClick = (toDoItem) => {
    if (selectedEventId === toDoItem?.id) {
      setSelectedEventId(null);
    } else {
      setSelectedEventId(toDoItem?.id);
    }
  }

  const handleEventModalClose = () => {
    setShowEventFormModal(false);
    setFormFields(initialFormFields);
  }

  const updateModelFieldValue = (name, value) => {
    setFormFields({
      ...formFields,
      [name]: value
    });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    let errorData = { ...errors };
    const errorMessage = validateJOIProperty(schema, event);
    if (errorMessage) {
      errorData[name] = errorMessage;
    } else {
      delete errorData[name];
    }
    updateModelFieldValue(name, value);
    setErrors(errorData);
  };

  const submit = (event) => {
    event.preventDefault();
    setLoading(true);
    let errors = validateJOIFormField(formFields, schema);
    if (errors == null) {
      let result = null;
      if (isNotEmpty(formFields.id)) {
        result = updateToDo(formFields);
      } else {
        result = createToDo(formFields);
      }
      result
        .then((res) => {
          if (res.status.toString() === '200') {
            let dataResponse = res.data;
            if (dataResponse.success) {
              showSuccess("Event has been saved");
              setLoading(false);
              clearModelFields();
              setShowEventFormModal(false);
              listEvents();
            } else {
              showError(dataResponse.message);
              setLoading(false);
            }
          } else {
            showError("Failed to save event");
            setLoading(false);
          }
        })
        .catch((error) => {
          showError("Failed to save event");
          setLoading(false);
        });
    } else {
      setErrors(errors);
      setLoading(false);
    }
  };

  const clearModelFields = () => {
    setFormFields(initialFormFields);
  };


  const listEvents = () => {
    setToDoLoading(true);
    ToDoList({

    }).then((res) => {
      setToDoLoading(false);
      const response = res.data;
      setToDoList(response.data);
    }).catch((err) => {
      console.log(err);
      setToDoLoading(false);
    });
  }

  useEffect(() => {
    listEvents();
  }, []);

  const editToDo = (e, toDoItem) => {
    e?.preventDefault();
    e?.stopPropagation();
    let calendarTime = moment(toDoItem?.time).format("YYYY-MM-DDTHH:mm");
    // console.log("calendarTime", calendarTime);
    setFormFields({
      ...formFields, ...{
        id: toDoItem?.id,
        name: toDoItem?.name,
        type: toDoItem?.type,
        time: calendarTime,
        // time: "2017-05-24T10:30",  
        description: toDoItem?.description
      }
    });
    setShowEventFormModal(true);
  }

  const deleteToDo = (e, toDoItem) => {
    e?.preventDefault();
    e?.stopPropagation();
    if (window.confirm("Are you sure you want to Delete?")) {
      deleteToDoItem({
        id: toDoItem?.id
      }).then((res) => {
        let data = res.data;
        if (data.success) {
          showSuccess("ToDo has been deleted");
          listEvents();
        } else {
          showError(data?.message);
        }
      }).catch(err => {
        showError("Failed to Delete ToDo");
      });
    }
  }

  useEffect(() => {
    let totalPendingToDoData = 0;
    let totalCompletedToDoData = 0;
    if (toDoList.length > 0) {
      for (let toDoItem of toDoList) {
        if (isNotEmpty(toDoItem?.completed_on)) {
          totalCompletedToDoData++;
        } else {
          totalPendingToDoData++;
        }
      }
      setTotalPendingToDo(totalPendingToDoData);
      setTotalCompletedToDo(totalCompletedToDoData);
    }
  }, [toDoList]);

  return (
    <>

      {toast.isOpen && <Toast message={toast.message} isError={toast.isError} isSuccess={toast.isSuccess} closeToast={closeToast} />}

      <Box className={classes.root}>

        <Box className={'theme-card mt-15px'}>
          <Box className={'theme-card-head'}>
            <Box className={'theme-card-title'}>
              <Typography variant='h1'>To-Do List</Typography>
              <Typography variant='h6'>List of all of your To-Do</Typography>
            </Box>
            <Box className={'theme-card-actions'}>
              <Button onClick={(e) => setShowEventFormModal(true)} className="theme-btn primary btn-xl btn-round">Create New</Button>
            </Box>
          </Box>
          <Box className={'theme-card-body'}>

            <Box className={'event-list-box'}>

              {
                totalPendingToDo <= 0 ?
                  <>
                    <Box className="event-box-no-results">
                      {
                        toDoLoading ? <>
                          <CircularProgress color="primary" size={20} />
                        </> : <>
                          <Typography variant='h6'>No ToDo Found</Typography>
                        </>
                      }
                    </Box>
                  </> : null
              }

              {
                toDoList.map((todoItem) => {
                  if (isEmpty(todoItem?.completed_on)) {
                    return <>
                      <ToDoBox todoItem={todoItem} selectedEventId={selectedEventId} editToDo={editToDo} deleteToDo={deleteToDo}
                        handleEventBoxClick={handleEventBoxClick} toggleEventCompleted={toggleEventCompleted} />
                    </>
                  }
                })
              }

            </Box>

          </Box>
        </Box>

        <Box className={'theme-card mt-15px'}>
          <Box className={'theme-card-head'}>
            <Box className={'theme-card-title'}>
              <Typography variant='h1'>Completed To-Do List</Typography>
              <Typography variant='h6'>List of all of your To-Do which are marked as Completed</Typography>
            </Box>
          </Box>
          <Box className={'theme-card-body'}>

            <Box className={'event-list-box'}>

              {
                totalCompletedToDo <= 0 ?
                  <>
                    <Box className="event-box-no-results">
                      {
                        toDoLoading ? <>
                          <CircularProgress color="primary" size={20} />
                        </> : <>
                          <Typography variant='h6'>No ToDo Found</Typography>
                        </>
                      }
                    </Box>
                  </> : null
              }

              {
                toDoList.map((todoItem) => {
                  if (!isEmpty(todoItem?.completed_on)) {
                    return <>
                      <ToDoBox todoItem={todoItem} selectedEventId={completedSelectedEventId} editToDo={editToDo} deleteToDo={deleteToDo}
                        handleEventBoxClick={handleCompletedEventBoxClick} toggleEventCompleted={toggleEventCompleted} />
                    </>
                  }
                })
              }

            </Box>

          </Box>
        </Box>


      </Box>

      <Dialog
        fullWidth={true}
        maxWidth='sm'
        open={showEventFormModal}
        className={classes.dialogRoot}
        PaperProps={{
          style: { borderRadius: 20, maxHeight: 'calc(100% - 44px)' }
        }}
        onClose={handleEventModalClose}
      >

        <DialogTitle>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div>
              Create New To-Do
            </div>
            <IconButton onClick={handleEventModalClose} style={{ marginLeft: 'auto' }}>
              <Close />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent dividers className={` event-form-modal-content ` + classes.contentHeight}>

          <Grid
            container
            direction="row"
            alignItems="center"
            spacing={2}
          >

            <Grid item xs={12} align="left" className='mt-5px'>
              <TextField value={formFields?.name || ""} className={classes.field} onChange={e => { handleChange(e) }} name="name" label="Name*" variant="outlined" />
              <FieldError error={errors?.name} />
            </Grid>

            <Grid item xs={12} align="left">
              <FormControl variant="outlined" className={classes.Select}>
                <InputLabel id="select-session">Type*</InputLabel>
                <Select
                  labelId="select-session"
                  id="select-session-comp"
                  value={formFields?.type || ""}
                  label="Type*"
                  name="type"
                  onChange={e => { handleChange(e) }}
                  required
                >
                  <MenuItem value={"event"}>Event</MenuItem>
                  <MenuItem value={"meeting"}>Meeting</MenuItem>
                </Select>
              </FormControl>
              <FieldError error={errors?.type} />
            </Grid>

            <Grid item xs={12} align="left" className='mt-5px'>
              <TextField type="datetime-local" value={formFields?.time || ""} className={classes.field} onChange={e => { handleChange(e) }} name="time" label="" variant="outlined" />
              <FieldError error={errors?.time} />
            </Grid>

            <Grid item xs={12} align="left" className='mt-5px'>
              <TextField multiline={true} rows={6} value={formFields?.description || ""} className={classes.field} onChange={e => { handleChange(e) }} name="description" label="Description" variant="outlined" />
              <FieldError error={errors?.description} />
            </Grid>

          </Grid>

          <Box className='mt-15px' display="flex" alignItems="center" justifyContent="center">
            <LoaderButton
              type="submit"
              variant="contained"
              color="primary"
              className={'theme-btn primary btn-xl btn-round'}
              size="large"
              onClick={submit}
              text="Save To-Do"
              textClass={classes.buttonText}
              isLoading={loading}
            />
          </Box>

        </DialogContent>
      </Dialog>

    </>
  );
};

export default ToDo; 
