import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Slider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@material-ui/core";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { makeStyles, styled } from "@material-ui/core/styles";
import Toast from "../../utils/Toast";
import { useHistory, useParams } from "react-router-dom";
import { isNotEmpty, parseByGoals, toTitleCase } from "../../utils/Generic";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { UNSDG_GOALS } from "../../utils/unsdg-goals";
import AddIcon from '@material-ui/icons/Add';
import { Close } from "@material-ui/icons";
import { addKPIData, editKPIData } from "../../services/admin.service";
import { deleteKPI } from "../../services/admin.service";
import { Button } from "antd";
import EsgImpactTopicsModal from "./esg-impact-topics-modal";
import GaugeChart from "react-gauge-chart";
import KpiTable from "./kpi-table";



const useStyles = makeStyles((theme) => ({
  root: {
    width: `calc(100% - ${300}px)`,
    marginLeft: 280,
    minHeight: "88vh",
  },
}));

const ViewObjective = (props) => {

  const { report, loadData, goals, objectives } = props;

  const [objective, setObjective] = useState({});
  const [goalDataItems, setGoalDataItems] = useState({});
  const [newKPIVal, setNewKPIVal] = useState('');
  const [newKPIRoadmap, setNewKPIRoadmap] = useState('');
  const [newKPIStatus, setNewKPIStatus] = useState('');
  const [newKPIProgress, setNewKPIProgress] = useState(0);

  const [showNewModal, setShowNewModal] = useState(false);
  const [objectiveIndex, setObjectiveIndex] = useState(0);
  const [openTopicModal, setOpenTopicModal] = useState(false)
  const [objKeyVal, setObjKeyVal] = useState(null)
  const [isEdit, setIsEdit] = useState(false)
  const [editId, setEditId] = useState(null)
  const [priorityCount, setPriorityCount] = useState({
    'moderate': 0,
    'major': 0,
    'significant': 0,
    'negligible': 0
  })

  let { objectiveId } = useParams();

  useEffect(() => {
    if (
      isNotEmpty(report?.sustainability_goal_objectives) &&
      report?.sustainability_goal_objectives.length > 0
    ) {
      let idN = 0;
      for (let objItem of report?.sustainability_goal_objectives) {
        let objectTemp = objItem;
        if (objectTemp.id.toString() === objectiveId.toString()) {
          setObjective(objectTemp);
          setObjectiveIndex(idN);
        }
        idN++;
      }
    }
  }, [report]);

  useEffect(() => {
    if (isNotEmpty(objective?.sustainability_goal_subjects) && objective?.sustainability_goal_subjects.length > 0) {
      let goalDataItemsTemp = parseByGoals(objective?.sustainability_goal_subjects);
      setGoalDataItems(goalDataItemsTemp);
      const temp = {
        'moderate': 0,
        'major': 0,
        'significant': 0,
        'negligible': 0
      }

      objective?.sustainability_goal_subjects.map(goal => {
        temp[goal.business_impact_relevance] = temp[goal.business_impact_relevance] + 1;
        temp[goal.people_environment_impact_relevance] = temp[goal.people_environment_impact_relevance] + 1;
      })

      setPriorityCount(temp)

    } else {
      setGoalDataItems({});
    }


  }, [objective]);

  let history = useHistory();

  const marks = [
    {
      value: 11
    },
    {
      value: 22
    },
    {
      value: 33
    },
    {
      value: 44
    },
    {
      value: 55
    },
    {
      value: 66
    },
    {
      value: 77
    },
    {
      value: 88
    },
    {
      value: 100
    },
  ];

  const classes = useStyles();

  const toastInitialValues = {
    isOpen: false,
    isSuccess: false,
    isError: false,
    message: ""
  }

  const [toast, setToast] = useState(toastInitialValues);

  const closeToast = () => {
    return setToast((prev) => ({
      ...prev,
      isOpen: false,
      isSuccess: false,
      isError: false,
      message: ""
    }));
  };

  const showError = (errorMsg) => {
    setToast((prev) => ({
      ...prev,
      isOpen: true,
      isError: true,
      isSuccess: false,
      message: errorMsg
    }));
  }

  const showSuccess = (succMsg) => {
    setToast((prev) => ({
      ...prev,
      isOpen: true,
      isError: false,
      isSuccess: true,
      message: succMsg
    }));
  }

  const goToPage = (pageUrl) => {
    history.push(pageUrl);
  }

  const addMoreKPI = () => {

    setNewKPIVal('');
    setNewKPIProgress(0);
    setNewKPIStatus('');
    setIsEdit(false);
    setEditId(null);
    setShowNewModal(true);
  }
  const handleShowNewModalClose = () => {
    setNewKPIVal('');
    setNewKPIProgress(0);
    setNewKPIStatus('');
    setIsEdit(false)
    setEditId(null)
    setShowNewModal(false);
  }

  const addNewKPI = () => {
    if (newKPIVal.trim() != '' && newKPIStatus.trim() != '') {
      if (isEdit == false) {
        addKPIData({
          id: report?.id,
          kpiTitle: newKPIVal,
          objId: objectiveId,
          kpiStatus: newKPIStatus,
          kpiProgress: newKPIProgress,
          kpiRoadmap: newKPIRoadmap
        }).then(res => {

          const response = res.data
          if (response.success == true)
            showSuccess(response.message);
          else
            showError(response.message)

          loadData()
        })
          .catch(err => {
            console.log(err)
            showError("Failed to add KPI");
          })
      } else {
        editKPIData({
          id: report?.id,
          kpiTitle: newKPIVal,
          objId: objectiveId,
          kpiStatus: newKPIStatus,
          kpiProgress: newKPIProgress,
          kpi_id: editId,
          kpiRoadmap: newKPIRoadmap
        }).then(res => {
          console.log(res)
          const response = res.data
          if (response.success == true)
            showSuccess(response.message);
          else
            showError(response.message)

          loadData()
        })
          .catch(err => {
            console.log(err)
            showError("Failed to add KPI");
          })
      }
      setNewKPIVal('');
      setShowNewModal(false)

    }
    else {
      showError('KPI cannot by empty!')
    }

  }

  const handleEditKPI = (e, kpiData, objectiveId) => {
    setIsEdit(true)
    setEditId(kpiData.id)
    setNewKPIVal(kpiData.kpi)
    setNewKPIStatus(kpiData.kpi_status)
    setNewKPIProgress(kpiData.kpi_progress)
    setShowNewModal(true)

  }

  const handleDeleteKPI = (e, kpiData) => {
    if (window.confirm("Are you sure you want to delete KPI?") === true) {

      deleteKPI({
        id: kpiData.id
      })
        .then(res => {
          const response = res.data
          if (response.success == true)
            showSuccess(response.message)
          else
            showError(response.message)

          loadData()
        })
        .catch(err => {
          console.log(err)
          showError(err)
        })
    }
  }
  function formatDate(dateString) {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const formattedDate = new Date(dateString).toLocaleDateString(undefined, options);
    return formattedDate;
  }

  const handleOpenModal = () => {
    setOpenTopicModal(true)
    setObjKeyVal(objectiveIndex)
  }
  const handleCloseTopicModal = () => {
    setOpenTopicModal(false)
    setObjKeyVal(null)
    setIsEdit(true)
  }

  const handleRelevanceSlideChange = (e, value) => {
    setNewKPIProgress(value)
  }

  return (
    <>

      {toast.isOpen && <Toast message={toast.message} isError={toast.isError} isSuccess={toast.isSuccess} closeToast={closeToast} />}

      {
        isNotEmpty(objective?.id) ? <>

          <div className='main-wrapper'>
            <div className="container">

              <section className='kpi-head-tabs flexbox-title'>
                <div className='back-button'>
                  <button onClick={(e) => goToPage('/sustainability/dashboard')}>
                    <AiOutlineArrowLeft /> Back To Dashboard
                  </button>
                </div>
                <div className="centerpart">
                  <div className='heading-objective'>
                    {/* <h2>Objective {objectiveIndex + 1}</h2> */}
                    <h2>{objective?.objective}</h2>
                    <span className="heading-date">
                      Created on <b>{formatDate(objective.created_at)}</b>
                    </span>
                  </div>
                  <div className="dividebar"><img src="/assets/images/divider.png" alt="divider img" /></div>
                  <div className="heading-sdg">
                    {
                      Object.keys(goalDataItems).map((key) => {
                        const goalItem = goalDataItems[key]
                        let goalNumber = goalItem?.goal_number;
                        return <>
                          <img className="hover-raised-image" height="58px" width="58px" src={`/assets/images/sdg-icons/E-WEB-Goal-${goalNumber}.png`} />
                        </>
                      })
                    }
                  </div>
                </div>
                <div className="empty"></div>
              </section>

              <div className='text-center'>
                {/* <p>{objective?.objective}</p> */}
                <p><br /></p>
              </div>
              <div className='topic-list navy-table'>
                <table aria-label="simple table " className="newui-table">
                  <thead>
                    <tr rowspan="7">
                      <th colspan="3" align="left">Material Topics & KPIs</th>
                      <th align="left">Roadmap</th>
                      <th align="left">Status</th>
                      <th align="left">Implemented Date</th>
                      <th align="left">Progress</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr rowspan="7">
                      <td colspan="4">
                        <div className="priority-count">
                          <span className={`material-topics-priority priority-moderate`}> <span className="round-number-subject round-number-item have-objectives governance"><Button>{priorityCount.moderate}</Button></span> Moderate</span>
                          <span className={`material-topics-priority priority-major`}> <span className="round-number-subject round-number-item have-objectives"><Button>{priorityCount.major}</Button></span> Major</span>
                          <span className={`material-topics-priority priority-significant`}> <span className="round-number-subject round-number-item have-objectives"><Button>{priorityCount.significant}</Button></span> Significant</span>
                          <span className={`material-topics-priority priority-negligible`}> <span className="round-number-subject round-number-item have-objectives"><Button>{priorityCount.negligible}</Button></span> Negligible</span>
                          <span><a href={undefined} onClick={handleOpenModal} >View Details <ArrowForwardIcon /></a></span>
                        </div>
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                    {/* <tr rowspan="7">
                      <td colspan="3">Name</td>
                      <td>Roadmap</td>
                      <td>Status</td>
                      <td>Created At</td>
                      <td>Progress</td>
                    </tr> */}
                    <KpiTable objective={objective} handleDeleteKPI={handleDeleteKPI} handleEditKPI={handleEditKPI} />

                    <tr rowspan="7">
                      <td colspan="4">
                        <button style={{ cursor: 'pointer' }} className="btn-add" onClick={addMoreKPI}> <span><AddIcon /></span> Add more KPI</button>
                      </td>
                    </tr>

                  </tbody>
                </table>

              </div>
            </div>
          </div>

          <Dialog
            onClose={handleShowNewModalClose}
            fullWidth={true}
            maxWidth='sm'
            open={showNewModal}
            className={`theme-base-modal sustainability-layout NewUi_modals  addkpimodal`}
            keepMounted={false}
            PaperProps={{
              style: { borderRadius: 20, maxHeight: 'calc(100% - 44px)' }
            }}
          >
            <DialogTitle>
              <div className="modal-head">
                <span className="capital-case text-center">{isEdit ? 'Edit' : 'Add'} KPI</span>
                <IconButton onClick={handleShowNewModalClose} style={{ marginLeft: 'auto' }}>
                  <Close />
                </IconButton>
              </div>
            </DialogTitle>
            <DialogContent dividers >
              <DialogContentText>
                <div className="sus-objective-box">
                  <label htmlFor="kpi_name">KPI Name</label>
                  <input id="kpi_name" className='sus-objective-field mb-20' onChange={(e) => setNewKPIVal(e?.target?.value)} placeholder='Add New KPI' value={newKPIVal} />
                  <label htmlFor="kpi_status">Status</label>
                  <select id="kpi_status" className='sus-objective-field mb-20' onChange={(e) => setNewKPIStatus(e?.target?.value)} placeholder='' value={newKPIStatus}>
                    <option selected value="" disabled hidden>Please Select</option>
                    <option value="New KPI" style={{ backgroundImage: "/assets/images/New KPI.png" }}> New KPI</option>
                    <option value="In Progress" style={{ backgroundImage: "/assets/images/In Progress.png" }}> In Progress</option>
                    <option value="Ongoing Commitment" style={{ backgroundImage: "/assets/images/Ongoing Commitment.png" }}> Ongoing Commitment</option>
                  </select>
                  <label htmlFor="kpi_roadmap">Roadmap</label>
                  <textarea id="kpi_roadmap" className='sus-objective-field mb-20' onChange={(e) => setNewKPIRoadmap(e?.target?.value)} placeholder='Add Roadmap'>{newKPIRoadmap}</textarea>
                  <label htmlFor="kpi_progress">Status</label>
                  <Slider className="unsdg-range" style={{ color: "red" }} onChange={(e, value) => handleRelevanceSlideChange(e, value)} marks={marks} step={null} value={newKPIProgress} aria-label="Default" valueLabelDisplay="auto" />
                  <button onClick={addNewKPI} className="btn-sustainability primary mt-10px">Save Changes</button>
                </div>
              </DialogContentText>
            </DialogContent>
          </Dialog>

        </> : null
      }
      {
        openTopicModal && objKeyVal != null &&
        <EsgImpactTopicsModal closeModal={handleCloseTopicModal} openModal={openTopicModal} objectiveKey={objKeyVal} objectives={objectives} report={report} goals={goals} />
      }

    </>
  );
};

export default ViewObjective;