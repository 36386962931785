import { GET_ALL_DOMAIN } from '../redux.constants';
import { getDomains } from '../../services/admin.service';

export const getAllDomains = () => {
  return dispatch => {
    getDomains().then(res => {
      dispatch({
        type: GET_ALL_DOMAIN,
        payload: res?.data
      });
    })
  }
}