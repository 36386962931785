import { Box, Grid, makeStyles, Paper } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import InfoPaper from "../components/InfoPaper";
import VerticalLinearStepperEdoc from "../components/NewEdoc-stepper";
import { getStatus } from "../services/api.service";
import { useAuth } from "../hooks";

const useStyles = makeStyles((theme) => {
  return {
    paper: {
      padding: theme.spacing(1),
      margin: theme.spacing(2),
      backgroundColor: 'transparent',
    },
    content: {
      display: "flex",
      width: `calc(100% - ${300}px)`,
      marginLeft: 280,
      minHeight: "88vh",
    },
  };
});

function Edocument() {
  const classes = useStyles();
  const { user } = useAuth();
  const [status, setStatus] = useState();

  useEffect(() => {
    document.title = `TruDoc | Dashboard`;
    loadStatuses();
  }, []);

  const loadStatuses = () => {
    getStatus({ email: user.email, type: 'tru-sign' })
      .then((res) => {
        setStatus(res.data.data);
        console.log(res.data.data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }

  const handleUpdate = () => {
    loadStatuses();
  }

  return (
    <Box className={classes.content}>
      <Grid
        container
        spacing={0}
        // direction="column"  
        alignItems="center"
        justify="center"
      >
        <Grid item xs={12}>
          <Paper className={classes.paper} elevation={0}>
            <VerticalLinearStepperEdoc onUpdate={handleUpdate} />
          </Paper>
        </Grid>
        {status?.map((_, index) => (
          <InfoPaper dataLink="tru-sign" key={index} title={_.title} value={_.value} />
        ))}
      </Grid>
    </Box>
  );
}

export default Edocument;
