import { GET_WALLET_DETAILS, REMOVE_WALLET_DETAILS } from '../redux.constants';

const WalletReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_WALLET_DETAILS: 
      return action.payload;
    case REMOVE_WALLET_DETAILS: 
      return {};
    default:
      return state;
  }
}

export default WalletReducer;