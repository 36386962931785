import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import Slide from "@material-ui/core/Slide";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useModal, useAuth } from "../../hooks";
import { verifyLoginOtp, requestOtp } from "../../services/auth.service";
import OTPInput from "./otp";
import "./login.css";
import { FormControl, IconButton, InputLabel, MenuItem, Select } from "@material-ui/core";
import { useEffect } from "react";
import LoaderButton from "../../utils/Loader/LoaderButton";
import { Cancel } from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    maxWidth: "650px",
    margin: "0 auto"
  },
  paper: {
    borderRadius: "10px",
    backgroundColor: theme.palette.primary.main,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(6),
    textAlign: "center"
  },
  heading: {
    fontSize: "24px",
    fontWeight: "500",
    lineHeight: theme.spacing(0.2),
    marginBottom: theme.spacing(5)
  },
  buttonLeft: {
    marginTop: "20px",
    marginBottom: "10px",
    marginRight: "10px",
    width: 150,
    height: 45,
    borderRadius: 25
  },
  buttonRight: {
    marginTop: "20px",
    marginBottom: "10px",
    border: '1px solid gray',
    backgroundColor: '#fff',
    width: 150,
    height: 45,
    color: "#6a6a6a",
    borderRadius: 25,
    '&:hover': {
      backgroundColor: "#fff", 
      color: '#363636',
      border: '1px solid #464646'
   }
  },
  buttonText: {
    fontWeight: "bold",
    fontSize: 16,
    letterSpacing: "0.15em",
    color: "#FFFDFD"
  },
  buttonTextCancel: {
    fontWeight: "bold",
    fontSize: 16,
    letterSpacing: "0.15em",
  },
  otpInput: {
    width: "3rem !important",
    height: "3rem",
    margin: "0 1rem",
    fontSize: "2rem",
    textAlign: "center",
    borderRadius: "4px",
    border: "1px solid rgba(0, 0, 0, 0.3)"
  },
  Select: {
    minWidth: "200px"
  },
  Dialog: {
    display: "grid",
    justifyItems: "center"
  },
  closeIcon:{
    position: 'absolute',
    right: '0',
    padding: '10px'
  },
  title:{
    marginRight: '3rem'
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" timeout={10000} ref={ref} {...props} />;
});

const MultipleCorporateModal = props => {
  const classes = useStyles();
  const history = useHistory();
  const { saveUser } = useAuth();
  const [selectedValue, setSelectedValue] = useState("");
  const [corporateError, setCorporateError] = useState(false);
  const isLoading = useSelector(state => state.buttonLoading);

  useEffect(()=>{
    setSelectedValue("");
  },[props.clearSelectValue])
  const handleSubmit = e => {
    setCorporateError(false);
    e.preventDefault();
    if(selectedValue == ""){
      setCorporateError(true)
    }
    if(selectedValue){
      const value = props.corporateData.filter(e => e.id === selectedValue );
      props.handleSubmit(value[0].name);
    }
  };

  const handleChange = (e) =>{
    setSelectedValue(e.target.value);
    setCorporateError(false);
  }

  const closeHandler = ()=>{
    props.handleClose();
    setSelectedValue("");
  }

  return (
    <Dialog
      open={props.open}
      TransitionComponent={Transition}
      keepMounted
      onClose={closeHandler}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      align="center"
    >
      <div className={classes.closeIcon}>
      <IconButton
        onClick={closeHandler}
      >
        <Cancel/>
      </IconButton>  
      </div>
      <DialogTitle id="alert-dialog-slide-title" className={classes.title}>
        {props.title}
      </DialogTitle>
      <DialogContent className={classes.Dialog}>
        <FormControl className={classes.Select}>
        <InputLabel id="select-corporate">Select Corporate</InputLabel>
        <Select
          labelId="select-corporate"
          id="select-corporate-comp"
          value={selectedValue}
          label="Select Corporate"
          onChange={handleChange}
          error={corporateError}
          required
        >
          {
            props.corporateData.map((e,i)=>{
              return <MenuItem key={i} value={e.id}>{e.name}</MenuItem>
            })
          }
        </Select>
        </FormControl>
        <div>

        <LoaderButton
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          className={classes.buttonLeft}
          textClass={classes.buttonText}
          isLoading={isLoading}
          text="Submit"
        />
        <Button
          onClick={closeHandler}
          className={classes.buttonRight}
        >
          <Typography className={classes.buttonTextCancel}>Cancel</Typography>
        </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};
export default MultipleCorporateModal;

