import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  LinearProgress,
  Snackbar,
  Tab,
  Tabs,
  TextField,
  Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Toast from "../../utils/Toast";
import { Alert, Autocomplete, createFilterOptions, TabContext, TabPanel } from "@material-ui/lab";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from '@fullcalendar/daygrid'
import moment from "moment/moment";
import { Close } from "@material-ui/icons";
import { ToDoList } from "../../services/admin.service";

const useStyles = makeStyles((theme) => ({
  root: {
    width: `calc(100% - ${300}px)`,
    marginLeft: 280,
    minHeight: "88vh",
  },
}));

const usersFilter = createFilterOptions();

const Calendar = () => {

  const classes = useStyles();

  const toastInitialValues = {
    isOpen: false,
    isSuccess: false,
    isError: false,
    message: ""
  }

  const [toast, setToast] = useState(toastInitialValues);

  const closeToast = () => {
    return setToast((prev) => ({
      ...prev,
      isOpen: false,
      isSuccess: false,
      isError: false,
      message: ""
    }));
  };

  const showError = (errorMsg) => {
    setToast((prev) => ({
      ...prev,
      isOpen: true,
      isError: true,
      isSuccess: false,
      message: errorMsg
    }));
  }

  const showSuccess = (succMsg) => {
    setToast((prev) => ({
      ...prev,
      isOpen: true,
      isError: false,
      isSuccess: true,
      message: succMsg
    }));
  }

  useEffect(() => {
    document.title = `TruDoc | Calendar`;
  }, []);

  const [events, setEvents] = useState([]);

  const [eventModalOpen, setEventModalOpen] = useState(false);
  const [eventDetails, setEventDetails] = useState({
    title: '',
    description: '',
    time: '',
    type: ''
  });

  const [toDoLoading, setToDoLoading] = useState(false);

  const handleEventClick = (eventInfo) => {
    let event = eventInfo?.event;
    let extraFields = event?.extendedProps;
    setEventDetails({
      title: event?.title,
      description: extraFields?.description,
      type: extraFields?.type,
      time: moment(event?.start).format('llll')
    });
    setEventModalOpen(true);
  }

  const handleEventModalClose = () => {
    setEventModalOpen(false);
  }

  const listEvents = () => {
    setToDoLoading(true);
    ToDoList({
      type: 'pending'
    }).then((res) => {
      setToDoLoading(false);
      const eventsData = res.data.data;
      if (eventsData.length > 0) {
        let eventsItems = [];
        for (let eventItem of eventsData) {
          eventsItems.push({
            title: eventItem?.name,
            start: moment(eventItem?.time).format("YYYY-MM-DDTHH:mm"),
            className: 'event-type-' + eventItem?.type,
            extendedProps: {
              description: eventItem?.description,
              type: eventItem?.type,
            },
          });
        }
        // console.log("eventsItems", eventsItems);
        setEvents(eventsItems);
      }
    }).catch((err) => {
      console.log(err);
      setToDoLoading(false);
    });
  }

  useEffect(() => {
    listEvents();
  }, []);

  return (
    <>

      {toast.isOpen && <Toast message={toast.message} isError={toast.isError} isSuccess={toast.isSuccess} closeToast={closeToast} />}

      <Box className={classes.root}>
        <div className="full-calendar-wrapper mt-25px">
          {
            toDoLoading ? <>
              <Box className="event-box-no-results">
                <CircularProgress color="primary" size={20} />
              </Box>
            </> : <>
              <FullCalendar
                plugins={[dayGridPlugin]}
                initialView='dayGridMonth'
                events={events}
                height={"78vh"}
                eventContent={renderEventContent}
                eventClick={handleEventClick}
              />
            </>
          }
        </div>
      </Box>

      <Dialog
        fullWidth={true}
        maxWidth='sm'
        open={eventModalOpen}
        className={classes.dialogRoot}
        PaperProps={{
          style: { borderRadius: 20, maxHeight: 'calc(100% - 44px)' }
        }}
        onClose={handleEventModalClose}
      >

        <DialogTitle>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div>
              {eventDetails?.title}
            </div>
            <IconButton onClick={handleEventModalClose} style={{ marginLeft: 'auto' }}>
              <Close />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent dividers className={eventDetails?.type + ` event-modal-content ` + classes.contentHeight}>

          <Typography variant='h4' className="event-modal-short-desc">
            <span className={eventDetails?.type + ` event-type`}>Type: {eventDetails?.type}</span>
            <span className="event-time">{eventDetails?.time}</span>
          </Typography>

          <Typography variant='p' className="event-modal-description">
            {eventDetails?.description}
          </Typography>

        </DialogContent>
      </Dialog>

    </>
  );
};

export default Calendar;

function renderEventContent(eventInfo) {
  let startTime = moment(eventInfo.event.start).format("LT")
  return (
    <>
          <b>{startTime}</b>
          <p>{eventInfo.event.title}</p>
      
    </>
  )
}