import { GET_ALL_DOMAIN } from '../redux.constants';

const initialState = {
  domains: {},
  selectedDomain: null
}

const DomainReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_DOMAIN:
      return { ...state, domains: action.payload }
    default:
      return state;
  }
}

export default DomainReducer;