
const useLocalStorage = () => {
  const setItem = (name, value) => {
    return localStorage.setItem(name, btoa(JSON.stringify(value)));
  }

  const getItem = (name) => {
    const savedValue = localStorage.getItem(name);
    return savedValue && JSON.parse(atob(savedValue));
  }

  const deleteItem = (name) => {
    return localStorage.removeItem(name);
  }

  return { setItem, getItem, deleteItem };
}

export default useLocalStorage;