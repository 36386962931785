import { SELECTED_CUSTOMER, ALL_CUSTOMERS } from '../redux.constants';

const initialState = {
  selected: null,
  customers: {}
};

const CustomersReducer = (state = initialState, action) => {
  switch (action.type) {
    case SELECTED_CUSTOMER:
      return { ...state, selected: action.payload };
    case ALL_CUSTOMERS:
      return { ...state, customers: action.payload };
    default:
      return state;
  }
}

export default CustomersReducer;