import { Box, CircularProgress, FormControlLabel, Grid, makeStyles, Paper, StepLabel, Switch, Typography, withStyles } from "@material-ui/core";
import {
  Container,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  TableCell,
  TableRow,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { generateCorporateFormLink, getAwaitingCorporates, getStatus, listCorporates, markCorporateActive, updateSuperCorporate } from "../../../services/api.service";
import { useAuth } from "../../../hooks";
import { generateUniqueFileNameFromName, printTime, printUnderScoreText } from "../../../utils/Generic";
import { DoneAll, InsertLink, Visibility, Edit, Close } from "@material-ui/icons";
import Toast from "../../../utils/Toast";
import { useHistory } from "react-router-dom";
import ReactPaginate from 'react-paginate';


const useStyles = makeStyles((theme) => {
  return {
    paper: {
      padding: theme.spacing(1),
      margin: theme.spacing(2),
      backgroundColor: 'transparent',
    },
    content: {
      display: "block",
      width: `calc(100% - ${300}px)`,
      marginLeft: 280,
      minHeight: "88vh",
    },
  };
});

function AllCorporates() {

  const history = useHistory();

  const [showNewModal, setShowNewModal] = useState(false)
  const [isSustainability, setIsSustainability] = useState(false)
  const [sustainabilityFile, setSustainabilityFile] = useState();
  const [corporateId, setCorporateId] = useState(null)

  const toastInitialValues = {
    isOpen: false,
    isSuccess: false,
    isError: false,
    message: ""
  }

  const [toast, setToast] = useState(toastInitialValues);

  const closeToast = () => {
    return setToast((prev) => ({
      ...prev,
      isOpen: false,
      isSuccess: false,
      isError: false,
      message: ""
    }));
  };

  const showError = (errorMsg) => {
    setToast((prev) => ({
      ...prev,
      isOpen: true,
      isError: true,
      isSuccess: false,
      message: errorMsg
    }));
  }

  const showSuccess = (succMsg) => {
    setToast((prev) => ({
      ...prev,
      isOpen: true,
      isError: false,
      isSuccess: true,
      message: succMsg
    }));
  }

  const classes = useStyles();
  const { user } = useAuth();

  const [corporateLoading, setCorporateLoading] = useState(true);

  let initialPaginateData = {
    docs: [],
    hasNextPage: false,
    hasPrevPage: false,
    limit: 0,
    offset: 0,
    page: 0,
    pagingCounter: 0,
    totalDocs: 0,
    totalPages: 0,
  };

  const [paginationData, setPaginationData] = useState(initialPaginateData);

  useEffect(() => {
    document.title = `TruDoc | All Corporates`;
    loadCorporates();
  }, []);

  const loadCorporates = (page = 1) => {
    setCorporateLoading(true);
    listCorporates({
      page
    })
      .then((res) => {
        setPaginationData(res.data.data);
        setCorporateLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
        setCorporateLoading(false);
      });
  }

  const generateFormLink = (corporate) => {
    setCorporateLoading(true);
    generateCorporateFormLink({ corporateId: corporate.id })
      .then((res) => {
        let data = res.data;
        if (data.success) {
          showSuccess(data.data.viewUrl);
        } else {
          showError(data.message);
        }
        setCorporateLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
        setCorporateLoading(false);
      });
  }

  const goToCorporateDetails = (corporate) => {
    history.push(`/super/corporate-details/${corporate?.id}`)
  }

  const handlePageClick = (data) => {
    let current = data.selected + 1;
    console.log(current);
    loadCorporates(current);
  }


  const handleEditCorporate = (data) => {
    console.log("IN FUNCTION", data)
    setIsSustainability(data.is_sustainability == 1 ? true : false)
    setCorporateId(data.id)
    setShowNewModal(true)
  }

  const handleUpdate = async () => {
    let fileName = sustainabilityFile.name;
    fileName = generateUniqueFileNameFromName(fileName);
    console.log(fileName, "HERE IS FILE NAME")
    await updateSuperCorporate({
      is_sustainability: isSustainability,
      sustainability_report: sustainabilityFile,
      sustainability_file_name: fileName,
      corporate_id: corporateId
    }).then(res => {
      console.log(res)
    }).catch(err => {
      console.log(err)
    });
    console.log("UPDATE HANDLED")
  }

  const handleFileChange = (e) => {
    if (e.target.files) {
      setSustainabilityFile(e.target.files[0]);
    }
  };

  return (
    <>
      <Box className={classes.content}>

        {toast.isOpen && <Toast message={toast.message} isError={toast.isError} isSuccess={toast.isSuccess} closeToast={closeToast} />}

        <Grid
          container
          className="d-block mt-15px mb-15px"
          spacing={0}
        >
          <Grid item xs={12}>
            <h4 className="dashboard-title-header">All Corporate</h4>
          </Grid>
          <Grid item xs={12}>
            {
              corporateLoading ? <>
                <Box width="100%" className="text-center py-50px">
                  <CircularProgress color="primary" size={50} />
                </Box>
              </> : <>
                <div className="table-responsive mt-15px">
                  <table className="table table-default table-green-head">
                    <thead>
                      <tr>
                        <th>Corporate</th>
                        <th>Status</th>
                        <th>Registered On</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {(paginationData && paginationData.docs && paginationData.docs.length > 0) ?
                        paginationData.docs.map((corporate) => {
                          return (
                            <>
                              <tr>
                                <td>{corporate.name}</td>
                                <td>{printUnderScoreText(corporate?.status)}</td>
                                <td>{printTime(corporate?.created_at)}</td>
                                <td>
                                  <div className="table-actions-btns">
                                    {/* <button title="Edit Corporate" onClick={(e) => handleEditCorporate(corporate)}>
                                      <Edit />
                                    </button> */}
                                    <button title="View Corporate Details" onClick={(e) => goToCorporateDetails(corporate)}>
                                      <Visibility />
                                    </button>
                                    <button title="Generate Form Link" onClick={(e) => generateFormLink(corporate)}>
                                      <InsertLink />
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            </>
                          );
                        }) : <>
                          <tr>
                            <td colspan="4" className="text-center">No Awaiting Corporates Found</td>
                          </tr>
                        </>}
                    </tbody>
                  </table>

                </div>

                {paginationData.docs.length > 0 ?
                  <div className="react-paginate-wrapper">
                    <ReactPaginate
                      breakLabel={"..."}
                      pageCount={paginationData?.totalPages}
                      onPageChange={handlePageClick}
                      forcePage={(paginationData?.page - 1)}
                    />
                  </div>
                  : <div className="text-center">No Data</div>}

              </>
            }

          </Grid>
        </Grid>

      </Box>
      <Dialog
        onClose={e => {setShowNewModal(false);setCorporateId(null)}}
        fullWidth={true}
        maxWidth='md'
        open={showNewModal}
        className={`theme-base-modal sustainability-layout`}
        keepMounted={false}
        PaperProps={{
          style: { borderRadius: 20, maxHeight: 'calc(100% - 44px)' }
        }}
      >
        <DialogTitle>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span className="capital-case">Edit Corporate</span>
            <IconButton onClick={e => {setShowNewModal(false);setCorporateId(null)}} style={{ marginLeft: 'auto' }}>
              <Close />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent dividers >
          <DialogContentText>
            <div className="sus-objective-box">
              <p htmlFor="sustainability_checkbox">
                Sustainability
                <input type="checkbox" id="sustainability_checkbox" name="sustainability_checkbox" onChange={e => setIsSustainability(state => !state)} checked={isSustainability} />
              </p>
              <p htmlFor="">
                Sustainability Report
                {/* accept="application/pdf"? */}
                <input type="file" onChange={handleFileChange} id="sustainability_report" name="sustainability_report"  /> 
              </p>
            </div>
            <button onClick={handleUpdate} className="btn-sustainability primary mt-10px">Update</button>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default AllCorporates;
