import { BUTTON_LOADING } from '../redux.constants';

const ButtonLoading = (state = false, action) => {
  switch (action.type) {
    case BUTTON_LOADING:
      return !state;
    default:
      return state;
  }
}

export default ButtonLoading;