/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import { useAuth } from '../hooks';
import {
  FONT_FAMILIES,
} from '../utils/constants';
import FontFamilyOption from './FontFamilyOption';

export default function FontFamilySelection({
  choiceState,
  setChoiceState,
  fullName,
  setChanged,
  currFont,
  showBorderAndSerial,
  pubKey
}) {
  const { pubKeyFingerprint, selectedFont, color } = choiceState;

  const { user } = useAuth();

  const handleChange = (font) => {
    setChoiceState((oldState) => ({
      ...oldState,
      selectedFont: font,
    }));
    setChanged(true);
  };

  return (
    <div className="sig-choice">
      <div className="font-family-picker">
        {FONT_FAMILIES.map((font) => {
          const isSelected = selectedFont === font;
          const isCurrent = currFont === font;
          return (
            <FontFamilyOption
              key={font}
              font={font}
              color={color}
              name={fullName}
              pubKey={pubKey}
              handleChange={handleChange}
              isSelected={isSelected}
              isCurrent={isCurrent}
              showBorderAndSerial={showBorderAndSerial}
            />
          );
        })}
      </div>
    </div>
  );
};


