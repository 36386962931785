import { Box } from '@material-ui/core';
import ProtectedRoute from "./ProtectedRoute";
import Dashboard from '../views/Dashboard';

import SuperDashboard from '../views/super/SuperDashboard';  
import SuperSettings from '../views/super/SuperSettings';

import Upload from "../views/Upload";
import NavBar from "../components/NavBar";
import SidePanel from "../components/SidePanel";
import Verify from "../views/Verify/Verify";
import Contacts from "../views/Contacts";
import AllDocuments from "../views/dms/AllDocuments";
import NewEdocument from "../views/NewEdocument";
import Profile from "../views/Profile";
import AwaitingMySign from "../views/Awaiting";
import Sign from "../views/Sign";
import ESign from "../views/ESign";
import bg from '../static/images/bg.png';
import { useEffect } from 'react';
import { useAuth, useLocalStorage } from '../hooks';
import { useSocketContext } from '../services/socket';
import SignDocument from '../views/SignDocument';
import { UserRoles } from '../constants';
import CertificateMaker from '../views/maker/CertificateMaker';
import InvoiceMaker from '../views/maker/InvoiceMaker';
import Customers from '../views/Customers';
import Chat from '../views/chat/Chat';
import CreateESign from '../views/CreateESign';
import { Switch } from 'react-router-dom';
import Wallet from '../views/trusign/Wallet';
import Redirect from '../views/Redirect';
import EDocumentSuccess from '../views/EDocumentSuccess';
import Calendar from '../views/calendar/calendar';
import Sustainability from '../views/sustainability/sustainability';
import ToDo from '../views/todo/todo';
import CorporateDetails from '../views/super/Corporate/CorporateDetails';
import AllCorporates from '../views/super/Corporate/AllCorporates';

const AuthenticatedRoute = () => {

    const socket = useSocketContext();
    const { getItem } = useLocalStorage();
    const { user } = useAuth();

    useEffect(() => {
        const userId = getItem("userId");
        socket.emit('info', { userId });
    }, [])

    return (<>

        <Box style={{ backgroundImage: `url(${bg})` }}>
            <NavBar>
                <SidePanel>

                    <ProtectedRoute exact path={["/dashboard", ""]} component={Dashboard} />
                    <ProtectedRoute exact path="/profile" component={Profile} />
                    <ProtectedRoute exact path="/create-esign" component={CreateESign} />

                    <ProtectedRoute exact path="/redirect" component={Redirect} />

                    <ProtectedRoute exact path="/e-doc-success" component={EDocumentSuccess} />

                    {
                        user?.role == UserRoles.SUPER_ADMIN ? <>

                            <ProtectedRoute exact path={["/super/dashboard"]} component={SuperDashboard} />
                            <ProtectedRoute exact path={["/super/corporates"]} component={AllCorporates} />
                            <ProtectedRoute exact path={["/super/corporate-details/:id"]} component={CorporateDetails} />
                            <ProtectedRoute exact path={["/super/settings"]} component={SuperSettings} />

                        </> : <>

                            <Switch>
                                <ProtectedRoute exact path={["/dashboard/tru-sign/my_wallet"]} render={() => <Wallet />} />
                                <ProtectedRoute exact path="/dashboard/:documentType/:filterType" component={AwaitingMySign} />
                            </Switch>

                            <ProtectedRoute exact path={["/sign-document"]} component={SignDocument} />

                            <ProtectedRoute exact path="/sign" component={Sign} />
                            <ProtectedRoute exact path="/e-sign" component={ESign} />

                            <ProtectedRoute exact path={["/e-document", "/dms/tru-sign/:documentId"]} component={NewEdocument} />

                            <ProtectedRoute exact path="/chat/:channelId" component={Chat} />

                            <ProtectedRoute exact path="/calendar" component={Calendar} />
                            <ProtectedRoute exact path="/to-do" component={ToDo} />
                            
                            <ProtectedRoute exact path="/sustainability/*" component={Sustainability} />  

                            {
                                user?.role == UserRoles.USER ? <>


                                </> : <>

                                    <ProtectedRoute exact path={["/upload", "/dms/verifiable/:documentId"]} component={Upload} />

                                    <ProtectedRoute exact path="/verify" component={Verify} />

                                    <ProtectedRoute exact path="/dms" render={() => <AllDocuments dmsType="dms" />} />

                                    <ProtectedRoute exact path="/contacts" component={Contacts} />
                                    <ProtectedRoute exact path="/customers" component={Customers} />

                                    <ProtectedRoute exact path="/maker/certificate" component={CertificateMaker} />
                                    <ProtectedRoute exact path="/maker/invoice" component={InvoiceMaker} />

                                </>
                            }

                        </>
                    }

                </SidePanel>
            </NavBar>

        </Box>
    </>

    );
};

export default AuthenticatedRoute;