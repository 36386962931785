import { SIGNUP_USER_SUCCESS } from "../redux.constants";

const SignUpActions = (payload) => {
  return dispatch => {
    dispatch({
      type: SIGNUP_USER_SUCCESS,
      payload
    })
  }
}

export default SignUpActions;