import React, { useRef, useEffect, useState } from "react";
import WebViewer from "@pdftron/webviewer";
import { CircularProgress, makeStyles } from "@material-ui/core";
import {
  Box,
  Grid,
} from "@material-ui/core";
import Toast from "../Toast";
import { useAuth } from "../../hooks";
import "./Viewer.css";
import { base64ImageDimensions, isNotEmpty, svgToImage, updateDimensionsAsPerSizes, withAppUrl } from "../Generic";
import { MenuItem } from "@material-ui/core";
import { Select } from "@material-ui/core";
import { getSignList } from "../../services/api.service";
import { MessageConstants } from "../MessageConstants";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: "transparent",
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  paper1: {
    display: "flex",
    flexWrap: "wrap",
    backgroundColor: "transparent",
    "& > *": {
      margin: theme.spacing(3),
      padding: theme.spacing(2),
      width: "100%",
      textAlign: "center",
    },
  },
  edocuments: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  paper2: {
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(3),
      padding: theme.spacing(2),
      width: "100%",
    },
  },
  paper3: {
    backgroundColor: "transparent",
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(3),
      padding: theme.spacing(2),
      width: "100%",
      height: 350,
    },
  },
  dropIcon: {
    width: "150px",
    padding: theme.spacing(2),
  },
  docOptions: {
    cursor: "pointer",
    background: "#66cfa3",
    padding: "5px 20px",
    color: "#fff",
    borderRadius: "20px",
  },
  icon: {
    margin: theme.spacing(1),
  },
  formControl: {
    minWidth: "100%",
  },
  contentMargin: {
    marginTop: theme.spacing(3),
  },
  addSign: {
    color: "#3da543",
    background: "#d9d9d940",
    padding: "3px 8px",
    borderRadius: "10px",
  },
  selectBox: {
    marginRight: "20px",
    display: "flex",
    flexDirection: "column",
    rowGap: "10px",
  },
  webViewerStyle: {
    overflow: "hidden",
    width: "calc(100vw - 620px)",
    height: "100vh",
  },
  Select: {
  },
  SelectImg: {
    height: "100px",
    width: "auto"
  }
}));

const CertificateStepperDocument = (props) => {
  const toastInitialValues = {
    isOpen: false,
    isSuccess: false,
    isError: false,
    message: "",
  };

  let [instance, setInstance] = useState(null);
  const [toast, setToast] = useState(toastInitialValues);
  const classes = useStyles();
  const viewer = useRef(null);
  const filePicker = useRef(null);
  const { user } = useAuth();

  const [webViewerInitiated, setWebViewerInitiated] = useState(false);

  const { fields } = props;

  useEffect(() => {
    console.log("fields", fields);
    console.log("instance", instance);
    if (instance == null) {
      if (!webViewerInitiated) {
        setWebViewerInitiated(true);
        startPdfViewer();
      }
    } else {
      addFieldToDoc();
    }
  }, [fields, instance]);

  const closeToast = () => {
    return setToast((prev) => ({
      ...prev,
      isOpen: false,
      isSuccess: false,
      isError: false,
      message: "",
    }));
  };

  const startPdfViewer = () => {
    WebViewer(
      {
        path: "/webviewer/lib",
        css: "/style.css",
        fullAPI: true,
        disableLogs: true,
        disabledElements: [
          "ribbons",
          "toggleNotesButton",
          "searchButton",
          "menuButton",
          "rubberStampToolGroupButton",
          "stampToolGroupButton",
          "fileAttachmentToolGroupButton",
          "calloutToolGroupButton",
          // "undo",
          "redo",
          "eraserToolButton",
          // "toolsHeader",
          "signatureToolGroupButton",
          "leftPanelButton",
          "viewControlsButton",
          "selectToolButton",
          "annotationPopup",
          "toolsOverlay",
          "defaultSignatureDeleteButton",
          "contextMenuPopup",
          "signatureModal",
          "underlineToolGroupButton",
          "highlightToolGroupButton",
          "shapeToolGroupButton",
          "freeTextToolGroupButton",
          "freeHandHighlightToolGroupButton",
          "freeHandToolGroupButton",
          "stickyToolGroupButton",
          "squigglyToolGroupButton",
          "strikeoutToolGroupButton",
          "redoButton"
        ],
      },
      viewer.current
    ).then((instance) => {
      instance = instance;
      setInstance(instance);
      const { documentViewer } = instance.Core;
      const annotationManager = documentViewer.getAnnotationManager();
      annotationManager.setReadOnly(false);
      annotationManager.setCurrentUser(user.username);
      instance.UI.loadDocument(withAppUrl("/pdfs/authenticity-certificate.pdf"), { filename: props?.fileName });
    });
  };

  const addFieldToDoc = async () => {
    const { documentViewer, annotationManager, Annotations } = instance.Core;

    annotationManager.addEventListener('annotationChanged', (annotations, action) => {
      // console.log(annotations);
    });

    documentViewer.addEventListener('annotationsLoaded', async () => {
      let allAnnotations = [];

      if (isNotEmpty(fields?.authorName)) {
        const authorNameAnnot = new Annotations.FreeTextAnnotation(Annotations.FreeTextAnnotation.Intent.FreeText, {
          PageNumber: 1,
          X: 65,
          Y: 170,
          FontSize: "26px",
          Width: 730,
          Height: 85,
          TextAlign: 'center',
          TextVerticalAlign: 'center',
          TextColor: new Annotations.Color(102, 207, 164, 1),
          StrokeColor: new Annotations.Color(255, 255, 255, 0),
          ReadOnly: true
        });

        authorNameAnnot.setContents(fields?.authorName.toString().toUpperCase());
        allAnnotations.push(authorNameAnnot)
      }

      if (isNotEmpty(fields?.productName)) {
        const productNameAnnot = new Annotations.FreeTextAnnotation(Annotations.FreeTextAnnotation.Intent.FreeText, {
          PageNumber: 1,
          X: 560,
          Y: 271,
          FontSize: "16px",
          Width: 250,
          Height: 25,
          TextAlign: 'left',
          TextVerticalAlign: 'top',
          TextColor: new Annotations.Color(0, 0, 0, 1),
          StrokeColor: new Annotations.Color(255, 255, 255, 0),
          ReadOnly: true
        });

        productNameAnnot.setContents(fields?.productName.toString());
        allAnnotations.push(productNameAnnot)
      }

      if (isNotEmpty(fields?.productImage)) {
        console.log(fields);
        const productImage = fields?.productImage;
        let dimensions = await base64ImageDimensions(productImage);
        if (dimensions != null) {
          dimensions = updateDimensionsAsPerSizes(dimensions, 170, 215);

          const productImageAnnot = new Annotations.StampAnnotation({
            PageNumber: 1,
            X: 115,
            Y: 255,
            Width: dimensions.width,
            Height: dimensions.height,
            // ReadOnly: true
          });
          await productImageAnnot.setImageData(fields?.productImage);
          allAnnotations.push(productImageAnnot);
        }
      }

      if (isNotEmpty(fields?.dimensionMetric)) {
        let metricText = [];
        if (isNotEmpty(fields?.height)) {
          metricText.push(fields.height + " " + fields?.dimensionMetric + "(H)");
        }
        if (isNotEmpty(fields?.width)) {
          metricText.push(fields.width + " " + fields?.dimensionMetric + "(W)");
        }
        if (isNotEmpty(fields?.length)) {
          metricText.push(fields.length + " " + fields?.dimensionMetric + "(L)");
        }
        if (metricText.length > 0) {
          metricText = metricText.join(" ");
          const metricAnnot = new Annotations.FreeTextAnnotation(Annotations.FreeTextAnnotation.Intent.FreeText, {
            PageNumber: 1,
            X: 560,
            Y: 312,
            FontSize: "16px",
            Width: 250,
            Height: 25,
            TextAlign: 'left',
            TextVerticalAlign: 'top',
            TextColor: new Annotations.Color(0, 0, 0, 1),
            StrokeColor: new Annotations.Color(255, 255, 255, 0),
            ReadOnly: true
          });

          metricAnnot.setContents(metricText.toString());
          allAnnotations.push(metricAnnot);
        }
      }

      if (isNotEmpty(fields?.productDescription)) {
        const productDescriptionAnnot = new Annotations.FreeTextAnnotation(Annotations.FreeTextAnnotation.Intent.FreeText, {
          PageNumber: 1,
          X: 560,
          Y: 355,
          FontSize: "16px",
          Width: 250,
          Height: 25,
          TextAlign: 'left',
          TextVerticalAlign: 'top',
          TextColor: new Annotations.Color(0, 0, 0, 1),
          StrokeColor: new Annotations.Color(255, 255, 255, 0),
          ReadOnly: true
        });

        productDescriptionAnnot.setContents(fields?.productDescription.toString());
        allAnnotations.push(productDescriptionAnnot)
      }


      let datePlaceText = [];
      if (isNotEmpty(fields?.placeMade)) {
        datePlaceText.push("Made In " + fields?.placeMade);
      }
      if (isNotEmpty(fields?.dateMade)) {
        let date = new Date(fields?.dateMade);
        let dateStr = date.toDateString();
        datePlaceText.push(dateStr);
      }
      if (datePlaceText.length > 0) {
        datePlaceText = datePlaceText.join(", ");
        const datePlaceTextAnnot = new Annotations.FreeTextAnnotation(Annotations.FreeTextAnnotation.Intent.FreeText, {
          PageNumber: 1,
          X: 560,
          Y: 399,
          FontSize: "16px",
          Width: 250,
          Height: 30,
          TextAlign: 'left',
          TextVerticalAlign: 'top',
          TextColor: new Annotations.Color(0, 0, 0, 1),
          StrokeColor: new Annotations.Color(255, 255, 255, 0),
          ReadOnly: true
        });

        datePlaceTextAnnot.setContents(datePlaceText.toString());
        allAnnotations.push(datePlaceTextAnnot);
      }

      let materialTechText = [];
      if (isNotEmpty(fields?.materialUsed)) {
        materialTechText.push(fields?.materialUsed);
      }
      if (isNotEmpty(fields?.techniqueUsed)) {
        materialTechText.push(fields?.techniqueUsed);
      }
      if (materialTechText.length > 0) {
        materialTechText = materialTechText.join(" and ");
        const materialTechTextAnnot = new Annotations.FreeTextAnnotation(Annotations.FreeTextAnnotation.Intent.FreeText, {
          PageNumber: 1,
          X: 560,
          Y: 440,
          FontSize: "16px",
          Width: 250,
          Height: 25,
          TextAlign: 'left',
          TextVerticalAlign: 'top',
          TextColor: new Annotations.Color(0, 0, 0, 1),
          StrokeColor: new Annotations.Color(255, 255, 255, 0),
          ReadOnly: true,
        });

        materialTechTextAnnot.setContents(materialTechText.toString());
        allAnnotations.push(materialTechTextAnnot);
      }

      if (isNotEmpty(fields?.signatureImage)) {
        const signatureImageAnnot = new Annotations.StampAnnotation({
          PageNumber: 1,
          X: 374,
          Y: 485,
          Width: 175,
          Height: 62,
          ReadOnly: true
        });
        await signatureImageAnnot.setImageData(fields?.signatureImage);
        allAnnotations.push(signatureImageAnnot);
      }

      annotationManager.addAnnotations(allAnnotations);
      annotationManager.drawAnnotationsFromList(allAnnotations);

    });
  }

  const upload = async () => {
    const { docViewer, annotManager } = instance;
    const doc = docViewer.getDocument();
    const xfdfString = await annotManager.exportAnnotations({
      widgets: true,
      fields: true,
    });
    const options = {
      xfdfString,
      downloadType: "pdf" 
    };
    const data = await doc.getFileData(options);
    const arr = new Uint8Array(data);
    const blob = new Blob([arr], { type: "application/pdf" });
    props.uploadDocument(blob);
  };

  useEffect(() => {
    if (props.sendFile) {
      upload();
    }
  }, [props.sendFile]);

  return (
    <>
      {toast.isOpen && (
        <Toast
          message={toast.message}
          isError={toast.isError}
          isSuccess={toast.isSuccess}
          closeToast={closeToast}
        />
      )}
      {
        props.isloading ? <Grid container>
          <Grid container item xs={12}>
            <Box width="100%" className="text-center">
              <CircularProgress color="primary" size={50} />
            </Box>
          </Grid>
        </Grid> :
          <div className={"prepareDocument"}>
            <div style={{ display: "flex", flexDirection: "row", flex: "grow" }}>
              <div
                className={`${classes.webViewerStyle} webviewer`}
                ref={viewer}
              ></div>
            </div>
            <input type="file" ref={filePicker} style={{ display: "none" }} />
          </div>
      }
    </>
  );
};

export default CertificateStepperDocument;
