import { Box, CircularProgress, Grid, makeStyles, Paper, StepLabel } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import InfoPaper from "../../components/InfoPaper";
import { generateCorporateFormLink, getAwaitingCorporates, getStatus, markCorporateActive } from "../../services/api.service";
import { useAuth } from "../../hooks";
import { printTime, printUnderScoreText } from "../../utils/Generic";
import { DoneAll, InsertLink, Visibility } from "@material-ui/icons";
import Toast from "../../utils/Toast";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => {
  return {
    paper: {
      padding: theme.spacing(1),
      margin: theme.spacing(2),
      backgroundColor: 'transparent',
    },
    content: {
      display: "block",
      width: `calc(100% - ${300}px)`,
      marginLeft: 280,
      minHeight: "88vh",
    },
  };
});

function SuperDashboard() {

  const history = useHistory();

  const toastInitialValues = {
    isOpen: false,
    isSuccess: false,
    isError: false,
    message: ""
  }

  const [toast, setToast] = useState(toastInitialValues);

  const closeToast = () => {
    return setToast((prev) => ({
      ...prev,
      isOpen: false,
      isSuccess: false,
      isError: false,
      message: ""
    }));
  };

  const showError = (errorMsg) => {
    setToast((prev) => ({
      ...prev,
      isOpen: true,
      isError: true,
      isSuccess: false,
      message: errorMsg
    }));
  }

  const showSuccess = (succMsg) => {
    setToast((prev) => ({
      ...prev,
      isOpen: true,
      isError: false,
      isSuccess: true,
      message: succMsg
    }));
  }

  const classes = useStyles();
  const { user } = useAuth();

  const [status, setStatus] = useState();
  const [statusLoading, setStatusLoading] = useState(true);

  const [corporates, setCorporates] = useState([]);
  const [corporateLoading, setCorporateLoading] = useState(true);

  useEffect(() => {

    document.title = `TruDoc | Super Dashboard`;

    setStatusLoading(true);

    getStatus({ email: user.email, type: 'tru-sign' })
      .then((res) => {
        setStatus(res.data.data);
        setStatusLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
        setStatusLoading(false);
      });

    loadCorporates();

  }, []);

  const loadCorporates = () => {
    setCorporateLoading(true);

    getAwaitingCorporates()
      .then((res) => {
        setCorporates(res.data.data);
        setCorporateLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
        setCorporateLoading(false);
      });
  }

  const markActive = (corporate) => {
    setCorporateLoading(true);
    markCorporateActive({ corporateId: corporate.id })
      .then((res) => {
        loadCorporates();
      })
      .catch((err) => {
        console.log(err.message);
        setCorporateLoading(false);
      });
  }

  const generateFormLink = (corporate) => {
    setCorporateLoading(true);
    generateCorporateFormLink({ corporateId: corporate.id })
      .then((res) => {
        let data = res.data;
        if (data.success) {
          showSuccess(data.data.viewUrl);
        } else {
          showError(data.message);
        }
        setCorporateLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
        setCorporateLoading(false);
      });
  }

  const goToCorporateDetails = (corporate) => {
    history.push(`/super/corporate-details/${corporate?.id}`)
  }

  return (
    <Box className={classes.content}>

      {toast.isOpen && <Toast message={toast.message} isError={toast.isError} isSuccess={toast.isSuccess} closeToast={closeToast} />}

      <Grid
        container
        className="d-block mt-15px mb-15px"
        spacing={0}
      >
        <Grid item xs={12}>
          <h4 className="dashboard-title-header">Awaiting Corporate</h4>
        </Grid>
        <Grid item xs={12}>
          {
            corporateLoading ? <>
              <Box width="100%" className="text-center py-50px">
                <CircularProgress color="primary" size={50} />
              </Box>
            </> : <>
              <div className="table-responsive mt-15px">
                <table className="table table-default table-green-head">
                  <thead>
                    <tr>
                      <th>Corporate</th>
                      <th>Status</th>
                      <th>Registered On</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {(corporates && corporates.length > 0) ?
                      corporates.map((corporate) => {
                        return (
                          <>
                            <tr>
                              <td>{corporate.name}</td>
                              <td>{printUnderScoreText(corporate?.status)}</td>
                              <td>{printTime(corporate?.created_at)}</td>
                              <td>
                                <div className="table-actions-btns">
                                  <button title="View Corporate Details" onClick={(e) => goToCorporateDetails(corporate)}>
                                    <Visibility />
                                  </button>
                                  <button title="Generate Form Link" onClick={(e) => generateFormLink(corporate)}>
                                    <InsertLink />
                                  </button>
                                  <button title="Mark Active" onClick={(e) => markActive(corporate)}>
                                    <DoneAll />
                                  </button>
                                </div>
                              </td>
                            </tr>
                          </>
                        );
                      }) : <>
                        <tr>
                          <td colspan="4" className="text-center">No Awaiting Corporates Found</td>
                        </tr>
                      </>}
                  </tbody>
                </table>
              </div>
            </>
          }

        </Grid>
      </Grid>

    </Box>
  );
}

export default SuperDashboard;
