import { getData, v2, WrappedDocument } from "@govtechsg/open-attestation";
import prettyBytes from "pretty-bytes";
import React, { FunctionComponent } from "react";
import { Paperclip } from "react-feather";
import { makeStyles, Typography } from "@material-ui/core";
import pdf from "../static/images/fileicons/pdf.svg";
import doc from "../static/images/fileicons/doc.svg";
import csv from "../static/images/fileicons/csv.svg";
import txt from "../static/images/fileicons/txt.svg";
import jpg from "../static/images/fileicons/jpg.svg";
import png from "../static/images/fileicons/png.svg";

const useStyles = makeStyles(theme => {
  return {
    Icon: {
      height: "20px",
      width: "20px",
      marginRight: "20px"
    },
    flex: {
      display: "flex",
      alignItems: "center",
      marginLeft: "30px",
      marginTop: "50px"
    }
  };
});
const ExtensionIcon = ({ ...props }) => {
  const classes = useStyles();
  return <img {...props} className={classes.Icon} alt="Extension Icon" />;
};

const getExtension = mimeType => {
  switch (true) {
    case mimeType === "text/csv" ||
      mimeType === "application/vnd.ms-excel" ||
      mimeType ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      return <ExtensionIcon src={csv} data-testid="attachment-icon-csv" />;
    case mimeType ===
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
      mimeType === "application/msword":
      return <ExtensionIcon src={doc} data-testid="attachment-icon-doc" />;
    case mimeType === "image/jpeg":
      return <ExtensionIcon src={jpg} data-testid="attachment-icon-jpg" />;
    case mimeType === "image/png":
      return <ExtensionIcon src={png} data-testid="attachment-icon-png" />;
    case mimeType === "application/pdf":
      return <ExtensionIcon src={pdf} data-testid="attachment-icon-pdf" />;
    case mimeType === "text/plain":
      return <ExtensionIcon src={txt} data-testid="attachment-icon-txt" />;
    default:
      return (
        <div data-testid={`attachment-icon-paperclip`}>
          <Paperclip className="m-auto" />
        </div>
      );
  }
};

const openTab = data => {
  const url = `${window.location.protocol}//${window.location.host}`;
  const childWin = window.open(url, "_blank"); // to omit noopener noreferrer for this case, otherwise unable to postMessage

  childWin.onload = () => {
    childWin.postMessage(
      {
        payload: data
      },
      url
    );
  };
};

const isOpenAttestationFile = decodedData => {
  try {
    const decodedJson = JSON.parse(decodedData);
    const unwrappedDocument = getData(decodedJson);
    if (!unwrappedDocument) return true;
  } catch (e) {
    return false;
  }
};

export default function AttachmentLink({ filename, data, type, path }) {
  const classes = useStyles();
  let filesize = "0";
  let canOpenFile = false;
  const hasBase64 = !!(data && type);
  const downloadHref = hasBase64 ? `data:${type};base64,${data}` : path || "#";
  const decodedData = atob(data);
  canOpenFile = isOpenAttestationFile(decodedData);
  filesize = prettyBytes(decodedData.length);

  return (
    <div>
      <div className={classes.flex}>
        <div>{getExtension(type)}</div>
        <div>
          <div>
            <Typography>{filename}</Typography>
            {hasBase64 && <Typography>{filesize}</Typography>}
          </div>
          <div className="flex">
            <div>
              <a
                href={downloadHref}
                download={`${filename}`}
                data-testid="attachment-download-link"
              >
                Download
              </a>
            </div>
            {canOpenFile && data && (
              <div>
                <button
                  onClick={() => {
                    openTab(data);
                  }}
                  className="text-blue hover:underline"
                  data-testid="attachment-open-link"
                >
                  Open
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
