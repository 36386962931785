import { Button, SvgIcon } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import useDrivePicker from 'react-google-drive-picker'
import { GOOGLE_CLIENT_ID, GOOGLE_DEVELOPER_KEY } from "../constants";
import { isNotEmpty } from "../utils/Generic";

const GoogleDriveChooser = (props) => {

    const [openPicker, authResponse] = useDrivePicker();

    useEffect(() => { 
        let accessToken = authResponse?.access_token;
        if (isNotEmpty(accessToken)) {
            window.gAccessToken = accessToken;
        }   
    }, [authResponse]);

    const handleOpenPicker = (e) => {
        e.preventDefault();
        openPicker({
            clientId: GOOGLE_CLIENT_ID,
            developerKey: GOOGLE_DEVELOPER_KEY,
            viewId: "DOCS",
            showUploadView: true,
            showUploadFolders: true,
            supportDrives: true,
            multiselect: false,
            callbackFunction: (data) => {
                if (data.action === 'cancel') {
                    // console.log('User clicked cancel/close button');
                } else if (data.action === 'picked') {
                    let docs = data.docs;
                    if (docs.length > 0) {
                        sendFileData({
                            ...docs[0],
                            ...{
                                pickerType: 'google',
                                accessToken: window.gAccessToken
                            }
                        });
                    }
                }
            }
        })
    }

    const sendFileData = (data) => {
        props.onSelected(data);
    }

    return (
        <>
            <Button className="google" onClick={handleOpenPicker} title="Choose File from Google Drive">
                <SvgIcon
                    className={"chooser-svg-icon"}
                    width="50"
                    height="50"
                    viewBox="0 0 50 50"
                >
                    <path
                        d="M39.8984 23.7565L26.3541 0.731187C26.2187 0.528053 26.0155 0.392578 25.7447 0.392578H13.5548C13.284 0.392578 13.0807 0.528053 12.9453 0.663449L0.0782164 22.3343C0.010479 22.5375 -0.0572583 22.8084 0.0782164 23.0115L6.07726 35.0096C6.08897 35.101 6.12058 35.1902 6.17317 35.2691C6.2271 35.35 6.30272 35.4194 6.38677 35.4745C6.49703 35.5623 6.62853 35.6077 6.78273 35.6077H33.1941C33.465 35.6077 33.6682 35.4722 33.8036 35.2691L39.8986 24.4337C40.0339 24.2305 40.0339 23.9596 39.8984 23.7565ZM38.1377 23.4179H26.8282L19.8792 10.9951C19.866 10.9123 19.8349 10.8286 19.7852 10.754L14.734 1.74701H25.3383L38.1377 23.4179ZM25.2447 23.4179H13.536L19.1708 12.5487L25.2447 23.4179ZM13.5332 2.46044L16.2839 7.38279L18.363 11.1603L11.8617 23.6687C11.8377 23.6964 11.8148 23.7254 11.794 23.7565L6.77664 33.4286L1.43265 22.7407L13.5332 2.46044ZM32.7877 34.2533H7.91874L12.8338 24.7722H26.4219H38.1377L32.7877 34.2533Z"
                        fill="#7E8D90"
                    />
                </SvgIcon>
            </Button>
        </>
    );

};

export default GoogleDriveChooser;
