import { useDispatch, useSelector } from 'react-redux';
import { ModalActions } from '../redux/actions';

const useModal = () => {
  const dispatch = useDispatch();
  const { isOpen } = useSelector(state => state.modal);

  const onOpen = (data = {}) => {
    const modalData = {
      isOpen: true,
      data
    }
    dispatch(ModalActions(modalData));
  }

  const onClose = (data = {}) => {
    const modalData = {
      isOpen: false,
      data
    }
    dispatch(ModalActions(modalData));
  }

  return { isOpen, onOpen, onClose };
}

export default useModal;