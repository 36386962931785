import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import useQuery from "../hooks/useQuery";

const Redirect = () => {

  let query = useQuery();
  const history = useHistory();

  useEffect(() => {
    let page = query.get('page');
    let search = query.get('search');
    // history.push({ pathname: page });
  }, []);


  return (

    <>

    </>
  );
};

export default Redirect;
