import { AWAITING_OTHER_SIGN, AWAITING_MY_SIGN, ISSUED, REJECTED, EXPIRY, MY_WALLET } from '../redux.constants';

const reduxMapper = {
  awaiting_my_sign: AWAITING_MY_SIGN,
  awaiting_others_sign: AWAITING_OTHER_SIGN,
  completed: ISSUED,
  declined: REJECTED,
  expired: EXPIRY,
  my_wallet: MY_WALLET
}

const SidePanelAction = (type, payload) => {
  return dispatch => {
    dispatch({
      type: reduxMapper[type],
      payload,
    })
  }
};

export default SidePanelAction;