import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, makeStyles, Grid, Paper, Typography, Card, CardContent, Button, TextField, CircularProgress, withStyles, FormControl, Select, MenuItem, InputBase, InputLabel } from '@material-ui/core';
import AdminLayout from './Admin.layout';
import { useState } from 'react';
import { getCorporateSession, getCorporateSettings, linkWallet, saveCorporateSession, saveCorporateSettings } from '../../services/admin.service';
import { MessageConstants } from '../../utils/MessageConstants';
import Toast, { toastInitialValues } from '../../utils/Toast';
import { btoaToBlob, fileToBase64, isNotEmpty, validateJOIFormField, validateJOIProperty } from '../../utils/Generic';
import FieldError from "./../../components/helperComponents/FieldError";
import * as Joi from "joi-browser";

import { documentUploadMethods } from "./../../constants";
import LoaderButton from '../../utils/Loader/LoaderButton';
import { useAuth } from '../../hooks';


const useStyles = makeStyles((theme) => {
  return {
    root: {
      padding: 10,
    },
    heading: {
      padding: 10
    },
    button: {
      marginTop: 15,
      width: 220,
      height: 45,
      borderRadius: 25,
      backgroundColor: theme.palette.admin.main,
      '&:hover': {
        backgroundColor: theme.palette.admin.main,
      }
    },
    buttonText: {
      fontWeight: "bold",
      fontSize: 14,
      letterSpacing: "0.15em",
      color: "#FFFDFD",
    },
    field: {
      width: "100%",
      borderRadius: 10,
      color: theme.palette.admin.main,
    },
    textFieldInput: {
      color: theme.palette.admin.main,
    },
    actionBox: {
      marginTop: 50
    },
    domainContainer: {
      minWidth: '70%',
      width: '70%',
      maxWidth: '100%',
      padding: 20,
      color: 'gray',
      cursor: "pointer",
      margin: '0 auto',
      textTransform: 'uppercase',
      '&:hover': {
        color: theme.palette.admin.main,
      }
    },
    itemUrl: {
      fontSize: 14,
    },
    subHeading: {
      textAlign: 'left'
    },
    Select: {
      minWidth: "100%"
    },
    updateButton: {
      width: 220,
      height: 45,
      borderRadius: 25,
      backgroundColor: theme.palette.admin.main,
      '&:hover': {
        backgroundColor: theme.palette.admin.main,
      }
    },
  }
});

const CssTextField = withStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {

      },
      "&:focus": {

      },
      "&:hover fieldset": {

      },
      borderRadius: 10,
    },
  },
})(TextField);

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 10,
    position: 'relative',
    backgroundColor: '#fff',
    border: '1px solid #BF85FA',
    fontSize: 18,
    padding: '18px 14px',
    color: theme.palette.admin.main,
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      backgroundColor: '#fff',
      borderColor: theme.palette.admin.main,
      color: theme.palette.admin.main,
      borderRadius: 10,
    },
  },
}))(InputBase);

const Settings = () => {
  const classes = useStyles();
  const [toast, setToast] = useState(toastInitialValues);
  const [settingsLoading, setSettingsLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [sessionLoading, setSessionLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [timeout, setTimeout] = useState("");
  const [settings, setSettings] = useState({});

  const [logoView, setLogoView] = useState(null);

  const { getUser } = useAuth();

  useEffect(() => {
    loadSettings();
    getSession();
  }, []);

  const schema = {
    non_transferable_method: Joi.string().required().label("Non Transferable Method"),
    business_logo: Joi.string().required().label("Business Logo"),
    business_name: Joi.string().required().label("Business Name"),
    business_contact_details: Joi.string().required().label("Business Contact Details"),
  };

  const loadSettings = () => {
    setSettingsLoading(true);
    getCorporateSettings().then(res => {
      setSettingsLoading(false);
      if (res && res.status === 200) {
        setSettings(res.data.settings);
      }
    }).catch(e => {
      setSettingsLoading(false);
      setToast((prev) => ({
        ...prev,
        isOpen: true,
        isError: true,
        message: MessageConstants.FAILED_TO_LOAD
      }));
    });
  }

  const saveSettings = () => {
    let errors = validateJOIFormField(settings, schema);
    if (errors == null) {
      setLoading(true);
      saveCorporateSettings(settings).then(res => {
        setLoading(false);
        setToast((prev) => ({
          ...prev,
          isOpen: true,
          isError: false,
          isSuccess: true,
          message: MessageConstants.SETTINGS_SAVED
        }));
        getUser();
      }).catch(e => {
        setLoading(false);
        setToast((prev) => ({
          ...prev,
          isOpen: true,
          isError: true,
          isSuccess: false,
          message: MessageConstants.FAILED_TO_LOAD
        }));
      });
    } else {
      setErrors(errors);
    }
  }

  const getSession = () => {
    setSettingsLoading(true);
    getCorporateSession().then(res => {
      setTimeout(res.data.sessionLifespan);
    }).catch(e => {
      setToast((prev) => ({
        ...prev,
        isOpen: true,
        isError: true,
        message: MessageConstants.SESSION_LOAD_FAILED
      }));
    }).finally(() => {
      setSettingsLoading(false);
    })
  }

  const saveTimeout = () => {
    setSessionLoading(true)
    saveCorporateSession({ sessionLifespan: timeout }).then(res => {
      setToast((prev) => ({
        ...prev,
        isOpen: true,
        isSuccess: true,
        message: MessageConstants.SESSION_UPDATE_SUCCESS
      }));
    }).catch(e => {
      setToast((prev) => ({
        ...prev,
        isOpen: true,
        isError: true,
        message: MessageConstants.SESSION_UPDATE_FAILED
      }));
    }).finally(() => {
      setSessionLoading(false);
    })
  }

  const updateModelFieldValue = (name, value) => {
    settings[name] = value;
    setSettings({ ...settings });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    let errorData = { ...errors };
    const errorMessage = validateJOIProperty(schema, event);
    if (errorMessage) {
      errorData[name] = errorMessage;
    } else {
      delete errorData[name];
    }
    updateModelFieldValue(name, value);
    setErrors(errorData);
  };

  const handleImageChange = async (e) => {
    if (e.target.files) {
      let file = e.target.files[0];
      file = await fileToBase64(file);
      if (file != null) {
        file = btoa(file);
        updateModelFieldValue('business_logo', file);
      }
    }
  }

  const closeToast = () => {
    return setToast((prev) => ({
      ...prev,
      isOpen: false,
      isSuccess: false,
      isError: false,
      message: ""
    }));
  };

  const lifeSpan = [30, 60, 120, 180, 240]; // in minutes

  useEffect(() => {
    if (isNotEmpty(settings?.business_logo)) {
      let logoBlobData = btoaToBlob(settings?.business_logo);
      var logoBlobUrl = URL.createObjectURL(logoBlobData);
      setLogoView(logoBlobUrl);
    } else {
      setLogoView(null);
    }
  }, [settings]);



  return (
    <>
      {toast.isOpen && <Toast message={toast.message} isError={toast.isError} isSuccess={toast.isSuccess} closeToast={closeToast} />}
      <AdminLayout>

        {
          settingsLoading ? <>

            <Grid container>
              <Grid container item xs={12}>
                <Box width="100%" className="text-center">
                  <CircularProgress color="primary" size={50} />
                </Box>
              </Grid>
            </Grid>

          </> : <>


            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              spacing={5}
              className={classes.domainBox}
            >
              <Grid item xs={12} align="center">

                <Card>
                  <CardContent>
                    <Typography variant="h6" textTransform="uppercase" className={classes.heading} align="left">
                      Update Timeout
                    </Typography>

                    <Box width="100%">

                      <Grid
                        container
                        direction="row"
                        alignItems="center"
                        className={classes.root}
                        spacing={2}
                      >
                        <Grid className='mb-3' display="flex" alignItems="center" item xs={12} align="left">
                          <FormControl variant="outlined" className={classes.Select}>
                            <InputLabel id="select-session">Select Session Timeout(in Min)</InputLabel>
                            <Select
                              labelId="select-session"
                              id="select-session-comp"
                              value={timeout}
                              label="Select Session Timeout(in Min)"
                              onChange={e => setTimeout(e.target.value)}
                              required
                            >
                              {
                                lifeSpan.map((e, i) => {
                                  return <MenuItem key={i} value={e}>{e}</MenuItem>
                                })
                              }
                            </Select>
                          </FormControl>
                        </Grid>

                        <Box className='mt-15px'>
                          <LoaderButton
                            type="submit"
                            variant="contained"
                            color="primary"
                            className={classes.updateButton}
                            size="large"
                            onClick={saveTimeout}
                            text="Update Timeout"
                            textClass={classes.buttonText}
                            isLoading={sessionLoading}
                          />
                        </Box>

                      </Grid>

                    </Box>

                  </CardContent>
                </Card>

              </Grid>
            </Grid>

            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              spacing={5}
              className={classes.domainBox}
            >
              <Grid item xs={12} align="center">

                <Card>
                  <CardContent>
                    <Typography variant="h6" textTransform="uppercase" className={classes.heading} align="left">
                      Update Settings
                    </Typography>

                    <Box width="100%">

                      <Grid
                        container
                        direction="row"
                        alignItems="center"
                        className={classes.root}
                        spacing={2}
                      >

                        <Grid item xs={12} align="left">
                          <FormControl variant="outlined" className={classes.Select}>
                            <InputLabel id="select-session">Non Transferable Document Upload Method*</InputLabel>
                            <Select
                              labelId="select-session"
                              id="select-session-comp"
                              value={settings?.non_transferable_method || ""}
                              label="Non Transferable Document Upload Method*"
                              name="non_transferable_method"
                              onChange={e => { handleChange(e) }}
                              required
                            >
                              <MenuItem value={documentUploadMethods.did.value}>{documentUploadMethods.did.label}</MenuItem>
                              <MenuItem value={documentUploadMethods.blockchain.value}>{documentUploadMethods.blockchain.label}</MenuItem>
                              <MenuItem value={documentUploadMethods.both.value}>{documentUploadMethods.both.label}</MenuItem>
                            </Select>
                          </FormControl>
                          <FieldError error={errors.non_transferable_method} />
                        </Grid>

                        <Grid item xs={12} align="left" className='mt-5px'>
                          <CssTextField
                            type={"file"}
                            label="Logo"
                            name="business_logo"
                            className={classes.field + ' fieldWithFile'}
                            onChange={handleImageChange}
                            variant="outlined"
                            required
                            fullWidth
                          />
                          <FieldError error={errors.business_logo} />
                          {
                            isNotEmpty(logoView) ? <>
                              <p className='field-help-text'><a href={logoView} target="_blank">Click to View Current Business Logo</a></p>
                            </> : null
                          }
                        </Grid>

                        <Grid item xs={12} align="left" className='mt-5px'>
                          <TextField value={settings?.business_name || ""} className={classes.field} onChange={e => { handleChange(e) }} name="business_name" label="Business Name*" variant="outlined" />
                          <FieldError error={errors.business_name} />
                        </Grid>

                        <Grid item xs={12} align="left" className='mt-5px'>
                          <TextField value={settings?.business_contact_details || ""} className={classes.field} multiline rows={4} onChange={e => { handleChange(e) }} name="business_contact_details" label="Business Contact Details*" variant="outlined" />
                          <FieldError error={errors.business_contact_details} />
                        </Grid>

                      </Grid>

                      <Box className='mt-15px' display="flex" alignItems="center" justifyContent="">
                        <LoaderButton
                          type="submit"
                          variant="contained"
                          color="primary"
                          className={classes.updateButton}
                          size="large"
                          onClick={saveSettings}
                          text="Update Settings"
                          textClass={classes.buttonText}
                          isLoading={loading}
                        />
                      </Box>

                    </Box>

                  </CardContent>
                </Card>

              </Grid>
            </Grid>

          </>
        }



      </AdminLayout>
    </>
  )
}

export default Settings;
