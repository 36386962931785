export const AssetManagementActions = {
    None: "None",
    TransferHolder: "TransferHolder",
    EndorseBeneficiary: "EndorseBeneficiary",
    Surrender: "Surrender",
    AcceptSurrendered: "AcceptSurrendered",
    RejectSurrendered: "RejectSurrendered",
    NominateBeneficiaryHolder: "NominateBeneficiaryHolder",
    EndorseTransfer: "EndorseTransfer",
  }
  