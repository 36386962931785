import { Box, Grid, makeStyles, Paper } from "@material-ui/core";
import React, { useEffect } from "react";
import InvoiceStepper from "../../components/maker/InvoiceStepper";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1),
    margin: theme.spacing(2),
    backgroundColor: 'transparent',
  },
  content: {
    display: "flex",
    width: `calc(100% - ${300}px)`,
    marginLeft: 280,
    minHeight: "88vh",
  },
}));

function InvoiceMaker() {
  const classes = useStyles();

  useEffect(() => {
    document.title = `TruDoc | Create Invoice`;
  }, []);

  return (
    <Box className={classes.content}>
      <Grid
        container
        spacing={0}
        // direction="column"
        alignItems="center"
        justify="center"
      >
        <Grid item xs={12}>
          <Paper className={classes.paper} elevation={0}>
            <InvoiceStepper />
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}

export default InvoiceMaker;
