import { SELECTED_CUSTOMER, ALL_CUSTOMERS } from '../redux.constants';
import { getAllCustomers } from '../../services/admin.service';

export const SelectCustomerAction = (payload) => {
  return dispatch => {
    dispatch({
      type: SELECTED_CUSTOMER,
      payload
    })
  }
};

export const GetCustomers = () => {
  return dispatch => {
    getAllCustomers().then(res => {
      if (res && res.status === 200) {
        dispatch({
          type: ALL_CUSTOMERS,
          payload: res.data
        })
      }
    })
  };
};
