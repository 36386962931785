import React, { useEffect, useRef, useState } from "react";
import {
    TableCell,
    TableRow,
} from "@material-ui/core";
import { makeStyles, styled } from "@material-ui/core/styles";
import Toast from "../../utils/Toast";
import { UNSDG_GOALS, UNSDG_GOAL_CATEGORIES } from "../../utils/unsdg-goals";
import { useHistory } from "react-router-dom";
import { generateSusChart, goalsParseCategories, isNotEmpty, printTime } from "../../utils/Generic";
import Chart from "react-google-charts";
import { deleteObjective } from "../../services/admin.service";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { Grid, Paper } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        width: `calc(100% - ${300}px)`,
        marginLeft: 280,
        minHeight: "88vh",
    },
}));


const DasboardOverview = (props) => {

    const { objectives, report, goals, setGoals, companyDocuments, canSave, loadData } = props;


    const [chartData, setChartData] = useState([]);
    const [topicsSort, setTopicsSort] = useState({
        Environment: [],
        Social: [],
        Governance: []
    })

    useEffect(() => {
        setChartData(generateSusChart(report));
    }, [report]);

    useEffect(() => {
        let final = {
            Environment: [],
            Social: [],
            Governance: []
        }
        report?.sustainability_goals.map(goal => {
            if (UNSDG_GOAL_CATEGORIES.environment.goals.includes(goal.goal_number)) {
                final.Environment.push(goal)
            }
            if (UNSDG_GOAL_CATEGORIES.social.goals.includes(goal.goal_number)) {
                final.Social.push(goal)
            }
            if (UNSDG_GOAL_CATEGORIES.governance.goals.includes(goal.goal_number)) {
                final.Governance.push(goal)
            }
        })
        setTopicsSort(final)
    }, [])

    let history = useHistory();


    const toastInitialValues = {
        isOpen: false,
        isSuccess: false,
        isError: false,
        message: ""
    }

    const [toast, setToast] = useState(toastInitialValues);

    const closeToast = () => {
        return setToast((prev) => ({
            ...prev,
            isOpen: false,
            isSuccess: false,
            isError: false,
            message: ""
        }));
    };

    const showError = (errorMsg) => {
        setToast((prev) => ({
            ...prev,
            isOpen: true,
            isError: true,
            isSuccess: false,
            message: errorMsg
        }));
    }

    const showSuccess = (succMsg) => {
        setToast((prev) => ({
            ...prev,
            isOpen: true,
            isError: false,
            isSuccess: true,
            message: succMsg
        }));
    }
    const goToPage = (pageUrl) => {
        history.push(pageUrl);
    }

    const handleDeleteObjective = (objectiveData) => {

        if (window.confirm("Are you sure you want to delete objective?") === true) {

            deleteObjective({
                id: objectiveData.id
            })
                .then(res => {
                    const response = res.data
                    if (response.success == true)
                        showSuccess(response.message)
                    else
                        showError(response.message)

                    loadData()
                })
                .catch(err => {
                    console.log(err)
                    showError(err)
                })
        }
        console.log("Click on delete from dashboard for ", objectiveData)
    }

    return (
        <>

            <div className="topic-backgroud card_header">
                <div className="bg-light-grey">
                    <h5 className="fs-24 m-0">My Material Topics</h5>
                </div>
                {
                    report?.sustainability_goals.length > 0 &&
                    
                    <div className="custom-grid-hequal main-wrapper row overview_dashboard">
                        <div className="overview-div">
                                <Grid container direction="column">
                                    <Grid item className='tabs'>
                                        <h4 className={`tab-head-options color-blue-light active`}>Environment</h4>
                                    </Grid>
                                    <Grid item container className="overview-topic-block color-blue">
                                        {topicsSort.Environment.map(goal =>
                                            goal.sustainability_goal_subjects.map((subject, index) => (
                                                <span className="topic-span color-blue-dark">{`#${subject.subject}`}</span>
                                            ))
                                        )}
                                    </Grid>
                                </Grid>
                            </div>
                            <div className="overview-div">
                                <Grid container direction="column">
                                    <Grid item className='tabs'>
                                        <h4 className={`tab-head-options color-purple-light active`}>Social</h4>
                                    </Grid>
                                    <Grid item container className="overview-topic-block color-purple">
                                        {topicsSort.Social.map(goal =>
                                            goal.sustainability_goal_subjects.map((subject, index) => (
                                                <span className="topic-span color-purple-dark">{`#${subject.subject}`}</span>
                                            ))
                                        )}
                                    </Grid>
                                </Grid>
                            </div>
                            <div className="overview-div">
                                <Grid container direction="column">
                                    <Grid item className='tabs'>
                                        <h4 className={`tab-head-options color-pink-light active`}>Governance</h4>
                                    </Grid>
                                    <Grid item container className="overview-topic-block color-pink">
                                        {topicsSort.Governance.map(goal =>
                                            goal.sustainability_goal_subjects.map((subject, index) => (
                                                <span className="topic-span color-pink-dark">{`#${subject.subject}`}</span>
                                            ))
                                        )}
                                    </Grid>
                                </Grid>
                            </div>
                        </div>

                }
            </div>


            {
                Object.keys(goals).length > 0 ? <>

                    <div className="more-about-esg ds-flex item-between">
                        <div className="col-50 overview-col-50 aboutesg-block">
                            <div className="bg-light-grey">
                                <h5 className="fs-24 m-0">Objectives</h5>
                            </div>

                            {
                                isNotEmpty(report?.sustainability_goal_objectives) && report?.sustainability_goal_objectives.length > 0 ? <>
                                    <ul className="esg-ul">
                                        {
                                            report?.sustainability_goal_objectives.map((goalObjective, index) => {
                                                return <>
                                                    <li className="ds-flex objective-item">
                                                        <a href={undefined} onClick={(e) => goToPage("/sustainability/objective/" + goalObjective?.id)}>
                                                            <h6>{index + 1}.</h6>
                                                            <div className="objective-title-detail">
                                                                <h6>{goalObjective?.objective} </h6>
                                                                <ArrowForwardIosIcon />
                                                            </div>
                                                        </a>
                                                    </li>
                                                </>
                                            })
                                        }
                                    </ul>
                                </> : <>
                                    <p className="text-center">No Objectives Added</p>
                                </>
                            }

                        </div>
                        <div className="col-50 overview-col-50  aboutesg-block">
                            <div className="bg-light-grey ">
                                <h5 className="fs-24 m-0">SDGs</h5>
                            </div>

                            {
                                isNotEmpty(report?.sustainability_goals) && report?.sustainability_goals.length > 0 ? <>
                                    <div container className="esg-unew-designl-grid">
                                        {
                                            report?.sustainability_goals.map((goalItem, index) => {
                                                let goalNumber = goalItem?.goal_number;
                                                let unsdgGoal = UNSDG_GOALS[goalNumber];
                                                return <>
                                                    <div item className="grid-col-5">
                                                        <img className="hover-raised-image" src={`/assets/images/sdg-icons/E-WEB-Goal-${goalNumber}.png`} />
                                                    </div>
                                                </>
                                            })
                                        }
                                    </div>
                                </> : <>
                                    <p className="text-center">No Objectives Added</p>
                                </>
                            }

                        </div>
                    </div>

                </> : <>
                    <div className='blank-page'>
                        <img src="/assets/images/dashboard-blank.png" />
                        <h2>No Data Yet</h2>
                        <p>Please click the button below to configure objectives</p>
                        <button onClick={(e) => history.push("/sustainability/unsdg/goals")} className='navy-btn'>Configure Objectives</button>
                    </div>
                </>
            }
        </>
    );
};

export default DasboardOverview;