import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Slider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@material-ui/core";
import { makeStyles, styled } from "@material-ui/core/styles";
import Toast from "../../utils/Toast";
import { useHistory, useParams } from "react-router-dom";
import { isNotEmpty, makeText, parseByGoals, toTitleCase } from "../../utils/Generic";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { saveKpiDataType, saveKpiData } from "../../services/admin.service";
import { BiPlusCircle } from "react-icons/bi";
import Select from 'react-select'
import KpiChart from "../charts/KpiChart";
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { Close } from "@material-ui/icons";


const StyledTableCell = styled(TableCell)(({ theme }) => ({

}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  height: '50px',
  border: 'none',
  '&:nth-of-type(even)': {
    backgroundColor: '#334bc120',
    border: 'none'
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));


const useStyles = makeStyles((theme) => ({
  root: {
    width: `calc(100% - ${300}px)`,
    marginLeft: 280,
    minHeight: "88vh",
  },
}));

const TrackKPI = (props) => {


  const defaultStyles = {
    control: (base, state) => ({
      ...base
    }),
    menu: (base) => ({
      ...base
    }),
    menuList: (base) => ({
      ...base,
      maxHeight: "120px" // your desired height
    })
  };


  const { report, setReport } = props;

  const [objective, setObjective] = useState({});
  const [kpi, setKpi] = useState({});
  const [kpiErrors, setKpiErrors] = useState(null)
  const [modalKpiErrors, setModalKpiErrors] = useState(null)

  const [loading, setLoading] = useState(false);

  const [kpiNotes, setKpiNotes] = useState('')
  const [kpiIndex, setKpiIndex] = useState(0);
  const [objectiveIndex, setObjectiveIndex] = useState(0);
  const [monthSelect, setMonthSelect] = useState([])

  const [graphType, setGraphType] = useState("Bar")


  const [kpiData, setKpiData] = useState([
    {
      date: null,
      year: new Date().getFullYear(),
      value: ""
    }
  ]);
  const [editKpiData, setEditKpiData] = useState([
    {
      date: null,
      year: new Date().getFullYear(),
      value: "",
      id: ""
    }
  ])

  const [showNewModal, setShowNewModal] = useState(false)


  let { objectiveId, kpiId } = useParams();

  const [trackDuration, setTrackDuration] = useState("yearly");
  const [dataType, setDataType] = useState("numeric");

  const addMoreKpi = () => {
    let kpiDataTemp = [...kpiData];
    kpiDataTemp.push({
      date: null,
      year: new Date().getFullYear(),
      value: ""
    });
    setKpiData(kpiDataTemp);
  }

  useEffect(() => {
    if (
      isNotEmpty(report?.sustainability_goal_objectives) &&
      report?.sustainability_goal_objectives.length > 0
    ) {
      let idN = 0;
      for (let objItem of report?.sustainability_goal_objectives) {
        let objectTemp = objItem;
        if (objectTemp.id.toString() === objectiveId.toString()) {
          setObjective(objectTemp);
          setObjectiveIndex(idN);
        }
        idN++;
      }
    }
  }, [report]);
  useEffect(() => {

    if (kpi?.track_duration && kpi?.track_duration == 'monthly') {

      setMonthSelect([
        { value: '1', label: 'Jan' },
        { value: '2', label: 'Feb' },
        { value: '3', label: 'Mar' },
        { value: '4', label: 'Apr' },
        { value: '5', label: 'May' },
        { value: '6', label: 'Jun' },
        { value: '7', label: 'Jul' },
        { value: '8', label: 'Aug' },
        { value: '9', label: 'Sep' },
        { value: '10', label: 'Oct' },
        { value: '11', label: 'Nov' },
        { value: '12', label: 'Dec' }
      ])
    }
    if (kpi?.track_duration && kpi?.track_duration == 'quarterly') {

      setMonthSelect([
        { value: '1', label: '1st Quater' },
        { value: '2', label: '2nd Quater' },
        { value: '3', label: '3rd Quater' },
        { value: '4', label: '4th Quater' },
      ])
    }
    if (kpi?.track_duration && kpi?.track_duration == 'biannually') {

      setMonthSelect([
        { value: '1', label: '1st Half' },
        { value: '2', label: '2nd Half' }
      ])
    }
  }, [kpi?.track_duration])

  useEffect(() => {
    if (isNotEmpty(objective?.sustainability_kpis) && objective?.sustainability_kpis.length > 0) {
      let idNx = 0;
      for (let kpiItem of objective?.sustainability_kpis) {
        if (kpiItem.id.toString() === kpiId.toString()) {
          setKpi(kpiItem);
          setKpiIndex(idNx);
        }
        idNx++;
      }
    }
  }, [objective]);

  useEffect(() => {
    if (isNotEmpty(kpi?.track_duration)) {
      setTrackDuration(kpi?.track_duration);
      setDataType(kpi?.data_type);
    }
  }, [kpi]);


  let history = useHistory();

  const toastInitialValues = {
    isOpen: false,
    isSuccess: false,
    isError: false,
    message: ""
  }

  const [toast, setToast] = useState(toastInitialValues);

  const closeToast = () => {
    return setToast((prev) => ({
      ...prev,
      isOpen: false,
      isSuccess: false,
      isError: false,
      message: ""
    }));
  };

  const showError = (errorMsg) => {
    setToast((prev) => ({
      ...prev,
      isOpen: true,
      isError: true,
      isSuccess: false,
      message: errorMsg
    }));
  }

  const showSuccess = (succMsg) => {
    setToast((prev) => ({
      ...prev,
      isOpen: true,
      isError: false,
      isSuccess: true,
      message: succMsg
    }));
  }

  const goToPage = (pageUrl) => {
    history.push(pageUrl);
  }

  const saveKpiType = () => {
    setLoading(true);
    saveKpiDataType({
      id: report?.id,
      kpiId: kpi?.id,
      trackDuration,
      dataType
    }).then(res => {
      setLoading(false);
      if (res.data.success === true) {
        showSuccess("Report has been Saved");
        setReport(res.data.data);
      } else {
        showError(res.data.message);
      }
    }).catch(err => {
      setLoading(false);
      showError("Failed to save Report");
    });
  }

  async function getAllIndices(arr, value) {
    return arr.reduce((indices, element, index) => {
      if (element === value) {
        indices.push(index);
      }
      return indices;
    }, []);
  }

  const validateData = async (kpiGet, show_place = '') => {

    var is_validated = true
    var already_present_month = []
    var already_present = []
    var already_present_id = []
    var errors = []

    var loopdata
    if (show_place == 'modal') {
      loopdata = kpiGet
    }
    else {
      loopdata = kpiData
    }
    for await (const data of kpi?.sustainability_kpi_data) {
      already_present_month.push(data.date)
      already_present.push(data.year)
      already_present_id.push(data?.id)
    }

    for await (const data of loopdata) {
      if ((kpi?.track_duration != 'yearly' && !data.date) || !data.year || !data.value) {
        errors = ["All fields are required!"]
        is_validated = false
        break
      }
      if (kpi?.track_duration != 'yearly') {
        if (already_present_month.includes(data.date)) {

          const indexes = await getAllIndices(already_present_month, data.date)

          // const index = already_present_month.indexOf(data.date)

          for await (const index of indexes) {
            if (already_present[index] == data.year) {
              if (data?.id != already_present_id[index]){
                errors.push(`Entry for ${monthSelect[data.date - 1].label} ${data.year} already exists!`)
                is_validated = false
                break
              }
            }
          }

        }
        already_present_month.push(data.date)
        already_present.push(data.year)
      }
      else {

        if (already_present.includes(`${data.year}`)) {
          const index = already_present.indexOf(`${data.year}`)
          if (data?.id != already_present_id[index]) {
            errors.push(`Entry for ${data.year} already exists!`)
            is_validated = false
          }
        }
        already_present.push(data.year)
      }

      if (kpi?.data_type == 'percentage') {
        if (+data.value < 0 || +data.value > 100) {
          if (kpi?.track_duration != 'yearly')
            errors.push(`Value for ${monthSelect[data.date - 1].label} ${data.year} should be a valid percentage value!`)
          else
            errors.push(`Value for ${data.year} should be a valid percentage value!`)
          is_validated = false
        }
      } else {
        if (+data.value < 0) {
          if (kpi?.track_duration != 'yearly')
            errors.push(`Value for ${monthSelect[data.date - 1].label} ${data.year} should be greater than 0!`)
          else
            errors.push(`Value for ${data.year} should be greater than 0!`)
          is_validated = false
        }
      }

    }

    if (is_validated == false) {
      if (show_place == 'modal')
        setModalKpiErrors(errors)
      else
        setKpiErrors(errors);
    }

    return is_validated

  }


  const saveKpiDataLocal = async () => {
    setKpiErrors(null)
    const validateVal = await validateData(kpi)

    if (validateVal) {
      setLoading(true);
      saveKpiData({
        id: report?.id,
        kpiId: kpi?.id,
        actionType: 'add',
        kpiData,
        notes: kpiNotes
      }).then(res => {
        setLoading(false);
        if (res.data.success === true) {
          showSuccess("KPI Data added");
          setReport(res.data.data);
          setKpiData([])
          setKpiNotes('')
        } else {
          showError(res.data.message);
        }
      }).catch(err => {
        setLoading(false);
        showError("Failed to save KPI data");
      })
    }

  }

  const handleMonthChange = (event, index) => {
    // const newMonthSelect = monthSelect.map(month => {

    //   if (month.value === event.value) {
    //     return { ...month, isDisabled: true };
    //   } else {
    //     return month;
    //   }
    // });


    // if (newMonthSelect[kpiData[index].date - 1]?.isDisabled)
    //   delete newMonthSelect[kpiData[index].date - 1].isDisabled

    const updatedKpiData = [...kpiData];
    updatedKpiData[index].date = event.value;

    // setMonthSelect(newMonthSelect);
    setKpiData(updatedKpiData);
  };

  const handleValueChange = (event, index) => {
    const updatedKpiData = [...kpiData];
    updatedKpiData[index].value = event.target.value;

    setKpiData(updatedKpiData);
  };

  const handleYearChange = (event, index) => {
    const updatedKpiData = [...kpiData];
    updatedKpiData[index].year = event.target.value;

    setKpiData(updatedKpiData);
  };

  const handleKpiDataDelete = (val) => {
    if (window.confirm("Are you sure you want to Delete?")) {
      saveKpiData({
        id: report?.id,
        kpiId: kpi?.id,
        actionType: 'delete',
        dataId: val.id
      }).then(res => {
        setLoading(false);
        if (res.data.success === true) {
          showSuccess("KPI Data Deleted ");
          setReport(res.data.data);
          setKpiData([])
        } else {
          showError(res.data.message);
        }
      }).catch(err => {
        setLoading(false);
        showError("Failed to save KPI data");
      })
    }
  }

  const handleKpiDataEdit = (val) => {
    const data = [{
      date: val?.date,
      year: val?.year,
      value: val?.value,
      id: val?.id
    }]
    setModalKpiErrors(null)
    setEditKpiData(data)
    setShowNewModal(true)
  }

  const handleShowNewModalClose = () => {
    setShowNewModal(false);
  }

  const handleEditKpiData = (e, type1) => {
    const updatedKpiData = [...editKpiData];
    if (type1 == 'date')
      updatedKpiData[0] = { ...editKpiData[0], [type1]: e.value }
    else
      updatedKpiData[0] = { ...editKpiData[0], [type1]: e.target.value }

    setEditKpiData(updatedKpiData);
  }


  const handleEditSave = async () => {
    setModalKpiErrors(null)
    const validateVal = await validateData(editKpiData, 'modal')

    if (validateVal) {
      setLoading(true);
      saveKpiData({
        id: report?.id,
        kpiId: kpi?.id,
        actionType: 'update',
        dataId: editKpiData[0].id,
        date: editKpiData[0].date,
        value: editKpiData[0].value,
        year: editKpiData[0].year,
      }).then(res => {
        setLoading(false);
        if (res.data.success === true) {
          showSuccess("KPI Data Updated");
          setReport(res.data.data);
          setEditKpiData([])
          handleShowNewModalClose()
        } else {
          showError(res.data.message);
        }
      }).catch(err => {
        setLoading(false);
        showError("Failed to save KPI data");
      })
    }

  }


  return (
    <>

      {toast.isOpen && <Toast message={toast.message} isError={toast.isError} isSuccess={toast.isSuccess} closeToast={closeToast} />}

      {
        isNotEmpty(objective?.id) && isNotEmpty(kpi?.id) ? <>

          <div className='main-wrapper'>
            <div className="container">

              <section className='kpi-head-tabs'>
                <div className='back-button'>
                  <button onClick={(e) => goToPage(`/sustainability/objective/${objectiveId}`)}>
                    <AiOutlineArrowLeft /> Back
                  </button>

                </div>
                <div className='heading-objective'>
                  <h2>Track Your KPI</h2>
                </div>
              </section>

              <div className='text-center kpi-top-info'>
                <p><span className="light-color">Objective</span></p>
                <p>{objective?.objective}</p>
                <p className="space-t"><span className="light-color">KPI</span></p>
                <p>{kpi?.kpi}</p>
              </div>

              {
                isNotEmpty(kpi?.track_duration) && isNotEmpty(kpi?.data_type) ?

                  <>
                    {
                      kpi?.sustainability_kpi_data && kpi?.sustainability_kpi_data.length > 0 ?
                        <>
                          <div className="buttons-chart">
                            <button className={graphType == 'Bar' ? 'btn-active' : ''} onClick={e => setGraphType('Bar')}>Bar Graph</button>
                            <button className={graphType == 'LineChart' ? 'btn-active' : ''} onClick={e => setGraphType('LineChart')}>Line Graph</button>
                          </div>
                          <div className="chart-pdd">
                            <KpiChart chartData={kpi?.sustainability_kpi_data} graphType={graphType} kpiData={kpi} monthType={monthSelect} />
                          </div>
                          {
                            kpi?.kpi_notes && (kpi?.kpi_notes != null || kpi?.kpi_notes.trim != '') &&
                            <div>
                              <h2 style={{ color: "grey" }}>Notes</h2>
                              <p>
                                {kpi?.kpi_notes}
                              </p>
                            </div>
                          }

                          <TableContainer sx={{ border: 'none' }} component={Paper}>
                            <Table sx={{ minWidth: 700 }} aria-label="customized table">
                              <TableHead>
                                <TableRow>
                                  <StyledTableCell style={{ backgroundColor: "black", color: "white" }} align="center">Date</StyledTableCell>
                                  <StyledTableCell style={{ backgroundColor: "black", color: "white" }} align="center">Value</StyledTableCell>
                                  <StyledTableCell style={{ backgroundColor: "black", color: "white" }} align="center"></StyledTableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>

                                {
                                  kpi?.sustainability_kpi_data.map((val) => {
                                    return <>
                                      <StyledTableRow className="unsdg-objective unsdg-output-row">
                                        <StyledTableCell align="center" component="th" scope="row">
                                          {`${kpi?.track_duration != 'yearly' ? monthSelect[+val?.date - 1]?.label : ''} ${val?.year}`}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                          {`${val?.value}`}
                                        </StyledTableCell>
                                        <StyledTableCell align="right" style={{ width: '100px' }}>
                                          <button className="table-delete-icon" onClick={e => handleKpiDataDelete(val)}>
                                            <DeleteIcon />
                                          </button>
                                          <button className="table-delete-icon" onClick={e => handleKpiDataEdit(val)}>
                                            <EditIcon />
                                          </button>
                                        </StyledTableCell>
                                      </StyledTableRow>
                                    </>
                                  })
                                }

                              </TableBody >
                            </Table>
                          </TableContainer>
                        </> :

                        <div className='blank-page'>
                          <img src="/assets/images/dashboard-blank.png" />
                          <h2>No Data Yet</h2>
                          <p>Please add data to track your KPI progress</p>
                        </div>
                    }

                    {
                      kpiData.map((kpiItem, index) => {
                        return <>
                          <Grid container spacing={3} className="my-3">

                            <Grid item md={kpi?.track_duration != 'yearly' ? 3 : 6} xs={12}>
                              <div className="form-group sus-form-field">
                                <label>Year</label>
                                <input className="input-big" type="number" onChange={(e) => handleYearChange(e, index)} value={kpiItem.year} placeholder="Year" />
                              </div>

                            </Grid>
                            {kpi?.track_duration != 'yearly' &&
                              <Grid item md={3} xs={12}>
                                <div className="form-group sus-form-field">
                                  <label>Month(s)</label>
                                  {/* <MonthPicker onChange={(e) => handleDateChange(e, index)} /> */}

                                  <div>
                                    <Select onChange={(e) => handleMonthChange(e, index)} value={monthSelect[kpiData.date]} options={monthSelect} placeholder="Month" className="py-3" />
                                  </div>
                                </div>

                              </Grid>
                            }

                            <Grid item md={6} xs={12}>
                              <div className="form-group sus-form-field">
                                <label>Value ({toTitleCase(dataType)})</label>
                                <input type="number" onChange={(e) => handleValueChange(e, index)} value={kpiItem.value} className="form-control" placeholder="Enter Value" />
                              </div>
                            </Grid>

                          </Grid>
                        </>
                      })
                    }

                    <div className="form-group sus-form-field">
                      <a onClick={addMoreKpi} className="add-more-trigger" href={undefined}>
                        <BiPlusCircle />
                        <span>Add More Historic Data</span>
                      </a>
                    </div>

                    <Grid container spacing={3}>
                      <Grid item md={12} xs={12}>
                        <div className="form-group sus-form-field">
                          <label>Add Notes</label>
                          <input type="text" value={kpiNotes} onChange={e => setKpiNotes(e.target.value)} className="form-control" placeholder="Enter your Notes here" />
                        </div>
                      </Grid>
                    </Grid>

                    <div className="form-group sus-form-field">
                      {
                        kpiErrors && kpiErrors.length > 0 &&
                        <div className="kpi-errors">
                          <ul>
                            {kpiErrors.map(error => (
                              <li style={{ color: "red" }}>{error}</li>
                            ))}
                          </ul>
                        </div>
                      }
                      <button onClick={saveKpiDataLocal} className="navy-btn">{loading ? <><CircularProgress color="primary" size={20} /></> : "Save"}</button>
                    </div>

                  </>
                  : <>
                    <Grid container spacing={3}>
                      <Grid item md={6} xs={12}>
                        <div className="form-group sus-form-field">
                          <label>Track KPI</label>
                          <select value={trackDuration} onChange={(e) => setTrackDuration(e?.target?.value)} className="form-control">
                            <option value="yearly">Yearly</option>
                            <option value="monthly">Monthly</option>
                            <option value="quarterly">Quarterly</option>
                            <option value="biannually">Biannually</option>
                          </select>
                        </div>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <div className="form-group sus-form-field">
                          <label>KPI Type</label>
                          <select value={dataType} onChange={(e) => setDataType(e?.target?.value)} className="form-control">
                            <option value="numeric">Numeric</option>
                            <option value="percentage">Percentage</option>
                          </select>
                        </div>
                      </Grid>
                    </Grid>

                    <div className="form-group sus-form-field">
                      <button onClick={saveKpiType} className="navy-btn">{loading ? <><CircularProgress color="primary" size={20} /></> : "Save"}</button>
                    </div>
                  </>
              }

            </div>
          </div>

        </> : null
      }
      <div className="min350">
        <Dialog
          onClose={handleShowNewModalClose}
          fullWidth={true}
          maxWidth='md'
          open={showNewModal}
          className={`theme-base-modal sustainability-layout`}
          keepMounted={false}
          PaperProps={{
            style: { borderRadius: 20, maxHeight: 'calc(100% - 44px)' }
          }}
        >
          <DialogTitle>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span className="capital-case">Edit Value</span>
              <IconButton onClick={handleShowNewModalClose} style={{ marginLeft: 'auto' }}>
                <Close />
              </IconButton>
            </div>
          </DialogTitle>
          <DialogContent dividers >
            <DialogContentText>
              <div className="sus-objective-box">
                <Grid container spacing={3} className="my-3">

                  <Grid item md={kpi?.track_duration != 'yearly' ? 3 : 6} xs={12}>
                    <div className="form-group sus-form-field">
                      <label>Year</label>
                      <input className="input-big" type="number" value={editKpiData[0]?.year} onChange={(e) => handleEditKpiData(e, 'year')} placeholder="Year" />
                    </div>

                  </Grid>
                  {kpi?.track_duration != 'yearly' &&
                    <Grid item md={3} xs={12}>
                      <div className="form-group sus-form-field">
                        <label>Month(s)</label>
                        {/* <MonthPicker onChange={(e) => handleEditKpiData(e)} /> */}

                        <div>
                          <Select styles={defaultStyles} onChange={(e) => handleEditKpiData(e, 'date')} value={monthSelect[editKpiData[0]?.date - 1]} options={monthSelect} placeholder="Month" className="py-3" />
                        </div>
                      </div>

                    </Grid>
                  }

                  <Grid item md={6} xs={12}>
                    <div className="form-group sus-form-field">
                      <label>Value ({toTitleCase(dataType)})</label>
                      <input type="number" onChange={(e) => handleEditKpiData(e, 'value')} value={editKpiData[0]?.value} className="form-control" placeholder="Enter Value" />
                    </div>
                  </Grid>

                </Grid>

                <div className="form-group sus-form-field">
                  {
                    modalKpiErrors && modalKpiErrors.length > 0 &&
                    <div className="kpi-errors">
                      <ul>
                        {modalKpiErrors.map(error => (
                          <li style={{ color: "red" }}>{error}</li>
                        ))}
                      </ul>
                    </div>
                  }
                  <button onClick={e => handleEditSave()} className="btn-sustainability primary mt-35px">Save</button>
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>

    </>
  );
};

export default TrackKPI;