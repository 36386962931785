import logo from "../static/images/logo.png";
import { fetchclientDocuments, uploadclientDocument, downloadDocument } from '../services/admin.service'
import React, { } from "react";
import { Link, useHistory, Redirect } from "react-router-dom";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import CloudDownload from '@material-ui/icons/CloudDownload';

import {
  Box,
  makeStyles,
  Typography,
  TextField,
  Divider,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button

} from "@material-ui/core";
import { useState } from "react";
import { useCorporateState } from "../redux/context/corporate-context";
import { CorporateStatus } from "../constants";
import { useAuth } from "../hooks";


const useStyles = makeStyles((theme) => {
  return {
    table: {
      minWidth: 650,
    },
    root: {
      minHeight: "100vh",
      backgroundColor: "transparent",
    },
    logo: {
      marginTop: theme.spacing(5),
      height: "50px",
      borderRadius: "0px",
      marginLeft: theme.spacing(8),
    },
    verifyText: {
      fontSize: "18px",
      fontWeight: 600,
      textDecoration: "none",
      fontFamily: "Poppins",
      lineHeight: "27px",
      color: "#66CFA4",
    },
    innerContainer: {
      marginTop: theme.spacing(7),
      marginBottom: theme.spacing(1),
      minHeight: "40px",
    },
    divider: {
      borderColor: "#E5E5E5",
      height: "477px",
      width: "1px",
      borderWidth: "1px",
    },
    verify: {
      color: "#262727",
      fontSize: 30,
      fontWeight: 600,
      fontFamily: "Poppins",
      lineHeight: "27px",
      textAlign: "center",
      marginBottom: "2rem"
    },
    loginText: {
      fontSize: "18px",
      textDecoration: "none",
      fontWeight: 600,
      fontFamily: "Poppins",
      lineHeight: "27px",
      color: "#262727",
    },
  };
});

const CorporateNotActive = () => {
  const classes = useStyles();

  const corporateState = useCorporateState();
  const { removeUser, user } = useAuth();

  const history = useHistory();

  const logout = () => {
    removeUser();
    history.replace("/login");
  };

  return (
    <div
      className="App"
      style={{
        height: "150vh",
        width: "100%"
      }}
    >

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginRight: "137px",
        }}
      >
        <img src={logo} alt="TruDoc" className={classes.logo} />

        <Box display="flex" alignItems="center" justifyContent="center">
          <Typography onClick={logout} className={classes.verifyText + ' cursor-pointer'}>Logout</Typography>
        </Box>

      </div>

      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        padding="10rem"
        className={classes.innerContainer}
      >
        {
          corporateState?.status == CorporateStatus.AWAITING_APPROVAL ? <>
            <Typography className={classes.verify}>Your Corporate is not approved yet, kindly wait for it to get approved.</Typography>
          </> : null
        }

        {
          corporateState?.status == CorporateStatus.REJECTED ? <>
            <Typography className={classes.verify}>Your Corporate is rejected, kindly contact support for further assistance.</Typography>
          </> : null
        }

        {
          corporateState?.status == CorporateStatus.ACTIVE ? <>
            <Redirect to="/login" />
          </> : null
        }


      </Box>

    </div >
  );
}



export default CorporateNotActive;
