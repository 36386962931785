import { useState, forwardRef, useEffect } from 'react';
import { Slide, Typography, makeStyles, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, CircularProgress, IconButton } from '@material-ui/core';
import { registerDomain } from "../../../services/api.service";
import AddDomainStep1 from './AddDomain.step1';
import AddDomainStep2 from './AddDomain.step2';
import AddDomainStep3 from './AddDomain.step3';
import AddDomainStep4Queued from './AddDomain.step4Queued';    //tushar 10 jan
import { useAuth, useLocalStorage } from '../../../hooks';
import { Cancel } from '@material-ui/icons';
import { MessageConstants } from '../../../utils/MessageConstants';
import Toast from '../../../utils/Toast';
import { useSocketContext } from '../../../services/socket';
import { isNotEmpty, parseWeb3Error } from "./../../../utils/Generic";
import MetaMaskTransaction from '../../MetaMaskTransaction';
import { useMetaMask } from 'metamask-react';
const Web3 = require('web3');

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="top" ref={ref} {...props} />;
});


const useStyles = makeStyles((theme) => {
  return {
    dialogRoot: {
      width: '50%',
      borderRadius: 50,
      margin: '0 auto',
    },
    contentHeight: {
      minHeight: '250px',
      maxWidth: '100%',
      margin: '0 auto',
      overflowX: 'hidden'
    },
    title: {
      paddingTop: 20,
      textAlign: 'center',
      fontWeight: '600',
      textTransform: 'uppercase',
      letterSpacing: 0.5,
      wordSpacing: 2,
    },
    stepText: {
      fontWeight: '600',
      paddingBottom: 30,
      textAlign: 'center',
      color: theme.palette.admin.main,
      letterSpacing: 1,
    },
    actionBar: {
      justifyContent: 'center',
      marginBottom: 50
    },
    nextButton: {
      width: 220,
      height: 50,
      borderRadius: 25,
      color: '#fff',
      letterSpacing: 1,
      backgroundColor: theme.palette.admin.main,
      '&:hover': {
        backgroundColor: theme.palette.admin.main,
      }
    },
    draftButton: {
      width: 220,
      height: 50,
      borderRadius: 25,
      letterSpacing: 1,
      backgroundColor: '#fff',
      color: "#6a6a6a",
      border: '1px solid gray',
      '&:hover': {
        backgroundColor: "#fff",
        color: '#363636',
        border: '1px solid #464646'
      }
    }
    ,
    closeIcon: {
      position: 'absolute',
      right: '0',
      padding: '10px'
    }

  }
});

const AddDomain = ({ open, handleClose }) => {


  const toastInitialValues = {
    isOpen: false,
    isSuccess: false,
    isError: false,
    message: ""
  }

  const [toast, setToast] = useState(toastInitialValues);


  const classes = useStyles();
  const [step, setStep] = useState(1);
  const [selected, setSelected] = useState('auto');
  const [domainName, setDomainName] = useState('');
  const [loading, setLoading] = useState(false);
  const [dnsRecords, setDnsRecords] = useState([]);
  const [dnsRecordsQueue, setDnsRecordsQueue] = useState();    //tushar 10 jan
  const { user } = useAuth();

  const [walletAddr, setWalletAddr] = useState(null);
  const [metaMaskTransactionDetails, setMetaMaskTransactionDetails] = useState(null);

  const { status, connect, account, chainId, ethereum } = useMetaMask();

  const [configureLoadingText, setConfigureLoadingText] = useState(null);
  const [configureSettingLoading, setConfigureSettingLoading] = useState(false);

  const [openMetaMaskModal, setOpenMetaMaskModal] = useState(false);

  const socket = useSocketContext();
  const { getItem } = useLocalStorage();

  useEffect(() => {
    const userId = getItem("userId");
    socket.emit('info', { userId });
  }, [])

  const closeToast = () => {
    return setToast((prev) => ({
      ...prev,
      isOpen: false,
      isSuccess: false,
      isError: false,
      message: ""
    }));
  };

  const popupCloseHandler = () => {
    handleClose();
    setStep(1);
    setDnsRecords([]);
    setDomainName("");
  }

  const resetMetaMaskStates = () => {
    setLoading(false);
    setConfigureLoadingText(null);
    setConfigureSettingLoading(false);
    setMetaMaskTransactionDetails(null);
  }

  const showError = (err) => {
    setConfigureSettingLoading(false);
    let errorMsg = err?.message;
    if (typeof err == "string") {
      errorMsg = err;
    }
    setToast((prev) => ({
      ...prev,
      isOpen: true,
      isError: true,
      isSuccess: false,
      message: errorMsg
    }));
  }

  const registerWithMetaMask = () => {
    let responseData = metaMaskTransactionDetails;
    let data = responseData.data;
    const requiredWalletAddr = data.walletAddress;

    const web3Instance = new Web3(ethereum);

    const docStoreAbi = JSON.parse(data?.documentStore?.abi);
    const docStoreBytecode = data?.documentStore?.byteCode;

    let docStoreContract = new web3Instance.eth.Contract(docStoreAbi);

    setConfigureSettingLoading(true);
    setConfigureLoadingText("Deploying Document Store...");

    docStoreContract.deploy({
      data: docStoreBytecode,
      arguments: ["TruDoc Document Store"]
    })
      .send({
        from: requiredWalletAddr
      })
      .on('receipt', function (receipt) {
        let documentStoreContractAddress = receipt.contractAddress;

        const tokenRegistryAbi = JSON.parse(data?.tokenRegistry?.abi);
        const tokenRegistryBytecode = data?.tokenRegistry?.byteCode;

        let tokenRegistryContract = new web3Instance.eth.Contract(tokenRegistryAbi);

        setConfigureLoadingText("Deploying Token Registry...");

        tokenRegistryContract.deploy({
          data: tokenRegistryBytecode,
          arguments: ["Trudoc Token Registry.", "TKN"]
        })
          .send({
            from: requiredWalletAddr
          })
          .on('receipt', function (receipt) {
            let tokenRegistryContractAddress = receipt.contractAddress;
            console.table({ documentStoreContractAddress, tokenRegistryContractAddress });
            sendRegisterDomainCall({
              documentStoreContractAddress,
              tokenRegistryContractAddress
            });
          })
          .on('error', function (error, receipt) {
            showError(parseWeb3Error(error));
          });
      })
      .on('error', function (error, receipt) {
        showError(parseWeb3Error(error));
      });

  }

  const sendRegisterDomainCall = (contracts = null) => {
    setLoading(true);

    registerDomain({
      domainName,
      email: user.email,
      contracts
    }).then(function (response) {

      if (response.status === 200) {
        let responseData = response.data;
        if (isNotEmpty(responseData?.externalWallet) && responseData?.externalWallet) {

          let data = responseData.data;
          const requiredWalletAddr = data.walletAddress;

          setWalletAddr(requiredWalletAddr);
          setOpenMetaMaskModal(true);
          setMetaMaskTransactionDetails(responseData);

        } else {
          resetMetaMaskStates();
          setOpenMetaMaskModal(false);
          setMetaMaskTransactionDetails(null);

          if (isNotEmpty(responseData?.dnsRecords) && responseData?.dnsRecords) {
            setLoading(false);
            setStep(3);
            setDnsRecords(responseData?.dnsRecords);
          } else {
            setLoading(false);
            setDnsRecordsQueue(response.message);
            setStep(4);
          }
        }
      } else {
        setLoading(false);
        let msg = MessageConstants.FAILED_TO_ADD_DOMAIN;
        if (response.status === 400)
          msg = response?.data?.message;
        if (response.status === 409)
          msg = MessageConstants.DOMAIN_ALREADY_EXIST;
        setLoading(false);
        setToast((prev) => ({
          ...prev,
          isOpen: true,
          isError: true,
          message: msg
        }));
      }
    })
      .catch(function (e) {
        const Error = JSON.parse(e.message);
        let msg = MessageConstants.FAILED_TO_ADD_DOMAIN;
        if (Error.status === 400)
          msg = Error.message;
        if (Error.status === 409)
          msg = MessageConstants.DOMAIN_ALREADY_EXIST;
        setToast((prev) => ({
          ...prev,
          isOpen: true,
          isError: true,
          message: msg
        }));
        setLoading(false);
      });
  }

  const handleNext = () => {
    if (step === 1) {
      setStep(2);
    } else if (step === 3) {
      setStep(1);
      setDnsRecords([]);
      setDomainName("");
      popupCloseHandler();
    } else {
      resetMetaMaskStates();
      sendRegisterDomainCall();
    }
  }

  const closeMetaMaskModal = (e) => {
    setOpenMetaMaskModal(false);
    setLoading(false);
  }

  const startMetaMaskTransaction = (e) => {
    registerWithMetaMask();
  }

  return (
    <>
      {toast.isOpen && <Toast message={toast.message} isError={toast.isError} isSuccess={toast.isSuccess} closeToast={closeToast} />}
      <Dialog
        fullWidth={true}
        maxWidth='md'
        open={open}
        onClose={popupCloseHandler}
        className={classes.dialogRoot}
        keepMounted={false}
        TransitionComponent={Transition}
        PaperProps={{
          style: { borderRadius: 20 }
        }}
      >
        <div className={classes.closeIcon}>
          <IconButton
            onClick={popupCloseHandler}
          >
            <Cancel />
          </IconButton>
        </div>

        <DialogTitle>
          <Typography variant='h5' className={classes.title}>
            Create New Signature Domain
          </Typography>
        </DialogTitle>
        <DialogContent className={classes.contentHeight}>
          {/* <DialogContentText className={classes.stepText}>Step {step}/2</DialogContentText> */}
          {step === 1 && <AddDomainStep1 domainName={domainName} setDomainName={setDomainName} />}

          {step === 2 && <AddDomainStep2 selected={selected} setSelected={setSelected} />}

          {step === 3 && <AddDomainStep3 dnsRecords={dnsRecords} />}

          {step === 4 && <AddDomainStep4Queued dnsRecords={dnsRecordsQueue} />}

        </DialogContent>
        <DialogActions className={classes.actionBar} gutterBottom>
          {/* {step !== 1 &&
          <Button className={classes.draftButton}>
            <Typography variant='h6'>SAVE AS DRAFT</Typography>
          </Button>
        } */}
          {
            loading
              ? <Button className={classes.nextButton}>
                <CircularProgress size={30} color="inherit" />
              </Button>
              : <Button className={classes.nextButton} onClick={handleNext}>
                <Typography variant='h6'>{step === 3 ? "Ok" : "Add"}</Typography>
              </Button>
          }
          <Button className={classes.draftButton} onClick={popupCloseHandler}>
            <Typography variant='h6'>Cancel</Typography>
          </Button>
        </DialogActions>
      </Dialog>

      <MetaMaskTransaction
        open={openMetaMaskModal}
        walletAddress={walletAddr}
        transactionStarted={configureSettingLoading}
        transactionProgressText={configureLoadingText}

        handleClose={closeMetaMaskModal}
        handleStart={startMetaMaskTransaction}
      />

    </>
  );
}

export default AddDomain;