import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControl,
  Grid,
  LinearProgress,
  Snackbar,
  Tab,
  Tabs,
  TextField,
  Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Toast from "../../utils/Toast";
import { useSocketContext } from './../../services/socket';
import { GroupAddOutlined, ChatOutlined, Add, Send } from "@material-ui/icons";
import { Alert, Autocomplete, createFilterOptions, TabContext, TabPanel } from "@material-ui/lab";
import { getCorporateMembers } from "../../services/api.service";
import { isNotEmpty, isValidEmailAddress } from "../../utils/Generic";
import { acceptChat, getMessages, list, newChat, newGroup, newMessage, sendAttachmentMessage, deleteChat } from "../../services/chat.service";
import ChatProfileBox from "../../components/chat/ChatProfileBox";
import { useAuth, useLocalStorage } from "./../../hooks";
import ChatMessage from "../../components/chat/ChatMessage";
import { useHistory, useParams } from "react-router-dom";
import ChatDetailsBox from "../../components/chat/ChatDetailsBox";
import { needToAcceptChat } from "../../utils/chat";
import DocumentViewer from "../../components/documents/DocumentViewer";
import { getDocumentPreview } from "../../services/dms.service";
import { haveViewer } from "../../utils/document";

const useStyles = makeStyles((theme) => ({
  root: {
    width: `calc(100% - ${300}px)`,
    marginLeft: 280,
    minHeight: "88vh",
  },
}));

const usersFilter = createFilterOptions();

const Chat = () => {

  let history = useHistory();
  let { channelId } = useParams();

  const classes = useStyles();
  const socket = useSocketContext();
  const { user } = useAuth();

  const chatParent = useRef(null);
  const uploadInputRef = useRef(null);
  const downloadElemRef = useRef(null);

  const [filesLoading, setFilesLoading] = useState(true);

  const toastInitialValues = {
    isOpen: false,
    isSuccess: false,
    isError: false,
    message: ""
  }

  const [toast, setToast] = useState(toastInitialValues);

  const closeToast = () => {
    return setToast((prev) => ({
      ...prev,
      isOpen: false,
      isSuccess: false,
      isError: false,
      message: ""
    }));
  };

  const showError = (errorMsg) => {
    setToast((prev) => ({
      ...prev,
      isOpen: true,
      isError: true,
      isSuccess: false,
      message: errorMsg
    }));
  }

  const showSuccess = (succMsg) => {
    setToast((prev) => ({
      ...prev,
      isOpen: true,
      isError: false,
      isSuccess: true,
      message: succMsg
    }));
  }

  const [activeChat, setActiveChat] = useState(null);

  const [defaultUsers, setDefaultUsers] = useState([]);

  const [chats, setChats] = useState([]);
  const [chatsLoading, setChatsLoading] = useState(false);
  const [chatDeleted,setChatDeleted] = useState(false);

  const [chatsAttachmentSending, setChatsAttachmentSending] = useState(false);

  const [showChatDetails, setShowChatDetails] = useState(false);

  const [messagesLoading, setMessagesLoading] = useState(false);

  const [hasMoreMessages, setHasMoreMessages] = useState(true);
  const [loadingMoreMessages, setLoadingMoreMessages] = useState(true);

  const [messages, setMessages] = useState([]);

  const [message, setMessage] = useState('');

  const [searchQuery, setSearchQuery] = useState('');

  const [newGroupName, setNewGroupName] = useState('');
  const [createGroupLoading, setCreateGroupLoading] = useState(false);
  const [openNewGroupPrompt, setOpenNewGroupPrompt] = useState(false);

  const [newChatUserEmail, setNewChatUserEmail] = useState('');
  const [newChatCreateLoading, setNewChatCreateLoading] = useState(false);
  const [openNewChatPrompt, setOpenNewChatPrompt] = useState(false);

  const [uploadFileData, setUploadFileData] = useState(null);

  const [selectedDocument, setSelectedDocument] = useState(null);
  const [openPdfViewer, setOpenPdfViewer] = useState(false);

  const [downloadedDocument, setDownloadedDocument] = useState(null);
  const [downloadUrl, setDownloadUrl] = useState(null);

  const [progress, setProgress] = useState(false);
  const [progressText, setProgressText] = useState('');

  const { getItem } = useLocalStorage();

  const userId = getItem("userId");

  useEffect(() => {
    document.title = `TruDoc | Chat`;
    getDefaultSignatories();
  }, []);

  useEffect(() => {
    listChats();
  }, [searchQuery]);

  useEffect(() => {
    // console.log(messages);   
  }, [messages]);

  const getDefaultSignatories = () => {
    getCorporateMembers()
      .then((res) => {
        let data = res.data.data;
        setDefaultUsers(data);
      })
      .catch((err) => {
        // console.log(err.message);
      });
  };

  const sendMessage = () => {
    if (isNotEmpty(message)) {
      const __createdtime__ = new Date();
      socket.emit('send_message', {
        username: user?.email,
        room: activeChat?.uuid,
        message,
        __createdtime__
      });
      setMessage('');
    } else {
      showError("Message should not be empty");
    }
  }

  const markMessageRead = (room, readMessageTime) => {
    socket.emit('message_read', {
      room: room,
      time: readMessageTime
    });
  }

  useEffect(() => {
    socket.on('receive_message', (data) => {
      setMessages((state) => [
        ...state,
        data,
      ]);
      scrollChatToBottom();
      markMessageRead(data?.room, data?.__createdtime__);
    });

    socket.on('new_message', (data) => {
      listChats(false);
    });

    socket.on('user_joined', (data) => {
      listChats(false);
    });

    socket.on('user_removed', (data) => {
      listChats(false);
    });

    return () => {
      socket.off('receive_message');
      socket.off('new_message');
      socket.off('user_joined');
      socket.off('user_removed');
    }
  }, [socket]);

  const listChats = (showLoading = true) => {
    if (showLoading) {
      setChatsLoading(true);
    }
    list({
      query: searchQuery
    }).then((res) => {
      let chatsData = res?.data?.data;
      if (isNotEmpty(chatsData)) {
        setChats(chatsData);
      }
      setChatsLoading(false);
    }).catch((err) => {
      setChatsLoading(false);
    });
  }

  const loadMoreMessages = () => {
    loadMessages(activeChat?.uuid, false);
  }

  const loadMessages = (chatUuid, showLoading = true) => {
    if (isNotEmpty(chatUuid)) {
      if (showLoading) {
        setMessagesLoading(true);
      }
      setLoadingMoreMessages(true);
      let lastMessageId = null;
      if (messages.length > 0) {
        lastMessageId = messages[0]?.id;
      }
      getMessages({
        chatId: chatUuid,
        lastMessageId: lastMessageId
      }).then((res) => {
        let data = res?.data?.data;
        if (data.length > 0) {
          setHasMoreMessages(true);
          setMessages((state) => [
            ...data,
            ...state
          ]);
          markMessageRead(chatUuid, new Date());
        } else {
          setHasMoreMessages(false);
        }
        setMessagesLoading(false);
        if (showLoading) {
          scrollChatToBottom();
        }
        setLoadingMoreMessages(false);
      }).catch((err) => {
        setMessagesLoading(false);
        setLoadingMoreMessages(false);
      });
    }
  }

  const startNewChat = () => {
    if (isNotEmpty(newChatUserEmail)) {
      setNewChatCreateLoading(true);
      newChat({
        email: newChatUserEmail
      }).then((res) => {
        let data = res?.data;
        if (data?.success.toString() === "true") {
          setNewChatUserEmail(null);
          listChats();
          setOpenNewChatPrompt(false);
          changeActiveChat(data?.data);
        } else {
          showError(data?.message);
        }
        setNewChatCreateLoading(false);
      }).catch((err) => {
        setNewChatCreateLoading(false);
      });
    }
  }

  const createNewGroup = () => {
    if (isNotEmpty(newGroupName)) {
      setCreateGroupLoading(true);
      newGroup({
        name: newGroupName
      }).then((res) => {
        let data = res?.data;
        if (data?.success.toString() === "true") {
          setNewGroupName('');
          listChats();
          setOpenNewGroupPrompt(false);
          changeActiveChat(data?.data);
        } else {
          showError(data?.message);
        }
        setCreateGroupLoading(false);
      }).catch((err) => {
        setCreateGroupLoading(false);
      });
    }
  }

  const changeActiveChat = (chat) => {
    // pageOpenChat(chat?.uuid);
  }

  const pageOpenChat = (chatUuid) => {
    history.push("/chat/" + chatUuid);
  }

  const openChat = (chat) => {
    pageOpenChat(chat?.uuid);
  }

  const acceptChatClick = (chat) => {
    setNewChatCreateLoading(true);
    acceptChat({
      chatId: chat?.uuid
    }).then((res) => {
      let data = res?.data;
      if (data?.success.toString() === "true") {
        setNewChatUserEmail(null);
        listChats();
        setOpenNewChatPrompt(false);
      } else {
        showError(data?.message);
      }
      setNewChatCreateLoading(false);
    }).catch((err) => {
      setNewChatCreateLoading(false);
    });
  }

  

  const handleChatDelete = (chatData) => {
    setNewChatCreateLoading(true);
    deleteChat({
      chat_uuid: chatData?.uuid,
    }).then((res) => {
      let data = res?.data;
      if (data?.success.toString() === "true") {
        history.push("/chat/list");
        listChats();
      }else{
        showError(data?.message)
        setNewChatCreateLoading(false);
      }
    }).catch(err => {
      setNewChatCreateLoading(false);
    })
  }

  const scrollChatToBottom = () => {
    const domNode = chatParent.current;
    if (domNode) {
      domNode.scrollTop = domNode.scrollHeight;
    }
  }

  const handleKeyPress = (e) => {
    if (e.key.toString().toLowerCase() === 'enter') {
      sendMessage();
    }
  }

  useEffect(() => {
    if (chats.length > 0) {
      if (channelId != "list") {
        if (activeChat?.uuid != channelId) {

          if (isNotEmpty(activeChat)) {
            socket.emit('leave_room', {
              room: activeChat?.uuid
            });
          }

          setActiveChat(null);
          setMessages([]);

          setChats(
            chats.map(item =>
              item.uuid === channelId
                ? { ...item, total_unread_messages: 0 }
                : item
            ));
          let chatSelected = chats.find(item => item.uuid === channelId);
          setActiveChat(chatSelected);
          socket.emit('join_room', {
            room: channelId
          });
        }

      } else {
        setActiveChat(null);
        setMessages([]);
      }
    }
  }, [channelId, chats]);

  useEffect(() => {
    if (isNotEmpty(activeChat)) {
      loadMessages(activeChat?.uuid);
    }
  }, [activeChat]);

  const uploadFileTrigger = () => {
    uploadInputRef.current.click();
  }

  const handleUploadFileChange = (event) => {
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      return;
    }
    setUploadFileData(fileObj);
  };

  useEffect(() => {
    doUploadFile();
  }, [uploadFileData]);

  const doUploadFile = (replaceUploadFile = null) => {
    if (uploadFileData != null) {
      // console.log(uploadFileData);
      setFilesLoading(true);
      setChatsAttachmentSending(true);
      uploadInputRef.current.value = '';
      setUploadFileData(null);
      sendAttachmentMessage({
        file: uploadFileData,
        chatId: activeChat?.uuid,
        overwrite: replaceUploadFile
      }).then(res => {
        res = res.data;
        if (res.success) {
          console.log("file done");
        } else {
          showError(res?.error);
        }
        setChatsAttachmentSending(false);
        setFilesLoading(false);
      }).catch(err => {
        setChatsAttachmentSending(false);
        setFilesLoading(false);
      });
    }
  }

  const handleOnView = (e) => {
    if (haveViewer(e?.file_type, e?.name)) {
      setOpenPdfViewer(true);
      setSelectedDocument(e);
    } else {
      setToast((prev) => ({
        ...prev,
        isOpen: true,
        isError: true,
        message: 'File viewer is not available hence file will be downloaded'
      }));
      handleOnDownload(e);
    }
  }

  const handleOnDownload = (e) => {
    setProgress(true);
    setProgressText('Downloading ' + e.name);
    setDownloadedDocument(e);
    getDocumentPreview({
      documentId: e?.id,
      requestType: 'download'
    }).then((res) => {
      setProgress(false);
      let fileData = res?.data?.fileData;
      if (isNotEmpty(fileData)) {
        let fileDataRaw = fileData?.data;

        let downloadUrl = `data:${fileData?.mimetype};base64,${fileDataRaw}`;

        if (fileData?.mimetype == "wrapped") {
          fileData.mimetype = "text/json";
          downloadUrl = `data:${fileData?.mimetype};charset=utf-8,` + encodeURIComponent(fileDataRaw);
        }

        //console.log(downloadUrl);  
        setDownloadUrl(downloadUrl);
      }
    }).catch(err => {
      console.log(err);
      setProgress(false);
    });
  }

  useEffect(() => {
    if (downloadUrl != null) {
      downloadElemRef.current.click();
      setTimeout((e) => {
        setDownloadUrl(null);
      }, 2500);
    }
  }, [downloadUrl]);

  return (
    <>

      {toast.isOpen && <Toast message={toast.message} isError={toast.isError} isSuccess={toast.isSuccess} closeToast={closeToast} />}

      <div className="file-upload-input d-none">
        <input onChange={handleUploadFileChange} ref={uploadInputRef} type="file" name="file" id="file-upload-input-elem" />
      </div>

      <a target="_blank" className="d-none" filename={downloadedDocument?.name} ref={downloadElemRef} href={downloadUrl} download={downloadedDocument?.name}>Download File</a>

      <Box className={classes.root}>

        <Grid
          className="chat-wrapper-main"
          container
          direction="row"
          alignItems="center"
          spacing={3}
        >

          <Grid item xs={12}>

            <Grid
              className="chat-wrapper"
              container
              direction="row"
              alignItems="center"
            // spacing={3}
            >

              <Grid item xs={3} className="chat-left-panel">

                <div className="chat-left-head">

                  <div className="chat-head-option">
                    <Typography variant="h6">Messages</Typography>
                    <div className="chat-head-actions">
                      <a onClick={(e) => setOpenNewChatPrompt(true)} href={undefined}><ChatOutlined /></a>
                      <a onClick={(e) => setOpenNewGroupPrompt(true)} href={undefined}><GroupAddOutlined /></a>
                    </div>
                  </div>

                  <FormControl fullWidth variant="outlined" className="chat-left-panel-search">
                    <TextField
                      fullWidth
                      label="Search People/Group"
                      variant="outlined"
                      color="primary"
                      value={searchQuery}
                      onChange={(event) => setSearchQuery(event.target.value)}
                    />
                  </FormControl>
                </div>


                {
                  chatsLoading ? <>
                    <Grid item xs={12} className="chat-peoples-empty text-center">
                      <CircularProgress color="primary" size={32} />
                    </Grid>
                  </> : <>
                    {
                      (chats != null && chats.length > 0) ? <>
                        <div className="chat-peoples">
                          {chats.map((chatItem) => {
                            return <>
                              <ChatProfileBox activeChat={activeChat} showAction={true} handleAcceptChat={(e) => acceptChatClick(chatItem)} handleOnClick={(e) => openChat(chatItem)} chat={chatItem} deleteChat={handleChatDelete} showDelete={false} />
                            </>
                          }
                          )}
                        </div>
                      </> : <>
                        <Grid item xs={12} className="chat-peoples-empty">
                          <h6>No Chats Found</h6>
                        </Grid>
                      </>
                    }
                  </>
                }

              </Grid>

              <Grid item xs={9} className="chat-panel">

                {
                  isNotEmpty(activeChat) ? <>
                    <Grid item xs={12} className="chat-panel-header chat-panel-header-with-detete-btn" >
                      <ChatProfileBox handleOnClick={(e) => setShowChatDetails(true)} chat={activeChat} deleteChat={handleChatDelete} showDelete={true} />
                    </Grid>

                    <Grid item xs={12} className={`chat-message-box ${showChatDetails ? 'show-details' : ''}`}>

                      <Box className="chat-message-area">

                        <Grid item xs={12} className="chat-messages-box" ref={chatParent}>

                          {
                            hasMoreMessages ? <>
                              <div className="load-more-box-t">
                                {
                                  loadingMoreMessages ? <>
                                    <CircularProgress color="primary" size={20} />
                                  </> : <>
                                    <a className="load-more-chats" onClick={loadMoreMessages}>Load More</a>
                                  </>
                                }
                              </div>
                            </> : null
                          }

                          {messages.map((messageItem) => {
                            return <>
                              <ChatMessage onView={((e) => handleOnView(e))} username={user?.email} message={messageItem} />
                            </>
                          }
                          )}

                        </Grid>

                        <Grid item xs={12} className="chat-message-form">
                          {
                            needToAcceptChat(activeChat) ? <>
                              <p>Please click accept first only then you will be able to send messages</p>
                            </> : <>
                              {
                                chatsAttachmentSending ? <>
                                  <a href={undefined}>
                                    <CircularProgress color="primary" size={15} />
                                  </a>
                                </> : <>
                                  <a onClick={uploadFileTrigger} href={undefined}><Add /></a>
                                </>
                              }
                              <FormControl fullWidth variant="outlined" className="chat-left-panel-search">
                                <TextField
                                  onKeyUp={handleKeyPress}
                                  fullWidth
                                  value={message}
                                  onChange={(event) => setMessage(event.target.value)}
                                  label="Enter Message"
                                  variant="outlined"
                                  color="primary"
                                />
                              </FormControl>
                              <a onClick={sendMessage} href={undefined}><Send /></a>
                            </>
                          }
                        </Grid>

                      </Box>

                      <Box className="chat-details">

                        <ChatDetailsBox onView={((e) => handleOnView(e))} onClosed={(e) => setShowChatDetails(false)} defaultUsers={defaultUsers} chat={activeChat} />

                      </Box>

                    </Grid>
                  </> :
                    <>
                      <Grid item xs={12} className="chat-panel-empty">
                        <ChatOutlined />
                        <h6>No Chat Selected</h6>
                        <p>Please select a person or group from chat area to view and send messages</p>
                      </Grid>
                    </>
                }

              </Grid>

            </Grid>

          </Grid>

        </Grid>

      </Box >


      <Dialog
        fullWidth={true}
        maxWidth='sm'
        open={openNewChatPrompt}
        className={classes.dialogRoot}
        keepMounted={false}
        PaperProps={{
          style: { borderRadius: 20, maxHeight: 'calc(100% - 44px)' }
        }}
      >

        <DialogContent className={classes.contentHeight}>

          <Typography variant='h6' className="confirmModalTitle theme-form-group">
            Enter Email Address
          </Typography>

          <Box className="w-100">

            <Autocomplete
              style={{ width: "100%" }}
              value={newChatUserEmail}
              onChange={(event, newValue) => {
                if (typeof newValue === 'string') {
                  setNewChatUserEmail(newValue);;
                } else if (newValue && newValue.inputValue) {
                  let userVal = newValue.inputValue;
                  if (isValidEmailAddress(userVal)) {
                    setNewChatUserEmail(userVal);
                  } else {
                    setToast((prev) => ({
                      ...prev,
                      isOpen: true,
                      isError: true,
                      message: 'Email address is not valid'
                    }));
                  }
                } else {
                  setNewChatUserEmail(newValue?.email);
                }
              }}
              filterOptions={(options, params) => {
                const filtered = usersFilter(options, params);
                if (params.inputValue !== '') {
                  filtered.push({
                    inputValue: params.inputValue,
                    email: `Add "${params.inputValue}"`,
                  });
                }
                return filtered;
              }}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              id="free-solo-with-text-demo"
              options={defaultUsers}
              getOptionLabel={(option) => {
                if (typeof option === 'string') {
                  return option;
                }
                if (option.inputValue) {
                  return option.inputValue;
                }
                return option.email;
              }}
              renderOption={(option) => option.email}
              freeSolo
              renderInput={(params) => (
                <TextField {...params} label="Search/Add User Email" variant="outlined" />
              )}
            />

            <Box className="w-100 mt-15px text-center">

              {newChatCreateLoading ?
                <>
                  <CircularProgress color="primary" size={20} />
                </>
                :
                <>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={e => startNewChat()}
                    className={`theme-btn theme-form-group me-10px`}
                  >
                    Save
                  </Button>

                  <Button
                    variant="contained"
                    color="default"
                    onClick={e => setOpenNewChatPrompt(false)}
                    className={`theme-btn theme-form-group secondary`}
                  >
                    Cancel
                  </Button>
                </>
              }
            </Box>

          </Box>

        </DialogContent>
      </Dialog>


      <Dialog
        fullWidth={true}
        maxWidth='sm'
        open={openNewGroupPrompt}
        className={classes.dialogRoot}
        keepMounted={false}
        PaperProps={{
          style: { borderRadius: 20, maxHeight: 'calc(100% - 44px)' }
        }}
      >

        <DialogContent className={classes.contentHeight}>

          <Typography variant='h6' className="confirmModalTitle theme-form-group">
            Enter Group Name
          </Typography>

          <Box className="w-100">

            <TextField
              id="groupName"
              label="Group Name"
              variant="outlined"
              value={newGroupName}
              onChange={(event) => setNewGroupName(event.target.value)}
              className={classes.input + ' w-100 theme-form-group'}
            />

            <Box className="w-100 text-center">

              {createGroupLoading ?
                <>
                  <CircularProgress color="primary" size={20} />
                </>
                :
                <>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={e => createNewGroup()}
                    className={`theme-btn theme-form-group me-10px`}
                  >
                    Save
                  </Button>

                  <Button
                    variant="contained"
                    color="default"
                    onClick={e => setOpenNewGroupPrompt(false)}
                    className={`theme-btn theme-form-group secondary`}
                  >
                    Cancel
                  </Button>
                </>
              }
            </Box>

          </Box>

        </DialogContent>
      </Dialog>

      <Dialog
        fullWidth={true}
        maxWidth='lg'
        open={openPdfViewer}
        className={classes.dialogRoot + ' doc-viewer-modal'}
        PaperProps={{
          style: { borderRadius: 20, maxHeight: 'calc(100% - 44px)', height: '80vh' }
        }}
        onClose={e => setOpenPdfViewer(false)}
      >

        <DialogContent className={classes.contentHeight}>

          <DocumentViewer data={selectedDocument} />

        </DialogContent>
      </Dialog>

      {progress && <Snackbar
        open={progress}
        autoHideDuration={null}
        onClose={() => { }}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={() => setProgress(false)} severity="info">
          {progressText}
          <LinearProgress className={classes.progress} />
        </Alert>
      </Snackbar>}


    </>
  );
};

export default Chat; 
