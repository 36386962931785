import { useState, Fragment, forwardRef, useEffect } from 'react';
import { Slide, Typography, makeStyles, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, CircularProgress } from '@material-ui/core';
import { getPdf } from "../../services/api.service";
import { LoaderSpinner } from "@govtechsg/tradetrust-ui-components";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { signDoc, downloadTt } from '../../services/api.service';
import { useAuth } from '../../hooks';
import { getDocuments } from "../../services/other.service";
import { sidePanelFilterMapping } from '../../constants';
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import SidePanelAction from "../../redux/actions/Sidepanel.action";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from '@material-ui/lab/Alert';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="top" ref={ref} {...props} />;
});
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => {
  return {
    dialogRoot: {
      width: '75%',
      borderRadius: 50,
      margin: '0 auto',
    },
    loader: {
      position: "absolute",
      left: "50%",
      top: "50%"
    },
    // modalTitleFlex:{
    //   display:"flex",
    //   justifyContent:"space-between"
    // },
    contentHeight: {
      minWidth: '100%',
      minHeight: '350px',
      maxWidth: '100%',
      margin: '0 auto'
    },
    title: {
      paddingTop: 20,
      textAlign: 'center',
      fontWeight: '600',
      textTransform: 'uppercase',
      letterSpacing: 0.5,
      wordSpacing: 2,
    },
    stepText: {
      fontWeight: '600',
      paddingBottom: 30,
      textAlign: 'center',
      color: theme.palette.admin.main,
      letterSpacing: 1,
    },
    actionBar: {
      justifyContent: 'center',
      marginBottom: 50
    },
    nextButton: {
      width: 220,
      height: 50,
      borderRadius: 25,
      color: '#fff',
      letterSpacing: 1,
      backgroundColor: theme.palette.admin.main,
      '&:hover': {
        backgroundColor: theme.palette.admin.main,
      }
    },
    draftButton: {
      width: 220,
      height: 50,
      borderRadius: 25,
      color: '#fff',
      letterSpacing: 1,
      backgroundColor: 'gray',
      '&:hover': {
        backgroundColor: 'gray',
      }
    },
    // titleRed:{
    //   color:"red"
    // },
    // titleGreen:{
    //   color:"green"
    // }
  }
});

const SimpleDocumentViewer = (props) => {

  const [data, setData] = useState({});
  const [basePdf, setBasePdf] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [loading, setLoading] = useState();
  const [showToast, setShowToast] = useState();

  const handleToastClose = () => {
    setShowToast('');  
  }

  useEffect(() => {
    if (data?.name !== undefined) {
      getPdf({
        filename: data.storage_key,
        edocs: data?.e_document,
        docId: data?.id
      })
        .then((res) => {
          setBasePdf(res.data.data.data ? `data:application/pdf;base64,${res.data.data.data}` : res.data.data);
          setIsLoading(false);
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
  }, [data]);

  const classes = useStyles();

  useEffect(() => {
    setData(props?.data);
  }, [props]);

  const handleCloseClick = () => {
    props.handleClose();
  }

  return (
    <Fragment>
      <Dialog
        fullWidth={true}
        maxWidth='md'
        open={props?.open}
        onClose={handleCloseClick}
        className={classes.dialogRoot}
        keepMounted={false}
        TransitionComponent={Transition}
        PaperProps={{
          style: { borderRadius: 20 }
        }}
      >
        <DialogContent className={classes.contentHeight} fullWidth={true} fullHeight={true} maxWidth={"xl"} maxHeight={"xl"}>
          {/* Show The PDF here */}
          {isLoading ? <CircularProgress color="primary" size={20} className={classes.loader} /> :
            <iframe src={`${basePdf}`} height="500px" width="95%"></iframe>}
        </DialogContent>
      </Dialog>
    </Fragment>
  );
}

export default SimpleDocumentViewer;