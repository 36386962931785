import { useState, useEffect, forwardRef } from "react";
import {
  Slide,
  Grid,
  Typography,
  InputAdornment,
  withStyles,
  TextField,
  makeStyles,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@material-ui/core";
import { Cancel } from "@material-ui/icons";
import { useSelector } from "react-redux";
import LoaderButton from "../utils/Loader/LoaderButton";
import Toast from "../utils/Toast";
import { MessageConstants } from '../utils/MessageConstants';
import { forgotPassword } from "../services/auth.service";
import MultipleCorporateModal from "../components/auth/MultipleCorporateModal";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="top" ref={ref} {...props} />;
});

const CssTextField = withStyles({
  root: {
    display: "block",
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#66CFA4",
      },
      "&:hover fieldset": {
        borderColor: "#66CFA4",
      },
      borderRadius: 50,
    },
  },
})(TextField);

const useStyles = makeStyles((theme) => {
  return {
    dialogRoot: {
      width: "700px",
      borderRadius: 50,
      margin: "0 auto",
    },
    contentHeight: {
      minHeight: "35px",
      maxWidth: "100%",
      margin: "0 auto",
    },
    title: {
      paddingTop: 20,
      textAlign: "center",
      fontWeight: "600",
      textTransform: "uppercase",
      letterSpacing: 0.5,
      wordSpacing: 2,
      paddingBottom: 10,
    },
    stepText: {
      fontWeight: "600",
      paddingBottom: 30,
      textAlign: "center",
      color: theme.palette.admin.main,
      letterSpacing: 1,
    },
    actionBar: {
      justifyContent: "center",
      marginBottom: 20,
    },
    nextButton: {
      width: 220,
      height: 50,
      borderRadius: 25,
      color: "#fff",
      letterSpacing: 1,
      backgroundColor: theme.palette.admin.main,
      "&:hover": {
        backgroundColor: theme.palette.admin.main,
      },
    },
    draftButton: {
      width: 220,
      height: 50,
      borderRadius: 25,
      letterSpacing: 1,
      backgroundColor: "#fff",
      color: "#6a6a6a",
      border: "1px solid gray",
      "&:hover": {
        backgroundColor: "#fff",
        color: "#363636",
        border: "1px solid #464646",
      },
    },
    closeIcon: {
      position: "absolute",
      right: "0",
      padding: "10px",
    },
    button: {
      width: 230,
      height: 50,
      borderRadius: 25,
      letterSpacing: 1,
      color: "#fff",
    },
    buttonText: {
      fontWeight: "bold",
      fontSize: 16,
      letterSpacing: "0.1em",
      color: "#FFFDFD",
    },
    field: {
      width: "32vw",
      marginTop: 20,
      marginBottom: 20,
      borderRadius: "50px 50px 50px 50px",
      color: theme.palette.secondary.main,
    },
    textFieldInput: {
      color: "#222",
    },
    detailWrap: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      marginLeft: "20px",
    },
  };
});

const ForgotPasswordModal = ({ open, handleClose }) => {
  const toastInitialValues = {
    isOpen: false,
    isSuccess: false,
    isError: false,
    message: ""
  }
  const classes = useStyles();
  const [toast, setToast] = useState(toastInitialValues);
  const isLoading = useSelector((state) => state.buttonLoading);
  const [state, setState] = useState({email: ""});
  const [selectedMail, setSelectedMail] = useState("")
  const [openDialog, setOpenDialog] = useState(false);
  const [corporateData, setCorporateData] = useState([]);
  const [clearSelectValue, setClearSelectValue] = useState(false);

  const setData = (e) => {
    setState((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const submitHandler = () => {
    const obj = {
      email: state.email
    }
    setSelectedMail(state.email)
    forgotPassword(obj).then((res) => {
      if (res && res.status === 200) {
        if(res?.data?.message === MessageConstants.MULTIPLE_CORPORATE){
          setCorporateData(res?.data?.corporates);
          setOpenDialog(true);
        }
        else {
          setState({...state, email: ""});
          setToast((prev) => ({
            ...prev,
            isOpen: true,
            isSuccess: true,
            message: MessageConstants.EMAIL_SEND_SUCCESS
          }));
        }
        handleClose();
      }
      }).catch(e => {
        const Error = JSON.parse(e.message);
          let msg = MessageConstants.ERROR_OCCURED;
          if (Error.status === 400)
            msg = Error.message;
          setToast((prev) => ({
            ...prev,
            isOpen: true,
            isError: true,
            message: msg
          }));
        })
  }

  useEffect(() => {
    if (!open) {
      setState({
        email: ""
      });
    }
  }, [open]);

  const closeToast = () => {
    return setToast((prev) => ({
      ...prev,
      isOpen: false,
      isSuccess: false,
      isError: false,
      message: ""
    }));
  };

  const enableSubmit = () => {
    return String(state.email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const MultipleCorporateHandler = (data) =>{
    if(data){
      const obj = {
        email: selectedMail,
        corporate: data
      }
      forgotPassword(obj).then(res => {
      if (res && res.status === 200) {
        setOpenDialog(false);
        setClearSelectValue(!clearSelectValue);
        setState({...state, email: ""});
        setToast((prev) => ({
          ...prev,
          isOpen: true,
          isSuccess: true,
          message: MessageConstants.EMAIL_SEND_SUCCESS
        }));
        handleClose();
      }
    }).catch(e => {
      const Error = JSON.parse(e.message);
        let msg = MessageConstants.ERROR_OCCURED;
        if (Error.status === 400)
          msg = Error.message;
        setToast((prev) => ({
          ...prev,
          isOpen: true,
          isError: true,
          message: msg
        }));
      })
    }
  }


  return (
    <>
    {toast.isOpen && <Toast message={toast.message} isError={toast.isError} isSuccess={toast.isSuccess} closeToast={closeToast} />}

    <MultipleCorporateModal 
      open={openDialog} 
      corporateData={corporateData} 
      handleClose={() => setOpenDialog(false)}
      handleSubmit={MultipleCorporateHandler}
      clearSelectValue={clearSelectValue}
      title="Select Corporate you want to reset password"
    />

    <Dialog
      fullWidth={true}
      maxWidth="md"
      open={open}
      onClose={handleClose}
      className={classes.dialogRoot}
      keepMounted={false}
      TransitionComponent={Transition}
      PaperProps={{
        style: { borderRadius: 20 },
      }}
    >
      <div className={classes.closeIcon}>
        <IconButton onClick={handleClose}>
          <Cancel />
        </IconButton>
      </div>
      <DialogTitle gutterBottom>
        <Typography variant="h5" className={classes.title}>
          Forgot Password
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.contentHeight}>
        <Grid item xs={8}>
          <form onSubmit={e => { e.preventDefault(); submitHandler()}}>
          <CssTextField
              type="text"
              label="Email"
              name="email"
              value={state.email || ""}
              className={classes.field}
              onChange={(e) => setData(e)}
              variant="outlined"
              color="primary"
              required
              fullWidth
              InputLabelProps={{ className: classes.textFieldInput }}
            />
          </form>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.actionBar} gutterBottom>
        <LoaderButton
          type="submit"
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={submitHandler}
          disabled={!enableSubmit()}
          isLoading={isLoading}
          text="Submit"
        />
        <Button className={classes.draftButton} onClick={handleClose}>
          <Typography variant="h6">{"Cancel"}</Typography>
        </Button>
      </DialogActions>
    </Dialog>
    </>
  );
};

export default ForgotPasswordModal;
