import React, { useRef, useEffect, useState } from 'react';
import WebViewer from '@pdftron/webviewer';
import { useAuth } from '../../hooks';
import "./Viewer.css";
import { useSelector } from 'react-redux';
import Toast from '../Toast';
import { MessageConstants } from '../MessageConstants';
import { annotationId } from '../../redux/selector/viewer.selector';
import { pdfBase64ToBlob } from '../Generic';

const ViewPdf = (props) => {
  const toastInitialValues = {
    isOpen: false,
    isSuccess: false,
    isError: false,
    message: ""
  }
  const [instance, setInstance] = useState(null);
  const [applySignature, setApplySignature] = useState(false);
  const { user } = useAuth();
  const viewer = useRef(null);
  const [toast, setToast] = useState(toastInitialValues);
  const userAnnots = useSelector(annotationId);

  const closeToast = () => {
    return setToast((prev) => ({
      ...prev,
      isOpen: false,
      isSuccess: false,
      isError: false,
      message: ""
    }));
  };

  useEffect(() => {
    WebViewer(
      {
        path: '/webviewer/lib',
        css: '/style.css',
        disabledElements: [
          "ribbons",
          "toggleNotesButton",
          "searchButton",
          // "menuButton", // show download button
          "settingsButton",
          "rubberStampToolGroupButton",
          "stampToolGroupButton",
          "fileAttachmentToolGroupButton",
          "calloutToolGroupButton",
          // "undo",
          // "redo",
          "eraserToolButton",
          // "toolsHeader",
          "signatureToolGroupButton", 
          "leftPanelButton",
          "viewControlsButton",
          "selectToolButton",
          "annotationPopup",
          // "toolsOverlay",
          "defaultSignatureDeleteButton",
          "contextMenuPopup",
          "signatureModal"
        ], 
      },
      viewer.current,
    ).then((instance) => {
      setInstance(instance);
      setApplySignature(true);
      instance.UI.disableTools([ 'AnnotationCreateSticky', 'AnnotationCreateFreeText' ]);
      const { documentViewer } = instance.Core;
      const annotationManager = documentViewer.getAnnotationManager();
      annotationManager.setReadOnly(props.isReadOnly);
      annotationManager.setCurrentUser(user.username);
      instance.UI.loadDocument(pdfBase64ToBlob(props.file), { filename: 'myfile.pdf' });        
      annotationManager.addEventListener('annotationChanged', (annotations, action) => {
        annotations.forEach((annot) => { // remove dragging, resizing, rotation on annotation
          if(!userAnnots.includes(annot.Id) && annot.Subject === 'Widget'){ // hide annotation if not belong to logged in user
            annot.Hidden = true;
          }
          annot.Listable = false;
        });
      });
      });
  }, []);

  useEffect(() => { // Add saved signature and initial
    if(applySignature){
      //not needed as this is readonly
    }
  }, [applySignature]);

  const uploadAfterSigning = async () => {
    const { docViewer, annotManager } = instance;
    const doc = docViewer.getDocument();
    const xfdfString = await annotManager.exportAnnotations({
      widgets: true,
      fields: true,
    })
    const data = await doc.getFileData({ xfdfString });
    const arr = new Uint8Array(data);
    const blob = new Blob([arr], { type: "application/pdf" });
    props.handleSignDocument(blob);
  };
    
  useEffect(()=>{
    if(props.saveFile){
      uploadAfterSigning();
    }
  },[props.saveFile])

  return (
    <>
      {toast.isOpen && <Toast message={toast.message} isError={toast.isError} isSuccess={toast.isSuccess} closeToast={closeToast} />}
      <div className="webviewer" ref={viewer}></div>
    </>
  );
};

export default ViewPdf;