import { useEffect } from "react";
import { Switch, Route, withRouter, Redirect, useLocation } from "react-router-dom";
import { createTheme, ThemeProvider } from "@material-ui/core";
import Login from "./views/Login";
import Verify from "./views/Verify/Verify";
import ClientUpload from "./views/client-upload/Client-upload";
import { useAuth } from "./hooks";
import ProtectedRoute from "./Routes/ProtectedRoute";
import AuthenticatedRoute from "./Routes/AuthenticatedRoute";
import Domains from "./views/admin/Domains";
import AdminContacts from "./views/admin/Contacts";
import AdminCustomers from "./views/admin/Customers";
import AdminDashboard from "./views/admin/Dashboard";
import SignUp from "./views/SignUp";
import Register from "./views/Register";
import ResetPassword from "./views/ResetPassword";
import Settings from "./views/admin/Settings";
import CorporateNotActive from "./views/CorporateNotActive";
import ViewDocument from "./views/ViewDocument";
import VerifyDocumentByToken from "./views/Verify/VerifyDocumentByToken";
import Terms from "./views/site/Terms";

import './styles/css/fonts.css';

const theme = createTheme({
  typography: {
    fontFamily: "Poppins",
  },
  palette: {
    primary: {
      main: "#66CFA4",
    },
    secondary: {
      main: "#FFFDFD",
    },
    admin: {
      main: "#BF85FA",
    },
  },
});

function App() {

  const location = useLocation();
  const { getUser } = useAuth();

  useEffect(() => {
    getUser();
  }, []);

  useEffect(() => {
    document.body.setAttribute("data-page", location.pathname);
  }, [location]);

  return (
    <ThemeProvider theme={theme}>


      <Switch>

        <Route exact path={["/terms-conditions"]}>
          <Terms />
        </Route>

        <Route exact path={["/login"]}>
          <Login />
        </Route>

        <Route exact path={["/signUp"]}>
          <SignUp />
        </Route>

        <Route exact path={["/register"]}>
          <Register />
        </Route>

        <Route exact path={["/auth/user/resetPassword"]}>
          <ResetPassword />
        </Route>

        <Route exact path="/verify-doc/:documentId">
          <Verify />
        </Route>

        <Route exact path="/doc-view/:id/:share_id">
          <ViewDocument />
        </Route>

        <Route exact path="/verify-doc">
          <Verify />
        </Route>

        <Route exact path="/verify/:token">
          <VerifyDocumentByToken />
        </Route>

        <Route exact path="/client-upload">
          <ClientUpload />
        </Route>

        <Route exact path="/corporate-inactive">
          <CorporateNotActive />
        </Route>

        <ProtectedRoute
          exact
          path="/admin/domains"
          component={Domains}
        />

        <ProtectedRoute
          exact
          path="/admin/settings"
          component={Settings}
        />

        <ProtectedRoute
          exact
          path={["/admin/dashboard", "/admin"]}
          component={AdminDashboard}
        />

        <ProtectedRoute
          exact
          path="/admin/contacts"
          component={AdminContacts}
        />

        <ProtectedRoute
          exact
          path="/admin/customers"
          component={AdminCustomers}
        />

        <AuthenticatedRoute />

        <Redirect to="/login" />

        {/* <Route exact path="*">
          <h1>Not Found</h1>
        </Route> */}

      </Switch>

    </ThemeProvider>
  );
}

export default withRouter(App);
