import React from 'react'
import { Checkbox, FormControlLabel, Box, MenuItem, Select, InputBase, FormControl, Grid, TextField, Typography, makeStyles, withStyles, Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      padding: 10
    },
    contentHeight: {
      minHeight: '500px'
    },
    field: {
      borderRadius: 10,
      color: theme.palette.admin.main,
    },
    fieldDisabled: {
      opacity: '.8',
      color: 'gray !important',
    },
    textFieldInput: {
      color: theme.palette.admin.main,
    },
    button: {
      color: theme.palette.admin.main,
    },
    subHeading: {
      fontSize: 17,
      fontWeight: '500',
      textTransform: 'uppercase',
    },
    gridItem: {
      padding: 10
    }
  }
});

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 10,
    position: 'relative',
    backgroundColor: '#fff',
    border: '1px solid #BF85FA',
    fontSize: 18,
    padding: '18px 14px',
    color: theme.palette.admin.main,
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      backgroundColor: '#fff',
      borderColor: theme.palette.admin.main,
      color: theme.palette.admin.main,
      borderRadius: 10,
    },
  },
}))(InputBase);

const GreenCheckbox = withStyles((theme) => ({
  root: {
    color: theme.palette.admin.main,
    '&$checked': {
      color: theme.palette.admin.main,
    },
  },
  checked: {},
}))((props) => <Checkbox color="default" {...props} />);

const CssTextField = withStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      borderColor: "#cccccc",
      color: "#333333",
      "& fieldset": {
        color: "#ccc",
        borderColor: "#ccc",
      },
      "&:focus": {
        color: "#66D0A4",
        borderColor: "#66D0A4",
      },
      "&:hover fieldset": {
        borderColor: "#66D0A4",
      },
      borderRadius: 10,
    },
  },
})(TextField);

const ViewEditForm = ({ handleChange, state, popupId }) => {
  const classes = useStyles();
  console.log(state);
  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      spacing={2}
    >
      <Grid
        container
        direction="row"
        alignItems="center"
        className={classes.root}
        spacing={2}
      >
        <Grid item xs={3} align="right">
          <Typography className={classes.subHeading}>Name*</Typography>
        </Grid>
        <Grid item xs={8} align="center">
          <CssTextField
            className={classes.field}
            variant="outlined"
            fullWidth
            InputLabelProps={{ className: classes.textFieldInput }}
            InputProps={{ className: classes.textFieldInput }}
            value={state.full_name}
            onChange={e => handleChange('full_name', e.target.value)}
          />
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        alignItems="center"
        className={classes.root}
        spacing={2}
      >
        <Grid item xs={3} align="right">
          <Typography className={classes.subHeading}>Email Id*</Typography>
        </Grid>
        <Grid item xs={8} align="center">
          <CssTextField
            className={`${classes.field} ${popupId === 1 ? classes.fieldDisabled : ''}`}
            variant="outlined"
            fullWidth
            disabled={popupId === 1 ? true : false}
            InputLabelProps={{ className: classes.textFieldInput }}
            InputProps={{ className: classes.textFieldInput }}
            value={state.email}
            onChange={e => handleChange('email', e.target.value)}
          />
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        alignItems="center"
        className={classes.root}
        spacing={2}
      >
        <Grid item xs={3} align="right">
          <Typography className={classes.subHeading}>Address*</Typography>
        </Grid>
        <Grid item xs={8} align="center">
          <CssTextField
            className={classes.field}
            variant="outlined"
            fullWidth
            multiline
            rows={6}
            InputLabelProps={{ className: classes.textFieldInput }}
            InputProps={{ className: classes.textFieldInput }}
            value={state.address}
            onChange={e => handleChange('address', e.target.value)}
          />
        </Grid>
      </Grid>



    </Grid>
  )
}

export default ViewEditForm;
