export const TOGGLE_MODAL = 'TOGGLE_MODAL';
export const ADD_AUTH = 'ADD_AUTH';
export const LOADING = 'LOADING';
export const BUTTON_LOADING = 'BUTTON_LOADING';

export const SELECTED_CONTACT = 'SELECTED_CONTACT';
export const ALL_CONTACTS = 'ALL_CONTACTS';

export const SELECTED_CUSTOMER = 'SELECTED_CUSTOMER';
export const ALL_CUSTOMERS = 'ALL_CUSTOMERS';

export const AWAITING_MY_SIGN = 'AWAITING_MY_SIGN';
export const AWAITING_OTHER_SIGN = 'AWAITING_OTHER_SIGN';
export const ISSUED = 'ISSUED';
export const REJECTED = 'REJECTED';
export const EXPIRY = 'EXPIRY';
export const MY_WALLET = 'MY_WALLET';
export const GET_ALL_DOMAIN = 'GET_ALL_DOMAIN';
export const GET_WALLET_DETAILS = 'GET_WALLET_DETAILS';
export const SIGNUP_USER_SUCCESS = 'SIGNUP_SUCCESS';
export const FETCH_USER_SIGNATURE = 'FETCH_USER_SIGNATURE';
export const RESET_FETCH_USER_SIGNATURE = 'RESET_FETCH_USER_SIGNATURE';
export const SELECT_VIEW_DOCUMENT = 'SELECT_VIEW_DOCUMENT';
export const REMOVE_WALLET_DETAILS = 'REMOVE_WALLET_DETAILS';
