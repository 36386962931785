import axios from "axios";
import store from "../redux/store";
import { ToggleLoading } from "../redux/actions";
import { API_URL } from "./services.constant";
import ButtonLoading from "../redux/actions/ButtonLoading.action";

axios.interceptors.request.use((req) => {
  const data = store.getState().user;
  if (data) {
    // if (
    //   req &&
    //   req.url &&
    //   (req.url.includes("/api/corporate/") ||
    //     req.url.includes("/api/users/signatories"))
    // ) {
    //   req.headers.authorization = `Bearer ${data.relam}`;
    // } else {
    //   req.headers.authorization = `Bearer ${data.token}`;
    // }
    req.headers.authorization = `Bearer ${data.token}`;
  }
  return req;
});

axios.interceptors.response.use(
  response => response,
  error => errorHandler(error)
);

const errorHandler = (error) => {
  if (error.response.status === 401) {
    localStorage.clear();
    return window.location.href = '/login'
  }
  return Promise.reject({ response: error.response });
};

const apiCall = (
  reqUrl,
  method = "GET",
  payload = null,
  formData = null,
  queryParams = null,
  contentType = "application/json",
  headers = null
) => {
  store.dispatch(ToggleLoading());
  const params = {
    url: `${API_URL}${reqUrl}`,
    method,
    headers: {
      "Content-Type": contentType,
      ...(headers || {})
    },
  };
  if (payload) {
    params.data = JSON.stringify(payload);
  }
  if (formData) {
    params.data = formData;
  }
  if (queryParams) {
    params.params = queryParams;
  }  
  store.dispatch(ButtonLoading());
  return axios(params)
    .then((response) => {
      store.dispatch(ToggleLoading());
      store.dispatch(ButtonLoading());
      return response;
    })
    .catch((err) => {
      store.dispatch(ToggleLoading());
      store.dispatch(ButtonLoading());
      return err?.response;
    })
};

export const userApiCall = (
  reqUrl,
  method = "GET",
  payload = null,
  formData = null,
  queryParams = null,
  contentType = "application/json"
) => {
  store.dispatch(ToggleLoading());
  const params = {
    url: `${API_URL}${reqUrl}`,
    method,
    headers: {
      "Content-Type": contentType,
    },
  };
  if (payload) {
    params.data = JSON.stringify(payload);
  }
  if (formData) {
    params.data = formData;
  }
  if (queryParams) {
    params.params = queryParams;
  }
  store.dispatch(ButtonLoading());
  return axios(params)
    .then((response) => {
      if (response.status !== 200) {
        throw { response: { data: response.data } };
      }
      store.dispatch(ToggleLoading());
      store.dispatch(ButtonLoading());
      return response;
    })
    .catch((err) => {
      store.dispatch(ToggleLoading());
      store.dispatch(ButtonLoading());
      throw new Error(JSON.stringify(err?.response?.data));
      // return err?.response;
    })
};

export default apiCall;
