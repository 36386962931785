import { Dialog, DialogContent, DialogContentText, DialogTitle, IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import React, { useState } from "react";
import { useEffect } from "react";
import { AiFillCheckSquare, AiFillPlusCircle } from 'react-icons/ai';
import { BsFillInfoCircleFill } from 'react-icons/bs'
import Toast from "../../utils/Toast";
import { UNSDG_GOALS } from "../../utils/unsdg-goals";
import { isNotEmpty } from "./../../utils/Generic";

const GoalBoxSubjects = (props) => {

    const [showAddSubjectModal, setShowAddSubjectModal] = useState(false);
    const [goalDetails, setGoalDetails] = useState({});
    const [currentGoal, setCurrentGoal] = useState({});
    const [subjects, setSubjects] = useState([]);
    const [newSubject, setNewSubject] = useState("");

    const { goals, goal, canSave } = props;

    const [canShow, setCanShow] = useState(false);

    const toastInitialValues = {
        isOpen: false,
        isSuccess: false,
        isError: false,
        message: ""
    }

    const [toast, setToast] = useState(toastInitialValues);

    const closeToast = () => {
        return setToast((prev) => ({
            ...prev,
            isOpen: false,
            isSuccess: false,
            isError: false,
            message: ""
        }));
    };

    const showError = (errorMsg) => {
        setToast((prev) => ({
            ...prev,
            isOpen: true,
            isError: true,
            isSuccess: false,
            message: errorMsg
        }));
    }

    useEffect(() => {
        let tempGoalDetails = UNSDG_GOALS[goal];
        setGoalDetails(tempGoalDetails);
        let tempSubjects = [...subjects];

        if (canSave) {
            if (tempGoalDetails?.subjects) {
                for (let itemData of tempGoalDetails?.subjects) {
                    if (!tempSubjects.includes(itemData)) {
                        tempSubjects.push(itemData);
                    }
                }
            }
        }

        if (goals.hasOwnProperty(goal)) {
            let tempCurrentGoal = goals[goal];
            setCurrentGoal(tempCurrentGoal);
            if (tempCurrentGoal?.subjects) {
                let goalObjects = Object.keys(tempCurrentGoal?.subjects);
                for (let goalObject of goalObjects) {
                    if (!tempSubjects.includes(goalObject)) {
                        tempSubjects.push(goalObject);
                    }
                }
            }
        }

        setSubjects(tempSubjects);
    }, [props]);

    useEffect(() => {
        if (subjects.length > 0) {
            setCanShow(true);
        } else {

        }
    }, [subjects]);

    const handleAdapt = (subject) => {
        props?.onAdapt(goal, goalDetails, subject);
    }

    const isSelected = (subject) => {
        if (isNotEmpty(currentGoal?.subjects)) {
            if (currentGoal?.subjects.hasOwnProperty(subject)) {
                return true;
            }
        }
        return false;
    }

    const handleCloseModal = () => {
        setNewSubject("");
        setShowAddSubjectModal(false);
    }

    const addNewSubject = () => {
        let tempSubjects = [...subjects];
        if (isNotEmpty(newSubject)) {
            if (!tempSubjects.includes(newSubject)) {
                tempSubjects.push(newSubject);
                setSubjects(tempSubjects);
                handleCloseModal();
            } else {
                showError("Material Topic already exist");
            }
        } else {
            showError("Material Topic should not be empty");
        }
    }

    return (
        <>

            {toast.isOpen && <Toast message={toast.message} isError={toast.isError} isSuccess={toast.isSuccess} closeToast={closeToast} />}

            {
                canShow ? <>

                    <div className='unsdg-data-box' data-subjects={subjects.length}>
                        <div className='left-side'>
                            <img src={`/assets/images/sdg-icons/E-WEB-Goal-${props?.goal}.png`} />
                            <div>
                                <h4>SDG {props?.goal} <span>{isNotEmpty(currentGoal?.subjects) ? Object.keys(currentGoal?.subjects).length : 0} Selected</span> </h4>
                                <p>{goalDetails?.title}</p>
                            </div>
                        </div>
                        <div className='right-side'>
                            {
                                canSave ? <>
                                    <button onClick={(e) => setShowAddSubjectModal(true)} className='add-tag'> <AiFillPlusCircle /> Add Material Topic</button>
                                </> : null
                            }
                            {
                                subjects.length > 0 ? <>
                                    {
                                        subjects.map((subject, index) => {
                                            return <>
                                                <a onClick={(e) => handleAdapt(subject)} key={subject + index} className={`goal-tag-option ${isSelected(subject) ? 'active' : ''}`} href={undefined}>{subject}</a>
                                            </>
                                        })
                                    }
                                </> : null
                            }
                        </div>
                    </div>

                </> : null
            }

            <Dialog
                onClose={handleCloseModal}
                fullWidth={true}
                maxWidth='md'
                open={showAddSubjectModal}
                className={`theme-base-modal`}
                keepMounted={false}
                PaperProps={{
                    style: { borderRadius: 20, maxHeight: 'calc(100% - 44px)' }
                }}
            >
                <DialogTitle>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        Add Material Topic
                        <IconButton onClick={handleCloseModal} style={{ marginLeft: 'auto' }}>
                            <Close />
                        </IconButton>
                    </div>
                </DialogTitle>
                <DialogContent dividers >
                    <DialogContentText>
                        <div className="sus-objective-box">
                            <input className='sus-objective-field' onChange={(e) => setNewSubject(e?.target?.value)} placeholder='Enter New Material Topic' value={newSubject} />
                            <button onClick={addNewSubject} className="btn-sustainability primary mt-10px">Save</button>
                        </div>
                    </DialogContentText>
                </DialogContent>
            </Dialog>

        </>
    );
};

export default GoalBoxSubjects;