import { Box, CircularProgress, Grid, makeStyles, Paper, StepLabel } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { fetchSustainabilityDetails, getCorporateDetails } from "../../../services/api.service";
import { useAuth } from "../../../hooks";
import { btoaToBlobUrl, isNotEmpty, printTime, printUnderScoreText } from "../../../utils/Generic";
import { DoneAll, InsertLink, Visibility } from "@material-ui/icons";
import Toast from "../../../utils/Toast";
import { useHistory, useParams } from "react-router-dom";
import GoogleFormResponse from "../../../components/sustainability/google-form-response";

const useStyles = makeStyles((theme) => {
  return {
    paper: {
      padding: theme.spacing(1),
      margin: theme.spacing(2),
      backgroundColor: 'transparent',
    },
    content: {
      display: "block",
      width: `calc(100% - ${300}px)`,
      marginLeft: 280,
      minHeight: "88vh",
    },
  };
});

function CorporateDetails() {

  const history = useHistory();

  let { id } = useParams();

  const toastInitialValues = {
    isOpen: false,
    isSuccess: false,
    isError: false,
    message: ""
  }

  const [toast, setToast] = useState(toastInitialValues);

  const closeToast = () => {
    return setToast((prev) => ({
      ...prev,
      isOpen: false,
      isSuccess: false,
      isError: false,
      message: ""
    }));
  };

  const showError = (errorMsg) => {
    setToast((prev) => ({
      ...prev,
      isOpen: true,
      isError: true,
      isSuccess: false,
      message: errorMsg
    }));
  }

  const showSuccess = (succMsg) => {
    setToast((prev) => ({
      ...prev,
      isOpen: true,
      isError: false,
      isSuccess: true,
      message: succMsg
    }));
  }

  const classes = useStyles();
  const { user } = useAuth();

  const [corporate, setCorporate] = useState({});
  const [sustLoading, setSustLoading] = useState(false);

  const [corporateLoading, setCorporateLoading] = useState(true);

  const [sustainabilityData, setSustainabilityData] = useState([]);

  useEffect(() => {
    fetchCorporateDetails();
  }, [id]);

  const fetchCorporateDetails = () => {
    setCorporateLoading(true);

    getCorporateDetails({ id })
      .then((res) => {
        setCorporate(res.data.data);

        let formData = res.data.data?.sustainability_form_data;
        if (isNotEmpty(formData)) {
          setSustainabilityData(JSON.parse(formData));
        } else {
          setSustainabilityData([]);
        }

        setCorporateLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
        setCorporateLoading(false);
      });
  }

  useEffect(() => {
    if (isNotEmpty(corporate?.id)) {
      document.title = `TruDoc | ${corporate?.name}`;
    } else {
      document.title = `TruDoc | Corporate Details`;
    }
  }, [corporate]);

  const getSustainabilityDetails = () => {
    setSustLoading(true);
    fetchSustainabilityDetails({ id }).then((res) => {
      let formData = res?.data?.data;
      if (isNotEmpty(formData)) {
        setSustainabilityData(formData);
      } else {
        setSustainabilityData([]);
      }
      setSustLoading(false);
    }).catch((err) => {
      setSustLoading(false);
    });
  }

  return (
    <Box className={classes.content}>

      {toast.isOpen && <Toast message={toast.message} isError={toast.isError} isSuccess={toast.isSuccess} closeToast={closeToast} />}

      <Grid
        container
        className="d-block mt-15px mb-15px"
        spacing={0}
      >
        <Grid item xs={12}>
          <h4 className="dashboard-title-header">{isNotEmpty(corporate?.id) ? corporate?.name : 'Corporate Details'}</h4>
        </Grid>
        <Grid item xs={12}>
          {
            corporateLoading ? <>
              <Box width="100%" className="text-center py-50px">
                <CircularProgress color="primary" size={50} />
              </Box>
            </> : <>

              <Grid
                container
                className="d-block mt-15px mb-15px"
                spacing={0}
              >

                <Grid item xs={12}>
                  <div className="card">
                    <div className="card-header">
                      <h4 className="card-title">Corporate Details</h4>
                    </div>
                    <div className="card-body pt-0px">
                      <table className="table info-table">
                        <tbody>
                          <tr>
                            <th>Name</th>
                            <td>{corporate?.name}</td>
                          </tr>
                          <tr>
                            <th>Status</th>
                            <td>{corporate?.status}</td>
                          </tr>
                          <tr>
                            <th>Created On</th>
                            <td>{printTime(corporate?.created_at)}</td>
                          </tr>
                          <tr>
                            <th>Activated On</th>
                            <td>{printTime(corporate?.activated_on)}</td>
                          </tr>
                          <tr>
                            <th>Business Name</th>
                            <td>{corporate?.business_name}</td>
                          </tr>
                          <tr>
                            <th>Business Contact Details</th>
                            <td>{corporate?.business_contact_details}</td>
                          </tr>
                          <tr>
                            <th>Business Logo</th>
                            {
                              isNotEmpty(corporate?.business_logo) ? <>
                                <td><a target="_blank" href={btoaToBlobUrl(corporate?.business_logo)}>View Logo</a></td>
                              </> : <>
                                <td>-</td>
                              </>
                            }
                          </tr>
                          <tr>
                            <th>Sustainability Form ID</th>
                            <td>{corporate?.sustainability_form_id}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </Grid>


                <Grid item xs={12}>
                  <div className="card mt-15px">
                    <div className="card-header">
                      <h4 className="card-title">Sustainability Details</h4>
                      <div className="card-actions">
                        <a href={undefined} onClick={getSustainabilityDetails}>Recheck Details</a>
                      </div>
                    </div>
                    <div className="card-body pt-0px">
                      {
                        sustLoading ? <>
                          <Box width="100%" className="text-center py-50px">
                            <CircularProgress color="primary" size={50} />
                          </Box>
                        </> : <>

                          <GoogleFormResponse data={sustainabilityData} />

                        </>
                      }
                    </div>
                  </div>
                </Grid>

              </Grid>

            </>
          }

        </Grid>
      </Grid>

    </Box>
  );
}

export default CorporateDetails;
