import styled from "@emotion/styled";
import { useIdentifierResolver } from "@govtechsg/address-identity-resolver";
import React, { FunctionComponent } from "react";
import { Info } from "react-feather";
import tw from "twin.macro";
import { TooltipIcon } from "./SvgIcon";
import EndorsementJourney from "./EndorsementJourney";
import { withStyles, makeStyles } from "@material-ui/core";
const useStyles = makeStyles((theme) => {
  return {
    statusTitle: {
      fontSize: "15px",
      fontWeight: 500,
      fontFamily: "Poppins",
      lineHeight: "19px",
      color: "#262727",
    },
  };
});

export const AddressCell = styled(
  ({
    address,
    addressName,
    className,
    titleEscrowAddress,
    isNewAddress,
    displayDashHead,
    displayDashTail,
  }) => {
    const { identityName } = useIdentifierResolver(address);

    const tooltipContent = (
      <div className="tooltip-container">
        <div className="tooltip-tittle">Title Escrow:</div>
        <div className="tooltip-content">{titleEscrowAddress}</div>
      </div>
    );
    const classes = useStyles();
    return (
      <div className={className}>
        <EndorsementJourney
          displayDashHead={!!displayDashHead}
          displayDot={isNewAddress}
          displayDashTail={!!displayDashTail}
        />
        <div className="flex">
          {identityName && <div className="name">{identityName}</div>}
          <TooltipIcon
            className="icon"
            content={tooltipContent}
            placement="top"
          >
            <Info />
          </TooltipIcon>
        </div>
        <div className={classes.statusTitle}>{address}</div>
        { addressName ? <div className={classes.statusTitle}>{"(" + addressName + ")"}</div> : null}
      </div>
    );
  }
)`
  .tooltip-container {
    ${tw`relative flex flex-col`}
  }

  .address {
    ${tw`text-sm font-semibold text-black flex justify-end text-right`}
  }
  .tooltip-tittle {
    ${tw`text-white font-bold text-base`}
  }

  .tooltip-content {
    ${tw`text-white text-base`}
  }

  .icon {
    ${tw`h-5 w-5 text-black ml-1`}
  }

  .name {
    ${tw`text-lg text-black font-semibold`}
  }
`;
