import {
  Button,
  MessageTitle,
  OverlayContext,
  showDocumentTransferMessage,
  LoaderSpinner,
} from "@govtechsg/tradetrust-ui-components";
import React, { useContext, useEffect, useState } from "react";
import { FormState } from "../../FormState";
import { isEthereumAddress } from "../../utils";
import { AssetInformationPanel } from "../../AssetInformationPanel";
import { AssetManagementTitle } from "../../AssetManagementTitle";
import { EditableAssetTitle } from "./../EditableAssetTitle";
import { MenuItem , Select , FormControl, InputLabel, FormLabel, FormControlLabel, Radio, RadioGroup} from '@material-ui/core';

export const EndorseBeneficiaryForm = ({
  formAction,
  tokenRegistryAddress,
  beneficiary,
  holder,
  handleTransfer,
  beneficiaryEndorseState,
  setFormActionNone,
  setShowEndorsementChain,
  corporateList,
  holderName,
  beneficiaryName,
  loadingEndrosmentChain
}) => {
  const [newBeneficiary, setNewBeneficiary] = useState("");
  const [newHolder, setNewHolder] = useState("");

  const [isTruDocUser, setIsTruDocUser] = useState("true");
  const [beneficiaryCorporateId, setBeneficiaryCorporateId] = useState("");
  const [holderCorporateId, setHolderCorporateId] = useState("");

  const isPendingConfirmation = beneficiaryEndorseState === FormState.PENDING_CONFIRMATION;
  const isConfirmed = beneficiaryEndorseState === FormState.CONFIRMED;
  const isEditable =
    beneficiaryEndorseState !== FormState.PENDING_CONFIRMATION && beneficiaryEndorseState !== FormState.CONFIRMED;
  const { showOverlay } = useContext(OverlayContext);

  useEffect(() => {
    if (isConfirmed) {
      showOverlay(
        showDocumentTransferMessage(MessageTitle.CHANGE_BENEFICIARY_SUCCESS, {
          isSuccess: true,
          beneficiaryAddress: newBeneficiary,
        })
      );
      setFormActionNone();
    }
  }, [isConfirmed, newBeneficiary, showOverlay, setFormActionNone]);

  const isValidEndorse = () => {
    if ((!newBeneficiary || !newHolder) && (!beneficiaryCorporateId || !holderCorporateId)) return false;
    if (newBeneficiary && newHolder && newBeneficiary === beneficiary && newHolder === holder) return false;
    if ((newBeneficiary && !isEthereumAddress(newBeneficiary)) || (newHolder && !isEthereumAddress(newHolder))) return false;

    return true;
  };

  const handleTransferTypeChange = (event) => {
    setIsTruDocUser(event.target.value);
    setNewBeneficiary("");
    setNewHolder("");
    setBeneficiaryCorporateId("");
    setHolderCorporateId("");
  };


  return (
    <>
      <AssetManagementTitle
        setFormActionNone={setFormActionNone}
        formAction={formAction}
        disabled={isPendingConfirmation}
      />
      <div className="flex flex-wrap justify-between mb-4 -mx-4">
        <div className="w-full px-4 lg:w-1/3">
          <AssetInformationPanel
            setShowEndorsementChain={setShowEndorsementChain}
            tokenRegistryAddress={tokenRegistryAddress}
            loadingEndrosmentChain={loadingEndrosmentChain}
          />
        </div>

        <FormControl component="fieldset"
        className="border-top-line"
        >

          <div className="borderTop"></div>
          <FormLabel component="legend">Transfer To</FormLabel>
          <RadioGroup
            aria-label="isTruDocUser"
            defaultValue={isTruDocUser}
            name="radio-buttons-group"
            onChange={handleTransferTypeChange}
          >
            <FormControlLabel value={"true"} control={<Radio />} label="TruDoc User" />
            <FormControlLabel value={"false"} control={<Radio />} label=" Public address" />
          </RadioGroup>
        </FormControl>
        {
          isTruDocUser === "true"
          ? <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Owner</InputLabel>
              <Select
                labelId="beneficiaryCorporateId"
                id="beneficiaryCorporateId"
                value={beneficiaryCorporateId}
                label="Owner"
                onChange={(event) => {
                  setBeneficiaryCorporateId(event.target.value)
                }}
              >
                <MenuItem value="">Select</MenuItem>
                {
                  corporateList && corporateList.map((corporate) => {
                    return <MenuItem value={corporate.id}>{corporate.name}</MenuItem>
                  })
                }
                
              </Select>
          </FormControl>
          : <div className="w-full px-4 lg:w-1/3 input-owner-wrp">
            <EditableAssetTitle
              role="Owner"
              value={beneficiary}
              newValue={newBeneficiary}
              isEditable={isEditable}
              onSetNewValue={setNewBeneficiary}
              error={beneficiaryEndorseState === FormState.ERROR}
            />
          </div> 
        }
        {
          isTruDocUser === "true"
          ? <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Holder</InputLabel>
                <Select
                  labelId="holderCorporateId"
                  id="holderCorporateId"
                  value={holderCorporateId}
                  label="Holder"
                  onChange={(event) => {
                    setHolderCorporateId(event.target.value)
                  }}
                >
                  <MenuItem value="">Holder</MenuItem>
                  {
                    corporateList && corporateList.map((corporate) => {
                      return <MenuItem value={corporate.id}>{corporate.name}</MenuItem>
                    })
                  }
                  
                </Select>
            </FormControl>
          : <div className="w-full px-4 lg:w-1/3 input-owner-wrp">
              <EditableAssetTitle
                role="Holder"
                value={holder}
                newValue={newHolder}
                isEditable={isEditable}
                onSetNewValue={setNewHolder}
                error={beneficiaryEndorseState === FormState.ERROR}
              />
            </div>
        }
        
      </div>
      <div className="flex flex-wrap pb-4" >
        <div className="w-auto lg:ml-auto">
          <div className="flex flex-wrap" style={{
            display:"flex",
            marginTop:"15px"
          }}>
            <div className="w-auto">
              <Button
                className="bg-white rounded-xl text-lg py-2 px-3 border-cloud-100 text-cloud-900 shadow-none hover:bg-cloud-200"
                onClick={setFormActionNone}
                disabled={isPendingConfirmation}
                data-testid={"cancelEndorseBtn"}
                style={{
                  background:"#66CFA4;",
                  borderRadius:"20px",
                  color:"#000",
                  textTransform:"capitalize",
                  border:"unset",
                  padding:"7.5px 15px",
                  marginRight:"15px"
                }}
              >
                Cancel
              </Button>
            </div>
            <div className="w-auto ml-2">
              <Button
                className="bg-cerulean rounded-xl text-lg text-white py-2 px-3 shadow-none hover:bg-cerulean-300"
                disabled={!isValidEndorse() || isPendingConfirmation}
                onClick={() => handleTransfer(newBeneficiary, newHolder, beneficiaryCorporateId, holderCorporateId)}
                data-testid={"endorseBtn"}
                style={{
                  background:"#66CFA4;",
                  borderRadius:"20px",
                  color:"#000",
                  textTransform:"capitalize",
                  border:"unset",
                  padding:"7.5px 15px",
                  marginRight:"15px"
                }}
              >
                {isPendingConfirmation ? <LoaderSpinner data-testid={"loader"} /> : <>Endorse</>}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
