import { useState, Fragment, forwardRef, useEffect } from "react";
import {
  Slide,
  Typography,
  makeStyles,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { getPdf, getSignList, signPDFDoc, uploadEDoc } from "../../services/api.service";
import { LoaderSpinner } from "@govtechsg/tradetrust-ui-components";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import { signDoc, downloadTt } from "../../services/api.service";
import { useAuth } from "../../hooks";
import { getDocuments } from "../../services/other.service";
import { sidePanelFilterMapping } from "../../constants";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import SidePanelAction from "../../redux/actions/Sidepanel.action";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import FillSignature from "../../utils/WebViewer/FillSignature";
import ViewPdf from "../../utils/WebViewer/ViewPdf";
import Toast, { toastInitialValues } from "../../utils/Toast";
import { MessageConstants } from "../../utils/MessageConstants";
import { isNotEmpty } from "../../utils/Generic";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="top" ref={ref} {...props} />;
});
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => {
  return {
    dialogRoot: {
      width: "75%",
      borderRadius: 50,
      margin: "0 auto",
    },
    loader: {
      position: "absolute",
      left: "50%",
      top: "50%",
    },
    // modalTitleFlex:{
    //   display:"flex",
    //   justifyContent:"space-between"
    // },
    contentHeight: {
      minWidth: "100%",
      minHeight: "350px",
      maxWidth: "100%",
      margin: "0 auto",
    },
    title: {
      paddingTop: 20,
      textAlign: "center",
      fontWeight: "600",
      textTransform: "uppercase",
      letterSpacing: 0.5,
      wordSpacing: 2,
    },
    stepText: {
      fontWeight: "600",
      paddingBottom: 30,
      textAlign: "center",
      color: theme.palette.admin.main,
      letterSpacing: 1,
    },
    actionBar: {
      justifyContent: "center",
      marginBottom: 50,
    },
    nextButton: {
      width: 220,
      height: 50,
      borderRadius: 25,
      color: "#fff",
      letterSpacing: 1,
      backgroundColor: theme.palette.admin.main,
      "&:hover": {
        backgroundColor: theme.palette.admin.main,
      },
    },
    draftButton: {
      width: 220,
      height: 50,
      borderRadius: 25,
      color: "#fff",
      letterSpacing: 1,
      backgroundColor: "gray",
      "&:hover": {
        backgroundColor: "gray",
      },
    },
    Select: {
      minWidth: "185px",
      maxHeight: "300px !important"
    },
    SelectImg: {
      height: "80px",
      width: "160px"
    }
    // titleRed:{
    //   color:"red"
    // },
    // titleGreen:{
    //   color:"green"
    // }
  };
});

const NewPdfViewer = ({ data, open, handleClose, pdfName, edocs, isReadOnly }) => {
  const [basePdf, setBasePdf] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [loading, setLoading] = useState();
  const { user } = useAuth();
  const { filterType } = useParams();
  const dispatch = useDispatch();
  const [showToast, setShowToast] = useState();
  const [saveFile, setSaveFile] = useState(false)
  const [signature, setSignature] = useState({ Fullname: [], Initial: [] });
  const [selectedValue, setSelectedValue] = useState(0);
  const [selectedSign, setSelectedSign] = useState("");
  const [toast, setToast] = useState(toastInitialValues);
  const [userAnnot, setUserAnnot] = useState(-1);

  const history = useHistory();

  const handleToastClose = () => {
    setShowToast("");
  };

  const handleChange = (e) => {
    setSelectedValue(e.target.value);
    setSelectedSign(signature.Fullname[e.target.value]);
  }

  const svgToImage = async (base64svg, height, width, format, cb) => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    canvas.width = width;
    canvas.height = height;
    const image = new Image();
    image.src = base64svg;
    image.onload = () => {
      context.clearRect(0, 0, width, height);
      context.drawImage(image, 0, 0, width, height);
      const pngData = canvas.toDataURL('image/' + format);
      cb(pngData);
    };
  }

  const getSignature = () => {
    getSignList()
      .then((res) => {
        if (res.data.length > 0) {
          let x = [], y = [], base64svg = "";
          for (const e of res.data) {
            base64svg = `data:image/svg+xml;base64,${e.data}`; //convert svg to base64svg
            svgToImage(base64svg, 400, 600, 'png', (base64Img) => {
              if (e.type === "FULLNAME")
                x.push(base64Img);
              else
                y.push(base64Img);
              setSignature({ Fullname: x, Initial: y });
              if (x.length)
                setSelectedSign(x[0]);
            });
          }
        } else if (res.data.length === 0) {
          setToast((prev) => ({
            ...prev,
            isOpen: true,
            isError: true,
            message: MessageConstants.REQUIRED_ATLEAST_ONE_SIGNATURE
          }));
        }
        console.log("Response", res.data);
      })
      .catch((err) => {
        console.log(err.message);
        setToast((prev) => ({
          ...prev,
          isOpen: true,
          isError: true,
          message: MessageConstants.FAILED_TO_LOAD_SIGNATURE
        }));
      });
  }

  useEffect(() => {
    getSignature();
  }, []);

  useEffect(() => {
    console.log("use effect callll", pdfName, "data:", data);
    getPdf({ filename: data.storage_key, edocs, docId: data?.id })
      .then((res) => {
        setBasePdf(res.data.data.data ? res.data.data.data : res.data.data);
        setIsLoading(false);
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  const handleESignDocument = async (fileBlob) => {
    const type = "SIGNED";
    const fileData = new File([fileBlob], data.storage_key, { type: 'application/pdf' });
    setLoading(type);
    if (data) {
      signPDFDoc({
        file: fileData,
        document_id: data.id,
        status: type
      }).then((res) => {
        if (res && res?.status === 200 && res?.data) {
          setShowToast(res?.data?.message);
          setLoading(null);
          setTimeout(() => {
            updateSidePanel(true);
          }, 1000);
        }
      });
    }
  };

  const handleSignDocument = (type = "SIGNED") => {
    console.log(data);
    setLoading(type);
    if (data) {
      signDoc({
        document_id: data.id,
        email: user.email,
        status: type,
      }).then(res => {
        if (res && res?.status === 200 && res?.data) {
          setShowToast(res?.data?.message);
          setLoading(null);
          setTimeout(() => {
            updateSidePanel(true)
          }, 1000);
        }
      })
    }
  }

  const updateSidePanel = () => {
    getDocuments({
      email: user.email,
      condition: sidePanelFilterMapping[filterType],
    }).then((res) => {
      if (res && res?.status === 200 && res?.data)
        dispatch(SidePanelAction([filterType], [...res?.data]));
    });
    handleClose();
  };

  const closeToast = () => {
    return setToast((prev) => ({
      ...prev,
      isOpen: false,
      isSuccess: false,
      isError: false,
      message: ""
    }));
  };

  const classes = useStyles();

  const goToSignaturesPage = () => {
    history.replace("/create-esign");
  }

  return (
    <Fragment>
      {toast.isOpen && <Toast message={toast.message} isError={toast.isError} isSuccess={toast.isSuccess} closeToast={closeToast} />}
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={open}
        onClose={handleClose}
        className={classes.dialogRoot}
        keepMounted={false}
        TransitionComponent={Transition}
        PaperProps={{
          style: { borderRadius: 20 },
        }}
      >
        <DialogTitle className="modalTitleFlex">

          {
            edocs ? <>
              <FormControl className={classes.Select}>
                <InputLabel id="select-signature">Select Signature</InputLabel>
                <Select
                  labelId="select-signature"
                  id="select-corporate-comp"
                  value={selectedValue}
                  label="Select Signature"
                  onChange={handleChange}
                  // error={corporateError}
                  required
                >
                  {
                    signature.Fullname.map((e, i) => {
                      return <MenuItem key={i} value={i}>
                        <img className={classes.SelectImg} src={e} alt="signature" />
                      </MenuItem>
                    })
                  }
                  {
                    isNotEmpty(signature?.Fullname) && signature?.Fullname.length <= 0 ? <>
                      <MenuItem onClick={goToSignaturesPage} key={-1} value={-1}>
                        Create new Signature
                      </MenuItem>
                    </> : null
                  }
                </Select>
              </FormControl>

              {filterType === "awaiting_my_sign" && (
                <Typography variant="p" className="titleGreen">
                  <Button disabled={signature.Fullname.length === 0 || userAnnot !== 0} onClick={() => setSaveFile(!saveFile)}>
                    Save
                    {loading === "SIGNED" && (
                      <CircularProgress color="primary" size={20} />
                    )}
                  </Button>
                </Typography>
              )}

            </> : <>

              {filterType !== 'completed' && filterType !== 'declined' && filterType !== 'expired' && filterType !== 'awaiting_others_sign' && <Typography variant='p' className="titleRed">
                <Button onClick={() => handleSignDocument("REJECTED")}> {filterType !== 'my_wallet' ? "Reject" : "Remove"}
                  {loading === 'REJECTED' ? <CircularProgress color="primary" size={20} /> : <HighlightOffIcon
                    style={{
                      marginLeft: "10px"
                    }}
                  />}
                </Button>
              </Typography>}
              {filterType === 'awaiting_my_sign' &&
                <Typography variant='p' className="titleGreen">
                  <Button onClick={() => handleSignDocument("SIGNED")}>
                    Approve   {loading === "SIGNED" ? <CircularProgress color="primary" size={20} /> : <CheckCircleOutlineIcon
                      style={{
                        marginLeft: "10px"
                      }} />}
                  </Button>
                </Typography>}

            </>
          }


        </DialogTitle>

        <DialogContent
          className={classes.contentHeight}
          fullWidth={true}
          fullHeight={true}
          maxWidth={"xl"}
          maxHeight={"xl"}
        >
          {/* Show The PDF here */}
          {isLoading ? (
            <CircularProgress
              color="primary"
              size={20}
              className={classes.loader}
            />
          ) : (
            isReadOnly ? <ViewPdf signature={signature} isReadOnly={isReadOnly} saveFile={saveFile} handleSignDocument={handleESignDocument} file={basePdf} />
              : <FillSignature userAnnot={userAnnot} setUserAnnot={setUserAnnot} selectedSign={selectedSign} isReadOnly={isReadOnly} saveFile={saveFile} handleSignDocument={handleESignDocument} file={basePdf} />
          )}
        </DialogContent>
      </Dialog>
      <Snackbar
        open={Boolean(showToast)}
        autoHideDuration={6000}
        onClose={() => handleToastClose()}
      >
        <Alert onClose={() => handleToastClose()} severity="success">
          {showToast}
        </Alert>
      </Snackbar>
    </Fragment>
  );
};

export default NewPdfViewer;
