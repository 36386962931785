import React, { FunctionComponent, useState, createRef, useRef, useEffect } from "react";
import {
  AppBar,
  Box,
  Button,
  makeStyles,
  Typography,
  Toolbar,
  Divider,
  TextField,
  Tab,
  Tabs,
  Select,
  MenuItem,
} from "@material-ui/core";
import { renderToStaticMarkup, renderToString } from "react-dom/server";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import CloseIcon from "@material-ui/icons/Close";
import Fade from "@material-ui/core/Fade";
import FontFamilySelection from "./FontFamilySelection";
import SigPad from "./SigPad";
import SigSealImage from "./helperComponents/SigSealImage";
import { sendSign } from "../services/api.service";
import SigSeal from "./helperComponents/SigSeal";
import { DropzoneArea } from "material-ui-dropzone";
import { useDispatch } from "react-redux";
import FetchSignature from "../redux/actions/Sign.action";
import Toast from "../utils/Toast";
import { MessageConstants } from "../utils/MessageConstants";


const useStyles = makeStyles((theme) => {
  return {
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      backgroundColor: "#ffffff",
      borderRadius: "8px",
      outline: "none",
      padding: theme.spacing(2, 4, 3),
    },
    innerModal: {
      display: "flex",
      justifyContent: "center",
    },
    divider: {
      width: "100%",
    },
    input: {
      marginRight: 10,
      marginTop: "30px",
      // marginBottom: "30px",
    },
    buttonModal: {
      width: "100px",
      height: "36px",
      marginLeft: "20px",
      backgroundColor: "#66CFA4",
      borderRadius: "20px",
      alignSelf: "center",
      marginTop: "20px",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer"
    },
    chooseBox: {
      display: "flex",
      overflow: "scroll",
      height: "280px",
      marginBottom: "20px",
      justifyContent: "space-between",
      width: "900px",
    },
    preview: {
      marginLeft: "5%",
    },
    belowText: {
      width: "900px",
    },
    UploadBox: {
      // overflow: "scroll",
      height: "280px",
      marginBottom: "20px",
      width: "900px",
    },
    text: {
      color: "#ffffff",
      fontWeight: "600",
    },
    text1: {
      color: "#000000",
      fontWeight: "600",
      fontSize: "25px",
      marginTop: "15px",
      marginBottom: "15px",
    },
    DropZone: {
      marginBottom: "40px",
      width: "400px",
      height: "800px",
    },
  };
});
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function CreateSignature(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [changed, setChanged] = useState(false);
  const [open, setOpen] = useState(true);
  const dispatch = useDispatch();
  const [choiceState, setChoiceState] = useState({
    // pubKeyFingerprint,
    selectedFont: "Caveat",
    color: "#000028",
  });
  const toastInitialValues = {
    isOpen: false,
    isSuccess: false,
    isError: false,
    message: ""
  }
  const sigPadRef = createRef();
  const [fullname, setFullName] = useState("");
  const [initials, setInitials] = useState("");
  const [url, setUrl] = useState("");
  const [sig] = useState();
  const [files, setFiles] = useState({ FullName: [], Initial: [] });
  const [updating, setUpdating] = useState(false);
  const [toast, setToast] = useState(toastInitialValues);

  const [signatureType, setSignatureType] = useState('only_signature');

  const [pubKey, setPubKey] = useState('0047647236472648274');

  const [showBorderAndSerial, setShowBorderAndSerial] = useState(true);

  useEffect(() => {
    if (signatureType === "full_signature") {
      setShowBorderAndSerial(true);
    } else {
      setShowBorderAndSerial(false);
    }
  }, [signatureType]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const closeToast = () => {
    return setToast((prev) => ({
      ...prev,
      isOpen: false,
      isSuccess: false,
      isError: false,
      message: ""
    }));
  };

  const SignType = {
    FULLNAME: "FULLNAME",
    INITIALS: "INITIALS"
  }

  const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

  const handleSave = async (type, accfiles) => {
    const x = files;
    if (accfiles.length) {
      let ssi = (<SigSealImage
        pubKey={""}
        color={"#000000"}
        className={{
          marginLeft: "50%",
        }}
        height="80"
        width="150"
        image={await toBase64(accfiles[0])}
      ></SigSealImage>)
      ssi = renderToString(ssi)
      x[type].push(ssi);
      setFiles(x);
    }
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const createSign = async (type, data) => {
    await sendSign({
      data: btoa(data),
      type: type,
    })
      .then((response) => {
        setToast((prev) => ({
          ...prev,
          isOpen: true,
          isSuccess: true,
          message: MessageConstants.SIGNATURE_CREATED_SUCCESS
        }));
        dispatch(FetchSignature());
      })
      .catch((e) => {
        console.log("Error...", e);
        setToast((prev) => ({
          ...prev,
          isOpen: true,
          isError: true,
          message: MessageConstants.SIGNATURE_CREATE_FAILED
        }));
      }).finally(() => {
        setTimeout(() => {
          props.handleCloseModal();
        }, 1000)
      });
  }

  const onUpdate = async () => {
    setUpdating(true);
    const sigData = {
      id: "0087655",
    };
    if (value === 0) {
      const { selectedFont, color } = choiceState;
      sigData["signature[styling]"] = {
        font_family: selectedFont,
        fill_color: color,
      };

      let svgInitial = (<SigSeal
        pubKey={pubKey}
        name={initials}
        font={selectedFont}
        color={"#000000"}
        height="80"
        width="150"
        showBorderAndSerial={showBorderAndSerial}
      />)

      let svgFullName = (<SigSeal
        pubKey={pubKey}
        name={fullname}
        font={selectedFont}
        color={"#000000"}
        height="80"
        width="150"
        showBorderAndSerial={showBorderAndSerial}
      />)

      svgFullName = renderToString(svgFullName)
      svgInitial = renderToString(svgInitial)
      if (initials.length === 0 && fullname.length === 0) {
        setToast((prev) => ({
          ...prev,
          isOpen: true,
          isError: true,
          message: MessageConstants.REQUIRED_FULLNAME_INITIAL
        }));
        return;
      }

      if (initials.length) {
        createSign(SignType.INITIALS, svgInitial);
      }
      if (fullname.length) {
        createSign(SignType.FULLNAME, svgFullName);
      }
    } else if (value === 1) {
      if (sigPadRef.current.isEmpty()) {
        setToast((prev) => ({
          ...prev,
          isOpen: true,
          isError: true,
          message: MessageConstants.REQUIRED_SIGNATURE_DRAW
        }));
        return;
      }
      const svgData = sigPadRef.current.toDataURL("image/svg+xml");
      let svgFullName = (<SigSealImage
        pubKey={""}
        image={svgData}
        color={"#000000"}
        height="80"
        width="150"
      />)
      svgFullName = renderToString(svgFullName);
      createSign(SignType.FULLNAME, svgFullName);
    } else if (value === 2) {
      if (files.FullName.length === 0 && files.Initial.length === 0) {
        setToast((prev) => ({
          ...prev,
          isOpen: true,
          isError: true,
          message: MessageConstants.REQUIRED_SIGNATURE_UPLOAD
        }));
        return;
      }
      if (files.FullName.length > 0) {
        createSign(SignType.FULLNAME, files.FullName[0]);
      }
      if (files.Initial.length > 0) {
        createSign(SignType.INITIALS, files.Initial[0]);
      }
    }
    setUpdating(false);
  };
  const currFont = "Caveat" || "";
  return (
    <>
      {toast.isOpen && <Toast message={toast.message} isError={toast.isError} isSuccess={toast.isSuccess} closeToast={closeToast} />}

      <div className={classes.paper}>
        <Typography className={classes.text1}>
          Create Your Signature
        </Typography>
        <Box className={classes.divider}>
          <Divider />
        </Box>
        {value === 0 && <form noValidate autoComplete="off">
          <TextField
            id="Full Name"
            label="Full Name"
            variant="outlined"
            onChange={(event) => setFullName(event.target.value)}
            className={classes.input}
          />
          <TextField
            id="Initials"
            label="Initials"
            variant="outlined"
            onChange={(event) => setInitials(event.target.value)}
            className={classes.input}
          />
          <Select
            labelId="category-label"
            variant="outlined"
            id="category-field"
            className={classes.input}
            label="Select Category"
            value={signatureType}
            onChange={e => setSignatureType(e.target.value)}
          >
            <MenuItem value="only_signature">Only Signature</MenuItem>
            <MenuItem value="full_signature">Signature with TruDoc Serial Number</MenuItem>
          </Select>
        </form>}
        {/* <Divider /> */}
        <AppBar style={{ marginTop: '30px' }} position="static">
          <Tabs
            value={value}
            centered={true}
            onChange={handleChange}
            aria-label="simple tabs example"
          >
            <Tab
              style={{ color: "#ffffff", fontWeight: "bold" }}
              label="Choose"
              {...a11yProps(0)}
            />
            <Tab
              style={{ color: "#ffffff", fontWeight: "bold" }}
              label="Draw"
              {...a11yProps(1)}
            />
            <Tab
              style={{ color: "#ffffff", fontWeight: "bold" }}
              label="Upload"
              {...a11yProps(2)}
            />
          </Tabs>
        </AppBar>
        {/* {First tab for font signature} */}
        <TabPanel value={value} index={0}>
          <Box className={classes.chooseBox}>
            {fullname && (
              <FontFamilySelection
                fullName={fullname}
                choiceState={choiceState}
                setChoiceState={setChoiceState}
                setChanged={setChanged}
                currFont={currFont}
                pubKey={pubKey}
                showBorderAndSerial={showBorderAndSerial}
              />
            )}
            {initials && (
              <FontFamilySelection
                fullName={initials}
                choiceState={choiceState}
                setChoiceState={setChoiceState}
                setChanged={setChanged}
                currFont={currFont}
                pubKey={pubKey}
                showBorderAndSerial={showBorderAndSerial}
              />
            )}
          </Box>
        </TabPanel>
        {/* {Second tab for draw signature} */}
        <TabPanel value={value} index={1}>
          <Box className={classes.UploadBox}>
            <SigPad sigPadRef={sigPadRef} setChanged={setChanged} />
          </Box>
        </TabPanel>

        {/* {Third tab for upload image} */}
        <TabPanel value={value} index={2}>
          <Box
            display="flex"
            align="center"
            justifyContent="space-between"
            className={classes.UploadBox}
          >
            <Box
              container
              direction="column"
              alignItems="center"
              justify="center"
              className={classes.DropZone}
            >
              <DropzoneArea
                open={open}
                onChange={(e) => handleSave("FullName", e)}
                dropzoneText={"Upload Image for full name "}
                filesLimit={1}
                acceptedFiles={["image/jpeg", "image/png", "image/bmp"]}
                previewGridClasses={{
                  item: classes.preview,
                }}
                getPreviewIcon={(file) => {
                  if (file.file.type.split("/")[0] === "image")
                    return (
                      <SigSealImage
                        // pubKey={""}
                        color={"#000000"}
                        className={{
                          marginLeft: "50%",
                        }}
                        height="80"
                        width="150"
                        image={file.data}
                      ></SigSealImage>
                    );
                }}
                onClose={handleClose}
              />
            </Box>
            <Box
              container
              direction="column"
              alignItems="center"
              justify="center"
              className={classes.DropZone}
            >
              <DropzoneArea
                open={open}
                onChange={(e) => handleSave("Initial", e)}
                filesLimit={1}
                dropzoneText={"Upload image for initials"}
                acceptedFiles={["image/jpeg", "image/png", "image/bmp"]}
                onClose={handleClose}
                previewGridClasses={{
                  item: classes.preview,
                }}
                getPreviewIcon={(file) => {
                  if (file.file.type.split("/")[0] === "image")
                    return (
                      <SigSealImage
                        pubKey={""}
                        color={"#000000"}
                        image={file.data}
                        height="80"
                        width="150"
                      ></SigSealImage>
                    );
                }}
              />
            </Box>
          </Box>
        </TabPanel>
        <Typography className={classes.belowText}>
          By clicking Create, I agree that the signature and initials will be
          the electronic representation of my signature and initials for all
          purposes when I (or my agent) use them on envelopes, including
          legally binding contracts - just the same as a pen-and-paper
          signature or initial.
        </Typography>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            // onClick={handleSignature}
            onClick={onUpdate}
            className={classes.buttonModal}
          >
            <Typography className={classes.text}>Create</Typography>
          </Box>
        </div>
      </div>

    </>
  );
}
