import { Grid } from "@material-ui/core";
import { isNotEmpty, printTime } from "../../utils/Generic";

const GoogleFormResponse = (props) => {

    const { data } = props;

    const formAnswers = (item) => {
        if (item.hasOwnProperty("answers")) {
            return item.answers;
        } else {
            return item;
        }
    }

    return (
        <>

            {
                data.length > 0 ? <>
                    {
                        data.map((item) => {
                            return <>
                                <Grid item xs={12}>
                                    <div className="card mb-15px">
                                        <div className="card-body">
                                            {
                                                isNotEmpty(item?.lastSubmittedTime) ? <>
                                                    <h4 className="gFormResponseTime">Submitted On:- {printTime(item?.lastSubmittedTime)}</h4>
                                                </> : null
                                            }
                                            <ul className="gFormResponses">
                                                {
                                                    formAnswers(item).map((responseItem, index) => {
                                                        return <>
                                                            <li className="gFormResponseItem">
                                                                <h4 className="gFormQuestion"><span className="count">{index + 1}.</span>{responseItem?.question}</h4>
                                                                <div className="gFormAnswer preLineText" dangerouslySetInnerHTML={{ __html: isNotEmpty(responseItem?.answer) ? responseItem?.answer : '-' }}></div>
                                                            </li>
                                                        </>;
                                                    })
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </Grid>
                            </>;
                        })
                    }
                </> : <>
                    <p className="text-center">No Data Found</p>
                </>
            }


        </>
    )
}

export default GoogleFormResponse;