import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Box, Grid, Typography, TextField, InputAdornment, Button } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import CustomerCard from "../../components/customers/CustomerCard";
import DetailedCard from '../../components/customers/DetailedCard';
import { addCustomer, deleteCustomer, updateCustomer } from '../../services/admin.service';
import AddOrEditModal from '../../components/admin/customers/AddOrEdit';
import AdminLayout from './Admin.layout';
import { GetCustomers, SelectCustomerAction } from "../../redux/actions/Customers.action";
import DeleteModal from '../DeleteModal';
import Toast from '../../utils/Toast';
import { useAuth } from "../../hooks";
import { MessageConstants } from '../../utils/MessageConstants';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "88vh",
  },
  filterBar: {
    marginTop: 20,
    marginBottom: 30
  },
  searchBar: {
    width: 300
  },
  button: {
    width: 220,
    height: 45,
    borderRadius: 25,
    marginLeft: 10,
    backgroundColor: theme.palette.admin.main,
    '&:hover': {
      backgroundColor: theme.palette.admin.main,
    }
  },
  buttonText: {
    fontWeight: "bold",
    fontSize: 14,
    letterSpacing: "0.15em",
    color: "#FFFDFD",
  },
  actionBar: {
    marginTop: 50,
    textAlign: "center",
  },
  heading: {
    textTransform: 'uppercase',
    fontWeight: '500'
  }
}));

const Customers = () => {
  const initialValues = {
    full_name: '',
    email: '',
    address: '',
  }

  const toastInitialValues = {
    isOpen: false,
    isSuccess: false,
    isError: false,
    message: ""
  }

  const classes = useStyles();
  const dispatch = useDispatch();
  const [data, setData] = useState();
  const [toast, setToast] = useState(toastInitialValues);
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [popupId, setPopupId] = useState(0);
  const { customers, selected } = useSelector((state) => state.customer);
  const [state, setState] = useState(initialValues);
  const { removeUser, user } = useAuth();

  const setStateData = (type, value) => {
    setState((prev) => ({
      ...prev,
      [type]: value,
    }));
  };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  useEffect(() => {
    dispatch(GetCustomers());
  }, []);

  useEffect(() => {
    if (selected !== null && popupId === 1) {
      setState((prev) => ({
        ...prev,
        username: selected.username,
        email: selected.email,
        role: selected.role,
        jobTitle: selected.job_title
      }));
    }
  }, [selected, popupId])

  useEffect(() => {
    if (popupId === 0) {
      setState((prev) => ({
        ...prev,
        ...initialValues
      }));
    }
  }, [popupId])

  useEffect(() => {
    setData(customers?.data);
  }, [customers])

  const closeDailogHandler = () => {
    setOpenDialog(false);
    setState((prev) => ({
      ...prev,
      ...initialValues
    }));
    setPopupId(0);
  }

  const editHandler = () => {
    setOpenDialog(true)
    setPopupId(1);
  }

  const addHandler = () => {
    setOpenDialog(true)
    setPopupId(0);
  }

  const deleteHandler = () => {
    setDeleteDialog(true);
  }

  const handleSearch = (value) => {
    setData([...customers?.data].filter(item => item?.full_name?.toLowerCase()?.includes(value?.toLowerCase())))
  }

  const closeToast = () => {
    return setToast((prev) => ({
      ...prev,
      isOpen: false,
      isSuccess: false,
      isError: false,
      message: ""
    }));
  };

  const createUser = () => {
    if (state.username == "" || state.email == "" || state.jobTitle == "" || state.role == "") {
      setToast((prev) => ({
        ...prev,
        isOpen: true,
        isError: true,
        message: MessageConstants.FIELD_REQUIRED
      }));
      return;
    }
    if (!validateEmail(state.email)) {
      setToast((prev) => ({
        ...prev,
        isOpen: true,
        isError: true,
        message: MessageConstants.INVALID_EMAIL
      }));
      return;
    }
    if (popupId === 0) {
      addCustomer(state).then(res => {
        if (res && res.status === 200) {
          setState(() => ({
            ...initialValues
          }));
          setData();
          setToast((prev) => ({
            ...prev,
            isOpen: true,
            isSuccess: true,
            message: MessageConstants.USER_ADDED_SUCCESS
          }));
          dispatch(GetCustomers());
          setOpenDialog(false);
        }
      }).catch(e => {
        const Error = JSON.parse(e.message);
        let msg = MessageConstants.USER_ADDED_FAILED;
        if (Error.status === 409)
          msg = MessageConstants.EMAIL_ALREADY_EXIST;
        setToast((prev) => ({
          ...prev,
          isOpen: true,
          isError: true,
          message: msg
        }));
      })
    }
    else if (popupId === 1) {
      const obj = {
        ...state,
        userId: selected.user_id,
        id: selected.id,
        corporateId: selected.corporate_id
      }
      updateCustomer(obj).then(res => {
        if (res && res.status === 200) {
          setData();
          setToast((prev) => ({
            ...prev,
            isOpen: true,
            isSuccess: true,
            message: MessageConstants.USER_UPDATE_SUCCESS
          }));
          dispatch(GetCustomers());
          setOpenDialog(false);
        }
      }).catch(e => {
        setToast((prev) => ({
          ...prev,
          isOpen: true,
          isError: true,
          message: MessageConstants.USER_UPDATE_FAILED
        }));
      })
    }
  }

  const deleteUserHandler = () => {
    const obj = {
      userId: selected.user_id,
      id: selected.id,
      email: selected.email
    }
    deleteCustomer(obj).then(res => {
      if (res && res.status === 200) {
        dispatch(SelectCustomerAction(null));
        setData();
        setToast((prev) => ({
          ...prev,
          isOpen: true,
          isSuccess: true,
          message: MessageConstants.USER_DELETE_SUCCESS
        }));
        dispatch(GetCustomers());
        setDeleteDialog(false);
      }
    }).catch(e => {
      setToast((prev) => ({
        ...prev,
        isOpen: true,
        isError: true,
        message: MessageConstants.USER_DELETE_FAILED
      }));
    })
  }

  return (
    <AdminLayout>
      {toast.isOpen && <Toast message={toast.message} isError={toast.isError} isSuccess={toast.isSuccess} closeToast={closeToast} />}
      <Grid className={classes.filterBar} container direction="row" alignItems="center" justify="space-between">
        <Typography variant="h5" gutterBottom className={classes.heading}>Customers</Typography>
        <Box display="flex" alignItems="center">
          <TextField
            className={classes.searchBar}
            label="Search by name"
            variant="outlined"
            color="primary"
            onChange={e => handleSearch(e.target.value)}
            InputProps={{
              endAdornment: <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>,
            }}
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.button}
            size="large"
            onClick={addHandler}
          >
            <Typography className={classes.buttonText}>New Customer</Typography>
          </Button>
        </Box>
      </Grid>

      <Grid container>
        <Grid container item xs={selected ? 8 : 12}>
          <Grid container item xs={selected && 12} spacing={3}>
            {data && data.map(card => {
              if (user?.email !== card.email) {
                return (
                  <Grid container item xs={selected ? 4 : 3} key={card.id}>
                    <CustomerCard
                      data={card}
                      isSelected={selected && selected.id === card.id}
                    />
                  </Grid>
                )
              }
            })}
          </Grid>
        </Grid>

        {selected &&
          <Grid container item xs={4}>
            <Box width="100%" height="calc(100vh - 200px)">
              <DetailedCard
                user="admin"
                onEdit={editHandler}
                onDelete={deleteHandler}
              />
            </Box>
          </Grid>
        }
      </Grid>

      <AddOrEditModal
        open={openDialog}
        handleClose={closeDailogHandler}
        data={selected}
        handleChange={setStateData}
        state={state}
        handleSubmit={createUser}
        popupId={popupId}
      />

      <DeleteModal
        open={deleteDialog}
        handleClose={() => setDeleteDialog(false)}
        handleSubmit={deleteUserHandler}
        message="Are you sure you want to delete user"
      />

    </AdminLayout>
  )
}

export default Customers;
