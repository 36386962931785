import React, { useState } from "react";
import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Grid,
  makeStyles,
  Tab,
  Tabs,
  TextField,
  Typography
} from "@material-ui/core";
import { useAuth } from "../../hooks";
import moment from "moment";
import { isNotEmpty, isValidEmailAddress, printTime } from "../../utils/Generic";
import { isEmpty } from "@magic-sdk/provider";
import { Autocomplete, createFilterOptions, TabContext, TabPanel } from "@material-ui/lab";
import { useEffect } from "react";
import { addGroupMember, chatDetails, removeGroupMember } from "../../services/chat.service";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import Toast from "../../utils/Toast";
import { Close } from "@material-ui/icons";
import { isGroupAdmin } from "../../utils/chat";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({

}));


const usersFilter = createFilterOptions();

const ChatDetailsBox = (props) => {

  const { chat, defaultUsers } = props;

  const [tabValue, setTabValue] = useState(0);

  const [memberFormLoading, setMemberFormLoading] = useState(false);

  const [openMemberForm, setOpenMemberForm] = useState(false);
  const [newChatUserEmail, setNewChatUserEmail] = useState(null);

  const [members, setMembers] = useState([]);
  const [attachments, setAttachments] = useState([]);

  const classes = useStyles();

  const toastInitialValues = {
    isOpen: false,
    isSuccess: false,
    isError: false,
    message: ""
  }

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const isGroup = () => {
    if (chat?.is_group.toString() === '1') {
      return true;
    }
    return false;
  }

  const getChatIcon = () => {
    if (isGroup()) {
      return '/assets/images/group-members.svg';
    }
    return '/assets/images/member-active.svg';
  }

  const chatName = () => {
    if (isGroup()) {
      return chat?.name;
    } else {
      return chat?.friend_member[0]?.user?.email;
    }
  }

  const loadChatOtherInfo = () => {
    if (isNotEmpty(chat)) {
      chatDetails({
        chatId: chat?.uuid
      }).then((res) => {
        let data = res?.data?.data;
        setMembers(data?.chatMembers);
        setAttachments(data?.chatAttachments);
      }).catch((err) => {
        console.log(err);
      });
    }
  }

  useEffect(() => {
    setTabValue(0);
    loadChatOtherInfo();
  }, [chat]);

  const canAddMembers = () => {
    if (isGroup()) {
      if (isGroupAdmin(chat)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  const openAddNewMember = () => {
    setOpenMemberForm(true);
  }

  const onDeleteMemberClick = (e, member) => {
    removeGroupMember({
      chatId: chat?.uuid,
      memberId: member?.id
    }).then(res => {
      let data = res?.data;
      if (data.success.toString() === 'true') {
        showSuccess("Member has been deleted");
        loadChatOtherInfo();
      } else {
        showError(data?.error);
      }
    }).catch(err => {
      showError("Failed to Delete Member");
    });
  }

  const saveMember = () => {
    if (isNotEmpty(newChatUserEmail)) {
      addGroupMember({
        chatId: chat?.uuid,
        email: newChatUserEmail
      }).then(res => {
        let data = res?.data;
        if (data.success.toString() === 'true') {
          setNewChatUserEmail(null);
          showSuccess("Member has been added");
          loadChatOtherInfo();
          setOpenMemberForm(false);
        } else {
          showError(data?.error);
        }
      }).catch(err => {
        showError("Failed to Add Member");
      });
    }
  }

  const isAdmin = (member) => {
    if (member?.is_admin.toString() === '1') {
      return true;
    } else {
      return false;
    }
  }

  const [toast, setToast] = useState(toastInitialValues);

  const closeToast = () => {
    return setToast((prev) => ({
      ...prev,
      isOpen: false,
      isSuccess: false,
      isError: false,
      message: ""
    }));
  };

  const showError = (errorMsg) => {
    setToast((prev) => ({
      ...prev,
      isOpen: true,
      isError: true,
      isSuccess: false,
      message: errorMsg
    }));
  }

  const showSuccess = (succMsg) => {
    setToast((prev) => ({
      ...prev,
      isOpen: true,
      isError: false,
      isSuccess: true,
      message: succMsg
    }));
  }

  const viewAttachment = (document) => {
    props?.onView(document);
  }

  return (
    <>

      {toast.isOpen && <Toast message={toast.message} isError={toast.isError} isSuccess={toast.isSuccess} closeToast={closeToast} />}

      <div className={`chat-profile-box chat-details-profile-box ${isGroup() ? 'group' : 'chat'}`}>
        <a className="close-chat-details" href={undefined} onClick={props?.onClosed}><Close /></a>
        <div className="img" style={{ "backgroundImage": 'url("' + getChatIcon() + '")' }}></div>
        <div className="info">
          <Typography variant="h6">{chatName()}</Typography>
        </div>
      </div>

      <Grid item xs={12} className="chat-details-tabs">

        <TabContext value={tabValue.toString()}>

          <AppBar position="static">

            <Tabs
              value={tabValue}
              centered={true}
              onChange={handleTabChange}
            >

              <Tab
                style={{ color: "#ffffff", fontWeight: "bold" }}
                label="Attachments"
                {...a11yProps(0)}
              />

              {
                isGroup() ?

                  <Tab
                    style={{ color: "#ffffff", fontWeight: "bold" }}
                    label="Members"
                    {...a11yProps(0)}
                  />

                  : null
              }

            </Tabs>

          </AppBar>

          <TabPanel value={"0"} key={0}>

            <div className="attachments-list-wrapper">

              {attachments && attachments.length > 0 ?
                attachments.map((attachment) => {
                  return (
                    <div key={attachment?.id} className={`attachments-list-item`}>
                      <a onClick={(e) => viewAttachment(attachment?.document)} href={undefined} className="user-info">
                        <Typography color="textPrimary" variant="h6" component="h6">{attachment?.document?.name}</Typography>
                        <Typography>{printTime(attachment?.created_at)}</Typography>
                      </a>
                    </div>
                  );
                }) : <>

                  <div key={-1} className="attachments-list-item not-found">
                    <Typography>
                      No Attachments Found
                    </Typography>
                  </div>

                </>
              }

            </div>

          </TabPanel>

          <TabPanel value={"1"} key={1}>

            <div className="members-list-wrapper">

              {canAddMembers() &&
                <div key={0} className="members-list-item add-new">
                  <Typography onClick={openAddNewMember}>
                    <PersonAddIcon />Click to add New Member
                  </Typography>
                </div>
              }

              {members && members.length > 0 ?
                members.map((member) => {
                  return (
                    <div key={member?.id} className={`members-list-item ${(!isAdmin(member) && canAddMembers()) ? 'manage-users' : 'not-manage-users'}`}>
                      <div className="user-info">
                        <Typography color="textPrimary" variant="h6" component="h6">{member?.user?.email}</Typography>
                        <Typography>{isAdmin(member) ? 'Admin' : 'Member'}</Typography>
                      </div>
                      {
                        !isAdmin(member) ? <>
                          <div className="user-actions">
                            {(canAddMembers()) ? <>
                              {
                                <Button onClick={e => onDeleteMemberClick(e, member)}>
                                  <DeleteOutlineOutlinedIcon />
                                </Button>
                              }
                            </> : null
                            }
                          </div>
                        </> : null
                      }
                    </div>
                  );
                }) : <>

                  <div key={-1} className="users-list-item not-found">
                    <Typography>
                      No Members Found
                    </Typography>
                  </div>

                </>
              }

            </div>

          </TabPanel>


        </TabContext>

      </Grid>


      <Dialog
        fullWidth={true}
        maxWidth='sm'
        open={openMemberForm}
        className={classes.dialogRoot}
        keepMounted={false}
        PaperProps={{
          style: { borderRadius: 20, maxHeight: 'calc(100% - 44px)' }
        }}
      >

        <DialogContent className={classes.contentHeight}>

          <Typography variant='h6' className="confirmModalTitle theme-form-group">
            Enter Email Address
          </Typography>

          <Box className="w-100">

            <Autocomplete
              style={{ width: "100%" }}
              value={newChatUserEmail}
              onChange={(event, newValue) => {
                if (typeof newValue === 'string') {
                  setNewChatUserEmail(newValue);;
                } else if (newValue && newValue.inputValue) {
                  let userVal = newValue.inputValue;
                  if (isValidEmailAddress(userVal)) {
                    setNewChatUserEmail(userVal);
                  } else {
                    setToast((prev) => ({
                      ...prev,
                      isOpen: true,
                      isError: true,
                      message: 'Email address is not valid'
                    }));
                  }
                } else {
                  setNewChatUserEmail(newValue?.email);
                }
              }}
              filterOptions={(options, params) => {
                const filtered = usersFilter(options, params);
                if (params.inputValue !== '') {
                  filtered.push({
                    inputValue: params.inputValue,
                    email: `Add "${params.inputValue}"`,
                  });
                }
                return filtered;
              }}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              id="free-solo-with-text-demo"
              options={defaultUsers}
              getOptionLabel={(option) => {
                if (typeof option === 'string') {
                  return option;
                }
                if (option.inputValue) {
                  return option.inputValue;
                }
                return option.email;
              }}
              renderOption={(option) => option.email}
              freeSolo
              renderInput={(params) => (
                <TextField {...params} label="Search/Add User Email" variant="outlined" />
              )}
            />

            <Box className="w-100 mt-15px text-center">

              {memberFormLoading ?
                <>
                  <CircularProgress color="primary" size={20} />
                </>
                :
                <>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={e => saveMember()}
                    className={`theme-btn theme-form-group me-10px`}
                  >
                    Save
                  </Button>

                  <Button
                    variant="contained"
                    color="default"
                    onClick={e => setOpenMemberForm(false)}
                    className={`theme-btn theme-form-group secondary`}
                  >
                    Cancel
                  </Button>
                </>
              }
            </Box>

          </Box>

        </DialogContent>
      </Dialog>

    </>
  );
};

export default ChatDetailsBox; 
