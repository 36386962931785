import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Chip from "@material-ui/core/Chip";
import { Box } from "@material-ui/core";
import { getImageFromMimeType, printTime } from "../../utils/Generic";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    width: "100%",
    minWidth: 200,
    maxWidth: "100%",
    textAlign: "center",
    padding: 20,
    margin: "0 auto",
    borderWidth: 2,
    borderColor: theme.palette.primary.main,
    cursor: "pointer",
    overflow: "hidden",
    height: "auto",
  },
  title: {
    marginTop: 10,
  },
  position: {
    marginTop: 0,
    marginBottom: 10,
  },
  avatar: {
    fontWeight: 500,
    fontSize: "24px",
    margin: "0 auto",
    width: theme.spacing(8),
    height: theme.spacing(8),
    textTransform: "uppercase"
  },
  banner: {
    position: "absolute",
    top: "-15px",
    left: "-50px",
    backgroundColor: "red",
    height: 50,
    width: 100,
    transform: "rotate(135deg)",
  },
}));

export default function DocumentCard(props) {

  const classes = useStyles();
  const [data, setData] = useState({});

  const showDetails = () => {
    props.onSelected(data);
  };

  const openFolder = () => {
    props.onOpen(data);
  }

  useEffect(() => {
    setData(props?.data);
  }, [props]);

  return (
    <Paper
      elevation={1}
      onClick={showDetails}
      onDoubleClick={openFolder}
      className={`document-title-wrapper ${data?.is_default && data?.is_default.toString() === "true" ? 'default' : ''} ${classes.root}`}
      variant={props?.isSelected ? "outlined" : "elevation"}
    >
      <Box className="document-tile" title={data?.name}>
        <Box className="document-image">
          <img src={getImageFromMimeType(data?.file_type, data?.is_folder)} />
        </Box>
        <Box className="document-details">
          <Typography
            className={classes.title}
            color="textPrimary"
            variant="h6"
            component="h6"
          >
            {data?.name}
          </Typography>
          <Typography
            className={classes.position}
            color="textSecondary"
            component="p"
            variant="body2"
          >
            {printTime(data?.created_at)}
          </Typography>
        </Box>
      </Box>
    </Paper>
  );
}
