import { ADD_AUTH } from '../redux.constants';

const AuthActions = (payload) => {
  return dispatch => {
    dispatch({
      type: ADD_AUTH,
      payload
    })
  }
}

export default AuthActions;