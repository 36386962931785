import { ADD_AUTH } from '../redux.constants';

const AuthReducer = (state = null, action) => {
  switch (action.type) {
    case ADD_AUTH:
      return action.payload;
    default:
      return state;
  }
}

export default AuthReducer;