import { Button, SvgIcon } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import DropboxChooser from "react-dropbox-chooser";
import { DROPBOX_APP_KEY } from "../constants";

const DropBoxChooser = (props) => {

    const setDropBox = (files) => {
        if(files.length > 0){
            sendFileData({
                ...files[0],
                ...{
                    pickerType: 'dropbox'
                }
            });
        }
    }

    const sendFileData = (data) => {
        props.onSelected(data);
    }

    return (
        <>
            <Button className="dropbox" onClick={(e) => e.preventDefault} title="Choose File from Dropbox">
                <DropboxChooser
                    appKey={DROPBOX_APP_KEY}
                    success={files => setDropBox(files)}
                    linkType={'direct'}
                    cancel={() => console.log('closed')}
                    multiselect={false}
                    folderselect={false}
                    extensions={['.pdf', '.tt']} >
                    <SvgIcon
                        className={"chooser-svg-icon"}
                        width="50"
                        height="50"
                        viewBox="0 0 50 50"
                    >
                        <path
                            d="M32.0712 12.7473L40 6.93284L28.7248 0.154297L20.7047 5.50103L12.6846 0.154375L0 6.86575L8.0319 13.0532L1.34231 19.2147L7.91951 23.0544V25.7247C7.91951 25.9261 8.05376 26.1274 8.25511 26.2617L19.452 33.5726C19.5079 33.6529 19.5797 33.722 19.6645 33.7784C19.7988 33.8455 19.8659 33.8455 20.0001 33.8455C20.0819 33.8455 20.1388 33.8453 20.2011 33.7997C20.2562 33.7751 20.3037 33.7433 20.3356 33.7113L31.745 26.3288C31.9464 26.2617 32.0806 25.9932 32.0806 25.7918V22.6013L38.6578 18.4093L32.0712 12.7473ZM19.9764 18.9406L10.4242 13.0501L20.6554 7.08188L29.606 12.7349L19.9764 18.9406ZM26.5772 24.5168L21.1409 19.8188L30.8724 13.5772L36.3758 18.3423L26.5772 24.5168ZM28.7248 1.76511L37.5839 7.0671L31.0295 11.8518L31.0067 11.8322L30.8349 11.9429L21.9115 6.34911L21.9463 6.32881L21.9127 6.30648L28.7248 1.76511ZM12.5503 1.76511L19.3959 6.32889L9.12748 12.2349L2.41611 7.13418L12.5503 1.76511ZM9.19465 13.8456L18.8565 19.776L13.5571 24.7181L8.92622 22.0336C8.89099 22.0101 8.85323 21.9922 8.81454 21.977L3.62416 18.9463L9.19465 13.8456ZM9.26174 23.8456L13.2886 26.1946C13.3391 26.2324 13.3919 26.2608 13.4458 26.2806L13.7584 26.463L15.7905 24.5614L19.3289 21.2952V31.9664L9.26182 25.3892V23.8456H9.26174ZM26.8456 25.9933L30.7382 23.5101V25.4564L20.6711 31.9664V21.2281L26.0402 25.9261C26.1119 25.9977 26.2007 26.0434 26.2973 26.0671L26.4429 26.1945L26.6467 26.0647C26.715 26.0489 26.7822 26.025 26.8456 25.9933Z"
                            fill="#7E8D90"
                        />
                    </SvgIcon>
                </DropboxChooser>
            </Button>
        </>
    );

};

export default DropBoxChooser;
