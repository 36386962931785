import { endpoint } from "./services.constant";
import apiCall from "./api";
import { getFormData } from "./utils";

export const createFolder = (payload) => {
  return apiCall(endpoint.dms.CREATE_FOLDER, 'POST', payload);
}

export const uploadToFolder = (payload) => {
  const formData = getFormData(payload);
  return apiCall(endpoint.dms.UPLOAD, "POST", null, formData, "multipart/form-data");
}

export const getAllDocuments = (options = {}) => {
  return apiCall(endpoint.dms.ALL_DOCUMENTS, 'POST', options);
}

export const getDocuments = (payload) => {
  return apiCall(endpoint.dms.ALL_DOCUMENTS, 'POST', payload);
} 

export const moveFiles = (payload) => {
  return apiCall(endpoint.dms.MOVE_FILES, 'POST', payload);
}

export const copyObjects = (payload) => {
  return apiCall(endpoint.dms.COPY_OBJECT, 'POST', payload);
}

export const getLink = (payload) => {
  return apiCall(endpoint.dms.GET_FILE, 'POST', payload);
}

export const previewShareLink = (payload) => {
  return apiCall(endpoint.dms.PREVIEW_SHARE_LINK, 'POST', payload);
}

export const getDocumentDetailsShareLink = (payload) => {
  return apiCall(endpoint.dms.DOCUMENT_DETAILS_SHARE_LINK, 'POST', payload);
}

export const getContentCategories = (options = {}) => {
  return apiCall(endpoint.common.CONTENT_CATEGORIES, 'POST', options);
}

export const getDocumentDetails = (options = {}) => {
  return apiCall(endpoint.dms.DOCUMENT_DETAILS, 'POST', options);
}
   
export const getDocumentPreview = (options = {}) => {
  return apiCall(endpoint.dms.DOCUMENT_PREVIEW, 'POST', options);  
}

export const getDocumentPreviewLink = (options = {}) => {
  return apiCall(endpoint.dms.DOCUMENT_PREVIEW_LINK, 'POST', options);
}

export const download = (options = {}) => {
  return apiCall(endpoint.dms.DOWNLOAD, 'POST', options);
}

export const renameDocument = (options = {}) => {
  return apiCall(endpoint.dms.RENAME_DOCUMENT, 'POST', options);
}

export const deleteDocument = (options = {}) => {
  return apiCall(endpoint.dms.DELETE_DOCUMENT, 'POST', options);
}

export const checkDocumentPresent = (options = {}) => {
  return apiCall(endpoint.dms.CHECK_DOCUMENT_PRESENT, 'POST', options);
}