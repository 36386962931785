import React, { useCallback, useEffect, useRef, useState } from "react";
import {
    Box, CircularProgress
} from "@material-ui/core";
import { makeStyles, styled } from "@material-ui/core/styles";
import Toast from "../../utils/Toast";
import { useHistory, useParams } from "react-router-dom";
import { useCorporateState } from "../../redux/context/corporate-context";
import { Document, Page, pdfjs } from 'react-pdf'
import ReactPageFlip from 'react-pageflip';
import LinkIcon from '@material-ui/icons/Link';
import GetAppIcon from '@material-ui/icons/GetApp';
import copy from 'copy-to-clipboard';
import ShareIcon from '@material-ui/icons/Share';
import { checkDocumentPresent, uploadToFolder } from "../../services/dms.service";
import SignTypeConfirmation from "../documents/SignTypeConfirmation";
import { Grid } from "antd";
import { LoaderSpinner } from "@govtechsg/tradetrust-ui-components";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const SustainabilityReport = (props) => {


    const { companyDocuments, setCompanyDocuments, report, canSave } = props;

    const [uploadFileData, setUploadFileData] = useState(null);
    const [parentDocumentId, setparentDocumentId] = useState(null)
    const [fileUploadLoading, setFileUploadLoading] = useState(false)
    const [openSignDocument, setOpenSignDocument] = useState(false);

    const [document, setDocument] = useState({});

    const toastInitialValues = {
        isOpen: false,
        isSuccess: false,
        isError: false,
        message: ""
    }

    const [toast, setToast] = useState(toastInitialValues);

    const closeToast = () => {
        return setToast((prev) => ({
            ...prev,
            isOpen: false,
            isSuccess: false,
            isError: false,
            message: ""
        }));
    };

    const showError = (errorMsg) => {
        setToast((prev) => ({
            ...prev,
            isOpen: true,
            isError: true,
            isSuccess: false,
            message: errorMsg
        }));
    }

    const showSuccess = (succMsg) => {
        setToast((prev) => ({
            ...prev,
            isOpen: true,
            isError: false,
            isSuccess: true,
            message: succMsg
        }));
    }

    let { tabId } = useParams();
    let history = useHistory();

    const [activeTabDetails, setActiveTabDetails] = useState({ title: 'Sustainability Report 2022' });
    const [activeTabIndex, setActiveTabIndex] = useState(0);

    const [sustainabilityData, setSustainabilityData] = useState([]);

    const corporateState = useCorporateState();

    const downloadElemRef = useRef(null);
    const pageNumber = 1

    const [numPages, setNumPages] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const goToNextPage = () => {
        if (currentPage < numPages) {
            setCurrentPage(currentPage + 2); // Show two pages at a time for the book effect
        }
    };

    const goToPrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 2);
        }
    };
    const onDocumentLoadSuccess = ({ numPages }) => {
        console.log(numPages, "numPagesnumPagesnumPages")
        setNumPages(numPages);
    };


    const handleGoNext = () => {
        let newTabIndex = parseFloat(activeTabIndex) + 1;
        if (companyDocuments.length > newTabIndex) {
            history.push("/sustainability/tab/" + newTabIndex);
        } else {
            history.push("/sustainability/know-sdgs");
        }
    }

    const Page1 = React.forwardRef((props, ref) => {
        return (
            <div className="demoPage" ref={ref}>
                <Page pageNumber={props.number} />
                {/* <h1>Hello {props.number}</h1> */}
            </div>
        );
    });


    const [rPage, setRPage] = useState([])
    const [pdfPage, setPdfPage] = useState(1)
    const pagePdf = useRef()
    useEffect(() => {
        if (numPages > 0) {
            const temp = Array.from({ length: numPages }, (_, index) => (
                <Page1 number={index + 1} />
            ));
            setRPage(temp)
        }
    }, [numPages])

    const prevButtonClick = () => {
        pagePdf.current.pageFlip().flipPrev()
    }
    const nextButtonClick = () => {
        pagePdf.current.pageFlip().flipNext()

    }

    const handleflip = () => {
        setPdfPage(pagePdf.current.pageFlip().getCurrentPageIndex() + 1)
    }
    const hanldeOpenPdf = () => {
        window.open(corporateState?.sustainability_report, "_blank")
    }

    const copyReportLink = () => {
        if (corporateState?.sustainability_report) {
            copy(`${corporateState?.sustainability_report}`)
            showSuccess("Link Copied")
        }
        else {
            console.log("hye 2");
            showError("Sustainability report is not uploaded!")
        }
    }

    const handleShare = () => {
        var temp_name = corporateState?.sustainability_report
        if (temp_name.startsWith("http")) {
            const temp = corporateState?.sustainability_report.split("/")
            temp_name = temp[temp.length - 1]
        }

        const sendData = {
            corporate_id: report?.corporate_id,
            filename: temp_name
        }
        setFileUploadLoading(true)
        checkDocumentPresent(sendData)
            .then(res => {
                if (res.data.success) {
                    setDocument(res?.data?.data)
                    setFileUploadLoading(false)
                    setOpenSignDocument(true)
                } else {
                    setparentDocumentId(res?.data?.data?.id)
                    uploadFileDms(corporateState?.sustainability_report);
                }
                console.log(res);
            })
            .catch(err => {
                console.log(err)
            })
    }

    const uploadFileDms = async (pdfUrl) => {
        fetch(pdfUrl)
            .then(response => response.blob())
            .then(blob => {
                var temp_name = corporateState?.sustainability_report
                if (temp_name.startsWith("http")) {
                    const temp = corporateState?.sustainability_report.split("/")
                    temp_name = temp[temp.length - 1]
                }
                const fileObj = new File([blob], temp_name, { type: 'application/pdf' });

                setUploadFileData(fileObj);
            })
            .catch(error => {
                console.error('Error fetching PDF file:', error);
            });
    }

    useEffect(() => {
        if (uploadFileData)
            doUploadFile();
    }, [uploadFileData]);

    const doUploadFile = (replaceUploadFile = null) => {
        if (uploadFileData != null) {
            // console.log(uploadFileData);                
            uploadToFolder({
                parentDocumentId,
                file: uploadFileData,
                overwrite: replaceUploadFile
            }).then(res => {
                res = res.data;
                console.log(res);
                handleShare()
                // setOpenSignDocument(true);
                // }
            }).catch(err => {
                console.log(err);
                setUploadFileData(null);
            });
        }
    }

    const closeSignDialogHanlder = (type) => {
        setOpenSignDocument(false);
        if (type === "default") {
            history.push({ pathname: "/dms/verifiable/" + document?.id });
        } else if (type === "esign") {
            history.push({ pathname: "/dms/tru-sign/" + document?.id });
        }
    }


    return (
        <>
            {
                toast.isOpen && <Toast message={toast.message} isError={toast.isError} isSuccess={toast.isSuccess} closeToast={closeToast} />}
            {
                fileUploadLoading ? <>
                    <div className="loading_report_div">
                        <CircularProgress className="mb-15" color="primary" size={35} />
                    </div>

                </>
                    :
                    <div className='about_wrapper'>
                        <div style={{ display: "flex", flexWrap: "wrap", alignContent: "space-around", flexDirection: "column" }}>
                            <div className="sus-report-title">
                                <h1 style={{ textAlign: "center" }}>
                                    {activeTabDetails?.title}
                                </h1>
                                <div className="sus-report-title-btns">
                                    <div style={{ textAlign: "right" }}>
                                        <LinkIcon className="report_actions" titleAccess="Copy Link" style={{ cursor: "pointer" }} onClick={copyReportLink} />
                                        {
                                            corporateState?.sustainability_report ?
                                                <a download="Sustainability Report" href={corporateState?.sustainability_report} target="_blank" style={{ color: "black" }}><GetAppIcon className="report_actions" titleAccess="Export As PDF" style={{ cursor: "pointer" }} /></a>
                                                :
                                                <GetAppIcon className="report_actions" titleAccess="Export As PDF" style={{ cursor: "pointer" }} onClick={e => showError("Sustainability Report not uploaded!")} />
                                        }
                                        <ShareIcon className="report_actions" titleAccess="Share Report" style={{ cursor: "pointer" }} onClick={handleShare} />
                                    </div>
                                </div>
                            </div>

                            <div className="pdfbar-btngroup center_div">
                                <button type="button" onClick={hanldeOpenPdf}>
                                    Open PDF
                                </button>
                            </div>
                        </div>
                        <div style={{ display: "flex", flexWrap: "wrap", alignContent: "space-around", flexDirection: "column" }}>
                            <Document file={corporateState?.sustainability_report} onLoadSuccess={onDocumentLoadSuccess}>
                                {
                                    rPage &&
                                    <ReactPageFlip
                                        // size="stretch"
                                        width={600}
                                        height={360}
                                        ref={pagePdf}
                                        size="fixed"
                                        minWidth={315}
                                        maxWidth={600}
                                        minHeight={360}
                                        maxHeight={360}
                                        flipOnTouch
                                        autoSize
                                        onFlip={handleflip}
                                        maxShadowOpacity={0.5}
                                    >
                                        {
                                            rPage.map((val, index) => (
                                                val
                                            ))
                                        }
                                    </ReactPageFlip>
                                }
                            </Document>
                            {
                                numPages &&
                                <>
                                    <div className="pdfbar-btngroup">
                                        <button type="button" onClick={prevButtonClick} disabled={pdfPage == 1 ? 'true' : ''} >
                                            Previous page
                                        </button>

                                        <span>{pdfPage} of {numPages}</span>


                                        <button type="button" onClick={nextButtonClick} disabled={pdfPage == numPages ? 'true' : ''}>
                                            Next page
                                        </button>
                                    </div>
                                </>
                            }

                        </div>

                        <Box className="btn-sustainability-box">
                            <div className="left">
                                {
                                    (canSave) ? <>
                                        <button onClick={(e) => props?.onSave()} className="btn-sustainability secondary">
                                            Save
                                        </button>
                                    </> : null
                                }
                            </div>
                            <div className="right">
                                <button onClick={(e) => handleGoNext()} className="btn-sustainability primary">
                                    Next
                                </button>
                            </div>
                        </Box>

                    </div >
            }


            <SignTypeConfirmation
                open={openSignDocument}
                handleClose={closeSignDialogHanlder}
                document={null}
            />
        </>
    );
};

export default SustainabilityReport;