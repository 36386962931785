import React from "react";
import clsx from "clsx";
import { SigSeal } from "./helperComponents";
import RadioGroup from "@material-ui/core/RadioGroup";
import { Typography, makeStyles, Box } from "@material-ui/core";
import { useEffect } from "react";
const useStyles = makeStyles((theme) => {
  return {
    active: {
      width: 20,
      height: 20,
      border: 1,
      backgroundColor: "#66CFA4",
      borderColor: "#000000",
      marginRight: 10,
      borderRadius: 10,
    },
    inactive: {
      width: 20,
      height: 20,
      backgroundColor: "#cacfd9",
      border: 1,
      zIndex: 10000,
      marginRight: 10,
      borderColor: "#000000",
      borderRadius: 10,
    },
    flex: {
      flex: 1,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
  };
});
const FontFamilyOption = ({
  font,
  name,
  pubKey,
  color,
  handleChange,
  isSelected,
  isCurrent,
  showBorderAndSerial
}) => {

  const onClick = () => {
    handleChange(font);
  };
  const classNames = clsx(
    "svg-wrapper",
    isSelected && "svg-selected",
    isCurrent && "svg-current"
  );

  const classes = useStyles();

  return (
    <div onClick={onClick} className={classes.flex} role="menuitem">
      <Box
        borderRadius="50%"
        className={isSelected ? classes.active : classes.inactive}
      ></Box>
      <SigSeal
        pubKey={pubKey}
        name={name}
        font={font}
        color={color}
        showBorderAndSerial={showBorderAndSerial}
      />
    </div>
  );
};

export default FontFamilyOption;
