import { MessageTitle } from "@govtechsg/tradetrust-ui-components";
import { ethers, providers } from "ethers";
import React, { createContext, useContext, useEffect, useState } from "react";
import { INFURA_API_KEY, NETWORK_NAME } from "../config";
import { utils } from "@govtechsg/oa-verify";
import { magic } from "../helpers";


export const SIGNER_TYPE = {
  IDENTITY: "Identity",
  METAMASK: "Metamask",
  MAGIC: "Magic",
}

const getProvider =
  NETWORK_NAME === "local"
    ? new providers.JsonRpcProvider()
    : utils.generateProvider({ network: NETWORK_NAME, providerType: "infura", apiKey: INFURA_API_KEY });


export const ProviderContext = createContext({
  providerType: SIGNER_TYPE.IDENTITY,
  provider: getProvider,
  upgradeToMetaMaskSigner: async () => {},
  upgradeToMagicSigner: async () => {},
  account: undefined,
});



export const ProviderContextProvider = ({ children }) => {
  const [providerType, setProviderType] = useState(SIGNER_TYPE.IDENTITY);
  const [provider, setProvider] = useState(getProvider);
  const [account, setAccount] = useState();

  const initializeMetaMaskSigner = async () => {
    const { ethereum, web3 } = window;

    const metamaskExtensionNotFound = typeof ethereum === "undefined" || typeof web3 === "undefined";
    if (metamaskExtensionNotFound) throw new Error(MessageTitle.NO_METAMASK);

    await ethereum.enable();
    const injectedWeb3 = ethereum || web3.currentProvider;
    if (!injectedWeb3) throw new Error("No injected web3 provider found");
    const web3provider = new ethers.providers.Web3Provider(injectedWeb3);
    const signer = web3provider.getSigner();
    const web3account = (await web3provider.listAccounts())[0];

    setProvider(signer);
    setProviderType(SIGNER_TYPE.METAMASK);
    setAccount(web3account);
  };

  const initialiseMagicSigner = async () => {
    // needs to be cast as any before https://github.com/magiclabs/magic-js/issues/83 has been merged.
    const magicProvider = new ethers.providers.Web3Provider(magic.rpcProvider);
    const signer = magicProvider.getSigner();
    const address = await signer.getAddress();

    setProvider(signer);
    setProviderType(SIGNER_TYPE.MAGIC);
    setAccount(address);
  };

  const upgradeToMetaMaskSigner = async () => {
    if (providerType === SIGNER_TYPE.METAMASK) return;
    return initializeMetaMaskSigner();
  };

  const upgradeToMagicSigner = async () => {
    if (providerType === SIGNER_TYPE.MAGIC) return;
    return initialiseMagicSigner();
  };

  useEffect(() => {
    // Do not listen before the provider is upgraded by the app
    if (providerType !== SIGNER_TYPE.METAMASK) return;
    window.ethereum.on("accountsChanged", () => {
      return initializeMetaMaskSigner();
    });
  }, [providerType]);

  return (
    <ProviderContext.Provider
      value={{ provider, providerType, upgradeToMetaMaskSigner, upgradeToMagicSigner, account }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export const useProviderContext = () => useContext(ProviderContext);
