import { useState, forwardRef } from 'react';
import { Slide, Typography, makeStyles, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, MenuItem, withStyles, InputBase, Checkbox, TextField, Grid, FormControl, Select, Divider, CircularProgress, Box } from '@material-ui/core';
import { Cancel } from '@material-ui/icons';
import { useEffect } from 'react';
import { updateCollaborator } from '../../services/api.service';
import { MessageConstants } from '../../utils/MessageConstants';
import Toast from '../../utils/Toast';
import { documentRoles } from "../../constants";
import { renameDocument } from '../../services/dms.service';
import { printTime, printUnderScoreText } from '../../utils/Generic';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="top" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => {
  return {
    contentHeight: {
      minHeight: '100px',
      maxWidth: 'calc(100% - 64px)',
      margin: '0 auto',
      minWidth: 'calc(100% - 64px)'
    },
    title: {
      paddingTop: 20,
      textAlign: 'center',
      fontWeight: '600',
      textTransform: 'uppercase',
      letterSpacing: 0.5,
      wordSpacing: 2,
      paddingBottom: 10
    },
    stepText: {
      fontWeight: '600',
      paddingBottom: 30,
      textAlign: 'center',
      color: theme.palette.admin.main,
      letterSpacing: 1,
    },
    actionBar: {
      justifyContent: 'center',
      marginBottom: 50
    },
    nextButton: {
      width: 220,
      height: 50,
      borderRadius: 25,
      color: '#fff',
      letterSpacing: 1,
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
      }
    },
    draftButton: {
      width: 220,
      height: 50,
      borderRadius: 25,
      letterSpacing: 1,
      backgroundColor: '#fff',
      color: "#6a6a6a",
      border: '1px solid gray',
      '&:hover': {
        backgroundColor: "#fff",
        color: '#363636',
        border: '1px solid #464646'
      }
    },
    closeIcon: {
      position: 'absolute',
      right: '0',
      padding: '10px'
    },
    buttonTextCancel: {
      fontWeight: "bold",
      fontSize: 16,
      letterSpacing: "0.15em",
    },
    buttonRight: {
      marginTop: "20px",
      marginBottom: "10px",
      border: '1px solid gray',
      backgroundColor: '#fff',
      width: 150,
      height: 45,
      color: "#6a6a6a",
      borderRadius: 25,
      '&:hover': {
        backgroundColor: "#fff",
        color: '#363636',
        border: '1px solid #464646'
      }
    },
  }
});



const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 50,
    position: 'relative',
    backgroundColor: '#fff',
    border: '1px solid #66CFA4',
    fontSize: 18,
    padding: '18px 14px',
    color: theme.palette.primary.main,
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      backgroundColor: '#fff',
      borderColor: theme.palette.primary.main,
      color: theme.palette.primary.main,
      borderRadius: 50,
    },
  },
}))(InputBase);

const GreenCheckbox = withStyles((theme) => ({
  root: {
    color: theme.palette.admin.main,
    '&$checked': {
      color: theme.palette.admin.main,
    },
  },
  checked: {},
}))((props) => <Checkbox color="default" {...props} />);

const CssTextField = withStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#66CFA4",
      },
      "&:hover fieldset": {
        borderColor: "#66CFA4",
      },
      borderRadius: 50,
    },
  },
})(TextField);

const AuditLog = (props) => {

  const toastInitialValues = {
    isOpen: false,
    isSuccess: false,
    isError: false,
    message: ""
  }

  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [documentLogs, setDocumentLogs] = useState({});

  const [toast, setToast] = useState(toastInitialValues);

  const sendClose = (anythingChange = false) => {
    props.handleClose(anythingChange);
  }

  const onClose = () => {
    sendClose();
  }

  const closeToast = () => {
    return setToast((prev) => ({
      ...prev,
      isOpen: false,
      isSuccess: false,
      isError: false,
      message: ""
    }));
  };

  useEffect(() => {
    setOpen(props?.open);
    setDocumentLogs(props?.documentLogs);
  }, [props]);



  return (
    <>
      {toast.isOpen && <Toast message={toast.message} isError={toast.isError} isSuccess={toast.isSuccess} closeToast={closeToast} />}
      <Dialog
        fullWidth={true}
        maxWidth='md'
        open={open}
        onClose={onClose}
        className={classes.dialogRoot}
        keepMounted={false}
        TransitionComponent={Transition}
        PaperProps={{
          style: { borderRadius: 20, maxHeight: 'calc(100% - 44px)' }
        }}
      >
        <div className={classes.closeIcon}>
          <IconButton
            onClick={onClose}
          >
            <Cancel />
          </IconButton>
        </div>
        <DialogTitle gutterBottom>
          <Typography variant='h5' className={classes.title}>
            Audit Log
          </Typography>
        </DialogTitle>

        <DialogContent className={classes.contentHeight}>

          <div className="table-responsive">
            <table className="table table-default table-green-head">
              <thead>
                <tr>
                  <th>User Email</th>
                  <th>Time</th>
                  <th>Type</th>
                  <th>Particular</th>
                </tr>
              </thead>
              <tbody>
                {documentLogs && documentLogs.length > 0 ?
                  documentLogs.map((logModel) => {
                    return (
                      <tr key={logModel?.id}>
                        <td>{logModel?.user?.email}</td>
                        <td>{printTime(logModel?.created_at)}</td>
                        <td className='capital-case'>{printUnderScoreText(logModel?.type)}</td>
                        <td>{logModel?.particular}</td>
                      </tr> 
                    );
                  }) : <>

                    <tr key={-1}>
                      <td colSpan={4}>No Logs Found</td>
                    </tr>

                  </>
                }

              </tbody>
            </table>
          </div>

        </DialogContent>

      </Dialog>

    </>
  );


}

export default AuditLog;