// External Imports
import { useContractFunctionHook } from "@govtechsg/ethers-contract-hook";
import React, { useCallback, useEffect, useState } from "react";

import { useProviderContext } from "./contexts/provider";
import { useTokenInformationContext } from "./contexts/TokenInformationContext";
import { useTokenRegistryContract } from "./hooks/useTokenRegistryContract";
import { AssetManagementActions } from "./AssetManagementActions";
import { AssetManagementForm } from "./AssetManagementForm";
import { DocumentStatus } from "./DocumentStatus";
import { AssetManagementTags } from "./AssetManagementTags";
import { Box, Container } from '@material-ui/core';

export const AssetManagementApplication = ({
  tokenId,
  tokenRegistryAddress,
  setShowEndorsementChain,
  document,
  verificationStatus,
  account,
  corporateList,
  loadingEndrosmentChain
}) => {
  const {
    approvedHolder,
    holder,
    approvedBeneficiary,
    beneficiary,

    changeHolder,
    changeHolderState,

    endorseBeneficiary,
    endorseBeneficiaryState,

    transferTo,
    transferToState,

    destroyTokenState,
    destroyToken,

    isSurrendered,
    isTokenBurnt,
    isTitleEscrow,

    approveNewTransferTargets,
    approveNewTransferTargetsState,

    transferToNewEscrow,
    transferToNewEscrowState,

    documentOwner,
    restoreToken,
    restoreTokenState,

    holderName,
    beneficiaryName,
    approvedBeneficiaryName,
    approvedHolderName
  } = useTokenInformationContext();

  // console.log("isTitleEscrow", isTitleEscrow, approvedHolder,
  // holder,
  // approvedBeneficiary,
  // beneficiary)

  const [assetManagementAction, setAssetManagementAction] = useState(AssetManagementActions.None);
  const { upgradeToMetaMaskSigner, provider } = useProviderContext();
  const { tokenRegistry } = useTokenRegistryContract(tokenRegistryAddress, provider);
  // Check if direct owner is minter, useContractFunctionHook value returns {0: boolean}
  const { call: checkIsMinter, value: isMinter } = useContractFunctionHook(tokenRegistry, "isMinter");

  useEffect(() => {
    if (isTitleEscrow === false && account) {
      checkIsMinter(account);
    }
  }, [account, checkIsMinter, isTitleEscrow]);
  const onSurrender = () => {
    // Change to surrendered state
    transferTo(tokenRegistryAddress);
  };

  const onDestroyToken = () => {
    destroyToken(tokenId);
  };

  const onRestoreToken = (lastBeneficiary, lastHolder) => {
    if (!lastBeneficiary || !lastHolder) throw new Error("Ownership data is not found");
    restoreToken(lastBeneficiary, lastHolder);
  };

  const onSetFormAction = useCallback(
    (assetManagementActions) => {
      setAssetManagementAction(assetManagementActions);
    },
    [setAssetManagementAction]
  );

  useEffect(() => {
    // onSetFormAction(AssetManagementActions.None);
  }, [account, onSetFormAction]); // unset action panel to none, whenever user change metamask account


  return (
    <Container maxWidth={false} id="title-transfer-panel">
      {assetManagementAction === AssetManagementActions.None && (
        <Box>
          {/* <DocumentStatus document={document} verificationStatus={verificationStatus} /> */}
          <AssetManagementTags document={document} verificationStatus={verificationStatus} />
        </Box>
      )}
      <div>
        {isTitleEscrow !== undefined && (
          <AssetManagementForm
            account={account}
            // onConnectToWallet={upgradeToMetaMaskSigner}
            beneficiary={beneficiary}
            approvedBeneficiary={approvedBeneficiary}
            holder={holder}
            approvedHolder={approvedHolder}
            documentOwner={documentOwner}
            tokenRegistryAddress={tokenRegistryAddress}
            isSurrendered={isSurrendered}
            isTokenBurnt={isTokenBurnt}
            isTitleEscrow={isTitleEscrow}
            isMinter={isMinter?.[0]}
            tokenId={tokenId}
            corporateList={corporateList}
            loadingEndrosmentChain={loadingEndrosmentChain}
            formAction={assetManagementAction}
            onSetFormAction={onSetFormAction}

            surrenderingState={transferToState}
            destroyTokenState={destroyTokenState}

            onSurrender={onSurrender}
            onTransferHolder={changeHolder}

            holderTransferringState={changeHolderState}
            onEndorseBeneficiary={endorseBeneficiary}

            beneficiaryEndorseState={endorseBeneficiaryState}
            
            onApproveNewTransferTargets={approveNewTransferTargets}
            approveNewTransferTargetsState={approveNewTransferTargetsState}
            
            onTransferToNewEscrow={transferToNewEscrow}
            transferToNewEscrowState={transferToNewEscrowState}
            setShowEndorsementChain={setShowEndorsementChain}
            
            onDestroyToken={onDestroyToken}
            onRestoreToken={onRestoreToken}
            restoreTokenState={restoreTokenState} 

            holderName={holderName}
            beneficiaryName={beneficiaryName}
            approvedBeneficiaryName={approvedBeneficiaryName}
            approvedHolderName={approvedHolderName}
          />
        )}
      </div>
    </Container>
  );
};
