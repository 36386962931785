import React, { useEffect } from "react";
import {
  Box,
  Button,
  Grid,
  makeStyles,
  Paper,
  SvgIcon,
  Typography,
} from "@material-ui/core";
import { useHistory, useLocation } from "react-router";


const useStyles = makeStyles((theme) => {
  return {
    content: {
      display: "flex",
      width: `calc(100% - ${240}px)`,
      marginLeft: 240, 
      minHeight: "80vh",
      background: 'transparent'
    },
    paper: {
      padding: theme.spacing(1),
      margin: theme.spacing(2),
      textAlign: "center",
    },
    flex: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      margin: theme.spacing(1),
    },
    heading: {
      // width: "643px",
      // height: "45px",
      left: "498px",
      top: "253px",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "30px",
      lineHeight: "150%",
      letterSpacing: "0.1em",
      marginBottom: 20
    },
    downloadIcon: {
      margin: theme.spacing(2),
      width: "25px",
      height: "25px",
      left: "1146px",
      top: "263px",
      cursor: "pointer",
    },
    watchIcon: {
      marginRight: theme.spacing(1),
      left: "4.17%",
      right: "4.17%",
      top: "18.75%",
      bottom: "18.75%",
      cursor: "pointer",
    },
    subHeading: {
      width: "345px",
      height: "18px",
      fontWeight: 500,
      fontSize: "20px",
      lineHeight: "89.5%",
      letterSpacing: "0.1em",
      marginBottom: theme.spacing(6),
    },
    contentText: {
      width: "395px",
      height: "15px",
      left: "720px",
      top: "436px",
      fontWeight: 500,
      fontSize: "17px",
      lineHeight: "89.5%",
      color: "#262727",
      textAlign: "left",
      marginLeft: theme.spacing(2),
    },
    signSvgIcon: {
      width: "75.95px",
      height: "107px",
    },
    button: {
      margin: theme.spacing(4),
      height: 45,
      borderRadius: 25,
    },
    buttonText: {
      fontWeight: 600,
      fontSize: 16,
      lineHeight: "24px",
      letterSpacing: "0.15em",
      color: "#FFFDFD",
    },
  };
});

function Sign() {
  const classes = useStyles();
  useEffect(() => {
    document.title = `TruDoc | Sign`;
  }, []);

  return (
    <Box className={classes.content}>
      <Grid
        container
        spacing={0}
        // direction="column"
        alignItems="center"
        // justify="center"
      >
        <Grid item xs={12}>
          <Paper className={`${classes.paper} doc-sign-com-wrp`} elevation={0}>
            <Grid container spacing={2} alignItems="center" justify="center">
              <Grid item xs={12} className={classes.flex}>
                <Typography className={classes.heading} color="primary">
                  {/* {response ? 'Document signing complete!' : 'Document has been sent for signing'} */}
               Document has been sent to selected users, We'll notify them via mail too.
                </Typography>
              </Grid> 
              {/* {response &&
                <Grid item xs={12} className={classes.subHeading}>
                  <div className={classes.flex}>
                    <Typography variant="h6" style={{ marginRight: 16 }}>
                      Created by
                    </Typography>
                    <Typography variant="h6" color="primary">
                      TRUDOC.TK
                    </Typography>
                  </div>
                </Grid>
              } */}
             

              <Grid container className="">
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Sign;
