import { useState, useEffect } from "react";
import styled from "@emotion/styled";
import {
  AppBar,
  Box,
  Button,
  makeStyles,
  Toolbar,
  SvgIcon,
  Menu,
  MenuItem,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  withStyles,
  Switch,
  Typography,
  Grid,
  FormControlLabel,
} from "@material-ui/core";
import { NavLink, useHistory } from "react-router-dom";
import { useAuth } from "../hooks";
import tw from "twin.macro";
import Modal from "@material-ui/core/Modal";
import { AddressCell } from "./AddressCell";
import tick from "../static/svg/tick.svg";
import { Link } from "react-router-dom";
import Backdrop from "@material-ui/core/Backdrop";
import Table from "@material-ui/core/Table";
import CloseIcon from "@material-ui/icons/Close";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { TextField, CircularProgress } from '@material-ui/core';
import Fade from "@material-ui/core/Fade";
import SimpleTableRow from "./SimpleTableRow";
import { format } from "date-fns";
import EndorsementJourney from "./EndorsementJourney";
import { changeDocHolder } from "../services/api.service";

const CssTextField = withStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      borderColor: "#BF85FA",
      color: "#BF85FA",
      "& fieldset": {
        color: "#BF85FA",
        borderColor: "#BF85FA",
      },
      "&:focus": {
        color: "#BF85FA",
        borderColor: "#BF85FA",
      },
      "&:hover fieldset": {
        borderColor: "#BF85FA",
      },
      borderRadius: 10,
    },
  },
})(TextField);


const useStyles = makeStyles((theme) => {
  return {
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      backgroundColor: "#ffffff",
      borderRadius: "8px",
      outline: "none",
      padding: theme.spacing(2, 4, 3),
    },
    innerModal: {
      display: "flex",
      justifyContent: "space-between",
    },
    endrosmentChain: {
      textAlign: "center",
      color: "#424343",
      fontFamily: "Poppins",
      fontWeight: 600,
      fontSize: "20px",
      lineHeight: "30px",
      marginTop: "20px",
      marginBottom: "20px",
    },
    Scroll: {
      overflow: "scroll",
      height: "400px",
      alignSelf: "center",
    },
    buttonModal: {
      width: "183px",
      height: "36px",
      backgroundColor: "#66CFA4",
      borderRadius: "20px",
      alignSelf: "center",
      marginTop: "50px",
      alignItems: "center",
      justifyContent: "center",
    },
    transferDoc: {
      fontSize: "12px",
      fontWeight: 600,
      fontFamily: "Poppins",
      lineHeight: "18px",
      color: "#ffffff",
      textAlign: "center",
    },
    tick: {
      width: "33.33px",
      height: "33.33px",
    },
    listHeading: {
      fontSize: "17px",
      fontWeight: 500,
      fontFamily: "Poppins",
      lineHeight: "15.21px",
      color: "#262727",
    },
    statusTitle: {
      fontSize: "15px",
      fontWeight: 500,
      fontFamily: "Poppins",
      lineHeight: "19px",
      color: "#262727",
    },
    listHeadingColor: {
      fontSize: "17px",
      fontWeight: 500,
      fontFamily: "Poppins",
      lineHeight: "15.21px",
      color: "#66CFA4",
      marginLeft: "5px",
    },
    listHeadingColorMain: {
      fontSize: "17px",
      fontWeight: 500,
      paddingTop: "2px",
      fontFamily: "Poppins",
      lineHeight: "15.21px",
      color: "#66CFA4",
    },
  };
});

export const EndrosmentChain = ({
  endorsementChain,
  handleCloseModal,
  verifyResponse,
  openModal,
  tokenId,
  tokenRegistryAddress
}) => {
  const classes = useStyles();
  let previousOwner = "";
  let previousHolder = "";
  let noOfNewHolder = 0;
  let tableRowIndex = 0;
  let noOfTransferActions = 0;
  const tableRows = [
    // By default there will always be this 'Document has been issued'
    <SimpleTableRow
      key={tableRowIndex++}
      index={tableRowIndex++}
      actionTitle="Document has been issued"
      displayDashHead={false}
      displayDot={true}
      displayDashTail={true}
    />,
  ];
  let totalNumberOfTransferActions = 0;
  let totalNumberOfNewHolderEvents = 0;
  let scanPreviousHolder = "";
  endorsementChain &&
    endorsementChain.forEach((event) => {
      if (event.eventType !== "Transfer") return;

      const ownerChangeEvent = event;
      totalNumberOfTransferActions +=
        ownerChangeEvent.holderChangeEvents.length;

      ownerChangeEvent.holderChangeEvents.forEach((holderChangeEvent) => {
        if (scanPreviousHolder !== holderChangeEvent.holder)
          totalNumberOfNewHolderEvents++;
        scanPreviousHolder = holderChangeEvent.holder;
      });
    });

  // for each erc721 event, build the ui according to the event type.
  endorsementChain &&
    endorsementChain.forEach((tradetrustErc721Event, eventIndex) => {
      const isLastEvent = eventIndex + 1 === endorsementChain.length;
      switch (tradetrustErc721Event.eventType) {
        // for transfer event we need to loop through the holderChangeEvents to get each holder change.
        case "Transfer":
          const ownerChangeEvent = tradetrustErc721Event;

          if (
            !ownerChangeEvent.holderChangeEvents ||
            !ownerChangeEvent.beneficiary
          ) {
            return new Error(
              "Invalid Event: Transfer Event does not have new owner or new holder address"
            );
          }

          ownerChangeEvent.holderChangeEvents.forEach(
            (holderChangeEvent, holderIndex) => {
              const isNewOwnerAddress =
                previousOwner !== ownerChangeEvent.beneficiary;
              const isNewHolderAddress =
                previousHolder !== holderChangeEvent.holder;
              if (isNewHolderAddress) noOfNewHolder++;
              const isFirstRowHolder = eventIndex === 0 && holderIndex === 0;
              noOfTransferActions++;

              // ui for each row of holderChangeEvent
              tableRows.push(
                <tr className="table-row" key={tableRowIndex++}>
                  <td className="table-cell">
                    {isNewOwnerAddress && (
                      <div className={classes.statusTitle}>
                        Endorse change of ownership
                      </div>
                    )}
                    {isNewHolderAddress && !isNewOwnerAddress && (
                      <div className={classes.statusTitle}>
                        Transfer holdership
                      </div>
                    )}
                    <div className={classes.statusTitle}>
                      {format(
                        new Date(holderChangeEvent.timestamp),
                        "do MMM yyyy, hh:mm aa"
                      )}
                    </div>
                  </td>
                  <td className="table-cell">
                    <AddressCell
                      address={ownerChangeEvent.beneficiary}
                      titleEscrowAddress={ownerChangeEvent.documentOwner}
                      isNewAddress={isNewOwnerAddress}
                      displayDashHead={!(isLastEvent && !isNewOwnerAddress)}
                      displayDashTail={!isLastEvent}
                      addressName={ownerChangeEvent.beneficiaryName}
                    />
                  </td>
                  <td className="table-cell">
                    <AddressCell
                      address={holderChangeEvent.holder}
                      addressName={holderChangeEvent.holderName}
                      titleEscrowAddress={ownerChangeEvent.documentOwner}
                      isNewAddress={isNewHolderAddress}
                      displayDashHead={
                        !(
                          isFirstRowHolder ||
                          (noOfNewHolder === totalNumberOfNewHolderEvents &&
                            !isNewHolderAddress)
                        )
                      }
                      displayDashTail={
                        !(
                          (isFirstRowHolder &&
                            noOfTransferActions ===
                              totalNumberOfTransferActions) ||
                          noOfNewHolder === totalNumberOfNewHolderEvents
                        )
                      }
                    />
                  </td>
                </tr>
              );
              previousOwner = ownerChangeEvent.beneficiary;
              previousHolder = holderChangeEvent.holder;
            }
          );
          break;

        // ui for each surrender row
        case "Surrender":
          tableRows.push(
            <SimpleTableRow
              key={tableRowIndex++}
              index={tableRowIndex++}
              date={format(
                new Date(tradetrustErc721Event?.eventTimestamp ?? 0),
                "do MMM yyyy, hh:mm aa"
              )}
              actionTitle="Document surrendered to issuer"
              displayDashHead={true}
              displayDot={true}
              displayDashTail={!isLastEvent}
            />
          );
          break;

        // ui for each accepted surrender row
        case "Burnt":
          tableRows.push(
            <SimpleTableRow
              key={tableRowIndex++}
              index={tableRowIndex++}
              date={format(
                new Date(tradetrustErc721Event?.eventTimestamp ?? 0),
                "do MMM yyyy, hh:mm aa"
              )}
              actionTitle="Surrender of document accepted"
              displayDashHead={true}
              displayDot={true}
              displayDashTail={!isLastEvent}
            />
          );
          break;

        // ui for each transfer to wallet row
        case "Transfer to Wallet":
          tableRows.push(
            <tr className="table-row" key={tableRowIndex++}>
              <td className="table-cell">
                <div
                  className={classes.statusTitle}
                  data-testid="transferred-to-wallet"
                >
                  Transferred to wallet
                </div>
                <div className={classes.statusTitle}>
                  {format(
                    new Date(tradetrustErc721Event?.eventTimestamp ?? 0),
                    "do MMM yyyy, hh:mm aa"
                  )}
                </div>
              </td>
              <td className="table-cell">
                <EndorsementJourney
                  displayDashHead={true}
                  displayDot={true}
                  displayDashTail={!isLastEvent}
                />
                <div className={classes.statusTitle}>
                  {tradetrustErc721Event.documentOwner}
                </div>
              </td>
              <td className="table-cell">
                <EndorsementJourney
                  displayDashHead={
                    !isLastEvent &&
                    noOfTransferActions !== totalNumberOfTransferActions
                  }
                  displayDot={false}
                  displayDashTail={
                    !isLastEvent &&
                    noOfTransferActions !== totalNumberOfTransferActions
                  }
                />
              </td>
            </tr>
          );
          break;

        // default not needed as eventType has only 4 possibilities which are all accounted for
        default:
          // trace("Unknown event type please check event history");
          break;
      }
    });

  return (
    <Modal
      className={classes.modal}
      open={openModal}
      onClose={handleCloseModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={openModal}>
        <div className={classes.paper}>
          <EndorsementChainLayoutStyle className="container pb-40">
            <div>
              <div className={classes.innerModal}>
                <div>
                  <List>
                    {[0].map((value) => {
                      return (
                        <ListItem key={value} button>
                          <ListItemAvatar>
                            <Avatar src={tick} className={classes.tick} />
                          </ListItemAvatar>
                          <ListItemText
                            primary={verifyResponse.data.data.map((res) => {
                              return res.data && res.data[0] ? (
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "flex-start",
                                    justifyContent: "flex-start",
                                  }}
                                >
                                  <Typography className={classes.listHeading}>
                                    Document has been issued by
                                  </Typography>
                                  <Typography
                                    className={classes.listHeadingColor}
                                  >
                                    {res.data[0].location}
                                  </Typography>
                                </div>
                              ) : null;
                            })}
                          />
                        </ListItem>
                      );
                    })}
                  </List>
                </div>
                <div
                  className={{
                    display: "flex",
                    alignSelf: "flex-end",
                  }}
                >
                  <IconButton
                    color="inherit"
                    aria-label="close"
                    onClick={handleCloseModal}
                    edge="end"
                    disableFocusRipple={true}
                  >
                    <CloseIcon />
                  </IconButton>
                </div>
              </div>
              <Typography className={classes.endrosmentChain}>
                ENDORSEMENT CHAIN
              </Typography>
              <div className="container pb-40">
                <div className={classes.Scroll}>
                  <table>
                    <thead>
                      <tr>
                        <th className="table-cell">Date</th>
                        <th className="table-cell">Owner</th>
                        <th className="table-cell">Holder</th>
                      </tr>
                    </thead>

                    <tbody>{endorsementChain && tableRows}</tbody>
                  </table>
                </div>
              </div>
            </div>
          </EndorsementChainLayoutStyle>
        </div>
      </Fade>
    </Modal>
  );
};
const EndorsementChainLayoutStyle = styled.div`
  .back-button {
    ${tw`text-black cursor-pointer mb-2`}

    &[data-disabled="true"] {
      ${tw`text-black cursor-not-allowed`}
    }
  }

  .table-row {
    ${tw`bg-white`}
  }

  .table-row > div {
    display: table-cell;
  }
  .table-cell {
    position: relative;
    vertical-align: top;
    border-top: none;
  }

  thead {
    th {
      ${tw`px-4 py-2`}
    }
  }

  tbody {
    td {
      ${tw`px-4 py-4`}
    }
  }

  .date {
    ${tw`text-sm font-semibold text-black flex justify-end text-right`}
  }

  .action-title {
    ${tw`text-lg text-black font-semibold flex justify-end text-right`}
  }

  .address {
    word-break: break-word;
  }
`;

export default EndrosmentChain;
