import React, { useRef, useEffect, useState } from "react";
import WebViewer from "@pdftron/webviewer";
import { Button, CircularProgress, makeStyles } from "@material-ui/core";
import {
  Box,
  FormControl,
  Grid,
  Typography,
} from "@material-ui/core";
import Toast from "../Toast";
import { useAuth } from "../../hooks";
import "./Viewer.css";
import { isNotEmpty, svgToImage } from "../Generic";
import { MenuItem } from "@material-ui/core";
import { Select } from "@material-ui/core";
import { getSignList } from "../../services/api.service";
import { MessageConstants } from "../MessageConstants";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: "transparent",
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  paper1: {
    display: "flex",
    flexWrap: "wrap",
    backgroundColor: "transparent",
    "& > *": {
      margin: theme.spacing(3),
      padding: theme.spacing(2),
      width: "100%",
      textAlign: "center",
    },
  },
  edocuments: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  paper2: {
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(3),
      padding: theme.spacing(2),
      width: "100%",
    },
  },
  paper3: {
    backgroundColor: "transparent",
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(3),
      padding: theme.spacing(2),
      width: "100%",
      height: 350,
    },
  },
  dropIcon: {
    width: "150px",
    padding: theme.spacing(2),
  },
  docOptions: {
    cursor: "pointer",
    background: "#66cfa3",
    padding: "5px 20px",
    color: "#fff",
    borderRadius: "20px",
  },
  icon: {
    margin: theme.spacing(1),
  },
  formControl: {
    minWidth: "100%",
  },
  contentMargin: {
    marginTop: theme.spacing(3),
  },
  addSign: {
    marginTop: "10px",
    color: "#fff",
    background: "#3da543",
    padding: "3px 8px",
    borderRadius: "10px",
    "&:hover": {
      color: "#fff",
      background: "#3da543",
    },
  },
  addTextField: {
    color: "#3da543",
    background: "#d9d9d940",
    padding: "3px 8px",
    borderRadius: "10px",
  },
  selectBox: {
    marginRight: "20px",
    display: "flex",
    flexDirection: "column",
    rowGap: "10px",
  },
  webViewerStyle: {
    overflow: "hidden",
    width: "calc(100vw - 620px)",
    height: "100vh",
  },
  Select: {
  },
  SelectImg: {
    height: "100px",
    width: "auto"
  },
  hrLine: {
    background: "#ccc",
    width: "100%"
  }
}));

let signIds = {};
const signPriority = {};

const InsertSignatures = (props) => {
  const toastInitialValues = {
    isOpen: false,
    isSuccess: false,
    isError: false,
    message: "",
  };

  let [instance, setInstance] = useState(null);
  const [assignee, setAssignee] = useState("");
  const [toast, setToast] = useState(toastInitialValues);
  const classes = useStyles();
  const viewer = useRef(null);
  const filePicker = useRef(null);
  const { user } = useAuth();

  const [signature, setSignature] = useState({ Fullname: [], Initial: [] });

  const [selectedSign, setSelectedSign] = useState("");
  const [selectedValue, setSelectedValue] = useState(0);

  const [webViewerInitiated, setWebViewerInitiated] = useState(false);

  const history = useHistory();

  const handleChange = (e) => {
    setSelectedValue(e.target.value);
    setSelectedSign(signature.Fullname[e.target.value]);
  }

  const getSignature = () => {
    getSignList()
      .then((res) => {
        if (res.data.length > 0) {
          let x = [], y = [], base64svg = "";
          for (const e of res.data) {
            base64svg = `data:image/svg+xml;base64,${e.data}`; //convert svg to base64svg
            svgToImage(base64svg, 400, 600, 'png', (base64Img) => {
              if (e.type === "FULLNAME") {
                x.push(base64Img);
              } else {
                y.push(base64Img);
              }
              setSignature({ Fullname: x, Initial: y });
              if (x.length)
                setSelectedSign(x[0]);
            });
          }
        } else if (res.data.length === 0) {
          setToast((prev) => ({
            ...prev,
            isOpen: true,
            isError: true,
            message: MessageConstants.REQUIRED_ATLEAST_ONE_SIGNATURE
          }));
        }
        console.log("Response", res.data);
      })
      .catch((err) => {
        console.log(err.message);
        setToast((prev) => ({
          ...prev,
          isOpen: true,
          isError: true,
          message: MessageConstants.FAILED_TO_LOAD_SIGNATURE
        }));
      });
  }

  useEffect(() => {
    getSignature();
  }, []);

  useEffect(() => {
    if (instance == null) {
      if (!webViewerInitiated) {
        setWebViewerInitiated(true);
        startPdfViewer();
      }
    }
  }, [selectedSign]);

  const closeToast = () => {
    return setToast((prev) => ({
      ...prev,
      isOpen: false,
      isSuccess: false,
      isError: false,
      message: "",
    }));
  };

  const startPdfViewer = () => {
    WebViewer(
      {
        path: "/webviewer/lib",
        css: "/style.css",
        fullAPI: true,
        disableLogs: true,
        disabledElements: [
          "ribbons",
          "toggleNotesButton",
          "searchButton",
          "menuButton",
          "rubberStampToolGroupButton",
          "stampToolGroupButton",
          "fileAttachmentToolGroupButton",
          "calloutToolGroupButton",
          // "undo",
          "redo",
          "eraserToolButton",
          // "toolsHeader",
          "signatureToolGroupButton",
          "leftPanelButton",
          "viewControlsButton",
          "selectToolButton",
          "annotationPopup",
          "toolsOverlay",
          "defaultSignatureDeleteButton",
          "contextMenuPopup",
          "signatureModal",
          "underlineToolGroupButton",
          "highlightToolGroupButton",
          "shapeToolGroupButton",
          "freeTextToolGroupButton",
          "freeHandHighlightToolGroupButton",
          "freeHandToolGroupButton",
          "stickyToolGroupButton",
          "squigglyToolGroupButton",
          "strikeoutToolGroupButton",
          "redoButton"
        ],
      },
      viewer.current
    ).then((instance) => {
      instance = instance;
      setInstance(instance);
      const { documentViewer } = instance.Core;
      const annotationManager = documentViewer.getAnnotationManager();
      annotationManager.setReadOnly(false);
      annotationManager.setCurrentUser(user.username);
      instance.UI.loadDocument(props.file, { filename: props?.fileName });
      annotationManager.addEventListener('annotationChanged', (annotations, action) => {
        if (action == "add") {
          annotationManager.deselectAllAnnotations();
          // let annotId = annotations.Id ? annotations.Id : annotations[0].Id;    
          annotationManager.selectAnnotations(annotations);
        }
      });
    });
  };

  const addSign = async () => {
    const { annotationManager, Annotations } = instance.Core;
    const annot = new Annotations.StampAnnotation({
      PageNumber: 1,
      X: 100,
      Y: 50,
      Width: 300,
      Height: 100,
    });
    await annot.setImageData(selectedSign);
    annotationManager.addAnnotation(annot);
    annotationManager.redrawAnnotation(annot);
  }

  const addTextField = async () => {
    const { annotationManager, Annotations } = instance.Core;
    const annot = new Annotations.FreeTextAnnotation(Annotations.FreeTextAnnotation.Intent.FreeText, {
      PageNumber: 1,
      X: 100,
      Y: 50,
      Width: 100,
      Height: 30,
      TextAlign: 'center',
      TextVerticalAlign: 'center',
      TextColor: new Annotations.Color(0, 0, 0, 1),
      StrokeColor: new Annotations.Color(0, 0, 0, 0),
    });
    console.log(annot);
    annot.setContents('Enter Text Here');
    annotationManager.addAnnotation(annot);
    annotationManager.redrawAnnotation(annot);
  }

  const uploadAfterSigning = async () => {
    const { docViewer, annotManager } = instance;
    const doc = docViewer.getDocument();
    const xfdfString = await annotManager.exportAnnotations({
      widgets: true,
      fields: true,
    })
    const options = { xfdfString };
    const data = await doc.getFileData(options);
    const arr = new Uint8Array(data);
    const blob = new Blob([arr], { type: "application/pdf" });
    props.uploadDocument(blob);
  };

  useEffect(() => {
    if (props.sendFile) {
      uploadAfterSigning();
    }
  }, [props.sendFile]);

  const goToSignaturesPage = () => {
    history.replace("/create-esign");
  }

  return (
    <>
      {toast.isOpen && (
        <Toast
          message={toast.message}
          isError={toast.isError}
          isSuccess={toast.isSuccess}
          closeToast={closeToast}
        />
      )}
      {
        props.isloading ? <Grid container>
          <Grid container item xs={12}>
            <Box width="100%" className="text-center">
              <CircularProgress color="primary" size={50} />
            </Box>
          </Grid>
        </Grid> :
          <div className={"prepareDocument"}>
            <div style={{ display: "flex", flexDirection: "row", flex: "grow" }}>

              <div className={classes.selectBox}>
                <Grid item>
                  <Typography className="fileupl-title">
                    Select Signature*:
                  </Typography>
                </Grid>
                <Grid item>
                  <FormControl className={classes.Select + ` sign-document-signs sign-document-signs-field`}>
                    <Select
                      labelId="select-signature"
                      id="select-corporate-comp"
                      value={selectedValue}
                      label="Select Signature"
                      onChange={handleChange}
                      required
                    >
                      {
                        signature.Fullname.map((e, i) => {
                          return <MenuItem key={i} value={i}>
                            <img className={classes.SelectImg} src={e} alt="signature" />
                          </MenuItem>
                        })
                      }
                      {
                        isNotEmpty(signature?.Fullname) && signature?.Fullname.length <= 0 ? <>
                          <MenuItem onClick={goToSignaturesPage} key={-1} value={-1}>
                            Create new Signature
                          </MenuItem>
                        </> : null
                      }
                    </Select>
                  </FormControl>
                </Grid>
                <Button
                  className={classes.addSign}
                  onClick={() => addSign()}
                >
                  Add signature
                </Button>
                <hr className={classes.hrLine} />
                <Button
                  className={classes.addTextField}
                  onClick={() => addTextField()}
                >
                  Add Text Field
                </Button>

              </div>
              <div
                className={`${classes.webViewerStyle} webviewer`}
                ref={viewer}
              ></div>
            </div>
            <input type="file" ref={filePicker} style={{ display: "none" }} />
          </div>
      }
    </>
  );
};

export default InsertSignatures;
