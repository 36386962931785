import { Box, Button, CircularProgress, Dialog, DialogContent, Grid, makeStyles, Paper, StepLabel, TextField, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { superSettingsList, superUpdateSetting } from "../../services/api.service";
import { useAuth } from "../../hooks";
import { printTime, printUnderScoreText } from "../../utils/Generic";
import { DoneAll, Edit, InsertLink } from "@material-ui/icons";
import Toast from "../../utils/Toast";

const useStyles = makeStyles((theme) => {
  return {
    paper: {
      padding: theme.spacing(1),
      margin: theme.spacing(2),
      backgroundColor: 'transparent',
    },
    content: {
      display: "block",
      width: `calc(100% - ${300}px)`,
      marginLeft: 280,
      minHeight: "88vh",
    },
  };
});

function SuperSettings() {


  const toastInitialValues = {
    isOpen: false,
    isSuccess: false,
    isError: false,
    message: ""
  }

  const [toast, setToast] = useState(toastInitialValues);

  const closeToast = () => {
    return setToast((prev) => ({
      ...prev,
      isOpen: false,
      isSuccess: false,
      isError: false,
      message: ""
    }));
  };

  const showError = (errorMsg) => {
    setToast((prev) => ({
      ...prev,
      isOpen: true,
      isError: true,
      isSuccess: false,
      message: errorMsg
    }));
  }

  const showSuccess = (succMsg) => {
    setToast((prev) => ({
      ...prev,
      isOpen: true,
      isError: false,
      isSuccess: true,
      message: succMsg
    }));
  }

  const classes = useStyles();
  const { user } = useAuth();

  const [setting, setSetting] = useState({});
  const [settings, setSettings] = useState([]);
  const [loading, setLoading] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [settingVal, setSettingVal] = useState("");

  useEffect(() => {

    document.title = `TruDoc | Super Settings`;
    loadSettings();

  }, []);

  const loadSettings = () => {
    setLoading(true);

    superSettingsList()
      .then((res) => {
        setSettings(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
        setLoading(false);
      });
  }

  const editSetting = (setting) => {
    setSetting(setting);
    setSettingVal(setting?.setting_value);
    setShowModal(true);
  }

  const updateSetting = () => {
    setUpdateLoading(true);

    superUpdateSetting({
      id: setting?.id,
      value: settingVal
    })
      .then((res) => {
        loadSettings();
        setUpdateLoading(false);
        showSuccess("Setting has been updated");
        setShowModal(false);
      })
      .catch((err) => {
        console.log(err.message);
        setUpdateLoading(false);
      });
  }

  return (
    <>
      <Box className={classes.content}>

        {toast.isOpen && <Toast message={toast.message} isError={toast.isError} isSuccess={toast.isSuccess} closeToast={closeToast} />}

        <Grid
          container
          className="d-block mt-15px mb-15px"
          spacing={0}
        >
          <Grid item xs={12}>
            <h4 className="dashboard-title-header">Settings</h4>
          </Grid>
          <Grid item xs={12}>
            {
              loading ? <>
                <Box width="100%" className="text-center py-50px">
                  <CircularProgress color="primary" size={50} />
                </Box>
              </> : <>
                <div className="table-responsive mt-15px">
                  <table className="table table-default table-green-head">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Updated On</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {(settings && settings.length > 0) ?
                        settings.map((setting) => {
                          return (
                            <>
                              <tr>
                                <td>{setting.name}</td>
                                <td>{printTime(setting?.updated_at)}</td>
                                <td>
                                  <div className="table-actions-btns">
                                    <button title="Edit Setting" onClick={(e) => editSetting(setting)}>
                                      <Edit />
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            </>
                          );
                        }) : <>
                          <tr>
                            <td colspan="4" className="text-center">No Settings Found</td>
                          </tr>
                        </>}
                    </tbody>
                  </table>
                </div>
              </>
            }

          </Grid>
        </Grid>

      </Box>

      <Dialog
        fullWidth={true}
        maxWidth='md'
        open={showModal}
        className={classes.dialogRoot}
        keepMounted={false}
        PaperProps={{
          style: { borderRadius: 20, maxHeight: 'calc(100% - 44px)' }
        }}
      >

        <DialogContent className={classes.contentHeight}>

          <Typography variant='h6' className="confirmModalTitle theme-form-group">
            {setting?.name}
          </Typography>

          <Box className="w-100">

            <textarea className='sus-objective-field' onChange={(event) => setSettingVal(event.target.value)} placeholder='Enter Value...' value={settingVal} cols="30" rows="20"></textarea>


            <Typography variant='p' className="normal-txt mt-15px mb-15px">
              {setting?.description}
            </Typography>

            <Box className="w-100 text-center">

              {updateLoading ?
                <>
                  <CircularProgress color="primary" size={20} />
                </>
                :
                <>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={e => updateSetting()}
                    className={`theme-btn theme-form-group me-10px`}
                  >
                    Save
                  </Button>

                  <Button
                    variant="contained"
                    color="default"
                    onClick={e => setShowModal(false)}
                    className={`theme-btn theme-form-group secondary`}
                  >
                    Cancel
                  </Button>
                </>
              }
            </Box>

          </Box>

        </DialogContent>
      </Dialog>

    </>

  );
}

export default SuperSettings;
