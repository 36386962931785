import { BUTTON_LOADING } from '../redux.constants';

const ButtonLoading = () => {
  return dispatch => {
    dispatch({
      type: BUTTON_LOADING
    })
  }
}

export default ButtonLoading;