import { isNotEmpty } from "./Generic";

export async function getFileData(pickerData) {
    if (isNotEmpty(pickerData)) {

        let fileResponse = null;

        switch (pickerData.pickerType) {

            case 'dropbox':
                fileResponse = await fetch(pickerData.link);
                break;

            case 'dms':
                fileResponse = await fetch(pickerData.link);
                break;

            case 'google':
                let headers = new Headers();
                headers.append('Authorization', `Bearer ${pickerData.accessToken}`);
                fileResponse = await fetch(`https://www.googleapis.com/drive/v2/files/${pickerData?.id}?alt=media&source=downloadUrl`, {
                    headers
                });
                break;

            default:
                return null;
                break;

        }

        if (fileResponse != null) {

            let fileBlobData = await fileResponse.blob();
            let metadata = {
                type: 'application/pdf'
            };

            let file = new File([fileBlobData], pickerData.name, metadata);
            return file;

        } else {
            return null;
        }

    } else {
        return null;
    }
}