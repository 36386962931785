import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Box,
  Grid,
  Typography,
  TextField,
  InputAdornment,
  FormControl,
  Select,
  Menu,
  MenuItem,
  InputBase,
  InputLabel,
  Dialog,
  DialogContent,
  CircularProgress,
  Button,
  LinearProgress,
  Snackbar
} from "@material-ui/core";
import { makeStyles, styled, withStyles } from "@material-ui/core/styles";
import DocumentCard from "../../components/documents/DocumentCard";
import DetailedCard from "../../components/documents/DetailedCard";
import ErrorIcon from "@material-ui/icons/Error";
import { createFolder, getAllDocuments, getContentCategories, getDocumentPreview, uploadToFolder, moveFiles, copyObjects } from "../../services/dms.service";
import { TableBody, TableHead } from "mui-datatables";
import { getImageFromMimeType, isNotEmpty, printTime } from "../../utils/Generic";
import DocumentViewer from "../../components/documents/DocumentViewer";
import Toast from "../../utils/Toast";
import { haveViewer } from "../../utils/document";
import { Alert } from "@material-ui/lab";
import { useDropzone } from 'react-dropzone'
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ContextMenu from "../../components/ContextMenu"
import { FormControlLabel } from "@material-ui/core";
import { Checkbox } from "@material-ui/core";
import { ChevronLeft, ChevronRight } from "react-feather";

const useStyles = makeStyles((theme) => ({
  root: {
    width: `calc(100% - ${300}px)`,
    marginLeft: 280,
    minHeight: "88vh",
  },
  filterBar: {
    marginTop: 20,
  },
  filterBar2: {
    marginTop: 5,
    marginBottom: 30,
  },
}));

const MoreOptionsComponent = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { index, onMenuItemClick } = props;

  const onDoubleClick = (event) => {
    event.stopPropagation()
  }

  return (
    <>
      <MoreVertIcon
        className={"cursor-pointer"}
        color="primary"
        onClick={(e) => {
          e.stopPropagation()
          setAnchorEl(e.currentTarget)
        }}
        onDoubleClick={onDoubleClick}
      >
        Open Menu
      </MoreVertIcon>
      <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        id={`more-options-menu-item-${index}`}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        className="more-options-menu"
      >
        <MenuItem
          onClick={(e) => {
            e.stopPropagation();
            onMenuItemClick("copy");
            setAnchorEl(null);
          }}
          onDoubleClick={onDoubleClick}
        >
          Copy
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            e.stopPropagation();
            onMenuItemClick("move");
            setAnchorEl(null);
          }}
          onDoubleClick={onDoubleClick}
        >
          Move
        </MenuItem>
      </Menu>
    </>
  );
};

const AllDocuments = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const uploadInputRef = useRef(null);
  const downloadElemRef = useRef(null);

  const { dmsType, dmsDefaultFolder, fileTypes } = props;

  const [showFilters, setShowFilters] = useState(props?.showFilters ?? false);
  const [showBreadcrumbs, setShowBreadcrumbs] = useState(props?.showBreadcrumbs ?? false);
  const [showOptions, setShowOptions] = useState(props?.showOptions ?? false);

  const [fileSelector, setFileSelector] = useState(false);

  useEffect(() => {
    if (dmsType == "dms") {
      setShowFilters(true);
      setShowBreadcrumbs(true);
      setShowOptions(true);
    } else if (dmsType == "fileSelector") {
      setFileSelector(true);
      setShowBreadcrumbs(true);
    }
  }, [dmsType]);

  const toastInitialValues = {
    isOpen: false,
    isSuccess: false,
    isError: false,
    message: ""
  }

  const [toast, setToast] = useState(toastInitialValues);

  const [parentDocumentId, setParentDocumentId] = useState(null);

  const [viewBy, setViewBy] = useState('');
  const [pane, setPane] = useState('');
  const [sortBy, setSortBy] = useState('');

  const [newFolderName, setNewFolderName] = useState('');
  const [openNewFolderPrompt, setOpenNewFolderPrompt] = useState(false);
  const [createFolderLoading, setCreateFolderLoading] = useState(false);

  const [filesLoading, setFilesLoading] = useState(true);

  const [search, setSearch] = useState('');
  const [openPdfViewer, setOpenPdfViewer] = useState(false);
  const [openEDocumentPdfViewer, setOpenEDocumentPdfViewer] = useState(false);
  const [pdfName, setPdfName] = useState();
  const [certData, setCertData] = useState();
  const [contentCategories, setContentCategories] = useState([]);

  const [certificates, setCertificates] = useState([]);
  const [cerificatesTableData, setCerificatesTableData] = useState([]);

  const [parentAnchestors, setParentAnchestors] = useState([]);
  const [parentDocument, setParentDocument] = useState(null);
  const [copiedDocument, setCopiedDocument] = useState(null);
  const [movedDocument, setMovedDocument] = useState(null);
  const [selectedDocumentList, setSelectedDocumentList] = useState([])
  const containerRef = useRef(null);

  const [edocs, setEdocs] = useState(true);
  const [filterFields, setFilterFields] = useState({
    search: '',
    category: '',
    ownerType: '',
    sortBy: '',
    parentDocumentId: null,
    _type: dmsType,
    _defaultFolder: dmsDefaultFolder,
    _fileTypes: fileTypes
  });

  const [selectedDocument, setSelectedDocument] = useState(null);
  const [uploadFileData, setUploadFileData] = useState(null);

  const [replaceConfirmModal, setReplaceConfirmModal] = useState(false);

  const [downloadedDocument, setDownloadedDocument] = useState(null);
  const [downloadUrl, setDownloadUrl] = useState(null);

  const [progress, setProgress] = useState(false);
  const [progressText, setProgressText] = useState('');

  const [uploadFilesQueue, setUploadFilesQueue] = useState([]);
  const [draggedItem, setDraggedItem] = useState(null)

  const [expanded, setExpanded] = useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const [backFolderId, setBackFolderId] = useState(null);
  const [nextFolderId, setNextFolderId] = useState(null);

  const handleFilterChange = (name, value) => {
    filterFields[name] = value;
    setFilterFields({ ...filterFields });
  }

  const loadContentCategories = () => {
    getContentCategories().then(res => {
      setContentCategories(res?.data);
    }).catch(error => {
      console.log(error);
    });
  }

  const updateSortValue = (val) => {
    setSortBy(val);
    handleFilterChange('sortBy', val);
  }

  const loadDocuments = () => {
    setSelectedDocument(null);
    setCertificates([]);
    setFilesLoading(true);
    getAllDocuments({
      filters: filterFields
    }).then((res) => {
      setCertificates(res?.data?.documents);
      setParentAnchestors(res?.data?.parentAnchestors);
      setParentDocument(res?.data?.parentDocument);
      setFilesLoading(false);
    }).catch(error => {
      setFilesLoading(false);
    });
  }

  useEffect(() => {
    document.title = `TruDoc | DMS`;
    loadContentCategories();
  }, []);

  useEffect(() => {
    loadDocuments();
  }, [filterFields]);

  useEffect(() => {
    handleFilterChange('parentDocumentId', parentDocumentId);
  }, [parentDocumentId]);

  const sendFileToPicker = (e) => {
    setFilesLoading(true);
    getDocumentPreview({
      documentId: e?.id,
      requestType: 'data'
    }).then((res) => {
      setFilesLoading(false);
      let fileData = res?.data?.fileData;
      if (isNotEmpty(fileData)) {
        let fileDataRaw = fileData?.data;
        let downloadUrl = `data:${fileData?.mimetype};base64,${fileDataRaw}`;
        if (fileData?.mimetype == "wrapped") {
          fileData.mimetype = "text/json";
          downloadUrl = `data:${fileData?.mimetype};charset=utf-8,` + encodeURIComponent(fileDataRaw);
        }
        fileData['link'] = downloadUrl;
        fileData['name'] = e?.name;
        props?.handleFileSelected(fileData);
      }
    }).catch(err => {
      console.log(err);
      setFilesLoading(false);
    });
  }

  const handleParentDocumentIdChange = (id) => {
    if (backFolderId === id) {
      setNextFolderId(parentDocumentId);
    } else {
      setNextFolderId(null);
    }
    setParentDocumentId(id);
  }

  useEffect(() => {
    if (parentAnchestors.length > 0) {
      let lastAnchhestor = parentAnchestors[(parentAnchestors.length - 1)];
      setBackFolderId(lastAnchhestor?.id);
    } else {
      setBackFolderId(null);
    }
  }, [parentAnchestors]);

  const handleDocumentSelected = (e) => {
    if (fileSelector) {
      if (e.is_folder) {
        handleParentDocumentIdChange(e.id);
      } else {
        sendFileToPicker(e);
      }
    } else {
      setSelectedDocument(e);
    }
  }

  const handleDocumentOpened = (e) => {
    if (fileSelector && !e.is_folder) {
      sendFileToPicker(e);
    } else {
      if (e.is_folder) {
        handleParentDocumentIdChange(e.id);
      } else {
        handleOnView(e);
      }
    }
  }

  const handleOnView = (e) => {
    if (haveViewer(e?.file_type, e?.name)) {
      setOpenPdfViewer(true);
      setCertData(e);
    } else {
      setToast((prev) => ({
        ...prev,
        isOpen: true,
        isError: true,
        message: 'File viewer is not available hence file will be downloaded'
      }));
      handleOnDownload(e);
    }
  }

  const handleOnDownload = (e) => {
    setProgress(true);
    setProgressText('Downloading ' + e.name);
    console.log("download link");
    setDownloadedDocument(e);
    getDocumentPreview({
      documentId: e?.id,
      requestType: 'download'
    }).then((res) => {
      setProgress(false);
      let fileData = res?.data?.fileData;
      if (isNotEmpty(fileData)) {
        let fileDataRaw = fileData?.data;

        let downloadUrl = `data:${fileData?.mimetype};base64,${fileDataRaw}`;

        if (fileData?.mimetype == "wrapped") {
          fileData.mimetype = "text/json";
          downloadUrl = `data:${fileData?.mimetype};charset=utf-8,` + encodeURIComponent(fileDataRaw);
        }

        //console.log(downloadUrl);  
        setDownloadUrl(downloadUrl);
      }
    }).catch(err => {
      console.log(err);
      setProgress(false);
    });
  }

  useEffect(() => {
    if (downloadUrl != null) {
      downloadElemRef.current.click();
    }
  }, [downloadUrl]);

  const handleOnDelete = (e) => {
    loadDocuments();
  }

  const createNewFolder = () => {
    let newFolderNameTmp = newFolderName.toString().trim();
    if (newFolderNameTmp != '') {
      setCreateFolderLoading(true);
      createFolder({
        parentDocumentId,
        name: newFolderName
      }).then((res) => {
        let data = res.data;
        if (data.success) {
          setOpenNewFolderPrompt(false);
          setNewFolderName('');
          loadDocuments();
        } else {
          setToast((prev) => ({
            ...prev,
            isOpen: true,
            isError: true,
            message: data?.error
          }));
        }
        setCreateFolderLoading(false);
      }).catch(error => {
        console.log(error);
        setCreateFolderLoading(false);
      });
    } else {

    }
  }

  const triggerUpload = () => {
    uploadInputRef.current.click();
  }

  const handleUploadFileChange = (event) => {
    const fileObj = event.target.files && event.target.files[0];
    console.log(fileObj);
    if (!fileObj) {
      return;
    }
    setUploadFileData(fileObj);
  };

  useEffect(() => {
    doUploadFile();
  }, [uploadFileData]);

  const doUploadFile = (replaceUploadFile = null) => {
    if (showOptions) {
      if (uploadFileData != null) {
        // console.log(uploadFileData);
        setFilesLoading(true);
        uploadToFolder({
          parentDocumentId,
          file: uploadFileData,
          overwrite: replaceUploadFile
        }).then(res => {
          res = res.data;
          if (res.success === 'replace') {
            // console.log("replace");;    
            setReplaceConfirmModal(true);
          } else {
            uploadInputRef.current.value = '';
            setUploadFileData(null);
            if (res.success) {
              // console.log("done");
              loadDocuments();
            } else {
              setToast((prev) => ({
                ...prev,
                isOpen: true,
                isError: true,
                message: res?.error
              }));
            }
          }
          setFilesLoading(false);
        }).catch(err => {
          // console.log(err);
          uploadInputRef.current.value = '';
          setUploadFileData(null);
          setFilesLoading(false);
        });
      }
    }
  }

  const confirmReplace = (e, replaceIt) => {
    if (replaceIt) {
      doUploadFile(true);
    } else {
      uploadInputRef.current.value = '';
      setUploadFileData(null);
    }
    setReplaceConfirmModal(false);
  }

  const closeToast = () => {
    return setToast((prev) => ({
      ...prev,
      isOpen: false,
      isSuccess: false,
      isError: false,
      message: ""
    }));
  };

  const moveFilesHandler = async (source, target) => {
    if (showOptions) {
      if (source instanceof Array) {
        let sourceParent = source.map((el) => el.parent_document_id);
        if ((target && sourceParent.indexOf(target.id) !== -1) || (!target && sourceParent.indexOf(null) !== -1)) {
          setToast((prev) => ({
            ...prev,
            isOpen: true,
            isError: true,
            message: "Can't paste in same folder"
          }));
          return false;
        }
      } else {
        if (target && source?.id == target?.id) {
          return false;
        }
      }

      if (target && !target.is_folder) {
        setToast((prev) => ({
          ...prev,
          isOpen: true,
          isError: true,
          message: "Target can't be a file."
        }));
        return false
      }
      setFilesLoading(true);
      moveFiles({
        "source": source instanceof Array ? source.map((ele) => ele.id) : [source.id],
        "target": target ? target?.id : null
      }).then((res) => {
        if (movedDocument) setMovedDocument(null)
        setSelectedDocumentList([])
        if (res?.data?.data?.failedEntries) {
          if (res.data.data.failedEntries.length > 0) {
            setFilesLoading(false);
            let messageToShow = ""
            res.data.data.failedEntries.forEach((ele) => {
              messageToShow += `${ele.name}: ${ele.message}; `
            })
            setToast((prev) => ({
              ...prev,
              isOpen: true,
              isError: true,
              message: messageToShow
            }));
          } else {
            loadDocuments()
          }
        } else {
          setFilesLoading(false);
        }
      }).catch(error => {
        setFilesLoading(false);
        console.log("[Error]", error)
      });
    }
  }

  const onDrop = useCallback(acceptedFiles => {
    console.log("acceptedFiles", acceptedFiles);
    setUploadFileData(acceptedFiles[0]);
  }, []);

  const { getRootProps, isDragActive } = useDropzone({ onDrop });


  const copyObjectHandler = async (source = [], target) => {
    if (showOptions) {
      let sourceParent = source.map((el) => el.parent_document_id);
      // console.log("source", source);
      // console.log("sourceParent", sourceParent);
      // console.log("target", target);
      if ((target && sourceParent.indexOf(target.id) !== -1) || (!target && sourceParent.indexOf(null) !== -1)) {
        setToast((prev) => ({
          ...prev,
          isOpen: true,
          isError: true,
          message: "Can't paste in same folder"
        }));
        return false;
      }

      setFilesLoading(true);
      copyObjects({
        "source": source.map((el) => el.id),
        "target": target ? target.id : null
      }).then((res) => {
        setCopiedDocument(null)
        setSelectedDocumentList([])
        if (res?.data?.data?.failedEntries) {
          if (res.data.data.failedEntries.length > 0) {
            setFilesLoading(false);
            // alert(res.data.data.failedEntries[0].message);
            let messageToShow = ""
            res.data.data.failedEntries.forEach((ele) => {
              messageToShow += `${ele.name} : ${ele.message};`
            })
            setToast((prev) => ({
              ...prev,
              isOpen: true,
              isError: true,
              message: messageToShow
            }));
          } else {
            loadDocuments()
          }
        } else {
          setFilesLoading(false);
        }
      }).catch(error => {
        setFilesLoading(false);
        console.log("[Error]", error)
      });
    }
  }

  const handleCopiedDocument = (documents) => {
    setCopiedDocument(documents);
    let message = documents.length + " documents has been Copied to Clipboard successful"
    if (documents.length == 1) {
      message = documents[0].name + " has been Copied to Clipboard successful"
    }
    setToast((prev) => ({
      ...prev,
      isOpen: true,
      isError: false,
      isSuccess: true,
      message: message
    }));
  }

  const handleMovedDocument = (documents) => {
    setMovedDocument(documents);
    let message = documents.length + " documents has been Cut to Clipboard successful"
    if (documents.length == 1) {
      message = documents[0].name + " has been Cut to Clipboard successful"
    }
    setToast((prev) => ({
      ...prev,
      isOpen: true,
      isError: false,
      isSuccess: true,
      message: message
    }));
  }

  const handleGoToBackScreen = () => {
    handleParentDocumentIdChange(backFolderId);
  }

  const handleGoToNextScreen = () => {
    if (isNotEmpty(nextFolderId)) {
      handleParentDocumentIdChange(nextFolderId);
    }
  }

  return (
    <>

      {toast.isOpen && <Toast message={toast.message} isError={toast.isError} isSuccess={toast.isSuccess} closeToast={closeToast} />}

      <div className="file-upload-input d-none">
        <input onChange={handleUploadFileChange} ref={uploadInputRef} type="file" name="file" id="file-upload-input-elem" />
      </div>

      <a target="_blank" className="d-none" filename={downloadedDocument?.name} ref={downloadElemRef} href={downloadUrl} download={downloadedDocument?.name}>Download File</a>

      <Box className={classes.root + ' dms-main-box ' + dmsType}>

        {
          showFilters ? <>
            <Grid
              className={classes.filterBar}
              container
              direction="row"
              alignItems="center"
              spacing={3}
            >

              <Grid item xs={4}>

                <FormControl fullWidth variant="outlined" className={classes.formControl}>
                  <TextField
                    fullWidth
                    className={classes.searchBar}
                    label="Search"
                    variant="outlined"
                    color="primary"
                    value={filterFields?.search}
                    onChange={e => handleFilterChange('search', e.target.value)}
                  />
                </FormControl>

              </Grid>

              <Grid item xs={4}>

                <FormControl fullWidth variant="outlined" sx={{ m: 1, minWidth: 300 }}>
                  <InputLabel id="category-label">Category</InputLabel>
                  <Select
                    labelId="category-label"
                    id="category-field"
                    label="Select Category"
                    value={filterFields?.category}
                    onChange={e => handleFilterChange('category', e.target.value)}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {contentCategories && contentCategories.length > 0 &&
                      contentCategories.map((contentCategory) => {
                        return (
                          <MenuItem key={contentCategory.value} value={contentCategory.value}>{contentCategory.label}</MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>

              </Grid>

              <Grid item xs={4}>

                <FormControl fullWidth variant="outlined" sx={{ m: 1, minWidth: 300 }}>
                  <InputLabel id="owner-label">Owner</InputLabel>
                  <Select
                    labelId="owner-label"
                    id="owner-field"
                    label="Owner"
                    value={filterFields?.ownerType}
                    onChange={e => handleFilterChange('ownerType', e.target.value)}
                  >
                    <MenuItem value="">
                      <em>All</em>
                    </MenuItem>
                    <MenuItem value="owned">Owned by Me</MenuItem>
                    <MenuItem value="shared">Shared with Me</MenuItem>
                  </Select>
                </FormControl>

              </Grid>

            </Grid>
          </> : null
        }

        <Grid
          className={classes.filterBar2}
          container
          direction="row"
          alignItems="center"
          spacing={3}
        >

          {
            showOptions ? <>
              <Grid className="dms-s-fiters" item xs={12}>

                <a href={undefined} onClick={e => { loadDocuments() }} className="theme-form-element">Refresh</a>

                <a href={undefined} onClick={e => { setOpenNewFolderPrompt(true) }} className="theme-form-element">New Folder</a>

                <a href={undefined} onClick={e => { triggerUpload() }} className="theme-form-element">Upload File</a>

                <select value={viewBy} onChange={e => setViewBy(e.target.value)} className="theme-form-element">
                  <option value="">View By</option>
                  <option value="list">List</option>
                  <option value="tiles">Tiles</option>
                </select>


                <select value={pane} onChange={e => setPane(e.target.value)} className="theme-form-element">
                  <option value="">Pane</option>
                  <option value="details">Details</option>
                  <option value="preview">Preview</option>
                </select>

                <select value={sortBy} onChange={e => updateSortValue(e.target.value)} className="theme-form-element">
                  <option value="">Sort By</option>
                  <option value="date">Date</option>
                  <option value="name">Name</option>
                  {/* <option value="collaborators">Collaborators</option> */}
                </select>

                {
                  selectedDocumentList.length > 0
                    ? <>
                      <a href={undefined} onClick={e => { handleCopiedDocument([...selectedDocumentList]) }} className="theme-form-element">Copy</a>
                      <a href={undefined} onClick={e => { handleMovedDocument([...selectedDocumentList]) }} className="theme-form-element">Move</a>
                    </>
                    : null
                }

              </Grid>
            </> : null
          }

          {
            showBreadcrumbs ? <>

              <Grid className="dms-breadcrumbs-main" item xs={12}>

                <Grid className="dms-breadcrumbs left" item xs={12}>

                  <ul>
                    <li>
                      <a
                        href={undefined}
                        onClick={e => handleParentDocumentIdChange(null)}
                        onDrop={(e) => {
                          moveFilesHandler(draggedItem, null)
                        }}
                      >Home</a>
                    </li>

                    {
                      (parentAnchestors != null && parentAnchestors.length > 0) && <>

                        {
                          parentAnchestors.map((parentAnchestor) => {
                            return (
                              <li>
                                <a
                                  className={`${parentAnchestor?.is_default.toString() === "true" ? 'default' : ''}`}
                                  href={undefined}
                                  onClick={e => handleParentDocumentIdChange(parentAnchestor.id)}
                                  onDrop={(e) => {
                                    moveFilesHandler(draggedItem, parentAnchestor)
                                  }}
                                >{parentAnchestor.name}</a>
                              </li>
                            );
                          })
                        }

                      </>
                    }

                    {
                      parentDocument != null && <>
                        <li>
                          <a
                            className={`active ${parentDocument?.is_default.toString() === "true" ? 'default' : ''}`}
                            href={undefined}
                            onClick={e => handleParentDocumentIdChange(parentDocument.id)}
                          >
                            {parentDocument.name}</a>
                        </li>
                      </>
                    }

                  </ul>

                </Grid>

                <Grid className="dms-actions-items right" item xs={12}>

                  <ul>

                    <li>
                      <a href={undefined} onClick={e => handleGoToBackScreen()}>
                        <ChevronLeft />
                      </a>
                    </li>

                    <li>
                      <a href={undefined} onClick={e => handleGoToNextScreen()}>
                        <ChevronRight />
                      </a>
                    </li>

                  </ul>

                </Grid>

              </Grid>

            </> : null
          }

        </Grid>

        <Box className={"dms-files-area truDrop " + (isDragActive ? 'truDrop-active' : '')} {...getRootProps()} ref={containerRef}>

          {
            copiedDocument || movedDocument ?
              <ContextMenu
                parentRef={containerRef}
              >
                <MenuItem
                  onClick={(e) => {
                    if (copiedDocument) {
                      copyObjectHandler(copiedDocument, parentDocument)
                    } else if (movedDocument) {
                      moveFilesHandler(movedDocument, parentDocument)
                    }
                  }}
                >
                  Paste
                </MenuItem>
              </ContextMenu>
              : null
          }

          <Box className="truDrop-content">
            <Box className="truDrop-content-box">
              <Typography variant="h6">Drop Files to Upload them to</Typography>
              <Typography variant="h4">
                <Typography variant="span" className="folderName">{parentDocument != null ? parentDocument.name : 'DMS'}</Typography>
              </Typography>
            </Box>
          </Box>

          {
            !filesLoading ?
              <>
                <Grid container>

                  <Grid container item xs={selectedDocument != null ? 8 : 12}>

                    {
                      (viewBy === 'list' || viewBy === '') && <>
                        <div className="table-responsive">
                          <table className="table table-default table-green-head">
                            <thead>
                              <tr>
                                {
                                  showOptions ? <>
                                    <th></th>
                                  </> : null
                                }
                                <th>Name</th>
                                <th>Date Created</th>
                                {
                                  showOptions ? <>
                                    <th></th>
                                  </> : null
                                }
                              </tr>
                            </thead>
                            <tbody>
                              {(certificates && certificates.length > 0) ?
                                certificates.map((cert) => {
                                  return (
                                    <>
                                      <tr
                                        className={`dms-table-row ${cert?.is_default.toString() === "true" ? 'default' : ''} ${selectedDocument?.id === cert.id ? 'selected' : ''}`}
                                        draggable
                                        onDragStart={(e) => setDraggedItem(cert)}
                                        onDrop={(e) => {
                                          moveFilesHandler(draggedItem, cert)
                                        }}
                                        key={`document-${cert.id}`}
                                      >

                                        {
                                          showOptions ? <>
                                            <td>
                                              <FormControlLabel
                                                className="theme-checkbox theme-checkbox-small"
                                                control={<Checkbox color="primary" checked={selectedDocumentList.findIndex((el) => el.id === cert.id) !== -1 ? true : false} onChange={() => {
                                                  let existingList = [...selectedDocumentList];
                                                  let listIndex = existingList.findIndex((el) => el.id === cert.id);
                                                  if (listIndex !== -1) {
                                                    existingList.splice(listIndex, 1)
                                                  } else {
                                                    existingList.push(cert)
                                                  }
                                                  setSelectedDocumentList(existingList)
                                                }} />}
                                              />
                                            </td>
                                          </> : null
                                        }

                                        <td className="table-row-title-main"
                                          onClick={e => handleDocumentSelected(cert)}
                                          onDoubleClick={e => handleDocumentOpened(cert)}
                                        >
                                          <Box className="table-row-title">
                                            <img src={getImageFromMimeType(cert?.file_type, cert?.is_folder)} />
                                            <span>{cert.name}</span>
                                          </Box>
                                        </td>
                                        <td>{printTime(cert?.created_at)}</td>
                                        {
                                          showOptions ? <>
                                            <td>
                                              <MoreOptionsComponent
                                                key={`more-options-cert-${cert.id}`}
                                                index={cert.id}
                                                onMenuItemClick={(operation) => {
                                                  if (operation === "copy") {
                                                    handleCopiedDocument([cert])
                                                  } else if (operation === "move") {
                                                    handleMovedDocument([cert])
                                                  }
                                                }}
                                              />
                                            </td>
                                          </> : null
                                        }
                                      </tr>
                                    </>
                                  );
                                }) : <>
                                  <tr>
                                    <td colspan="2">No Documents Found</td>
                                  </tr>
                                </>}
                            </tbody>
                          </table>
                        </div>
                      </>
                    }

                    {
                      (viewBy === 'tiles') && <>
                        <Grid container item xs={selectedDocument != null && 12} spacing={3} className="documents-container">
                          {(certificates && certificates.length > 0) ?
                            certificates.map((cert) => {
                              return (
                                <Grid
                                  container
                                  item
                                  xs={selectedDocument != null ? 4 : 3}
                                  key={cert.id}
                                >
                                  <Box width="90%">
                                    <DocumentCard
                                      data={cert}
                                      isSelected={
                                        selectedDocument != null && selectedDocument?.id === cert.id
                                      }
                                      onSelected={e => handleDocumentSelected(e)}
                                      onOpen={e => handleDocumentOpened(e)}
                                    />
                                  </Box>
                                </Grid>
                              );
                            }) : <>
                              <Box width="100%" align="center" padding={8}>
                                <ErrorIcon className={classes.emptyIcon} color="primary" />
                                <Typography variant="h6">No Documents Found</Typography>
                              </Box>
                            </>}
                        </Grid>
                      </>
                    }


                  </Grid>

                  {selectedDocument != null && (
                    <Grid className="" container item xs={4}>
                      <Box width="100%">

                        {(pane === 'details' || pane === '') && <>

                          <DetailedCard
                            showOptions={showOptions}
                            data={selectedDocument}
                            onView={e => handleOnView(e)}
                            onDownload={e => handleOnDownload(e)}
                            onDelete={e => handleOnDelete(e)}
                            onRefresh={e => loadDocuments()}
                          />

                        </>
                        }

                        {(pane === 'preview') && <>

                          <DocumentViewer data={selectedDocument} />

                        </>
                        }

                      </Box>
                    </Grid>
                  )}

                </Grid>
              </> :
              <>
                <Grid container>
                  <Grid container item xs={12}>
                    <Box width="100%" className="text-center">
                      <CircularProgress color="primary" size={50} />
                    </Box>
                  </Grid>
                </Grid>
              </>
          }

        </Box>

      </Box>

      <Dialog
        fullWidth={true}
        maxWidth='lg'
        open={openPdfViewer}
        className={classes.dialogRoot + ' doc-viewer-modal'}
        PaperProps={{
          style: { borderRadius: 20, maxHeight: 'calc(100% - 44px)', height: '80vh' }
        }}
        onClose={e => setOpenPdfViewer(false)}
      >

        <DialogContent className={classes.contentHeight}>

          <DocumentViewer data={selectedDocument} />

        </DialogContent>
      </Dialog>


      <Dialog
        fullWidth={true}
        maxWidth='sm'
        open={openNewFolderPrompt}
        className={classes.dialogRoot}
        keepMounted={false}
        PaperProps={{
          style: { borderRadius: 20, maxHeight: 'calc(100% - 44px)' }
        }}
      >

        <DialogContent className={classes.contentHeight}>

          <Typography variant='h6' className="confirmModalTitle theme-form-group">
            Enter name of the Folder
          </Typography>

          <Box className="w-100">

            <TextField
              id="Folder Name"
              label="Folder Name"
              variant="outlined"
              onChange={(event) => setNewFolderName(event.target.value)}
              className={classes.input + ' w-100 theme-form-group'}
            />

            <Box className="w-100 text-center">

              {createFolderLoading ?
                <>
                  <CircularProgress color="primary" size={20} />
                </>
                :
                <>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={e => createNewFolder()}
                    className={`theme-btn theme-form-group me-10px`}
                  >
                    Save
                  </Button>

                  <Button
                    variant="contained"
                    color="default"
                    onClick={e => setOpenNewFolderPrompt(false)}
                    className={`theme-btn theme-form-group secondary`}
                  >
                    Cancel
                  </Button>
                </>
              }
            </Box>

          </Box>

        </DialogContent>
      </Dialog>


      <Dialog
        fullWidth={true}
        maxWidth='sm'
        open={replaceConfirmModal}
        className={classes.dialogRoot}
        keepMounted={false}
        PaperProps={{
          style: { borderRadius: 20, maxHeight: 'calc(100% - 44px)' }
        }}
      >

        <DialogContent className={classes.contentHeight}>

          <Typography variant='h6' className="confirmModalTitle">
            File with same name already exist. Please choose how you want to Continue.
          </Typography>

          <Box className="confirmModalBox">

            <Button
              variant="contained"
              color="primary"
              onClick={e => confirmReplace(e, true)}
              className={`${classes.button} next-upl-btn me-10px`}
            >
              Replace

            </Button>

            <Button
              variant="contained"
              color="primary"
              onClick={e => confirmReplace(e, false)}
              className={`${classes.button} back-upl-btn`}
            >
              Cancel
            </Button>

          </Box>

        </DialogContent>
      </Dialog>

      {
        progress && <Snackbar
          open={progress}
          autoHideDuration={null}
          onClose={() => { }}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        >
          <Alert onClose={() => setProgress(false)} severity="info">
            {progressText}
            <LinearProgress className={classes.progress} />
          </Alert>
        </Snackbar>
      }

    </>
  );
};

export default AllDocuments; 
