import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
    Box,
    Button,
    Grid,
    LinearProgress,
    Paper,
    Snackbar,
    TableCell,
    TableRow,
    Typography,
} from "@material-ui/core";
import { makeStyles, styled } from "@material-ui/core/styles";
import Toast from "../../utils/Toast";
import { AiOutlineUpload } from 'react-icons/ai';
import { FiPaperclip } from 'react-icons/fi';
import { useHistory, useParams } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import { deleteSustainabilityFile, uploadSustainabilityFile } from "../../services/admin.service";
import { generateUniqueFileNameFromName, getImageFromMimeType, isNotEmpty } from "../../utils/Generic";
import { DeleteForever, GetApp } from "@material-ui/icons";
import { isNull } from "joi-browser";
import { useCorporateState } from "../../redux/context/corporate-context";
import GoogleFormResponse from "./google-form-response";
import { getDocumentPreview } from "../../services/dms.service";
import { Alert } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
    root: {
        width: `calc(100% - ${300}px)`,
        marginLeft: 280,
        minHeight: "88vh",
    },
    paper1: {
        display: "flex",
        flexWrap: "wrap",
        backgroundColor: 'transparent',
        "& > *": {
            margin: theme.spacing(3),
            padding: theme.spacing(2),
            width: "100%",
            textAlign: "center",
        },
    },
}));

const TabDocument = (props) => {

    const classes = useStyles();

    const { companyDocuments, setCompanyDocuments, report, canSave } = props;

    const toastInitialValues = {
        isOpen: false,
        isSuccess: false,
        isError: false,
        message: ""
    }

    const [toast, setToast] = useState(toastInitialValues);

    const [progress, setProgress] = useState(false);
    const [progressText, setProgressText] = useState('');
    const [downloadedDocument, setDownloadedDocument] = useState(null);
    const [downloadUrl, setDownloadUrl] = useState(null);

    const closeToast = () => {
        return setToast((prev) => ({
            ...prev,
            isOpen: false,
            isSuccess: false,
            isError: false,
            message: ""
        }));
    };

    const showError = (errorMsg) => {
        setToast((prev) => ({
            ...prev,
            isOpen: true,
            isError: true,
            isSuccess: false,
            message: errorMsg
        }));
    }

    const showSuccess = (succMsg) => {
        setToast((prev) => ({
            ...prev,
            isOpen: true,
            isError: false,
            isSuccess: true,
            message: succMsg
        }));
    }

    let { tabId } = useParams();
    let history = useHistory();

    const [activeTabDetails, setActiveTabDetails] = useState({});
    const [activeTabIndex, setActiveTabIndex] = useState(0);

    const [sustainabilityData, setSustainabilityData] = useState([]);

    const corporateState = useCorporateState();

    const downloadElemRef = useRef(null);

    useEffect(() => {
        if (downloadUrl != null) {
            downloadElemRef.current.click();
            setTimeout((e) => {
                setDownloadUrl(null);
            }, 1500);
        }
    }, [downloadUrl]);

    useEffect(() => {
        setSustainabilityData([]);
        if (isNotEmpty(corporateState?.sustainability_form_data)) {
            setSustainabilityData(JSON.parse(corporateState?.sustainability_form_data));
        }
    }, [corporateState]);

    useEffect(() => {
        let activeTabIndex = tabId;
        if (companyDocuments.hasOwnProperty(activeTabIndex)) {
            setActiveTabDetails(companyDocuments[activeTabIndex]);
            setActiveTabIndex(activeTabIndex);
        }
    }, [tabId, companyDocuments]);

    const handleDeleteDocument = (documentId) => {
        if (window.confirm("Are you sure you want to delete this document?") === true) {
            deleteSustainabilityFile({
                documentId: documentId,
                reportId: report?.id
            }).then((res) => {
                let data = res.data;
                if (data.success) {
                    let companyDocumentTemps = [...companyDocuments];
                    let documentsTemp = companyDocumentTemps[activeTabIndex].documents;
                    documentsTemp = documentsTemp.filter(obj => obj.id !== documentId);
                    companyDocumentTemps[activeTabIndex].documents = documentsTemp;
                    setCompanyDocuments(companyDocumentTemps);
                } else {
                    showError(data.message);
                }
            }).catch(err => {
                console.log(err);
            });
        }
    }

    const handleDownloadDocument = (e) => {
        setProgress(true);
        setProgressText('Downloading ' + e.name);
        console.log("download link");
        setDownloadedDocument(e);
        getDocumentPreview({
            documentId: e?.id,
            id: null,
            share_id: null
        }).then((res) => {
            setProgress(false);
            let fileData = res?.data?.fileData;
            if (isNotEmpty(fileData)) {
                let fileDataRaw = fileData?.data;

                let downloadUrl = `data:${fileData?.mimetype};base64,${fileDataRaw}`;

                if (fileData?.mimetype == "wrapped") {
                    fileData.mimetype = "text/json";
                    downloadUrl = `data:${fileData?.mimetype};charset=utf-8,` + encodeURIComponent(fileDataRaw);
                }

                //console.log(downloadUrl);  
                setDownloadUrl(downloadUrl);
            }
        }).catch(err => {
            console.log(err);
            setProgress(false);
        });
    }

    const handleGoBack = () => {
        if (activeTabIndex > 0) {
            let activeTabIndexTemp = activeTabIndex - 1;
            history.push("/sustainability/tab/" + activeTabIndexTemp);
        }
    }

    const handleGoNext = () => {
        let newTabIndex = parseFloat(activeTabIndex) + 1;
        if (companyDocuments.length > newTabIndex) {
            history.push("/sustainability/tab/" + newTabIndex);
        } else {
            history.push("/sustainability/know-sdgs");
        }
    }

    const onDrop = (acceptedFiles) => {
        if (companyDocuments.hasOwnProperty(activeTabIndex)) {
            for (let uploadFileData of acceptedFiles) {
                let fileName = uploadFileData.name;
                // fileName = generateUniqueFileNameFromName(fileName, report?.hash);
                fileName = generateUniqueFileNameFromName(fileName);
                uploadSustainabilityFile({
                    type: companyDocuments[activeTabIndex].title,
                    file: uploadFileData,
                    replace_name: fileName
                }).then(res => {
                    res = res.data;
                    if (res.success.toString() === "true") {
                        let companyDocumentTemps = [...companyDocuments];
                        let documentData = res?.data;
                        companyDocumentTemps[activeTabIndex].documents.unshift({
                            id: documentData?.id,
                            name: documentData?.name,
                            file_type: documentData?.file_type,
                            is_folder: documentData?.is_folder
                        });
                        setCompanyDocuments(companyDocumentTemps);
                        showSuccess(fileName + " has been uploaded");
                    } else {
                        showError(res?.message);
                    }
                }).catch(err => {

                });
            }
        }
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    return (
        <>
            {/* {console.log(activeTabDetails)} */}

            {toast.isOpen && <Toast message={toast.message} isError={toast.isError} isSuccess={toast.isSuccess} closeToast={closeToast} />}

            <a target="_blank" className="d-none" filename={downloadedDocument?.name} ref={downloadElemRef} href={downloadUrl} download={downloadedDocument?.name}>Download File</a>

            <div className='about_wrapper'>


                <h1>{activeTabDetails?.title}</h1>

                {
                    canSave ? <>
                        <div className='upload_wrapper'>
                            <div className='main_upload'>

                                <div {...getRootProps()} >
                                    <input type="file" name="file" {...getInputProps()} />
                                    <Grid container spacing={0}>
                                        <Grid item xs={12}>
                                            <div className={classes.paper1}>
                                                <Paper className="upl-wrp-style" elevation={0} style={{ backgroundColor: 'transparent' }}>
                                                    <div className='upload'>
                                                        <FiPaperclip size={85} color={'var(--sustainability-light)'} />
                                                        <h3>Drop a document here to get started</h3>
                                                        <h3>Or</h3>
                                                        <button className="btn-sustainability primary">
                                                            <AiOutlineUpload size={20} />&nbsp; Upload Document
                                                        </button>
                                                    </div>
                                                </Paper>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                            </div>
                        </div>
                    </> : null
                }

                <h4 className='h4'>Attachments</h4>
                <div className='files_wrapper'>
                    <div className='file_cards'>
                        {
                            (companyDocuments[activeTabIndex] !== undefined && companyDocuments[activeTabIndex].documents !== undefined && companyDocuments[activeTabIndex].documents.length > 0) ? <>
                                {
                                    companyDocuments[activeTabIndex].documents.map((document) => {
                                        return <>
                                            <Box md={3} >
                                                <div className="document-card-box">
                                                    <div className='document-card-box-title'>
                                                        <img src={getImageFromMimeType(document?.file_type, document?.is_folder)} />
                                                        <h3>{document?.name}</h3>
                                                    </div>
                                                    <div className='document-card-box-actions'>
                                                        <GetApp className="delete me-10px" onClick={(e) => handleDownloadDocument(document)} />
                                                        {
                                                            canSave ? <>
                                                                <DeleteForever className="delete" onClick={(e) => handleDeleteDocument(document?.id)} />
                                                            </> : null
                                                        }
                                                    </div>
                                                </div>
                                            </Box>
                                        </>
                                    })
                                }
                            </> : <>
                                <p className="no-results">No Attachments Found</p>
                            </>
                        }
                    </div>

                </div>

                {
                    (activeTabIndex.toString() === "0" && sustainabilityData.length > 0) ? <>
                        <h1 className="mb-20px">Sustainability Details</h1>
                        <GoogleFormResponse data={sustainabilityData} />
                    </> : null
                }

                <Box className="btn-sustainability-box">
                    <div className="left">
                        {
                            (canSave) ? <>
                                <button onClick={(e) => props?.onSave()} className="btn-sustainability secondary">
                                    Save
                                </button>
                            </> : null
                        }
                    </div>
                    <div className="right">
                        <button onClick={(e) => handleGoBack()} className="btn-sustainability primary-out me-10px">
                            Back
                        </button>
                        <button onClick={(e) => handleGoNext()} className="btn-sustainability primary">
                            Next
                        </button>
                    </div>
                </Box>

            </div>

            {progress && <Snackbar
                open={progress}
                autoHideDuration={null}
                onClose={() => { }}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            >
                <Alert onClose={() => setProgress(false)} severity="info">
                    {progressText}
                    <LinearProgress className={classes.progress} />
                </Alert>
            </Snackbar>}

        </>
    );
};

export default TabDocument;