import React, { useCallback, useState, useEffect, useRef } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import {
  TextField,
  CircularProgress,
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogContent,
  Slide,
  DialogActions,
} from "@material-ui/core";
import { btoaToBlob, fileToBase64, isNotEmpty, svgToImage, validateJOIFormField } from "./../../utils/Generic";
import {
  Box,
  FormControl,
  Grid,
  IconButton,
  Snackbar,
  Typography,
} from "@material-ui/core";
import DragDrop4 from "../../static/svg/drag-drop-4.svg";
import { useDropzone } from "react-dropzone";
import { Alert } from "@material-ui/lab";
import {
  uploadEDoc,
  getSignList,
} from "../../services/api.service";
import { getAllCustomers, getCorporateInfo } from "../../services/admin.service";
import { useHistory } from "react-router";
import { useModal } from "../../hooks";
import { useAuth } from "../../hooks";
import { Cancel } from "@material-ui/icons";
import Toast, { toastInitialValues } from "../../utils/Toast";
import { MessageConstants } from "../../utils/MessageConstants";
import FieldError from "../helperComponents/FieldError";
import * as Joi from "joi-browser";
import { InputLabel } from "@material-ui/core";
import { MenuItem } from "@material-ui/core";
import { Select } from "@material-ui/core";
import InvoiceStepperDocument from "./../../utils/WebViewer/InvoiceStepperDocument";
import { uploadToFolder } from "../../services/dms.service";
import SaveTypeConfirmation from "../documents/SaveTypeConfirmation";
import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';
import { useCorporateState } from "../../redux/context/corporate-context";

const useStyles = makeStyles((theme) => ({
  dialogRoot: {
    width: "600px",
    borderRadius: 50,
    margin: "0 auto",
  },
  contentHeight: {
    minHeight: "100px",
    maxWidth: "100%",
    margin: "0 auto",
    minWidth: "300px",
  },
  root: {
    width: "100%",
    backgroundColor: "transparent",
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  paper1: {
    display: "flex",
    flexWrap: "wrap",
    backgroundColor: "transparent",
    "& > *": {
      margin: theme.spacing(3),
      padding: theme.spacing(2),
      width: "100%",
      textAlign: "center",
    },
  },

  paper2: {
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(3),
      padding: theme.spacing(2),
      width: "100%",
    },
  },
  paper3: {
    backgroundColor: "transparent",
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(3),
      padding: theme.spacing(2),
      width: "100%",
      height: 350,
    },
  },
  dropIcon: {
    width: "150px",
    padding: theme.spacing(2),
  },
  icon: {
    margin: theme.spacing(1),
  },
  formControl: {
    minWidth: "100%",
  },
  contentMargin: {
    marginTop: theme.spacing(3),
  },
  content: {
    display: "flex",
    width: `calc(100% - ${300}px)`,
    marginLeft: 320,
    minHeight: "80vh",
    background: "transparent",
  },
  paper: {
    padding: theme.spacing(1),
    margin: theme.spacing(2),
    textAlign: "center",
  },
  text2: {
    color: "#7E8D90",
    fontSize: "16px",
    fontWeight: 500,
    fontFamily: "Poppins",
    height: "48px",
    lineHeight: "27px",
  },
  text2: {
    color: "#7E8D90",
    fontSize: "20px",
    fontWeight: 500,
    fontFamily: "Poppins",
    lineHeight: "27px",
  },
  flex: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: theme.spacing(1),
  },
  heading: {
    // width: "643px",
    // height: "45px",
    left: "498px",
    top: "253px",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "30px",
    lineHeight: "150%",
    letterSpacing: "0.1em",
    marginBottom: 20,
  },
  dialogRoot: {
    width: '700px',
    borderRadius: 50,
    margin: '0 auto',
  },
  contentHeight: {
    minHeight: '100px',
    maxWidth: '100%',
    margin: '0 auto',
    minWidth: '300px'
  },
  title: {
    paddingTop: 20,
    textAlign: 'center',
    fontWeight: '600',
    textTransform: 'uppercase',
    letterSpacing: 0.5,
    wordSpacing: 2,
    paddingBottom: 10
  },
  stepText: {
    fontWeight: '600',
    paddingBottom: 30,
    textAlign: 'center',
    color: theme.palette.admin.main,
    letterSpacing: 1,
  },
  actionBar: {
    justifyContent: 'center',
    marginBottom: 50
  },
  nextButton: {
    width: 220,
    height: 50,
    borderRadius: 25,
    color: '#fff',
    letterSpacing: 1,
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    }
  },
  draftButton: {
    width: 220,
    height: 50,
    borderRadius: 25,
    letterSpacing: 1,
    backgroundColor: '#fff',
    color: "#6a6a6a",
    border: '1px solid gray',
    '&:hover': {
      backgroundColor: "#fff",
      color: '#363636',
      border: '1px solid #464646'
    }
  },
  closeIcon: {
    position: 'absolute',
    right: '0',
    padding: '10px'
  },
  buttonTextCancel: {
    fontWeight: "bold",
    fontSize: 16,
    letterSpacing: "0.15em",
  },
  buttonRight: {
    marginTop: "20px",
    marginBottom: "10px",
    border: '1px solid gray',
    backgroundColor: '#fff',
    width: 150,
    height: 45,
    color: "#6a6a6a",
    borderRadius: 25,
    '&:hover': {
      backgroundColor: "#fff",
      color: '#363636',
      border: '1px solid #464646'
    }
  },
  addMoreButton: {
    border: "1px solid #66CFA4",
    borderRadius: "50px",
    width: "150px",
    margin: "0 auto",
    textAlign: "center",
    color: "#66CFA4",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "12px",
    height: "56px",
    "&:hover": {
      background: "#66CFA4",
      color: "#ffffff"
    },
  },
  removeButton: {
    border: "1px solid #e45050",
    borderRadius: "50px",
    width: "150px",
    margin: "0 auto",
    textAlign: "center",
    color: "#e45050",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "12px",
    height: "56px",
    "&:hover": {
      background: "#e45050",
      color: "#ffffff"
    },
  },
}));


const CssTextField = withStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#66CFA4",
      },
      "&:hover fieldset": {
        borderColor: "#66CFA4",
      },
      borderRadius: 50,
    },
  },
})(TextField);

export default function InvoiceStepper() {

  const [selectionModel, setSelectionModel] = React.useState([]);
  const [openNotification, setOpenNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState(false);
  const [progress, setProgress] = useState(false);
  
  const { isOpen, onOpen } = useModal();
  const { user } = useAuth();
  const [activeStep, setActiveStep] = useState(0);

  const columns = ["user_id", "username", "email", "job_title", "group", {
    name: "order",
    options: {
      customBodyRender: (value, tableMeta, updateValue) => {
        let { rowData, rowIndex, columnIndex } = tableMeta
        if (selectionModel?.length && selectionModel.indexOf(rowIndex) !== -1) {
          return (
            <div>
              <select value={rowData[columnIndex] || selectionModel.indexOf(rowIndex) + 1} onChange={(event) => {
                let rowsData = [...rows]
                rowsData[rowIndex].order = event.target.value
                setRows([...rowsData])
              }}>
                {
                  selectionModel?.map((value, index) => {
                    return <option value={index + 1}>{index + 1}</option>
                  })
                }
              </select>
            </div>
          );
        } else {
          return null;
        }
      }
    }
  }];

  const [rows, setRows] = useState([]);
  const [pdfUpdatedList, setPdfUpdatedList] = useState([]);

  const [isSaveloading, setIsSaveloading] = useState(false);

  const [isloading, setIsloading] = useState(false);
  const [isloadingFinal, setIsloadingFinal] = useState(false);
  const [signatoryList, setSignatoryList] = useState([]);
  const [sendFile, setSendFile] = useState(false);
  const [uploadFile, setUploadFile] = useState(false);
  let [replaceFile, setReplaceFile] = useState(false);
  const [replaceLoading, setReplaceLoading] = useState(false);
  const [toast, setToast] = useState(toastInitialValues);

  const [fileName, setFileName] = useState("invoice.pdf");

  const [newUpload, setNewUpload] = useState(true);

  let [replacedName, setReplacedName] = useState(null);
  const [replaceConfirmModal, setReplaceConfirmModal] = useState(false);

  const [showNewNameModel, setShowNewNameModel] = useState(false);
  const [openSaveTypeModal, setOpenSaveTypeModal] = useState(false);

  const [showCustomCustomerOption, setShowCustomCustomerOption] = useState(false);

  let [submitFile, setSubmitFile] = useState(null);
  let [submitSignatory, setSubmitSignatory] = useState(null);
  let [submitAnnotationId, setSubmitAnnotationId] = useState(null);
  let [submitSignPriority, setSubmitSignPriority] = useState(null);

  const [signature, setSignature] = useState({ Fullname: [], Initial: [] });
  const [errors, setErrors] = useState({});

  const [downloadedDocument, setDownloadedDocument] = useState(null);
  const [downloadUrl, setDownloadUrl] = useState(null);
  const downloadElemRef = useRef(null);

  const [selectCustomerIndex, setSelectCustomerIndex] = useState(null);
  const [customers, setCustomers] = useState([]);

  const [corporateData, setCorporateData] = useState([]);

  const history = useHistory();

  const getCorporate = () => {
    getCorporateInfo().then(res => {
      let corporateTempData = res?.data?.data;
      setCorporateData(corporateTempData);

      if (isNotEmpty(corporateTempData?.business_name)) {

      } else {
        setToast((prev) => ({
          ...prev,
          isOpen: true,
          isError: true,
          message: MessageConstants.BUSINESS_DETAILS_NOT_FOUND
        }));
      }


    }).catch(err => {

    });
  }

  const getSignature = () => {
    getSignList()
      .then((res) => {
        if (res.data.length > 0) {
          let x = [], y = [], base64svg = "";
          for (const e of res.data) {
            base64svg = `data:image/svg+xml;base64,${e.data}`; //convert svg to base64svg
            svgToImage(base64svg, 400, 600, 'png', (base64Img) => {
              if (e.type === "FULLNAME") {
                x.push(base64Img);
              } else {
                y.push(base64Img);
              }
              setSignature({ Fullname: x, Initial: y });
            });
          }
        } else if (res.data.length === 0) {
          setToast((prev) => ({
            ...prev,
            isOpen: true,
            isError: true,
            message: MessageConstants.REQUIRED_ATLEAST_ONE_SIGNATURE
          }));
        }
      })
      .catch((err) => {
        setToast((prev) => ({
          ...prev,
          isOpen: true,
          isError: true,
          message: MessageConstants.FAILED_TO_LOAD_SIGNATURE
        }));
      });
  }

  const getCustomers = () => {
    getAllCustomers()
      .then((res) => {
        res = res.data;
        if (res.data.length > 0) {
          setCustomers(res.data);
        } else if (res.data.length === 0) {
          setToast((prev) => ({
            ...prev,
            isOpen: true,
            isError: true,
            message: MessageConstants.REQUIRED_ATLEAST_ONE_CUSTOMER
          }));
        }
      })
      .catch((err) => {
        setToast((prev) => ({
          ...prev,
          isOpen: true,
          isError: true,
          message: MessageConstants.FAILED_TO_LOAD_CUSTOMERS
        }));
      });
  }

  useEffect(() => {
    getSignature();
    getCustomers();
    getCorporate();
    return () => setSendFile(false);
  }, []);

  const handleReset = () => {
    setSubmitFile(null);
    setSubmitSignatory(null);
    setSubmitAnnotationId(null);
    setSubmitSignPriority(null);
    setSendFile(false);
    setActiveStep(1);
  };

  const closeToast = () => {
    return setToast((prev) => ({
      ...prev,
      isOpen: false,
      isSuccess: false,
      isError: false,
      message: ""
    }));
  };

  const handleNext = () => {
    setSendFile(false);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handlePreviewNext = () => {
    const signatories = selectionModel.map((value, index) => {
      return {
        user_id: rows[value].user_id,
        email: rows[value].email,
        placeholders: [],
        username: rows[value].username,
        order: rows[value].order || index + 1
      };
    });
    signatories.sort((a, b) => {
      return a.order - b.order;
    });
    setSignatoryList(signatories);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  useEffect(() => {
    if (downloadUrl != null) {
      downloadElemRef.current.click();
    }
  }, [downloadUrl]);

  const handleSaveTypeConfirm = async (type) => {
    if (type == 'dms') {
      setShowNewNameModel(true);
    } else if (type == 'pc') {
      var fileURL = URL.createObjectURL(submitFile);
      setDownloadUrl(fileURL);
    }
    setOpenSaveTypeModal(false);
  }

  const handleSaveTrigger = () => {
    setDownloadUrl(null);
    setIsSaveloading(true);
    setSendFile(true);
  }

  const uploadDocument = async (fileBlob) => {
    const fileData = new File([fileBlob], fileName, {
      type: "application/pdf",
    });
    submitFile = fileData;
    setSubmitFile(submitFile);
    setSendFile(false);
    setIsSaveloading(false);
    setOpenSaveTypeModal(true);
    setDownloadedDocument({ name: fileName });
  };

  const submitUpload = async (overwrite = null) => {
    setIsloading(true);

    uploadToFolder({
      parentDocumentId: null,
      file: submitFile,
      overwrite: overwrite,
      replace_name: replacedName
    }).then(res => {
      res = res.data;
      if (res.success === 'replace') {
        setReplaceConfirmModal(true);
        setIsloading(false);
      } else {
        if (res.success) {
          setUploadFile(true);
          setToast((prev) => ({
            ...prev,
            isOpen: true,
            isSuccess: true,
            isError: false,
            message: MessageConstants.DOCUMENT_SAVED_TO_DMS
          }));
          history.push({ pathname: "/dms" });
        } else {
          setToast((prev) => ({
            ...prev,
            isOpen: true,
            isError: true,
            message: res?.error
          }));
          setIsloading(false);
        }
      }
    }).catch(err => {
      setToast((prev) => ({
        ...prev,
        isOpen: true,
        isError: true,
        message: MessageConstants.REQUEST_FAILED
      }));
      setIsloading(false);
    });

  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setIsloading(false);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenNotification(false);
  };

  const confirmReplace = (e, replaceIt) => {
    if (replaceIt) {
      setReplaceConfirmModal(false);
      submitUpload(true);
    } else {
      setReplaceConfirmModal(false);
      setShowNewNameModel(true);
    }
  };

  const hideNewNameModel = () => {
    setShowNewNameModel(false);
  }

  const submitNewNameModel = () => {
    if (isNotEmpty(replacedName)) {
      setShowNewNameModel(false);
      submitUpload(false);
    }
  }

  const classes = useStyles();

  const [formFields, setFormFields] = useState({
    invoice_number: "",
    invoice_date: "",
    tax_label: "",
    sub_total: "",
    tax: "",
    payable_amount: "",
    payment_details: "",
    due_date: "",
    company_name: '',
    company_address: '',
    company_email: '',
    logo: '',
    bill_to_name: "",
    bill_to_email: "",
    bill_to_address: "",
    signature: "",
    signatureImage: "",
    items: []
  });

  const [invoiceProducts, setInvoiceProducts] = useState([]);

  const schema = {
    invoice_number: Joi.string().required().label("Invoice Numer"),
    invoice_date: Joi.string().required().label("Invoice Date"),
    payment_details: Joi.string().required().label("Payment Details"),
    due_date: Joi.string().required().label("Due Date"),
    currency: Joi.string().required().label("Currency"),
    signature: Joi.string().required().label("Singature"),
    bill_to_name: Joi.string().required().label("Bill To"),
  };

  const firstNextBtnDisableState = () => {
    let errors = validateJOIFormField(formFields, schema);
    if (errors == null) {
      return false;
    } else {
      return true;
    }
  }

  const setFormData = (key, val) => {
    setFormFields((prev) => ({
      ...prev,
      [key]: val,
    }));
    if (key == "signature") {
      setFormData('signatureImage', signature.Fullname[val]);
    }
    if (key == "invoice_number") {
      setFileName(val + ".pdf");
      setReplacedName(val + ".pdf");
    }
  }

  useEffect(() => {
    if (selectCustomerIndex >= 0) {
      let customerData = customers[selectCustomerIndex];
      if (isNotEmpty(customerData)) {
        setFormData('bill_to_name', customerData?.full_name);
        setFormData('bill_to_email', customerData?.email);
        setFormData('bill_to_address', customerData?.address);
      }
    }
  }, [selectCustomerIndex]);

  useEffect(() => {
    if (isNotEmpty(corporateData?.business_contact_details)) {
      setFormData('company_name', corporateData?.business_name);
      setFormData('company_address', corporateData?.business_contact_details);
      setFormData('company_email', corporateData?.user?.email);
      const base64Data = window.atob(corporateData?.business_logo);
      setFormData('logo', base64Data);
    }
  }, [corporateData]);

  const setData = (e) => {
    setFormData(e.target.name, e.target.value);
  };

  const handleImageChange = async (e) => {
    if (e.target.files) {
      let fileBase64 = await fileToBase64(e.target.files[0]);
      setFormData('productImage', fileBase64);
    }
  }

  const removeProduct = (index) => {
    if (isNotEmpty(invoiceProducts[index])) {
      const newInvoiceProducts = [...invoiceProducts];
      newInvoiceProducts.splice(index, 1);
      setInvoiceProducts(newInvoiceProducts);
    }
  }

  const addProduct = (e) => {
    setInvoiceProducts([
      ...invoiceProducts,
      {
        description: '',
        quantity: '',
        unit: '',
        price: '',
      }
    ]);
  }

  const handleProductUpdate = (e, index) => {
    const newInvoiceProducts = [...invoiceProducts];
    const invoiceProduct = newInvoiceProducts.find(
      (item, i) => i === index
    );
    invoiceProduct[e.target.name] = e.target.value;
    setInvoiceProducts(newInvoiceProducts);
    setFormData('items', newInvoiceProducts);
  }

  useEffect(() => {
    // console.log(formFields);
    // console.log(invoiceProducts);  
  }, [invoiceProducts, formFields]);

  useEffect(() => {
    if (invoiceProducts.length <= 0) {
      addProduct();
    }
  }, []);

  const handleSelectCustomerIndex = (val) => {
    val = val.toString();
    setSelectCustomerIndex(val);
    // console.log(val);  
    if (val === "redirect") {
      console.log("replace");
      history.replace({ pathname: "/customers" });
    }
  }

  const goToSignaturesPage = () => {
    history.replace("/create-esign");
  }

  return (
    <>
      {toast.isOpen && <Toast message={toast.message} isError={toast.isError} isSuccess={toast.isSuccess} closeToast={closeToast} />}

      <a target="_blank" className="d-none" filename={downloadedDocument?.name} ref={downloadElemRef} href={downloadUrl} download={downloadedDocument?.name}>Download File</a>

      {uploadFile && (
        <>
          <Box className={classes.content}>
            <Grid container spacing={0} alignItems="center">
              <Grid item xs={12}>
                <Paper
                  className={`${classes.paper} doc-sign-com-wrp`}
                  elevation={0}
                >
                  <Grid
                    container
                    spacing={2}
                    alignItems="center"
                    justify="center"
                  >
                    <Grid item xs={12} className={classes.flex}>
                      <Typography className={classes.heading} color="primary">
                        Document has been uploaded to DMS.
                      </Typography>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </Box>
        </>
      )}
      {!uploadFile && (
        <>
          <div className={classes.root}>
            <Snackbar
              open={openNotification}
              autoHideDuration={6000}
              onClose={handleClose}
              anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            >
              <Alert onClose={handleClose} severity="success">
                {notificationMessage}
              </Alert>
            </Snackbar>
            <Stepper
              activeStep={activeStep}
              orientation="vertical"
              style={{ backgroundColor: "transparent" }}
            >
              <Step color="secondary">
                <StepLabel className="upl-step-label">
                  Fill the Invoice Details
                </StepLabel>
                <StepContent color="secondary">
                  <div className="maker-form">
                    <Box>

                      <Grid container alignItems="center" justify="center" spacing={5}>

                        <Grid item xs={12}>

                          <Grid container alignItems="top" justify="top" spacing={2}>

                            <Grid item xs={6}>
                              <CssTextField
                                type={"date"}
                                label="Invoice Date"
                                name="invoice_date"
                                value={formFields.invoice_date || ""}
                                className={"theme-form-elem" + ' fieldWithFile'}
                                onChange={(e) => setData(e)}
                                variant="outlined"
                                color="primary"
                                fullWidth
                                InputLabelProps={{ className: classes.textFieldInput }}
                              />
                              <FieldError error={errors.invoice_date} />
                            </Grid>

                            <Grid item xs={6}>
                              <FormControl variant="outlined" className={"theme-form-elem"}>
                                <InputLabel>Bill To</InputLabel>
                                <Select
                                  name="dimensionMetric"
                                  value={selectCustomerIndex || ""}
                                  label="Bill To"
                                  onChange={e => handleSelectCustomerIndex(e?.target?.value)}
                                >
                                  {
                                    customers.map((customer, i) => {
                                      return <MenuItem className="sign-options" key={i} value={i.toString()}>
                                        {customer?.full_name}
                                      </MenuItem>
                                    })
                                  }

                                  <MenuItem className="sign-options" key={0} value={"redirect"}>
                                    Add New Customer
                                  </MenuItem>

                                </Select>
                              </FormControl>
                              <FieldError error={errors.bill_to_name} />
                              {
                                showCustomCustomerOption ? <>
                                  <p className='field-help-text d-none text-right'><a onClick={(e) => setShowCustomCustomerOption(false)} href={undefined} target="_blank">Hide Manual Customer Details</a></p>
                                </> : <>
                                  <p className='field-help-text d-none text-right'><a onClick={(e) => setShowCustomCustomerOption(true)} href={undefined} target="_blank">Add Customer Details Manually</a></p>
                                </>
                              }
                            </Grid>

                          </Grid>

                        </Grid>

                      </Grid>

                      {
                        (false && showCustomCustomerOption) ? <>
                          <Grid container alignItems="center" justify="center" spacing={5}>

                            <Grid item xs={12}>

                              <Grid container alignItems="center" justify="center" spacing={2}>

                                <Grid item xs={6}>
                                  <CssTextField
                                    type={"text"}
                                    label="Customer Name"
                                    name="bill_to_name"
                                    value={formFields.bill_to_name || ""}
                                    className={"theme-form-elem"}
                                    onChange={(e) => setData(e)}
                                    variant="outlined"
                                    color="primary"
                                    fullWidth
                                    InputLabelProps={{ className: classes.textFieldInput }}
                                  />
                                  <FieldError error={errors.bill_to_name} />
                                </Grid>

                                <Grid item xs={6}>
                                  <CssTextField
                                    type={"text"}
                                    label="Customer Email"
                                    name="bill_to_email"
                                    value={formFields.bill_to_email || ""}
                                    className={"theme-form-elem"}
                                    onChange={(e) => setData(e)}
                                    variant="outlined"
                                    color="primary"
                                    fullWidth
                                    InputLabelProps={{ className: classes.textFieldInput }}
                                  />
                                  <FieldError error={errors.bill_to_email} />
                                </Grid>

                              </Grid>

                            </Grid>

                          </Grid>


                          <Grid container alignItems="center" justify="center" spacing={5}>

                            <Grid item xs={12}>

                              <Grid container alignItems="center" justify="center" spacing={2}>

                                <Grid item xs={12}>
                                  <CssTextField
                                    type={"text"}
                                    label="Customer Address"
                                    name="bill_to_address"
                                    value={formFields.bill_to_address || ""}
                                    className={"theme-form-elem"}
                                    onChange={(e) => setData(e)}
                                    variant="outlined"
                                    color="primary"
                                    fullWidth
                                    multiline
                                    rows={3}
                                    InputLabelProps={{ className: classes.textFieldInput }}
                                  />
                                  <FieldError error={errors.bill_to_address} />
                                </Grid>

                              </Grid>

                            </Grid>

                          </Grid>

                        </> : null
                      }

                      <Grid container alignItems="center" justify="center" spacing={5}>

                        <Grid item xs={12}>

                          <Grid container alignItems="center" justify="center" spacing={2}>

                            <Grid item xs={4}>
                              <CssTextField
                                type={"date"}
                                label="Due Date"
                                name="due_date"
                                value={formFields.due_date || ""}
                                className={"theme-form-elem" + ' fieldWithFile'}
                                onChange={(e) => setData(e)}
                                variant="outlined"
                                color="primary"
                                fullWidth
                                InputLabelProps={{ className: classes.textFieldInput }}
                              />
                              <FieldError error={errors.due_date} />
                            </Grid>

                            <Grid item xs={4}>
                              <CssTextField
                                type={"text"}
                                label="Tax Label (ex: GST)"
                                name="tax_label"
                                value={formFields.tax_label || ""}
                                className={"theme-form-elem"}
                                onChange={(e) => setData(e)}
                                variant="outlined"
                                color="primary"
                                fullWidth
                                InputLabelProps={{ className: classes.textFieldInput }}
                              />
                              <FieldError error={errors.tax_label} />
                            </Grid>

                            <Grid item xs={4}>
                              <CssTextField
                                type={"text"}
                                label="Total Tax"
                                name="tax"
                                value={formFields.tax || ""}
                                className={"theme-form-elem"}
                                onChange={(e) => setData(e)}
                                variant="outlined"
                                color="primary"
                                fullWidth
                                InputLabelProps={{ className: classes.textFieldInput }}
                              />
                              <FieldError error={errors.tax} />
                            </Grid>

                          </Grid>

                        </Grid>

                      </Grid>

                      <Grid container alignItems="center" justify="center" spacing={5}>
                        <Grid item xs={12}>
                          <Grid container alignItems="center" justify="center" spacing={2}>

                            <Grid item xs={6}>
                              <CssTextField
                                type={"text"}
                                label="Invoice Number"
                                name="invoice_number"
                                value={formFields.invoice_number || ""}
                                className={"theme-form-elem"}
                                onChange={(e) => setData(e)}
                                variant="outlined"
                                color="primary"
                                fullWidth
                                InputLabelProps={{ className: classes.textFieldInput }}
                              />
                              <FieldError error={errors.invoice_number} />
                            </Grid>

                            <Grid item xs={6}>
                              <CssTextField
                                type={"text"}
                                label="Currency"
                                name="currency"
                                value={formFields.currency || ""}
                                className={"theme-form-elem"}
                                onChange={(e) => setData(e)}
                                variant="outlined"
                                color="primary"
                                fullWidth
                                InputLabelProps={{ className: classes.textFieldInput }}
                              />
                              <FieldError error={errors.currency} />
                            </Grid>

                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid container alignItems="center" justify="center" spacing={5}>

                        <Grid item xs={12}>
                          <CssTextField
                            type={"text"}
                            label="Payment Details"
                            name="payment_details"
                            value={formFields.payment_details || ""}
                            className={"theme-form-elem"}
                            onChange={(e) => setData(e)}
                            variant="outlined"
                            color="primary"
                            fullWidth
                            multiline
                            rows={3}
                            InputLabelProps={{ className: classes.textFieldInput }}
                          />
                          <FieldError error={errors.payment_details} />
                        </Grid>

                      </Grid>

                      <Grid container alignItems="center" justify="center" spacing={5}>

                        <Grid item xs={12}>
                          <FormControl variant="outlined" className={`theme-form-elem theme-form-elem-signs ${isNotEmpty(formFields?.signature) ? 'selected' : 'empty'}`}>
                            <InputLabel>Select Signature</InputLabel>
                            <Select
                              labelId="select-signature"
                              name="signature"
                              value={formFields.signature || ""}
                              label="Select Signature*"
                              onChange={(e) => setData(e)}>
                              {
                                signature.Fullname.map((e, i) => {
                                  return <MenuItem className="sign-options" key={i} value={i.toString()}>
                                    <img className="sign-selected" src={e} alt="signature" />
                                  </MenuItem>
                                })
                              }
                              {
                                isNotEmpty(signature?.Fullname) && signature?.Fullname.length <= 0 ? <>
                                  <MenuItem onClick={goToSignaturesPage} key={-1} value={-1}>
                                    Create new Signature
                                  </MenuItem>
                                </> : null
                              }
                            </Select>
                            <FieldError error={errors.signature} />
                          </FormControl>
                        </Grid>

                      </Grid>

                    </Box>
                  </div>

                  <div className={classes.actionsContainer}>
                    <div>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                        className={`${classes.button} next-upl-btn`}
                        disabled={firstNextBtnDisableState()}
                      >
                        Next
                      </Button>
                      {isloading ? (
                        <CircularProgress color="primary" size={20} />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </StepContent>
              </Step>

              <Step>
                <StepLabel className="upl-step-label">
                  Description of Products
                </StepLabel>
                <StepContent color="secondary">
                  <div className="maker-form">
                    <Box>

                      <Grid container alignItems="center" justify="center" spacing={5}>

                        <Grid item xs={12}>

                          {
                            invoiceProducts.map((invoiceProduct, index) => {
                              return <>
                                <Grid container alignItems="center" justify="center" spacing={5}>

                                  <Grid item xs={12}>
                                    <CssTextField
                                      type={"text"}
                                      label="Description"
                                      name="description"
                                      value={invoiceProduct.description || ""}
                                      className={"theme-form-elem"}
                                      onChange={(e) => handleProductUpdate(e, index)}
                                      variant="outlined"
                                      color="primary"
                                      fullWidth
                                      multiline
                                      rows={4}
                                      InputLabelProps={{ className: classes.textFieldInput }}
                                    />
                                    <FieldError error={errors.paymentDetails} />
                                  </Grid>

                                </Grid>

                                <Grid container alignItems="center" justify="center" spacing={5}>

                                  <Grid item xs={12}>

                                    <Grid container alignItems="center" justify="center" spacing={2}>

                                      <Grid item xs={6}>
                                        <CssTextField
                                          type={"number"}
                                          label="Quantity"
                                          name="quantity"
                                          value={invoiceProduct.quantity || ""}
                                          className={"theme-form-elem"}
                                          onChange={(e) => handleProductUpdate(e, index)}
                                          variant="outlined"
                                          color="primary"
                                          fullWidth
                                          InputLabelProps={{ className: classes.textFieldInput }}
                                        />
                                        <FieldError error={errors.authorName} />
                                      </Grid>

                                      <Grid item xs={6}>
                                        <CssTextField
                                          type={"text"}
                                          label="Unit( Ex: Piece/Kg)"
                                          name="unit"
                                          value={invoiceProduct.unit || ""}
                                          className={"theme-form-elem"}
                                          onChange={(e) => handleProductUpdate(e, index)}
                                          variant="outlined"
                                          color="primary"
                                          fullWidth
                                          InputLabelProps={{ className: classes.textFieldInput }}
                                        />
                                        <FieldError error={errors.authorName} />
                                      </Grid>

                                    </Grid>

                                  </Grid>

                                </Grid>

                                <Grid container alignItems="center" justify="center" spacing={5}>

                                  <Grid item xs={10}>
                                    <CssTextField
                                      type={"text"}
                                      label="Price per Item"
                                      name="price"
                                      value={invoiceProduct.price || ""}
                                      className={"theme-form-elem"}
                                      onChange={(e) => handleProductUpdate(e, index)}
                                      variant="outlined"
                                      color="primary"
                                      fullWidth
                                      InputLabelProps={{ className: classes.textFieldInput }}
                                    />
                                    <FieldError error={errors.paymentDetails} />
                                  </Grid>

                                  <Grid item xs={2}>
                                    <Button onClick={(e) => removeProduct(index)} className={classes.removeButton}>
                                      <ClearIcon />
                                      REMOVE
                                    </Button>
                                  </Grid>

                                </Grid>
                              </>
                            })
                          }

                          <Grid container alignItems="center" justify="center" spacing={5}>

                            <Grid item xs={12}>
                              <hr />
                            </Grid>

                          </Grid>

                          <Grid container alignItems="center" justify="center" spacing={5}>
                            <Grid item xs={12}>
                              <Box
                                className={classes.buttonLeft}
                                justifyContent="center"
                                alignItems="center"
                              >
                                <Button onClick={addProduct} className={classes.addMoreButton}>
                                  <AddIcon />
                                  ADD MORE
                                </Button>
                              </Box>
                            </Grid>
                          </Grid>

                        </Grid>

                      </Grid>
                    </Box>

                  </div>

                  <div className={classes.actionsContainer}>
                    <div>
                      <Button
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        className={`${classes.button} back-upl-btn`}
                      >
                        Back
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                        className={`${classes.button} next-upl-btn`}
                      >
                        Next
                      </Button>
                    </div>
                  </div>

                </StepContent>
              </Step>

              <Step>
                <StepLabel className="upl-step-label">
                  Preview and Download
                </StepLabel>
                <StepContent>
                  <Grid container spacing={0}>
                    <Grid item xs={10}>
                      <div className={classes.paper2}>
                        <Paper
                          elevation={0}
                          style={{ backgroundColor: "transparent" }}
                        >
                          <Grid
                            container
                            spacing={2}
                            alignItems="center"
                            justify="start"
                          >

                            <InvoiceStepperDocument
                              uploadDocument={uploadDocument}
                              isloading={isloading}
                              sendFile={sendFile}
                              fileName={fileName}
                              fields={formFields}
                            />

                          </Grid>
                        </Paper>
                      </div>
                    </Grid>
                  </Grid>
                  <div className={classes.actionsContainer}>
                    <div>
                      <Button
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        className={`${classes.button} back-upl-btn`}
                      >
                        Back
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleSaveTrigger()}
                        // onClick={handleNext}
                        className={`${classes.button} next-upl-btn`}
                      // disabled={isloadingFinal}
                      >
                        {isSaveloading ? <CircularProgress color="secondary" size={20} /> : "Save"}
                      </Button>
                      {isloadingFinal ? (
                        <CircularProgress color="primary" size={20} />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </StepContent>
              </Step>

            </Stepper>
          </div>



          <Dialog
            fullWidth={true}
            maxWidth="md"
            open={replaceConfirmModal}
            className={classes.dialogRoot}
            keepMounted={false}
            PaperProps={{
              style: { borderRadius: 20, maxHeight: "calc(100% - 44px)" },
            }}
          >
            <DialogContent className={classes.contentHeight}>
              <Typography variant="h6" className="confirmModalTitle">
              File with same name already exist in Store. Please choose how you want to Continue.
              </Typography>

              <Box className="confirmModalBox">

                <Button
                  variant="contained"
                  color="primary"
                  onClick={(e) => confirmReplace(e, true)}
                  className={`${classes.button} next-upl-btn`}
                >
                  Replace
                </Button>

                <Button
                  variant="contained"
                  color="primary"
                  onClick={(e) => confirmReplace(e, false)}
                  className={`${classes.button} back-upl-btn`}
                >
                  Keep Both
                </Button>

              </Box>
            </DialogContent>
          </Dialog>


          <Dialog
            fullWidth={true}
            maxWidth='md'
            open={showNewNameModel}
            className={classes.dialogRoot}
            keepMounted={false}
            onClose={hideNewNameModel}
            PaperProps={{
              style: { borderRadius: 20, maxHeight: 'calc(100% - 44px)' }
            }}
          >
            <div className={classes.closeIcon}>
              <IconButton onClick={hideNewNameModel}>
                <Cancel />
              </IconButton>
            </div>
            <DialogTitle gutterBottom>
              <Typography variant='h5' className={classes.title}>
                Enter New Name
              </Typography>
            </DialogTitle>
            <DialogContent className={classes.contentHeight}>
              <Grid
                container
                direction="row"
                alignItems="center"
                className={classes.root}
                spacing={2}
              >
                <Grid item xs={3} align="right">
                  <Typography className={classes.subHeading}>Name*</Typography>
                </Grid>
                <Grid item xs={9} align="center">
                  <CssTextField
                    className={classes.field}
                    variant="outlined"
                    fullWidth
                    InputLabelProps={{ className: classes.textFieldInput }}
                    InputProps={{ className: classes.textFieldInput }}
                    value={replacedName}
                    onChange={e => setReplacedName(e.target.value)}
                  />
                </Grid>
              </Grid>
            </DialogContent>

            <DialogActions className={classes.actionBar} gutterBottom>

              <Box className="w-100 text-center">

                <Button className={classes.nextButton + ' me-10px'} onClick={submitNewNameModel}>
                  <Typography variant='h6'>Continue</Typography>
                </Button>
                <Button className={classes.draftButton} onClick={hideNewNameModel}>
                  <Typography variant='h6'>{"Cancel"}</Typography>
                </Button>

              </Box>

            </DialogActions>


          </Dialog>

          <SaveTypeConfirmation
            open={openSaveTypeModal}
            handleClose={handleSaveTypeConfirm}
          />

        </>
      )
      }
    </>
  );
}
