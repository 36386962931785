import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
    Box,
    Checkbox,
    FormControlLabel,
    Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Alert, Autocomplete, createFilterOptions, TabContext, TabPanel } from "@material-ui/lab";
import { isNotEmpty, printTime } from "../../utils/Generic";

const useStyles = makeStyles((theme) => ({
    root: {
        width: `calc(100% - ${300}px)`,
        marginLeft: 280,
        minHeight: "88vh",
    },
    field: {
        width: "100%",
        borderRadius: 10,
        color: theme.palette.admin.main,
    },
    Select: {
        width: "100%",
        borderRadius: 10,
        color: theme.palette.admin.main,
    },
}));

const usersFilter = createFilterOptions();

const ToDoBox = (props) => {

    const classes = useStyles();
    const { todoItem, selectedEventId } = props;


    return (
        <>

            <Box key={todoItem?.id} className={`event-box ${isNotEmpty(todoItem?.completed_on) ? 'completed' : ''} ${selectedEventId == todoItem?.id ? 'expand' : ''}`}>
                <Box className={'event-box-main'} onClick={(e) => props?.handleEventBoxClick(todoItem)}>
                    <Box className={'event-box-title'}>
                        <FormControlLabel
                            className="theme-checkbox theme-checkbox-small"
                            control={<Checkbox color="primary" checked={isNotEmpty(todoItem?.completed_on)} onClick={(e) => props?.toggleEventCompleted(e, todoItem)} name="gilad" />}
                        />
                        <Typography variant='h4'>{todoItem?.name}</Typography>
                    </Box>
                    <Box className={'event-box-middle'}>
                        <Typography variant='h6'>{todoItem?.type}</Typography>
                    </Box>
                    <Box className={'event-box-short-desc'}>
                        <Typography variant='h6'>{printTime(todoItem?.time)}</Typography>
                        <Box className={'event-box-short-actions'}>
                            <a href={undefined} onClick={(e) => props?.editToDo(e, todoItem)}>Edit</a>
                            <a href={undefined} onClick={(e) => props?.deleteToDo(e, todoItem)}>Delete</a>
                        </Box>
                    </Box>
                </Box>
                {
                    isNotEmpty(todoItem?.description) ?
                        <>
                            <Box className={'event-box-description'}>
                                <Typography variant='p'>{todoItem?.description}</Typography>
                            </Box>
                        </> : <>
                            <Box className={'event-box-description'}>
                                <Typography variant='p'>No Description Added</Typography>
                            </Box>
                        </>
                }
            </Box>

        </>
    );
};

export default ToDoBox; 
