import {
  Button,
  MessageTitle,
  OverlayContext,
  showDocumentTransferMessage,
  LoaderSpinner,
} from "@govtechsg/tradetrust-ui-components";
import React, { useContext, useEffect, useState } from "react";
import { FormState } from "../../FormState";
import { isEthereumAddress } from "../../utils";
import { AssetInformationPanel } from "../../AssetInformationPanel";
import { AssetManagementTitle } from "../../AssetManagementTitle";
import { EditableAssetTitle } from "./../EditableAssetTitle";
import { MenuItem, Select, FormControl, InputLabel, FormLabel, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';

export const NominateBeneficiaryHolderForm = ({
  formAction,
  tokenRegistryAddress,
  beneficiary,
  holder,
  handleNomination,
  nominationState,
  setFormActionNone,
  setShowEndorsementChain,
  corporateList,
  holderName,
  loadingEndrosmentChain
}) => {
  const [newBeneficiary, setNewBeneficiary] = useState("");
  const [isTruDocUser, setIsTruDocUser] = useState("true");
  const [corporateId, setCorporateId] = useState("");
  const isPendingConfirmation = nominationState === FormState.PENDING_CONFIRMATION;
  const isConfirmed = nominationState === FormState.CONFIRMED;
  const isEditable = nominationState !== FormState.PENDING_CONFIRMATION && nominationState !== FormState.CONFIRMED;
  const { showOverlay } = useContext(OverlayContext);

  useEffect(() => {
    if (isConfirmed) {
      showOverlay(
        showDocumentTransferMessage(MessageTitle.NOMINATE_BENEFICIARY_HOLDER_SUCCESS, {
          isSuccess: true,
        })
      );
      setFormActionNone();
    }
  }, [isConfirmed, newBeneficiary, showOverlay, setFormActionNone]);

  const isInvalidNomination =
    (!newBeneficiary && !corporateId) || !holder || (newBeneficiary && newBeneficiary === beneficiary) || (newBeneficiary && !isEthereumAddress(newBeneficiary));

  const handleTransferTypeChange = (event) => {
    setIsTruDocUser(event.target.value);
    setNewBeneficiary("");
    setCorporateId("");
  };

  const handleChange = (event) => {
    setCorporateId(event.target.value);
  };
  return (
    <>
      <AssetManagementTitle
        setFormActionNone={setFormActionNone}
        formAction={formAction}
        disabled={isPendingConfirmation}
      />
      <div className="flex flex-wrap justify-between mb-4 mx-4">
        <div className="w-full px-4 lg:w-1/3">
          <AssetInformationPanel
            setShowEndorsementChain={setShowEndorsementChain}
            tokenRegistryAddress={tokenRegistryAddress}
            loadingEndrosmentChain={loadingEndrosmentChain}
          />
        </div>
        <FormControl component="fieldset"
        style={{
          marginTop:"25px",

        }}
        className="border-top-line"
        
        >
          <div className="borderTop"></div>
          <FormLabel component="legend">Transfer To</FormLabel>
          <RadioGroup
            aria-label="isTruDocUser"
            defaultValue={isTruDocUser}
            name="radio-buttons-group"
            onChange={handleTransferTypeChange}
          >
            <FormControlLabel value={"true"} control={<Radio />} label="TruDoc User" />
            <FormControlLabel value={"false"} control={<Radio />} label=" Public address" />
          </RadioGroup>
        </FormControl>
        
        {
          isTruDocUser === "true"
            ? <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">User</InputLabel>
              <Select
                labelId="corporateId"
                id="corporateId"
                value={corporateId}
                label="User"
                onChange={handleChange}
              >
                <MenuItem value="">Select</MenuItem>
                {
                  corporateList && corporateList.map((corporate) => {
                    return <MenuItem value={corporate.id}>{corporate.name}</MenuItem>
                  })
                }

              </Select>
            </FormControl>
            : <div className="w-full px-4 lg:w-1/3 input-owner-wrp">
              <EditableAssetTitle
                role="Owner"
                value={beneficiary}
                newValue={newBeneficiary}
                isEditable={isEditable}
                onSetNewValue={setNewBeneficiary}
                error={nominationState === FormState.ERROR}
                style={{
                  color: "#000",
                  fontSize: "18px",
                  fontFamily: "Poppins"
                }}
              />
            </div>
        }
        <div className="w-full px-4 lg:w-1/3 holder-wrp">
          <EditableAssetTitle role="Holder" value={holder} isEditable={false} corporateName={holderName}/>
        </div>
      </div>
      <div className="flex flex-wrap pb-4">
        <div className="w-auto lg:ml-auto">
          <div className="flex flex-wrap"     
            style={{
              display:"flex",
            }} >
            <div className="w-auto flex">
              <Button
                className="bg-white rounded-xl text-lg py-2 px-3 border-cloud-100 text-cloud-900 shadow-none hover:bg-cloud-200"
                onClick={setFormActionNone}
                disabled={isPendingConfirmation}
                data-testid={"cancelNominationBtn"}
                style={{
                  background:"#66CFA4;",
                  borderRadius:"20px",
                  color:"#000",
                  textTransform:"capitalize",
                  padding:"7.5px 15px",
                  border:"unset",
                  marginRight:"15px"
                }}
              >
                Cancel
              </Button>
            </div>
            <div className="w-auto ml-2">
              <Button
                className="bg-cerulean rounded-xl text-lg text-white py-2 px-3 shadow-none hover:bg-cerulean-300"
                style={{
                  background:"#66CFA4;",
                  borderRadius:"20px",
                  color:"#000",
                  textTransform:"capitalize",
                  border:"unset",
                  padding:"7.5px 15px"
                }}
                disabled={isInvalidNomination || isPendingConfirmation}
                onClick={() => {
                  if (holder === undefined) return;
                  // holder is used instead of 'NewHolder' because we do not want to change the value on the UI when nominating beneficiary.
                  handleNomination(newBeneficiary, holder, corporateId);
                }}
                data-testid={"nominationBtn"}
              >
                {isPendingConfirmation ? <LoaderSpinner data-testid={"loader"} /> : <>Nominate</>}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};