import { Box, CircularProgress, Grid } from "@material-ui/core";
import {
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import ReactPaginate from 'react-paginate';
import { Close } from "@material-ui/icons";
import { UNSDG_GOAL_CATEGORIES } from "../../utils/unsdg-goals";
import { goalsParseSubjects } from "../../utils/Generic";
import { Button } from "antd";

function EsgImpactTopicsModal(props) {

    const { closeModal, openModal, objectiveKey, objectives, report, goals } = props
    const [activeTab, setActiveTab] = useState('environment')
    const [topicList, setTopicList] = useState({
        environment: [],
        social: [],
        governance: [],
    })
    function capitalizeFirstLetter(word) {
        return word.charAt(0).toUpperCase() + word.slice(1);
    }

    const chooseTab = (sub) => {
        setActiveTab(sub)
    }

    const orderedGoals = goalsParseSubjects(goals)
    console.log(objectives,objectiveKey,objectives[objectiveKey])
    useEffect(() => {
        const temp = {
            environment: [],
            social: [],
            governance: [],
        }
        objectives[objectiveKey].subjects.map(goal => {
            if (UNSDG_GOAL_CATEGORIES.environment.goals.includes(goal.goal)) {
                temp.environment.push(goal.subject)
            }
            if (UNSDG_GOAL_CATEGORIES.social.goals.includes(goal.goal)) {
                temp.social.push(goal.subject)
            }
            if (UNSDG_GOAL_CATEGORIES.governance.goals.includes(goal.goal)) {
                temp.governance.push(goal.subject)
            }
        })

        setTopicList(temp)

    }, [])

    return (
        <>
            <Dialog
                onClose={e => closeModal()}
                fullWidth={true}
                maxWidth='md'
                open={openModal}
                className={`theme-base-modal sustainability-layout NewUi_modals`}
                keepMounted={false}
                PaperProps={{
                    style: { borderRadius: 20, maxHeight: 'calc(100% - 44px)' }
                }}
            >
                <DialogTitle>
                    <div className="modal-head">
                        <span className="capital-case">Material Topics</span>
                        <IconButton onClick={e => closeModal()} style={{ marginLeft: 'auto' }}>
                            <Close />
                        </IconButton>
                    </div>
                </DialogTitle>
                <DialogContent dividers >
                    <DialogContentText>
                        <div className="sus-objective-box">
                            <main className='new-design'>
                                <div className='container main-wrapper'>
                                    <div className='tabs'>
                                        <a href={undefined} className={`tab-head-options ${activeTab === "environment" ? 'active' : ''} `} onClick={(e) => chooseTab("environment")}>Environment</a>
                                        <a href={undefined} className={`tab-head-options color-purple ${activeTab === "social" ? 'active' : ''} `} onClick={(e) => chooseTab("social")}>Social</a>
                                        <a href={undefined} className={`tab-head-options color-pink ${activeTab === "governance" ? 'active' : ''} `} onClick={(e) => chooseTab("governance")}>Governance</a>
                                    </div>

                                    {
                                        // activeTab === "environment" ?
                                        <>
                                            <section className='scrollable-box'>
                                                <table  className="popup-table">
                                                    {
                                                        topicList[activeTab].length > 0 ? <>
                                                            <thead>
                                                            <tr className="li-headings">
                                                                <th width="40%">Material Topics</th>
                                                                <th width="20%">SDGs</th>
                                                                <th width="20%">Impact on people and the environment</th>
                                                                <th width="20%">Impact on business success</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            {topicList[activeTab].map((goal, index) => {
                                                                return <>
                                                                    <tr>
                                                                        {
                                                                            <>
                                                                                {orderedGoals.map(og => {
                                                                                    if (og.title == goal) {
                                                                                        return (
                                                                                            <>
                                                                                                <td width="40%">
                                                                                                    <div className={`round-number-subject round-number-item have-objectives ${activeTab}`} >
                                                                                                        <Button>{og?.keyIndex + 1}</Button>
                                                                                                        {goal}
                                                                                                    </div>
                                                                                                </td>
                                                                                                <td width="20%"><img className="goal-images" height='100%' width='100%' src={`/assets/images/sdg-icons/E-WEB-Goal-${og?.goalNumber}.png`} /></td>
                                                                                                <td width="20%"><span className={`material-topics-priority priority-${og.peopleEnvironmentImpactRelevance.textValue}`}>{capitalizeFirstLetter(og.peopleEnvironmentImpactRelevance.textValue)}</span></td>
                                                                                                <td width="20%"><span className={`material-topics-priority priority-${og.businessImpactRelevance.textValue}`}>{capitalizeFirstLetter(og.businessImpactRelevance.textValue)}</span></td>
                                                                                            </>
                                                                                        )
                                                                                    }
                                                                                })}
                                                                            </>
                                                                        }
                                                                    </tr>
                                                                </>
                                                            })}
                                                            </tbody>
                                                        </> : 'No Data Found'
                                                    }
                                                </table>
                                            </section>

                                        </>
                                    }
                                </div>
                            </main>
                        </div>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </>
    );
}

export default EsgImpactTopicsModal;
