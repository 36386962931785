import { endpoint } from "./services.constant";
import apiCall, { userApiCall } from "./api";
import { getFormData } from "./utils";

export const loginApi = payload => {
  return apiCall(endpoint.auth.LOGIN, "POST", payload);
};

export const requestOtp = payload => {
  return apiCall(endpoint.auth.SEND_OTP, "POST", payload);
};

export const verifyOtp = payload => {
  return apiCall(endpoint.auth.VERIFY_OTP, "POST", payload);
};

export const verifyLoginOtp = payload => {
  return apiCall(endpoint.auth.VERIFY_LOGIN_OTP, "POST", payload);
};

export const signupApi = payload => {
  return apiCall(endpoint.auth.SIGNUP, "POST", payload);
};

export const verifyDoc = payload => {
  return apiCall(endpoint.doc.VERIFY, "POST", payload);
};

export const resetPassword = payload => {
  return userApiCall(endpoint.auth.RESET_PASSWORD, "POST", payload);
}

export const resetForgotPassword = payload => {
  return userApiCall(endpoint.auth.RESET_FORGOT_PASSWORD, "POST", payload);
}

export const forgotPassword = payload => {
  return userApiCall(endpoint.auth.FORGOT_PASSWORD, "POST", payload);
}

export const uploadDoc = payload => {
  const formData = getFormData(payload);
  return apiCall(
    endpoint.doc.UPLOAD,
    "PUT",
    null,
    formData,
    "multipart/form-data"
  );
};

export const getDomainList = payload => {
  return apiCall(endpoint.doc.DOMAIN, "GET", payload);
};

export const getEdocumentList = payload => {
  return apiCall(endpoint.eDoc.GET_EDOCUMENTLIST, "POST", payload);
};
