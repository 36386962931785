// External Imports
import { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";

// Internal Imports
import { useAuth } from '../../hooks';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { Slide, Typography, makeStyles, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, CircularProgress } from '@material-ui/core';
import { utils } from "@govtechsg/open-attestation";
import { downloadTt, getEndrosmentChain, verifyDoc, accountDetailsForTitleTransfer, fetchCorporateList, revoke, revokeStatus, markRevoked } from '../../services/api.service';
import { AssetManagementApplication } from './transferablePanelFiles/AssetManagementApplication';
// import { verifyDocument } from './transferablePanelFiles/verify';
import { useTokenInformationContext } from './transferablePanelFiles/contexts/TokenInformationContext';
import EndrosmentChain from "../EndrosmentChain";
import { isNotEmpty, normalizeAddr, parseWeb3Error, printTime } from '../../utils/Generic';
import { documentUploadMethods } from '../../constants';
import Toast from '../../utils/Toast';
import { MessageConstants } from '../../utils/MessageConstants';
import MetaMaskTransaction from '../MetaMaskTransaction';
import { useMetaMask } from 'metamask-react';

const Web3 = require('web3');

const TransferablePanel = ({ data }) => {
  const { filterType } = useParams();
  const [ttFile, setTTFile] = useState(undefined);
  const [verificationStatus, setVerificationStatus] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [loadingEndrosmentChain, setLoadingEndrosmentChain] = useState(false);
  const [tokenRegistryAddress, setTokenRegistryAddress] = useState("");
  const [tokenId, setTokenId] = useState("");
  const [accountAddr, setAccountAddr] = useState("");
  const [open, setOpen] = useState(false);
  const [corporateList, setCorporateList] = useState([]);
  const [endrosmentChainData, setEndrosmentChain] = useState([]);
  const [showEndorsementChain, setShowEndorsementChain] = useState(false);

  const { status, connect, account, chainId, ethereum } = useMetaMask();
  const [openMetaMaskModal, setOpenMetaMaskModal] = useState(null);
  const [walletAddr, setWalletAddr] = useState(null);
  const [metaMaskTransactionDetails, setMetaMaskTransactionDetails] = useState(null);
  const [metaMaskTansactionStarted, setMetaMaskTansactionStarted] = useState(null);
  const [metaMaskTransactionProgressText, setMetaMaskTransactionProgressText] = useState(false);

  const {
    initialize,
    resetStates:
    resetTokenInformationState,
    isTitleEscrow,
    beneficiary,
    holder
  } = useTokenInformationContext();

  // console.log("isTitleEscrow", isTitleEscrow);
  // console.log("beneficiary", beneficiary);
  // console.log("holder", holder);
  // console.log("accountAddr", accountAddr);  

  const isHolder = isTitleEscrow && normalizeAddr(accountAddr) === normalizeAddr(holder);
  // console.log("isHolder", isHolder);
  const isBeneficiary = isTitleEscrow && normalizeAddr(accountAddr) === normalizeAddr(beneficiary);
  // console.log("isBeneficiary", isBeneficiary);
  const canSurrender = isBeneficiary && isHolder;
  // console.log("canSurrender", canSurrender);

  const { user } = useAuth();

  const [revokeStatusLoading, setRevokeStatusLoading] = useState(true);
  const [revokeStatusTxt, setRevokedStatusTxt] = useState(null);

  const toastInitialValues = {
    isOpen: false,
    isSuccess: false,
    isError: false,
    message: ""
  }

  const [toast, setToast] = useState(toastInitialValues);

  const closeToast = () => {
    return setToast((prev) => ({
      ...prev,
      isOpen: false,
      isSuccess: false,
      isError: false,
      message: ""
    }));
  };

  const getTokenRegistryAddress = (document) => {
    const issuerAddress = utils.getIssuerAddress(document);
    return issuerAddress instanceof Array ? issuerAddress[0] : issuerAddress;
  };

  const getAccountDetailsForTitleTransfer = () => {
    accountDetailsForTitleTransfer()
      .then(res => {
        if (res && res?.status === 200 && res?.data?.account) {
          setAccountAddr(res.data.account)
        }
      }).catch((err) => {
        console.log("[Error Fetching account details]", err)
      })
  }

  const getCorporateList = () => {
    fetchCorporateList()
      .then(res => {
        if (res && res?.status === 200 && res?.data?.data) {
          setCorporateList(res.data.data)
        }
      }).catch((err) => {
        console.log("[Error Fetching account details]", err)
      })
  }

  const handleClose = () => {
    setShowEndorsementChain(false);
  };

  const getTransferableRecord = () => {
    setLoading(true);
    const fileName = data?.name?.replace('.pdf', '.tt');
    const bucketName = data?.storage_bucket;
    downloadTt({
      docId: data?.id,
      bucketName: bucketName,
      fileName
    }).then(res => {
      if (res && res?.status === 200 && res?.data) {
        setTTFile(res.data)
        setTokenRegistryAddress(getTokenRegistryAddress(res.data));
        try {
          setTokenId(`0x${utils.getAssetId(res.data)}`);
        } catch (e) {
          console.log("error in fetching token address", e, res.data);
        }

        setLoading(false)
      }
    }).catch(() => {
      setLoading(false);
    })
  };

  useEffect(() => {
    setLoading(true)
    getTransferableRecord();
    getAccountDetailsForTitleTransfer();
    getCorporateList();
    setLoading(false)
  }, [])

  useEffect(() => {
    if (tokenRegistryAddress && tokenId) {
      initialize(tokenRegistryAddress, tokenId, data.id);
      return () => {
        resetTokenInformationState();
      };
    }
  }, [tokenId, tokenRegistryAddress, resetTokenInformationState, initialize]);

  const fetchEndorsementChain = () => {
    getEndrosmentChain({
      tokenRegistryAddress: tokenRegistryAddress,
      tokenId: tokenId,
    })
      .then((res) => {
        console.log("endorsementChain Response", res.data.endorsementChain);
        setEndrosmentChain(res.data.endorsementChain);
        setLoadingEndrosmentChain(true)
      })
      .catch((err) => {
        console.log(err.message);
      });
  }

  const checkRevokeStatus = () => {
    setRevokeStatusLoading(true);
    setRevokedStatusTxt('-');
    revokeStatus({
      document_id: data?.id
    })
      .then((res) => {
        let data = res.data;
        if (data.revoked) {
          setRevokedStatusTxt("Revoked on " + printTime(data.revokedAt));
        } else {
          setRevokedStatusTxt('');
        }
        setRevokeStatusLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
        setRevokeStatusLoading(false);
      });
  }

  const showError = (err) => {
    setMetaMaskTansactionStarted(false);
    setMetaMaskTransactionProgressText(null);
    setRevokeStatusLoading(false);
    let errorMsg = err?.message;
    if (typeof err == "string") {
      errorMsg = err;
    }
    setToast((prev) => ({
      ...prev,
      isOpen: true,
      isError: true,
      isSuccess: false,
      message: errorMsg
    }));
  }

  const markDocumentRevoked = (transactionHash) => {
    markRevoked({
      document_id: data?.id,
      transactionHash
    })
      .then(async (res) => {
        let data = res.data;
        console.log(data);
        setMetaMaskTransactionDetails(null);
        setOpenMetaMaskModal(false);
        setRevokeStatusLoading(false);
        if (data.success) {
          checkRevokeStatus();
        } else {
          setRevokedStatusTxt(data.error);
        }
      })
      .catch((err) => {
        setMetaMaskTransactionDetails(null);
        setOpenMetaMaskModal(false);
        setRevokeStatusLoading(false);
        showError(err.message);
      });
  }

  const revokeDocument = () => {
    if (window.confirm("Are you sure you want to revoke this document?")) {
      setRevokeStatusLoading(true);
      revoke({
        document_id: data?.id
      })
        .then(async (res) => {
          let data = res.data;
          // console.log(data);
          if (data.success) {
            setRevokeStatusLoading(false);
            setRevokedStatusTxt('Revoke Request Sent');
          } else {
            if (isNotEmpty(data?.externalWallet) && data?.externalWallet) {

              setWalletAddr(accountAddr);
              setMetaMaskTransactionDetails(data);
              setOpenMetaMaskModal(true);

            } else {
              setRevokeStatusLoading(false);
              setRevokedStatusTxt(data.error);
            }
          }
        })
        .catch((err) => {
          console.log(err.message);
          setRevokeStatusLoading(false);
        });
    }
  }

  useEffect(() => {
    setLoading(true)
    checkRevokeStatus();
    verifyDoc(ttFile).then(res => {
      console.log(res);
      setVerificationStatus(res);
      setLoading(false);
    });
  }, [ttFile])

  useEffect(() => {
    if (tokenId && tokenRegistryAddress) {
      fetchEndorsementChain();
    }
  }, [tokenId, tokenRegistryAddress]);

  const canRevoked = () => {
    if (data.upload_method === documentUploadMethods.blockchain.value) {
      if (isTitleEscrow !== undefined) {
        if (canSurrender) {
          return true;
        }
      } else {
        return true;
      }
    }
    return false;
  }

  const closeModalForTransaction = (e) => {
    setRevokeStatusLoading(false);
    setMetaMaskTansactionStarted(false);
    setOpenMetaMaskModal(false);
  }

  const startMetaMaskTransaction = () => {
    setRevokeStatusLoading(true);
    setMetaMaskTansactionStarted(true);
    setMetaMaskTransactionProgressText("Revoking Document...");

    let data = metaMaskTransactionDetails;
    const web3Instance = new Web3(ethereum);
    const documentStoreContract = new web3Instance.eth.Contract(JSON.parse(data?.documentStore?.abi), data?.documentStore?.address);
    // console.log(documentStoreContract.methods); 
    documentStoreContract.methods.revoke(
      data?.merkleRoot
    ).send({
      from: accountAddr
    })
      .on('receipt', function (receipt) {
        markDocumentRevoked(receipt?.transactionHash);
      })
      .on('error', function (error, receipt) {
        showError(parseWeb3Error(error));
      });
  }

  return (
    <>
      {toast.isOpen && <Toast message={toast.message} isError={toast.isError} isSuccess={toast.isSuccess} closeToast={closeToast} />}

      {/* {!loading && (
            
        )} */}
      {loading ? <CircularProgress color="primary" size={20} /> : ttFile && verificationStatus ?
        <> <AssetManagementApplication
          tokenRegistryAddress={tokenRegistryAddress}
          tokenId={tokenId}
          setShowEndorsementChain={setShowEndorsementChain}
          document={ttFile}
          verificationStatus={verificationStatus}
          account={accountAddr}
          corporateList={corporateList}
          loadingEndrosmentChain={loadingEndrosmentChain}
        />

          {
            canRevoked() ? <>
              <div className='revoke-doc-box'>
                {
                  revokeStatusLoading ? <>
                    <CircularProgress color="primary" size={20} />
                  </> : <>
                    {revokeStatusTxt === '-' ? <>
                    </> : <>
                      {isNotEmpty(revokeStatusTxt) ?
                        <>
                          <span className='theme-badge'>{revokeStatusTxt}</span>
                        </> : <>
                          <a onClick={revokeDocument} href={undefined} className='theme-btn danger'>Revoke Document</a>
                        </>
                      }
                    </>
                    }
                  </>
                }
              </div>
            </> : ''
          }

          <EndrosmentChain
            handleCloseModal={handleClose}
            openModal={showEndorsementChain}
            endorsementChain={endrosmentChainData}
            verifyResponse={verificationStatus}
            tokenId={tokenId}
            tokenRegistryAddress={tokenRegistryAddress}
          ></EndrosmentChain>
        </>
        : <div>Unable to fetch the tt record. Please contact support.</div>
      }

      <MetaMaskTransaction
        open={openMetaMaskModal}
        walletAddress={walletAddr}
        transactionStarted={metaMaskTansactionStarted}
        transactionProgressText={metaMaskTransactionProgressText}

        handleClose={closeModalForTransaction}
        handleStart={startMetaMaskTransaction}
      />

    </>

  )

}

export default TransferablePanel;