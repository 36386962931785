import { useContractFunctionHook } from "@govtechsg/ethers-contract-hook";
import React, { createContext, useContext, useEffect, useState, useCallback } from "react";

// Internal imports
import { useTitleEscrowContract } from "../hooks/useTitleEscrowContract";
import { useProviderContext } from "./provider";
import { useTokenRegistryContract } from "../hooks/useTokenRegistryContract";
import { useRestoreToken } from "../hooks/useRestoreToken";
import { useSupportsInterface } from "../hooks/useSupportsInterface";
import { transferTitle, getCorporateListFromAddress } from "../../../../services/api.service"
// import { AssetManagementActions  } from "../AssetManagementActions"
import { useAuth } from '../../../../hooks';
import MetaMaskTransaction from "../../../MetaMaskTransaction";
import { useMetaMask } from "metamask-react";
import { isNotEmpty, parseWeb3Error } from "../../../../utils/Generic";
const Web3 = require('web3');

const contractFunctionStub = () => {
  return undefined
};

export const TokenInformationContext = createContext({
  initialize: () => { },
  changeHolder: contractFunctionStub,
  changeHolderState: "UNINITIALIZED",
  transferTo: contractFunctionStub,
  transferToState: "UNINITIALIZED",
  endorseBeneficiary: contractFunctionStub,
  endorseBeneficiaryState: "UNINITIALIZED",
  isSurrendered: false,
  isTokenBurnt: false,
  documentOwner: "",
  approveNewTransferTargets: contractFunctionStub,
  approveNewTransferTargetsState: "UNINITIALIZED",
  transferToNewEscrow: contractFunctionStub,
  transferToNewEscrowState: "UNINITIALIZED",
  resetStates: () => { },
  destroyToken: contractFunctionStub,
  destroyTokenState: "UNINITIALIZED",
  restoreToken: contractFunctionStub,
  restoreTokenState: "UNINITIALIZED",
});

export const TokenInformationContextProvider = ({
  children,
}) => {
  const [tokenId, setTokenId] = useState("");
  const [tokenRegistryAddress, setTokenRegistryAddress] = useState("");
  const [documentId, setDocumentId] = useState("");

  const { provider } = useProviderContext();
  const { tokenRegistry } = useTokenRegistryContract(tokenRegistryAddress, provider);
  const { titleEscrow, updateTitleEscrow, documentOwner } = useTitleEscrowContract(provider, tokenRegistry, tokenId);
  const isSurrendered = documentOwner === tokenRegistryAddress;
  const isTokenBurnt = documentOwner === "0x000000000000000000000000000000000000dEaD"; // check if the token belongs to burn address.
  const { user } = useAuth();

  // First check whether Contract is TitleEscrow
  // const isTitleEscrow = true // 0xdcce2211 is from TitleEscrow's ERC165 https://github.com/Open-Attestation/token-registry/blob/5cdc6d2ccda4fbbfcbd429ca90c3049e72bc1e56/contracts/TitleEscrow.sol#L56

  // First check whether Contract is TitleEscrow
  const { isInterfaceType: isTitleEscrow } = useSupportsInterface(titleEscrow, "0xdcce2211"); // 0xdcce2211 is from TitleEscrow's ERC165 https://github.com/Open-Attestation/token-registry/blob/5cdc6d2ccda4fbbfcbd429ca90c3049e72bc1e56/contracts/TitleEscrow.sol#L56

  // Contract Read Functions
  const { call: getHolder, value: holder } = useContractFunctionHook(titleEscrow, "holder");
  const { call: getBeneficiary, value: beneficiary } = useContractFunctionHook(titleEscrow, "beneficiary");
  const { call: getApprovedBeneficiary, value: approvedBeneficiary } = useContractFunctionHook(titleEscrow, "approvedBeneficiary");
  const { call: getApprovedHolder, value: approvedHolder } = useContractFunctionHook(titleEscrow, "approvedHolder");

  const [holderName, setHolderName] = useState("")
  const [beneficiaryName, setBeneficiaryName] = useState("")
  const [approvedBeneficiaryName, setApprovedBeneficiaryNamey] = useState("")
  const [approvedHolderName, setApprovedHolderName] = useState("");

  const [transactionHash, setTransactionHash] = useState(null);

  const { status, connect, account, chainId, ethereum } = useMetaMask();
  const [openMetaMaskModal, setOpenMetaMaskModal] = useState(null);
  const [walletAddr, setWalletAddr] = useState(null);
  const [metaMaskTransactionDetails, setMetaMaskTransactionDetails] = useState(null);
  const [metaMaskTansactionStarted, setMetaMaskTansactionStarted] = useState(null);
  const [metaMaskTransactionProgressText, setMetaMaskTransactionProgressText] = useState(false);

  const [metaMaskFuncDetails, setMetaMaskFuncDetails] = useState(null);

  // const {
  //   send: destroyToken,
  //   state: destroyTokenState,
  //   reset: resetDestroyingTokenState,
  // } = useContractFunctionHook(tokenRegistry, "destroyToken");

  const [destroyTokenState, setDestroyTokenState] = useState("UNINITIALIZED");
  const destroyToken = () => {
    setDestroyTokenState("PENDING_CONFIRMATION");
    transferTitle({
      tokenId: tokenId,
      tokenRegistryAddress: tokenRegistryAddress,
      operationType: "AcceptSurrendered",
      email: user.email,
      transactionHash
    })
      .then((res) => {
        console.log("res", res)
        if (res && res?.status === 200 && res?.data) {
          let response = res?.data;
          if (isNotEmpty(response?.externalWallet)) {

            setMetaMaskTransactionDetails(response);
            setWalletAddr(response?.walletAddress);
            setOpenMetaMaskModal(true);
            setMetaMaskFuncDetails({
              callFunc: 'destroyToken',
              params: []
            });

          } else {
            resetMetaMaskStates();
            setDestroyTokenState("CONFIRMED")
          };
        }else{
          showError(res?.data);
          resetMetaMaskStates();
        }
      })
      .catch((error) => {
        resetMetaMaskStates();
        alert("Failed!")
      })
  }
  const resetDestroyingTokenState = () => {
    // setDestroyTokenState("UNINITIALIZED")
  }

  // const { restoreToken, state: restoreTokenState } = useRestoreToken(provider, tokenRegistry, tokenId);
  const [restoreTokenState, setRestoreTokenState] = useState("UNINITIALIZED");
  const restoreToken = (lastBeneficiary, lastHolder) => {
    console.log("lastBeneficiary, lastHolder", lastBeneficiary, lastHolder)
    setRestoreTokenState("PENDING_CONFIRMATION");
    transferTitle({
      tokenId: tokenId,
      tokenRegistryAddress: tokenRegistryAddress,
      operationType: "RejectSurrendered",
      lastBeneficiary: lastBeneficiary,
      lastHolder: lastHolder,
      email: user.email,
      transactionHash
    })
      .then((res) => {
        console.log("res", res)
        if (res && res?.status === 200 && res?.data) {
          let response = res?.data;
          if (isNotEmpty(response?.externalWallet)) {

            setMetaMaskTransactionDetails(response);
            setWalletAddr(response?.walletAddress);
            setOpenMetaMaskModal(true);
            setMetaMaskFuncDetails({
              callFunc: 'restoreToken',
              params: [
                lastBeneficiary, lastHolder
              ]
            });

          } else {
            resetMetaMaskStates();
            setRestoreTokenState("CONFIRMED")
          };
        }else{
          showError(res?.data);
          resetMetaMaskStates();
        }
      })
      .catch((error) => {
        resetMetaMaskStates();
        alert("Failed!")
      })
  }
  const resetResetToken = () => {
    // setRestoreTokenState("UNINITIALIZED")
  }


  // Contract Write Functions (available only after provider has been upgraded)
  // const {
  //   send: transferTo,
  //   state: transferToState,
  //   reset: resetTransferTo,
  // } = useContractFunctionHook(titleEscrow, "transferTo");

  const [transferToState, setTransferToState] = useState("UNINITIALIZED");
  const transferTo = () => {
    setTransferToState("PENDING_CONFIRMATION")
    transferTitle({
      tokenId: tokenId,
      tokenRegistryAddress: tokenRegistryAddress,
      operationType: "Surrender",
      email: user.email,
      transactionHash
    })
      .then((res) => {
        console.log("res", res)
        if (res && res?.status === 200 && res?.data) {
          let response = res?.data;
          if (isNotEmpty(response?.externalWallet)) {

            setMetaMaskTransactionDetails(response);
            setWalletAddr(response?.walletAddress);
            setOpenMetaMaskModal(true);
            setMetaMaskFuncDetails({
              callFunc: 'transferTo',
              params: []
            });

          } else {
            resetMetaMaskStates();
            setTransferToState("CONFIRMED")
          };
        }else{
          showError(res?.data);
          resetMetaMaskStates();
        }
      })
      .catch((error) => {
        resetMetaMaskStates();
        alert("Failed!");
        // setTransferToState("UNINITIALIZED")
      })
  }
  const resetTransferTo = () => {
    // setTransferToState("UNINITIALIZED")
  }

  const resetMetaMaskStates = () => {
    setMetaMaskFuncDetails(null);   
    setMetaMaskTransactionDetails(null);
    setMetaMaskTansactionStarted(false);
    setOpenMetaMaskModal(false);
    setMetaMaskTransactionProgressText(null);
    setTransactionHash(null);
  }


  // const {
  //   send: changeHolder,
  //   state: changeHolderState,
  //   reset: resetChangeHolder,  
  // } = useContractFunctionHook(titleEscrow, "changeHolder");

  // Change Holder Callbacks
  const [changeHolderState, setChangeHolderState] = useState("UNINITIALIZED");
  const changeHolder = (newHolder, corporateId) => {
    setChangeHolderState("PENDING_CONFIRMATION");
    transferTitle({
      newHolder: newHolder,
      corporateId: corporateId,
      tokenId: tokenId,
      tokenRegistryAddress: tokenRegistryAddress,
      operationType: "TransferHolder",
      docId: documentId,
      email: user.email,
      transactionHash
    })
      .then((res) => {
        console.log("res", res)
        if (res && res?.status === 200 && res?.data) {
          let response = res?.data;
          if (isNotEmpty(response?.externalWallet)) {

            setMetaMaskTransactionDetails(response);
            setWalletAddr(response?.walletAddress);
            setOpenMetaMaskModal(true);
            setMetaMaskFuncDetails({
              callFunc: 'changeHolder',
              params: [
                newHolder, corporateId
              ]
            });

          } else {
            resetMetaMaskStates();
            setChangeHolderState("CONFIRMED");
          };
        }else{
          showError(res?.data);
          resetMetaMaskStates();
        }
      })
      .catch((error) => {
        resetMetaMaskStates();
        console.log("Error while Transfer", error)
        alert("Failed!")
      })
  }
  const resetChangeHolder = () => {
    // setChangeHolderState("UNINITIALIZED")
  }

  // const {
  //   send: endorseBeneficiary,
  //   state: endorseBeneficiaryState,
  //   reset: resetEndorseBeneficiary,
  // } = useContractFunctionHook(titleEscrow, "transferToNewEscrow");

  const [endorseBeneficiaryState, setEndorseBeneficiaryState] = useState("UNINITIALIZED");
  const endorseBeneficiary = (newBeneficiary, newHolder, beneficiaryCorporateId, holderCorporateId) => {
    setEndorseBeneficiaryState("PENDING_CONFIRMATION")
    transferTitle({
      newBeneficiary: newBeneficiary,
      newHolder: newHolder,
      tokenId: tokenId,
      tokenRegistryAddress: tokenRegistryAddress,
      beneficiaryCorporateId, holderCorporateId,
      operationType: "EndorseBeneficiary",
      docId: documentId,
      email: user.email,
      transactionHash
    })
      .then((res) => {
        console.log("res", res)
        if (res && res?.status === 200 && res?.data) {
          let response = res?.data;
          if (isNotEmpty(response?.externalWallet)) {

            setMetaMaskTransactionDetails(response);
            setWalletAddr(response?.walletAddress);
            setOpenMetaMaskModal(true);
            setMetaMaskFuncDetails({
              callFunc: 'endorseBeneficiary',
              params: [
                newBeneficiary, newHolder, beneficiaryCorporateId, holderCorporateId
              ]
            });

          } else {
            resetMetaMaskStates();
            setEndorseBeneficiaryState("CONFIRMED")
          };
        }else{
          showError(res?.data);
          resetMetaMaskStates();
        }
      })
      .catch((error) => {
        resetMetaMaskStates();
        alert("Failed!")
      })
  }
  const resetEndorseBeneficiary = () => {
    // setEndorseBeneficiaryState("UNINITIALIZED")
  }


  // const {
  //   send: approveNewTransferTargets,
  //   state: approveNewTransferTargetsState,
  //   reset: resetApproveNewTransferTargets,
  // } = useContractFunctionHook(titleEscrow, "approveNewTransferTargets");
  // Nominate Change of Ownership

  const [approveNewTransferTargetsState, setApproveNewTransferTargetsState] = useState("UNINITIALIZED");
  const approveNewTransferTargets = (newBeneficiary, newHolder, corporateId) => {
    setApproveNewTransferTargetsState("PENDING_CONFIRMATION")
    transferTitle({
      newBeneficiary: newBeneficiary,
      newHolder: newHolder,
      tokenId: tokenId,
      tokenRegistryAddress: tokenRegistryAddress,
      operationType: "NominateBeneficiaryHolder",
      corporateId,
      email: user.email,
      transactionHash
    })
      .then((res) => {
        console.log("res", res)
        if (res && res?.status === 200 && res?.data) {
          let response = res?.data;
          if (isNotEmpty(response?.externalWallet)) {

            setMetaMaskTransactionDetails(response);
            setWalletAddr(response?.walletAddress);
            setOpenMetaMaskModal(true);
            setMetaMaskFuncDetails({
              callFunc: 'approveNewTransferTargets',
              params: [
                newBeneficiary, newHolder, corporateId
              ]
            });

          } else {
            resetMetaMaskStates();
            setApproveNewTransferTargetsState("CONFIRMED")
          };
        }else{
          showError(res?.data);
          resetMetaMaskStates();
        }
      })
      .catch((error) => {
        resetMetaMaskStates();
        alert("Failed!")
      })
  }
  const resetApproveNewTransferTargets = () => {
    // setApproveNewTransferTargetsState("UNINITIALIZED")
  }

  // const {
  //   send: transferToNewEscrow,
  //   state: transferToNewEscrowState,
  //   reset: resetTransferToNewEscrow,
  // } = useContractFunctionHook(titleEscrow, "transferToNewEscrow");

  const [transferToNewEscrowState, setTransferToNewEscrowState] = useState("UNINITIALIZED");
  const transferToNewEscrow = (approvedBeneficiary, approvedHolder) => {
    setTransferToNewEscrowState("PENDING_CONFIRMATION")
    transferTitle({
      approvedBeneficiary: approvedBeneficiary,
      approvedHolder: approvedHolder,
      tokenId: tokenId,
      tokenRegistryAddress: tokenRegistryAddress,
      operationType: "EndorseTransfer",
      docId: documentId,
      email: user.email,
      transactionHash
    })
      .then((res) => {
        console.log("res", res)
        if (res && res?.status === 200 && res?.data) {
          let response = res?.data;
          if (isNotEmpty(response?.externalWallet)) {

            setMetaMaskTransactionDetails(response);
            setWalletAddr(response?.walletAddress);
            setOpenMetaMaskModal(true);
            setMetaMaskFuncDetails({
              callFunc: 'transferToNewEscrow',
              params: [
                approvedBeneficiary, approvedHolder
              ]
            });

          } else {
            resetMetaMaskStates();
            setTransferToNewEscrowState("CONFIRMED")
          };
        }else{
          showError(res?.data);
          resetMetaMaskStates();
        }
      })
      .catch((error) => {
        resetMetaMaskStates();
        alert("Failed!")
      })
  }
  const resetTransferToNewEscrow = () => {
    // setTransferToNewEscrowState("UNINITIALIZED")
  }

  const resetProviders = useCallback(() => {
    resetTransferTo();
    resetDestroyingTokenState();
    resetChangeHolder();
    resetEndorseBeneficiary();
    resetApproveNewTransferTargets();
    resetTransferToNewEscrow();
    resetResetToken()
  }, [
    resetDestroyingTokenState,
    resetApproveNewTransferTargets,
    resetChangeHolder,
    resetEndorseBeneficiary,
    resetTransferTo,
    resetTransferToNewEscrow,
    resetResetToken
  ]);

  const resetStates = useCallback(() => {
    setTokenId(undefined);
    setTokenRegistryAddress(undefined);
  }, []);

  const initialize = useCallback((address, id, docId) => {
    setTokenId(id);
    setTokenRegistryAddress(address);
    setDocumentId(docId);
  }, []);

  useEffect(() => {

  }, [tokenId, tokenRegistryAddress])

  // Fetch all new information when title escrow is initialized or updated (due to actions)
  useEffect(() => {
    if (isTitleEscrow) {
      // only fetch TitleEscrow info after we determine owner is a Title Escrow contract
      getHolder();
      getApprovedHolder();
      getBeneficiary();
      getApprovedBeneficiary();
    }
  }, [getApprovedBeneficiary, getApprovedHolder, getBeneficiary, getHolder, isTitleEscrow]);

  // Fetch the corporate name
  useEffect(() => {
    let addresses = [];
    if (holder) addresses.push(holder)
    if (beneficiary) addresses.push(beneficiary)
    if (approvedBeneficiary) addresses.push(approvedBeneficiary)
    if (approvedHolder) addresses.push(approvedHolder);

    if (addresses.length > 0) {
      getCorporateListFromAddress({ addresses })
        .then((res) => {
          if (res.data && res.data.addresses) {
            let addresses = res.data.addresses
            //Update Holder name
            if (holder && holder[0]) {
              let holderAddress = addresses.find((ele) => ele.address === holder[0].substring(2).toLowerCase());
              if (holderAddress && holderAddress.corporate && holderAddress.corporate.name) {
                setHolderName(holderAddress.corporate.name)
              }
            }

            // Update beneficiary name
            if (beneficiary && beneficiary[0]) {
              let beneficiaryAddress = addresses.find((ele) => ele.address === beneficiary[0].substring(2).toLowerCase());
              if (beneficiaryAddress && beneficiaryAddress.corporate && beneficiaryAddress.corporate.name) {
                setBeneficiaryName(beneficiaryAddress.corporate.name)
              }
            }

            // Update approvedBeneficiary name
            if (approvedBeneficiary && approvedBeneficiary[0]) {
              let approvedBeneficiaryAddress = addresses.find((ele) => ele.address === approvedBeneficiary[0].substring(2).toLowerCase());
              if (approvedBeneficiaryAddress && approvedBeneficiaryAddress.corporate && approvedBeneficiaryAddress.corporate.name) {
                setApprovedBeneficiaryNamey(approvedBeneficiaryAddress.corporate.name)
              }
            }

            // Update approvedHolder name
            if (approvedHolder && approvedHolder[0]) {
              let approvedHolderAddress = addresses.find((ele) => ele.address === approvedHolder[0].substring(2).toLowerCase());
              if (approvedHolderAddress && approvedHolderAddress.corporate && approvedHolderAddress.corporate.name) {
                setApprovedHolderName(approvedHolderAddress.corporate.name)
              }
            }
          }
        })
        .catch((error) => {
          console.log("Error Fetching Corporates", error)
        })
    }
  }, [holder, beneficiary, approvedBeneficiary, approvedHolder]);

  // Update holder whenever holder transfer is successful
  useEffect(() => {
    if (changeHolderState === "CONFIRMED") getHolder();
  }, [changeHolderState, getHolder]);

  // Update entire title escrow whenever endorse is successful
  useEffect(() => {
    if (endorseBeneficiaryState === "CONFIRMED") updateTitleEscrow();
  }, [endorseBeneficiaryState, updateTitleEscrow]);

  // Update entire title escrow whenever transferTo is successful
  useEffect(() => {
    if (transferToState === "CONFIRMED") updateTitleEscrow();
  }, [transferToState, updateTitleEscrow]);

  // Update entire title escrow whenever token is burnt
  useEffect(() => {
    if (destroyTokenState === "CONFIRMED") updateTitleEscrow();
  }, [destroyTokenState, updateTitleEscrow]);

  useEffect(() => {
    if (restoreTokenState === "CONFIRMED") updateTitleEscrow();
  }, [restoreTokenState, updateTitleEscrow]);

  // Update entire title escrow whenever endorse transfer to beneficiary and holder is successful
  useEffect(() => {
    if (transferToNewEscrowState === "CONFIRMED") updateTitleEscrow();
  }, [transferToNewEscrowState, updateTitleEscrow]);

  // Reset states for all write functions when provider changes to allow methods to be called again without refreshing
  useEffect(resetProviders, [resetProviders, provider]);

  const showError = (err) => {
    setMetaMaskTansactionStarted(false);
    let errorMsg = err?.message;
    if (typeof err == "string") {
      errorMsg = err;
    }
    console.log(errorMsg);
    // setToast((prev) => ({
    //   ...prev,
    //   isOpen: true,
    //   isError: true,
    //   isSuccess: false,
    //   message: errorMsg
    // }));
  }

  const closeModalForTransaction = (e) => {
    setMetaMaskTransactionDetails(null);
    setMetaMaskTansactionStarted(false);
    setOpenMetaMaskModal(false);
  }

  const startMetaMaskTransaction = () => {
    let transDetails = metaMaskTransactionDetails;
    if (transDetails != null) {
      let funcDetails = metaMaskFuncDetails;
      console.log("funcDetails", funcDetails);
      console.log("transDetails", transDetails);
      //changeHolder(...funcDetails.params);

      const web3Instance = new Web3(ethereum);
      const titleEscrowContract = new web3Instance.eth.Contract(JSON.parse(transDetails?.titleEscrow?.abi), transDetails?.titleEscrow?.address);

      setMetaMaskTansactionStarted(true);
      setMetaMaskTransactionProgressText('Processing Request...');

      titleEscrowContract.methods[transDetails.contract.method](...transDetails.contract.params).send({
        from: transDetails?.walletAddress
      })
        .on('receipt', function (receipt) {
          setTransactionHash(receipt.transactionHash)
        })
        .on('error', function (error, receipt) {
          console.log(error);
          showError(parseWeb3Error(error));
        });
    }
  }

  useEffect(() => {
    let funcDetails = metaMaskFuncDetails;
    if (transactionHash != null && funcDetails != null) {
      switch (funcDetails.callFunc) {
        case 'destroyToken':
          destroyToken(...funcDetails.params);
          break;
        case 'restoreToken':
          restoreToken(...funcDetails.params);
          break;
        case 'transferTo':
          transferTo(...funcDetails.params);
          break;
        case 'changeHolder':
          changeHolder(...funcDetails.params);
          break;
        case 'endorseBeneficiary':
          endorseBeneficiary(...funcDetails.params);
          break;
        case 'approveNewTransferTargets':
          approveNewTransferTargets(...funcDetails.params);
          break;
        case 'transferToNewEscrow':
          transferToNewEscrow(...funcDetails.params);
          break;
      }
    }
  }, [transactionHash]);

  return (
    <>

      <TokenInformationContext.Provider
        value={{
          tokenId,
          tokenRegistryAddress,
          initialize,
          holder: holder?.[0],
          beneficiary: beneficiary?.[0],
          approvedBeneficiary: approvedBeneficiary?.[0],
          approvedHolder: approvedHolder?.[0],
          changeHolder,
          endorseBeneficiary,
          transferTo,
          changeHolderState,
          endorseBeneficiaryState,
          transferToState,
          destroyTokenState,
          destroyToken,
          isSurrendered,
          isTokenBurnt,
          isTitleEscrow,
          documentOwner,
          approveNewTransferTargets,
          approveNewTransferTargetsState,
          transferToNewEscrow,
          transferToNewEscrowState,
          resetStates,
          restoreToken,
          restoreTokenState,
          holderName,
          beneficiaryName,
          approvedBeneficiaryName,
          approvedHolderName
        }}
      >
        {children}
      </TokenInformationContext.Provider>

      <MetaMaskTransaction
        open={openMetaMaskModal}
        walletAddress={walletAddr}
        transactionStarted={metaMaskTansactionStarted}
        transactionProgressText={metaMaskTransactionProgressText}

        handleClose={closeModalForTransaction}
        handleStart={startMetaMaskTransaction}
      />

    </>
  );
};

export const useTokenInformationContext = () =>
  useContext(TokenInformationContext);
