import React, { FunctionComponent,useState } from "react";
import { Box, Button, Container, makeStyles } from '@material-ui/core';
import { AddressInfo } from "./AddressInfo";
import { ExternalLinkEtherscanAddress } from "./ExternalLink";
import { CircularProgress } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  feildButton: {
    background:"#66CFA4;",
    borderRadius:"20px",
    color:"#000",
    textTransform:"capitalize",
    padding:"7.5px 15px"
  },
  feildLabel: {
    color: "#000",
    fontSize:"18px",
    margin:"10px 0px",
    fontFamily: "Poppins"
  }
}));
export const AssetInformationPanel = ({
  tokenRegistryAddress,
  setShowEndorsementChain,
  loadingEndrosmentChain,
  className,
}) => {
  const classes = useStyles();
  return (
      <Box>
        <AddressInfo  title="" name="" resolvedBy="" source="">
          <ExternalLinkEtherscanAddress className={`${classes.feildLabel}`} name="View BL Registry" address={tokenRegistryAddress} />
          {loadingEndrosmentChain ?  <Button
            size="small"
            className={classes.margin}
            onClick={() => setShowEndorsementChain(true)}
            className={`cursor-pointer transition-colors duration-200 ease-out text-cerulean-200 hover:text-cerulean ${classes.feildButton}`}
            id="endorsement-chain-button"
          >
            View Endorsement Chain
          </Button> : <CircularProgress color="primary" size={20}/>  }
         
        </AddressInfo>
      </Box>
  );
};