import {
  makeStyles,
  Grid,
  Typography,
  AppBar,
  Paper,
  Toolbar,
  Button,
  Box,
} from "@material-ui/core";
import React, { useState } from "react";
import { Link, NavLink, useHistory, useLocation } from "react-router-dom";
import { useAuth } from "../hooks";
import LoginBg from "../static/images/login-bg.png";
import logo from "../static/images/logo.png";
import RegisterForm from "../components/auth/RegisterForm";
import LoginBg1 from "../static/images/login-bg-1.jpeg";
import LoginBg2 from "../static/images/login-bg-2.jpeg";
import LoginBg3 from "../static/images/login-bg-3.jpeg";
import LoginBg4 from "../static/images/login-bg-4.jpeg";
import LoginBg5 from "../static/images/login-bg-5.jpeg";
import CreateSignature from "../components/CreateSignature";
const bg = [LoginBg1, LoginBg2, LoginBg3, LoginBg4, LoginBg5];
const imageUrl = bg[Math.floor(Math.random() * bg.length)];

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "80%",
    justifyContent: "center",
    marginTop: -20,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(2),
  },
  toolbar: theme.mixins.toolbar,
  logo: {
    height: "80px",
    width: "auto",
    borderRadius: "0px",
    marginLeft: 60,
  },
  appBar: {
    marginTop: 30,
  },
  link: {
    cursor: "pointer",
    textDecoration: "none",
    color: "#000000d6",
    fontSize: "0.875rem",
    fontFamily: "Poppins",
    fontWeight: "400",
    lineHeight: "1.43",
  }
}));

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://material-ui.com/">
        TruDoc.io
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const Register = () => {
  const location = useLocation();
  const history = useHistory();
  const classes = useStyles();
  const { user } = useAuth();
  // const [formType, setFormType] = useState("login");
  // const [open, setOpen] = React.useState(false);

  if (user) {
    const { from } = location.state || { from: { pathname: "/dashboard" } };
    history.replace(from);
  }
  // const handleOpen = () => {
  //   setOpen(true);
  // };
  // const handleClose = () => {
  //   setOpen(false);
  // };
  // const updateForm = () => {
  //   setFormType(formType === "signup" ? "login" : "signup");
  // };
  return (
    <Grid container component="main" className={classes.root}>
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        className={classes.image}
        style={{ backgroundImage: `url(${imageUrl})` }}
      />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <AppBar
          position="static"
          className={classes.appBar}
          elevation={0}
          color="transparent"
        >
          <Toolbar>
            <Box flexGrow={1} align="center">
              <img src={logo} alt="TruDoc" className={classes.logo} />
            </Box>
          </Toolbar>
        </AppBar>
        <div className={classes.paper}>
          <Box align="center" width="100%">
            <Typography component="h1" variant="h5" gutterBottom>
              Create new Account
            </Typography>
            <RegisterForm />
            <Grid container className={classes.form}>
              <Grid item align="right" xs={12}>
                <NavLink to="/login" className={classes.link}>
                  Already have a account? Login
                </NavLink>
                {/* <Typography
                  onClick={updateForm}
                  variant="body2"
                  style={{ cursor: "pointer" }}
                >
                  {formType === "signup"
                    ? "Already have a account? Login"
                    : `Don't have an account? Sign Up`}
                </Typography> */}
              </Grid>
            </Grid>
          </Box>
        </div>
      </Grid>
    </Grid>
  );
};

export default Register;