import { FETCH_USER_SIGNATURE, RESET_FETCH_USER_SIGNATURE } from '../redux.constants';

const FetchSignature = (state = false, action) => {
  switch (action.type) {
    case FETCH_USER_SIGNATURE:
      return !state;
    case RESET_FETCH_USER_SIGNATURE:
      return false;
    default:
      return state;
  }
}

export default FetchSignature;