import { LOADING } from '../redux.constants';

const ToggleLoading = (state = false, action) => {
  switch (action.type) {
    case LOADING:
      return !state;
    default:
      return state;
  }
}

export default ToggleLoading;