import React, { useEffect, useRef, useState } from "react";
import GaugeChart from "react-gauge-chart";
import { isNotEmpty } from "../../utils/Generic";
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { BsThreeDotsVertical } from "react-icons/bs";
const KpiTable = (props) => {

    const { objective, handleDeleteKPI, handleEditKPI } = props;

    function formatDate1(dateString) {
        const options = { year: 'numeric', month: 'long' };
        const formattedDate = new Date(dateString).toLocaleDateString(undefined, options);
        return formattedDate;
    }

    console.log(objective?.sustainability_kpis)
    return (
        <>
            {
                isNotEmpty(objective?.sustainability_kpis) && objective?.sustainability_kpis.length > 0 ? <>
                    {
                        objective?.sustainability_kpis.map((kpiItem) => {
                            return <>
                                <tr rowspan="7">
                                    <td colspan="3" component="th" scope="row" className="track-kpi-links">
                                        {kpiItem?.kpi}
                                    </td>
                                    <td component="th" scope="row" className="track-kpi-links">                                        
                                        {kpiItem?.kpi_roadmap}
                                    </td>
                                    <td component="th" scope="row" className="track-kpi-links">
                                        <img src={`/assets/images/${kpiItem?.kpi_status}.png`} alt="" />
                                        {kpiItem?.kpi_status}
                                    </td>
                                    <td component="th" scope="row" className="track-kpi-links">
                                        {formatDate1(kpiItem?.created_at)}
                                    </td>
                                    <td component="th" scope="row" className="track-kpi-links">
                                        {/* {kpiItem?.kpi_progress} */}
                                       <GaugeChart id="gauge-chart1" nrOfLevels={8} cornerRadius={1} percent={`${kpiItem?.kpi_progress / 100}`} colors={["#FF0000","#4FAC3F"]} animate={false} hideText={true} />
                                    </td>
                                    <td>
                                    <ul className="dropbar">
                                            <li>
                                                <button className="dotbnt"><BsThreeDotsVertical /></button>
                                                <div className="dropcontent">
                                                    <button onClick={e => handleDeleteKPI(e, kpiItem)} className="table-delete-icon">
                                                        <DeleteIcon />Delete KPI
                                                    </button>
                                                    <button onClick={e => handleEditKPI(e, kpiItem, objective.id)} className="table-delete-icon">
                                                        <EditIcon />Edit KPI
                                                    </button>
                                                </div>
                                            </li>

                                        </ul>
                                    </td>
                                </tr>
                            </>
                        })
                    }
                </> : <>
                    <tr rowspan="7">
                        <td colSpan="4" component="th" scope="row" className="text-center">
                            No KPIs Found
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                </>
            }
        </>
    )
};

export default KpiTable;