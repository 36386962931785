import React, { useRef, useEffect, useState } from "react";
import WebViewer from "@pdftron/webviewer";
import { Button, CircularProgress, makeStyles } from "@material-ui/core";
import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  LinearProgress,
  NativeSelect,
  Radio,
  RadioGroup,
  Snackbar,
  SvgIcon,
  Typography,
} from "@material-ui/core";
import Toast from "../Toast";
import { useAuth } from "../../hooks";
import "./Viewer.css";
import { isNotEmpty } from "../Generic";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: "transparent",
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  paper1: {
    display: "flex",
    flexWrap: "wrap",
    backgroundColor: "transparent",
    "& > *": {
      margin: theme.spacing(3),
      padding: theme.spacing(2),
      width: "100%",
      textAlign: "center",
    },
  },
  edocuments: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  paper2: {
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(3),
      padding: theme.spacing(2),
      width: "100%",
    },
  },
  paper3: {
    backgroundColor: "transparent",
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(3),
      padding: theme.spacing(2),
      width: "100%",
      height: 350,
    },
  },
  dropIcon: {
    width: "150px",
    padding: theme.spacing(2),
  },
  docOptions: {
    cursor: "pointer",
    background: "#66cfa3",
    padding: "5px 20px",
    color: "#fff",
    borderRadius: "20px",
  },
  icon: {
    margin: theme.spacing(1),
  },
  formControl: {
    minWidth: "100%",
  },
  contentMargin: {
    marginTop: theme.spacing(3),
  },
  addSign: {
    marginTop: "10px",
    color: "#fff",
    background: "#3da543",
    padding: "3px 8px",
    borderRadius: "10px",
    "&:hover": {
      color: "#fff",
      background: "#3da543",
    },
  },
  addTextField: {
    color: "#3da543",
    background: "#d9d9d940",
    padding: "3px 8px",
    borderRadius: "10px",
  },
  selectBox: {
    marginRight: "20px",
    display: "flex",
    flexDirection: "column",
    rowGap: "10px",
  },
  webViewerStyle: {
    overflow: "hidden",
    width: "calc(100vw - 620px)",
    height: "100vh",
  },
  hrLine: {
    background: "#ccc",
    width: "100%"
  }
}));

let signIds = {};
const signPriority = {};

const CreateSignature = (props) => {
  const toastInitialValues = {
    isOpen: false,
    isSuccess: false,
    isError: false,
    message: "",
  };
  const [instance, setInstance] = useState(null);
  const [assignee, setAssignee] = useState("");
  const [toast, setToast] = useState(toastInitialValues);
  const classes = useStyles();
  const viewer = useRef(null);
  const filePicker = useRef(null);
  const { user } = useAuth();
  const [selectedAssignee, setSelectedAssignee] = useState(null);

  const closeToast = () => {
    return setToast((prev) => ({
      ...prev,
      isOpen: false,
      isSuccess: false,
      isError: false,
      message: "",
    }));
  };

  useEffect(() => {
    WebViewer(
      {
        path: "/webviewer/lib",
        css: "/style.css",
        fullAPI: true,
        disableLogs: true,
        // enableAnnotations: false,
        // enableAnnotationNumbering: true,
        disabledElements: [
          "ribbons",
          "toggleNotesButton",
          "searchButton",
          "menuButton",
          "rubberStampToolGroupButton",
          "stampToolGroupButton",
          "fileAttachmentToolGroupButton",
          "calloutToolGroupButton",
          // "undo",
          // "redo",
          "eraserToolButton",
          "toolsHeader",
          "leftPanelButton",
          "viewControlsButton",
          "selectToolButton",
          // "annotationPopup",
          "annotationCommentButton",
          "annotationStyleEditButton",
          "linkButton",
          "toolsOverlay"
        ],
      },
      viewer.current
    ).then((instance) => {
      setInstance(instance);
      const { documentViewer } = instance.Core;
      documentViewer.zoomTo(1); //Zoom to 100%
      const annotationManager = documentViewer.getAnnotationManager();
      annotationManager.setCurrentUser(user.username);
      if (isNotEmpty(props?.fileName)) {
        instance.UI.loadDocument(props.file, { filename: props.fileName });
      } else {
        instance.UI.loadDocument(props.file);
      }
      annotationManager.addEventListener('annotationChanged', (annotations, action) => {
        if (action == "add") {
          annotationManager.deselectAllAnnotations();
          // let annotId = annotations.Id ? annotations.Id : annotations[0].Id;    
          annotationManager.selectAnnotations(annotations);
        }
      });
    });
  }, []);

  const handleChangeAssignee = (event) => {
    const val = props.signatoryList.find(e => e.username === event.target.value);
    setSelectedAssignee(val);
    setAssignee(event.target.value);
  };

  const applyFields = async () => {
    const { Annotations, documentViewer } = instance.Core;
    const annotationManager = documentViewer.getAnnotationManager();
    const fieldManager = annotationManager.getFieldManager();
    const annotationsList = annotationManager.getAnnotationsList();
    const annotsToDelete = [];
    const annotsToDraw = [];
    await Promise.all(
      annotationsList.map(async (annot, index) => {
        let inputAnnot;
        let field;

        if (typeof annot.custom !== "undefined") {
          if (annot.custom.type === "SIGNATURE") {
            field = new Annotations.Forms.Field(
              annot.getContents() + Date.now() + index,
              {
                type: "Sig",
              }
            );
            inputAnnot = new Annotations.SignatureWidgetAnnotation(field, {
              appearance: "_DEFAULT",
              appearances: {
                _DEFAULT: {
                  Normal: {
                    data: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAYdEVYdFNvZnR3YXJlAHBhaW50Lm5ldCA0LjEuMWMqnEsAAAANSURBVBhXY/j//z8DAAj8Av6IXwbgAAAAAElFTkSuQmCC",
                    offset: {
                      x: 100,
                      y: 100,
                    },
                  },
                },
              },
            });
          } else {
            // exit early for other annotations
            annotationManager.deleteAnnotation(annot, false, true); // prevent duplicates when importing xfdf
            return;
          }
        } else {
          // exit early for other annotations
          return;
        }

        // set position
        inputAnnot.PageNumber = annot.getPageNumber();
        inputAnnot.X = annot.getX();
        inputAnnot.Y = annot.getY();
        inputAnnot.rotation = annot.Rotation;
        if (annot.Rotation === 0 || annot.Rotation === 180) {
          inputAnnot.Width = annot.getWidth();
          inputAnnot.Height = annot.getHeight();
        } else {
          inputAnnot.Width = annot.getHeight();
          inputAnnot.Height = annot.getWidth();
        }

        // delete original annotation
        annotsToDelete.push(annot);

        // customize styles of the form field
        Annotations.WidgetAnnotation.getCustomStyles = function (widget) {
          if (widget instanceof Annotations.SignatureWidgetAnnotation) {
            return {
              border: "1px solid #66CFA4",
            };
          }
        };
        Annotations.WidgetAnnotation.getCustomStyles(inputAnnot);

        // draw the annotation the viewer
        annotationManager.addAnnotation(inputAnnot);
        fieldManager.addField(field);
        annotsToDraw.push(inputAnnot);
      })
    );

    // delete old annotations
    annotationManager.deleteAnnotations(annotsToDelete, null, true);

    // refresh viewer
    await annotationManager.drawAnnotationsFromList(annotsToDraw);
    await uploadForSigning(); //----------------return new docs
  };

  const addTextField = async () => {
    const { annotationManager, Annotations } = instance.Core;
    const annot = new Annotations.FreeTextAnnotation(Annotations.FreeTextAnnotation.Intent.FreeText, {
      PageNumber: 1,
      X: 100,
      Y: 50,
      Width: 100,
      Height: 30,
      TextAlign: 'center',
      TextVerticalAlign: 'center',
      TextColor: new Annotations.Color(0, 0, 0, 1),
      StrokeColor: new Annotations.Color(0, 0, 0, 0),
    });
    console.log(annot);
    annot.setContents('Enter Text Here');
    annotationManager.addAnnotation(annot);
    annotationManager.redrawAnnotation(annot);
  }

  const addField = (type, point = {}, name = "", value = "", flag = {}) => {
    if (assignee === "") {
      setToast((prev) => ({
        ...prev,
        isOpen: true,
        isError: true,
        message: "Please select atleast one signatories",
      }));
      return;
    }

    const { documentViewer, Annotations } = instance.Core;
    const annotationManager = documentViewer.getAnnotationManager();
    const doc = documentViewer.getDocument();
    const displayMode = documentViewer.getDisplayModeManager().getDisplayMode();
    const page = displayMode.getSelectedPages(point, point);
    if (!!point.x && page.first == null) {
      return; //don't add field to an invalid page location
    }
    const page_idx = page.first !== null ? page.first : documentViewer.getCurrentPage();
    const page_info = doc.getPageInfo(page_idx);
    const page_point = displayMode.windowToPage(point, page_idx);
    const zoom = documentViewer.getZoom();

    var textAnnot = new Annotations.FreeTextAnnotation();
    textAnnot.PageNumber = page_idx;
    const rotation = documentViewer.getCompleteRotation(page_idx) * 90;
    textAnnot.Rotation = rotation;
    if (rotation === 270 || rotation === 90) {
      textAnnot.Width = 50.0 / zoom;
      textAnnot.Height = 250.0 / zoom;
    } else {
      textAnnot.Width = 250.0 / zoom;
      textAnnot.Height = 50.0 / zoom;
    }
    textAnnot.X = (page_point.x || page_info.width / 2) - textAnnot.Width / 2;
    textAnnot.Y = (page_point.y || page_info.height / 2) - textAnnot.Height / 2;

    textAnnot.setPadding(new Annotations.Rect(0, 0, 0, 0));
    textAnnot.custom = {
      type,
      value,
      flag,
      name: `${assignee}_${type}_`,
    };
    textAnnot.setContents(textAnnot.custom.name);
    textAnnot.FontSize = "" + 20.0 / zoom + "px";
    textAnnot.FillColor = new Annotations.Color(211, 211, 211, 0.5);
    textAnnot.TextColor = new Annotations.Color(0, 165, 228);
    textAnnot.StrokeThickness = 1;
    textAnnot.StrokeColor = new Annotations.Color(0, 165, 228);
    textAnnot.TextAlign = "center";

    textAnnot.Author = annotationManager.getCurrentUser();
    annotationManager.deselectAllAnnotations();
    annotationManager.addAnnotation(textAnnot, true);
    annotationManager.redrawAnnotation(textAnnot);
    annotationManager.selectAnnotation(textAnnot);
  };

  const uploadForSigning = async () => {
    const { docViewer, annotManager } = instance;
    const { documentViewer } = instance.Core;
    const annotationManager = documentViewer.getAnnotationManager();
    const annots = annotationManager.getAnnotationsList();
    let priority = 1;
    annots.forEach(e => {
      for (let x = 0; x < props.signatoryList.length; x++) {
        if (e.Subject === "Widget" && e.element.id.split('_SIGNATURE_')[0] === props.signatoryList[x].username) {
          if (!signIds[props.signatoryList[x].user_id]) {
            signIds[props.signatoryList[x].user_id] = [];
            signPriority[props.signatoryList[x].user_id] = priority;
            priority += 1;
          }
          signIds[props.signatoryList[x].user_id].push(e.Id);
          break;
        }
      }
    })
    const doc = docViewer.getDocument();
    const xfdfString = await annotManager.exportAnnotations({
      widgets: true,
      fields: true
    });
    const data = await doc.getFileData({ xfdfString });
    const arr = new Uint8Array(data);
    const blob = new Blob([arr], { type: "application/pdf" });
    props.uploadDocument(blob, signIds, signPriority);
    signIds = {}
  };

  useEffect(() => {
    if (props.sendFile) {
      applyFields();
    }
  }, [props.sendFile]);

  return (
    <>
      {toast.isOpen && (
        <Toast
          message={toast.message}
          isError={toast.isError}
          isSuccess={toast.isSuccess}
          closeToast={closeToast}
        />
      )}
      {
        props.isloading ? <Grid container>
          <Grid container item xs={12}>
            <Box width="100%" className="text-center">
              <CircularProgress color="primary" size={50} />
            </Box>
          </Grid>
        </Grid> :
          <div className={"prepareDocument"}>
            <div style={{ display: "flex", flexDirection: "row", flex: "grow" }}>
              <div className={classes.selectBox}>
                <Grid item>
                  <Typography className="fileupl-title">
                    Select signatories*:
                  </Typography>
                </Grid>
                <Grid item>
                  <FormControl className={classes.formControl}>
                    <NativeSelect
                      value={assignee}
                      onChange={handleChangeAssignee}
                      name="assignee_id"
                      className={`${classes.selectEmpty} upl-select-box select-sm-width`}
                      inputProps={{ "aria-label": "assignee_id" }}
                    >
                      <option value="">Please Select</option>
                      {props.signatoryList &&
                        props.signatoryList.length > 0 &&
                        props.signatoryList?.map((item, index) => (
                          <option key={index} value={item.username}>
                            {item.username}
                          </option>
                        ))}
                    </NativeSelect>
                  </FormControl>
                </Grid>
                <Button
                  className={classes.addSign}
                  onClick={() => addField("SIGNATURE")}
                >
                  Add
                </Button>
                <hr className={classes.hrLine} />
                <Button
                  className={classes.addTextField}
                  onClick={() => addTextField()}
                >
                  Add Text Field
                </Button>
              </div>
              <div
                className={`${classes.webViewerStyle} webviewer`}
                ref={viewer}
              ></div>
            </div>
            <input type="file" ref={filePicker} style={{ display: "none" }} />
          </div>
      }
    </>
  );
};

export default CreateSignature;
