import React from "react";
import {
  Typography,
  Button,
} from "@material-ui/core";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import moment from "moment";
import { isNotEmpty } from "../../utils/Generic";
import { isEmpty } from "@magic-sdk/provider";
import { needToAcceptChat } from "../../utils/chat";

const ChatProfileBox = (props) => {

  const { chat, showAction, activeChat } = props;


  const isGroup = () => {
    if (chat?.is_group.toString() === '1') {
      return true;
    }
    return false;
  }

  const getChatIcon = () => {
    if (isGroup()) {
      return '/assets/images/group-members.svg';
    }
    return '/assets/images/member-active.svg';
  }

  const chatName = () => {
    if (isGroup()) {
      return chat?.name;
    } else {
      return chat?.friend_member[0]?.user?.email;
    }
  }

  const chatSubTitle = () => {
    if (isGroup()) {
      return lastActivityTime();
    } else {
      if (isNotEmpty(chat?.friend_member[0]?.corporate?.name)) {
        return chat?.friend_member[0]?.corporate?.name;
      } else {
        return lastActivityTime();
      }
    }
  }

  const lastActivityTime = () => {
    const today = new Date();
    let date = new Date(chat?.last_activity_on);
    let momentTime = moment(date);
    if (today.toDateString() === date.toDateString()) {
      return momentTime.format('LT');
    }
    return momentTime.format('lll');
  }

  const profileClick = () => {
    if (!needToAcceptChat(chat)) {
      props?.handleOnClick();
    }
  }

  const isActiveChat = () => {
    if (activeChat?.uuid == chat?.uuid) {
      return true;
    } else {
      return false;
    }
  }

  return (
    <>
      <div onClick={profileClick} className={`chat-profile-box ${isActiveChat() ? 'active' : ''} ${needToAcceptChat(chat) ? 'accept-pending' : 'accepted'}  ${isGroup() ? 'group' : 'chat'}`}>

        <div className="img" style={{ "backgroundImage": 'url("' + getChatIcon() + '")' }}>
          {
            (isNotEmpty(showAction) && chat?.total_unread_messages > 0) ? <>
              <span className="message-count">{chat?.total_unread_messages}</span>
            </> : null
          }
        </div>

        <div className="info">
          <Typography variant="h6">{chatName()}</Typography>
          <Typography variant="p">{chatSubTitle()}</Typography>
        </div>

        {
          showAction && needToAcceptChat(chat) ? <>
            <div className="actions">
              <a onClick={props?.handleAcceptChat} href={undefined} className="accept-chat">Accept</a>
            </div>
          </> : null
        }

      </div>
      {
        props?.showDelete && 
        <Button className="btn-red" variant="outlined" onClick={() => props?.deleteChat(chat)} startIcon={<DeleteForeverIcon />}>Delete chat</Button>
      }
    </>
  );
};

export default ChatProfileBox; 
