import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@material-ui/core";
import { makeStyles, styled } from "@material-ui/core/styles";
import Toast from "../../utils/Toast";
import { useHistory } from "react-router-dom";
import { goalsParseCategories, isEmpty, isNotEmpty, parseObjectiveSubjects } from "../../utils/Generic";
import UnsdgSubjectNumber from "./unsdg-subject-number";
import DeleteIcon from '@material-ui/icons/Delete';
import { deleteObjective } from "../../services/admin.service";

const useStyles = makeStyles((theme) => ({
  root: {
    width: `calc(100% - ${300}px)`,
    marginLeft: 280,
    minHeight: "88vh",
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({

}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  height: '118px',
  border: 'none',
  '&:nth-of-type(even)': {
    backgroundColor: '#334bc120',
    border: 'none'
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const ListObjective = (props) => {

  console.log(props,"NAMAN")

  const { goals, objectives, loadData} = props;

  let history = useHistory();

  const toastInitialValues = {
    isOpen: false,
    isSuccess: false,
    isError: false,
    message: ""
  }

  const [toast, setToast] = useState(toastInitialValues);

  const closeToast = () => {
    return setToast((prev) => ({
      ...prev,
      isOpen: false,
      isSuccess: false,
      isError: false,
      message: ""
    }));
  };

  const showError = (errorMsg) => {
    setToast((prev) => ({
      ...prev,
      isOpen: true,
      isError: true,
      isSuccess: false,
      message: errorMsg
    }));
  }

  const showSuccess = (succMsg) => {
    setToast((prev) => ({
      ...prev,
      isOpen: true,
      isError: false,
      isSuccess: true,
      message: succMsg
    }));
  }

  const goToPage = (pageUrl) => {
    history.push(pageUrl);
  }

  const handleDeleteObjective = (e, objectiveData) => {
    
    if (window.confirm("Are you sure you want to delete objective?") === true) {

      deleteObjective({
        id: objectiveData.id
      })
        .then(res => {
          const response = res.data
          if (response.success == true)
            showSuccess(response.message)
          else
            showError(response.message)

          loadData()
        })
        .catch(err => {
          console.log(err)
          showError(err)
        })
    }
  }

  console.log("objectives", objectives);

  return (
    <>
      {toast.isOpen && <Toast message={toast.message} isError={toast.isError} isSuccess={toast.isSuccess} closeToast={closeToast} />}

      <div className="navy-table blue-table">
        <table aria-label="simple table">
          <thead>
            <tr>
              <th align="left">Objective</th>
              <th align="left">Material Topics</th>
              <th></th>
              {/* <th align="left">KPIs</th> */}
            </tr>
          </thead>
          <tbody>
            {
              objectives.length > 0 ? <>
                {
                  objectives.map((objective, index) => {
                    let subjectsData = parseObjectiveSubjects(objective, goals);
                    return <>
                      <tr key={index}>
                        <td component="th" scope="row" style={{cursor:'pointer'}} onClick={(e) => goToPage("/sustainability/objective/" + objective?.id)}>{objective?.title}</td>
                        <td align="left">
                          <div className="round-numbers-box">
                            {
                              isNotEmpty(subjectsData) ? <>
                                {
                                  Object.keys(subjectsData).map((sKey) => {
                                    let itemObjectives = subjectsData[sKey];
                                    if (itemObjectives.length > 0) {
                                      return <>
                                        <UnsdgSubjectNumber goals={goals} objectives={objectives} showMulti={true} subject={itemObjectives[0]} subjects={itemObjectives} />
                                      </>
                                    }
                                  })
                                }
                              </> : null
                            }
                          </div>
                        </td>
                        <td>
                            <button className="table-delete-icon" onClick={e => handleDeleteObjective(e, objective)}>
                              <DeleteIcon />
                            </button>
                        </td>
                        {/* <td align="left">{isNotEmpty(objective?.kpis) ? objective?.kpis.length : '0'}</td> */}
                      </tr>
                    </>
                  })
                }
              </> : <>

              </>
            }
          </tbody>
        </table>
      </div>

    </>
  );
};

export default ListObjective;