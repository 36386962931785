import { useState } from 'react'
import { Grid, Typography, makeStyles, Paper } from '@material-ui/core';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      padding: '0 20px'
    },
    subHeading: {
      fontSize: 17,
      fontWeight: '500',
    },
    item: {
      fontSize: 16,
      borderWidth: 2,
      width: 200,
      padding: 30,
      cursor: 'pointer',
      '&:hover' : {
        color: theme.palette.admin.main,
        borderColor: theme.palette.admin.main,
      }
    },
    itemDesc: {
      marginTop: 15,
      fontSize: 14,
      lineHeight: 2
    },
    itemSelect: {
      fontSize: 16,
      borderWidth: 2,
      width: 200,
      padding: 30,
      borderColor: theme.palette.admin.main,
      color: theme.palette.admin.main,
      cursor: 'pointer',
    }
  }
});

const AddDomainStep2 = (props) => {
  const classes = useStyles();
  const { selected, setSelected } = props
  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="flex-start"
      spacing={5}
      className={classes.root}
    >
      <Grid item xs align="center">
        <Paper 
          elevation={0} 
          variant="outlined" 
          className={`${selected === 'auto' ? classes.itemSelect : classes.item}`} 
          onClick={() => setSelected('auto')}
        >
          <Typography className={classes.subHeading}>One Click Verification</Typography>
        </Paper>
        {selected === 'auto' &&
          <Typography className={classes.itemDesc}>
            Login to your DNS provider, and authenticate your TruDoc account to use your DNS provider. TruDoc will add the DNS record to verify your domain.
          </Typography>
        }
      </Grid>
      <Grid item xs align="center">
        <Paper 
          elevation={0} 
          variant="outlined" 
          className={`${selected === 'manual' ? classes.itemSelect : classes.item}`} 
          onClick={() => setSelected('manual')}
        >
          <Typography className={classes.subHeading}>Manual Verification</Typography>
        </Paper>
        {selected === 'manual' &&
          <Typography className={classes.itemDesc}>
            In manual verification, you can verify using the TXT Record Method - Add a specific TXT Record, in the given format in the domain's DNS Manager.
          </Typography>
        }
      </Grid>
    </Grid>
  )
}

export default AddDomainStep2;
