import { endpoint } from "./services.constant";
import apiCall from "./api";
import { getFormData } from "./utils";

export const list = (payload) => {
  return apiCall(endpoint.chat.LIST, 'POST', payload);
}

export const getMessages = (payload) => {
  return apiCall(endpoint.chat.MESSAGES, 'POST', payload);
}

export const chatDetails = (payload) => {
  return apiCall(endpoint.chat.CHAT_DETAILS, 'POST', payload);
}

export const acceptChat = (payload) => {
  return apiCall(endpoint.chat.ACCEPT_CHAT, 'POST', payload);
}

export const newChat = (payload) => {
  return apiCall(endpoint.chat.NEW_CHAT, 'POST', payload);
}

export const newGroup = (payload) => {
  return apiCall(endpoint.chat.NEW_GROUP, 'POST', payload);
}

export const addGroupMember = (payload) => {
  return apiCall(endpoint.chat.ADD_GROUP_MEMBER, 'POST', payload);
}

export const removeGroupMember = (payload) => {
  return apiCall(endpoint.chat.REMOVE_GROUP_MEMBER, 'POST', payload);
}

export const sendAttachmentMessage = (payload) => {
  const formData = getFormData(payload);
  return apiCall(endpoint.chat.NEW_MEDIA_MESSAGE, "POST", null, formData, "multipart/form-data");
}

export const deleteChat = (payload) => {
  return apiCall(endpoint.chat.DELETE_CHAT, "POST", payload);
}