import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepConnector from "@material-ui/core/StepConnector";
import Check from "@material-ui/icons/Check";

const QontoConnector = withStyles((theme) => ({
  alternativeLabel: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  active: {
    "& $line": {
      borderColor: "#222",
    },
  },
  completed: {
    "& $line": {
      borderColor: "#222",
    },
  },
  line: {
    borderColor: "#eaeaf0",
    borderTopWidth: 2,
    borderRadius: 1,
  },
}))(StepConnector);

const useQontoStepIconStyles = makeStyles((theme) => ({
  root: {
    color: "#eaeaf0",
    display: "flex",
    height: 22,
    alignItems: "center",
  },
  active: {
    color: theme.palette.primary.main,
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
  completed: {
    color: theme.palette.primary.main,
    zIndex: 1,
    fontSize: 18,
  },
  error: {
    color: "red"
  }
}));

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed, error } = props;
  console.log("props", props)
  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? (
        <Check className={classes.completed} />
      ) : error ? (
        <div className={`${classes.circle} ${classes.error}`} />
      ) :(
        <div className={classes.circle} />
      )}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const SigningProgress = ({ documentSignData }) => {
  
  const classes = useStyles();
  let activeStep = 0;
  documentSignData = documentSignData.sort((ele) => ele.status === "SIGNED" ? -1 : 1);
  let index = documentSignData.findIndex((ele) => ele.status !== "SIGNED");
  if(index !== -1) activeStep = index;
  if(documentSignData[documentSignData.length - 1].status === "SIGNED") activeStep = documentSignData.length; // When all users have signed the doc
  
  return (
    <div className={classes.root}>
      <Stepper
        activeStep={activeStep}
        alternativeLabel
        connector={<QontoConnector />}
      >
        {documentSignData &&
          documentSignData.length > 0 &&
          documentSignData.map((item) => {
            return (
              <Step key={item.id}>
                <StepLabel align="center" StepIconComponent={QontoStepIcon} error={item.status === "REJECTED"}>
                  {item?.user?.email}
                </StepLabel>
              </Step>
            );
          })}
      </Stepper>
    </div>
  );
};

export default SigningProgress;
