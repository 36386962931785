import React from 'react'
import { Grid, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => {
    return {
        root: {
            padding: 20
        }
    }
})

const AddDomainStep3 = (props) => {
    const classes = useStyles();
    const { dnsRecordsQueue } = props
    return (
        <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={5}
        >
            <Grid
                container
                direction="row"
                alignItems="center"
                className={classes.root}
                spacing={5}
            >
                <Grid item xs={12} align="center">
                    <p>Your domain is set in Queue, You will be updated via email for further Steps; {dnsRecordsQueue}</p>
                    {/* <table sx={{ minWidth: 650 }} aria-label="simple table">
                        <thead>
                            <tr>
                                <th>Record Type</th>
                                <th>Value</th>
                            </tr>
                        </thead>
                        <tbody>
                            {dnsRecords.map((row, index) => (
                                <tr
                                    key={index}
                                >
                                    <td>{row.type}</td>
                                    <td> {row.value}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table> */}
                </Grid>
            </Grid>
        </Grid>
    )
}

export default AddDomainStep3;
