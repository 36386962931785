import logo from "../../static/images/logo.png";
import Dragdrop from "../../static/svg/Dragdrop.svg";
import drive from "../../static/svg/drive.svg";
import cloud from "../../static/svg/cloud.svg";
import upload from "../../static/svg/upload.svg";
import qrscan from "../../static/svg/qrscan.svg";
import { getData } from "@govtechsg/open-attestation";
import tick from "../../static/svg/tick.svg";
import download from "../../static/images/download.png";
import CloseIcon from "@material-ui/icons/Close";
import EndrosmentChain from "../../components/EndrosmentChain";
import { useParams } from "react-router-dom";

import { useDispatch } from "react-redux";
import { verifyDoc, getEndrosmentChain, uploadTt, verifyDocByDocID, getPickerFileData } from "../../services/api.service";
import AttachmentLink from "../../components/AttachmentLink";
import DecentralisedRendererContainer from "../../components/DecentralisedTemplateRenderer/DecentralisedRenderer";
import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import "./styles.css";
import { resetCertificateState } from "../../redux/reducers/certificate";

import { Alert } from "@material-ui/lab";

import {
  Box,
  makeStyles,
  withStyles,
  Typography,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  CircularProgress,
  Divider,
  Button,
  TableCell,
  Snackbar,
  SvgIcon,
} from "@material-ui/core";
import Dropzone from "react-dropzone";
import QrReader from "react-qr-reader";
import { useAuth } from "../../hooks";
import { utils } from "@govtechsg/open-attestation";

import DropBoxChooser from "../../components/DropBoxChooser";
import GoogleDriveChooser from "../../components/GoogleDriveChooser";
import { getFileData } from "../../utils/picker";
import DmsChooser from "../../components/DmsChooser";

const useStyles = makeStyles((theme) => {
  return {
    root: {
      minHeight: "100vh",
      backgroundColor: "transparent",
    },
    logo: {
      marginTop: theme.spacing(5),
      height: "50px",
      borderRadius: "0px",
      marginLeft: theme.spacing(8),
    },
    number: {
      height: "30px",
      width: "30px",
      borderRadius: "15px",
      backgroundColor: "#66CFA4",
      marginRight: theme.spacing(1),
    },
    num: {
      color: "#ffffff",
      fontSize: "18px",
      fontWeight: 600,
      fontFamily: "Poppins",
      lineHeight: "27px",
    },
    verify: {
      color: "#262727",
      fontSize: 30,
      fontWeight: 600,
      fontFamily: "Poppins",
      lineHeight: "27px",
      textAlign: "center",
    },
    text2: {
      color: "#7E8D90",
      fontSize: "16px",
      fontWeight: 500,
      fontFamily: "Poppins",
      height: "48px",
      lineHeight: "27px",
      marginLeft: "119px",
    },
    text1: {
      color: "#7E8D90",
      fontSize: "16px",
      fontWeight: 500,
      height: "48px",
      fontFamily: "Poppins",
      lineHeight: "27px",
    },
    text2: {
      color: "#7E8D90",
      fontSize: "20px",
      fontWeight: 500,
      fontFamily: "Poppins",
      lineHeight: "27px",
    },
    heading: {
      fontWeight: 400,
      fontSize: "32px",
    },
    innerContainer: {
      marginTop: theme.spacing(7),
      marginBottom: theme.spacing(1),
      minHeight: "40px",
    },
    divider: {
      borderColor: "#E5E5E5",
      height: "477px",
      width: "1px",
      borderWidth: "1px",
    },
    Container: {
      width: "967px",
      height: "477px",
      borderRadius: "10px",
      borderWidth: "1px",
      alignItems: "center",
      borderColor: "#7E8D90",
      marginTop: theme.spacing(3),
    },
    fileNameContainer: {
      height: "40px",
      borderRadius: "10px",
      borderWidth: "1px",
      alignItems: "center",
      borderColor: "#7E8D90",
      marginLeft: theme.spacing(2),
    },
    buttonLeft: {
      width: "214px",
      height: "48px",
      borderRadius: "20px",
      marginTop: "21px",
      backgroundColor: "#66CFA4",
    },
    buttonRight: {
      width: "214px",
      height: "48px",
      marginTop: "21px",
      marginLeft: "135px",
      borderRadius: "20px",
      backgroundColor: "#66CFA4",
    },
    dragDrop: {
      width: "117px",
      height: "181px",
    },
    dragDropRight: {
      width: "117px",
      height: "181px",
      marginTop: "-56px",
      marginLeft: "177px",
    },
    qrscan: {
      width: "400px",
      height: "400px",
      borderRadius: "10px",
      borderWidth: "1px",
      alignItems: "center",
      borderColor: "#7E8D90",
      marginTop: theme.spacing(3),
    },
    uploadIcon: {
      width: "40px",
      height: "35px",
      marginRight: "34px",
    },
    uploadContainer: {
      marginLeft: "85px",
      marginTop: "27.15px",
    },
    buttonText: {
      color: "#ffffff",
      fontFamily: "Poppins",
      fontWeight: 600,
      paddingTop: "12px",
      fontSize: "16px",
      textAlign: "center",
      lineHeight: "24px",
    },
    fileName: {
      color: "#7E8D90",
      fontFamily: "Poppins",
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "24px",
      paddingRight: "10px",
      paddingLeft: "18px",
    },
    verificationLabel: {
      color: "#66CFA4",
      fontFamily: "Poppins",
      fontWeight: 600,
      fontSize: "30px",
      lineHeight: "45px",
      textAlign: "center",
    },
    surrenderLabel: {
      color: "#f03737",
      border: "2px solid #f03737",
      borderRadius: "5px",
      padding: "5px 12px",
      fontFamily: "Poppins",
      fontWeight: 600,
      fontSize: "15px",
      textAlign: "center",
    },
    subheading: {
      fontSize: "20px",
      fontWeight: 500,
      fontFamily: "Poppins",
      lineHeight: "17.9px",
      color: "#262727",
      marginRight: "10px",
    },
    sideHeading: {
      fontSize: "20px",
      fontWeight: 500,
      fontFamily: "Poppins",
      lineHeight: "17.9px",
      color: "#66CFA4",
    },
    rootList: {
      alignItems: "center",
      justifyContent: "center",
      display: "flex",
    },
    labelFLex: {
      alignItems: "center",
      marginTop: "40px",
      height: "40px",
      justifyContent: "space-around",
      display: "flex",
    },
    tick: {
      width: "33.33px",
      height: "33.33px",
    },
    listHeading: {
      fontSize: "17px",
      fontWeight: 500,
      fontFamily: "Poppins",
      lineHeight: "23px",
      color: "#262727",
    },
    listHeadingColor: {
      fontSize: "17px",
      fontWeight: 500,
      fontFamily: "Poppins",
      lineHeight: "15.21px",
      color: "#66CFA4",
      marginLeft: "5px",
    },
    listHeadingColorMain: {
      fontSize: "17px",
      fontWeight: 500,
      paddingTop: "2px",
      fontFamily: "Poppins",
      lineHeight: "15.21px",
      color: "#66CFA4",
    },
    listHeadingColor: {
      fontSize: "17px",
      fontWeight: 500,
      fontFamily: "Poppins",
      lineHeight: "15.21px",
      color: "#66CFA4",
      marginLeft: "5px",
    },
    listHeadingColorMain: {
      fontSize: "17px",
      fontWeight: 500,
      paddingTop: "2px",
      fontFamily: "Poppins",
      lineHeight: "15.21px",
      color: "#66CFA4",
    },
    notvalid: {
      fontSize: "20px",
      fontWeight: 500,
      fontFamily: "Poppins",
      lineHeight: "15.21px",
      color: "#262727",
      textAlign: "center",
    },
    button: {
      width: "306px",
      height: "48px",
      backgroundColor: "#66CFA4",
      borderRadius: "20px",
      alignItems: "center",
      marginBottom: "50px",
      justifyContent: "center",
    },
    buttonModal: {
      width: "183px",
      height: "36px",
      backgroundColor: "#66CFA4",
      borderRadius: "20px",
      alignSelf: "center",
      marginTop: "50px",
      alignItems: "center",
      justifyContent: "center",
    },
    buttonModal: {
      width: "183px",
      height: "36px",
      backgroundColor: "#66CFA4",
      borderRadius: "20px",
      alignSelf: "center",
      marginTop: "50px",
      alignItems: "center",
      justifyContent: "center",
    },
    anotherDoc: {
      fontSize: "16px",
      fontWeight: 600,
      fontFamily: "Poppins",
      lineHeight: "24px",
      color: "#ffffff",
      paddingTop: "12px",
      textAlign: "center",
    },
    transferDoc: {
      fontSize: "12px",
      fontWeight: 600,
      fontFamily: "Poppins",
      lineHeight: "18px",
      color: "#ffffff",
      textAlign: "center",
    },
    transferDoc: {
      fontSize: "12px",
      fontWeight: 600,
      fontFamily: "Poppins",
      lineHeight: "18px",
      color: "#ffffff",
      textAlign: "center",
    },
    verifyText: {
      fontSize: "18px",
      fontWeight: 600,
      textDecoration: "none",
      fontFamily: "Poppins",
      lineHeight: "27px",
      color: "#66CFA4",
    },
    loginText: {
      fontSize: "18px",
      textDecoration: "none",
      fontWeight: 600,
      fontFamily: "Poppins",
      lineHeight: "27px",
      color: "#262727",
    },
    download: {
      width: "20px",
      height: "20px",
      marginLeft: "10px",
    },
    labelName: {
      paddingRight: "10px",
      fontSize: "17px",
      fontWeight: 500,
      fontFamily: "Poppins",
      lineHeight: "15.21px",
      cursor: "pointer",
    },
    table: {
      minWidth: 700,
    },
    table: {
      minWidth: 700,
    },
    labelActive: {
      color: "#66CFA4",
      paddingRight: "10px",
      fontSize: "17px",
      fontWeight: 500,
      fontFamily: "Poppins",
      lineHeight: "15.21px",
      cursor: "pointer",
    },
    notThere: {
      color: "#000000",
      fontFamily: "Poppins",
      fontWeight: 600,
      fontSize: "30px",
      lineHeight: "45px",
      paddingTop: "150px",
      textAlign: "center",
    },
    endrosmentStyle: {
      color: "#7E8D90",
      fontFamily: "Poppins",
      fontWeight: 600,
      fontSize: "12px",
      lineHeight: "18px",
      textDecorationLine: "underline",
      marginLeft: "5px",
      cursor: "pointer",
    },
    benef: {
      color: "#74BCFF",
      fontFamily: "Poppins",
      fontWeight: 500,
      fontSize: "11px",
      lineHeight: "16.5px",
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      backgroundColor: "#ffffff",
      borderRadius: "8px",
      outline: "none",
      padding: theme.spacing(2, 4, 3),
    },
    innerModal: {
      display: "flex",
      justifyContent: "space-around",
    },
    endrosmentChain: {
      textAlign: "center",
      color: "#424343",
      fontFamily: "Poppins",
      fontWeight: 600,
      fontSize: "20px",
      lineHeight: "30px",
      marginTop: "20px",
      marginBottom: "20px",
    },
    circle: {
      width: "13px",
      height: "13px",
      borderRadius: "6.5px",
      backgroundColor: "#66CFA4",
    },
    circle2: {
      width: "13px",
      height: "13px",
      marginRight: "-20px",
      borderRadius: "6.5px",
      backgroundColor: "#66CFA4",
    },
    controls: {
      margin: theme.spacing(1),
    }
  };
});

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#424343",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: "11px",
    borderRight: "2px dashed #000",
    borderBottom: "none",
    alignItems: "center",
    justifyContent: "center",
  },
}))(TableCell);
const StyledTableCellHolder = withStyles((theme) => ({
  head: {
    backgroundColor: "#424343",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: "11px",
    borderBottom: "none",
    alignItems: "center",
    justifyContent: "center",
  },
}))(TableCell);
const StyledTableCellHead = withStyles((theme) => ({
  head: {
    backgroundColor: "#424343",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: "11px",
    borderBottom: "none",
    alignItems: "center",
    justifyContent: "center",
  },
}))(TableCell);

export default function Verify() {
  const { user } = useAuth();
  const { documentId } = useParams();
  const [fileNames, setFileNames] = useState([]);
  const [documentFile, setDocumentFile] = useState(undefined);
  const [qrRead, setqrRead] = useState(false);
  const [result, setResult] = useState([]);
  const [valid, setValid] = useState(true);
  const [exist, setExist] = useState(true);
  const [loading, setLoading] = useState(false);
  const [verifyResponse, setVerifyResponse] = useState([]);
  const [verificationScreen, setVerificationScreen] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState("");
  const [open, setOpen] = React.useState(false);
  const [visible, setVisible] = useState(false);
  const [verfieidDoc, setVerifiedDoc] = useState(false);
  const [tokenId, setTokenId] = useState("");
  const [tokenRegistryAddress, setTokenRegistryAddress] = useState("");
  const [endrosmentChainData, setEndrosmentChain] = useState([]);
  const [endrosloader, setEndrosLoader] = useState(true);
  const [openNotification, setOpenNotification] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("success");

  const [verifyDocRes, setVerifyDocRes] = useState({});

  const handleOpen = () => {
    if (endrosloader === true) {
      return (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          style={{ height: "50%" }}
        >
          <CircularProgress size={30} color="inherit" />
        </Box>
      );
    } else {
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };
  const dispatch = useDispatch();
  const resetCertificateData = useCallback(
    () => dispatch(resetCertificateState()),
    [dispatch]
  );
  const [doc, setDoc] = useState([]);
  useEffect(() => {
    return () => {
      resetCertificateData();
    };
  }, [resetCertificateData]);

  const originalData = getData(doc);
  const attachments = originalData?.attachments;
  const hasAttachments = attachments ? attachments.length > 0 : false;
  const childRef = React.useRef();
  const updateTemplates = useCallback((templateList) => {
    // filter all templates that are renderable currently
    let templatesModified = templateList.filter((item) => {
      return (
        (item.type === "custom-template" && item.label !== "Default") ||
        item.type === "application/pdf" ||
        !item.type
      ); // !item.type caters to renderers that still has decentralized-renderer-react-components dependency at <2.3.0, where type does not exists
    });

    templatesModified = templateList.filter((item) => {
      switch (item.id) {
        case 'covering-letter-template':
          item.label = "Cover";
          break;
        case 'attachment-0':
          item.label = "Preview";
          break;
        default:
          break;
      }
      return item;
    });

    // set modified templates
    setTemplates(templatesModified);
    setSelectedTemplate(templatesModified[0].id);
  }, []);

  useEffect(() => {
    console.log("executed only once!*************", documentId);
    if (documentId) {
      verifyDocBy_DocID(documentId);
    }
  }, [""]);

  const addToAccount = () => {
    // Get all the required attributes
    const payload = {
      tokenId: tokenId,
      tokenRegistryAddress: tokenRegistryAddress,
      file: documentFile
    };
    console.log(documentFile);
    uploadTt(payload).then((res) => {
      if (res.data.status === 401) {
        setAlertType("warning")
      } else if (res.data.status === 500) {
        setAlertType("error")
      }
      setAlertMessage(res.data.message);
      setOpenNotification(true);
      console.log("Successfully Added to your Account")
    }).catch((err) => {
      setAlertMessage(err.message)
      setAlertType("error")
      setOpenNotification(true)
    })


  }
  const onPrint = () => {
    if (childRef.current) {
      childRef.current.print();
    }
  };
  const handleScan = (data) => {
    fetch(data)
      .then((response) => response.json())
      .then((jsonData) => {
        setLoading(true);
        handleDataValidation(jsonData);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const handleError = (err) => {
    console.error("QR code error ", err);
  };
  const handleDrop = (acceptedFiles) => {
    // console.log(acceptedFiles);
    setDocumentFile(acceptedFiles[0]);
    setFileNames(acceptedFiles.map((file) => file.name));
    const reader = new FileReader();
    if (reader.error) {
      console.log("Readfile error", reader.error);
    }
    reader.onload = () => {
      try {
        // console.log(reader.result);
        const json = JSON.parse(reader.result);
        setLoading(true);
        handleDataValidation(json);
      } catch (e) {
        setValid(false);
        setDocumentFile(null);
        setFileNames([]);
        console.log("Error catched", e);
      }
    };
    if (acceptedFiles && acceptedFiles.length && acceptedFiles.length > 0)
      acceptedFiles.map((f) => reader.readAsText(f));
  };

  const verifyDocBy_DocID = async (documentId) => {
    try {
      setLoading(true);
      setFileNames([`verifying...`]);

      setEndrosLoader(true);
      setEndrosmentChain([]);
      setVisible(false);
      setVerifiedDoc(false);
      const response = await verifyDocByDocID(documentId);
      if (response?.data?.data?.verified?.valid) {
        const document = response?.data?.data?.ttfile;
        // setDocumentFile(acceptedFiles[0])
        setFileNames([response?.data?.data?.document?.name]);
        setDoc(document);
        try {
          EndrosmentChainAPI(document);
        } catch (error) {
          console.log("EndrosmentChainAPI");
          console.log(error);
        }
        setVerifyResponse({ data: response?.data?.data?.verified });
        setExist(true);
        setLoading(false);
        setVerificationScreen(true);
        setValid(true);
        setVerifiedDoc(true);
      } else {
        setDoc([]);
        setLoading(false);
        setVerifyResponse([]);
        setExist(false);
        setVerificationScreen(true);
      }
    } catch (e) {
      setLoading(true);
      setDoc([]);
      setVerifyResponse([]);
      setValid(false);
      setVerificationScreen(false);
      console.log("Error catched", e);
    }
  };

  const handleDataValidation = async (document) => {
    setEndrosLoader(true);
    setEndrosmentChain([]);
    setVisible(false);
    setVerifiedDoc(false);
    await verifyDoc(document)
      .then(function (response) {
        setVerifyDocRes(response?.data);
        if (response.data.valid === true) {
          setDoc(document);
          try {
            EndrosmentChainAPI(document);
          } catch (error) {
            console.log("EndrosmentChainAPI");
            console.log(error);
          }
          setVerifyResponse(response);
          setExist(true);
          setLoading(false);
          setVerificationScreen(true);
          setValid(true);
          setVerifiedDoc(true);
        } else {
          setDoc([]);
          setLoading(false);
          setVerifyResponse([]);
          setExist(false);
          setVerificationScreen(true);
        }
      })
      .catch(function (error) {
        console.log(error);
        setLoading(true);
        setDoc([]);
        setVerifyResponse([]);
        setValid(false);
        setVerificationScreen(false);
      });
  };

  const EndrosmentChainAPI = (document) => {
    setTokenId(`0x${utils.getAssetId(document)}`);
    setTokenRegistryAddress(
      utils.isTransferableAsset(document) === true
        ? utils.getIssuerAddress(document)[0]
        : "");
    setVisible(utils.isTransferableAsset(document));

    getEndrosmentChain({
      tokenRegistryAddress: utils.isTransferableAsset(document) === true
        ? utils.getIssuerAddress(document)[0]
        : "",
      tokenId: `0x${utils.getAssetId(document)}`,
    })
      .then((res) => {
        console.log("Response", res.data.endorsementChain);
        setEndrosLoader(false);
        setEndrosmentChain(res.data.endorsementChain);
      })
      .catch((err) => {
        console.log("Error I get--->", err.message);
      });
  };
  const handleNotificationClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenNotification(false);
  };

  const chooserFileSelect = async (data) => {
    if (data != null) {
      setLoading(true);
      let fileData = await getFileData(data);
      if (fileData != null) {
        // console.log("fileData", fileData);
        handleDrop([fileData]);
      }
    }
  }

  const classes = useStyles();
  return verificationScreen ? (
    <div
      className="App"
      style={{
        minHeight: "100vh",
        width: `${user && user.token ? `calc(100% - 360px)` : "100%"}`,
        marginLeft: `${user && user.token ? "340px" : "0px"}`,
      }}
    >
      <Snackbar
        open={openNotification}
        autoHideDuration={6000}
        onClose={handleNotificationClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Alert onClose={handleNotificationClose} severity={alertType}>
          {alertMessage}
        </Alert>
      </Snackbar>
      {!(user && user.token) && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginRight: "137px",
          }}
        >
          <img src={logo} alt="TruDoc" className={classes.logo} />
          <Box display="flex" alignItems="center" justifyContent="center">
            <Typography className={classes.verifyText}>VERIFY</Typography>
            {/* <Divider
              orientation="vertical"
              style={{
                marginLeft: "40px",
                marginRight: "40px",
                height: "43px",
              }}
            ></Divider>
            <Typography
              onClick={() => setqrRead(false)}
              component={Link}
              to="/"
              className={classes.loginText}
            >
              Login
            </Typography> */}
          </Box>
        </div>
      )}
      {exist && (
        <div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography className={classes.verificationLabel}>
              Document verification complete!
            </Typography>
            <img src={download} className={classes.download} />
          </div>

          {
            verifyDocRes?.isSurrendered ? <>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography className={classes.surrenderLabel}>
                  Surrendered To Issuer
                </Typography>
              </div>
            </> : null
          }

          {open && (
            <EndrosmentChain
              handleCloseModal={handleClose}
              openModal={open}
              endorsementChain={endrosmentChainData}
              verifyResponse={verifyResponse}
              tokenId={tokenId}
              tokenRegistryAddress={tokenRegistryAddress}
            ></EndrosmentChain>
          )}
          {visible && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              {/* <Typography className={classes.subheading} noWrap>
              Created by
            </Typography>
            <Typography className={classes.sideHeading} noWrap>
              TRUDOC.TK
            </Typography> */}
              {/* Adding A new api call to update the uploaded document */}
              {true && (
                <Button
                  className={classes.controls}
                  variant="contained"
                  color="primary"
                  onClick={handleOpen}
                  disableElevation
                >
                  VIEW ENDORSEMENT CHAIN
                </Button>
              )}
              {(user && user.token) && (
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={addToAccount}
                >
                  ADD TO MY ACCOUNT
                </Button>
              )}
            </div>
          )}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {verifyResponse && (
              <List
                style={{
                  display: "flex",
                }}
              >
                {[0, 1, 2].map((value) => {
                  return (
                    <ListItem key={value}>
                      <ListItemAvatar>
                        <Avatar src={tick} className={classes.tick} />
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          value === 2 ? (
                            <Typography className={classes.listHeading}>
                              Document has not been tampered with
                            </Typography>
                          ) : value === 1 ? (
                            <Typography className={classes.listHeading}>
                              Document verified on blockchain
                            </Typography>
                          ) : (
                            verifyResponse.data.data.map((res) => {
                              return res.data && res.data[0] ? (
                                <div
                                  style={{
                                    display: "flex",
                                  }}
                                >
                                  <Typography className={classes.listHeading}>
                                    Document has been issued by
                                    <Typography
                                      className={classes.listHeadingColorMain}
                                    >
                                      {res.data[0].location}
                                    </Typography>
                                  </Typography>
                                </div>
                              ) : null;
                            })
                          )
                        }
                      />
                    </ListItem>
                  );
                })}
              </List>
            )}
          </div>
        </div>
      )}
      {exist ? (
        <div>
          <div className={classes.labelFLex}>
            {templates.map(({ id, label, type }) => (
              <div key={id}>
                <div
                  onClick={() => {
                    setSelectedTemplate(id);
                  }}
                >
                  <Typography
                    className={
                      id === selectedTemplate
                        ? classes.labelActive
                        : classes.labelName
                    }
                  >
                    {label}
                  </Typography>
                </div>
              </div>
            ))}
            {hasAttachments && (
              <div>
                <div
                  className={classes.rootList}
                  onClick={() => {
                    setSelectedTemplate("attachmentTab");
                  }}
                >
                  <div
                    className={
                      selectedTemplate === "attachmentTab"
                        ? classes.labelActive
                        : classes.labelName
                    }
                  >
                    Attachment
                  </div>
                  {/* <div  
                    className={
                      selectedTemplate === "attachmentTab"
                        ? classes.labelActive
                        : classes.labelName
                    }
                    data-testid="attachment-number"
                  >
                    {attachments && attachments.length}
                  </div> */}
                </div>
              </div>
            )}
          </div>
          {attachments && selectedTemplate === "attachmentTab" && (
            <div>
              {attachments.map(({ filename, data, type }, index) => (
                <div key={index} style={{ minHeight: "30vh" }}>
                  <AttachmentLink filename={filename} data={data} type={type} />
                </div>
              ))}
            </div>
          )}
          {selectedTemplate !== "attachmentTab" && (
            <div>
              <DecentralisedRendererContainer
                rawDocument={doc}
                updateTemplates={updateTemplates}
                selectedTemplate={selectedTemplate}
                ref={childRef}
              />
            </div>
          )}
        </div>
      ) : (
        <div
          style={{
            minHeight: "50vh",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography className={classes.notThere}>
            Your document doesn't exist in blockchain!!!
          </Typography>
        </div>
      )}


      {verfieidDoc === true && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "89px",
          }}
        >
          <Box
            className={classes.button}
            justifyContent="center"
            alignItems="center"
            onClick={() => {
              setVerificationScreen(false);
              setFileNames([]);
              setqrRead(false);
            }}
          >
            <Typography className={classes.anotherDoc}>
              VERIFY ANOTHER DOCUMENT
            </Typography>
          </Box>
        </div>
      )}


    </div>
  ) : (
    <div
      className="App"
      style={{
        height: `${user && user.token ? "70vh" : "150vh"}`,
        width: `${user && user.token ? `calc(100% - 360px)` : "100%"}`,
        marginLeft: `${user && user.token ? "340px" : "0px"}`,
      }}
    >
      {!(user && user.token) && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginRight: "137px",
          }}
        >
          <img src={logo} alt="TruDoc" className={classes.logo} />
          <Box display="flex" alignItems="center" justifyContent="center">
            <Typography className={classes.verifyText}>VERIFY</Typography>
            {/* <Divider
              orientation="vertical"
              style={{
                marginLeft: "40px",
                marginRight: "40px",
                height: "43px",
              }}
            ></Divider>
            <Typography component={Link} to="/" className={classes.loginText}>
              Login
            </Typography> */}
          </Box>
        </div>
      )}
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        className={classes.innerContainer}
        style={{
          padding: `${user && user.token ? "40px" : "0px"}`,
        }}
      >
        {fileNames.length > 0 ? (
          <Typography className={classes.verify}>Document uploaded</Typography>
        ) : (
          <Typography className={classes.verify}>Verify Document</Typography>
        )}
        {fileNames.length > 0 && (
          <Box
            border={1}
            display="flex"
            justifyContent="flex-start"
            className={classes.fileNameContainer}
          >
            <Typography className={classes.fileName}>{fileNames}</Typography>
            <IconButton
              color="inherit"
              aria-label="close"
              edge="start"
              onClick={() => {
                setValid(true);
                setFileNames([]);
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        )}
      </Box>
      {fileNames.length > 0 && loading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          style={{ height: "50%" }}
        >
          <CircularProgress size={30} color="inherit" />
        </Box>
      ) : (
        <div>
          <Dropzone acceptedFiles=".json,.tt" onDrop={handleDrop}>
            {({ getRootProps, getInputProps, isDragAccept, isDragReject }) => {
              const additionalClass = isDragAccept
                ? "accept"
                : isDragReject || !valid
                  ? "reject"
                  : "";

              return (
                <div
                  style={{ backgroundColor: "transparent" }}
                  {...getRootProps({
                    className: `dropzone ${additionalClass}`,
                  })}
                  onClick={(e) => e.stopPropagation()}
                >
                  <input {...getInputProps()} />
                  {valid ? (
                    <Box display="flex" justifyContent="center">
                      {!qrRead ? (
                        <Box
                          border={1}
                          display="flex"
                          justifyContent="center"
                          {...getRootProps({
                            className: [
                              classes.Container,
                              `${additionalClass}`,
                            ],
                          })}
                        >
                          <Box
                            flexDirection="row-reverse"
                            alignSelf="center"
                            justifyContent="center"
                            textAlign="center"
                            margin="0 80px"
                          >
                            <img
                              src={Dragdrop}
                              alt="TruDoc"
                              className={classes.dragDrop}
                            />
                            <Typography
                              align="center"
                              className={classes.text1}
                            >
                              Drop a document here
                            </Typography>
                            <Typography
                              align="center"
                              className={classes.text2}
                            >
                              OR
                            </Typography>
                            <Box
                              className={classes.buttonLeft}
                              justifyContent="center"
                              alignItems="center"
                            >
                              <Typography className={classes.buttonText}>
                                CHOOSE DOCUMENT
                              </Typography>
                            </Box>

                            <Box className="upl-icon-list file-choose-icons" mt={2}>

                              <DmsChooser onSelected={chooserFileSelect} />
                              <DropBoxChooser onSelected={chooserFileSelect} />
                              <GoogleDriveChooser onSelected={chooserFileSelect} />

                            </Box>
                          </Box>
                        </Box>
                      ) : (
                        <Box
                          {...getRootProps({
                            onClick: (e) => {
                              e.stopPropagation();
                            },
                          })}
                        >
                          <IconButton
                            color="inherit"
                            aria-label="close"
                            edge="start"
                            style={{ marginLeft: "-20px" }}
                            onClick={() => setqrRead(false)}
                          >
                            <CloseIcon />
                          </IconButton>
                          <QrReader
                            delay={300}
                            onError={handleError}
                            onScan={handleScan}
                            className={classes.qrscan}
                          />
                        </Box>
                      )}
                    </Box>
                  ) : (
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Box
                        border={1}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        {...getRootProps({
                          className: [classes.Container, `${additionalClass}`],
                        })}
                      >
                        <Typography className={classes.notvalid}>
                          Please check the document format
                        </Typography>
                      </Box>
                    </Box>
                  )}
                </div>
              );
            }}
          </Dropzone>
        </div>
      )}
    </div>
  );
}
