import { useState, useEffect } from "react";
import {
  Box,
  makeStyles,
  Avatar,
  Typography,
  Grid,
  withStyles,
  TextField,
  Button,
  Snackbar,
  IconButton,
  InputAdornment,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { useAuth } from "../hooks";
import { resetPassword } from "../services/auth.service";
import { getFirstChar } from "../utils/Generic";
import { getSignList, getSignData, deleteSignature } from "../services/api.service";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import Toast from "../utils/Toast";
import { MessageConstants } from "../utils/MessageConstants";
import { useHistory } from "react-router-dom";
import { Cancel } from "@material-ui/icons";
import DeleteModal from "./DeleteModal";
import { useDispatch, useSelector } from "react-redux";
import { ResetFetchSignature } from "../redux/actions/Sign.action";
import CreateSignature from "../components/CreateSignature";


const useStyles = makeStyles((theme) => ({
  root: {
    boxSizing: "border-box",
    width: `calc(100% - ${300}px)`,
    marginLeft: 280,
    marginTop: 40,
    backgroundColor: "#fff",
    minHeight: "100vh",
  },
  large: {
    width: theme.spacing(12),
    height: theme.spacing(12),
    fontSize: 50,
    fontWeight: 600,
    margin: "0 auto 30px auto",
    textTransform: "uppercase",
    backgroundColor: theme.palette.primary.main,
  },
  button: {
    marginBottom: "10px",
    width: 300,
    height: 50,
    borderRadius: 25,
  },
  buttonText: {
    fontWeight: "bold",
    fontSize: 16,
    letterSpacing: "0.1em",
    color: "#FFFDFD",
  },
  field: {
    width: "40vw",
    marginTop: 20,
    marginBottom: 20,
    borderRadius: "50px 50px 50px 50px",
    color: theme.palette.secondary.main,
  },
  textFieldInput: {
    color: "#222",
  },
  detailWrap: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginLeft: '20px',
  },
  showSign: {
    display: 'inline-block'
  },
  signDiv: {
    float: 'left'
  },
  closeIcon: {
    top: '-75px',
    left: '90px',
    padding: '0',
    position: 'relative',
  },
}));

const CssTextField = withStyles({
  root: {
    display: "block",
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#66CFA4",
      },
      "&:hover fieldset": {
        borderColor: "#66CFA4",
      },
      borderRadius: 50,
    },
  },
})(TextField);

const CreateESign = () => {
  const toastInitialValues = {
    isOpen: false,
    isSuccess: false,
    isError: false,
    message: ""
  }
  const passwordRegx = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;

  const history = useHistory()
  const { user, removeUser } = useAuth();
  const classes = useStyles();
  const dispatch = useDispatch();
  const fetchSignature = useSelector(state => state.fetchSignature);
  const [toast, setToast] = useState(toastInitialValues);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [show, setShow] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [signature, setSignature] = useState([]);
  const [selected, setSelected] = useState(-1);
  const [state, setState] = useState({
    newPassword: "",
    confirmNewPassword: "",
  });

  const getSignature = () => {
    getSignList()
      .then((res) => {
        setSignature(res.data);
        console.log("Response", res.data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }

  useEffect(() => {
    getSignature();
  }, []);

  useEffect(() => {
    if (fetchSignature) {
      getSignature();
      dispatch(ResetFetchSignature());
    }
  }, [fetchSignature]);

  const setData = (e) => {
    setState((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const enableSubmit = () => {
    return (
      (state.confirmNewPassword === state.newPassword &&
        state.confirmNewPassword?.length > 0 &&
        state.newPassword?.length > 0) && (state?.newPassword?.match(passwordRegx))
    );
  };

  const logout = () => {
    removeUser();
    history.replace("/login");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    resetPassword({
      username: user?.email,
      password: state?.newPassword,
    }).then((res) => {
      if (res && res.status === 200 && res?.data?.message) {
        setToast((prev) => ({
          ...prev,
          isOpen: true,
          isSuccess: true,
          message: MessageConstants.PASSWORD_UPDATE_SUCCESS
        }));
        setState((prev) => ({
          ...prev,
          newPassword: "",
          confirmNewPassword: "",
        }));
        setTimeout(() => {
          logout();
        }, 2000)
      }
    }).catch((e) => {
      const Error = JSON.parse(e.message);
      let msg = MessageConstants.PASSWORD_UPDATE_FAILED;
      if (Error.status === 400)
        msg = Error.message;
      setToast((prev) => ({
        ...prev,
        isOpen: true,
        isError: true,
        message: msg
      }));
    })
  };

  const closeToast = () => {
    return setToast((prev) => ({
      ...prev,
      isOpen: false,
      isSuccess: false,
      isError: false,
      message: ""
    }));
  };

  const deleteHandler = () => {
    deleteSignature(selected).then(res => {
      if (res && res.status === 200) {
        setToast((prev) => ({
          ...prev,
          isOpen: true,
          isSuccess: true,
          message: MessageConstants.SIGN_DELETE_SUCCESS
        }));
        const filterSign = signature.filter(e => e.id !== selected)
        setSignature(filterSign);
        setDeleteDialog(false);
        setSelected(-1);
      }
    }).catch(e => {
      setToast((prev) => ({
        ...prev,
        isOpen: true,
        isError: true,
        message: MessageConstants.SIGN_DELETE_FAILED
      }));
    })
  }

  const handleCloseThing = () => {
    
  }

  return (
    <>
      <Box className={classes.root}>
        {toast.isOpen && <Toast message={toast.message} isError={toast.isError} isSuccess={toast.isSuccess} closeToast={closeToast} />}
        <CreateSignature openValue={true} handleCloseModal={(e) => handleCloseThing()} />
      </Box>
    </>
  );
};

export default CreateESign;