import { useState, useEffect, forwardRef } from "react";
import {
  Slide,
  Grid,
  Typography,
  InputAdornment,
  withStyles,
  TextField,
  makeStyles,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@material-ui/core";
import {
  Cancel,
  VisibilityOffOutlined,
  VisibilityOutlined,
} from "@material-ui/icons";
import { useSelector } from "react-redux";
import LoaderButton from "../../../utils/Loader/LoaderButton";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="top" ref={ref} {...props} />;
});

const CssTextField = withStyles({
  root: {
    display: "block",
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#66CFA4",
      },
      "&:hover fieldset": {
        borderColor: "#66CFA4",
      },
      borderRadius: 50,
    },
  },
})(TextField);

const useStyles = makeStyles((theme) => {
  return {
    dialogRoot: {
      width: "700px",
      borderRadius: 50,
      margin: "0 auto",
    },
    contentHeight: {
      minHeight: "35px",
      maxWidth: "100%",
      margin: "0 auto",
    },
    title: {
      paddingTop: 20,
      textAlign: "center",
      fontWeight: "600",
      textTransform: "uppercase",
      letterSpacing: 0.5,
      wordSpacing: 2,
      paddingBottom: 10,
    },
    stepText: {
      fontWeight: "600",
      paddingBottom: 30,
      textAlign: "center",
      color: theme.palette.admin.main,
      letterSpacing: 1,
    },
    actionBar: {
      justifyContent: "center",
      marginBottom: 20,
    },
    nextButton: {
      width: 220,
      height: 50,
      borderRadius: 25,
      color: "#fff",
      letterSpacing: 1,
      backgroundColor: theme.palette.admin.main,
      "&:hover": {
        backgroundColor: theme.palette.admin.main,
      },
    },
    draftButton: {
      width: 220,
      height: 50,
      borderRadius: 25,
      letterSpacing: 1,
      backgroundColor: "#fff",
      color: "#6a6a6a",
      border: "1px solid gray",
      "&:hover": {
        backgroundColor: "#fff",
        color: "#363636",
        border: "1px solid #464646",
      },
    },
    closeIcon: {
      position: "absolute",
      right: "0",
      padding: "10px",
    },
    button: {
      width: 230,
      height: 50,
      borderRadius: 25,
      letterSpacing: 1,
      color: "#fff",
    },
    buttonText: {
      fontWeight: "bold",
      fontSize: 16,
      letterSpacing: "0.1em",
      color: "#FFFDFD",
    },
    field: {
      width: "32vw",
      marginTop: 20,
      marginBottom: 20,
      borderRadius: "50px 50px 50px 50px",
      color: theme.palette.secondary.main,
    },
    textFieldInput: {
      color: "#222",
    },
    detailWrap: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      marginLeft: "20px",
    },
  };
});

const ChangePasswordModal = ({ open, handleClose, handleSubmit }) => {
  const classes = useStyles();
  const isLoading = useSelector(state => state.buttonLoading);
  const [show, setShow] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [state, setState] = useState({
    newPassword: "",
    confirmNewPassword: "",
  });

  const enableSubmit = () => {
    return (
      state.confirmNewPassword === state.newPassword &&
      state.confirmNewPassword?.length > 0 &&
      state.newPassword?.length > 0
    );
  };

  const setData = (e) => {
    setState((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const submitHandler = () =>{
    handleSubmit(state);
  }

  useEffect(() => {
    if(!open){
      setState({
        newPassword: "",
        confirmNewPassword: "",
      })
    }
  }, [open])
  

  return (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      open={open}
      onClose={handleClose}
      className={classes.dialogRoot}
      keepMounted={false}
      TransitionComponent={Transition}
      PaperProps={{
        style: { borderRadius: 20 },
      }}
    >
      <div className={classes.closeIcon}>
        <IconButton onClick={handleClose}>
          <Cancel />
        </IconButton>
      </div>
      <DialogTitle gutterBottom>
        <Typography variant="h5" className={classes.title}>
          Reset Password
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.contentHeight}>
        <Grid item xs={8}>
          <form>
            <CssTextField
              type={show ? "text" : "password"}
              label="New Password"
              name="newPassword"
              value={state.newPassword || ""}
              className={classes.field}
              onChange={(e) => setData(e)}
              variant="outlined"
              color="primary"
              required
              fullWidth
              InputLabelProps={{ className: classes.textFieldInput }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShow(!show)}
                      edge="end"
                    >
                      {show ? (
                        <VisibilityOutlined />
                      ) : (
                        <VisibilityOffOutlined />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
                className: classes.textFieldInput,
              }}
            />
            <CssTextField
              type={showConfirm ? "text" : "password"}
              label="Confirm New Password"
              name="confirmNewPassword"
              value={state.confirmNewPassword || ""}
              className={classes.field}
              onChange={(e) => setData(e)}
              variant="outlined"
              color="primary"
              required
              fullWidth
              error={
                state.confirmNewPassword?.length > 0 &&
                state.newPassword !== state.confirmNewPassword
              }
              InputLabelProps={{ className: classes.textFieldInput }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowConfirm(!showConfirm)}
                      edge="end"
                    >
                      {showConfirm ? (
                        <VisibilityOutlined />
                      ) : (
                        <VisibilityOffOutlined />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
                className: classes.textFieldInput,
              }}
            />
          </form>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.actionBar} gutterBottom>
      <LoaderButton
          type="submit"
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={submitHandler}
          disabled={!enableSubmit()}
          isLoading={isLoading}
          text="Reset"
        />
        {/* <Button
          
        >
          <Typography className={classes.next}></Typography>
        </Button> */}
        <Button className={classes.draftButton} onClick={handleClose}>
          <Typography variant="h6">{"Cancel"}</Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChangePasswordModal;
