import { TOGGLE_MODAL } from '../redux.constants';

const initialState = {
  isOpen: false,
  data: {}
}

const toggleModal = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_MODAL:
      return action.payload;
    default:
      return state;
  }
}

export default toggleModal;