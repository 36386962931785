import { SELECTED_CONTACT, ALL_CONTACTS } from '../redux.constants';
import { getAllContacts } from '../../services/admin.service';

export const SelectContactAction = (payload) => {
  return dispatch => {
    dispatch({
      type: SELECTED_CONTACT,
      payload
    })
  }
};

export const GetContacts = () => {
  return dispatch => {
    getAllContacts().then(res => {
      if (res && res.status === 200) {
        dispatch({
          type: ALL_CONTACTS,
          payload: res.data
        })
      }
    })
  };
};
