import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Box, Typography, makeStyles, Snackbar } from "@material-ui/core";
import Certificate from "../components/awaiting/Certificate";
import PdfViewer from "../components/awaiting/PdfViewer";
import EDocumentPdfViewer from "../components/awaiting/eDocumentPdfViewer";
import FilterBar from "../components/awaiting/FilterBar";
import { getDocuments } from "../services/other.service";
import { sidePanelFilterMapping, noDocumentMessage } from "../constants";
import { useAuth } from "../hooks";
import SidePanelAction from "../redux/actions/Sidepanel.action";
import ErrorIcon from "@material-ui/icons/Error";
import {
  Grid,
  FormControlLabel,
  Switch,
  withStyles
} from "@material-ui/core";
import PrepareDocument from "../utils/WebViewer/FillSignature";
import NewPdfViewer from "../components/awaiting/NewPdfViewer";
import { setSelectedDoc } from "../redux/actions/Viewer.action";


import { useSocketContext } from '../services/socket';
import { LinearProgress } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import Toast from "../utils/Toast";

const useStyles = makeStyles((theme) => {
  return {
    paper: {
      padding: theme.spacing(1),
      margin: theme.spacing(2),
    },
    content: {
      width: `calc(100% - ${300}px)`,
      marginLeft: 280,
      marginTop: 10,
      minHeight: "88vh",
      padding: theme.spacing(1),
    },
    emptyIcon: {
      fontSize: 60,
      paddingBottom: theme.spacing(1),
    },
  };
});
const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "#52d869",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#52d869",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 23,
    height: 23,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
    height: "95%",
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});
const Awaiting = () => {
  const toastInitialValues = {
    isOpen: false,
    isSuccess: false,
    isError: false,
    message: ""
  }

  const classes = useStyles();
  const dispatch = useDispatch();
  const { user } = useAuth();
  const { sidePanel } = useSelector((state) => state);
  let { documentType, filterType } = useParams();
  const certificate = sidePanel[filterType == 'my_wallet' ? 'issued' : filterType];
  const [openPdfViewer, setOpenPdfViewer] = useState(false);
  const [openEDocumentPdfViewer, setOpenEDocumentPdfViewer] = useState(false);
  const [pdfName, setPdfName] = useState();
  const [certData, setCertData] = useState();
  const [edocs, setEdocs] = useState(false);

  const [expanded, setExpanded] = useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const socket = useSocketContext();

  const [progress, setProgress] = useState(false);
  const [toast, setToast] = useState(toastInitialValues);

  const [transactionDocId, setTransactionDocId] = useState(null);

  const closeToast = () => {
    return setToast((prev) => ({
      ...prev,
      isOpen: false,
      isSuccess: false,
      isError: false,
      message: ""
    }));
  };

  const socketProgressHandler = (data) => {
    setProgress(data);
  }

  const socketSuccessHandler = (data) => {
    setToast((prev) => ({
      ...prev,
      isOpen: true,
      isSuccess: true,
      message: data
    }));
  }

  const socketFailedHandler = (data) => {
    setToast((prev) => ({
      ...prev,
      isOpen: true,
      isError: true,
      message: data
    }));
  }

  useEffect(() => {
    socket.on("document_revoke_in_process", socketProgressHandler);
    socket.on("document_revoke_success", socketSuccessHandler);
    socket.on("document_revoke_failed", socketFailedHandler);
  }, [socket]);

  useEffect(() => {
    if (documentType == "tru-sign") {
      setEdocs(true);
    } else {
      setEdocs(false);
    }
  }, [documentType]);

  const loadDocuments = () => {
    if (filterType) {
      getDocuments({
        email: user.email,
        condition: sidePanelFilterMapping[filterType],
        type: 'transferable_record',
        edocuments: edocs
      }).then((res) => {
        console.log(certificate);
        if (res && res?.status === 200 && res?.data)
          dispatch(SidePanelAction([filterType], [...res?.data]));
      });
    }
  }

  useEffect(() => {
    loadDocuments();
  }, [filterType, edocs]);

  console.log("filterType:", filterType, "edoc:", edocs, "certData:", certData);

  const Alert = (props) => {
    return <MuiAlert elevation={6} variant="standard" {...props} />;
  };

  return (
    <>
      {toast.isOpen && <Toast message={toast.message} isError={toast.isError} isSuccess={toast.isSuccess} closeToast={closeToast} />}
      <Box className={classes.content}>

        {/* <Typography component="div">
          <Grid
            component="label"
            container
            alignItems="center"
            spacing={1}
            justify="flex-end"
          >
            <Grid item>
              <FormControlLabel
                control={
                  <IOSSwitch
                    checked={edocs}
                    name="checkedB"
                    onChange={() => setEdocs(!edocs)}
                  />
                }
                label="EDocuments"
              />
            </Grid>
          </Grid>
        </Typography> */}

        {certificate && certificate.length > 0 ? (
          <>
            <FilterBar />
            <Box display="flex" justifyContent="space-around" mb="20px">
              <Box width="25%">
                <Typography>Doc Name</Typography>
              </Box>
              <Box width="15%">
                <Typography>Created On</Typography>
              </Box>
              <Box width="10%">
                <Typography>Creator</Typography>
              </Box>
              {!edocs ? <Box width="20%">
                <Typography>Domain</Typography>
              </Box> : ""}

              <Box width="20%" align="center">
                <Typography>Action</Typography>
              </Box>
            </Box>
            {certificate &&
              certificate.map((cert) => {
                return (
                  <Certificate
                    key={cert.id}
                    expanded={expanded}
                    handleChange={handleChange}
                    data={cert}
                    filterType={filterType}
                    handleViewPdf={() => {
                      console.log("cert", cert)
                      setOpenPdfViewer(true);
                      setPdfName(cert.name);
                      setCertData(cert);
                      dispatch(setSelectedDoc(cert.id))
                    }}
                    edocs={edocs}
                  />
                );
              })}
          </>
        ) : (
          <Box align="center" padding={10}>
            <ErrorIcon className={classes.emptyIcon} color="primary" />
            <Typography variant="h6">{noDocumentMessage[filterType]}</Typography>
          </Box>
        )}
        {openPdfViewer && edocs && filterType == "awaiting_my_sign" ?
          <NewPdfViewer
            data={certData}
            open={openPdfViewer}
            handleClose={() => setOpenPdfViewer(false)}
            pdfName={pdfName}
            edocs={edocs}
            isReadOnly={false}
          ></NewPdfViewer>
          : openPdfViewer ? <PdfViewer
            data={certData}
            open={openPdfViewer}
            handleClose={() => setOpenPdfViewer(false)}
            pdfName={pdfName}
            edocs={edocs}
            isReadOnly={true}
          ></PdfViewer> : ""}
      </Box>


      {progress && <Snackbar
        open={progress}
        autoHideDuration={null}
        onClose={() => { }}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={() => setProgress(false)} severity="info">
          Document revoking in progress
          <LinearProgress className={classes.progress} />
        </Alert>
      </Snackbar>}

    </>
  );
};

export default Awaiting;
//  <EDocumentPdfViewer
//           data={certData}
//           open={openPdfViewer}
//           handleClose={() => setOpenPdfViewer(false)}
//           pdfName={pdfName}
//           isEdocs={edocs}
//         ></EDocumentPdfViewer>

        // <PdfViewer
        //   data={certData}
        //   open={openPdfViewer}
        //   handleClose={() => setOpenPdfViewer(false)}
        //   pdfName={pdfName}
        //   edocs={edocs}
        // ></PdfViewer>