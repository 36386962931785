import React, { useCallback, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import MUIDataTable from "mui-datatables";
import { Document, Page } from 'react-pdf';
import Certificate from "../components/awaiting/Certificate";
import PdfViewer from "../components/awaiting/PdfViewer";
import { useParams } from "react-router-dom";
import DropboxChooser from "react-dropbox-chooser";
import axios from "axios";
import { getCorporateSettings } from './../services/admin.service';

import { documentUploadMethods } from "./../constants";
import { isNotEmpty } from "./../utils/Generic";

import {
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  LinearProgress,
  NativeSelect,
  Radio,
  RadioGroup,
  Snackbar,
  SvgIcon,
  TextField,
  Typography,
  withStyles,
} from "@material-ui/core";
import DragDrop4 from "../static/svg/drag-drop-4.svg";
// import pdfUploaded from "../static/svg/pdfUploaded.svg";
import { useDropzone } from "react-dropzone";
import { Alert } from "@material-ui/lab";
import { uploadDoc, signDoc, getSignatories } from "../services/api.service";
import { useHistory } from "react-router";
import { useModal } from "../hooks";
import { useAuth } from "../hooks";
import { requestOtp, getDomainList, getEdocumentList } from "../services/auth.service";
import VerifyModal from "../components/auth/Verification";
import CloseIcon from "@material-ui/icons/Close";
import ErrorIcon from "@material-ui/icons/Error";
import { sidePanelFilterMapping, noDocumentMessage } from "../constants";

import Pdf from '../static/svg/pdf.svg';
import { getDocumentDetails } from "../services/dms.service";
import { Cancel } from "@material-ui/icons";
import Toast from "../utils/Toast";
import DropBoxChooser from "./DropBoxChooser";
import GoogleDriveChooser from "./GoogleDriveChooser";
import { getFileData } from "../utils/picker";
import DmsChooser from "./DmsChooser";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: 'transparent',
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  paper1: {
    display: "flex",
    flexWrap: "wrap",
    backgroundColor: 'transparent',
    "& > *": {
      margin: theme.spacing(3),
      padding: theme.spacing(2),
      width: "100%",
      textAlign: "center",
    },
  },
  edocuments: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  paper2: {
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(3),
      padding: theme.spacing(2),
      width: "100%",
    },
  },
  paper3: {
    backgroundColor: 'transparent',
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(3),
      padding: theme.spacing(2),
      width: "100%",
      height: 350,
    },
  },
  dropIcon: {
    width: "150px",
    padding: theme.spacing(2),
  },
  docOptions: {
    cursor: "pointer",
    background: "#66cfa3",
    padding: "5px 20px",
    color: "#fff",
    borderRadius: "20px",
  },
  icon: {
    margin: theme.spacing(1),
  },
  formControl: {
    minWidth: "100%",
  },
  contentMargin: {
    marginTop: theme.spacing(3),
  },
  dialogRoot: {
    width: '700px',
    borderRadius: 50,
    margin: '0 auto',
  },
  text2: {
    color: "#7E8D90",
    fontSize: "16px",
    fontWeight: 500,
    fontFamily: "Poppins",
    height: "48px",
    lineHeight: "27px",
  },
  text2: {
    color: "#7E8D90",
    fontSize: "20px",
    fontWeight: 500,
    fontFamily: "Poppins",
    lineHeight: "27px",
  },
  contentHeight: {
    minHeight: '100px',
    maxWidth: '100%',
    margin: '0 auto',
    minWidth: '300px'
  },
  title: {
    paddingTop: 20,
    textAlign: 'center',
    fontWeight: '600',
    textTransform: 'uppercase',
    letterSpacing: 0.5,
    wordSpacing: 2,
    paddingBottom: 10
  },
  stepText: {
    fontWeight: '600',
    paddingBottom: 30,
    textAlign: 'center',
    color: theme.palette.admin.main,
    letterSpacing: 1,
  },
  actionBar: {
    justifyContent: 'center',
    marginBottom: 50
  },
  nextButton: {
    width: 220,
    height: 50,
    borderRadius: 25,
    color: '#fff',
    letterSpacing: 1,
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    }
  },
  draftButton: {
    width: 220,
    height: 50,
    borderRadius: 25,
    letterSpacing: 1,
    backgroundColor: '#fff',
    color: "#6a6a6a",
    border: '1px solid gray',
    '&:hover': {
      backgroundColor: "#fff",
      color: '#363636',
      border: '1px solid #464646'
    }
  },
  closeIcon: {
    position: 'absolute',
    right: '0',
    padding: '10px'
  },
  buttonTextCancel: {
    fontWeight: "bold",
    fontSize: 16,
    letterSpacing: "0.15em",
  },
  buttonRight: {
    marginTop: "20px",
    marginBottom: "10px",
    border: '1px solid gray',
    backgroundColor: '#fff',
    width: 150,
    height: 45,
    color: "#6a6a6a",
    borderRadius: 25,
    '&:hover': {
      backgroundColor: "#fff",
      color: '#363636',
      border: '1px solid #464646'
    }
  },
}));

const CssTextField = withStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#66CFA4",
      },
      "&:hover fieldset": {
        borderColor: "#66CFA4",
      },
      borderRadius: 50,
    },
  },
})(TextField);

// function getSteps() {
//   return ["Select campaign settings", "Create an ad group", "Create an ad"];
// }

// function getStepContent(step) {
//   switch (step) {
//     case 0:
//       return `<h1>hi bob<h1>`;
//     case 1:
//       return "An ad group contains one or more ads which target a shared set of keywords.";
//     case 2:
//       return `hi`;
//     default:
//       return "Unknown step";
//   }
// } 

export default function VerticalLinearStepper() {

  const [file, setFiles] = useState(null);
  const [domain, setDomain] = useState("");
  const [domainName, setDomainName] = useState("");
  const [documentType, setDocumentType] = useState("VERIFIABLE_DOCUMENT");
  const [selectionModel, setSelectionModel] = React.useState([]);
  const [openNotification, setOpenNotification] = useState(false);
  const [openUploadNotification, setOpenUploadNotification] = useState(false);
  const [progress, setProgress] = useState(false);
  const [eDocument, seteDocument] = useState(false);
  const history = useHistory();
  const { isOpen, onOpen } = useModal();
  const { user } = useAuth();
  const [activeStep, setActiveStep] = useState(0);

  const columns = ["user_id", "username", "email", "job_title", "group", {
    name: "order",
    options: {
      customBodyRender: (value, tableMeta, updateValue) => {
        let { rowData, rowIndex, columnIndex } = tableMeta;
        if (selectionModel?.length && selectionModel.indexOf(rowIndex) !== -1) {
          return (
            <div>
              <select value={rowData[columnIndex] || selectionModel.indexOf(rowIndex) + 1} onChange={(event) => {
                let rowsData = [...rows]
                rowsData[rowIndex].order = event.target.value
                setRows([...rowsData])
              }}>
                {
                  selectionModel?.map((value, index) => {
                    return <option value={index + 1}>{index + 1}</option>
                  })
                }
              </select>
            </div>
          );
        } else {
          return null;
        }
      }
    }
  }];


  const [rows, setRows] = useState([]);
  const [domainList, setDomainList] = useState([]);
  const [eDocumentList, setEDocumentList] = useState([]);
  const [documentSelected, setDocumentSelected] = useState("");
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  let { filterType } = useParams();
  const [openPdfViewer, setOpenPdfViewer] = useState(false);
  const [pdfName, setPdfName] = useState();
  const [certData, setCertData] = useState();
  const [edocs, setEdocs] = useState(true);

  const [corporateSettings, setCorporateSettings] = useState({});

  const [nonTransferableUploadMethod, setNonTransferableUploadMethod] = useState(documentUploadMethods.both.value);
  const [uploadMethod, setUploadMethod] = useState(documentUploadMethods.both.value);

  let [replacedName, setReplacedName] = useState(null);
  const [replaceConfirmModal, setReplaceConfirmModal] = useState(false);

  let { documentId } = useParams();

  const [fileName, setFileName] = useState(null);

  const [loadingDocument, setLoadingDocument] = useState(false);
  const [documentData, setDocumentData] = useState(null);
  const [newUpload, setNewUpload] = useState(true);
  const [showNewNameModel, setShowNewNameModel] = useState(false);

  const toastInitialValues = {
    isOpen: false,
    isSuccess: false,
    isError: false,
    message: ""
  }

  const [toast, setToast] = useState(toastInitialValues);

  const closeToast = () => {
    return setToast((prev) => ({
      ...prev,
      isOpen: false,
      isSuccess: false,
      isError: false,
      message: ""
    }));
  };

  useEffect(() => {
    console.log("documentId", documentId);
    if (isNotEmpty(documentId)) {
      setLoadingDocument(true);
      getDocumentDetails({
        documentId: documentId
      }).then((res) => {
        let document = res?.data?.document;
        setLoadingDocument(false);
        setDocumentData(document);
        setNewUpload(false);
        setFileName(document?.name);
      }).catch(err => {
        console.log(err);
        setLoadingDocument(false);
      });
    } else {
      setDocumentData(null);
      setLoadingDocument(false);
      setNewUpload(true);
    }
  }, [documentId]);

  // const steps = 3;
  // const steps = getSteps();

  useEffect(() => {
    getDomain();
    loadCorporateSettings();
    getEdocList();
    getSignatories()
      .then((res) => {
        setRows(res.data.data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  useEffect(() => {
    console.log("uploadMethod", uploadMethod);
  }, [uploadMethod]);

  const loadCorporateSettings = () => {
    getCorporateSettings().then(res => {
      if (res && res.status === 200) {
        let settings = res.data.settings;
        setCorporateSettings(settings);
        setNonTransferableUploadMethod(settings?.non_transferable_method);
        setUploadMethod(settings?.non_transferable_method);
      }
    }).catch(e => {
    });
  }

  // const steps = 3;
  // const steps = getSteps();

  const handleChangeDomain = (event) => {
    console.log("event.target:", event.target.label)
    setDomain(event.target.value);
    setDomainName(event.target.id);
  };

  const handleChangeDocumentSelect = (event) => {
    console.log("event.target:", event.target.value);
    setDocumentSelected(event.target.value);
    // setDomainName(event.target.id);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  // const handleReset = () => {
  //   setActiveStep(0);
  // };
  const getDomain = () => {
    getDomainList()
      .then((res) => {
        if (res && res.status === 200) {
          setDomainList(res.data);
        } else {
          setDomainList([]);
        }
      })
      .catch((e) => {
        console.log("Error catched", e);
      });
  };

  const getEdocList = () => {
    getEdocumentList({ email: user.email })
      .then((res) => {
        if (res && res.status === 200) {
          setEDocumentList(res.data.data);
        } else {
          setEDocumentList([]);
        }
      })
      .catch((e) => {
        console.log("Error catched", e);
      });
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenNotification(false);
  };

  // function ab2str(buf) {
  //   return String.fromCharCode.apply(null, new Uint8Array(buf));
  // }

  const submitUpload = (overwrite = false) => {
    const signatories = selectionModel.map((value, index) => {
      return {
        user_id: rows[value].user_id,
        email: rows[value].email,
        placeholders: [],
        order: rows[value].order || index + 1
      };
    });
    console.log("selectionModel", selectionModel);
    setOpenUploadNotification(true);
    setProgress(true);

    let sortedSignatories = signatories;
    //sort signatories by order
    sortedSignatories.sort((a, b) => {
      return a.order - b.order;
    });

    uploadDoc({
      file,
      signatories: sortedSignatories,
      domain_id: domain,
      document_type: documentType,
      upload_method: uploadMethod,
      eDocument,
      documentSelected,
      newUpload,
      documentId: documentData?.id,
      overwrite: overwrite,
      replace_name: replacedName,
    }).then((response) => {
      let responseData = response?.data;
      console.log(responseData);
      if (isNotEmpty(responseData?.status) && responseData.status.toString() === "500") {
        if (isNotEmpty(responseData?.error?.duplicate)) {
          setReplaceConfirmModal(true);
          setProgress(false);
        } else {
          setProgress(false);
          setToast((prev) => ({
            ...prev,
            isOpen: true,
            isError: true,
            message: responseData.message
          }));
        }
      } else {
        if (isNotEmpty(responseData?.error?.duplicate)) {
          setReplaceConfirmModal(true);
          setProgress(false);
        } else {
          if (signatories && signatories.length === 1 && signatories[0].email === user.email) {
            signDoc({
              document_id: response.data.document_id,
              email: user.email,
              status: "SIGNED",
            }).then((res) => {
              setProgress(true);
              setOpenUploadNotification(false);
              console.log(res);
              var dataStr =
                "data:text/json;charset=utf-8," +
                encodeURIComponent(JSON.stringify(res?.data?.response?.doc[0]));
              console.log(dataStr);
              history.push({ pathname: "/sign", state: { response: dataStr, file, domainName } });
            });
          } else {
            history.push({ pathname: "/sign", state: { response: null, file } })
          }
        }
      }
    }).catch(error => {
      setProgress(false);
      console.log("error", error);
    });
  };

  const setDropBox = async (files) => {
    console.log("files", files);
    let link = files[0].link;
    let name = files[0].name;
    const file = await getFileFromUrl(link, name);
    console.log("file setDropBox:", file);
    setFiles(file);
    setFileName(name);
    setNewUpload(true);
    setOpenNotification(true);
  };

  async function getFileFromUrl(url, name, defaultType = 'application/pdf') {
    const response = await fetch(url);
    console.log("response fetch:", response);
    const data = await response.blob();
    return new File([data], name, {
      type: data.type || defaultType,
    });
  }

  const options = {
    filter: true,
    filterType: "dropdown",
    tableBodyHeight: "240px",
    responsive: "scroll",
    onRowSelectionChange: (
      currentRowsSelected,
      allRowsSelected,
      rowsSelected
    ) => {
      setSelectionModel(rowsSelected);
    },
    rowsSelected: selectionModel,
  };
  const handleOTP = () => {
    submitUpload();

    // onOpen({});
    // requestOtp().then((res) => {
    //   if (res && res.status === 200) {
    //     onOpen({
    //       sessionId: res.sessionId,
    //     });
    //     submitUpload();
    //   } else {
    //   }
    // });
  };
  const onDrop = useCallback((acceptedFiles) => {
    // Do something with the files
    acceptedFiles.forEach((f) => {
      if (f.type === "application/pdf") {
        const reader = new FileReader();
        reader.onabort = () => console.log("file reading was aborted");
        reader.onerror = () => console.log("file reading has failed");
        reader.onload = () => {
          // Do whatever you want with the file contents
          // const binaryStr = reader.result;
          setFiles(f);
          setFileName(f?.name);
          setNewUpload(true);
          setOpenNotification(true);
          setTimeout(e => {
            handleNext();
          }, 500);
        };
        reader.readAsText(f);
      } else {
        console.log("file format unaccepted");
        console.log(f);
      }
    });
  }, []);

  const chooserFileSelect = async (data) => {
    if (data != null) {
      let fileData = await getFileData(data);
      if (fileData != null) {
        // console.log("fileData", fileData);
        onDrop([fileData]);
      }
    }
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  const classes = useStyles();
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  const [expanded, setExpanded] = useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  // console.log("file:", file);

  const firstNextBtnIsDisable = () => {
    if (eDocument) {
      if (documentSelected == "") {
        return true;
      } else {
        return false;
      }
    } else {
      if (newUpload) {
        if (file) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    }
    //eDocument ? documentSelected == "" ? true : false : !newUpload ? true : !file ? true : false;
  }

  const confirmReplace = (e, replaceIt) => {
    if (replaceIt) {
      setReplaceConfirmModal(false);
      submitUpload(true);
    } else {
      setReplaceConfirmModal(false);
      setShowNewNameModel(true);
      setReplacedName(fileName);
    }
  };

  const hideNewNameModel = () => {
    setShowNewNameModel(false);
  }

  const submitNewNameModel = () => {
    if (isNotEmpty(replacedName)) {
      setShowNewNameModel(false);
      submitUpload(false);
    }
  }

  return (
    <>

      {toast.isOpen && <Toast message={toast.message} isError={toast.isError} isSuccess={toast.isSuccess} closeToast={closeToast} />}

      <div className={classes.root}>

        <Snackbar
          open={openNotification}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        >
          <Alert onClose={handleClose} severity="success">
            File Uploaded!
          </Alert>
        </Snackbar>
        {/* <Snackbar
        open={openUploadNotification}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Alert onClose={handleClose} severity="success">
          File uploading!
        </Alert>
      </Snackbar> */}
        <Stepper activeStep={activeStep} orientation="vertical" style={{ backgroundColor: 'transparent' }}>
          <Step color="secondary">

            <div className="d-none">
              <StepLabel className={`upl-step-label`} >
                <div className={classes.edocuments}>
                  <div className={classes.docOptions} onClick={() => activeStep == 0 ? seteDocument(false) : ""} disabled={activeStep == 0 ? false : true}>Upload Document </div>
                  <div className={classes.docOptions} disabled={activeStep == 0 ? false : true} onClick={() => activeStep == 0 ? seteDocument(true) : ""}>Select E-Documents</div>
                </div>
              </StepLabel>
            </div>

            <StepLabel className="upl-step-label">
              Upload Document
            </StepLabel>

            <StepContent color="secondary">
              {!eDocument ? (<div {...getRootProps()} >
                <input type="file" name="file" {...getInputProps()} />
                <Grid container spacing={0}>
                  <Grid item xs={12}>
                    <div className={classes.paper1}>
                      <Paper className="upl-wrp-style" elevation={0} style={{ backgroundColor: 'transparent' }}>

                        {
                          newUpload ? <>

                            {file ? (
                              <img
                                src={Pdf}
                                alt="TruDoc"
                                className={classes.dropIcon}
                              />
                            ) : (
                              <img
                                src={DragDrop4}
                                alt="TruDoc"
                                className={classes.dropIcon}
                              />
                            )}
                            <Box m={2}>
                              {file ? (
                                <Typography variant="h6">{fileName}</Typography>
                              ) : (
                                <Typography className="fOne-doc-text">
                                  Drop a document here to get started
                                </Typography>
                              )}
                            </Box>

                          </> : <>

                            <img
                              src={Pdf}
                              alt="TruDoc"
                              className={classes.dropIcon}
                            />

                            <Box m={2}>
                              <Typography variant="h6">{fileName}</Typography>
                            </Box>

                          </>
                        }

                        <Typography
                          align="center"
                          className={classes.text2}
                        >
                          OR
                        </Typography>

                        <Box m={2}>
                          <Button className="upl-btn mt-0px" variant="contained" color="primary">
                            {file ? 'Choose Another Document' : 'UPLOAD DOCUMENT'}
                          </Button>
                        </Box>
                        <Box className="upl-icon-list  file-choose-icons" mt={2}>

                          <DmsChooser onSelected={chooserFileSelect} />
                          <DropBoxChooser onSelected={chooserFileSelect} />
                          <GoogleDriveChooser onSelected={chooserFileSelect} />

                        </Box>
                      </Paper>
                    </div>
                  </Grid>
                </Grid>
              </div>) :
                (<div className={classes.paper2}>
                  <Box className={classes.content}>

                    {eDocumentList && eDocumentList.length > 0 ? (
                      <>
                        <Box display="flex" justifyContent="space-around" mb="20px">
                          <Box width="25%">
                            <Typography>Doc Name</Typography>
                          </Box>
                          <Box width="15%">
                            <Typography>Created On</Typography>
                          </Box>
                          {!eDocument ? <Box width="10%">
                            <Typography>Creator</Typography>
                          </Box> : ""}
                          {!eDocument ? <Box width="20%">
                            <Typography>Domain</Typography>
                          </Box> : ""}
                          <Box width="20%" align="center">
                            <Typography>Action</Typography>
                          </Box>
                        </Box>
                        {eDocumentList &&
                          eDocumentList.map((cert) => {
                            return (
                              <Certificate
                                key={cert.id}
                                // expanded={expanded}
                                handleChange={handleChange}
                                data={cert}
                                // filterType={filterType}
                                handleViewPdf={() => {
                                  console.log("cert", cert)
                                  setOpenPdfViewer(true);
                                  setPdfName(cert.storage_key);
                                  setCertData(cert);
                                }}
                                radio={true}
                                handleChangeCheckBox={(e) => handleChangeDocumentSelect(e)}
                                eDocument={eDocument}
                              />
                            );
                          })}
                      </>
                    ) : (
                      <Box align="center" padding={10}>
                        <ErrorIcon className={classes.emptyIcon} color="primary" />
                        <Typography variant="h6">{noDocumentMessage[filterType]}</Typography>
                      </Box>
                    )}
                    {/* <Grid item xs={4}>
                    <FormControl className={classes.formControl}>
                      <NativeSelect
                        value={documentSelected}
                        onChange={handleChangeDocumentSelect}
                        // id={signatoryColorForSign}
                        name="signatory_for_sign"
                        className={`${classes.selectEmpty} upl-select-box`}
                        inputProps={{ "aria-label": "domain_config_id" }}
                      >
                        <option value="" disabled={true}>Please Select</option>
                        {
                          eDocumentList && eDocumentList.map((e) => {
                            return <option value={e.name}>{e.name}</option>
                          })
                        }
                      </NativeSelect>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <div>
                      <Document file="https://trudocs-public-pdf-img-only.s3.ap-southeast-1.amazonaws.com/1111122332.pdf" onLoadSuccess={onDocumentLoadSuccess}>
                        <Page pageNumber={pageNumber} />
                      </Document>
                      <p>
                        Page {pageNumber} of {numPages}
                      </p>
                    </div>   
                    </Grid> */}
                  </Box>
                  {/* </Grid>
              </Paper> */}
                </div>)}
              <div className={classes.actionsContainer}>
                <div>
                  <Button
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    className={`${classes.button} back-upl-btn`}
                  >
                    Back
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                    // className={classes.button}
                    className={`${classes.button} next-upl-btn`}
                    disabled={firstNextBtnIsDisable()}
                  >
                    Next
                  </Button>
                </div>
              </div>
            </StepContent>
          </Step>

          {/* Step 2 */}

          <Step>
            <StepLabel className="upl-step-label">Select domain & document type</StepLabel>
            <StepContent>
              <Grid container spacing={0}>
                <Grid item xs={10}>
                  <div className={classes.paper2}>
                    <Paper elevation={0} style={{ backgroundColor: 'transparent' }}>
                      <Grid
                        container
                        spacing={2}
                        alignItems="center"
                        justify="start"
                      >
                        <Grid item xs={2}>
                          <Typography className="fileupl-title">DOMAIN* :</Typography>
                        </Grid>
                        <Grid item xs={9}>
                          <FormControl className={classes.formControl}>
                            <NativeSelect
                              value={domain}
                              onChange={handleChangeDomain}
                              name="domain_config_id"
                              className={`${classes.selectEmpty} upl-select-box`}
                              inputProps={{ "aria-label": "domain_config_id" }}
                            >
                              <option value="">Please Select</option>
                              {domainList && domainList.length > 0 && domainList?.map((item, index) => (
                                <>
                                  {
                                    item.domain_configs.length > 0 ? <>
                                      <option key={index} value={item.id}>
                                        {item.name}
                                      </option>
                                    </> : null
                                  }
                                </>
                              ))}
                            </NativeSelect>
                          </FormControl>
                        </Grid>


                        <Grid item xs={12} className={classes.contentMargin}>
                          <Typography className="fileupl-title">
                            Create a title/transferable document?
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <FormControl component="fieldset">
                            <RadioGroup
                              row
                              value={documentType}
                              aria-label="position"
                              name="position"
                              defaultValue="top"
                            >
                              <FormControlLabel
                                value="TRANSFERABLE_RECORD"
                                control={<Radio color="primary" />}
                                label="Yes"
                                onClick={() => {
                                  setDocumentType("TRANSFERABLE_RECORD");
                                  setUploadMethod(documentUploadMethods.blockchain.value);
                                }
                                }
                              />
                              <FormControlLabel
                                value="VERIFIABLE_DOCUMENT"
                                control={<Radio color="primary" />}
                                label="No"
                                onClick={() => {
                                  setDocumentType("VERIFIABLE_DOCUMENT");
                                  setUploadMethod(nonTransferableUploadMethod);
                                }
                                }
                              />
                            </RadioGroup>
                          </FormControl>
                        </Grid>

                        {
                          documentType === 'VERIFIABLE_DOCUMENT' && nonTransferableUploadMethod === documentUploadMethods.both.value ?
                            <>

                              <Grid item xs={12} className={classes.contentMargin}>
                                <Typography className="fileupl-title">
                                  Upload document to?
                                </Typography>
                              </Grid>
                              <Grid item xs={12}>

                                <button onClick={() =>
                                  setUploadMethod(documentUploadMethods.did.value)
                                } title="Select DID to post document to decentralised system. No Ethereum will be deducted for this option." className={`theme-btn btn-lg me-10px ${uploadMethod == documentUploadMethods.did.value ? 'primary' : ''}`}>DID</button>

                                <button onClick={() =>
                                  setUploadMethod(documentUploadMethods.blockchain.value)
                                } title="Select Blockchain to post document on Ethereum system. Ethereum charges will apply for this option." className={`theme-btn btn-lg ${uploadMethod == documentUploadMethods.blockchain.value ? 'primary' : ''}`}>Blockchain</button>

                                <FormControl component="fieldset" className="d-none">
                                  <RadioGroup
                                    row
                                    value={uploadMethod}
                                    aria-label="upload_method"
                                    name="upload_method"
                                    defaultValue="top"
                                  >

                                    <FormControlLabel
                                      value={documentUploadMethods.blockchain.value}
                                      control={<Radio color="primary" />}
                                      label="Yes"
                                      onClick={() =>
                                        setUploadMethod(documentUploadMethods.blockchain.value)
                                      }
                                    />

                                    <FormControlLabel
                                      value={documentUploadMethods.did.value}
                                      control={<Radio color="primary" />}
                                      label="No"
                                      onClick={() =>
                                        setUploadMethod(documentUploadMethods.did.value)
                                      }
                                    />

                                  </RadioGroup>
                                </FormControl>
                              </Grid>

                            </> : ''
                        }


                      </Grid>
                    </Paper>
                  </div>
                </Grid>
              </Grid>
              <div className={classes.actionsContainer}>
                <div>
                  <Button
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    className={`${classes.button} back-upl-btn`}
                  >
                    Back
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                    className={`${classes.button} next-upl-btn`}
                  >
                    Next
                  </Button>
                </div>
              </div>
            </StepContent>
          </Step>
          <Step>
            <StepLabel className="upl-step-label">Select Signatories</StepLabel>
            <StepContent>
              <div className={`${classes.paper3} select-seg-table`}>
                <MUIDataTable data={rows} columns={columns} options={options} />
              </div>

              <div className={classes.actionsContainer}>
                <div>
                  <Button
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    className={`${classes.button} back-upl-btn`}
                  >
                    Back
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleOTP}
                    className={`${classes.button} next-upl-btn`}
                  >
                    Go
                  </Button>
                </div>
              </div>
              {progress && <LinearProgress />}
              {isOpen && <VerifyModal />}
            </StepContent>
          </Step>
        </Stepper>
        {/* {activeStep === steps.length && (
        <Paper square elevation={0} className={classes.resetContainer}>
          <Typography>All steps completed - you&apos;re finished</Typography>
          <Button onClick={handleReset} className={classes.button}>
            Reset
          </Button>
        </Paper>
      )} */}
        {openPdfViewer ?
          <PdfViewer
            data={certData}
            open={openPdfViewer}
            handleClose={() => setOpenPdfViewer(false)}
            pdfName={pdfName}
            edocs={edocs}
            radio={true}
          ></PdfViewer> : ""}
      </div>

      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={replaceConfirmModal}
        className={classes.dialogRoot}
        keepMounted={false}
        PaperProps={{
          style: { borderRadius: 20, maxHeight: "calc(100% - 44px)" },
        }}
      >
        <DialogContent className={classes.contentHeight}>
          <Typography variant="h6" className="confirmModalTitle">
          File with same name already exist in Store. Please choose how you want to Continue.
          </Typography>

          <Box className="confirmModalBox">

            <Button
              variant="contained"
              color="primary"
              onClick={(e) => confirmReplace(e, true)}
              className={`${classes.button} next-upl-btn`}
            >
              Replace
            </Button>

            <Button
              variant="contained"
              color="primary"
              onClick={(e) => confirmReplace(e, false)}
              className={`${classes.button} back-upl-btn`}
            >
              Keep Both
            </Button>

          </Box>
        </DialogContent>
      </Dialog>


      <Dialog
        fullWidth={true}
        maxWidth='md'
        open={showNewNameModel}
        className={classes.dialogRoot}
        keepMounted={false}
        onClose={hideNewNameModel}
        PaperProps={{
          style: { borderRadius: 20, maxHeight: 'calc(100% - 44px)' }
        }}
      >
        <div className={classes.closeIcon}>
          <IconButton
            onClick={hideNewNameModel}
          >
            <Cancel />
          </IconButton>
        </div>
        <DialogTitle gutterBottom>
          <Typography variant='h5' className={classes.title}>
            Enter New Name
          </Typography>
        </DialogTitle>

        <DialogContent className={classes.contentHeight}>

          <Grid
            container
            direction="row"
            alignItems="center"
            className={classes.root}
            spacing={2}
          >
            <Grid item xs={3} align="right">
              <Typography className={classes.subHeading}>Name*</Typography>
            </Grid>
            <Grid item xs={9} align="center">
              <CssTextField
                className={classes.field}
                variant="outlined"
                fullWidth
                InputLabelProps={{ className: classes.textFieldInput }}
                InputProps={{ className: classes.textFieldInput }}
                value={replacedName}
                onChange={e => setReplacedName(e.target.value)}
              />
            </Grid>
          </Grid>

        </DialogContent>

        <DialogActions className={classes.actionBar} gutterBottom>

          <Box className="w-100 text-center">

            <Button className={classes.nextButton + ' me-10px'} onClick={submitNewNameModel}>
              <Typography variant='h6'>Continue</Typography>
            </Button>
            <Button className={classes.draftButton} onClick={hideNewNameModel}>
              <Typography variant='h6'>{"Cancel"}</Typography>
            </Button>

          </Box>

        </DialogActions>


      </Dialog>

    </>
  );
}