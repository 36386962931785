export const API_Base_URL =
  process.env.NODE_ENV === "production" ?
    "https://app.trudoc.io/api" :
    "http://localhost:3000"

export const API_URL = `${API_Base_URL}/v1`;

export const endpoint = {
  health: {
    SERVER_HEALTH: "/health",
    API_HEALTH: "/api/health",
    AUTH_HEALTH: "/auth/health",
  },
  auth: {
    LOGIN: "/api/users/login",
    VERIFY_OTP: "/api/users/otp/verify",
    SIGNUP: "/api/users/signup",
    SEND_OTP: "/api/users/otp/send",
    VERIFY_LOGIN_OTP: "/api/users/otp/verify",
    RESET_PASSWORD: "/api/users/resetPassword",
    FORGOT_PASSWORD: "/api/users/forgotPassword",
    RESET_FORGOT_PASSWORD: "/api/users/resetForgotPassword"
  },
  doc: {
    VERIFY: "/api/document/verify",
    UPLOAD: "/api/document/create",
    SIGN: "/api/document/sign",
    DOMAIN: "/api/domain",
    DOWNLOADTT: "/api/document/ttFileDownload",
    RENDERPDF: "/api/document/download",
    PDF_Images: "/api/document/download/downloadPDFImages",
    ENDROSMENT_CHAIN: "/api/document/endorsement-chain",
    UPLOADTT: "/api/document/uploadTT",
    TITLE_TRANSFER: "/api/document/title-transfer",
    REVOKE: "/api/document/revoke",
    REVOKE_STATUS: "/api/document/revoke/status",
    MARK_REVOKED: "/api/document/revoke/mark-revoked",
    ACCOUNT_DETAILS: "/api/document/address",
    SIGN_TRANSACTION: "/api/document/sign/transaction",
    UPDATE_SIGN_TRANSACTION: "/api/document/sign/update-transaction",
    PICKER_FILE_DATA: "/api/document/picker/file-data"
  },
  dms: {
    UPLOAD: "/api/dms/upload",
    CREATE_FOLDER: "/api/dms/new-folder",
    ALL_DOCUMENTS: "/api/dms/all-documents",
    DOCUMENT_DETAILS: "/api/dms/document-details",
    DOCUMENT_PREVIEW: "/api/dms/document-preview",
    DOCUMENT_PREVIEW_LINK: "/api/dms/document-preview-link",
    DOWNLOAD: "/api/dms/download",
    REVERT_VERSION: "/api/dms/revert-version",
    RENAME_DOCUMENT: "/api/dms/rename",
    DELETE_DOCUMENT: "/api/dms/delete",
    CHECK_DOCUMENT_PRESENT: "/api/dms/checkDocumentPresent",
    ADD_COLLABORATOR: "/api/dms/add-collaborator",
    UPDATE_COLLABORATOR: "/api/dms/update-collaborator",
    DELETE_COLLABORATOR: "/api/dms/delete-collaborator",
    MOVE_FILES: "/api/dms/move-files",
    GET_FILE: "/api/dms/get-link",
    PREVIEW_SHARE_LINK: "/api/dms/preview-share-link",
    DOCUMENT_DETAILS_SHARE_LINK: "/api/dms/share-link-document-details",
    COPY_OBJECT: "/api/dms/copy-files"
  },
  eDoc: {
    SIGN_PDF: "/api/eDocument/signed-edoc",
    PROCESS_E_PDF: "/api/eDocument/processPDF",
    GET_EDOCUMENTLIST: "/api/eDocument/documentList",
    UPLOAD: "/api/eDocument/create",
    API_HEALTH: "/api/health",
    AUTH_HEALTH: "/auth/health",
  },
  others: {
    ALL_DOCUMENTS: "/api/sidePanel/panel",
  },
  common: {
    CONTENT_CATEGORIES: "/api/common/content-categories",
  },
  public: {
    ACCESS_TOKEN_DETAILS: "/public/access-token-details",
    ENDROSMENT_CHAIN: "/public/endorsement-chain",
  },
  user: {
    SIGNATORIES: "/api/users/signatories",
    CORPORATE_MEMBERS: "/api/users/corporate-members",
    SIGN_LIST: "/api/users/sign",
    DELETE_SIGN: "/api/users/sign",
    CONTACTS: "/api/users/contacts",
    SEND_SIGN: "/api/users/sign",
    SIGN_DATA: "/api/users/sign/download?signId=",
    DETAILS: "/api/users/details"
  },
  admin: {
    WALLET: "/api/corporate/wallet",
    CONTACTS: "/api/corporate/contacts",
    CUSTOMERS: "/api/corporate/customers",
    DOMAIN: "/api/domain",
    DOMAIN_SECRET: "/api/domain/secret",
    STATUS: "/api/document/status",
    CORPORATE_LIST: "/api/corporate/get-list",
    CORPORATE_LIST_FROM_ADDRESS: "/api/corporate/get-list-by-address",
    BLOCK_UNBLOCK_USER: "/api/corporate/contacts/blockUnblockUser",
    RESEND_DOMAIN_VERIFICATION: "/api/domain/resendDomainVerification",
    CONFIGURE_DOMAIN: "/api/domain/configure",
    CONFIGURE_DOMAIN_SAVE: "/api/domain/configure-save",
    CORPORATE_SETTINGS: "/api/corporate/settings",
    CORPORATE_SETTINGS_SAVE: "/api/corporate/settings",
    CORPORATE_SESSION_UPDATE: "/api/corporate/session",
    GET_CORPORATE_SESSION: "/api/corporate/session",
    UPDATE_WALLET_TYPE: "/api/corporate/update-wallet-type",
    LINK_WALLET: "/api/corporate/link-wallet",
    CREATE_WALLET: "/api/corporate/create-wallet",
    CORPORATE_INFO: "/api/corporate/info",
  },
  client: {
    uploadDocument: "/client-api/demo-api-doc",
    downloadDocument: "/client-api/document/ttfile",
    VERIFY_DOC_BY_ID: (docID) => `/client-api/document/${docID}/verify`,
  },
  super: {
    DASHBOARD: "/api/super/dashboard",
    LIST_CORPORATES: "/api/super/corporates/list",
    AWAITING_CORPORATES: "/api/super/corporates/awaiting",
    CORPORATE_UPDATE:  "/api/super/corporates/update",
    MARK_CORPORATE_ACTIVE: "/api/super/corporates/mark-active",
    CORPORATE_FORM_LINK: "/api/super/corporates/form-link",
    SETTINGS_LIST: "/api/super/settings/list",
    UPDATE_SETTING: "/api/super/settings/update",
    CORPORATE_DETAILS: "/api/super/corporates/details",
    FETCH_SUSTAINABILITY_DETAILS: "/api/super/corporates/fetch-sustainability-details",
  },
  todo: {
    CREATE: "/api/todo/create",
    UPDATE: "/api/todo/update",
    MARK_COMPLETED: "/api/todo/mark-completed",
    MARK_UNCOMPLETED: "/api/todo/mark-uncompleted",
    DELETE: "/api/todo/delete",
    LIST: "/api/todo/list",
  },
  chat: {
    LIST: "/api/chat/list",
    MESSAGES: "/api/chat/messages",
    CHAT_DETAILS: "/api/chat/details",
    ACCEPT_CHAT: "/api/chat/accept-chat",
    NEW_CHAT: "/api/chat/new-chat",
    NEW_GROUP: "/api/chat/new-group",
    ADD_GROUP_MEMBER: "/api/chat/add-member",
    REMOVE_GROUP_MEMBER: "/api/chat/remove-member",
    NEW_MEDIA_MESSAGE: "/api/chat/new-media-message",
    DELETE_CHAT: "/api/chat/delete"
  },
  sustainability: {
    CURRENT_REPORT: "/api/sustainability/current-report",
    SAVE_REPORT: "/api/sustainability/save-report",
    SAVE_KPI_DATA_TYPE: "/api/sustainability/kpi-data-type",
    SAVE_KPI_DATA: "/api/sustainability/kpi-data",
    ADD_KPI_DATA: "/api/sustainability/add-kpi",
    EDIT_KPI_DATA: "/api/sustainability/edit-kpi",
    DELETE_KPI: "/api/sustainability/delete-kpi",
    UPLOAD: "/api/sustainability/upload",
    DELETE_DOCUMENT: "/api/sustainability/delete-document",
    LOGS: "/api/sustainability/logs",
    DELETE_OBJECTIVE: "/api/sustainability/delete-objective",
  }
};

