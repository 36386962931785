import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { Button, Box, AppBar, Tabs, Tab, Divider, Avatar, CircularProgress } from "@material-ui/core";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import InsertLinkIcon from '@material-ui/icons/InsertLink';
import Restore from "@material-ui/icons/Restore";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import VisibilityIcon from '@material-ui/icons/Visibility';
import UpdateIcon from '@material-ui/icons/Update';
import { canBeSentToSign, haveViewer, matchCollaboratorRole } from "../../utils/document";
import { documentRoles } from "../../constants";
import { useAuth } from "../../hooks";
import { TabContext, TabPanel } from "@material-ui/lab";
import { getFirstChar, concatStrings, printWithDefault, getInitials, isNotEmpty, printTime, getImageFromMimeType } from "../../utils/Generic";
import AddCollaborators from "./AddCollaborators";
import EdiCollaborator from "./EditCollaborator";
import { deleteCollaborator, revertDocumentToVersion } from "../../services/api.service";
import Toast from "../../utils/Toast";
import { MessageConstants } from '../../utils/MessageConstants';
import { useHistory } from "react-router-dom";
import { deleteDocument, getDocumentDetails, getLink, getDocumentDetailsShareLink } from "../../services/dms.service";
import RenameDocument from "./RenameDocument";
import SignTypeConfirmation from "./SignTypeConfirmation";
import { GetApp, ShareOutlined } from "@material-ui/icons";
import AuditLog from "./AuditLog";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 300,
    width: "100%",
    textAlign: "center",
    paddingTop: 20,
  },
  title: {
    marginTop: 15,
    marginBottom: 10,
  },
  position: {
    fontSize: 18,
    marginBottom: 20,
  },
  avatar: {
    fontWeight: 500,
    fontSize: 30,
    margin: "0 auto",
    width: theme.spacing(10),
    height: theme.spacing(10),
    textTransform: "uppercase",
  },
  actionBar: {
    paddingBottom: 20,
  },
  buttonModal: {
    width: "100px",
    height: "36px",
    marginLeft: "20px",
    backgroundColor: "#66CFA4",
    borderRadius: "20px",
    alignSelf: "center",
    marginTop: "20px",
    alignItems: "center",
    justifyContent: "center",
  },
  buttonModalText: {
    color: "#ffffff",
    fontWeight: "600",
  },
}));


function a11yProps(index) {
  return {
    key: `simple-tab-${index}`,
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function DetailedCard(props) {

  const history = useHistory();

  const showOptions = props?.showOptions;

  const toastInitialValues = {
    isOpen: false,
    isSuccess: false,
    isError: false,
    message: ""
  }

  const [toast, setToast] = useState(toastInitialValues);

  const classes = useStyles();

  const [data, setData] = useState(null);

  const [document, setDocument] = useState({});

  const [tabValue, setTabValue] = React.useState(0);
  const [isSharePreview, setSharePreview] = React.useState(false);

  const { user } = useAuth();

  const [openEditCollaboratorDialog, setOpenEditCollaboratorDialog] = useState(false);
  const [collaborator, setCollaborator] = useState({});

  const [openDialog, setOpenDialog] = useState(false);
  const [popupId, setPopupId] = useState(0);

  const [isLoading, setIsLoading] = useState(true);

  const [openRenameDocument, setOpenRenameDocument] = useState(false);
  const [openSignDocument, setOpenSignDocument] = useState(false);

  const [openLogModal, setOpenLogModal] = useState(false);

  useEffect(() => {
    setData(props?.data);
    if (props.isSharePreview) setSharePreview(true)
  }, [props]);


  const onEditClick = (e) => {
    setOpenRenameDocument(true);
  }

  const onGetLink = (documentId) => {
    getLink({
      documentId
    })
      .then((res) => {
        if (res?.data?.data?.id) {
          setToast((prev) => ({
            ...prev,
            isOpen: true,
            isSuccess: true,
            message: `${window.location.origin}/doc-view/${btoa(res.data.data.id)}/${res.data.data.share_id}`
          }));
        }
        // setIsLoading(false);
        // setDocument(res?.data?.document);
      }).catch(err => {
        console.log(err);
        // setIsLoading(false);
        setToast((prev) => ({
          ...prev,
          isOpen: true,
          isSuccess: false,
          message: MessageConstants.GET_LINK_FAILED
        }));
      });

  }

  const onSignClick = (e) => {
    setOpenSignDocument(true);
  }

  const onViewClick = (e) => {
    props.onView(document);
  }

  const onLogClick = (e) => {
    setOpenLogModal(true);
  }

  const onDownloadClick = (e) => {
    props.onDownload(document);
  }

  const onDeleteClick = (e) => {
    if (window.confirm("Are you sure you want to delete it?")) {
      setIsLoading(true);
      deleteDocument({
        documentId: data?.id
      }).then((res) => {
        setIsLoading(false);
        let data = res.data;
        if (data.success) {
          props.onDelete(document);
        } else {
          setToast((prev) => ({
            ...prev,
            isOpen: true,
            isError: true,
            message: data.message
          }));
        }
      }).catch(err => {
        console.log(err);
        setIsLoading(false);
      });
    }
  }

  const onEditCollaboratorClick = (e, collaborator) => {
    setOpenEditCollaboratorDialog(true);
    setCollaborator(collaborator);
  }

  const closeCollaboratorDailogHandler = (anythingChanged) => {
    setOpenEditCollaboratorDialog(false);
    if (anythingChanged) {
      loadDocumentDetails();
    }
  }

  const closeRenameDialogHanlder = (anythingChanged) => {
    setOpenRenameDocument(false);
    if (anythingChanged) {
      loadDocumentDetails();
      props.onRefresh(document);
    }
  }

  const closeLogDialogHanlder = (e) => {
    setOpenLogModal(false);
  }

  const closeSignDialogHanlder = (type) => {
    setOpenSignDocument(false);
    if (type === "default") {
      history.push({ pathname: "/dms/verifiable/" + document?.id });
    } else if (type === "esign") {
      history.push({ pathname: "/dms/tru-sign/" + document?.id });
    }
  }

  const revertToVersion = (e, version) => {
    if (window.confirm("Are you sure you want to revert?")) {
      setIsLoading(true);
      revertDocumentToVersion({
        documentId: data?.id,
        version: version?.id
      }).then((res) => {
        setIsLoading(false);
        if (res?.data?.success === false) {
          setToast((prev) => ({
            ...prev,
            isOpen: true,
            isError: true,
            message: res?.data?.message
          }));
        } else {
          setToast((prev) => ({
            ...prev,
            isOpen: true,
            isSuccess: true,
            message: MessageConstants.DOCUMENT_VERSION_REVERT
          }));
          loadDocumentDetails();
        }
      }).catch(err => {
        console.log(err);
      });
    }
  }

  const closeToast = () => {
    return setToast((prev) => ({
      ...prev,
      isOpen: false,
      isSuccess: false,
      isError: false,
      message: ""
    }));
  };

  const onDeleteCollaboratorClick = (e, collaborator) => {
    if (window.confirm("Are you sure you want to delete it?") === true) {
      setIsLoading(true);
      deleteCollaborator({
        documentId: data?.id,
        collaboratorId: collaborator?.id
      }).then((res) => {
        setIsLoading(false);
        if (res?.data?.success === false) {
          setToast((prev) => ({
            ...prev,
            isOpen: true,
            isError: true,
            message: res?.data?.message
          }));
        } else {
          setToast((prev) => ({
            ...prev,
            isOpen: true,
            isSuccess: true,
            message: MessageConstants.COLLABORATOR_DELETED_SUCCESS
          }));
          loadDocumentDetails();
        }
      }).catch(err => {
        setIsLoading(false);
        console.log(err);
      });
    }
  }

  const isOwner = (collaborator) => {
    return matchCollaboratorRole(collaborator, [
      documentRoles.owner.value
    ]);
  }

  const canEdit = () => {
    return matchCollaboratorRole(document?.myCollaborator, [
      documentRoles.owner.value,
      documentRoles.admin.value,
      documentRoles.editor.value
    ]);
  }

  const isDocumentOwner = () => {
    return matchCollaboratorRole(document?.myCollaborator, [
      documentRoles.owner.value,
    ]);
  }

  const canDelete = () => {
    return matchCollaboratorRole(document?.myCollaborator, [
      documentRoles.owner.value,
      documentRoles.admin.value
    ]);
  }

  const canManageCollaborators = () => {
    return matchCollaboratorRole(document?.myCollaborator, [
      documentRoles.owner.value,
      documentRoles.admin.value
    ]);
  }

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const openNewCollaboratorForm = () => {
    setOpenDialog(true);
    setPopupId(0);
  }

  const closeDailogHandler = (anythingChanged) => {
    setOpenDialog(false);
    if (anythingChanged) {
      loadDocumentDetails();
    }
  }

  const loadDocumentDetails = () => {
    if (isNotEmpty(data)) {
      setIsLoading(true);
      if (props.isSharePreview) {
        getDocumentDetailsShareLink({
          documentId: data?.id,
          id: props?.shareData?.id,
          share_id: props?.shareData?.share_id
        }).then((res) => {
          setIsLoading(false);
          setDocument(res?.data?.document);
        }).catch(err => {
          console.log(err);
          setIsLoading(false);
          setToast((prev) => ({
            ...prev,
            isOpen: true,
            isSuccess: true,
            message: MessageConstants.DOCUMENT_DETAILS_LOAD_FAILED
          }));
        });
      } else {
        getDocumentDetails({
          documentId: data?.id
        }).then((res) => {
          setIsLoading(false);
          setDocument(res?.data?.document);
        }).catch(err => {
          console.log(err);
          setIsLoading(false);
          setToast((prev) => ({
            ...prev,
            isOpen: true,
            isSuccess: true,
            message: MessageConstants.DOCUMENT_DETAILS_LOAD_FAILED
          }));
        });
      }
    }
  }

  useEffect(() => {
    loadDocumentDetails();
  }, [data]);

  return (
    <>
      {toast.isOpen && <Toast message={toast.message} isError={toast.isError} isSuccess={toast.isSuccess} closeToast={closeToast} />}
      <Card className={`document-detail-card ${showOptions ? 'with-options' : 'without-options'} ${classes.root}`} variant="outlined">

        {
          isLoading ? <>

            <CircularProgress className="mb-15" color="primary" size={35} />

          </> : <>

            <CardContent>

              <Typography
                className={classes.title}
                color="textPrimary"
                variant="h6"
                component="h6"
              >
                <Box className="document-detail-card-title">
                  <img src={getImageFromMimeType(document?.file_type, document?.is_folder)} />
                  <span>{document.name}</span>
                </Box>
              </Typography>

              <Typography className={classes.position} color="textSecondary">
                {
                  (isNotEmpty(document?.content_categories) && document?.content_categories != '-') ?
                    document.content_categories.toString().split(',').map(category => <span key={category} className="badge badge-success my-2">{category}</span>)
                    : ''
                }
              </Typography>

              {
                (isNotEmpty(document?.is_default) && document?.is_default.toString() === "false") ? <>

                  <Box>

                    {
                      showOptions ? <>
                        {
                          canEdit() && !isSharePreview && <Button onClick={onEditClick} title="Rename">
                            <EditOutlinedIcon />
                          </Button>
                        }

                        {
                          isDocumentOwner() && !isSharePreview && <Button title="Get Public Link" onClick={(e) => {
                            e.preventDefault()
                            onGetLink(document.id)
                          }}>
                            <InsertLinkIcon />
                          </Button>
                        }
                      </> : null
                    }

                    {
                      !document?.is_folder && <>

                        {
                          haveViewer(document?.file_type, document?.name) ? <>

                            <Button onClick={onViewClick} title="View">
                              <VisibilityIcon />
                            </Button>

                          </> : null
                        }

                        {
                          showOptions ? <>
                            {
                              canBeSentToSign(document?.file_type) && !isSharePreview ? <>

                                <Button onClick={onSignClick} title="Sign">
                                  <ShareOutlined />
                                </Button>

                              </> : null
                            }
                          </> : null
                        }

                        <Button onClick={onDownloadClick} title="Download">
                          <GetApp />
                        </Button>

                      </>
                    }

                    {
                      showOptions ? <>
                        {
                          canDelete() && !isSharePreview && <Button onClick={onDeleteClick} title="Delete">
                            <DeleteOutlineOutlinedIcon />
                          </Button>
                        }

                        {
                          !isSharePreview ? <>
                            <Button onClick={onLogClick} title="Audit Logs">
                              <UpdateIcon />
                            </Button>
                          </> : null
                        }
                      </> : null
                    }

                  </Box>

                </> : <></>
              }

              {
                showOptions ? <>

                  <TabContext value={tabValue.toString()}>

                    <AppBar position="static">
                      <Tabs
                        value={tabValue}
                        centered={true}
                        onChange={handleTabChange}
                        aria-label="simple tabs example"
                      >
                        <Tab
                          style={{ color: "#ffffff", fontWeight: "bold" }}
                          label="Collaborators"
                          {...a11yProps(0)}
                        />

                        {
                          !document?.is_folder &&
                          <Tab
                            style={{ color: "#ffffff", fontWeight: "bold" }}
                            label="Version History"
                            {...a11yProps(1)}
                          />

                        }

                      </Tabs>

                    </AppBar>

                    <TabPanel value={"0"} key={0}>

                      <div className="users-list-wrapper">

                        {canManageCollaborators() &&
                          <div key={0} className="users-list-item add-new">
                            <Typography onClick={openNewCollaboratorForm}>
                              <PersonAddIcon />Click to add New Collaborator
                            </Typography>
                          </div>
                        }

                        {document?.document_collaborators && document?.document_collaborators.length > 0 ?
                          document?.document_collaborators.map((collaborator) => {
                            return (
                              <div key={collaborator?.id} className="users-list-item">
                                <Avatar className={classes.avatar}>
                                  {getInitials(printWithDefault(concatStrings(" ", collaborator?.user?.first_name, collaborator?.user?.last_name), collaborator?.user?.email))}
                                </Avatar>
                                <div className="user-info">
                                  <Typography color="textPrimary" variant="h6" component="h6">{collaborator?.user?.email}</Typography>
                                  <Typography>{collaborator?.role}</Typography>
                                </div>
                                <div className="user-actions">
                                  {isOwner(collaborator) ? <>
                                    <Typography className="current-owner">Owner</Typography>
                                  </> : <>
                                    {
                                      canManageCollaborators() && <Button onClick={e => onEditCollaboratorClick(e, collaborator)}>
                                        <EditOutlinedIcon />
                                      </Button>
                                    }
                                    {
                                      canManageCollaborators() && <Button onClick={e => onDeleteCollaboratorClick(e, collaborator)}>
                                        <DeleteOutlineOutlinedIcon />
                                      </Button>
                                    }
                                  </>
                                  }
                                </div>
                              </div>
                            );
                          }) : <>

                            <div key={-1} className="users-list-item not-found">
                              <Typography>
                                No Collaborators Found
                              </Typography>
                            </div>

                          </>
                        }

                      </div>

                    </TabPanel>

                    <TabPanel value={"1"} key={1}>

                      <div className="version-list-wrapper">

                        {document?.document_versions && document?.document_versions.length > 0 ?
                          document?.document_versions.map((version) => {
                            return (
                              <div key={version?.id} className="version-list-item">
                                <div className="version-info">
                                  <Typography color="textPrimary" variant="h6" component="h6">{version?.comments}</Typography>
                                  <Typography>Version: <span>{version?.version}</span></Typography>
                                  <Typography>Date: <span>{printTime(version?.created_at)}</span></Typography>
                                  <Typography>By: <span>{version?.user?.email}</span></Typography>
                                </div>
                                <div className="versions-actions">
                                  {version.is_current_version.toString() === '1' ? <>
                                    <Typography className="current-version">Current</Typography>
                                  </> : <>
                                    <Button onClick={e => revertToVersion(e, version)}>
                                      <Restore />
                                    </Button>
                                  </>
                                  }
                                </div>
                              </div>
                            );
                          }) : <>

                            <div key={-1} className="version-list-item not-found">
                              <Typography>
                                No History Found
                              </Typography>
                            </div>

                          </>
                        }

                      </div>

                    </TabPanel>


                    <TabPanel value={"2"} key={2}>

                      <div className="logs-list-wrapper">

                        {document?.document_logs && document?.document_logs.length > 0 ?
                          document?.document_logs.map((logModel) => {
                            return (
                              <div key={logModel?.id} className="logs-list-item">
                                <div className="logs-info">
                                  <Typography color="textPrimary" variant="h6" component="h6">{logModel?.particular}</Typography>
                                  <Typography>Date: <span>{printTime(logModel?.created_at)}</span></Typography>
                                  <Typography>By: <span>{logModel?.user?.email}</span></Typography>
                                </div>
                              </div>
                            );
                          }) : <>

                            <div key={-1} className="logs-list-item not-found">
                              <Typography>
                                No Logs Found
                              </Typography>
                            </div>

                          </>
                        }

                      </div>

                    </TabPanel>


                  </TabContext>

                </> : null
              }

            </CardContent>

          </>
        }

      </Card>


      <AddCollaborators
        open={openDialog}
        handleClose={closeDailogHandler}
        popupId={popupId}
        document={document}
      />

      <EdiCollaborator
        open={openEditCollaboratorDialog}
        handleClose={closeCollaboratorDailogHandler}
        collaborator={collaborator}
        document={document}
      />

      <RenameDocument
        open={openRenameDocument}
        handleClose={closeRenameDialogHanlder}
        documentId={document?.id}
        documentName={document?.name}
      />

      <AuditLog
        open={openLogModal}
        handleClose={closeLogDialogHanlder}
        documentLogs={document?.document_logs}
      />

      <SignTypeConfirmation
        open={openSignDocument}
        handleClose={closeSignDialogHanlder}
        document={document}
      />

    </>

  );
}
