import { Box, Grid, makeStyles, Paper } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import InfoPaper from "../components/InfoPaper";
import SignDocumentStepper from "../components/SignDocumentStepper";
import { getStatus } from "../services/api.service";
import { useAuth } from "../hooks";

const useStyles = makeStyles((theme) => {
  return {
    paper: {
      padding: theme.spacing(1),
      margin: theme.spacing(2),
      backgroundColor: 'transparent',
    },
    content: {
      display: "flex",
      width: `calc(100% - ${300}px)`,
      marginLeft: 280,
      minHeight: "88vh",
    },
  };
});

function SignDocument() {
  const classes = useStyles();
  const { user } = useAuth();

  useEffect(() => {
    document.title = `TruDoc | Sign a Document`;
  }, []);

  return (
    <Box className={classes.content}>
      <Grid
        container
        spacing={0}
        // direction="column"
        alignItems="center"
        justify="center"
      >
        <Grid item xs={12}>
          <Paper className={classes.paper} elevation={0}>
            <SignDocumentStepper />
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}

export default SignDocument;
