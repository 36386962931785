import { useState, useEffect, useCallback } from "react";
import { TitleEscrowFactory } from "@govtechsg/token-registry";


export const useTitleEscrowContract = (
  provider,
  tokenRegistry,
  tokenId
) => {
  const [titleEscrow, setTitleEscrow] = useState();
  const [documentOwner, setDocumentOwner] = useState();

  const updateTitleEscrow = useCallback(async () => {
    console.log(tokenRegistry);
    console.log(tokenId);
    if (!tokenRegistry || !tokenId) return;
    const titleEscrowAddress = await tokenRegistry.ownerOf(tokenId);
    console.log(titleEscrowAddress);
    setDocumentOwner(titleEscrowAddress);    
    const instance = TitleEscrowFactory.connect(titleEscrowAddress, provider);
    console.log(instance);
    setTitleEscrow(instance);
  }, [provider, tokenId, tokenRegistry]);

  useEffect(() => {
    updateTitleEscrow();
    return () => {
      setTitleEscrow(undefined);
      setDocumentOwner(undefined);
    };
  }, [updateTitleEscrow, tokenId, provider]);

  return { titleEscrow, updateTitleEscrow, documentOwner };
};
