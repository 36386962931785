import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Box, Grid, Typography, TextField, InputAdornment, Button } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import ContactCard from "../../components/contacts/ContactCard";
import DetailedCard from '../../components/contacts/DetailedCard';
import { addUser, blockUnblockUser, deleteUser, updateUser } from '../../services/admin.service';
import AddOrEditModal from '../../components/admin/contacts/AddOrEdit';
import AdminLayout from './Admin.layout';
import { GetContacts, SelectContactAction } from "../../redux/actions/Contacts.action";
import DeleteModal from '../DeleteModal';
import Toast from '../../utils/Toast';
import { useAuth } from "../../hooks";
import { MessageConstants } from '../../utils/MessageConstants';
import BlockUnblockModal from '../../components/admin/contacts/BlockUnblockModal';
import ChangePasswordModal from '../../components/admin/contacts/ChangePasswordModal';
import { resetPassword } from '../../services/auth.service';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "88vh",
  },
  filterBar: {
    marginTop: 20,
    marginBottom: 30
  },
  searchBar: {
    width: 300
  },
  button: {
    width: 220,
    height: 45,
    borderRadius: 25,
    marginLeft: 10,
    backgroundColor: theme.palette.admin.main,
    '&:hover': {
      backgroundColor: theme.palette.admin.main,
    }
  },
  buttonText: {
    fontWeight: "bold",
    fontSize: 14,
    letterSpacing: "0.15em",
    color: "#FFFDFD",
  },
  actionBar: {
    marginTop: 50,
    textAlign: "center",
  },
  heading: {
    textTransform: 'uppercase',
    fontWeight: '500'
  }
}));

const Contacts = () => {
  const initialValues = {
    username: '',
    email: '',
    role: '',
    jobTitle: ''
  }

  const toastInitialValues = {
    isOpen: false,
    isSuccess: false,
    isError: false,
    message: ""
  }

  const classes = useStyles();
  const dispatch = useDispatch();
  const [data, setData] = useState();
  const [toast, setToast] = useState(toastInitialValues);
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [blockDialog, setBlockDialog] = useState(false);
  const [unBlockDialog, setunBlockDialog] = useState(false);
  const [changePasswordDialog, setChangePasswordDialog] = useState(false);
  const [popupId, setPopupId] = useState(0);
  const { contacts, selected } = useSelector((state) => state.contact);
  const [state, setState] = useState(initialValues);
  const { removeUser, user } = useAuth();

  const setStateData = (type, value) => {
    setState((prev) => ({
      ...prev,
      [type]: value,
    }));
  };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  useEffect(() => {
    dispatch(GetContacts());
  }, []);

  useEffect(() => {
    if (selected !== null && popupId === 1) {
      setState((prev) => ({
        ...prev,
        username: selected.username,
        email: selected.email,
        role: selected.role,
        jobTitle: selected.job_title
      }));
    }
  }, [selected, popupId])

  useEffect(() => {
    if (popupId === 0) {
      setState((prev) => ({
        ...prev,
        ...initialValues
      }));
    }
  }, [popupId])

  useEffect(() => {
    setData(contacts?.data);
  }, [contacts])

  const closeDailogHandler = () => {
    setOpenDialog(false);
    setState((prev) => ({
      ...prev,
      ...initialValues
    }));
    setPopupId(0);
  }

  const editHandler = () => {
    setOpenDialog(true)
    setPopupId(1);
  }

  const addHandler = () => {
    setOpenDialog(true)
    setPopupId(0);
  }

  const deleteHandler = () => {
    setDeleteDialog(true);
  }

  const handleSearch = (value) => {
    setData([...contacts?.data].filter(item => item?.username?.toLowerCase()?.includes(value?.toLowerCase())))
  }

  const closeToast = () => {
    return setToast((prev) => ({
      ...prev,
      isOpen: false,
      isSuccess: false,
      isError: false,
      message: ""
    }));
  };

  const createUser = () => {
    if (state.email == "" || state.jobTitle == "" || state.role == "") {
      setToast((prev) => ({
        ...prev,
        isOpen: true,
        isError: true,
        message: MessageConstants.FIELD_REQUIRED
      }));
      return;
    }
    if (!validateEmail(state.email)) {
      setToast((prev) => ({
        ...prev,
        isOpen: true,
        isError: true,
        message: MessageConstants.INVALID_EMAIL
      }));
      return;
    }
    if (popupId === 0) {
      addUser(state).then(res => {
        if (res && res.status === 200) {
          setState(() => ({
            ...initialValues
          }));
          setData();
          setToast((prev) => ({
            ...prev,
            isOpen: true,
            isSuccess: true,
            message: MessageConstants.USER_ADDED_SUCCESS
          }));
          dispatch(GetContacts());
          setOpenDialog(false);
        }
      }).catch(e => {
        const Error = JSON.parse(e.message);
        let msg = MessageConstants.USER_ADDED_FAILED;
        if (Error.status === 409)
          msg = MessageConstants.EMAIL_ALREADY_EXIST;
        setToast((prev) => ({
          ...prev,
          isOpen: true,
          isError: true,
          message: msg
        }));
      })
    }
    else if (popupId === 1) {
      const obj = {
        ...state,
        userId: selected.user_id,
        id: selected.id,
        corporateId: selected.corporate_id
      }
      updateUser(obj).then(res => {
        if (res && res.status === 200) {
          setData();
          setToast((prev) => ({
            ...prev,
            isOpen: true,
            isSuccess: true,
            message: MessageConstants.USER_UPDATE_SUCCESS
          }));
          dispatch(GetContacts());
          setOpenDialog(false);
        }
      }).catch(e => {
        setToast((prev) => ({
          ...prev,
          isOpen: true,
          isError: true,
          message: MessageConstants.USER_UPDATE_FAILED
        }));
      })
    }
  }

  const deleteUserHandler = () => {
    const obj = {
      userId: selected.user_id,
      id: selected.id,
      email: selected.email
    }
    deleteUser(obj).then(res => {
      if (res && res.status === 200) {
        dispatch(SelectContactAction(null));
        setData();
        setToast((prev) => ({
          ...prev,
          isOpen: true,
          isSuccess: true,
          message: MessageConstants.USER_DELETE_SUCCESS
        }));
        dispatch(GetContacts());
        setDeleteDialog(false);
      }
    }).catch(e => {
      setToast((prev) => ({
        ...prev,
        isOpen: true,
        isError: true,
        message: MessageConstants.USER_DELETE_FAILED
      }));
    })
  }

  const blockUnblockHandler = (id) => {
    const key = id === 0 ? 0 : 1;
    const obj = {
      key: key,
      email: selected.email
    }
    blockUnblockUser(obj).then(res => {
      if (res && res.status === 200) {
        const msg = key === 1 ? MessageConstants.USER_ACCOUNT_ENABLED_SUCCESS
          : MessageConstants.USER_ACCOUNT_DISABLED_SUCCESS
        dispatch(SelectContactAction(null));
        setToast((prev) => ({
          ...prev,
          isOpen: true,
          isSuccess: true,
          message: msg
        }));
        dispatch(GetContacts());
        setBlockDialog(false);
        setunBlockDialog(false);
      }
    }).catch(e => {
      const msg = key === 1 ? MessageConstants.USER_ACCOUNT_ENABLED_FAILED
        : MessageConstants.USER_ACCOUNT_DISABLED_FAILED
      setToast((prev) => ({
        ...prev,
        isOpen: true,
        isError: true,
        message: msg
      }));
    })
  }

  const changePasswordHandler = (values) => {
    const obj = {
      username: selected.email,
      password: values.newPassword
    }
    resetPassword(obj).then(res => {
      if (res && res.status === 200 && res?.data?.message) {
        dispatch(SelectContactAction(null));
        setChangePasswordDialog(false);
        setToast((prev) => ({
          ...prev,
          isOpen: true,
          isSuccess: true,
          message: MessageConstants.PASSWORD_UPDATE_SUCCESS
        }));
      }
    }).catch((e) => {
      const Error = JSON.parse(e.message);
        let msg = MessageConstants.PASSWORD_UPDATE_FAILED;
        if (Error.status === 400)
          msg = Error.message;
        setToast((prev) => ({
          ...prev,
          isOpen: true,
          isError: true,
          message: msg
        }));
      })
  }

  return (
    <AdminLayout>
      {toast.isOpen && <Toast message={toast.message} isError={toast.isError} isSuccess={toast.isSuccess} closeToast={closeToast} />}
      <Grid className={classes.filterBar} container direction="row" alignItems="center" justify="space-between">
        <Typography variant="h5" gutterBottom className={classes.heading}>Contacts</Typography>
        <Box display="flex" alignItems="center">
          <TextField
            className={classes.searchBar}
            label="Search by name"
            variant="outlined"
            color="primary"
            onChange={e => handleSearch(e.target.value)}
            InputProps={{
              endAdornment: <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>,
            }}
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.button}
            size="large"
            onClick={addHandler}
          >
            <Typography className={classes.buttonText}>Add New Contact</Typography>
          </Button>
        </Box>
      </Grid>

      <Grid container>
        <Grid container item xs={selected ? 8 : 12}>
          <Grid container item xs={selected && 12} spacing={3}>
            {data && data.map(card => {
              if (user?.email !== card.email) {
                return (
                  <Grid container item xs={selected ? 4 : 3} key={card.id}>
                    <ContactCard
                      data={card}
                      isSelected={selected && selected.id === card.id}
                    />
                  </Grid>
                )
              }
            })}
          </Grid>
        </Grid>

        {selected &&
          <Grid container item xs={4}>
            <Box width="100%" height="calc(100vh - 200px)">
              <DetailedCard
                user="admin"
                onEdit={editHandler}
                onDelete={deleteHandler}
                onBlock={() => setBlockDialog(true)}
                onUnBlock={() => setunBlockDialog(true)}
                onChangePassowrd={() => setChangePasswordDialog(true)}
              />
            </Box>
          </Grid>
        }
      </Grid>

      <AddOrEditModal
        open={openDialog}
        handleClose={closeDailogHandler}
        data={selected}
        handleChange={setStateData}
        state={state}
        handleSubmit={createUser}
        popupId={popupId}
      />

      <DeleteModal
        open={deleteDialog}
        handleClose={() => setDeleteDialog(false)}
        handleSubmit={deleteUserHandler}
        message="Are you sure you want to delete user"
      />
      
      <BlockUnblockModal
        id={0}
        open={blockDialog}
        handleClose={() => setBlockDialog(false)}
        handleSubmit={() => blockUnblockHandler(0)}
      />
      <BlockUnblockModal
        id={1}
        open={unBlockDialog}
        handleClose={() => setunBlockDialog(false)}
        handleSubmit={() => blockUnblockHandler(1)}
      />

      <ChangePasswordModal
        open={changePasswordDialog}
        handleClose={() => setChangePasswordDialog(false)}
        handleSubmit={changePasswordHandler}
      />
    </AdminLayout>
  )
}

export default Contacts;
