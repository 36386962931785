import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Slider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@material-ui/core";
import { makeStyles, styled } from "@material-ui/core/styles";
import Toast from "../../utils/Toast";
import RoundProgresBar from "../charts/RoundProgressBar";
import { UNSDG_GOALS, UNSDG_GOAL_CATEGORIES } from "../../utils/unsdg-goals";
import { Close } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { goalsParseCategories } from "../../utils/Generic";

const useStyles = makeStyles((theme) => ({
  root: {
    width: `calc(100% - ${300}px)`,
    marginLeft: 280,
    minHeight: "88vh",
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({

}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  height: '118px',
  border: 'none',
  '&:nth-of-type(even)': {
    backgroundColor: '#334bc120',
    border: 'none'
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const Overview = (props) => {

  const { goals, setGoals, companyDocuments, canSave } = props;

  const [modalOpen, setModalOpen] = useState(false);

  const [modalTitle, setModalTitle] = useState('');
  const [modalObjective, setModalObjective] = useState('');

  const [modalGoal, setModalGoal] = useState(-1);
  const [modalGoalObjectiveIndex, setModalGoalObjectiveIndex] = useState(-1);
  const [modalObjectiveType, setModalObjectiveType] = useState('');

  let history = useHistory();

  const classes = useStyles();

  const toastInitialValues = {
    isOpen: false,
    isSuccess: false,
    isError: false,
    message: ""
  }

  const [toast, setToast] = useState(toastInitialValues);

  const closeToast = () => {
    return setToast((prev) => ({
      ...prev,
      isOpen: false,
      isSuccess: false,
      isError: false,
      message: ""
    }));
  };

  const showError = (errorMsg) => {
    setToast((prev) => ({
      ...prev,
      isOpen: true,
      isError: true,
      isSuccess: false,
      message: errorMsg
    }));
  }

  const showSuccess = (succMsg) => {
    setToast((prev) => ({
      ...prev,
      isOpen: true,
      isError: false,
      isSuccess: true,
      message: succMsg
    }));
  }

  const handleCloseModal = () => {
    setModalTitle('');
    setModalObjective('');
    setModalObjectiveType('');
    setModalGoal(-1);
    setModalGoalObjectiveIndex(-1);
    setModalOpen(false);
  }

  const addMoreObjective = (goal) => {
    let tempGoals = { ...goals };
    if (tempGoals.hasOwnProperty(goal)) {
      console.log("obbb", tempGoals[goal].objectives);
      tempGoals[goal].objectives.push({
        impact: "",
        status: "",
        plan: ""
      });
    }
    setGoals(tempGoals);
  }

  const removeObjective = (goal, indexToRemove) => {
    if (window.confirm("Are you sure you want to remove it?") === true) {
      let tempGoals = { ...goals };
      if (tempGoals.hasOwnProperty(goal)) {
        let goalObjectives = tempGoals[goal].objectives;
        if (goalObjectives.length > 1) {
          goalObjectives = goalObjectives.slice(0, indexToRemove).concat(goalObjectives.slice(indexToRemove + 1));
          tempGoals[goal].objectives = goalObjectives;
          setGoals(tempGoals);
        }
      }
    }
  }

  const handleInputClick = (goal, index, type) => {
    let tempGoals = { ...goals };
    if (tempGoals.hasOwnProperty(goal)) {
      let goalObjectives = tempGoals[goal].objectives;
      if (goalObjectives.hasOwnProperty(index)) {
        let goalObjectiveData = goalObjectives[index];

        setModalTitle(UNSDG_GOALS[goal].title + " - " + type);
        setModalObjective(goalObjectiveData[type]);
        setModalObjectiveType(type);
        setModalGoal(goal);
        setModalGoalObjectiveIndex(index);

        setModalOpen(true);
      }
    }
  }

  const saveModalObjective = () => {
    let tempGoals = { ...goals };
    if (tempGoals.hasOwnProperty(modalGoal)) {
      let goalObjectives = tempGoals[modalGoal].objectives;
      if (goalObjectives.hasOwnProperty(modalGoalObjectiveIndex)) {
        let goalObjectiveData = goalObjectives[modalGoalObjectiveIndex];
        if (goalObjectiveData.hasOwnProperty(modalObjectiveType)) {
          tempGoals[modalGoal].objectives[modalGoalObjectiveIndex][modalObjectiveType] = modalObjective;
          setGoals(tempGoals);
          handleCloseModal();
        }
      }
    }
  }


  const handleGoBack = () => {
    history.push("/sustainability/track");
  }

  return (
    <>

      {toast.isOpen && <Toast message={toast.message} isError={toast.isError} isSuccess={toast.isSuccess} closeToast={closeToast} />}

      <div className='objective_main undsg_main'>
        <h1 className="text-gradient">Your Sustainability Journey</h1>
        <Box>

          {
            Object.keys(goals).length <= 0 ? <>
              <p className="no-results">No Goals Found</p>
            </> : null
          }

          {
            goalsParseCategories(goals).map((category) => {
              let categoryItem = UNSDG_GOAL_CATEGORIES[category];
              return <>

                <div className="card mb-25px goal-category-card">
                  <div className="card-header">
                    <h4 className="card-title">{categoryItem.title}</h4>
                  </div>
                  <div className="card-body">
                    <div className="goal-category-box">

                      <TableContainer sx={{ border: 'none' }} component={Paper}>
                        <Table sx={{ minWidth: 700 }} aria-label="customized table">
                          <TableHead>
                            <TableRow>
                              <StyledTableCell align="center">Goal</StyledTableCell>
                              <StyledTableCell align="center">Relevance</StyledTableCell>
                              <StyledTableCell align="center">
                                <div className="input-item-titles">
                                  <span>Impact</span>
                                  <span>Action Plan</span>
                                  <span>Progress</span>
                                </div>
                              </StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>

                            {
                              Object.keys(goals).map((goal) => {
                                goal = parseInt(goal);
                                return <>
                                  {
                                    (categoryItem.goals.includes(goal) && goals[goal]?.relevance.value > 0 && goals[goal].objectives.length > 0) ?
                                      <>
                                        <StyledTableRow className="unsdg-objective unsdg-output-row">
                                          <StyledTableCell align="center" component="th" scope="row">
                                            <ReactTooltip className="__react-tootltip-theme" id={`goal-tooltip-${goal}`} multiline={true} />
                                            <img data-for={`goal-tooltip-${goal}`} data-tip={UNSDG_GOALS[goal]?.info} src={`/assets/images/sdg-icons/E-WEB-Goal-${goal}.png`} />
                                          </StyledTableCell>
                                          <StyledTableCell align="center">
                                            <Box className="progress-bar-parent">
                                              <RoundProgresBar style={{ stroke: UNSDG_GOALS[goal].color }} size={65} value={goals[goal]?.relevance.value} />
                                              <Typography className="progress-bar-label" variant="caption" component="div" color="textSecondary">{goals[goal]?.relevance.textValue.toString()}</Typography>
                                            </Box>
                                          </StyledTableCell>
                                          <StyledTableCell className="unsdg-objective-goals-inputs" align="center">
                                            {
                                              goals[goal].objectives.map((objective, index) => {
                                                return <>
                                                  <Box className="input-item-main">
                                                    <Paper className="input-item">
                                                      <input onClick={(e) => handleInputClick(goal, index, 'impact')} readOnly={true} placeholder='-' value={objective?.impact} className='card_input' type='text' />
                                                    </Paper>
                                                    <Paper className="input-item">
                                                      <input onClick={(e) => handleInputClick(goal, index, 'plan')} readOnly={true} placeholder='-' value={objective?.plan} className='card_input' type='text' />
                                                    </Paper>
                                                    <Paper className="input-item">
                                                      <Box className="progress-bar-parent">
                                                        <RoundProgresBar style={{ stroke: UNSDG_GOALS[goal].color }} size={65} value={objective?.progress.value} />
                                                        <Typography className="progress-bar-label" variant="caption" component="div" color="textSecondary">{objective?.progress.textValue.toString()}</Typography>
                                                      </Box>
                                                    </Paper>
                                                  </Box>
                                                </>;
                                              }
                                              )}
                                          </StyledTableCell>
                                        </StyledTableRow>
                                      </> : null
                                  }
                                </>
                              })
                            }

                          </TableBody>
                        </Table>
                      </TableContainer>

                    </div>
                  </div>
                </div>

              </>
            })
          }

        </Box>

        <Box className="btn-sustainability-box">
          <div className="left">

          </div>
          <div className="right">
            <button onClick={handleGoBack} className="btn-sustainability primary-out me-10px">
              Back
            </button>
            {
              canSave ? <>
                <button onClick={(e) => props?.onSave()} className="btn-sustainability primary">
                  Save
                </button>
              </> : null
            }
          </div>
        </Box>

      </div>

      <Dialog
        onClose={handleCloseModal}
        fullWidth={true}
        maxWidth='md'
        open={modalOpen}
        className={`theme-base-modal sustainability-layout`}
        keepMounted={false}
        PaperProps={{
          style: { borderRadius: 20, maxHeight: 'calc(100% - 44px)' }
        }}
      >
        <DialogTitle>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span className="capital-case">{modalTitle}</span>
            <IconButton onClick={handleCloseModal} style={{ marginLeft: 'auto' }}>
              <Close />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent dividers >
          <DialogContentText>
            <div className="sus-objective-box">
              <textarea className='sus-objective-field' placeholder='-' value={modalObjective} cols="30" rows="20"></textarea>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>

    </>
  );
};

export default Overview;