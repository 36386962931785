import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, makeStyles, Grid, Paper, Typography, CircularProgress, FormControlLabel, Switch, withStyles, FormGroup, Card, CardContent, Button, InputBase, TextField } from '@material-ui/core';
import AccountTreeOutlinedIcon from '@material-ui/icons/AccountTreeOutlined';
import InsertChartOutlinedIcon from '@material-ui/icons/InsertChartOutlined';
import AccountBalanceWalletOutlinedIcon from '@material-ui/icons/AccountBalanceWalletOutlined';
import AdminLayout from './Admin.layout';
import { getWalletDetails } from '../../redux/actions/Wallet.action';
import { hexToNumber, isNotEmpty } from '../../utils/Generic';
import { metaMaskStates, network, networkChainId, walletTypes } from "./../../constants";
import { useMetaMask } from 'metamask-react';
import Toast from '../../utils/Toast';
import { createWallet, getCorporateSettings, linkWallet, updateWalletType } from '../../services/admin.service';
import { MessageConstants } from '../../utils/MessageConstants';
import { isEmpty } from '@magic-sdk/provider';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 30
  },
  gridItem: {
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 12,
    paddingRight: 12,
    cursor: 'pointer',
  },
  gridItemHeading: {
    textTransform: 'uppercase',
    fontWeight: '600'
  },
  gridItemAccount: {
    color: '#66CFA4',
    fontWeight: '600'
  },
  gridItemDocs: {
    color: '#61D6FB',
    fontWeight: '600',
    marginRight: 10
  },
  gridItemWallet: {
    color: '#FFD074',
    fontWeight: '600',
    wordBreak: 'break-word'
  },
  icon: {
    fontSize: 50,
    marginRight: 20
  },
  root: {
    padding: 10,
  },
  heading: {
    padding: 10
  },
  button: {
    marginTop: 15,
    width: 220,
    height: 45,
    borderRadius: 25,
    backgroundColor: theme.palette.admin.main,
    '&:hover': {
      backgroundColor: theme.palette.admin.main,
    }
  },
  buttonText: {
    fontWeight: "bold",
    fontSize: 14,
    letterSpacing: "0.15em",
    color: "#FFFDFD",
  },
  field: {
    width: 250,
    borderRadius: 10,
    color: theme.palette.admin.main,
  },
  textFieldInput: {
    color: theme.palette.admin.main,
  },
  actionBox: {
    marginTop: 50
  },
  domainContainer: {
    minWidth: '70%',
    width: '70%',
    maxWidth: '100%',
    padding: 20,
    color: 'gray',
    cursor: "pointer",
    margin: '0 auto',
    textTransform: 'uppercase',
    '&:hover': {
      color: theme.palette.admin.main,
    }
  },
  itemUrl: {
    fontSize: 14,
  },
  subHeading: {
    textAlign: 'left'
  }
}));

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "#52d869",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#52d869",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 23,
    height: 23,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
    height: "95%",
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
    display: 'flex',
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "#52d869",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#52d869",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 23,
    height: 23,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
    height: "95%",
  },
  checked: {},
  focusVisible: {},
}))(Switch);


const CssTextField = withStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      borderColor: "#BF85FA",
      color: "#BF85FA",
      "& fieldset": {
        color: "#BF85FA",
        borderColor: "#BF85FA",
      },
      "&:focus": {
        color: "#BF85FA",
        borderColor: "#BF85FA",
      },
      "&:hover fieldset": {
        borderColor: "#BF85FA",
      },
      borderRadius: 10,
    },
  },
})(TextField);

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 10,
    position: 'relative',
    backgroundColor: '#fff',
    border: '1px solid #BF85FA',
    fontSize: 18,
    padding: '18px 14px',
    color: theme.palette.admin.main,
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      backgroundColor: '#fff',
      borderColor: theme.palette.admin.main,
      color: theme.palette.admin.main,
      borderRadius: 10,
    },
  },
}))(InputBase);

const Dashboard = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { wallet } = useSelector(state => state);

  const toastInitialValues = {
    isOpen: false,
    isSuccess: false,
    isError: false,
    message: ""
  }

  const [toast, setToast] = useState(toastInitialValues);

  const [loading, setLoading] = useState(true);
  const [settingsLoading, setSettingsLoading] = useState(true);

  const [wallets, setWallets] = useState([]);

  const [selectedWallet, setSelectedWallet] = useState({});
  let [walletType, setWalletType] = useState(null);


  const { status, connect, account, chainId, ethereum } = useMetaMask();
  const [settings, setSettings] = useState({});

  useEffect(() => {
    dispatch(getWalletDetails());
  }, []);

  useEffect(() => {
    console.log("wallet", wallet);
    if (isNotEmpty(wallet?.settings)) {
      setWallets(wallet?.wallets);
      setSettings(wallet?.settings);
      setLoading(false);
    }
  }, [wallet]);

  useEffect(() => {
    if (isNotEmpty(walletType)) {
      if (wallets.hasOwnProperty(walletType)) {
        setSelectedWallet(wallets[walletType]?.data);
      }
    }
  }, [walletType, wallets]);

  useEffect(() => {
    // console.log("settings", settings);
    setWalletType(settings?.walletType);
  }, [settings]);

  const toggleWalletType = () => {
    let oldWalletType = walletType;
    if (walletType === walletTypes.metamask.value) {
      walletType = walletTypes.trudoc.value;
    } else {
      walletType = walletTypes.metamask.value;
    }
    setWalletType(walletType);
    setLoading(true);
    updateWalletType({
      wallet_type: walletType
    }).then(res => {
      let data = res?.data;
      if (data.success) {
        setLoading(false);
        setToast((prev) => ({
          ...prev,
          isOpen: true,
          isError: false,
          message: MessageConstants.SETTINGS_SAVED
        }));
      } else {
        setLoading(false);
        setToast((prev) => ({
          ...prev,
          isOpen: true,
          isError: true,
          message: data.message
        }));
        setWalletType(oldWalletType);
      }
    }).catch(e => {
      setLoading(false);
      setToast((prev) => ({
        ...prev,
        isOpen: true,
        isError: true,
        message: MessageConstants.FAILED_TO_LOAD
      }));
      setWalletType(oldWalletType);
    });
  }


  const connectToCorrectNetwork = () => {
    let chainIdString = hexToNumber(chainId).toString();
    let requiredChainId = networkChainId;
    requiredChainId = requiredChainId.toString();

    if (chainIdString === requiredChainId) {
      return true;
    } else {
      return false;
    }
  }

  const linkAccount = () => {
    if (account != null) {
      let correctNetworkCon = connectToCorrectNetwork();
      if (correctNetworkCon) {
        setLoading(true);
        linkWallet({
          address: account,
          chainId: hexToNumber(chainId),
          type: walletTypes.metamask.value,
          network: network
        }).then(res => {
          let data = res.data;
          setLoading(false);
          if (data.success) {
            // setToast((prev) => ({
            //   ...prev,
            //   isOpen: true,
            //   isError: false,
            //   message: MessageConstants.WALLET_CONNECTED
            // }));  
            setLoading(true);
            dispatch(getWalletDetails());
          } else {
            setToast((prev) => ({
              ...prev,
              isOpen: true,
              isError: true,
              message: data.error
            }));
          }
        }).catch(e => {
          setLoading(false);
          setToast((prev) => ({
            ...prev,
            isOpen: true,
            isError: true,
            message: MessageConstants.FAILED_TO_LOAD
          }));
        });

      } else {
        setToast((prev) => ({
          ...prev,
          isOpen: true,
          isError: true,
          message: 'Network is incorrect, please connect to ' + network + ' network'
        }));
      }
    }
  }

  const createTruDocWallet = () => {
    setLoading(true);
    createWallet({
      type: walletType
    }).then(res => {
      let data = res.data;
      setLoading(false);
      if (data.success) {
        setLoading(true);
        dispatch(getWalletDetails());
      } else {
        setToast((prev) => ({
          ...prev,
          isOpen: true,
          isError: true,
          message: data.error
        }));
      }
    }).catch(e => {
      setLoading(false);   
      setToast((prev) => ({
        ...prev,
        isOpen: true,
        isError: true,
        message: MessageConstants.FAILED_TO_LOAD
      }));
    });
  }

  const closeToast = () => {
    return setToast((prev) => ({
      ...prev,
      isOpen: false,
      isSuccess: false,
      isError: false,
      message: ""
    }));
  };

  return (

    <>
      {toast.isOpen && <Toast message={toast.message} isError={toast.isError} isSuccess={!toast.isSuccess} closeToast={closeToast} />}

      <AdminLayout>

        {
          loading ? <>

            <Grid container>
              <Grid container item xs={12}>
                <Box width="100%" className="text-center">
                  <CircularProgress color="primary" size={50} />
                </Box>
              </Grid>
            </Grid>

          </> : <>

            {
              settings?.canChangeWalletType ? <>
                <Typography component="div">
                  <Grid
                    id="walletSwitchMain"
                    container
                    alignItems="center"
                    spacing={1}
                    justify="flex-end"
                  >

                    <Typography id="walletSwitch" component="div">
                      <Grid
                        component="label"
                        container
                        alignItems="center"
                        spacing={1}
                        justify="flex-end"
                      >
                        <Grid item>

                          <FormGroup>
                            <Typography component="div">
                              <Grid component="label" container alignItems="center" spacing={1}>
                                <Grid item>TruDoc</Grid>
                                <Grid item>
                                  <AntSwitch checked={walletType === walletTypes.metamask.value} onChange={toggleWalletType} name="checkedC" />
                                </Grid>
                                <Grid item>MetaMask</Grid>
                              </Grid>
                            </Typography>
                          </FormGroup>

                        </Grid>
                      </Grid>
                    </Typography>

                  </Grid>
                </Typography>
              </> : null
            }

            {
              isNotEmpty(selectedWallet?.wallet) ?
                <>
                  <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="flex-start"
                    spacing={5}
                    className={classes.root}
                  >
                    <Grid item xs>
                      <Paper className={classes.gridItem}>
                        <Box display="flex" alignItems="center">
                          <Typography variant="h1">
                            <AccountTreeOutlinedIcon className={classes.icon} />
                          </Typography>
                          <Box>
                            <Typography variant="h6" className={classes.gridItemHeading}>Account Status</Typography>
                            <Typography variant="h4" className={classes.gridItemAccount}>{selectedWallet?.status}</Typography>
                          </Box>
                        </Box>
                      </Paper>
                    </Grid>
                    <Grid item xs>
                      <Paper className={classes.gridItem}>
                        <Box display="flex" alignItems="center">
                          <Typography variant="h1">
                            <InsertChartOutlinedIcon className={classes.icon} />
                          </Typography>
                          <Box>
                            <Typography variant="h6" className={classes.gridItemHeading}>Document Status</Typography>
                            <Box display="flex" alignItems="center">
                              <Typography variant="h4" className={classes.gridItemDocs}>{selectedWallet?.state?.transaction_count}</Typography>
                              <Typography>Docs Signed</Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Paper>
                    </Grid>
                  </Grid>


                  <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="flex-start"
                    spacing={5}
                    className={classes.root}
                  >
                    <Grid item xs>
                      <Paper className={classes.gridItem}>
                        <Box display="flex" alignItems="center">
                          <Typography variant="h1">
                            <AccountBalanceWalletOutlinedIcon className={classes.icon} />
                          </Typography>
                          <Box>
                            <Typography variant="h6" className={classes.gridItemHeading}>Wallet</Typography>
                            <Box display="flex" alignItems="center">
                              <Typography variant="h4" className={classes.gridItemWallet}>{selectedWallet?.state?.balance}</Typography>
                              <Typography>ethers</Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Paper>
                    </Grid>
                    <Grid item xs>
                      <Paper className={classes.gridItem}>
                        <Box display="flex" alignItems="center">
                          <Typography variant="h1">
                            <AccountBalanceWalletOutlinedIcon className={classes.icon} />
                          </Typography>
                          <Box>

                            {
                              walletType === walletTypes.metamask.value ? <>
                                <Typography variant="h6" className={classes.gridItemHeading}>MetaMask Address</Typography>
                                <Box display="flex" alignItems="center">
                                  <Typography variant="h4" className={classes.gridItemWallet}>{selectedWallet?.wallet ? selectedWallet.wallet : null}</Typography>
                                </Box>
                              </> : <>
                                <Typography variant="h6" className={classes.gridItemHeading}>TruDoc Address</Typography>
                                <Box display="flex" alignItems="center">
                                  <Typography variant="h4" className={classes.gridItemWallet}>{selectedWallet?.wallet ? "0x" + selectedWallet.wallet : null}</Typography>
                                </Box>
                              </>
                            }



                          </Box>
                        </Box>
                      </Paper>
                    </Grid>
                  </Grid>
                </> : null
            }


            {
              (walletType === walletTypes.trudoc.value && !isNotEmpty(wallets?.trudoc) && isEmpty(selectedWallet.wallet)) ?
                <>

                  <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    spacing={5}
                    className={classes.domainBox}
                  >
                    <Grid item xs={12} align="center">

                      <Card>
                        <CardContent>
                          <Typography variant="h6" textTransform="uppercase" className={classes.heading} align="left">
                            Trudoc Wallet
                          </Typography>

                          <Grid
                            container
                            direction="row"
                            alignItems="center"
                            className={classes.root}
                            spacing={2}
                          >

                            <Grid item xs={12} align="left">


                              <Typography className={classes.subHeading + ' '}>Wallet is not created yet, click below button to create it now.</Typography>

                              <Box display="flex" alignItems="center" justifyContent="" className={classes.listItem + ' mb-15px'}>
                                <Button
                                  type="submit"
                                  variant="contained"
                                  color="primary"
                                  className={classes.button}
                                  size="large"
                                  disabled={loading}
                                  onClick={(e) => createTruDocWallet()}
                                >
                                  <Typography className={classes.buttonText}> {!loading ? 'Create Now' : ''} {loading && <CircularProgress />}</Typography>
                                </Button>
                              </Box>


                            </Grid>

                          </Grid>

                        </CardContent>
                      </Card>

                    </Grid>
                  </Grid>

                </> : null
            }


            {
              walletType === walletTypes.metamask.value && !isNotEmpty(wallets?.metamask) ?
                <>

                  <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    spacing={5}
                    className={classes.domainBox}
                  >
                    <Grid item xs={12} align="center">

                      <Card>
                        <CardContent>
                          <Typography variant="h6" textTransform="uppercase" className={classes.heading} align="left">
                            MetaMask
                          </Typography>

                          <Grid
                            container
                            direction="row"
                            alignItems="center"
                            className={classes.root}
                            spacing={2}
                          >

                            <Grid item xs={12} align="left">


                              {
                                isNotEmpty(wallets?.metamask) ?
                                  <>
                                    <Typography className={classes.subHeading + ' mb-10px'}>Great, Your account is already Linked.</Typography>
                                  </> : <>

                                    {
                                      status == metaMaskStates.unavailable ? <>

                                        <Typography className={classes.subHeading}>MetaMask not available, your browser may not support it or it is not yet installed in your browser.</Typography>

                                      </> : null
                                    }

                                    {
                                      status == metaMaskStates.connected ? <>

                                        {
                                          connectToCorrectNetwork() ?
                                            <>

                                              {
                                                isNotEmpty(wallets?.metamask) ?
                                                  <>

                                                  </> : <>

                                                    <Typography className={classes.subHeading + ' '}>Your account is not linked. Click button below to link to connected account.</Typography>

                                                    <Box display="flex" alignItems="center" justifyContent="" className={classes.listItem + ' mb-15px'}>
                                                      <Button
                                                        type="submit"
                                                        variant="contained"
                                                        color="primary"
                                                        className={classes.button}
                                                        size="large"
                                                        disabled={loading}
                                                        onClick={(e) => linkAccount()}
                                                      >
                                                        <Typography className={classes.buttonText}> {!loading ? 'Link Now' : ''} {loading && <CircularProgress />}</Typography>
                                                      </Button>
                                                    </Box>

                                                    <Typography className={classes.subHeading}>MetaMask is connected. Connected account: <strong>{account}</strong>.</Typography>

                                                  </>
                                              }

                                            </> : <>
                                              <Typography className={classes.subHeading}>MetaMask is connected to incorrect network.</Typography>
                                            </>
                                        }

                                      </> : null
                                    }

                                    {
                                      status == metaMaskStates.notConnected ? <>

                                        <Typography className={classes.subHeading}>MetaMask not connected, click below button to connect now.</Typography>

                                        <Box display="flex" alignItems="center" justifyContent="" className={classes.listItem}>
                                          <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            className={classes.button}
                                            size="large"
                                            disabled={loading}
                                            onClick={(e) => connect()}
                                          >
                                            <Typography className={classes.buttonText}> {!loading ? 'Connect Account' : ''} {loading && <CircularProgress />}</Typography>
                                          </Button>
                                        </Box>

                                      </> : null
                                    }

                                    {
                                      (status == metaMaskStates.connecting || status == metaMaskStates.initializing) ? <>

                                        <Box width="100%" className="text-left">
                                          <CircularProgress color="primary" size={50} />
                                        </Box>

                                      </> : null
                                    }


                                  </>
                              }


                            </Grid>

                          </Grid>

                        </CardContent>
                      </Card>

                    </Grid>
                  </Grid>

                </> : null
            }


          </>

        }

      </AdminLayout>
    </>

  )
}

export default Dashboard;
