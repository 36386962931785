import React from "react";

const UnsdgObjectiveInfo = (props) => {

    const { subject, subjects, showMulti = false } = props;

    return (
        <>
            <div className={`objective-box-info ${subject?.category?.id}`}>
                <p className="title">Goal</p>
                <p className="objective"> <span className="number">{subject.goalNumber}</span>#{subject?.goal?.title} <strong>{subject?.category?.id}</strong></p>
                <p className="title">Material Topic(s)</p>
                <ul>
                    {
                        showMulti === false ? <>
                            <li className="list-item-m single">{subject?.title}</li>
                        </> : <>
                            {
                                Object.keys(subjects).map((key) => {
                                    let subjectsItem = subjects[key];
                                    return <>
                                        <li className="list-item-m multi">{subjectsItem?.title}</li>
                                    </>
                                })
                            }
                        </>
                    }
                </ul>
            </div>
        </>
    );
};

export default UnsdgObjectiveInfo;