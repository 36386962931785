import React, { useState } from "react";
import { Grid, makeStyles, withStyles, Box } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import MuiAlert from "@material-ui/lab/Alert";
import { resetForgotPassword } from "../../services/auth.service";
import Toast from "../../utils/Toast";
import { useHistory, useParams } from "react-router-dom";
import LoaderButton from "../../utils/Loader/LoaderButton";
import { MessageConstants } from "../../utils/MessageConstants";
import { useLocation } from "react-router-dom";


const useStyles = makeStyles((theme) => ({
  field: {
    marginBottom: 20,
    borderRadius: "50px 50px 50px 50px",
    color: theme.palette.primary.main,
  },
  button: {
    marginTop: "20px",
    marginBottom: "10px",
    width: '100%',
    height: 45,
  },
  buttonText: {
    fontWeight: "bold",
    fontSize: 16,
    letterSpacing: "0.15em",
    color: "#FFFDFD",
  },
  textFieldInput: {
    color: "#ffffff",
  },
  form: {
    width: "100%",
  }
}));

const useQuery = ()=>{
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function ResetPasswordForm() {

  const classes = useStyles();
  const query = useQuery();
  const [state, setState] = useState({
    password: "",
    confirmPassword: "",
    loading: false
  });
  const toastInitialValues = {
    isOpen: false,
    isSuccess: false,
    isError: false,
    message: ""
  }
  const [ toast, setToast ] = useState(toastInitialValues); 
  const history = useHistory();
  const params = useParams();

  const setData = (type, value) => {
    setState((prev) => ({
      ...prev,
      [type]: value,
    }));
  };

  const closeToast = () => {
    return setToast((prev) => ({
      ...prev,
      isOpen: false,
      isSuccess: false,
      isError: false,
      message: ""
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let sess = query.get("session");
    sess = sess.replaceAll(' ','+');
    resetForgotPassword({
      username: query.get("email"),
      password: state.password,
      session: sess,
      secret: query.get("secret")
    }).then((res) => {
      if (res && res.status === 200 && res?.data?.message) {
        setToast((prev) => ({
          ...prev,
          isOpen: true,
          isSuccess: true,
          message: MessageConstants.PASSWORD_RESET_SUCCESS
        }));
        resetInput();
        setTimeout(()=>{
          history.push('/login');
        },2000)
      }
    }).catch((e)=>{
      const Error = JSON.parse(e.message);
      let msg = MessageConstants.PASSWORD_RESET_FAILED;
      if (Error.status === 400)
        msg = Error.message;
      setToast((prev) => ({
        ...prev,
        isOpen: true,
        isError: true,
        message: msg
      }));
    })
  };

  const resetInput = () => {
    setState((prev) => ({
      ...prev,
      password: "",
      confirmPassword: "",
    }));
  };

  const enableSubmit = () => {
    return (
      state.password &&
      state.confirmPassword === state.password
    );
  };


  return (
    <>
    {toast.isOpen && <Toast message={toast.message} isError={toast.isError} isSuccess={toast.isSuccess} closeToast={closeToast} />}
    
    <Box width="100%" mt="20px">
      <form autoComplete="off" onSubmit={handleSubmit} className={classes.form}>
        <TextField
          label="New Password"
          type="password"
          value={state.password}
          className={classes.field}
          onChange={(e) => setData("password", e.target.value)}
          variant="outlined"
          color="primary"
          fullWidth
          required
        />
        <TextField
          label="Confirm New Password"
          type="password"
          value={state.confirmPassword}
          className={classes.field}
          onChange={(e) => setData("confirmPassword", e.target.value)}
          variant="outlined"
          color="primary"
          fullWidth
          required
          error={
            state.confirmPassword?.length > 0 &&
            state.password !== state.confirmPassword
          }
        />
        <Grid item xs={12} align="center">
          <LoaderButton
            type="submit"
            variant="contained"
            color="primary"
            size="large"
            textClass={classes.buttonText}
            className={classes.button}
            disabled={!enableSubmit()}
            isLoading={state.loading}
            text="Submit"
          />
        </Grid>
      </form>
    </Box>
    </>
  );
}

export default ResetPasswordForm;
