import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
  useImperativeHandle
} from "react";
import { connect } from "react-redux";
import { getData } from "@govtechsg/open-attestation";
import {
  FrameConnector,
  renderDocument,
  selectTemplate,
  print
} from "@govtechsg/decentralized-renderer-react-components";

const SCROLLBAR_WIDTH = 20;
export default function DecentralisedRenderer({
  rawDocument,
  updateTemplates,
  selectedTemplate,
  forwardedRef
}) {
  const toFrame = useRef();
  const document = useMemo(() => getData(rawDocument), [rawDocument]);
  const [height, setHeight] = useState(0);

  useImperativeHandle(forwardedRef, () => ({
    print() {
      if (toFrame.current) {
        toFrame.current(print());
      }
    }
  }));

  const onConnected = useCallback(
    frame => {
      toFrame.current = frame;
      if (toFrame.current) {
        toFrame.current(renderDocument({ document, rawDocument }));
      }
    },
    [document, rawDocument]
  );

  const dispatch = action => {
    if (action.type === "UPDATE_HEIGHT") {
      setHeight(action.payload + SCROLLBAR_WIDTH); // adding SCROLLBAR_WIDTH in case the frame content overflow horizontally, which will cause scrollbars to appear
    }
    if (action.type === "UPDATE_TEMPLATES") {
      updateTemplates(action.payload);
    }
  };

  // render document onload
  useEffect(() => {
    if (toFrame.current) {
      toFrame.current(renderDocument({ document }));
    }
  }, [document, toFrame]);

  // update document when click on template tab
  useEffect(() => {
    if (toFrame.current && selectedTemplate) {
      toFrame.current(selectTemplate(selectedTemplate));
    }
  }, [selectedTemplate, toFrame]);

  return (
    <FrameConnector
      style={{ height: height, width: "100%", }}
      source={document.$template.url}
      dispatch={dispatch}
      onConnected={onConnected}
    />
  );
}

const mapDispatchToProps = dispatch => ({
});

const ForwardedRefDecentralisedRenderer = React.forwardRef((props, ref) => (
  <DecentralisedRenderer {...props} forwardedRef={ref} />
));

export const DecentralisedRendererContainer = connect(
  null,
  mapDispatchToProps,
  null,
  { forwardRef: true }
)(ForwardedRefDecentralisedRenderer);
