import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Box,
  Grid,
  Typography,
  TextField,
  InputAdornment,
  IconButton
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import ContactCard from "../components/contacts/ContactCard";
import DetailedCard from "../components/contacts/DetailedCard";
import { GetContacts, SelectContactAction } from "../redux/actions/Contacts.action";

const useStyles = makeStyles((theme) => ({
  root: {
    width: `calc(100% - ${300}px)`,
    marginLeft: 280,
    minHeight: "88vh",
  },
  filterBar: {
    marginTop: 20,
    marginBottom: 30,
  },
  searchBar: {
    width: 300,
  },
}));

const Contacts = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [search, setSearch] = useState('');
  const { contacts, selected } = useSelector((state) => state.contact);
  const [data, setData] = useState();
  const [showClearBtn, setShowClearBtn] = useState(false);
  const [searchBoxVal, setSearchBoxVal] = useState("");

  useEffect(() => {
    dispatch(GetContacts());
    return () => dispatch(SelectContactAction(null));
  }, []);

  useEffect(() => {
    setData(contacts?.data);
  }, [contacts])

  const handleSearch = (value) => {
    setSearchBoxVal(value)
    setShowClearBtn(value ? true : false);
    setData([...contacts?.data].filter(item => item?.username?.toLowerCase()?.includes(value?.toLowerCase())))
  }

  return (
    <Box className={classes.root}>
      <Grid
        className={classes.filterBar}
        container
        direction="row"
        alignItems="center"
        justify="space-between"
      >
        <Typography variant="body1">Most Recent</Typography>
        <TextField
          className={classes.searchBar}
          label="Search "
          value={searchBoxVal}
          variant="outlined"
          color="primary"
          onChange={e => handleSearch(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                {
                  showClearBtn ? <IconButton position="start" onClick={e => handleSearch('')}>
                    <ClearIcon />
                  </IconButton> : <SearchIcon />
                }
              </InputAdornment>

            ),
          }}
        />
      </Grid>

      <Grid container>
        <Grid container item xs={selected ? 8 : 12}>
          <Grid container item xs={selected && 12} spacing={3}>
            {data &&
              data.map((card) => {
                return (
                  <Grid
                    container
                    item
                    xs={selected ? 6 : 4}
                    key={card.id}
                  >
                    <Box width="90%">
                      <ContactCard
                        data={card}
                        isSelected={
                          selected && selected.id === card.id
                        }
                      />
                    </Box>
                  </Grid>
                );
              })}
          </Grid>
        </Grid>

        {selected && (
          <Grid container item xs={4}>
            <Box width="100%" height="calc(100vh - 200px)">
              <DetailedCard />
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default Contacts;
