import { FETCH_USER_SIGNATURE, RESET_FETCH_USER_SIGNATURE } from "../redux.constants";

const FetchSignature = () => {
  return dispatch => {
    dispatch({
      type: FETCH_USER_SIGNATURE
    })
  }
}

export const ResetFetchSignature = () => {
  return dispatch => {
    dispatch({
      type: RESET_FETCH_USER_SIGNATURE
    })
  }
}

export default FetchSignature;