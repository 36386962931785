import { useDispatch, useSelector } from 'react-redux';
import { useLocalStorage } from './index';
import { AuthActions } from '../redux/actions';
import { removeWalletDetails } from '../redux/actions/Wallet.action';
const LOCALSTORAGE_KEY = 'TrudocUser';

const useAuth = () => {
  const dispatch = useDispatch();
  const { setItem, getItem, deleteItem } = useLocalStorage();
  const { user } = useSelector(state => state);

  const getUserDetailsFromToken = (data) => {
    if (data && data.split('.') && data.split('.').length > 0) {
      const userDetailsFromToken =  JSON.parse(atob(data.split('.')[1]));
      const role = getItem("userType");
      return {
        token: data,
        email: userDetailsFromToken.email,
        username: userDetailsFromToken.preferred_username,
        corporate: userDetailsFromToken.iss.split('/')[5].replace(/%20/g, " "),
        role: role
      }
    }
  }
  const saveUser = (payload) => {
    if (payload) {
      setItem(LOCALSTORAGE_KEY, payload);
      const userDetails = getUserDetailsFromToken(payload);
      if (userDetails) {
        // dispatch(AuthActions({ ...userDetails, relam: payload && payload[Object.keys(payload)[1]] }));
        dispatch(AuthActions({ ...userDetails, relam: "trudoc" }));
        
      }
    }
  };

  const getUser = () => {
    let userInfo = getItem(LOCALSTORAGE_KEY);
    if (userInfo) {
      // const userDetails = getUserDetailsFromToken(userInfo.trudoc);
      const userDetails = getUserDetailsFromToken(userInfo);
      if (userDetails) {
        dispatch(AuthActions({ ...userDetails, relam: userInfo && userInfo[Object.keys(userInfo)[1]] }));
      }
    }
  }

  const removeUser = () => {
    deleteItem(LOCALSTORAGE_KEY)
    dispatch(AuthActions(null));
    dispatch(removeWalletDetails());
  };

  return { user, saveUser, removeUser, getUser };
}

export default useAuth;