import { useState, forwardRef } from 'react';
import { Slide, Typography, makeStyles, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, MenuItem, withStyles, InputBase, Checkbox, TextField, Grid, FormControl, Select, Divider, CircularProgress, Box } from '@material-ui/core';
import { Cancel } from '@material-ui/icons';
import { useEffect } from 'react';
import { updateCollaborator } from '../../services/api.service';
import { MessageConstants } from '../../utils/MessageConstants';
import Toast from '../../utils/Toast';
import { documentRoles } from "../../constants";
import { renameDocument } from '../../services/dms.service';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="top" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => {
  return {
    dialogRoot: {
      width: '700px',
      borderRadius: 50,
      margin: '0 auto',
    },
    contentHeight: {
      minHeight: '100px',
      maxWidth: '100%',
      margin: '0 auto',
      minWidth: '300px'
    },
    title: {
      paddingTop: 20,
      textAlign: 'center',
      fontWeight: '600',
      textTransform: 'uppercase',
      letterSpacing: 0.5,
      wordSpacing: 2,
      paddingBottom: 10
    },
    stepText: {
      fontWeight: '600',
      paddingBottom: 30,
      textAlign: 'center',
      color: theme.palette.admin.main,
      letterSpacing: 1,
    },
    actionBar: {
      justifyContent: 'center',
      marginBottom: 50
    },
    nextButton: {
      width: '100%',
      height: 50,
      margin: '5px 0',
      borderRadius: 25,
      color: '#fff',
      letterSpacing: 1,
      backgroundColor: theme.palette.primary.main,
      textTransform: 'capitalize',
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
      }
    },
    draftButton: {
      width: 220,
      height: 50,
      borderRadius: 25,
      textTransform: 'capitalize',
      letterSpacing: 1,
      backgroundColor: '#fff',
      color: "#6a6a6a",
      border: '1px solid gray',
      '&:hover': {
        backgroundColor: "#fff",
        color: '#363636',
        border: '1px solid #464646'
      }
    },
    closeIcon: {
      position: 'absolute',
      right: '0',
      padding: '10px'
    },
    buttonTextCancel: {
      fontWeight: "bold",
      fontSize: 16,
      letterSpacing: "0.15em",
    },
    buttonRight: {
      marginTop: "20px",
      marginBottom: "10px",
      border: '1px solid gray',
      backgroundColor: '#fff',
      width: 150,
      height: 45,
      color: "#6a6a6a",
      borderRadius: 25,
      '&:hover': {
        backgroundColor: "#fff",
        color: '#363636',
        border: '1px solid #464646'
      }
    },
  }
});



const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 50,
    position: 'relative',
    backgroundColor: '#fff',
    border: '1px solid #66CFA4',
    fontSize: 18,
    padding: '18px 14px',
    color: theme.palette.primary.main,
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      backgroundColor: '#fff',
      borderColor: theme.palette.primary.main,
      color: theme.palette.primary.main,
      borderRadius: 50,
    },
  },
}))(InputBase);

const GreenCheckbox = withStyles((theme) => ({
  root: {
    color: theme.palette.admin.main,
    '&$checked': {
      color: theme.palette.admin.main,
    },
  },
  checked: {},
}))((props) => <Checkbox color="default" {...props} />);

const CssTextField = withStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#66CFA4",
      },
      "&:hover fieldset": {
        borderColor: "#66CFA4",
      },
      borderRadius: 50,
    },
  },
})(TextField);

const SaveTypeConfirmation = (props) => {

  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const sendClose = (anythingChange = false) => {
    props.handleClose(anythingChange);
  }

  const onClose = (type) => {
    sendClose(type);
  }

  useEffect(() => {
    setOpen(props?.open);
  }, [props]);


  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth='md'
        open={open}
        onClose={onClose}
        className={classes.dialogRoot}
        keepMounted={false}
        TransitionComponent={Transition}
        PaperProps={{
          style: { borderRadius: 20, maxHeight: 'calc(100% - 44px)' }
        }}
      >
        <div className={classes.closeIcon}>
          <IconButton
            onClick={onClose}
          >
            <Cancel />
          </IconButton>
        </div>
        <DialogTitle gutterBottom>
          <Typography variant='h5' className={classes.title}>
            Where you want to save this?
          </Typography>
        </DialogTitle>

        <DialogActions className={classes.actionBar} gutterBottom>

          <Grid
            className={classes.filterBar}
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
            spacing={0}
          >

            <Grid item xs={8}>

              <Box className="w-100 text-center">

                <Button className={classes.nextButton} onClick={e => onClose('dms')}>
                  <Typography variant='h6'>{"Save to DMS"}</Typography>
                </Button>
                <Button className={classes.nextButton + ' me-10px'} onClick={e => onClose('pc')}>
                  <Typography variant='h6'>{"Save to PC"}</Typography>
                </Button>

              </Box>

            </Grid>

          </Grid>

        </DialogActions>

      </Dialog>

    </>
  );


}

export default SaveTypeConfirmation;