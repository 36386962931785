import { Button, Popover } from "antd";
import React, { useEffect, useState } from "react";
import { isNotEmpty } from "../../utils/Generic";
import UnsdgObjectiveInfo from "./unsdg-objective-info";

const UnsdgSubjectImage = (props) => {

    const { objectives, subject, subjects, showMulti = false } = props;

    const [haveObjectives, setHaveObjectives] = useState(false);

    useEffect(() => {
        let haveItem = false;   
        if (objectives.length > 0) {
            for (let objective of objectives) {
                if (objective.subjects.length > 0) {
                    for (let subItem of objective.subjects) {
                        if (isNotEmpty(subject.goalNumber)) {
                            if (subItem.goal.toString() === subject.goalNumber.toString()) {
                                if (subItem.subject === subject.title) {
                                    haveItem = true;
                                }
                            }
                        }
                    }
                }
            }
        }
        setHaveObjectives(haveItem);
    }, [objectives, subject]);

    return (
        <>
            {/* <div className={`goal-images round-number-item ${haveObjectives ? 'have-objectives' : 'no-objectives'} ${subject?.category?.id}`}> */}
                <Popover content={<UnsdgObjectiveInfo showMulti={showMulti} subject={subject} subjects={subjects} />}>
                    {/* <Button>{subject?.goalNumber}</Button> */}
                    <img className="goal-images" src={`/assets/images/sdg-icons/E-WEB-Goal-${subject?.goalNumber}.png`} />
                </Popover>
            {/* </div> */}
        </>
    );
};

export default UnsdgSubjectImage;