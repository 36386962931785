import { useState, forwardRef } from 'react';
import { Slide, Typography, makeStyles, Button, Dialog, DialogActions, DialogTitle, IconButton, MenuItem, withStyles, InputBase, Checkbox, TextField, Grid, FormControl, Select, Divider, CircularProgress, Box } from '@material-ui/core';
import { Cancel } from '@material-ui/icons';
import { useEffect } from 'react';
import { metaMaskStates, networkChainId } from '../constants';
import { hexToNumber, isNotEmpty, normalizeAddr } from '../utils/Generic';
import { useMetaMask } from 'metamask-react';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="top" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => {
  return {
    dialogRoot: {
      width: '700px',
      borderRadius: 50,
      margin: '0 auto',
    },
    contentHeight: {
      minHeight: '100px',
      maxWidth: '100%',
      margin: '0 auto',
      minWidth: '300px'
    },
    title: {
      paddingTop: 20,
      textAlign: 'center',
      fontWeight: '600',
      textTransform: 'uppercase',
      letterSpacing: 0.5,
      wordSpacing: 2,
      paddingBottom: 10
    },
    contentDesc: {
      paddingTop: 20,
      textAlign: 'center',
      letterSpacing: 0.5,
      wordSpacing: 2,
      paddingBottom: 10,
      fontSize: 14,
      width: '100%',
      display: 'block',
      fontFamily: 'Poppins'
    },
    stepText: {
      fontWeight: '600',
      paddingBottom: 30,
      textAlign: 'center',
      color: theme.palette.admin.main,
      letterSpacing: 1,
    },
    actionBar: {
      justifyContent: 'center',
      marginBottom: 50
    },
    nextButton: {
      width: 220,
      height: 50,
      borderRadius: 25,
      color: '#fff',
      letterSpacing: 1,
      backgroundColor: theme.palette.primary.main,
      textTransform: 'capitalize',
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
      }
    },
    draftButton: {
      width: 220,
      height: 50,
      borderRadius: 25,
      textTransform: 'capitalize',
      letterSpacing: 1,
      backgroundColor: '#fff',
      color: "#6a6a6a",
      border: '1px solid gray',
      '&:hover': {
        backgroundColor: "#fff",
        color: '#363636',
        border: '1px solid #464646'
      }
    },
    closeIcon: {
      position: 'absolute',
      right: '0',
      padding: '10px'
    },
    buttonTextCancel: {
      fontWeight: "bold",
      fontSize: 16,
      letterSpacing: "0.15em",
    },
    buttonRight: {
      marginTop: "20px",
      marginBottom: "10px",
      border: '1px solid gray',
      backgroundColor: '#fff',
      width: 150,
      height: 45,
      color: "#6a6a6a",
      borderRadius: 25,
      '&:hover': {
        backgroundColor: "#fff",
        color: '#363636',
        border: '1px solid #464646'
      }
    },
  }
});

const MetaMaskTransaction = (props) => {

  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [walletAddress, setWalletAddress] = useState(false);
  const [transactionStarted, setTransactionStarted] = useState(false);
  const [transactionProgressText, setTransactionProgressText] = useState(null);

  const { status, connect, account, chainId, ethereum } = useMetaMask();

  useEffect(() => {
    setOpen(props?.open);
    setWalletAddress(props?.walletAddress);
    setTransactionStarted(props?.transactionStarted);
    setTransactionProgressText(props?.transactionProgressText);
  }, [props]);

  const sendClose = (anythingChange = false) => {
    props.handleClose(anythingChange);
  }

  const startNow = () => {
    props.handleStart();
  }

  const connectToCorrectNetwork = () => {
    let chainIdString = hexToNumber(chainId).toString();
    let requiredChainId = networkChainId;
    requiredChainId = requiredChainId.toString();
    if (chainIdString === requiredChainId) {
      return true;
    } else {
      return false;
    }
  }

  const correctWalletConnected = () => {
    if (isNotEmpty(walletAddress)) {
      let walletAddr = normalizeAddr(walletAddress);
      let walletAddrConnected = normalizeAddr(account);
      if (walletAddr === walletAddrConnected) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }



  return (
    <>

      <Dialog
        fullWidth={true}
        maxWidth='md'
        open={open}
        onClose={(e) => sendClose(false)}
        className={classes.dialogRoot}
        keepMounted={false}
        TransitionComponent={Transition}
        PaperProps={{
          style: { borderRadius: 20, maxHeight: 'calc(100% - 44px)' }
        }}
      >
        <div className={classes.closeIcon}>
          <IconButton
            onClick={(e) => sendClose(false)}
          >
            <Cancel />
          </IconButton>
        </div>
        <DialogTitle gutterBottom>
          <Typography variant='h5' className={classes.title}>
            MetaMask
          </Typography>
          <Typography variant='p' className={classes.contentDesc}>
            Now you have to approve transaction(s) through metamask to complete request.
          </Typography>
        </DialogTitle>

        <DialogActions className={classes.actionBar} gutterBottom>

          <Box className="w-100 text-center" display="flex" alignItems="center" justifyContent="center" flexDirection="column">

            {
              (status == metaMaskStates.connecting || status == metaMaskStates.initializing) ? <>
                <CircularProgress color="primary" size={20} />
              </> : <>

                {
                  status == metaMaskStates.connected && !connectToCorrectNetwork() ? <>
                    <Typography className={classes.subHeading}>Metamask is connected to Wrong Network.</Typography>
                  </> : null
                }

                {
                  status == metaMaskStates.connected && !correctWalletConnected() ? <>
                    <Typography className={classes.subHeading}>MetaMask is connected to wrong wallet, please connect to {walletAddress}.</Typography>
                  </> : null
                }

                {
                  status == metaMaskStates.notConnected ? <>
                    <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column" className={classes.listItem}>
                      <Typography className={classes.subHeading}>MetaMask not connected, click below button to connect now.</Typography>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        className={'mt-10px ' + classes.button}
                        size="large"
                        onClick={(e) => connect()}
                      >
                        <Typography className={classes.buttonText}>Connect Now</Typography>
                      </Button>
                    </Box>

                  </> : null
                }

                {
                  status == metaMaskStates.connected && correctWalletConnected() && connectToCorrectNetwork() ? <>
                    <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column" className={classes.listItem}>

                      {
                        transactionStarted ? <>
                          <CircularProgress />
                          {
                            transactionProgressText != null ? <>
                              <Typography className={classes.listItemtext + " mt-10px"}>{transactionProgressText}</Typography>
                            </> : null
                          }
                        </> : <>
                          <Button
                            type="button"
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            size="large"
                            onClick={(e) => startNow()}
                          >
                            <Typography className={classes.buttonText}>Start Now</Typography>
                          </Button>
                        </>
                      }

                    </Box>

                  </> : null
                }

              </>
            }

            <Typography variant='p' className={classes.contentDesc}>
              Please don't close this box after starting payment until prompted.
            </Typography>

          </Box>

        </DialogActions>

      </Dialog>

    </>
  );


}

export default MetaMaskTransaction;