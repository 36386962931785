import React, { useEffect, useRef, useState } from "react";
import { makeStyles, styled } from "@material-ui/core/styles";
import { generateSusChart, goalsParseCategories, isNotEmpty, parseObjectiveSubjects, printTime } from "../../utils/Generic";
import Chart from "react-google-charts";
import { UNSDG_GOALS, UNSDG_GOAL_CATEGORIES } from "../../utils/unsdg-goals";
import UnsdgSubjectImage from "./unsdg-subject-image";
import UnsdgSubjectNumber from "./unsdg-subject-number";
import { Grid } from "@material-ui/core";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import EsgImpactTopicsModal from "./esg-impact-topics-modal";
import EsgImpactSubjectModal from "./esg-impact-subjects-modal";


const DashboardEsgImpact = (props) => {

  const { objectives, report, goals, setGoals, companyDocuments, canSave, loadData } = props;


  const [chartData, setChartData] = useState([]);
  const [chartData2, setChartData2] = useState([])
  const [sliceColor, setSliceColor] = useState({})
  const [objectiveTopics, setObjectiveTopics] = useState([]);
  const [esgObjective, setEsgObjective] = useState([])
  const [openTopicModal, setOpenTopicModal] = useState(false)
  const [openSubjectModal, setOpenSubjectModal] = useState(false)
  const [objKeyVal, setObjKeyVal] = useState(null)

  useEffect(() => {
    setChartData(generateSusChart(report));
    const checkThis = Object.keys(UNSDG_GOALS).map((goal) => {
      let unsdgGoal = UNSDG_GOALS[goal];
      let objectiveCount = 0;
      let kpiCount = 0
      report?.sustainability_goal_objectives.forEach(element => {
        if (element?.sustainability_goal_subjects.length > 0) {
          element?.sustainability_goal_subjects.forEach(temp => {
            if (temp?.goal_number == goal) {
              objectiveCount++
              kpiCount += element?.sustainability_kpis.length
            }
          })
        }
      });
      var html_tooltip = `<div class='tooltip-chart'>
                            <div>
                              <h2>${unsdgGoal?.title}</h2>
                              <p>${unsdgGoal?.info}</p>
                            </div>
                            <div class='row'>
                              <div class='col-4'>
                                <h2>Objectives</h2>
                                <p>${objectiveCount}</p>
                              </div>
                              <div class='col-4'>
                                <h2>ESG</h2> 
                                <p>${unsdgGoal?.category}</p>
                              </div>
                              <div class='col-4'>
                                <h2>KPI's</h2> 
                                <p>${kpiCount}</p>
                              </div>
                            </div>
                          </div>`
      return [unsdgGoal?.title, 1, html_tooltip]
    })
    const selected = report?.sustainability_goals.map(goal => (goal.goal_title))
    const slice = Object.keys(UNSDG_GOALS).map((goal) => {
      let unsdgGoal = UNSDG_GOALS[goal];
      if (selected.includes(unsdgGoal.title))
        return { color: unsdgGoal?.color }
      else
        return { color: '#f1f1f1' }
    })
    const object = slice.reduce((acc, currentValue, index) => {
      acc[index] = currentValue;
      return acc;
    }, {});
    setSliceColor(object)
    setChartData2([["Task", "Total Actions", { type: 'string', role: 'tooltip', 'p': { 'html': true } }], ...checkThis])

    const fetchObjectiveData = async () => {
      const topics = await Promise.all(
        report?.sustainability_goal_objectives.map(async (objective) => {
          const topics = await getObjectiveTopics(objective?.sustainability_goal_subjects);

          return topics.join(', ')
        })
      );
      const esgtopics = await Promise.all(
        report?.sustainability_goal_objectives.map(async (objective) => {
          const esgtopic = await getEsgTopics(objective?.sustainability_goal_subjects)

          return esgtopic.join(', ')
        })
      );

      setEsgObjective(esgtopics)
      setObjectiveTopics(topics);
      // const esgtopic = 
    };

    if (report?.sustainability_goal_objectives.length > 0) {
      fetchObjectiveData();
    }

  }, [report]);

  const getObjectiveTopics = async (sub) => {
    const subjectlist = sub.map(obj => `#${obj.subject}`)
    return subjectlist
  }

  const getEsgTopics = async (sub) => {

    const subjectlist = sub.flatMap(obj =>
      Object.keys(UNSDG_GOAL_CATEGORIES).map(goal => {
        if (UNSDG_GOAL_CATEGORIES[goal].goals.includes(obj?.goal_number)) {
          return UNSDG_GOAL_CATEGORIES[goal].title;
        }
        return null; // Return null for cases where the condition is not met
      }).filter(Boolean) // Filter out null values from the resulting array
    );

    var unique = subjectlist.filter((value, index, array) => array.indexOf(value) === index);
    return unique

    // return unique
  }

  const handleViewMoreTopics = async (index) => {
    setOpenTopicModal(true)
    setObjKeyVal(index)
  }
  const handleViewMoreGoals = async (index) => {
    setOpenSubjectModal(true)
    setObjKeyVal(index)
  }

  const handleCloseTopicModal = () => {
    setOpenTopicModal(false)
    setObjKeyVal(null)
  }
  const handleCloseSubjectModal = () => {
    setOpenSubjectModal(false)
    setObjKeyVal(null)
  }



  return (
    <>
      {
        Object.keys(goals).length > 0 ? <>

          <div className='main-wrapper'>
            <div className="container ">
              <div className="ds-flex item-between">

                <div className="esg-graph col-50">
                  <div className="bg-light-grey">
                    <h5 className="cl-white fs-24 m-0">SDGs</h5>
                  </div>
                  <div className="chart-elem">
                    <div className="info-chart">
                      <div className="chart-elem-inner">
                        <Chart
                          chartType="PieChart"
                          data={chartData2}
                          options={{
                            legend: 'none',
                            pieSliceText: 'none',
                            pieHole: 0.5,
                            slices: sliceColor,
                            tooltip: { isHtml: true }
                          }}
                          width={"100%"}
                          height={"400px"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="esg-graph col-50">
                  <div className="bg-light-grey">
                    <h5 className="cl-white fs-24 m-0">ESG Impacts</h5>
                  </div>
                  <div className="chart-elem">
                    <div className="info-chart">
                      <div className="chart-elem-inner"
                        style={{ position: 'relative', width: '100%', }}
                      >
                        <Chart
                          chartType="PieChart"
                          data={chartData}
                          options={{
                            legend: 'none',
                            pieSliceText: 'none',
                            slices: {
                              0: { color: '#3F91CE' },
                              1: { color: '#6D55AB' },
                              2: { color: '#F04594' }
                            },
                            pieHole: 0.7,
                            tooltip: { isHtml: true }
                          }}
                          width={"100%"}
                          height={"400px"}
                        />
                        <div
                          style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            textAlign: 'left',
                          }}
                        >
                          <p><span style={{ display: 'inline-block', width: '10px', height: '10px', borderRadius: '50%', backgroundColor: '#3F91CE', marginRight: '5px' }}></span>Environment</p>
                          <p><span style={{ display: 'inline-block', width: '10px', height: '10px', borderRadius: '50%', backgroundColor: '#6D55AB', marginRight: '5px' }}></span>Social</p>
                          <p><span style={{ display: 'inline-block', width: '10px', height: '10px', borderRadius: '50%', backgroundColor: '#F04594', marginRight: '5px' }}></span>Governance</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="navy-table blue-table">
                <table aria-label="simple table">
                  <thead>
                    <tr>
                      <th>Objective</th>
                      <th>Material Topics</th>
                      <th>ESG</th>
                      <th>Status</th>
                      <th>Goals</th>
                      {/* <th align="left">KPIs</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {
                      objectives.length > 0 ? <>
                        {
                          objectives.map((objective, indexMain) => {
                            let subjectsData = parseObjectiveSubjects(objective, goals);
                            return <>
                              <tr key={indexMain}>
                                <td component="th" scope="row" >{objective?.title}</td>
                                {/* <td component="th" scope="row" >{objectiveTopics[index]}</td> */}
                                <td component="th" scope="row" >
                                  {
                                    <div className="round-numbers-box">
                                      {
                                        isNotEmpty(subjectsData) ? <>
                                          {
                                            Object.keys(subjectsData).map((sKey, index) => {
                                              let itemObjectives = subjectsData[sKey];
                                              if (itemObjectives.length > 0 && index <= 7) {
                                                return <>
                                                  {
                                                    index < 7 ?
                                                      <UnsdgSubjectNumber goals={goals} objectives={objectives} showMulti={true} subject={itemObjectives[0]} subjects={itemObjectives} />
                                                      :
                                                      <span className="more-context" onClick={e => handleViewMoreTopics(indexMain)}>{+Object.keys(subjectsData).length - +index} more <ArrowForwardIcon /></span>
                                                  }
                                                </>
                                              }
                                            })
                                          }
                                        </> : null
                                      }
                                    </div>
                                  }
                                </td>
                                <td component="th" scope="row" >{esgObjective[indexMain]}</td>
                                <td component="th" scope="row" >New Objective</td>
                                <td component="th" scope="row" >
                                  <div className="round-numbers-box objective-img-fivegrid">
                                    {
                                      isNotEmpty(subjectsData) ? <>
                                        {
                                          Object.keys(subjectsData).map((sKey, index) => {
                                            let itemObjectives = subjectsData[sKey];
                                            if (itemObjectives.length > 0 && index <= 3) {
                                              return <>
                                                {
                                                  index < 3 ?
                                                    <UnsdgSubjectImage goals={goals} objectives={objectives} showMulti={true} subject={itemObjectives[0]} subjects={itemObjectives} />
                                                    :
                                                    <span className="more-context" onClick={e => handleViewMoreGoals(indexMain)} >{+Object.keys(subjectsData).length - +index} more <ArrowForwardIcon /></span>
                                                }
                                              </>
                                            }
                                          })
                                        }
                                      </> : null
                                    }
                                  </div>
                                </td>
                              </tr>
                            </>
                          })
                        }
                      </> : <>

                      </>
                    }
                  </tbody>
                </table>
              </div>

            </div>
          </div>

        </> : <>
          <div className='blank-page'>
            <img src="/assets/images/dashboard-blank.png" />
            <h2>No Data Yet</h2>
            <p>Please click the button below to configure objectives</p>
          </div>
        </>
      }
      {
        // console.log(openTopicModal,"   aasdasd    ",objKeyVal)
        openTopicModal && objKeyVal != null &&
        <EsgImpactTopicsModal closeModal={handleCloseTopicModal} openModal={openTopicModal} objectiveKey={objKeyVal} objectives={objectives} report={report} goals={goals} />
      }
      {
        // console.log(openTopicModal,"   aasdasd    ",objKeyVal)
        openSubjectModal && objKeyVal != null &&
        <EsgImpactSubjectModal closeModal={handleCloseSubjectModal} openModal={openSubjectModal} objectiveKey={objKeyVal} objectives={objectives} report={report} goals={goals} />
      }
    </>
  );
};

export default DashboardEsgImpact;