import { getFormData } from "./utils";
import { endpoint } from "./services.constant";
import apiCall, { userApiCall } from "./api";

export const getSignatories = (payload) => {
    return apiCall(endpoint.user.SIGNATORIES, "GET", payload);
};

export const getCorporateMembers = (payload) => {
    return apiCall(endpoint.user.CORPORATE_MEMBERS, "GET", payload);
};

export const getUserDetails = (payload = {}) => {
    return apiCall(endpoint.user.DETAILS, "POST", payload);
};

export const getContacts = (payload) => {
    return apiCall(endpoint.user.CONTACTS, "GET", payload);
};

export const getSignList = (payload) => {
    return apiCall(endpoint.user.SIGN_LIST, "GET", payload);
};
export const getSignData = (payload) => {
    return apiCall(endpoint.user.SIGN_DATA + payload, "GET");
};

export const deleteSignature = (id) => {
    return userApiCall(`${endpoint.user.DELETE_SIGN}/${id}`, "DELETE");
};

export const getEndrosmentChain = (queryParams) => {
    return apiCall(endpoint.public.ENDROSMENT_CHAIN, "GET", null, null, queryParams);
};
export const verifyDoc = (payload) => {
    return apiCall(endpoint.doc.VERIFY, "POST", payload);
};

export const verifyDocByDocID = (documentId) => {
    return apiCall(endpoint.client.VERIFY_DOC_BY_ID(documentId), "GET");
};

export const accountDetailsForTitleTransfer = () => {
    return apiCall(endpoint.doc.ACCOUNT_DETAILS, "GET", {});
}

export const uploadDoc = (payload) => {
    const formData = getFormData(payload);
    return apiCall(
        endpoint.doc.UPLOAD,
        "POST",
        null,
        formData,
        "multipart/form-data"
    );
};

export const uploadEDoc = (payload) => {
    const formData = getFormData(payload);
    return userApiCall(
        endpoint.eDoc.UPLOAD,
        "POST",
        null,
        formData,
        "multipart/form-data"
    );
};
export const processEDoc = (payload) => {
    const formData = getFormData(payload);
    return apiCall(endpoint.eDoc.PROCESS_E_PDF, "POST", null, formData, "multipart/form-data");
};


export const sendSign = (payload) => {
    return apiCall(endpoint.user.SEND_SIGN, "POST", payload);
};

export const getPdf = (payload) => {
    return apiCall(endpoint.doc.RENDERPDF, "POST", payload);
};

export const getPdfImages = (payload) => {
    return apiCall(endpoint.doc.PDF_Images, "POST", payload);
};

export const signDoc = (payload) => {
    return apiCall(endpoint.doc.SIGN, "POST", payload);
};

export const signPDFDoc = (payload) => {
    const formData = getFormData(payload);
    return apiCall(
        endpoint.eDoc.SIGN_PDF,
        "POST",
        null,
        formData,
        "multipart/form-data"
    );
    return apiCall(endpoint.doc.SIGN_PDF, "POST", payload);
};

export const getStatus = (payload) => {
    return apiCall(endpoint.admin.STATUS, "POST", payload);
};

export const downloadTt = (payload) => {
    return apiCall(endpoint.doc.DOWNLOADTT, "POST", payload);
};

export const uploadTt = (payload) => {
    const formData = getFormData(payload);
    return apiCall(
        endpoint.doc.UPLOADTT,
        "POST",
        null,
        formData,
        "multipart/form-data"
    )
}

export const registerDomain = (payload) => {
    return userApiCall(endpoint.admin.DOMAIN, "POST", payload); //user API call used for error handling here
};

export const changeDocHolder = (payload) => {
    return apiCall(endpoint.doc.TITLE_TRANSFER, "POST", payload);
};

export const transferTitle = (payload) => {
    return apiCall(endpoint.doc.TITLE_TRANSFER, "POST", payload);
}

export const revoke = (payload) => {
    return apiCall(endpoint.doc.REVOKE, "POST", payload);
}

export const markRevoked = (payload) => {
    return apiCall(endpoint.doc.MARK_REVOKED, "POST", payload);
}

export const revokeStatus = (payload) => {
    return apiCall(endpoint.doc.REVOKE_STATUS, "POST", payload);
}

export const fetchCorporateList = (payload) => {
    return apiCall(endpoint.admin.CORPORATE_LIST, "GET", payload);
}

export const getCorporateListFromAddress = (payload) => {
    return apiCall(endpoint.admin.CORPORATE_LIST_FROM_ADDRESS, "POST", payload);
}

export const addCollaborator = (payload) => {
    return apiCall(endpoint.dms.ADD_COLLABORATOR, "POST", payload);
};

export const updateCollaborator = (payload) => {
    return apiCall(endpoint.dms.UPDATE_COLLABORATOR, "POST", payload);
};

export const deleteCollaborator = (payload) => {
    return apiCall(endpoint.dms.DELETE_COLLABORATOR, "POST", payload);
};

export const revertDocumentToVersion = (payload) => {
    return apiCall(endpoint.dms.REVERT_VERSION, "POST", payload);
};

export const getSignTransaction = (payload) => {
    return apiCall(endpoint.doc.SIGN_TRANSACTION, "POST", payload);
};

export const updateSignTransaction = (payload) => {
    return apiCall(endpoint.doc.UPDATE_SIGN_TRANSACTION, "POST", payload);
};

export const getPickerFileData = (payload) => {
    return apiCall(endpoint.doc.PICKER_FILE_DATA, "POST", payload);
};

export const accessTokenDetails = (payload) => {
    return apiCall(endpoint.public.ACCESS_TOKEN_DETAILS, "POST", payload);
};

export const getAwaitingCorporates = (payload = {}) => {
    return apiCall(endpoint.super.AWAITING_CORPORATES, "POST", payload);
};

export const listCorporates = (payload = {}) => {
    return apiCall(endpoint.super.LIST_CORPORATES, "POST", payload);
};

export const markCorporateActive = (payload) => {
    return apiCall(endpoint.super.MARK_CORPORATE_ACTIVE, "POST", payload);
};

export const generateCorporateFormLink = (payload) => {
    return apiCall(endpoint.super.CORPORATE_FORM_LINK, "POST", payload);
};

export const superDashboard = (payload = {}) => {
    return apiCall(endpoint.super.DASHBOARD, "POST", payload);
};

export const superSettingsList = (payload = {}) => {
    return apiCall(endpoint.super.SETTINGS_LIST, "POST", payload);
};

export const superUpdateSetting = (payload = {}) => {
    return apiCall(endpoint.super.UPDATE_SETTING, "POST", payload);
};

export const getCorporateDetails = (payload = {}) => {
    return apiCall(endpoint.super.CORPORATE_DETAILS, "POST", payload);
};

export const fetchSustainabilityDetails = (payload = {}) => {
    return apiCall(endpoint.super.FETCH_SUSTAINABILITY_DETAILS, "POST", payload);
};

export const updateSuperCorporate = (payload = {}) => {
    const formData = getFormData(payload);
    return apiCall(endpoint.super.CORPORATE_UPDATE, "POST", null, formData, null, "multipart/form-data");
}