import { SELECT_VIEW_DOCUMENT } from '../redux.constants';

const ViewerReducer = (state = {}, action) => {
  switch (action.type) {
    case SELECT_VIEW_DOCUMENT: 
      return action.payload;
    default:
      return state;
  }
}

export default ViewerReducer;