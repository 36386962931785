import React, { useRef, useEffect, useState } from 'react';
import WebViewer from '@pdftron/webviewer';
import { useAuth } from '../../hooks';
import "./Viewer.css";
import { useSelector } from 'react-redux';
import Toast, { toastInitialValues } from '../Toast';
import { MessageConstants } from '../MessageConstants';
import { annotationId, isLastSigner } from '../../redux/selector/viewer.selector';
import { pdfBase64ToBlob } from '../Generic';

const FillSignature = (props) => {

  const [instance, setInstance] = useState(null);
  // const [applySignature, setApplySignature] = useState(false);
  const {userAnnot, setUserAnnot} = props;
  const { user } = useAuth();
  const viewer = useRef(null);
  const [toast, setToast] = useState(toastInitialValues);
  const userAnnots = useSelector(annotationId);
  const isLastUser = useSelector(isLastSigner);
  const [sign, setSign] = useState(props.selectedSign);
  let flag = false;
  let userAnnotCount = 0;

  const closeToast = () => {
    return setToast((prev) => ({
      ...prev,
      isOpen: false,
      isSuccess: false,
      isError: false,
      message: ""
    }));
  };

  useEffect(() => {
    WebViewer(
      {
        path: '/webviewer/lib',
        css: '/style.css',
        fullAPI: true,
        disabledElements: [
          "ribbons",
          "toggleNotesButton",
          "searchButton",
          "menuButton",
          "rubberStampToolGroupButton",
          "stampToolGroupButton",
          "fileAttachmentToolGroupButton",
          "calloutToolGroupButton",
          // "undo",
          "redo",
          "eraserToolButton",
          // "toolsHeader",
          "signatureToolGroupButton", 
          "leftPanelButton",
          "viewControlsButton",
          "selectToolButton",
          "annotationPopup",
          "toolsOverlay",
          "defaultSignatureDeleteButton",
          "contextMenuPopup",
          "signatureModal",
          "underlineToolGroupButton",
          "highlightToolGroupButton",
          "shapeToolGroupButton",
          "freeTextToolGroupButton",
          "freeHandHighlightToolGroupButton",
          "freeHandToolGroupButton",
          "stickyToolGroupButton",
          "squigglyToolGroupButton",
          "strikeoutToolGroupButton",
          "redoButton"
        ],
      },
      viewer.current,
    ).then((instance) => {
      setInstance(instance);
      // setApplySignature(true);
      const { documentViewer } = instance.Core;
      const annotationManager = documentViewer.getAnnotationManager();
      const signatureTool = documentViewer.getTool('AnnotationCreateSignature');
      signatureTool.setSignature(props.selectedSign);
      annotationManager.setReadOnly(props.isReadOnly);
      annotationManager.setCurrentUser(user.username);
      instance.UI.loadDocument(pdfBase64ToBlob(props.file), { filename: 'myfile.pdf' });        
      annotationManager.addEventListener('annotationChanged', (annotations, action) => {
        console.log(annotations);
        if(action === "delete" && flag && (annotations[0]?.Subject === "Signature" || annotations[0]?.Subject === "annotation.signature")){
          userAnnotCount += 1;
          setUserAnnot(userAnnotCount);
        }
        else if(action === "add" && flag && (annotations[0]?.Subject === "Signature" || annotations[0]?.Subject === "annotation.signature")){
          userAnnotCount -= 1;
          setUserAnnot(userAnnotCount);
        }

        annotations.forEach((annot) => { // remove dragging, resizing, rotation on annotation
          if(!userAnnots.includes(annot.Id) && annot.Subject === 'Widget'){ // hide annotation if not belong to logged in user
            annot.Hidden = true;
          }else if(userAnnots.includes(annot.Id) && annot.Subject === 'Widget'){
            userAnnotCount += 1;
            setUserAnnot(userAnnotCount);
          }
          annot.Listable = false;
        });
      });
      signatureTool.addEventListener('locationSelected',() => {
        signatureTool.setSignature(sign);
        signatureTool.addSignature();
        flag = true;
      });
    });

  }, []);

  useEffect(()=>{
    if(instance?.Core){
      const { documentViewer } = instance.Core;
      const signatureTool = documentViewer.getTool('AnnotationCreateSignature');
      signatureTool.setSignature(props.selectedSign);
      setSign(props.selectedSign);
    }
  },[props.selectedSign])

  // useEffect(() => { // Add saved signature and initial
  //   if(applySignature){
  //     if(props.signature.Fullname.length < 1){
        // setToast((prev) => ({
        //   ...prev,
        //   isOpen: true,
        //   isError: true,
        //   message: MessageConstants.REQUIRED_ATLEAST_ONE_SIGNATURE
        // }));
        // return;
  //     }
  //     const { documentViewer } = instance.Core;
  //     const signatureTool = documentViewer.getTool('AnnotationCreateSignature');
  //     documentViewer.addEventListener('documentLoaded', () => {
  //       signatureTool.importSignatures(props.signature.Fullname);
  //       signatureTool.importInitials(props.signature.Initial);
  //       setApplySignature(false);
  //     });
  //   }
  // }, [applySignature]);

  const uploadAfterSigning = async () => {
    const { docViewer, annotManager } = instance;
    const doc = docViewer.getDocument();
    const xfdfString = await annotManager.exportAnnotations({
      widgets: true,
      fields: true,
    })
    const options = { xfdfString };
    if(isLastUser){
      options.flatten = true;
    }
    const data = await doc.getFileData(options);
    const arr = new Uint8Array(data);
    const blob = new Blob([arr], { type: "application/pdf" });
    if(userAnnot === 0){
      props.handleSignDocument(blob);
      userAnnotCount = 0;
      flag = false;
    }
    else{
      setToast((prev) => ({
        ...prev,
        isOpen: true,
        isError: true,
        message: MessageConstants.REQUIRED_FILL_ALL_SIGNATURE
      }));
    }
  };

  useEffect(()=>{
    if(props.saveFile){
      uploadAfterSigning();
    }
  },[props.saveFile])

  return (
    <>
      {toast.isOpen && <Toast message={toast.message} isError={toast.isError} isSuccess={toast.isSuccess} closeToast={closeToast} />}
      <div className="webviewer" ref={viewer}></div>
    </>
  );
};

export default FillSignature;