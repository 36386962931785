import { Snackbar } from '@material-ui/core'
import React from 'react'
import MuiAlert from "@material-ui/lab/Alert";

const Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  };  

export  const toastInitialValues = {
  isOpen: false,
  isSuccess: false,
  isError: false,
  message: ""
}

const Toast = (props) => {

    const closeToastHandler = () =>{
        props.closeToast();
    }
    
  return (
    <>
    {
        props.isSuccess && <Snackbar
        open={true}
        autoHideDuration={6000}
        onClose={closeToastHandler}
      >
        <Alert onClose={closeToastHandler} severity="success"> {props.message} </Alert>
      </Snackbar>
    }
    {
        props.isError && <Snackbar
        open={true}
        autoHideDuration={6000}
        onClose={closeToastHandler}
      >
        <Alert onClose={closeToastHandler} severity="error"> {props.message} </Alert> 
      </Snackbar>
    }
    </>
  )
}

export default Toast