import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
  CircularProgress,
  Container,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Drawer,
  IconButton,
  TableCell,
  TableRow,
} from "@material-ui/core";
import { makeStyles, styled } from "@material-ui/core/styles";
import Toast from "../../utils/Toast";
import { IoArrowBackCircleSharp } from 'react-icons/io5';
import { AiOutlineHistory, AiOutlineLink } from 'react-icons/ai';
import { AiOutlineMenu } from 'react-icons/ai';
import Unsdg from "../../components/sustainability/unsdg";
import Objective from "../../components/sustainability/objective";
import { useHistory, useParams } from "react-router-dom";
import TabDocument from "../../components/sustainability/tab-document";
import { Close, CloseOutlined, Home, MoreVert } from "@material-ui/icons";
import { currentSustainabilityReport, saveSustainabilityReport } from "../../services/admin.service";
import { btoaToBlobUrl, isNotEmpty } from "../../utils/Generic";

import { useLocalStorage } from '../../hooks';
import History from "../../components/sustainability/history";
import { useCorporateState } from "../../redux/context/corporate-context";
import KnwoSdgs from "../../components/sustainability/know-sdgs";
import Track from "../../components/sustainability/track";
import Overview from "../../components/sustainability/overview";

import { RxDashboard } from 'react-icons/rx'
import { BsFileBarGraphFill } from 'react-icons/bs'
import { AiFillTool } from 'react-icons/ai'

import XLSX from "sheetjs-style";
import * as FileSaver from "file-saver";
import { UNSDG_GOALS } from "../../utils/unsdg-goals";
import { Dropdown, DropdownItem } from "@govtechsg/tradetrust-ui-components";
import SustainabilityDashboard from "../../components/sustainability/dashboard";

import { Switch, Route, withRouter, Redirect, useLocation } from "react-router-dom";
import ViewObjective from "../../components/sustainability/view-objective";
import TrackKPI from "../../components/sustainability/track-kpi";
import SustainabilityReport from "../../components/sustainability/sustainability_report";

const useStyles = makeStyles((theme) => ({
  root: {
    width: `calc(100% - ${300}px)`,
    marginLeft: 280,
    minHeight: "88vh",
  },
}));

const Sustainability = () => {

  const classes = useStyles();
  let history = useHistory();

  const toastInitialValues = {
    isOpen: false,
    isSuccess: false,
    isError: false,
    message: ""
  }

  const [toast, setToast] = useState(toastInitialValues);

  const closeToast = () => {
    return setToast((prev) => ({
      ...prev,
      isOpen: false,
      isSuccess: false,
      isError: false,
      message: ""
    }));
  };

  const showError = (errorMsg) => {
    setToast((prev) => ({
      ...prev,
      isOpen: true,
      isError: true,
      isSuccess: false,
      message: errorMsg
    }));
  }

  const showSuccess = (succMsg) => {
    setToast((prev) => ({
      ...prev,
      isOpen: true,
      isError: false,
      isSuccess: true,
      message: succMsg
    }));
  }

  useEffect(() => {
    document.title = `TruDoc | Sustainability`;
  }, []);

  const { getItem } = useLocalStorage();
  const userType = getItem("userType");

  let { activeTab } = useParams();

  const [goals, setGoals] = useState({});

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const [activeMenuTab, setActiveMenuTab] = useState("dashboard");
  const [defaultTabs, setDefaultTabs] = useState(["About", "Leadership Message", "References"]);

  const initialCompanyDocs = [
    { title: 'About', documents: [] },
    // { title: 'Leadership Message', documents: [] },
    // { title: 'References', documents: [] },
  ];

  const [companyDocuments, setCompanyDocuments] = useState(initialCompanyDocs);
  const [objectives, setObjectives] = useState([]);

  const [canSave, setCanSave] = useState(false);

  const [loading, setLoading] = useState(false);

  const [showNewModal, setShowNewModal] = useState(false);
  const [newTabName, setNewTabName] = useState('');

  const [report, setReport] = useState({});

  const corporateState = useCorporateState();

  const [sustainabilityData, setSustainabilityData] = useState([]);

  useEffect(() => {
    setSustainabilityData([]);
    if (isNotEmpty(corporateState?.sustainability_form_data)) {
      setSustainabilityData(JSON.parse(corporateState?.sustainability_form_data));
    }
  }, [corporateState]);

  const loadReport = (showLoading = true) => {
    if (showLoading)
      setLoading(true);
    currentSustainabilityReport().then(res => {
      if (showLoading)
        setLoading(false);
      const response = res.data;
      setReport(response.data);
    }).catch(err => {
      if (showLoading)
        setLoading(false);
    });
  }

  useEffect(() => {
    loadReport();
  }, []);

  useEffect(() => {
    if (userType === "admin") {
      setCanSave(true);
    } else {
      setCanSave(false);
    }
  }, [userType]);

  useEffect(() => {
    if (isNotEmpty(report?.id)) {

      let tabsTemp = initialCompanyDocs;
      let goalsTemp = {};

      if (report.sustainability_tabs !== undefined && report.sustainability_tabs.length > 0) {

        for (let tab of report.sustainability_tabs) {
          let tabDocuments = [];
          if (tab.sustainability_documents !== undefined && tab.sustainability_documents.length > 0) {
            for (let document of tab.sustainability_documents) {
              let documentData = document?.document;
              tabDocuments.push({
                id: document.document_id,
                name: documentData?.name,
                file_type: documentData?.file_type,
                is_folder: documentData?.is_folder
              });
            }
          }

          let tabTitle = tab.title;
          let tabFound = false;

          let index = 0;
          for (let tabTemp of tabsTemp) {
            if (tabTemp.title === tabTitle) {
              tabFound = true;
              tabsTemp[index]['documents'] = tabDocuments;
              tabsTemp[index]['id'] = tab?.id
            }
            index++;
          }

          if (tabFound === false) {
            tabsTemp.push({
              title: tabTitle,
              documents: tabDocuments,
              id: tab?.id
            });
          }

        }

        // setCompanyDocuments(tabsTemp);

      }

      if (report.sustainability_goals !== undefined && report.sustainability_goals.length > 0) {
        for (let goal of report.sustainability_goals) {
          let subjectTemp = {};

          if (goal.sustainability_goal_subjects !== undefined && goal.sustainability_goal_subjects.length > 0) {
            for (let subjectItem of goal.sustainability_goal_subjects) {
              subjectTemp[subjectItem?.subject] = {
                id: subjectItem?.id,
                title: subjectItem?.subject,
                businessImpactRelevance: {
                  value: subjectItem.business_impact_relevance_value,
                  textValue: subjectItem.business_impact_relevance
                },
                peopleEnvironmentImpactRelevance: {
                  value: subjectItem.people_environment_impact_relevance_value,
                  textValue: subjectItem.people_environment_impact_relevance
                },
              };
            }
          }
          console.log("GOAL", goal)
          if (!goalsTemp.hasOwnProperty(goal.goal_number)) {
            goalsTemp[goal.goal_number] = {
              subjects: subjectTemp,
              title: goal.goal_title,
              id: goal?.id
            };
          }

          // console.log("goalsTemp", goalsTemp);
          setGoals(goalsTemp);

        }

        if (report.sustainability_goal_objectives !== undefined && report.sustainability_goal_objectives.length > 0) {
          let objectivesData = [];
          for (let goalObjective of report.sustainability_goal_objectives) {
            let kpis = [];
            let subjectItems = [];

            if (goalObjective.sustainability_kpis !== undefined && goalObjective.sustainability_kpis.length > 0) {
              for (let kipItem of goalObjective.sustainability_kpis) {
                kpis.push({
                  id: kipItem.id,
                  title: kipItem?.kpi
                });
              }
            }

            if (goalObjective.sustainability_goal_subjects !== undefined && goalObjective.sustainability_goal_subjects.length > 0) {
              for (let subItem of goalObjective.sustainability_goal_subjects) {
                subjectItems.push({
                  goal: subItem.goal_number,
                  subject: subItem?.subject
                });
              }
            }

            console.log("goalObjective", goalObjective);

            objectivesData.push({
              id: goalObjective.id,
              title: goalObjective.objective,
              kpis: kpis,
              subjects: subjectItems
            });
          }

          setObjectives(objectivesData);

        }


      }

      // console.log("report", report);
    }
  }, [report]);

  const addMoreTabs = () => {
    setShowNewModal(true);
  }

  const handleShowNewModalClose = () => {
    setNewTabName('');
    setShowNewModal(false);
  }

  const removeTab = (indexToRemove) => {
    if (window.confirm("Are you sure you want to remove this tab?") === true) {
      let companyDocumentsTemp = [...companyDocuments];
      companyDocumentsTemp = companyDocumentsTemp.slice(0, indexToRemove).concat(companyDocumentsTemp.slice(indexToRemove + 1));
      setCompanyDocuments(companyDocumentsTemp);
      let nextTab = 0;
      if (indexToRemove > 0) {
        nextTab = indexToRemove - 1;
      }
      changeTab("tab/" + nextTab);
    }
  }

  const saveNewTab = () => {
    let newTabNameTemp = newTabName.toString().trim();
    if (newTabNameTemp !== "") {
      let companyDocumentsTemp = [...companyDocuments];
      companyDocumentsTemp.push({
        title: newTabNameTemp,
        documents: []
      });
      setCompanyDocuments(companyDocumentsTemp);
      setNewTabName('');
      setShowNewModal(false);
    } else {
      showError("Tab name is required");
    }
  }

  const changeTab = (tabName) => {
    history.push("/sustainability/" + tabName);
  }

  useEffect(() => {
    console.log("goals", goals);
  }, [goals]);

  const goBack = () => {
    history.push("/dashboard");
  }

  const handleReportSave = (showLoading = true) => {
    if (showLoading)
      setLoading(true);
    saveSustainabilityReport({
      id: report?.id,
      goals: goals,
      objectives: objectives,
      companyDocuments: companyDocuments
    }).then(res => {
      if (showLoading)
        setLoading(false);
      if (res.data.success === true) {
        showSuccess("Report has been Saved");
        loadReport(false)
      } else {
        showError(res.data.message);
      }
    }).catch(err => {
      if (showLoading)
        setLoading(false);
      showError("Failed to save Report");
    });
  }

  const handleReload = () => {
    // loadReport();
  }

  const formAnswers = (item) => {
    if (item.hasOwnProperty("answers")) {
      return item.answers;
    } else {
      return item;
    }
  }

  const generateNow = () => {
    let excelData = [];

    for (let goal of Object.keys(goals)) {
      let goalItem = goals[goal];
      let unsdgGoal = UNSDG_GOALS[goal];
      for (let objective of goalItem.objectives) {
        let excelRow = {
          "Goal": goal,
          "Title": unsdgGoal?.title,
          "Relevance": goalItem?.relevance?.textValue,
          "Progress": objective?.progress?.textValue,
          "Impact": objective?.impact,
          "Current Status": objective?.status,
          "Action Plan": objective?.plan,
        };
        excelData.push(excelRow);
      }
    }

    const goalsSheetData = XLSX.utils.json_to_sheet(excelData);

    let googleFormsData = [];

    for (let sustainabilityItem of sustainabilityData) {
      sustainabilityItem = formAnswers(sustainabilityItem);
      for (let responseData of sustainabilityItem) {
        let formRow = {
          "Question": responseData?.question,
          "Answer": responseData?.answer
        };
        googleFormsData.push(formRow);
      }
    }

    const googleFormsSheetData = XLSX.utils.json_to_sheet(googleFormsData);

    const fileType = "";
    const wb = { Sheets: { 'Goals': goalsSheetData, 'Sustainability Details': googleFormsSheetData }, SheetNames: ['Goals', 'Sustainability Details'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, 'data.xlsx');
  }

  const changeActiveTab = (tab) => {
    setActiveMenuTab(tab);
    setIsSidebarOpen(false);
    switch (tab) {
      case 'dashboard':
        history.push("/sustainability/dashboard");
        break;
      case 'myEsgImpacts':
        history.push("/sustainability/unsdg/goals");
        break;
      case 'configureObjectives':
        history.push("/sustainability/unsdg/goals");
        break;
    }
  }

  const openSideMenu = () => {
    setIsSidebarOpen(true);
  }

  return (
    <>

      {/* {console.log(report)}
      {console.log(companyDocuments)}
      {console.log(goals)} */}

      {toast.isOpen && <Toast message={toast.message} isError={toast.isError} isSuccess={toast.isSuccess} closeToast={closeToast} />}
      <main className={`new-design ${activeTab}`}>

        {/* <aside className={`sidebar-new ${isSidebarOpen ? 'show' : ''} `}> */}
        <Drawer className="sidebar-new" open={isSidebarOpen ? 'left' : ''} onClose={e => setIsSidebarOpen(false)}>
          <div className='logo-image'>
            {
              isNotEmpty(corporateState?.business_logo) ? <>
                <img src={btoaToBlobUrl(corporateState?.business_logo)} alt={corporateState?.business_name} />
              </> : <>
                <img src='/static/media/logo.c893cafe.png' />
              </>
            }
          </div>
          <ul>
            <li>
              <a className={` ${activeMenuTab === 'dashboard' ? 'active' : ''} `} onClick={() => changeActiveTab("dashboard")} href={undefined}> <RxDashboard size={22} /> Dashboard  </a>
            </li>
            {/* <li>
              <a className={` ${activeMenuTab === 'myEsgImpacts' ? 'active' : ''} `} onClick={() => changeActiveTab("myEsgImpacts")} href={undefined}> <BsFileBarGraphFill size={22} /> My ESG Impacts  </a>
            </li> */}
            <li>
              <a className={` ${activeMenuTab === 'configureObjectives' ? 'active' : ''} `} onClick={() => changeActiveTab("configureObjectives")} href={undefined}> <AiFillTool size={22} /> Configure Objectives  </a>
            </li>
            <li>
              <a onClick={() => goBack()} href={undefined}> <Home size={22} /> Back to Home  </a>
            </li>
            <li>
              <a className={` ${activeMenuTab === 'configureObjectives' ? 'active' : ''} `} onClick={() => setIsSidebarOpen(false)} href={undefined}> <CloseOutlined size={22} /> Close  </a>
            </li>
          </ul>
          {/* </aside> */}
        </Drawer>


        <div className="sustainability-layout">

          <Container maxWidth="xl">

            <div className='navbar_layout'>
              <div className='navbar_here'>
                <div className='navbar_main'>
                  <div className='go_back_button'>
                    <button type="button">
                      {/* <IoArrowBackCircleSharp size={35} color={'#66D0A4'} /> */}
                      <div className='menu-button '>
                        <button onClick={() => openSideMenu()}>
                          <AiOutlineMenu size={20} color={'white'} />
                        </button>
                      </div>
                      {
                        isNotEmpty(corporateState?.business_logo) ? <>
                          <img src={btoaToBlobUrl(corporateState?.business_logo)} alt={corporateState?.business_name} />
                        </> : <>
                          <img src='/static/media/logo.c893cafe.png' />
                        </>
                      }
                    </button>
                  </div>

                  {
                    activeTab === "dashboard" ? <>
                      <div className="navbar_links navbar_links-dashboard">
                        <a href={undefined}>
                          {
                            isNotEmpty(corporateState?.business_logo) ? <>
                              <img src={btoaToBlobUrl(corporateState?.business_logo)} alt={corporateState?.business_name} />
                            </> : <>
                              <img src='/static/media/logo.c893cafe.png' />
                            </>
                          }
                        </a>
                      </div>
                    </> : <>

                      <div className="navbar_links">

                        {
                          corporateState?.sustainability_report ?
                            <>
                              <a href={undefined} onClick={(e) => changeTab('report')} className={`${(activeTab == "sustainability-report") ? 'active' : ''}`}>Sustainability Report 2022</a>
                              <a href={undefined} className='divider'><span ></span></a>
                            </> : <></>
                        }


                        <div className="navbar_links_tabs">
                          {
                            companyDocuments.map((companyDocument, index) => {
                              return <>
                                {
                                  (!defaultTabs.includes(companyDocument.title)) ? <>
                                    {/* <span className={`combo ${activeTab == "tab/" + index ? 'active' : ''}`}>
                                <a href={undefined} onClick={(e) => changeTab("tab/" + index)} to={'/about'} >{companyDocument.title}</a>
                                {
                                  canSave ? <>
                                    <a onClick={(e) => removeTab(index)} className="remove" href={undefined}>x</a>
                                  </> : <></>
                                }
                              </span> */}
                                  </> : <>
                                    {
                                      companyDocument.title == 'About' &&
                                      <a href={undefined} onClick={(e) => changeTab("tab/" + index)} className={`${activeTab == "tab-" + index ? 'active' : ''}`} to={'/about'} >{companyDocument.title}</a>
                                    }
                                  </>
                                }
                              </>;
                            })
                          }
                        </div>

                        {/* <Dropdown
                          data-testid="tabsDropdown"
                          dropdownButtonText="View All"
                          classNameRoot="sustainbility-menu-dropdown-main"
                          className="sustainbility-menu-dropdown"
                          classNameMenu="sustainbility-menu-dropdown-list"
                        >
                          {
                            companyDocuments.map((companyDocument, index) => {
                              return <>
                                {
                                  (!defaultTabs.includes(companyDocument.title)) ? <>
                                    <DropdownItem
                                      data-testid={"newMenu"}
                                    >
                                      <a href={undefined} onClick={(e) => changeTab("tab/" + index)} to={'/about'} >{companyDocument.title}</a>
                                      {
                                        canSave ? <>
                                          <a onClick={(e) => removeTab(index)} className="remove" href={undefined}>x</a>
                                        </> : <></>
                                      }
                                    </DropdownItem>
                                  </> : null
                                }
                              </>;
                            })
                          }
                        </Dropdown> */}

                        {/* {
                          canSave ? <>
                            <a className="add-more-unsdg-tabs" href={undefined} onClick={addMoreTabs}>+ Add more</a>
                          </> : <></>
                        } */}

                        <a href={undefined} className='divider'><span ></span></a>

                        <a href={undefined} onClick={(e) => changeTab("know-sdgs")} className={`${(activeTab == "know-sdgs") ? 'active' : ''}`}>Know your SDG's</a>

                        <a href={undefined} className='divider'><span ></span></a>

                        <a href={undefined} onClick={(e) => changeTab("unsdg/goals")} className={`${(activeTab == "unsdg" || activeTab == "unsdg-relevance") ? 'active' : ''}`}>Material Topics</a>

                        <a href={undefined} onClick={(e) => changeTab("objectives")} className={`${activeTab == "objectives" ? 'active' : ''}`}>Set Objectives</a>

                        <a href={undefined} onClick={(e) => changeTab("track")} className={`${activeTab == "track" ? 'active' : ''}`}>Track Your Objectives</a>

                        <a className="show-zoom-link" onClick={(e) => changeTab("history")} href={undefined} to={'/'}><span style={{ alignItems: 'center' }}>History</span></a>

                        <a className="show-zoom-link" onClick={generateNow} href={undefined}><span style={{ alignItems: 'center' }}>Generate Now </span></a>

                        {/* <a href={undefined} onClick={(e) => changeTab("overview")} className={`${activeTab == "overview" ? 'active' : ''}`}>Journey</a> */}

                      </div>

                      <div className='navbar_links unshow-zoom-link'>
                        {
                          canSave ? <>
                            <Dropdown
                              data-testid="tabsDropdown"
                              dropdownButtonText={<MoreVert />}
                              classNameRoot="sustainbility-menu-dropdown-main sustainbility-dropdown-option"
                              className="sustainbility-menu-dropdown"
                              classNameMenu="sustainbility-menu-dropdown-list"
                            >
                              <DropdownItem
                                data-testid={"newMenu"}
                              >
                                {/* <a onClick={(e) => changeTab("history")} className={`${activeTab == "history" ? 'active' : ''}`} href={undefined} to={'/'}><span style={{ display: 'flex', alignItems: 'center' }}><AiOutlineHistory />&nbsp; History</span></a> */}
                                <a onClick={(e) => changeTab("history")} href={undefined} to={'/'}><span style={{ display: 'flex', alignItems: 'center' }}><AiOutlineHistory />&nbsp; History</span></a>
                              </DropdownItem>
                              <DropdownItem
                                data-testid={"newMenu"}
                              >
                                <a onClick={generateNow} href={undefined}><span style={{ display: 'flex', alignItems: 'center' }}><AiOutlineLink />&nbsp; Generate Now </span></a>
                              </DropdownItem>
                            </Dropdown>
                          </> : null
                        }
                      </div>

                    </>
                  }

                </div>
              </div>
              <div className='outlet_here'>
                <div className='toggle_button right-unsdg-btns'>
                  <div className='menu-button'>
                    <button onClick={() => openSideMenu()}>
                      <AiOutlineMenu size={20} color={'white'} />
                    </button>
                  </div>

                  {
                    isNotEmpty(corporateState?.business_logo) ? <>
                      <img src={btoaToBlobUrl(corporateState?.business_logo)} alt={corporateState?.business_name} width="200px" />
                    </> : <>
                      <img src='/static/media/logo.c893cafe.png' width="200px" />
                    </>
                  }
                  {/* <img src='/assets/images/logo.png' /> */}
                  <button className="right-unsdg-btn" onClick={() => document.body.classList.toggle('toggle')}><AiOutlineMenu size={30} /></button>
                </div>
              </div>
            </div>

            <Container>

              {
                loading ? <>
                  <div className="text-center">
                    <CircularProgress color="primary" size={20} />
                  </div>
                </> : <>

                  {
                    isNotEmpty(report?.id) ? <>

                      <Switch>

                        <Route exact path={["/sustainability/report"]}>
                          <SustainabilityReport loadData={() => loadReport(false)} goals={goals} objectives={objectives} report={report} />
                        </Route>

                        <Route exact path={["/sustainability/dashboard"]}>
                          <SustainabilityDashboard loadData={() => loadReport(false)} goals={goals} objectives={objectives} report={report} />
                        </Route>

                        <Route exact path={["/sustainability/objective/:objectiveId"]}>
                          <ViewObjective loadData={() => loadReport(false)} goals={goals} report={report} objectives={objectives} />
                        </Route>

                        <Route exact path={["/sustainability/track-kpi/:objectiveId/:kpiId"]}>
                          <TrackKPI setReport={setReport} goals={goals} report={report} objectives={objectives} />
                        </Route>

                        <Route exact path={["/sustainability/tab/:tabId"]}>
                          <TabDocument canSave={canSave} report={report} onReload={handleReload} onSave={handleReportSave} companyDocuments={companyDocuments} setCompanyDocuments={setCompanyDocuments} />
                        </Route>

                        <Route exact path={["/sustainability/know-sdgs"]}>
                          <KnwoSdgs canSave={canSave} onSave={handleReportSave} companyDocuments={companyDocuments} goals={goals} setGoals={setGoals} />
                        </Route>

                        <Route exact path={["/sustainability/unsdg/:tabId"]}>
                          <Unsdg canSave={canSave} onSave={handleReportSave} companyDocuments={companyDocuments} objectives={objectives} goals={goals} setGoals={setGoals} />
                        </Route>

                        <Route exact path={["/sustainability/objectives"]}>
                          <Objective objectives={objectives} loadData={() => loadReport(false)} setObjectives={setObjectives} canSave={canSave} onSave={handleReportSave} companyDocuments={companyDocuments} goals={goals} setGoals={setGoals} />
                        </Route>

                        <Route exact path={["/sustainability/track"]}>
                          <Track objectives={objectives} loadData={() => loadReport()} canSave={canSave} onSave={handleReportSave} companyDocuments={companyDocuments} goals={goals} setGoals={setGoals} />
                        </Route>

                        <Route exact path={["/sustainability/overview"]}>
                          <Overview canSave={canSave} onSave={handleReportSave} companyDocuments={companyDocuments} goals={goals} setGoals={setGoals} />
                        </Route>

                        <Route exact path={["/sustainability/history"]}>
                          <History />
                        </Route>

                      </Switch>

                      {/* {
                        activeTab.toString().startsWith("tab-") ? <>
                          <TabDocument canSave={canSave} report={report} onReload={handleReload} onSave={handleReportSave} companyDocuments={companyDocuments} setCompanyDocuments={setCompanyDocuments} />
                        </> : null
                      } */}

                      {/* {
                        activeTab == "know-sdgs" ? <>
                          <KnwoSdgs canSave={canSave} onSave={handleReportSave} companyDocuments={companyDocuments} goals={goals} setGoals={setGoals} />
                        </> : null
                      } */}

                      {/* {
                        (activeTab == "unsdg" || activeTab == "unsdg-relevance") ? <>
                          <Unsdg canSave={canSave} onSave={handleReportSave} companyDocuments={companyDocuments} goals={goals} objectives={objectives}  setGoals={setGoals} />
                        </> : null
                      } */}

                      {/* {
                        activeTab == "objectives" ? <>
                          <Objective objectives={objectives} setObjectives={setObjectives} canSave={canSave} onSave={handleReportSave} companyDocuments={companyDocuments} goals={goals} setGoals={setGoals} />
                        </> : null
                      } */}

                      {/* {
                        activeTab == "track" ? <>
                          <Track objectives={objectives} canSave={canSave} onSave={handleReportSave} companyDocuments={companyDocuments} goals={goals} setGoals={setGoals} />
                        </> : null
                      } */}

                      {/* {
                        activeTab == "overview" ? <>
                          <Overview canSave={canSave} onSave={handleReportSave} companyDocuments={companyDocuments} goals={goals} setGoals={setGoals} />
                        </> : null
                      }

                      {
                        activeTab == "history" ? <>
                          <History />
                        </> : null
                      } */}

                    </> : <>
                      <div className="text-center">
                        <h4>No Report Found</h4>
                      </div>
                    </>
                  }
                </>
              }

            </Container>

          </Container>

        </div>

      </main>

      <Dialog
        onClose={handleShowNewModalClose}
        fullWidth={true}
        maxWidth='md'
        open={showNewModal}
        className={`theme-base-modal sustainability-layout`}
        keepMounted={false}
        PaperProps={{
          style: { borderRadius: 20, maxHeight: 'calc(100% - 44px)' }
        }}
      >
        <DialogTitle>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span className="capital-case">Add New Tab</span>
            <IconButton onClick={handleShowNewModalClose} style={{ marginLeft: 'auto' }}>
              <Close />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent dividers >
          <DialogContentText>
            <div className="sus-objective-box">
              <input className='sus-objective-field' onChange={(e) => setNewTabName(e?.target?.value)} placeholder='Enter Tab Name' value={newTabName} />
              <button onClick={saveNewTab} className="btn-sustainability primary mt-10px">Save</button>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>

    </>
  );
};

export default Sustainability;