import { createContext, useContext } from "react";
import io from "socket.io-client";
import { API_Base_URL } from "./services.constant";
import store from "../redux/store";

const socket = io('https://app.trudoc.io', {
	path: '/api/socket.io',
	transports: ['websocket'],
	secure: true,
	auth: (cb) => {
		const data = store.getState().user;
		console.log("data", data)
		if (data) {
			cb({ token: data.token });
		} else {
			cb();
		}
	},
	query: {
		// token: new Date().getTime()   
	}
});

socket.on("connect_error", (err) => {
	console.log("connect_error", err);   
	setTimeout(() => {
		socket.connect();
	}, 5000);
});

socket.on("connect", () => {
	socket.emit('ping', null);
});

const SocketContext = createContext();

const useSocketContext = () => useContext(SocketContext);

const SocketProvider = ({ children }) => {
	return (
		<SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
	);
};

console.log("socket con")

export { useSocketContext, SocketProvider };