import { GET_WALLET_DETAILS, REMOVE_WALLET_DETAILS } from '../redux.constants';
import { getWallet } from '../../services/wallet.service';

export const getWalletDetails = () => {
  return dispatch => {
    getWallet().then(res => {
      if (res && res.status === 200 && res?.data) {
        dispatch({
          type: GET_WALLET_DETAILS,
          payload: res?.data
        });
      }
    })
  }
}

export const removeWalletDetails = () => {
  return dispatch => {
    dispatch({
      type: REMOVE_WALLET_DETAILS
    });
  }
}