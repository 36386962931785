import React, { useEffect, useRef, useState } from "react";
import { makeStyles, styled } from "@material-ui/core/styles";
import GoalBoxSubjects from "./goal-box-subjects";
import { UNSDG_GOALS, UNSDG_GOAL_CATEGORIES } from "../../utils/unsdg-goals";

const useStyles = makeStyles((theme) => ({
    root: {
        width: `calc(100% - ${300}px)`,
        marginLeft: 280,
        minHeight: "88vh",
    },
}));

const UnsdgSubjectSelector = (props) => {

    const [activeTab, setActiveTab] = useState("environment");

    const chooseTab = (tabType) => {
        setActiveTab(tabType);
    }

    const sustainabilityGoals = UNSDG_GOAL_CATEGORIES;

    const { setGoals, goals, canSave } = props;

    const handleAdapt = (goal, goalDetails, subject) => {
        if (canSave) {
            let tempGoals = { ...goals };

            if (!tempGoals.hasOwnProperty(goal)) {
                tempGoals[goal] = {
                    title: UNSDG_GOALS[goal].title,
                    subjects: {},
                    objectives: [
                        {
                            impact: "",
                            status: "",
                            plan: "",
                            progress: {
                                value: 0,
                                textValue: '0%'
                                // textValue: 'Not Started Yet'
                            }
                        }
                    ]
                };
            }
            if (tempGoals[goal].subjects.hasOwnProperty(subject)) {
                delete tempGoals[goal].subjects[subject];
                if (Object.keys(tempGoals[goal].subjects).length == 0 && tempGoals.hasOwnProperty(goal)) {
                    delete tempGoals[goal]
                }
            } else {
                tempGoals[goal].subjects[subject] = {
                    title: subject,
                    businessImpactRelevance: {
                        value: 0,
                        textValue: ''
                    },
                    peopleEnvironmentImpactRelevance: {
                        value: 0,
                        textValue: ''
                    },
                };
            }

            setGoals(tempGoals);
        }
    }

    return (
        <>

            <main className='new-design'>
                <div className='container main-wrapper'>
                    <div className='tabs'>
                        <a href={undefined} className={`tab-head-options ${activeTab === "environment" ? 'active' : ''} `} onClick={(e) => chooseTab("environment")}>Environment</a>
                        <a href={undefined} className={`tab-head-options color-purple ${activeTab === "social" ? 'active' : ''} `} onClick={(e) => chooseTab("social")}>Social</a>
                        <a href={undefined} className={`tab-head-options color-pink ${activeTab === "governance" ? 'active' : ''} `} onClick={(e) => chooseTab("governance")}>Governance</a>
                    </div>

                    {
                        activeTab === "environment" ? <>

                            <div className='main-unsdg-info'>

                                <div><h2>Environment</h2></div>
                                <div><img src='/assets/images/envclr.png' alt='environment' /></div>
                                <div className='grey-li'>
                                    <ul>
                                        <li>Reducing carbon emission</li>
                                        <li>Sustainable product design</li>
                                        <li>Sustainable packaging</li>
                                    </ul>
                                </div>
                                <div className='blue-li'>
                                    <ul>
                                        <li>Emissions</li>
                                        <li>Energy</li>
                                        <li>Product Packaging and Materials</li>
                                        <li>Waste/Recycle</li>
                                        <li>Water</li>
                                    </ul>
                                </div>

                            </div>

                            <section className='scrollable-box'>

                                {
                                    sustainabilityGoals.environment.goals.length > 0 ? <>
                                        {sustainabilityGoals.environment.goals.map((goal, index) => {
                                            return <>
                                                <GoalBoxSubjects canSave={canSave} key={index} goals={goals} onAdapt={handleAdapt} goal={goal} />
                                            </>
                                        })}
                                    </> : null
                                }

                            </section>

                        </> : null
                    }

                    {
                        activeTab === "social" ? <>

                            <div className='main-unsdg-info color-purple'>

                                <div><h2>Social</h2></div>
                                <div><img src='/assets/images/socclrd.png' alt='social' /></div>
                                <div className='grey-li'>
                                    <ul>
                                        <li>Inclusive product deign</li>
                                        <li>Philanthropy & community engagement</li>
                                        <li>Fostering an inclusive workplace</li>
                                    </ul>
                                </div>
                                <div className='blue-li'>
                                    <ul>
                                        <li>Community/Philanthropy</li>
                                        <li>Diversity and Inclusion</li>
                                        <li>Human Rights</li>
                                        <li>Labor Practices</li>
                                        <li>Health and Safety</li>
                                    </ul>
                                </div>

                            </div>

                            <section className='scrollable-box color-purple'>

                                {
                                    sustainabilityGoals.social.goals.length > 0 ? <>
                                        {sustainabilityGoals.social.goals.map((goal, index) => {
                                            return <>
                                                <GoalBoxSubjects canSave={canSave} key={index} goals={goals} onAdapt={handleAdapt} goal={goal} />
                                            </>
                                        })}
                                    </> : null
                                }

                            </section>

                        </> : null
                    }

                    {
                        activeTab === "governance" ? <>

                            <div className='main-unsdg-info color-pink'>

                                <div><h2>Governance</h2></div>
                                <div><img src='/assets/images/govclrd.png' alt='governance' /></div>
                                <div className='grey-li'>
                                    <ul>
                                        <li>Leading and integrity</li>
                                        <li>Global company</li>
                                    </ul>
                                </div>
                                <div className='blue-li'>
                                    <ul>
                                        <li>Ethics/Integrity</li>
                                        <li>Data Privacy and Security</li>
                                        <li>Product Quality</li>
                                        <li>Regulatory/Compliance</li>
                                        <li>Innovation</li>
                                    </ul>
                                </div>

                            </div>

                            <section className='scrollable-box color-pink'>

                                {
                                    sustainabilityGoals.governance.goals.length > 0 ? <>
                                        {sustainabilityGoals.governance.goals.map((goal, index) => {
                                            return <>
                                                <GoalBoxSubjects canSave={canSave} key={index} goals={goals} onAdapt={handleAdapt} goal={goal} />
                                            </>
                                        })}
                                    </> : null
                                }

                            </section>

                        </> : null
                    }

                </div>
            </main>

        </>
    );
};

export default UnsdgSubjectSelector;