import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@material-ui/core";
import { makeStyles, styled } from "@material-ui/core/styles";
import Toast from "../../utils/Toast";
import RoundProgresBar from "../charts/RoundProgressBar";
import { UNSDG_GOALS, UNSDG_GOAL_CATEGORIES } from "../../utils/unsdg-goals";
import { Close } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { goalsParseCategories } from "../../utils/Generic";
import UnsdgGrid from "./unsdg-grid";
import { AiFillPlusCircle, AiOutlinePlus } from "react-icons/ai";
import SetObjective from "./set-objective";
import UnsdgSubjectSelector from "./unsdg-subject-selectors";

const useStyles = makeStyles((theme) => ({
  root: {
    width: `calc(100% - ${300}px)`,
    marginLeft: 280,
    minHeight: "88vh",
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({

}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  height: '118px',
  border: 'none',
  '&:nth-of-type(even)': {
    backgroundColor: '#334bc120',
    border: 'none'
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const Objective = (props) => {

  const { goals, setGoals, companyDocuments, canSave, objectives, setObjectives, onSave, loadData } = props;

  const [modalOpen, setModalOpen] = useState(false);

  const [addedObjective, setAddedObjective] = useState(false)

  const [openObjectiveModal, setOpenObjectiveModal] = useState(false);

  const [modalTitle, setModalTitle] = useState('');
  const [modalObjective, setModalObjective] = useState('');

  const [modalGoal, setModalGoal] = useState(-1);
  const [modalGoalObjectiveIndex, setModalGoalObjectiveIndex] = useState(-1);
  const [modalObjectiveType, setModalObjectiveType] = useState('');

  useEffect(() => {
    if (addedObjective == true) {
      props.onSave(false)
      setAddedObjective(false)
    }
  }, [addedObjective])




  let history = useHistory();

  const classes = useStyles();

  const toastInitialValues = {
    isOpen: false,
    isSuccess: false,
    isError: false,
    message: ""
  }

  const [toast, setToast] = useState(toastInitialValues);

  const closeToast = () => {
    return setToast((prev) => ({
      ...prev,
      isOpen: false,
      isSuccess: false,
      isError: false,
      message: ""
    }));
  };

  const showError = (errorMsg) => {
    setToast((prev) => ({
      ...prev,
      isOpen: true,
      isError: true,
      isSuccess: false,
      message: errorMsg
    }));
  }

  const showSuccess = (succMsg) => {
    setToast((prev) => ({
      ...prev,
      isOpen: true,
      isError: false,
      isSuccess: true,
      message: succMsg
    }));
  }

  const handleCloseModal = () => {
    setModalTitle('');
    setModalObjective('');
    setModalObjectiveType('');
    setModalGoal(-1);
    setModalGoalObjectiveIndex(-1);
    setModalOpen(false);
  }

  const addMoreObjective = (goal) => {
    let tempGoals = { ...goals };
    if (tempGoals.hasOwnProperty(goal)) {
      console.log("obbb", tempGoals[goal].objectives);
      tempGoals[goal].objectives.push({
        impact: "",
        status: "",
        plan: ""
      });
    }
    setGoals(tempGoals);
  }

  const removeObjective = (goal, indexToRemove) => {
    if (window.confirm("Are you sure you want to remove it?") === true) {
      let tempGoals = { ...goals };
      if (tempGoals.hasOwnProperty(goal)) {
        let goalObjectives = tempGoals[goal].objectives;
        if (goalObjectives.length > 1) {
          goalObjectives = goalObjectives.slice(0, indexToRemove).concat(goalObjectives.slice(indexToRemove + 1));
          tempGoals[goal].objectives = goalObjectives;
          setGoals(tempGoals);
        }
      }
    }
  }

  const handleInputClick = (goal, index, type) => {
    let tempGoals = { ...goals };
    if (tempGoals.hasOwnProperty(goal)) {
      let goalObjectives = tempGoals[goal].objectives;
      if (goalObjectives.hasOwnProperty(index)) {
        let goalObjectiveData = goalObjectives[index];

        setModalTitle(UNSDG_GOALS[goal].title + " - " + type);
        setModalObjective(goalObjectiveData[type]);
        setModalObjectiveType(type);
        setModalGoal(goal);
        setModalGoalObjectiveIndex(index);

        setModalOpen(true);
      }
    }
  }

  const saveModalObjective = () => {
    let tempGoals = { ...goals };
    if (tempGoals.hasOwnProperty(modalGoal)) {
      let goalObjectives = tempGoals[modalGoal].objectives;
      if (goalObjectives.hasOwnProperty(modalGoalObjectiveIndex)) {
        let goalObjectiveData = goalObjectives[modalGoalObjectiveIndex];
        if (goalObjectiveData.hasOwnProperty(modalObjectiveType)) {
          tempGoals[modalGoal].objectives[modalGoalObjectiveIndex][modalObjectiveType] = modalObjective;
          setGoals(tempGoals);
          handleCloseModal();
        }
      }
    }
  }

  const handleModalObjectiveChange = (e) => {
    if (canSave) {
      setModalObjective(e.target.value);
    }
  }

  const handleGoBack = () => {
    history.push("/sustainability/unsdg/relevance");
  }

  const handleGoNext = () => {
    history.push("/sustainability/track");
  }

  const openSetObjectiveModal = () => {
    setOpenObjectiveModal(true);
  }

  const handleSetObjectiveModalClose = () => {
    setOpenObjectiveModal(false);
  }

  return (
    <>

      {toast.isOpen && <Toast message={toast.message} isError={toast.isError} isSuccess={toast.isSuccess} closeToast={closeToast} />}

      <div className='objective_main objective_main_showcase undsg_main'>
        <h1 className="text-gradient text-center">Set Objectives</h1>
        <Box>

          <div className="new-design">
            <div className="main-discrimination-box">

              <button className="navy-btn set-objective" onClick={openSetObjectiveModal}>
                <AiFillPlusCircle /> Set Objective
              </button>

              <SetObjective loadData={loadData} setAddedObjective={setAddedObjective} objectives={objectives} setObjectives={setObjectives} setGoals={setGoals} goals={goals} handleClose={handleSetObjectiveModalClose} open={openObjectiveModal} />

              <Grid container spacing={3}>
                <Grid item md={7} xs={12}>
                  <UnsdgGrid className="mx-auto" goals={goals} objectives={objectives} />
                </Grid>
                <Grid item md={5} xs={12}>
                  <UnsdgSubjectSelector canSave={false} goals={goals} setGoals={setGoals} />
                </Grid>
              </Grid>

            </div>
          </div>

        </Box>

        <Box className="btn-sustainability-box">
          <div className="left">
            {
              canSave ? <>
                <button onClick={(e) => props?.onSave()} className="btn-sustainability secondary">
                  Save
                </button>
              </> : null
            }
          </div>
          <div className="right">
            <button onClick={handleGoBack} className="btn-sustainability primary-out me-10px">
              Back
            </button>
            <button onClick={handleGoNext} className="btn-sustainability primary">
              Next
            </button>
          </div>
        </Box>

      </div>

      <Dialog
        onClose={handleCloseModal}
        fullWidth={true}
        maxWidth='md'
        open={modalOpen}
        className={`theme-base-modal sustainability-layout`}
        keepMounted={false}
        PaperProps={{
          style: { borderRadius: 20, maxHeight: 'calc(100% - 44px)' }
        }}
      >
        <DialogTitle>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span className="capital-case">{modalTitle}</span>
            <IconButton onClick={handleCloseModal} style={{ marginLeft: 'auto' }}>
              <Close />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent dividers >
          <DialogContentText>
            <div className="sus-objective-box">
              <textarea className='sus-objective-field' onChange={handleModalObjectiveChange} placeholder='Enter Objective Details...' value={modalObjective} cols="30" rows="20"></textarea>
              {
                canSave ? <>
                  <button onClick={saveModalObjective} className="btn-sustainability primary mt-10px">Save</button>
                </> : null
              }
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>

    </>
  );
};

export default Objective;