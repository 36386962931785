import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@material-ui/core";
import { makeStyles, styled } from "@material-ui/core/styles";
import Toast from "../../utils/Toast";
import RoundProgresBar from "../charts/RoundProgressBar";
import { UNSDG_GOALS, UNSDG_GOAL_CATEGORIES } from "../../utils/unsdg-goals";
import { CloseOutlined } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { goalsParseCategories, isEmpty, isNotEmpty } from "../../utils/Generic";
import UnsdgGrid from "./unsdg-grid";
import { AiOutlinePlus } from "react-icons/ai";
import UnsdgSubjectNumber from "./unsdg-subject-number";
import ListObjective from "./list-objective";

const useStyles = makeStyles((theme) => ({
  root: {
    width: `calc(100% - ${300}px)`,
    marginLeft: 280,
    minHeight: "88vh",
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({

}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  height: '118px',
  border: 'none',
  '&:nth-of-type(even)': {
    backgroundColor: '#334bc120',
    border: 'none'
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const SetObjective = (props) => {

  const { open, goals, setGoals, canSave, objectives, setObjectives, onSave, loadData } = props;

  let history = useHistory();

  const classes = useStyles();
  const [currentActiveTab, setCurrentActiveTab] = useState("set-objective");

  const [activeFilter, setActiveFilter] = useState("all");
  const [searchSubject, setSearchSubject] = useState("");

  const initialKips = [
    {
      title: ""
    }
  ];

  const [cObjective, setCObjective] = useState("");
  const [choosedSubjects, setChoosedSubjects] = useState([]);
  const [choosedGoals, setChoosedGoals] = useState([]);
  const [kpis, setKpis] = useState(initialKips);

  const toastInitialValues = {
    isOpen: false,
    isSuccess: false,
    isError: false,
    message: ""
  }

  const [toast, setToast] = useState(toastInitialValues);

  const closeToast = () => {
    return setToast((prev) => ({
      ...prev,
      isOpen: false,
      isSuccess: false,
      isError: false,
      message: ""
    }));
  };

  const showError = (errorMsg) => {
    setToast((prev) => ({
      ...prev,
      isOpen: true,
      isError: true,
      isSuccess: false,
      message: errorMsg
    }));
  }

  const showSuccess = (succMsg) => {
    setToast((prev) => ({
      ...prev,
      isOpen: true,
      isError: false,
      isSuccess: true,
      message: succMsg
    }));
  }

  const changeActiveTab = (activeTab) => {
    setCurrentActiveTab(activeTab);
  }

  const addKpiItem = () => {
    setKpis([
      ...kpis,
      {
        title: ""
      }
    ]);
  }

  const removeKpi = (index) => {
    let kpisTemp = [...kpis];
    if (kpisTemp.length > 1) {
      kpisTemp.splice(index, 1);
      setKpis(kpisTemp);
    } else {
      showError("Atleast one KPI is required");
    }
  }

  const updateKpiTitle = (e, index) => {
    let kpisTemp = [...kpis];
    kpisTemp[index].title = e.target?.value;
    setKpis(kpisTemp);
  }

  const handleSubjectChoose = (e, subject) => {
    let choosedSubjectsTemp = [...choosedSubjects];
    let val = e.target.value;
    if (e.target.checked) {
      if (!choosedSubjectsTemp.includes(val)) {
        choosedSubjectsTemp.push(val);
      }
    } else {
      if (choosedSubjectsTemp.includes(val)) {
        choosedSubjectsTemp = choosedSubjectsTemp.filter(item => item !== val);
      }
    }
    setChoosedSubjects(choosedSubjectsTemp);
  }

  const handleGoalChoose = (e, subject) => {
    let choosedGoalsTemp = [...choosedGoals];
    let goalNumber = subject.goalNumber;
    if (choosedGoals.includes(goalNumber)) {
      //uncheck all
      choosedGoalsTemp.splice(choosedGoalsTemp.indexOf(goalNumber), 1);
    } else {
      //check all
      choosedGoalsTemp.push(goalNumber);
    }
    setChoosedGoals(choosedGoalsTemp);
  }

  useEffect(() => {
    // let choosedSubjectsTemp = [...choosedSubjects];
    let choosedSubjectsTemp = [];
    if (choosedGoals.length > 0) {
      for (let goalNumber of Object.keys(goals)) {
        if (choosedGoals.includes(goalNumber)) {
          let allSubjects = goals[goalNumber].subjects;
          for (let subjectKey of Object.keys(allSubjects)) {
            let subjectItem = allSubjects[subjectKey];
            let subKey = goalNumber + '-' + subjectItem.title;
            choosedSubjectsTemp.push(subKey);
          }
        }
      }
    }
    setChoosedSubjects(choosedSubjectsTemp);
  }, [choosedGoals, goals]);

  const saveObjective = () => {
    if (cObjective != "") {
      if (choosedSubjects.length > 0) {
        // if (kpis.length > 0) {
          let objectivesTemp = [...objectives];

          const titleExists = objectivesTemp.some(item => item.title === cObjective);
          if (titleExists) {
            showError("Objective already added");
            return false;
          }

          const subjectItems = [];

          for (let choosedSubject of choosedSubjects) {
            for (let goalNumber in goals) {
              let goal = goals[goalNumber];
              if (isNotEmpty(goal?.subjects)) {
                for (let subjectKey in goal?.subjects) {
                  let subjectItem = goal?.subjects[subjectKey];
                  let subjectKeyTemp = goalNumber + "-" + subjectKey;
                  if (choosedSubject === subjectKeyTemp) {
                    subjectItems.push({
                      goal: goalNumber,
                      subject: subjectItem?.title
                    });
                  }
                }
              }
            }
          }

          objectivesTemp.push({
            title: cObjective,
            kpis,
            subjects: subjectItems
          });
          console.log(objectivesTemp,"Check objective here")
          setObjectives(objectivesTemp);

          setKpis(initialKips);
          setChoosedSubjects([]);
          setChoosedGoals([]);
          setCObjective("");
          props?.setAddedObjective(true) 
          
          // } else {
            //   showError("Add at-least one KPI");
            // }
          } else {
            showError("Select at-least one of Material Topics");
          }
        } else {
          showError("Objective name is required");
        }
      }


  const isCatSelected = (catType) => {

  }

  const handleEnvChoose = (catType) => {

  }

  return (
    <>

      {toast.isOpen && <Toast message={toast.message} isError={toast.isError} isSuccess={toast.isSuccess} closeToast={closeToast} />}

      <div className={`right-side-panel-wrapper ${open ? 'open' : ''} `}>
        <div className="right-side-panel">
          <div className="tabs-setobjective">

            <div className="actions text-right">
              <a onClick={props?.handleClose} href={undefined} className="set-objective-close-btn">
                <CloseOutlined />
              </a>
            </div>

            <div className="tabs">
              <a className={` ${currentActiveTab == "set-objective" ? 'active' : ''} `} onClick={(e) => changeActiveTab('set-objective')} href={undefined}>Set Objective</a>
              <a className={` ${currentActiveTab == "objective-list" ? 'active' : ''} `} onClick={(e) => changeActiveTab('objective-list')} href={undefined}>View Objectives List</a>
            </div>

            {
              currentActiveTab == "set-objective" ? <>
                <div className="set-objective-content">
                  <div>
                    <p>Objective Name</p>
                    <input type="text" placeholder="Enter your objective" value={cObjective} onChange={(e) => setCObjective(e.target?.value)} />
                  </div>
                  <div className="tags-affected">
                    <p>Affected by this objective</p>
                    <div className="tags-box-main">
                      <div className="tags-box-search">
                        <input type="text" placeholder="Search Material Topic" value={searchSubject} onChange={(e) => setSearchSubject(e.target?.value)} />
                        <div className="tag-filters">
                          <ul>
                            <li><a className={activeFilter === 'all' ? 'active' : ''} href={undefined} onClick={(e) => setActiveFilter("all")}>All</a></li>
                            <li><a className={activeFilter === 'environment' ? 'active' : ''} href={undefined} onClick={(e) => setActiveFilter("environment")}>Environment</a></li>
                            <li><a className={activeFilter === 'governance' ? 'active' : ''} href={undefined} onClick={(e) => setActiveFilter("governance")}>Governance</a></li>
                            <li><a className={activeFilter === 'social' ? 'active' : ''} href={undefined} onClick={(e) => setActiveFilter("social")}>Social</a></li>
                          </ul>
                        </div>
                      </div>
                      <div className="tags-box">

                        {/* {
  Object.keys(goals).map((goalNumber) => {
    let subjects = goals[goalNumber].subjects;
    let goalsShowed = [];
    return <>
      {
        isNotEmpty(subjects) ? <>
          {
            Object.keys(subjects).map((subjectKey) => {
              let subject = subjects[subjectKey];
              let subjectKeyTemp = goalNumber;
              if (!goalsShowed.includes(goalNumber)) {
                goalsShowed.push(goalNumber);
                return <>
                  <div className="tag">
                    <label htmlFor={subjectKeyTemp}>
                      <input checked={choosedGoals.includes(subjectKeyTemp)} type="checkbox" onChange={(e) => handleGoalChoose(e, subject)} id={subjectKeyTemp} value={subjectKeyTemp} />
                      <UnsdgSubjectNumber goals={goals} objectives={objectives} showMulti={true} subject={subject} subjects={subjects} />
                      <p>{subject?.goal?.title}</p>
                    </label>
                  </div>
                </>
              }
            })
          }
        </> : null
      }
    </>
  })
} */}


                        {
                          Object.keys(goals).map((goalNumber) => {
                            let subjects = goals[goalNumber].subjects;
                            return <>
                              {
                                isNotEmpty(subjects) ? <>
                                  {
                                    Object.keys(subjects).map((subjectKey) => {
                                      let subject = subjects[subjectKey];
                                      let subjectKeyTemp = goalNumber + "-" + subjectKey;
                                      let show = true;
                                      if (activeFilter != "all") {
                                        if (activeFilter != subject.goal.category) {
                                          show = false;
                                        }
                                      }
                                      if (show) {
                                        if (isNotEmpty(searchSubject)) {
                                          if (!subject.title.toString().toLowerCase().includes(searchSubject.toString().toLowerCase())) {
                                            show = false;
                                          }
                                        }
                                      }
                                      if (show) {
                                        return <>
                                          <div className="tag">
                                            <label htmlFor={subjectKeyTemp}>
                                              <input checked={choosedSubjects.includes(subjectKeyTemp)} type="checkbox" onChange={(e) => handleSubjectChoose(e, subject)} id={subjectKeyTemp} value={subjectKeyTemp} />
                                              <UnsdgSubjectNumber goals={goals} objectives={objectives} subject={subject} subjects={subjects} />
                                              <p>{subject?.title}</p>
                                            </label>
                                          </div>
                                        </>
                                      } else {
                                        return <></>
                                      }
                                    })
                                  }
                                </> : null
                              }
                            </>
                          })
                        }
                      </div>
                    </div>
                  </div>

                  {/* <div className="kpi-box">
                    {
                      kpis.map((kpiItem, index) => {
                        return <>
                          <div className="kpi-item">
                            <p>KPI {index + 1}</p>
                            <div className="kpi-item-fields">
                              <input onChange={(e) => updateKpiTitle(e, index)} type="text" value={kpiItem?.title} placeholder="Enter text" />
                              <a className="remove-item" onClick={(e) => removeKpi(index)} href={undefined}>
                                <CloseOutlined />
                              </a>
                            </div>
                          </div>
                        </>
                      })
                    }
                    <button onClick={addKpiItem} className="add-more-btn"> <AiOutlinePlus /> Add more Kpi </button>
                  </div> */}
                  <div style={{margin:"20px 0px"}}>
                    <button onClick={saveObjective} className="navy-btn">Set Objective</button>
                  </div>
                </div>
              </> : null
            }

            {
              currentActiveTab == "objective-list" ? <>
                <div className="view-objective-content">
                  <ListObjective loadData={loadData} goals={goals} objectives={objectives} />
                </div>
              </> : null
            }

          </div>
        </div>
      </div>

    </>
  );
};

export default SetObjective;