import React from "react";

import logo from "../../static/images/logo.png";
import { useAuth } from "../../hooks";
import { Box, makeStyles, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";


const useStyles = makeStyles((theme) => {
    return {
        root: {
            minHeight: "100vh",
            backgroundColor: "transparent",
        },
        logo: {
            marginTop: theme.spacing(5),
            height: "50px",
            borderRadius: "0px",
            marginLeft: theme.spacing(8),
        },
        pageTitle: {
            color: "#262727",
            fontSize: 30,
            fontWeight: 600,
            fontFamily: "Poppins",
            lineHeight: "27px",
            textAlign: "center",
        }
    };
});

export default function Terms() {

    const classes = useStyles();
    const { user } = useAuth();
    const history = useHistory();

    const backToMain = () => {
        history.push("/login");
    }

    const backToApp = () => {
        history.push("/");
    }

    return (<>

        <div
            className="App"
            style={{
                minHeight: "100vh",
                width: `${user && user.token ? `calc(100% - 360px)` : "100%"}`,
                marginLeft: `${user && user.token ? "340px" : "0px"}`,
            }}
        >

            {!(user && user.token) && (
                <div
                    className="cms-upper-head"
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginRight: "137px",
                    }}
                >
                    <img onClick={backToApp} src={logo} alt="TruDoc" className={classes.logo} />
                    <Box display="flex" alignItems="center" justifyContent="center">
                        <Typography onClick={backToMain} className="cms-upper-head-link">Login</Typography>
                    </Box>
                </div>
            )}

            <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
                className={classes.innerContainer}
                style={{
                    padding: `${user && user.token ? "40px" : "0px"}`,
                }}
            >
                <Typography className={` cms-page-title ` + classes.pageTitle}>Terms & Conditions</Typography>

                <div className="cms-content-area">
                    <h4>Welcome to TruDoc</h4>
                    <p>
                        TruDoc is a cloud-based platform. Users highly protected cloud
                        environment. Since its' cloud-based, users can access them through the
                        web and any number of device types (e.g., desktop, laptop, tablets, and
                        smartphone). This document, the TruDoc Terms of Service (Terms),
                        outlines the terms regarding the use of our product. These Terms are a
                        legally binding contract between you and TruDoc so please read carefully.
                        If you do not agree with these Terms, do not register or use any of the
                        Services. By using, accessing or browsing the TruDoc Service, platform
                        and products including applications, mobile, software, websites or other
                        properties owned or operated by TruDoc or by registering for a TruDoc
                        account (Services) you are agreeing to be bound by these Terms for the
                        Services provided by TruDoc (TruDoc or us). You are entering into this
                        contract with TruDoc, Inc.
                    </p>
                    <p>
                        If you are using the Services on behalf of an organization, you are
                        agreeing to these Terms for that organization and promising to TruDoc
                        that you have the authority to bind that organization to these Terms (in
                        which event, “you” and “your” will refer to that organization) unless that
                        organization has a separate paid contract in effect with us, in which event
                        the terms of that contract will govern your use of the Services.
                    </p>
                    <h4>PRIVACY</h4>
                    <p>
                        To operate and provide the Services, we collect certain information about
                        you. We use and protect that information as described in our Privacy
                        Policy. You acknowledge your use of the Services is subject to our Privacy
                        Policy and understand that it identifies how TruDoc collects, stores, and
                        uses certain information.
                    </p>
                    <h4>CHANGES TO THESE TERMS</h4>
                    <p>
                        We reserve the right to modify these Terms. We will post the most current
                        version of these Terms at www.TruDoc.com (the site). If we make material
                        changes to these Terms, we will notify you via the Services and/or by
                        email to the address associated with your account. If you do not accept
                        the changes, you must stop using and cancel your account by emailing
                        support@TruDoc.com. Your continued use of our Services after we publish
                        or send a notice about our changes to these Terms means that you are
                        consenting to the updated terms.
                    </p>
                    <h4>YOUR ACCOUNT</h4>
                    <p>
                        Certain aspects of the Services may require you to obtain an account by
                        completing a registration form and designating a user ID and password.
                        When registering with TruDoc you must:
                    </p>
                    <ol>
                        <li>
                            provide true, current and complete information about yourself on
                            the registration form and
                        </li>
                        <li>
                            maintain such information so it continues to be true, current and
                            complete
                        </li>
                    </ol>
                    <p>
                        You are entirely responsible for all materials and information that you
                        upload, post or otherwise transmit via the Services. Only you may use
                        your TruDoc account and you are responsible for your account. If you
                        become aware of any unauthorized use of the Services or your account or
                        have any questions about your account please contact TruDoc Support
                        immediately.
                    </p>
                    <h4>EMAIL SELECTED FOR YOUR ACCOUNT</h4>
                    <p>
                        You get to choose what email address(es) you register for an account.
                        Please be aware, however, that if the domain of the email address
                        associated with your account is owned or controlled by an organization
                        and that organization establishes a direct relationship with us and wishes
                        to add your account to such relationship, then you may be rolled into that
                        organization's account after notification. Following that notice, if you
                        choose not to change the email address associated with your account,
                        your account will be controlled by the organization. If an organization
                        providing you with your account, you understand that this organization
                        has rights to your account and may:
                    </p>
                    <ol>
                        <li>Manage your account (including suspending or cancelling)</li>
                        <li>Reset your password</li>
                        <li>View your usage and profile data, including how and when your account is used and</li>
                        <li>Manage the Content in your account.</li>
                    </ol>
                    <h4>CONSENT TO ELECTRONIC COMMUNICATIONS AND SOLICITATION</h4>
                    <p>
                        By registering with TruDoc or signing up for Services, you understand that
                        we may send you (including via email) information regarding the Services,
                        such as:
                    </p>
                    <ol>
                        <li>Notices about your use of the Services, including notices of
                            violations of use.</li>
                        <li>Updates to the Services and new features or products and</li>
                        <li>Promotional information and materials regarding TruDoc's products
                            and services</li>
                    </ol>
                    <p>
                        Please review your settings in your account to control the messages you
                        receive from us or unsubscribe by following the instructions in the
                        message. Notices emailed to you will be considered given and received
                        when the email is sent. If you don't consent to receive notices (other than
                        promotional materials) electronically, you must stop using the Services.
                    </p>
                    <h4>CONTENT</h4>
                    <p>
                        We call all the electronic data that you upload and store on your account
                        Content. All Content uploaded by you into your account is yours! We don't
                        control, verify, or endorse the Content that you or others put on the
                        Services. You are responsible for:
                    </p>
                    <ol>
                        <li>All Content you place in your TruDoc account(s) and share through
                            the Services and</li>
                        <li>Making sure that you have all the rights you need to the Content</li>
                    </ol>
                    <p>
                        Besides, by storing, using or transmitting Content you cannot and will not
                        violate any law or these Terms You agree to provide TruDoc (as well as
                        agents or service providers acting on TruDoc's behalf to provide the
                        Services) the right to transmit, process, use and disclose Content and
                        other information which we may obtain as part of your use of the Services
                        but only:
                    </p>
                    <ol>
                        <li>As necessary for us to provide the Services</li>
                        <li>As otherwise permitted by these Terms</li>
                        <li>As otherwise required by law, regulation or order or</li>
                        <li>To respond to an emergency</li>
                    </ol>
                    <h4>COPYRIGHT COMPLAINTS AND REMOVAL POLICY</h4>
                    <p>
                        TruDoc respects the intellectual property of others and will respond to
                        notices of alleged copyright infringement that comply with the law. We
                        reserve the right to delete or disable Content alleged to violate copyright
                        laws or these Terms and reserve the right to terminate the account(s) of
                        violators. If you believe there has been a violation of your intellectual
                        property rights, please contact TruDoc Support immediately. Or contact
                        our designated copyright agent at support@dcirrus.com
                    </p>
                    <h4>CONFIDENTIAL INFORMATION</h4>
                    <p>
                        During your use of the Services, TruDoc may share with you information
                        that is confidential, sensitive or should be kept secret. For example, if we
                        tell you about our product roadmaps, product designs and architecture,
                        technology and technical information, provide you with security audit
                        reviews, business, and marketing plans, or share with you our business
                        processes, these should always be considered confidential to TruDoc.
                        Similarly, we agree that your Content, credit card/banking information and
                        information contained in your account is confidential to you. Also, if either
                        of us provides any documents to the other that are labelled confidential
                        (or something similar), or provide information (either in writing or verbal)
                        that is of a type that a reasonable person should understand to be
                        confidential such information is to be treated as confidential information.
                        However, if you tell us information that:
                    </p>
                    <ol>
                        <li>We already know at the time you tell us</li>
                        <li>Was told to us by a third-party who had the right to tell us</li>
                        <li>Is generally available to the public or</li>
                        <li>Was independently developed by us without using any of your
                            confidential information, then that information will not be
                            considered confidential The same goes for information that we tell
                            you that falls into any of these categories. Lastly, we both agree
                            that:</li>
                        <li>We will treat each other's information with the same degree of care
                            that we treat our confidential information</li>
                        <li>
                            Will use each other's confidential information only in connection
                            with these Terms and the Services
                        </li>
                        <li>Only share the information with others who have a need to know
                            and who have agreed in writing to treat it as confidential (as we've
                            outlined in this section) and</li>
                        <li>Not share the information with any third-party except as allowed in
                            these Terms or through the Services. Of course, confidential
                            information will always remain the property of its owner.</li>
                    </ol>
                    <h4>CONTENT STORAGE</h4>
                    <p>The Services are provided from Singapore. By using and accessing the
                        Services, you understand and agree to the storage of Content and any
                        other personal information in your country zone as we respect the data
                        protection laws of your county. However, you understand that you (or
                        other people that you collaborate with) can access the Services (including
                        Content) from outside of your country zone (subject to applicable law) and
                        that nothing prohibits the processing of other information outside of your
                        country.</p>
                    <h4>ACCEPTABLE USE POLICY</h4>
                    <p>
                        You agree you will not, nor will you encourage others or assists others,
                        harm the Services or use the Services to harm others. For example, you
                        must not use the Services to harm, threaten, or harass another person,
                        organization or TruDoc and/or to build a similar service or website. You
                        must not:
                    </p>
                    <ol>
                        <li>Damage, disable, overburden, or impair the Service (or any network
                            connected to the Services)</li>
                        <li>Resell or redistribute the Services or any part of it</li>
                        <li>Use any unauthorized means to modify, reroute, or gain access to
                            the Services or attempt to carry out these activities</li>
                        <li>Use any automated process or service (such as a bot, a spider, or
                            periodic caching of information stored by TruDoc) to access or use
                            the Services</li>
                        <li>Use the Services beyond the features allocation and amounts
                            provided in that Service or violation of our fair use policy</li>
                        <li>Use the Services to violate any law of distributing malware or
                            malicious Content or</li>
                        <li>Distribute, post, share information or Content you don't have the
                            right to or is illegal</li>
                    </ol>
                    <p>
                        As part of our efforts to protect the Service, protect our customers, or to
                        stop you from breaching these Terms we retain the right to block or
                        otherwise prevent delivery of any type of file, email or other
                        communication to or from the Services. We also reserve the right to
                        deactivate, change and/or require you to change your TruDoc user ID and
                        any custom or vanity URLs, custom links, or vanity domains you may
                        obtain through the Services
                    </p>
                    <h4>SUSPENSION AND TERMINATION OF THE SERVICE</h4>
                    <p>
                        We reserve the right to suspend or terminate your access to the Service
                        at any time in our sole discretion. You understand that if your account is
                        suspended or terminated, you may no longer have access to the Content
                        that is stored with the Services. Upon termination, you may request
                        access to your Content, which we will make available, except in cases
                        where we have terminated your account due to your violation of these
                        Terms. You must make such a request within 14 days following
                        termination otherwise, any Content you have stored with the Services
                        may not be retrievable and we will have no obligation to maintain Content
                        stored in your account after this 14-days period.
                    </p>
                    <h4>UPDATES TO THE SERVICE</h4>
                    <p>
                        We can make necessary deployments of changes, updates or
                        enhancements to the Services at any time. We may also add or remove
                        functionalities or features, or we may suspend or stop the Services
                        altogether.
                    </p>
                    <h4>THIRD-PARTY SERVICES</h4>
                    <p>
                        TruDoc may make available to your optional third-party applications,
                        services or products, for use in connection with the Services (Third-Party
                        Products). These Third-Party Products are not necessary for the use of the
                        Services and your use (and any exchange of any information, license,
                        payments, etc., are between you and the third-party provider) is solely
                        between you and the applicable third-party provider. TruDoc makes no
                        warranties of any kind and assumes no liability of any kind for your use of
                        such Third-Party Products. If you have any questions or concerns
                        regarding the Third-Party Products, then please contact the applicable
                        third-party provider.
                    </p>
                    <h4>TRUDOC PROPRIETARY RIGHTS</h4>
                    <p>All contents of the Site and Services including but not limited to logo,
                        design, text, software, technical drawings, configurations, graphics, other
                        files, and their selection and arrangement and TruDoc Confidential
                        Information belong to TruDoc, and/or its suppliers, affiliates, or licensors.
                        TruDoc or its licensors own and reserve all right, title and interest in and
                        to the Services and all hardware, software and other items used to
                        provide the Services, other than the rights we expressly grant to you to
                        use the Services and TruDoc Confidential Information. No title to or
                        ownership of any proprietary rights related to the Services or TruDoc
                        Confidential Information is transferred to you under these Terms. If you
                        provide comments, suggestions and recommendations to TruDoc about a
                        Service (e.g., modifications, enhancements, improvements) (collectively,
                        “Feedback”), you are automatically assigning this Feedback to TruDoc.</p>
                    <h4>WARRANTY</h4>
                    <p>NO WARRANTY OR CONDITIONS TO THE EXTENT NOT PROHIBITED BY
                        LAW, TRUDOC AND ITS AFFILIATES (AND ASSOCIATED SERVICE
                        PROVIDERS)</p>
                    <ol>
                        <li>PROVIDE THE SERVICES “AS IS”, “WITH ALL FAULTS” AND “AS
                            AVAILABLE”</li>
                        <li>MAKE NO REPRESENTATIONS OR WARRANTIES OR CONDITIONS
                            WHETHER EXPRESS OR IMPLIED (E.G. WARRANTY OF
                            MERCHANTABILITY, SATISFACTORY QUALITY, FITNESS FOR A
                            PARTICULAR PURPOSE, OR NON-INFRINGEMENT), AND</li>
                        <li>DO NOT GUARANTEE THAT THE SERVICES WILL BE UNINTERRUPTED,
                            ERROR-FREE OR FREE OF HARMFUL COMPONENTS, THAT THE
                            CONTENT WILL BE SECURE OR NOT OTHERWISE LOST OR DAMAGED</li>
                    </ol>
                    <h4>IF YOU ARE DISSATISFIED WITH ANY PORTION OF THE SERVICE OR
                        WITH THESE TERMS OF SERVICE, YOUR SOLE AND EXCLUSIVE
                        REMEDY IS TO DISCONTINUE USE OF THE SERVICES.</h4>
                    <h4>INDEMNIFICATION</h4>
                    <p>To the extent not prohibited by law, you will defend TruDoc against any
                        cost, loss, damage, or other liability arising from any third-party demand
                        or claim that any Content or information provided by you, or your use of
                        the Services:</p>
                    <ol>
                        <li>infringes a registered patent, trademark, or copyright of a third-
                            party, or misappropriates a trade secret (to the extent that such
                            misappropriation is not the result of TruDoc's actions) or</li>
                        <li>violates applicable law or these Terms. TruDoc will reasonably notify
                            you of any such claim or demand that is subject to your
                            indemnification obligation.</li>
                    </ol>
                    <h4>LIMITATION OF LIABILITY TO THE EXTENT NOT PROHIBITED BY LAW</h4>
                    <p>
                        IN NO EVENT WILL TRUDOC, ITS AFFILIATES, RESELLERS, OFFICERS,
                        EMPLOYEES, AGENTS, SUPPLIERS OR LICENSORS BE LIABLE FOR: ANY
                        INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE, COVER OR CONSEQUENTIAL
                        DAMAGES (INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOST
                        PROFITS, REVENUE, GOODWILL, USE OR CONTENT) HOWEVER CAUSED,
                        UNDER ANY THEORY OF LIABILITY, INCLUDING, WITHOUT LIMITATION,
                        CONTRACT, TORT, WARRANTY, NEGLIGENCE OR OTHERWISE, EVEN IF
                        TRUDOC HAS BEEN ADVISED AS TO THE POSSIBILITY OF SUCH DAMAGES.
                        THE AGGREGATE LIABILITY OF TRUDOC AND ITS AFFILIATES, OFFICERS,
                        RESELLERS, EMPLOYEES, AGENTS, SUPPLIERS OR LICENSORS, RELATING
                        TO THE SERVICES WILL BE LIMITED TO REFUND OF THE MOST RECENT
                        MONTHLY OR YEARLY FEE THAT YOU PAID FOR THAT SERVICE; THE
                        LIMITATIONS AND EXCLUSIONS ALSO APPLY IF THIS REMEDY DOES NOT
                        FULLY COMPENSATE YOU FOR ANY LOSSES OR FAILS OF ITS ESSENTIAL
                        PURPOSE. SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OF
                        INCIDENTAL, CONSEQUENTIAL OR OTHER DAMAGES. IN SUCH AN EVENT,
                        THIS LIMITATION WILL NOT APPLY TO YOU TO THE EXTENT PROHIBITED BY
                        LAW.
                    </p>
                    <h4>DISPUTE RESOLUTION AND GOVERNING LAW</h4>
                    <p>
                        You must comply with all domestic and international export laws and
                        regulations that apply to your use of the Services, such as software. These
                        laws include restrictions on destinations, end-users, and end-use. Any
                        dispute relating to these Terms or the Services will be brought in the
                        Singapore Courts.
                    </p>
                    <h4>TRIALS</h4>
                    <p>
                        You can sign-up for a trial for some of the Services and your trial period
                        starts on the day you create the trial account and lasts for 14 days or
                        more as decided by TruDoc. If you are on a trial, you may cancel at any
                        time until the last day of your trial. If you do not wish to incur charges,
                        you must cancel the account by the end of the 14th day. If you do not
                        cancel your account and we have told you the account will be converted
                        to a paid subscription at the end of the trial period, then you authorize us
                        to charge your credit card for that specific Service. You may, however,
                        cancel your subscription before the next billing cycle in accordance with
                        these Terms, but no credits or refunds will be available.
                    </p>
                    <h4>GENERAL TERMS</h4>
                    <ol>
                        <li>Severability; These Terms apply to the maximum extent permitted
                            by relevant law. If a court holds that we cannot enforce a part of
                            these Terms as written, you and we will replace those terms with
                            similar terms to the extent enforceable under the relevant law, but
                            the rest of these Terms will remain in effect. This is the entire
                            contract between you and us regarding the Service. It supersedes
                            any prior contract or oral or written statements regarding your use
                            of the Services.</li>
                        <li>Assignment and transfer. We may assign, transfer, or otherwise
                            dispose of our rights and obligations under this contract, in whole or
                            in part, at any time without notice. You may not assign this contract
                            or transfer any rights to use the Service unless we allow you to do
                            so in writing.</li>
                        <li>Independent Contractors; No third-party beneficiaries. TruDoc and
                            you are not legal partners or agents; instead, our relationship is that
                            of independent contractors. This contract is solely for your and our
                            benefit. It is not for the benefit of any other person, except for
                            permitted successors.</li>
                        <li>Waiver. The failure of either of us to insist upon or enforce strict
                            performance of any of the provisions of these Terms or to exercise
                            any rights or remedies under these Terms will not be construed as a
                            waiver or relinquishment to any extent of such right to assert or rely
                            upon any such provision, right or remedy in that or any other
                            instance; rather, the same will remain in full force and effect.</li>
                    </ol>
                    <h4>BILLING/PAYMENT</h4>
                    <p>If you select a paid Service, you must provide us with current, complete,
                        accurate and authorized payment method information (e.g. credit card
                        information). You authorize us to charge your provided payment method
                        for the Services you have selected and for any paid feature(s) that you
                        choose. We may bill:</p>
                    <ol>
                        <li>In advance</li>
                        <li>At the time of purchase</li>
                        <li>Shortly after purchase or</li>
                        <li>If you have elected a subscription service, regularly</li>
                    </ol>
                    <p>
                        To the extent TruDoc has not received your payment, to bring your
                        account up to date, we may bill you simultaneously for both past due and
                        current amounts. If you do not cancel your account, we may automatically
                        renew your Service(s) and charge you for any renewal term. You
                        understand that failure to pay any charges or fees may result in the
                        suspension or cancellation of your Services.
                    </p>
                    <h4>FEES</h4>
                    <p>
                        TruDoc offers both free and paid Services. If you choose to subscribe to a
                        paid Service, you agree to pay the fees (“Fees”) as quoted to you when
                        you purchase that Service. We may calculate taxes payable by you based
                        on the billing information that you provide us at the time of purchase. You
                        are responsible for all charges related to using the purchased Service (for
                        example, data charges and currency exchange settlements). You will pay
                        the Fees in the currency TruDoc quoted at the time of purchase. TruDoc
                        reserves the right to change the eligible currencies at any time. TruDoc
                        reserves the right to change its prices at any time, however, if we have
                        offered a specific duration and Fee for your use of the Service, we agree
                        that the Fee will remain in force for that duration. After the offer period
                        ends, your use of the Service will be charged at the then-current Fee(s). If
                        you don't agree with these changes, you must stop using the Service and
                        cancel via email to support@TruDoc.com (with cancellation confirmation
                        from a TruDoc representative). If you cancel, your Service ends at the end
                        of your current Service period or payment period, and no refunds for
                        previously paid services will be issued. If you do not cancel in accordance
                        with these Terms, the subscription for the Service will automatically renew
                        at the then-current price and for the same subscription period. We will
                        charge your credit card on file with us on the first day of the renewal of
                        the subscription period.
                    </p>
                    <h4>SUBSCRIPTION PERIOD</h4>
                    <p>
                        You may select one of the following subscription plans and billing options
                        (please note that there might be only one of these options available
                        depending on the Service purchased): A monthly subscription plan
                        (Monthly Subscription Plan). The subscription period for the Monthly
                        Subscription Plan will be for one month and will automatically renew
                        unless you cancel your Monthly Subscription Plan at least three business
                        days before the renewal date. You will be billed on or about the same day
                        each month until such time that you cancel. An annual subscription plan
                        (Annual Subscription Plan). The subscription period for the Annual
                        Subscription Plan will be for one year and will automatically renew each
                        year on the anniversary unless you cancel at least three business days
                        before your renewal date. You will be billed annually on or about the same
                        day each year until such time that you cancel. Note that under the Annual
                        Subscription Plan you will not be permitted to cancel, reduce the number
                        of seats, or downgrade the TruDoc Service you have selected until the
                        anniversary date. Be aware that you are committing to a one-year plan; if
                        you are not certain, we recommend choosing the Monthly Subscription
                        Plan. If you select the Monthly Subscription Plan, you can switch to the
                        Annual Subscription Plan at any time. If you select the Annual Subscription
                        Plan, you may not change to the Monthly Subscription Plan until the end
                        of the one-year term of your Annual Subscription Plan.
                    </p>
                    <h4>TRUDOC SUBSCRIPTION SPECIFIC TERMS</h4>
                    <p>If you are purchasing a TruDoc subscription plan, the following additional
                        terms apply to you: You:</p>
                    <ol>
                        <li>Are required to have a TruDoc account for each user</li>
                        <li>Will only receive the features and functionality that are included in
                            the specific subscription plan that you have purchased,</li>
                        <li>Are responsible for setting your configurations of the TruDoc Service
                            and</li>
                        <li>Are responsible for managing any of your devices and/or any
                            systems that you use to access the TruDoc Service.</li>
                    </ol>
                    <h4>TRUDOC ZONES SPECIFIC TERMS</h4>
                    <p>
                        If you are purchasing the TruDoc infrastructure product that enables the
                        storing of Content in specific Location(s) and by specific Service
                        Provider(s) (TruDoc Zones), the following additional terms in this Section
                        apply to you. Subject to the terms in this Section and a purchase of a
                        TruDoc Zones account for each user, TruDoc agrees to store your Content
                        in the specific geographic location(s) named at the time of purchase
                        (Location(s)) and with the entities providing the geographic storage of
                        Content as named at the time of purchase (Service Provider(s)). You
                        understand and agree that:
                    </p>
                    <ol>
                        <li>By utilizing TruDoc Zones, the terms of this Section supersede and
                            replace any and all provisions in these Terms regarding the location
                            of Content storage and related controls (but only those terms)</li>
                        <li>The applicable Service is provided from Singapore and that for
                            TruDoc Zones, while the Content will be stored and may be partially
                            processed in the Location(s), some processing and metadata
                            storage and processing may be provided from Singapore or the
                            United States</li>
                        <li>It is only the Content generated after your purchase and
                            provisioning of TruDoc Zones that will be stored in the Location(s)
                            selected at the time of purchase</li>
                        <li>Certifications of Service Provider(s) may vary based on selected
                            Service Provider(s) and Location(s) and</li>
                        <li>It is your responsibility to configure and properly utilize the
                            applicable Service and TruDoc Zones to address your obligations
                            related to data types and data residency obligations</li>
                    </ol>
                    <p>
                        To maintain TruDoc's obligations to you for the provision of TruDoc Zones,
                        you acknowledge and agree that TruDoc may change the Service
                        Provider(s) at any time during your Subscription Plan. TruDoc agrees to
                        provide you with at least thirty (30) days prior written notice of such
                        change in Service Provider before implementation unless such change is
                        necessary to prevent imminent harm to you, TruDoc, or the Services, in
                        which case TruDoc will notify you as soon as reasonably practicable
                        thereafter.
                    </p>
                    <h4>TRAINING OR CONSULTING SERVICES</h4>
                    <ol>
                        <li>Consulting Services. In the event you order any professional,
                            educational, operational or technical services (collectively, Consulting
                            Services), the nature, details, and duration of the Consulting Services will
                            further be described in the datasheet or statement of work which is
                            referenced.</li>
                        <li>Deliverables and TruDoc Tools. TruDoc will own and retains all rights,
                            title, and interest in and to any training materials or other tangible
                            materials provided to you as part of the Consulting Services (each, a
                            Deliverable) (excluding any of your Confidential Information), and related
                            intellectual property rights. Subject to these Terms, TruDoc grants you a
                            royalty-free, limited, non-exclusive, non-transferable and terminable
                            license to use the Deliverables solely for your authorized use of the
                            TruDoc Service during your subscription term. Nothing herein shall be
                            construed to assign or transfer any intellectual property rights in the
                            proprietary tools, libraries, know-how, techniques and expertise (TruDoc
                            Tools) used by TruDoc to develop the Deliverables and to the extent such
                            TruDoc Tools are delivered with or as part of the Deliverables, they are
                            licensed, not assigned, to you on the same terms as the Deliverables.</li>
                        <li>
                            Consulting Services Warranty. Regarding Consulting Services only,
                            TruDoc warrants that:
                            <br />
                            (a) it and each of its employees, consultants and subcontractors, if any,
                            have the necessary knowledge, skills, experience, qualifications, and
                            resources to provide and perform the Consulting Services in accordance
                            with the applicable datasheet or statement of work and
                            <br />
                            (b) the Consulting Services will be performed in a professional and
                            workmanlike manner in accordance with industry standards and as per
                            the scope of services outlined in the applicable datasheet or statement of
                            work.
                            You acknowledge that TruDoc's ability to successfully perform the
                            Consulting Services is dependent upon your provision of timely
                            information, access to resources, and participation as outlined in the
                            applicable Consulting Services. If through no fault or delay of you the
                            Consulting Services do not conform to the foregoing warranty, and you
                            notify TruDoc within seven (7) calendar days of TruDoc's delivery of the
                            Consulting Services, TruDoc will re-perform the non-conforming portion(s)
                            of the Consulting Services at no additional cost to you
                        </li>
                    </ol>
                </div>

            </Box>

        </div>

    </>);
}
