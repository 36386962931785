import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Chip from "@material-ui/core/Chip";
import { Box, CircularProgress } from "@material-ui/core";
import { base64ToBlob, getImageFromMimeType, isNotEmpty, printTime } from "../../utils/Generic";
import { getDocumentPreview } from "../../services/dms.service";
import { haveViewer } from "../../utils/document";
import AllDocumentViewer from "../../utils/WebViewer/AllDocumentViewer";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    width: "100%",
    minWidth: 200,
    maxWidth: "100%",
    textAlign: "center",
    padding: 20,
    margin: "0 auto",
    borderWidth: 2,
    borderColor: theme.palette.primary.main,
    cursor: "pointer",
    overflow: "hidden",
    height: "auto",
  },
  title: {
    marginTop: 10,
  },
  position: {
    marginTop: 0,
    marginBottom: 10,
  },
  avatar: {
    fontWeight: 500,
    fontSize: "24px",
    margin: "0 auto",
    width: theme.spacing(8),
    height: theme.spacing(8),
    textTransform: "uppercase"
  },
  banner: {
    position: "absolute",
    top: "-15px",
    left: "-50px",
    backgroundColor: "red",
    height: 50,
    width: 100,
    transform: "rotate(135deg)",
  },
}));

export default function DocumentViewer(props) {

  const classes = useStyles();

  const [data, setData] = useState(null);
  const [document, setDocument] = useState({});

  const [isLoading, setIsLoading] = useState(true);
  const [prevExist, setPrevExist] = useState(false);

  const [baseIframPath, setBaseIframPath] = useState(null);
  const [documentBlobUrl, setDocumentBlobUrl] = useState(null);

  const [fileData, setFileData] = useState(null);

  useEffect(() => {
    setData(props?.data);
  }, [props]);

  useEffect(() => {
    loadDocumentDetails();
  }, [data]);

  const loadDocumentDetails = () => {
    if (isNotEmpty(data)) {
      setIsLoading(true);
      getDocumentPreview({
        documentId: data?.id,
        id: props?.shareData?.id,
        share_id: props?.shareData?.share_id,
        requestType: 'view'
      }).then((res) => {
        setIsLoading(false);
        setDocument(res?.data?.document);
        let fileData = res?.data?.fileData;
        setFileData(fileData);
      }).catch(err => {
        console.log(err);
        setIsLoading(false);
        //MessageConstants.DOCUMENT_DETAILS_LOAD_FAILED
      });
    }
  }

  useEffect(() => {
    setPrevExist(false);
    if (fileData != null) {
      if (haveViewer(fileData?.mimetype, fileData?.name)) {
        let base64Data = `data:${fileData?.mimetype};base64,${fileData?.data}`;
        let blobData = base64ToBlob(base64Data);
        var blobUrl = URL.createObjectURL(blobData);
        setDocumentBlobUrl(blobUrl);
        setBaseIframPath(base64Data);
        setPrevExist(true);
      }
    }
  }, [fileData]);

  return (
    <Paper
      elevation={1}
      className={classes.root + ' document-viewer-wrapper'}
    >
      <Box className="document-preview">
        {isLoading ? <>

          <CircularProgress className="mb-15" color="primary" size={35} />

        </> : <>

          {!prevExist ? <>

            <Typography
              className={classes.title}
              color="textPrimary"
              variant="h6"
              component="h6"
            >
              Preview Not Available
            </Typography>

          </> : <>

            <AllDocumentViewer
              documentUrl={documentBlobUrl}
              document={document}
            />

          </>
          }

        </>
        }
      </Box>
    </Paper>
  );
}
