import { LOADING } from '../redux.constants';

const ToggleLoading = () => {
  return dispatch => {
    dispatch({
      type: LOADING
    })
  }
}

export default ToggleLoading;