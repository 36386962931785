export const randomBg = ['#4BFFC9', '#74BCFF', '#FFD074', '#CD85FA', '#4BDFFF', '#FA85EE'];

export const DROPBOX_APP_KEY = "anel87zc2mn1j1l";

export const GOOGLE_CLIENT_ID = "657245132917-jd5qm07h0l533md8o0ct4qq2qsg5d8cb.apps.googleusercontent.com";
export const GOOGLE_DEVELOPER_KEY = "AIzaSyBXOKvSh-uLneu7gz__h3Qi9JQP_MB6m6U";

export const sidePanelFilterMapping = {
    awaiting_my_sign: 'awaiting_my_sign',
    awaiting_others_sign: 'awaiting_others_sign',
    completed: 'issued',
    declined: 'rejected',
    expired: 'expired',
    my_wallet: 'issued'
}

export const noDocumentMessage = {
    awaiting_my_sign: "No documents awaiting your Sign",
    awaiting_others_sign: "No documents awaiting others Sign",
    completed: "No documents issued",
    declined: "No documents rejected",
    expired: "No documents expired",
    my_wallet: "No documents in the wallet."
}

export const documentRoles = {
    owner: {
        value: 'owner',
        label: 'Owner'
    },
    admin: {
        value: 'admin',
        label: 'Admin'
    },
    editor: {
        value: 'editor',
        label: 'Editor'
    },
    viewer: {
        value: 'viewer',
        label: 'Viewer'
    },
};

export const documentUploadMethods = {
    both: {
        value: 'both',
        label: 'Both'
    },
    did: {
        value: 'did',
        label: 'DIDs (Decentralized Identifiers)'
    },
    blockchain: {
        value: 'blockchain',
        label: 'Blockchain'
    },
};

export const walletTypes = {
    trudoc: {
        value: 'trudoc',
        label: 'Trudoc'
    },
    metamask: {
        value: 'metamask',
        label: 'MetaMask'
    },
};

export const metaMaskStates = {
    initializing: "initializing",
    unavailable: "unavailable",
    notConnected: "notConnected",
    connecting: "connecting",
    connected: "connected",
}

export const network = "mainnet";
export const networkChainId = 1;


export const DocStatus = {
    SIGNED: "SIGNED",
    REJECTED: "REJECTED",
    PENDING: "PENDING",
    ISSUED: "ISSUED",
    AWAITING_APPROVAL: "AWAITING_APPROVAL",
    REJECTED_BY_OTHER: "REJECTED_BY_OTHER"
}

export const CorporateStatus = {
    AWAITING_APPROVAL: "AWAITING_APPROVAL",
    ACTIVE: "ACTIVE",
    REJECTED: "REJECTED"
}

export const UserRoles = {
    SUPER_ADMIN: 'superAdmin',
    ADMIN: 'admin',
    SIGNER: 'signer',
    USER: 'user',
};

export const pickerLang = {
    months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    from: 'From', to: 'To',
}