import mock from './domainlist.mock';
import { Grid, Box, makeStyles, Button, Typography, TextField, withStyles, Paper, InputAdornment } from '@material-ui/core';
import LanguageIcon from '@material-ui/icons/Language';
import SearchIcon from '@material-ui/icons/Search';
import { useEffect, useState } from 'react';
import SendMailIcon from '../../../static/images/sendEmail.png'
import { resendDomainVerification } from '../../../services/admin.service';
import Toast from '../../../utils/Toast';
import { MessageConstants } from '../../../utils/MessageConstants';
import { useSocketContext } from '../../../services/socket';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      padding: 10,
    },
    button: {
      width: 220,
      height: 45,
      borderRadius: 25,
      backgroundColor: theme.palette.admin.main,
      '&:hover': {
        backgroundColor: theme.palette.admin.main,
      }
    },
    buttonText: {
      fontWeight: "bold",
      fontSize: 14,
      letterSpacing: "0.15em",
      color: "#FFFDFD",
    },
    field: {
      width: 250,
      borderRadius: 10,
      color: theme.palette.admin.main,
    },
    textFieldInput: {
      color: theme.palette.admin.main,
    },
    actionBox: {
      marginTop: 50
    },
    domainContainer: {
      minWidth: '100%',
      width: '100%',
      maxWidth: '100%',
      color: 'gray',
      cursor: "pointer",
      margin: '0 auto',
      textTransform: 'uppercase',
      '&:hover': {
        color: theme.palette.admin.main,
      }
    },
    domainContainerWrapper: {
      padding: 15,
    },
    itemUrl: {
      fontSize: 14,
      wordBreak: 'break-all'
    }
  }
});

const CssTextField = withStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      borderColor: "#BF85FA",
      color: "#BF85FA",
      "& fieldset": {
        color: "#BF85FA",
        borderColor: "#BF85FA",
      },
      "&:focus": {
        color: "#BF85FA",
        borderColor: "#BF85FA",
      },
      "&:hover fieldset": {
        borderColor: "#BF85FA",
      },
      borderRadius: 10,
    },
  },
})(TextField);

const DomainList = ({ openAddDomainModal, domains, setSelectedDomain }) => {
  const toastInitialValues = {
    isOpen: false,
    isSuccess: false,
    isError: false,
    message: ""
  }
  const classes = useStyles();
  const [data, setData] = useState(domains);
  const [toast, setToast] = useState(toastInitialValues);
  const [progress, setProgress] = useState(false);
  const socket = useSocketContext();

  const handleSearch = (value) => {
    setData([...domains].filter(item => item?.name?.toLowerCase()?.includes(value?.toLowerCase())))
  }

  const closeToast = () => {
    return setToast((prev) => ({
      ...prev,
      isOpen: false,
      isSuccess: false,
      isError: false,
      message: ""
    }));
  };

  const mailHandler = (id) => {
    resendDomainVerification(id).then(res => {
      if (res && res.status === 200) {
        setToast((prev) => ({
          ...prev,
          isOpen: true,
          isSuccess: true,
          message: MessageConstants.EMAIL_SEND_SUCCESS
        }));
      }
    }).catch(e => {
      setToast((prev) => ({
        ...prev,
        isOpen: true,
        isError: true,
        message: MessageConstants.EMAIL_SEND_FAILED
      }));
    })
  }

  const socketProgressHandler = (data) => {
    setProgress(data);
  }

  useEffect(() => {
    setData(domains);
  }, [domains])

  useEffect(() => {
    socket.on("domain_response_in_process", socketProgressHandler);
  }, [socket]);

  return (
    <>
      {toast.isOpen && <Toast message={toast.message} isError={toast.isError} isSuccess={toast.isSuccess} closeToast={closeToast} />}
      <Box className={classes.root}>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
          spacing={3}
        >
          {data && data instanceof Array && data.map((item, index) => {
            return (
              <Grid item xs={4} align="center" key={index} onClick={() => setSelectedDomain(item)}>
                <Paper elevation={3} className={classes.domainContainer}>
                  <Box className={classes.domainContainerWrapper}>
                    <Typography variant='h3'>
                      <LanguageIcon fontSize="inherit" />
                    </Typography>
                    <Typography variant="h6" className={classes.itemUrl}>{item.name}</Typography>
                    <Button onClick={() => mailHandler(item.id)}>
                      <img src={SendMailIcon} alt="SendEmail" />
                    </Button>
                  </Box>
                </Paper>
              </Grid>
            )
          })}
        </Grid>
        <Box display="flex" alignItems="center" justifyContent="space-between" className={classes.actionBox}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.button}
            size="large"
            onClick={openAddDomainModal}
            disabled={progress}
          >
            <Typography className={classes.buttonText}>Add new domain</Typography>
          </Button>

          <CssTextField
            label="Search Domain"
            className={classes.field}
            variant="outlined"
            onChange={e => handleSearch(e.target.value)}
            InputProps={{
              endAdornment: <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            }}
            InputLabelProps={{ className: classes.textFieldInput }}
          />
        </Box>
      </Box>
    </>
  )
}

export default DomainList;
