import React, { useState } from "react";
import SignatureCanvas from "react-signature-canvas";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { COLORS } from "../utils/constants";
import { AppBar, Box, Button, makeStyles, Typography } from "@material-ui/core";
const SigPad = ({ sigPadRef, setChanged }) => {
  const [hasContent, setHasContent] = useState(false);
  const [penColor] = useState("#000000");

  const sigPadClear = () => {
    sigPadRef.current.clear();
    setChanged(false);
    setHasContent(false);
  };

  const onEnd = () => {
    setChanged(true);
    setHasContent(true);
  };
  return (
    <div>
      <div>
        <Typography align="center" style={{marginBottom:"20px"}}>Draw Signature</Typography>
        <Box container direction="column" alignItems="center" justify="center">
          <SignatureCanvas
            minWidth={2}
            penColor={penColor}
            canvasProps={{ width: 900, height: 200 }}
            backgroundColor="#F5F5F5"
            ref={sigPadRef}
            onEnd={onEnd}
          />
        </Box>
        {hasContent && (
          <button type="button" className="clear flat" onClick={sigPadClear}>
            <FontAwesomeIcon icon={faTrashAlt} color={penColor} />
          </button>
        )}
      </div>
    </div>
  );
};

export default SigPad;
