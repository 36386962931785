import React from "react";
import Button from "@material-ui/core/Button";
import { CircularProgress, makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  spinner: {
    height: '18px !important',
    width: '18px !important'
  },
}));

const LoaderButton = (props) => {
  const classes = useStyles();
  return (
    <>
      <Button
        onClick={props?.onClick}
        disabled={props?.isLoading || props.disabled}
        type={props?.type}
        variant={props?.variant}
        color={props?.color}
        className={`${props.className && props.className}`}
      >
        <Typography className={props?.textClass}>{props.text}</Typography>
        {props?.isLoading ? <CircularProgress className={classes.spinner} /> : null}
      </Button>
    </>
  );
};

export default LoaderButton;
