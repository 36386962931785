import React, { FunctionComponent } from "react";
import EndorsementJourney from "./EndorsementJourney";
import { withStyles, makeStyles } from "@material-ui/core";
const useStyles = makeStyles((theme) => {
  return {
    statusTitle: {
      fontSize: "15px",
      fontWeight: 500,
      fontFamily: "Poppins",
      lineHeight: "19px",
      color: "#262727",
    },
  };
});

function SimpleTableRow({
  index,
  date,
  actionTitle,
  displayDashHead,
  displayDot,
  displayDashTail,
}) {
  const classes = useStyles();
  return (
    <tr className="table-row" key={index}>
      <td className="table-cell">
        <div className={classes.statusTitle}>{actionTitle}</div>
        {date && (
          <div className={classes.statusTitle} data-testid="date">
            {date}
          </div>
        )}
      </td>
      <td className="table-cell" colSpan={2}>
        <EndorsementJourney
          displayDashHead={displayDashHead}
          displayDot={displayDot}
          displayDashTail={displayDashTail}
        />
      </td>
    </tr>
  );
}
export default SimpleTableRow;
