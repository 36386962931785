import { useState, forwardRef } from 'react';
import { Slide, Typography, makeStyles, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton } from '@material-ui/core';
import Form from './Form';
import { Cancel } from '@material-ui/icons';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="top" ref={ref} {...props} />;
});


const useStyles = makeStyles((theme) => {
  return {
    dialogRoot: {
      width: '700px',
      borderRadius: 50,
      margin: '0 auto',
    },
    contentHeight: {
      minHeight: '350px',
      maxWidth: '100%',
      margin: '0 auto',
    },
    title: {
      paddingTop: 20,
      textAlign: 'center',
      fontWeight: '600',
      textTransform: 'uppercase',
      letterSpacing: 0.5,
      wordSpacing: 2,
      paddingBottom: 10
    },
    stepText: {
      fontWeight: '600',
      paddingBottom: 30,
      textAlign: 'center',
      color: theme.palette.admin.main,
      letterSpacing: 1,
    },
    actionBar: {
      justifyContent: 'center',
      marginBottom: 50
    },
    nextButton: {
      width: 220,
      height: 50,
      borderRadius: 25,
      color: '#fff',
      letterSpacing: 1,
      backgroundColor: theme.palette.admin.main,
      '&:hover': {
        backgroundColor: theme.palette.admin.main,
      }
    },
    draftButton: {
      width: 220,
      height: 50,
      borderRadius: 25,
      letterSpacing: 1,
      backgroundColor: '#fff',
      color: "#6a6a6a",
      border: '1px solid gray',
      '&:hover': {
        backgroundColor: "#fff", 
        color: '#363636',
        border: '1px solid #464646'
     }
    },
    closeIcon:{
      position: 'absolute',
      right: '0',
      padding: '10px'
    },
    buttonTextCancel: {
      fontWeight: "bold",
      fontSize: 16,
      letterSpacing: "0.15em",
    },
    buttonRight: {
      marginTop: "20px",
      marginBottom: "10px",
      border: '1px solid gray',
      backgroundColor: '#fff',
      width: 150,
      height: 45,
      color: "#6a6a6a",
      borderRadius: 25,
      '&:hover': {
        backgroundColor: "#fff", 
        color: '#363636',
        border: '1px solid #464646'
     }
    }
  }
});

const AddOrEditContact = ({ open, handleClose, handleChange, data, state, handleSubmit, popupId }) => {
  const classes = useStyles();

  return (
    <Dialog
      fullWidth={true}
      maxWidth='md'
      open={open}
      onClose={handleClose}
      className={classes.dialogRoot}
      keepMounted={false}
      TransitionComponent={Transition}
      PaperProps={{
        style: { borderRadius: 20, maxHeight: 'calc(100% - 44px)' }
      }}
    >
      <div className={classes.closeIcon}>
      <IconButton
        onClick={handleClose}
      >
        <Cancel/>
      </IconButton>  
      </div>
      <DialogTitle gutterBottom>
        <Typography variant='h5' className={classes.title}>
          {popupId === 0 ? "Add New Contact" : "Update Contact"}
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.contentHeight}>
        <Form
          state={state}
          handleChange={handleChange}
          popupId={popupId}
        />
      </DialogContent>
      <DialogActions className={classes.actionBar} gutterBottom>
        <Button className={classes.nextButton} onClick={handleSubmit}>
          <Typography variant='h6'>{popupId === 0 ? "Save" : "Update"}</Typography>
        </Button>
        <Button className={classes.draftButton} onClick={handleClose}>
          <Typography variant='h6'>{"Cancel"}</Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AddOrEditContact;