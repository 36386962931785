import { Button, Dialog, DialogContent, DialogTitle, IconButton, makeStyles, SvgIcon } from "@material-ui/core";
import { Close, Publish } from "@material-ui/icons";
import React, { useState, useEffect } from "react";
import { isNotEmpty } from "../utils/Generic";
import AllDocuments from "../views/dms/AllDocuments";

const useStyles = makeStyles((theme) => ({

}));

const DmsChooser = (props) => {

    const classes = useStyles();
    const [openPickerModal, setOpenPickerModal] = useState(false);

    const handleOpenPicker = (e) => {
        e?.preventDefault();
        e?.stopPropagation();
        setOpenPickerModal(true);
        return false;
    }

    const handleClosePicker = (e) => {
        e?.preventDefault();
        e?.stopPropagation();
        setOpenPickerModal(false);
        return false;
    }

    const sendFileData = (data) => {
        props.onSelected(data);
    }

    const onFileSelected = (fileData) => {
        handleClosePicker();
        sendFileData({
            ...fileData,
            ...{
                pickerType: 'dms'
            }
        });
    }

    const handleChildClicks = (e) => {
        e?.preventDefault();
        e?.stopPropagation();
    }

    return (
        <>
            <Button className="dms" onClick={handleOpenPicker} title="Choose File from TruDoc Store">
                <img src="/assets/images/dms-upload-icon-1.png" />
            </Button>
            <Dialog
                onClick={handleChildClicks}
                onClose={handleClosePicker}
                fullWidth={true}
                maxWidth='lg'
                open={openPickerModal}
                className={classes.dialogRoot}
                keepMounted={false}
                PaperProps={{
                    style: { borderRadius: 20, maxHeight: 'calc(100% - 44px)' }
                }}
            >
                <DialogTitle>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        Choose File
                        <IconButton onClick={handleClosePicker} style={{ marginLeft: 'auto' }}>
                            <Close />
                        </IconButton>
                    </div>
                </DialogTitle>
                <DialogContent dividers className={classes.contentHeight}>
                    <AllDocuments handleFileSelected={onFileSelected} dmsType="fileSelector" fileTypes={["application/pdf"]} />
                </DialogContent>
            </Dialog>
        </>
    );

};

export default DmsChooser;
