import React, { useEffect, useRef, useState } from "react";
import {
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    Slider,
    TableCell,
    TableRow,
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Toast from "../../utils/Toast";
import { UNSDG_GOALS, UNSDG_GOAL_CATEGORIES } from "../../utils/unsdg-goals";
import { useHistory } from "react-router-dom";
import { generateSusChart, goalsParseCategories, isNotEmpty, parseByGoals, printTime } from "../../utils/Generic";
import Chart from "react-google-charts";
import { addKPIData, deleteKPI, deleteObjective, editKPIData } from "../../services/admin.service";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { Grid, Paper } from "@material-ui/core";
import KpiTable from "./kpi-table";
import { Close } from "@material-ui/icons";
import AddIcon from '@material-ui/icons/Add';


const DashboardPerformance = (props) => {

    const { objectives, report, goals, setGoals, companyDocuments, canSave, loadData } = props;

    const [kpiCount, setKpiCount] = useState(0)

    const [objective, setObjective] = useState({});
    const [goalDataItems, setGoalDataItems] = useState({});
    const [newKPIVal, setNewKPIVal] = useState('');
    const [newKPIStatus, setNewKPIStatus] = useState('');
    const [newKPIProgress, setNewKPIProgress] = useState(0);

    const [showNewModal, setShowNewModal] = useState(false);
    const [objectiveIndex, setObjectiveIndex] = useState(0);
    const [openTopicModal, setOpenTopicModal] = useState(false)
    const [objKeyVal, setObjKeyVal] = useState(null)
    const [isEdit, setIsEdit] = useState(false)
    const [editId, setEditId] = useState(null)
    const [objectiveId, setObjectiveId] = useState(null)
    const [priorityCount, setPriorityCount] = useState({
        'moderate': 0,
        'major': 0,
        'significant': 0,
        'negligible': 0
    })

    const toastInitialValues = {
        isOpen: false,
        isSuccess: false,
        isError: false,
        message: ""
    }

    const [toast, setToast] = useState(toastInitialValues);

    const closeToast = () => {
        return setToast((prev) => ({
            ...prev,
            isOpen: false,
            isSuccess: false,
            isError: false,
            message: ""
        }));
    };

    const handleShowNewModalClose = () => {
        setNewKPIVal('');
        setObjectiveId(null)
        setNewKPIProgress(0);
        setNewKPIStatus('');
        setIsEdit(false)
        setEditId(null)
        setShowNewModal(false);
    }


    const marks = [
        {
            value: 11
        },
        {
            value: 22
        },
        {
            value: 33
        },
        {
            value: 44
        },
        {
            value: 55
        },
        {
            value: 66
        },
        {
            value: 77
        },
        {
            value: 88
        },
        {
            value: 100
        },
    ];

    useEffect(() => {
        let count = 0
        objectives.map(obj => {
            if (isNotEmpty(obj.kpis))
                count += obj.kpis.length
        })

        setKpiCount(count)
    })


    const handleEditKPI = (e, kpiData, objective_id = null) => {
        console.log(objective_id)
        setIsEdit(true)
        setEditId(kpiData.id)
        setNewKPIVal(kpiData.kpi)
        setNewKPIStatus(kpiData.kpi_status)
        setNewKPIProgress(kpiData.kpi_progress)
        setShowNewModal(true)
        setObjectiveId(objective_id)
    }

    const addMoreKPI = (objectiveId) => {

        setNewKPIVal('');
        setNewKPIProgress(0);
        setNewKPIStatus('');
        setIsEdit(false);
        setEditId(null);
        setShowNewModal(true);
        setObjectiveId(objectiveId)
    }


    const handleDeleteKPI = (e, kpiData) => {
        if (window.confirm("Are you sure you want to delete KPI?") === true) {

            deleteKPI({
                id: kpiData.id
            })
                .then(res => {
                    const response = res.data
                    if (response.success == true)
                        showSuccess(response.message)
                    else
                        showError(response.message)

                    loadData()
                })
                .catch(err => {
                    console.log(err)
                    showError(err)
                })
        }
    }


    const showError = (errorMsg) => {
        setToast((prev) => ({
            ...prev,
            isOpen: true,
            isError: true,
            isSuccess: false,
            message: errorMsg
        }));
    }

    const showSuccess = (succMsg) => {
        setToast((prev) => ({
            ...prev,
            isOpen: true,
            isError: false,
            isSuccess: true,
            message: succMsg
        }));
    }

    const handleRelevanceSlideChange = (e, value) => {
        setNewKPIProgress(value)
    }

    const addNewKPI = () => {
        if (newKPIVal.trim() != '' && newKPIStatus.trim() != '') {
            if (isEdit == false) {
                addKPIData({
                    id: report?.id,
                    kpiTitle: newKPIVal,
                    objId: objectiveId,
                    kpiStatus: newKPIStatus,
                    kpiProgress: newKPIProgress
                }).then(res => {

                    const response = res.data
                    if (response.success == true)
                        showSuccess(response.message);
                    else
                        showError(response.message)

                    loadData()
                })
                    .catch(err => {
                        console.log(err)
                        showError("Failed to add KPI");
                    })
            } else {
                editKPIData({
                    id: report?.id,
                    kpiTitle: newKPIVal,
                    objId: objectiveId,
                    kpiStatus: newKPIStatus,
                    kpiProgress: newKPIProgress,
                    kpi_id: editId
                }).then(res => {
                    console.log(res)
                    const response = res.data
                    if (response.success == true)
                        showSuccess(response.message);
                    else
                        showError(response.message)

                    loadData()
                })
                    .catch(err => {
                        console.log(err)
                        showError("Failed to add KPI");
                    })
            }
            setNewKPIVal('');
            setShowNewModal(false)

        }
        else {
            showError('KPI cannot by empty!')
        }

    }
    const CustomSlider = withStyles({
        rail: {
          backgroundImage: "red"
        },
        track: {
          backgroundImage: "red"
        }
      })(Slider);

    return (
        <>
            <div className="performance-main">
                <div className="dashboard-leftbar">
                    <div className="kpi-count-div">
                        <h2>Total KPIs</h2>
                        <span>{kpiCount}</span>
                    </div>
                    <div className="status-div">
                        <h2>Status</h2>
                        <h4><img src="/assets/images/New KPI.png" alt="" />New KPI</h4>
                        <h4><img src="/assets/images/In Progress.png" alt="" />In Progress</h4>
                        <h4><img src="/assets/images/Ongoing Commitment.png" alt="" />Ongoing Commitment</h4>
                    </div>
                </div>
                <div className="topic-backgroud card_header">
                    <div className="bg-light-grey">
                        <h5 className="fs-24 m-0">Objectives with Goals & KPIs</h5>
                    </div>
                    <div>
                        {
                            report?.sustainability_goal_objectives.length > 0 ?
                                report?.sustainability_goal_objectives.map((obj, index) => {

                                    let goalDataItemsTemp = parseByGoals(obj?.sustainability_goal_subjects);
                                    return (

                                        <div className='topic-list navy-table'>
                                            <table aria-label="simple table" className="newui-table">
                                                <thead>
                                                    <tr rowspan="7">
                                                        <th colspan="4">
                                                            <div className="objective-header">
                                                                <h3>{index + 1}. {obj.objective}</h3>
                                                            </div>
                                                        </th>
                                                        <th colspan="4">
                                                            <div className="allimg-flex">
                                                                {
                                                                    Object.keys(goalDataItemsTemp).map((key) => {
                                                                        const goalItem = goalDataItemsTemp[key]
                                                                        let goalNumber = goalItem?.goal_number;
                                                                        return <>
                                                                            <img className="hover-raised-image" height="58px" width="58px" src={`/assets/images/sdg-icons/E-WEB-Goal-${goalNumber}.png`} />
                                                                        </>
                                                                    })
                                                                }
                                                            </div>

                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    <KpiTable objective={obj} handleDeleteKPI={handleDeleteKPI} handleEditKPI={handleEditKPI} />

                                                    {/* <tr rowspan="7">
                                                        <td colspan="4">
                                                            <button style={{ cursor: 'pointer' }} className="btn-add" onClick={e => addMoreKPI(obj.id)}> <span><AddIcon /></span> Add more KPI</button>
                                                        </td>
                                                    </tr> */}
                                                </tbody>
                                            </table>
                                        </div>
                                    )
                                })
                                :
                                'No Data Found!'
                        }
                    </div>
                </div>
            </div>
            <Dialog
                onClose={handleShowNewModalClose}
                fullWidth={true}
                maxWidth='sm'
                open={showNewModal}
                className={`theme-base-modal sustainability-layout NewUi_modals  addkpimodal`}
                keepMounted={false}
                PaperProps={{
                    style: { borderRadius: 20, maxHeight: 'calc(100% - 44px)' }
                }}
            >
                <DialogTitle>
                    <div className="modal-head">
                        <span className="capital-case text-center">{isEdit ? 'Edit' : 'Add'} KPI</span>
                        <IconButton onClick={handleShowNewModalClose} style={{ marginLeft: 'auto' }}>
                            <Close />
                        </IconButton>
                    </div>
                </DialogTitle>
                <DialogContent dividers >
                    <DialogContentText>
                        <div className="sus-objective-box">
                            <label htmlFor="kpi_name">KPI Name</label>
                            <input id="kpi_name" className='sus-objective-field mb-20' onChange={(e) => setNewKPIVal(e?.target?.value)} placeholder='Add New KPI' value={newKPIVal} />
                            <label htmlFor="kpi_status">Status</label>
                            <select id="kpi_status" className='sus-objective-field mb-20' onChange={(e) => setNewKPIStatus(e?.target?.value)} placeholder='' value={newKPIStatus}>
                                <option selected value="" disabled hidden>Please Select</option>
                                <option value="New KPI" style={{ backgroundImage: "/assets/images/New KPI.png" }}> New KPI</option>
                                <option value="In Progress" style={{ backgroundImage: "/assets/images/In Progress.png" }}> In Progress</option>
                                <option value="Ongoing Commitment" style={{ backgroundImage: "/assets/images/Ongoing Commitment.png" }}> Ongoing Commitment</option>
                            </select>
                            <label htmlFor="kpi_progress">Status</label>
                            <CustomSlider className="unsdg-range" onChange={(e, value) => handleRelevanceSlideChange(e, value)} marks={marks} step={null} value={newKPIProgress} aria-label="Default" valueLabelDisplay="auto" />
                            <button onClick={addNewKPI} className="btn-sustainability primary mt-10px">Save Changes</button>
                        </div>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default DashboardPerformance;