import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Slider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@material-ui/core";
import { makeStyles, styled } from "@material-ui/core/styles";
import Toast from "../../utils/Toast";
import RoundProgresBar from "../charts/RoundProgressBar";
import { UNSDG_GOALS, UNSDG_GOAL_CATEGORIES } from "../../utils/unsdg-goals";
import { Close } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { goalsParseCategories } from "../../utils/Generic";
import ListObjective from "./list-objective";

const useStyles = makeStyles((theme) => ({
  root: {
    width: `calc(100% - ${300}px)`,
    marginLeft: 280,
    minHeight: "88vh",
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({

}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  height: '118px',
  border: 'none',
  '&:nth-of-type(even)': {
    backgroundColor: '#334bc120',
    border: 'none'
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const Track = (props) => {

  const { goals, objectives, setGoals, companyDocuments, canSave } = props;

  let history = useHistory();

  const classes = useStyles();

  const toastInitialValues = {
    isOpen: false,
    isSuccess: false,
    isError: false,
    message: ""
  }

  const [toast, setToast] = useState(toastInitialValues);

  const closeToast = () => {
    return setToast((prev) => ({
      ...prev,
      isOpen: false,
      isSuccess: false,
      isError: false,
      message: ""
    }));
  };

  const showError = (errorMsg) => {
    setToast((prev) => ({
      ...prev,
      isOpen: true,
      isError: true,
      isSuccess: false,
      message: errorMsg
    }));
  }

  const showSuccess = (succMsg) => {
    setToast((prev) => ({
      ...prev,
      isOpen: true,
      isError: false,
      isSuccess: true,
      message: succMsg
    }));
  }

  const handleGoBack = () => {
    history.push("/sustainability/objectives");
  }

  const handleGoNext = () => {
    history.push("/sustainability/overview");
  }

  return (
    <>

      {toast.isOpen && <Toast message={toast.message} isError={toast.isError} isSuccess={toast.isSuccess} closeToast={closeToast} />}

      <div className='objective_main undsg_main'>
        <h1 className="text-gradient">Track Your Objectives</h1>
        <Box>

          {
            Object.keys(goals).length <= 0 ? <>
              <p className="no-results">No Goals Found</p>
            </> : <>

              <div className="new-design">
                <div className="main-discrimination-box">

                  <ListObjective loadData={() => props?.loadData()} goals={goals} objectives={objectives} />

                </div>
              </div>


            </>
          }

        </Box>

        <Box className="btn-sustainability-box">
          <div className="left">
            {
              canSave ? <>
                {/* <button onClick={(e) => props?.onSave()} className="btn-sustainability secondary">
                  Save
                </button> */}
              </> : null
            }
          </div>
          <div className="right">
            <button onClick={handleGoBack} className="btn-sustainability primary-out me-10px">
              Back
            </button>
            {/* <button onClick={handleGoNext} className="btn-sustainability primary">
              Next
            </button> */}
            {
              canSave ? <>
                <button onClick={(e) => props?.onSave()} className="btn-sustainability primary">
                  Save
                </button>
              </> : null
            }
          </div>
        </Box>

      </div>


    </>
  );
};

export default Track;