import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  LinearProgress,
  Paper,
  Slider,
  Snackbar,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography
} from "@material-ui/core";
import { makeStyles, styled } from "@material-ui/core/styles";
import Toast from "../../utils/Toast";
import GoalBox from "./goal-box";
import UnsdgGoals from "./unsdg-goals";
import UnsdgRelevance from "./unsdg-relevance";
import { useHistory, useParams } from "react-router-dom";
import { sustainabilityLogs } from "../../services/admin.service";
import { printTime } from "../../utils/Generic";

const useStyles = makeStyles((theme) => ({
  root: {
    width: `calc(100% - ${300}px)`,
    marginLeft: 280,
    minHeight: "88vh",
  },
}));

const History = (props) => {

  const classes = useStyles();
  let history = useHistory();

  const { goals, setGoals, companyDocuments } = props;

  const toastInitialValues = {
    isOpen: false,
    isSuccess: false,
    isError: false,
    message: ""
  }

  const [toast, setToast] = useState(toastInitialValues);

  const closeToast = () => {
    return setToast((prev) => ({
      ...prev,
      isOpen: false,
      isSuccess: false,
      isError: false,
      message: ""
    }));
  };

  const showError = (errorMsg) => {
    setToast((prev) => ({
      ...prev,
      isOpen: true,
      isError: true,
      isSuccess: false,
      message: errorMsg
    }));
  }

  const showSuccess = (succMsg) => {
    setToast((prev) => ({
      ...prev,
      isOpen: true,
      isError: false,
      isSuccess: true,
      message: succMsg
    }));
  }

  let { activeTab } = useParams();

  const [logs, setLogs] = useState([]);
  const [logsLoading, setLogsLoading] = useState(false);

  const loadLogs = () => {
    setLogsLoading(true);
    sustainabilityLogs().then(res => {
      setLogsLoading(false);
      setLogs(res.data.data);
    }).catch(err => {
      setLogsLoading(false);
      showError("Failed to Load Logs")
    });
  }

  useEffect(() => {
    loadLogs();
  }, []);

  return (
    <>

      {toast.isOpen && <Toast message={toast.message} isError={toast.isError} isSuccess={toast.isSuccess} closeToast={closeToast} />}

      <div className='about_wrapper'>
        <h1>History</h1>

        {
          logsLoading ? <>
            <div className="text-center">
              <CircularProgress color="primary" size={20} />
            </div>
          </> : <>
            <div className="table-responsive">
              <table className="table table-default table-green-head">
                <thead>
                  <tr>
                    <th>User</th>
                    <th>Particular</th>
                    <th>Time</th>
                  </tr>
                </thead>
                <tbody>
                  {(logs && logs.length > 0) ?
                    logs.map((log) => {
                      return (
                        <>
                          <tr
                            className={`dms-table-row`}
                            key={`log-${log.id}`}
                          >

                            <td>{log?.user?.email}</td>
                            <td>{log?.particular}</td>
                            <td>{printTime(log?.created_at)}</td>
                          </tr>
                        </>
                      );
                    }) : <>
                      <tr>
                        <td colspan="3">No Logs Found</td>
                      </tr>
                    </>}
                </tbody>
              </table>
            </div>
          </>
        }

      </div>

    </>
  );
};

export default History;