import { useState, forwardRef } from 'react';
import { Slide, Typography, makeStyles, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, MenuItem, withStyles, InputBase, Checkbox, TextField, Grid, FormControl, Select, Divider, Box, CircularProgress } from '@material-ui/core';
import { Cancel } from '@material-ui/icons';
import { useEffect } from 'react';
import { updateCollaborator } from '../../services/api.service';
import { MessageConstants } from '../../utils/MessageConstants';
import Toast from '../../utils/Toast';
import { documentRoles } from "../../constants";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="top" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => {
  return {
    dialogRoot: {
      width: '700px',
      borderRadius: 50,
      margin: '0 auto',
    },
    contentHeight: {
      minHeight: '100px',
      maxWidth: '100%',
      margin: '0 auto',
      minWidth: '300px'
    },
    title: {
      paddingTop: 20,
      textAlign: 'center',
      fontWeight: '600',
      textTransform: 'uppercase',
      letterSpacing: 0.5,
      wordSpacing: 2,
      paddingBottom: 10
    },
    stepText: {
      fontWeight: '600',
      paddingBottom: 30,
      textAlign: 'center',
      color: theme.palette.admin.main,
      letterSpacing: 1,
    },
    actionBar: {
      justifyContent: 'center',
      marginBottom: 50
    },
    nextButton: {
      width: 220,
      height: 50,
      borderRadius: 25,
      color: '#fff',
      letterSpacing: 1,
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
      }
    },
    draftButton: {
      width: 220,
      height: 50,
      borderRadius: 25,
      letterSpacing: 1,
      backgroundColor: '#fff',
      color: "#6a6a6a",
      border: '1px solid gray',
      '&:hover': {
        backgroundColor: "#fff",
        color: '#363636',
        border: '1px solid #464646'
      }
    },
    closeIcon: {
      position: 'absolute',
      right: '0',
      padding: '10px'
    },
    buttonTextCancel: {
      fontWeight: "bold",
      fontSize: 16,
      letterSpacing: "0.15em",
    },
    buttonRight: {
      marginTop: "20px",
      marginBottom: "10px",
      border: '1px solid gray',
      backgroundColor: '#fff',
      width: 150,
      height: 45,
      color: "#6a6a6a",
      borderRadius: 25,
      '&:hover': {
        backgroundColor: "#fff",
        color: '#363636',
        border: '1px solid #464646'
      }
    },
  }
});



const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 50,
    position: 'relative',
    backgroundColor: '#fff',
    border: '1px solid #66CFA4',
    fontSize: 18,
    padding: '18px 14px',
    color: theme.palette.primary.main,
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      backgroundColor: '#fff',
      borderColor: theme.palette.primary.main,
      color: theme.palette.primary.main,
      borderRadius: 50,
    },
  },
}))(InputBase);

const GreenCheckbox = withStyles((theme) => ({
  root: {
    color: theme.palette.admin.main,
    '&$checked': {
      color: theme.palette.admin.main,
    },
  },
  checked: {},
}))((props) => <Checkbox color="default" {...props} />);

const CssTextField = withStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#66CFA4",
      },
      "&:hover fieldset": {
        borderColor: "#66CFA4",
      },
      borderRadius: 50,
    },
  },
})(TextField);

const EdiCollaborator = (props) => {

  const toastInitialValues = {
    isOpen: false,
    isSuccess: false,
    isError: false,
    message: ""
  }

  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const [modelFields, setModelFields] = useState({
    role: ''
  });

  const [document, setDocument] = useState({});
  const [collaborator, setCollaborator] = useState({});

  const [isLoading, setIsLoading] = useState(false);

  const [toast, setToast] = useState(toastInitialValues);

  const onSubmit = () => {
    modelFields['documentId'] = document?.id;
    modelFields['collaboratorId'] = collaborator?.id;
    setIsLoading(true);
    updateCollaborator(modelFields).then(res => {
      console.log(res);
      setIsLoading(false);
      if (res.status.toString() === '200') {
        let data = res.data;
        if (data.success) {
          sendClose(true);
          setToast((prev) => ({
            ...prev,
            isOpen: true,
            isSuccess: true,
            message: MessageConstants.COLLABORATOR_ADDED_SUCCESS
          }));
        } else {
          throw data.message;
        }
      } else {
        throw res;
      }
    }).catch(e => {
      setIsLoading(false);
      let msg = MessageConstants.COLLABORATOR_ADDED_FAILED;
      if ((typeof e).toString().toLowerCase() == "string") {
        msg = e;
      }
      setToast((prev) => ({
        ...prev,
        isOpen: true,
        isError: true,
        message: msg
      }));
    });
  }

  const sendClose = (anythingChange = false) => {
    props.handleClose(anythingChange);
  }

  const onClose = () => {
    sendClose();
  }

  const handleChange = (name, value) => {
    modelFields[name] = value;
    setModelFields({ ...modelFields });
  }

  const closeToast = () => {
    return setToast((prev) => ({
      ...prev,
      isOpen: false,
      isSuccess: false,
      isError: false,
      message: ""
    }));
  };

  useEffect(() => {
    setOpen(props?.open);
    setCollaborator(props?.collaborator);
    setDocument(props?.document);
  }, [props]);

  useEffect(() => {
    handleChange('role', collaborator?.role);
  }, [collaborator]);

  return (
    <>
      {toast.isOpen && <Toast message={toast.message} isError={toast.isError} isSuccess={toast.isSuccess} closeToast={closeToast} />}
      <Dialog
        fullWidth={true}
        maxWidth='md'
        open={open}
        onClose={onClose}
        className={classes.dialogRoot}
        keepMounted={false}
        TransitionComponent={Transition}
        PaperProps={{
          style: { borderRadius: 20, maxHeight: 'calc(100% - 44px)' }
        }}
      >
        <div className={classes.closeIcon}>
          <IconButton
            onClick={onClose}
          >
            <Cancel />
          </IconButton>
        </div>
        <DialogTitle gutterBottom>
          <Typography variant='h5' className={classes.title}>
            {collaborator?.user?.email}
          </Typography>
        </DialogTitle>

        <DialogContent className={classes.contentHeight}>

          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={2}
          >


            <Grid item xs={3} align="right">
              <Typography className={classes.subHeading}>Role*</Typography>
            </Grid>
            <Grid item xs={9} align="left">
              <FormControl variant="outlined" fullWidth className={classes.formControl}>
                <Select
                  input={<BootstrapInput />}
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  label="Select a role"
                  value={modelFields.role || ""}
                  onChange={e => handleChange('role', e.target.value)}
                >
                  <MenuItem value={documentRoles.admin.value}>{documentRoles.admin.label}</MenuItem>
                  <MenuItem value={documentRoles.editor.value}>{documentRoles.editor.label}</MenuItem>
                  <MenuItem value={documentRoles.viewer.value}>{documentRoles.viewer.label}</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>

        </DialogContent>

        <DialogActions className={classes.actionBar} gutterBottom>

          <Box className="w-100 text-center">

            {isLoading ?
              <>
                <CircularProgress color="primary" size={20} />
              </>
              :
              <>
                <Button className={classes.nextButton + ' me-10px'} onClick={onSubmit}>
                  <Typography variant='h6'>Update</Typography>
                </Button>
                <Button className={classes.draftButton} onClick={onClose}>
                  <Typography variant='h6'>{"Cancel"}</Typography>
                </Button>
              </>
            }
          </Box>

        </DialogActions>


      </Dialog>
    </>
  );


}

export default EdiCollaborator;