import { Box, CircularProgress, Grid, makeStyles, Paper, StepLabel } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import InfoPaper from "../components/InfoPaper";
import VerticalLinearStepper from "../components/Stepper";
import { getStatus } from "../services/api.service";
import { useAuth } from "../hooks";

import { Link, NavLink, useHistory, useLocation } from "react-router-dom";
import { UserRoles } from "../constants";

const useStyles = makeStyles((theme) => {
  return {
    paper: {
      padding: theme.spacing(1),
      margin: theme.spacing(2),
      backgroundColor: 'transparent',
    },
    content: {
      display: "block",
      width: `calc(100% - ${300}px)`,
      marginLeft: 280,
      minHeight: "88vh",
    },
  };
});

function Dashboard() {
  const classes = useStyles();
  const { user } = useAuth();

  const location = useLocation();
  const history = useHistory();

  const [status, setStatus] = useState();
  const [statusLoading, setStatusLoading] = useState(true);

  const [verifiableStatus, setVerifiableStatus] = useState();
  const [verifiableStatusLoading, setVerifiableStatusLoading] = useState(true);

  useEffect(() => {

    document.title = `TruDoc | Dashboard`;

    setStatusLoading(true);
    getStatus({ email: user.email, type: 'tru-sign' })
      .then((res) => {
        setStatus(res.data.data);
        setStatusLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
        setStatusLoading(false);
      });

    setVerifiableStatusLoading(true);
    getStatus({ email: user.email, type: 'verifiable' })
      .then((res) => {
        setVerifiableStatus(res.data.data);
        setVerifiableStatusLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
        setVerifiableStatusLoading(false);
      });


  }, []);

  useEffect(() => {
    if (user?.username === "superadmin") {
      const { from } = location.state || { from: { pathname: "/super/dashboard" } };
      history.replace(from);
    }
  }, [user]);

  return (
    <Box className={classes.content}>

      <Grid
        container
        className="d-block mt-15px mb-15px"
        spacing={0}
      >
        <Grid item xs={12}>
          <h4 className="dashboard-title-header">Tru-Sign</h4>
        </Grid>
        <Grid item xs={12}>
          {
            statusLoading ? <>
              <Box width="100%" className="text-center py-50px">
                <CircularProgress color="primary" size={50} />
              </Box>
            </> : <>
              <Grid
                container
                spacing={0}
                // direction="column"
                // alignItems="center"
                justify="center"
              >
                {status?.map((_, index) => (
                  <InfoPaper dataLink="tru-sign" key={index} title={_.title} value={_.value} />
                ))}
              </Grid>
            </>
          }
        </Grid>
      </Grid>

      {

        (user?.role != UserRoles.USER) ? <>

          <Grid
            container
            className="d-block mt-15px mb-15px"
            spacing={0}
          >
            <Grid item xs={12}>
              <h4 className="dashboard-title-header">Verifiable Documents</h4>
            </Grid>
            <Grid item xs={12}>
              {
                verifiableStatusLoading ? <>
                  <Box width="100%" className="text-center py-50px">
                    <CircularProgress color="primary" size={50} />
                  </Box>
                </> : <>
                  <Grid
                    container
                    spacing={0}
                    // direction="column"
                    // alignItems="center"
                    justify="center"
                  >
                    {verifiableStatus?.map((_, index) => (
                      <InfoPaper dataLink="verifiable" key={index} title={_.title} value={_.value} />
                    ))}
                  </Grid>
                </>
              }
            </Grid>
          </Grid>

        </> : null

      }

    </Box>
  );
}

export default Dashboard;
