import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
  TableCell,
  TableRow,
} from "@material-ui/core";
import { makeStyles, styled } from "@material-ui/core/styles";
import Toast from "../../utils/Toast";
import LinkIcon from '@material-ui/icons/Link';
import GetAppIcon from '@material-ui/icons/GetApp';
import { useHistory } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { generateSusChart, goalsParseCategories, isNotEmpty, printTime } from "../../utils/Generic";
import Chart from "react-google-charts";
import DeleteIcon from '@material-ui/icons/Delete';
import { deleteObjective } from "../../services/admin.service";
import DasboardOverview from "./dashboard-overview";
import DashboardEsgImpact from "./dashboard-esg-impact";
import { useCorporateState } from "../../redux/context/corporate-context";
import { API_Base_URL } from "../../services/services.constant";
import copy from 'copy-to-clipboard';
import DashboardPerformance from "./dashboard-performance";

const useStyles = makeStyles((theme) => ({
  root: {
    width: `calc(100% - ${300}px)`,
    marginLeft: 280,
    minHeight: "88vh",
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({

}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  height: '118px',
  border: 'none',
  '&:nth-of-type(even)': {
    backgroundColor: '#334bc120',
    border: 'none'
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const SustainabilityDashboard = (props) => {

  const { objectives, report, goals, setGoals, companyDocuments, canSave, loadData } = props;

  const [modalOpen, setModalOpen] = useState(false);

  const [modalTitle, setModalTitle] = useState('');
  const [modalObjective, setModalObjective] = useState('');
  const [activeTab, setActiveTab] = useState(1)

  const [modalGoal, setModalGoal] = useState(-1);
  const [modalGoalObjectiveIndex, setModalGoalObjectiveIndex] = useState(-1);
  const [modalObjectiveType, setModalObjectiveType] = useState('');

  const [chartData, setChartData] = useState([]);

  const corporateState = useCorporateState()
console.log("USER DATA HERE",corporateState)
  useEffect(() => {
    setChartData(generateSusChart(report));
  }, [report]);

  let history = useHistory();

  const classes = useStyles();

  const toastInitialValues = {
    isOpen: false,
    isSuccess: false,
    isError: false,
    message: ""
  }

  const [toast, setToast] = useState(toastInitialValues);

  const closeToast = () => {
    return setToast((prev) => ({
      ...prev,
      isOpen: false,
      isSuccess: false,
      isError: false,
      message: ""
    }));
  };

  const showError = (errorMsg) => {
    setToast((prev) => ({
      ...prev,
      isOpen: true,
      isError: true,
      isSuccess: false,
      message: errorMsg
    }));
  }

  const showSuccess = (succMsg) => {
    setToast((prev) => ({
      ...prev,
      isOpen: true,
      isError: false,
      isSuccess: true,
      message: succMsg
    }));
  }

  const handleCloseModal = () => {
    setModalTitle('');
    setModalObjective('');
    setModalObjectiveType('');
    setModalGoal(-1);
    setModalGoalObjectiveIndex(-1);
    setModalOpen(false);
  }

  const handleGoBack = () => {
    history.push("/sustainability/track");
  }

  const goToPage = (pageUrl) => {
    history.push(pageUrl);
  }

  const handleDeleteObjective = (objectiveData) => {

    if (window.confirm("Are you sure you want to delete objective?") === true) {

      deleteObjective({
        id: objectiveData.id
      })
        .then(res => {
          const response = res.data
          if (response.success == true)
            showSuccess(response.message)
          else
            showError(response.message)

          loadData()
        })
        .catch(err => {
          console.log(err)
          showError(err)
        })
    }
    console.log("Click on delete from dashboard for ", objectiveData)
  }
  /* Helper function */
function download_file(fileURL, fileName) {
  // for non-IE
  if (!window.ActiveXObject) {
      var save = document.createElement('a');
      save.href = fileURL;
      save.target = '_blank';
      var filename = fileURL.substring(fileURL.lastIndexOf('/')+1);
      save.download = fileName || filename;
       if ( navigator.userAgent.toLowerCase().match(/(ipad|iphone|safari)/) && navigator.userAgent.search("Chrome") < 0) {
      document.location = save.href; 
// window event not working here
    }else{
          var evt = new MouseEvent('click', {
              'view': window,
              'bubbles': true,
              'cancelable': false
          });
          save.dispatchEvent(evt);
          (window.URL || window.webkitURL).revokeObjectURL(save.href);
    }	
  }

  // for IE < 11
  else if ( !! window.ActiveXObject && document.execCommand)     {
      var _window = window.open(fileURL, '_blank');
      _window.document.close();
      _window.document.execCommand('SaveAs', true, fileName || fileURL)
      _window.close();
  }
}

  const handleCopyLink = async () => {
    if(corporateState?.sustainability_report)
    {
      copy(`${corporateState?.sustainability_report}`)
      showSuccess("Link Copied")
    }
    else
    {
      console.log("hye 2");
      showError("Sustainability report is not uploaded!")
    }
  }

  return (
    <>

      {toast.isOpen && <Toast message={toast.message} isError={toast.isError} isSuccess={toast.isSuccess} closeToast={closeToast} />}

      {
        Object.keys(goals).length > 0 ? <>

          <div className='main-wrapper'>
            <div className="container ">
              <section className="heading dashboard-heading">
                <div>
                  <h2 className="heading-text .text-gradient text-center">Dashboard</h2>
                </div>
                <div style={{textAlign:"right"}}>
                  <LinkIcon className="report_actions" titleAccess="Copy Link" style={{cursor:"pointer"}} onClick={handleCopyLink} />
                  {
                    corporateState?.sustainability_report ? 
                    <a download="Sustainability Report" href={corporateState?.sustainability_report} target="_blank" style={{color:"black"}}><GetAppIcon className="report_actions" titleAccess="Export As PDF" style={{cursor:"pointer"}} /></a>
                    :
                    <GetAppIcon className="report_actions" titleAccess="Export As PDF" style={{cursor:"pointer"}} onClick={e => showError("Sustainability Report not uploaded!")} />
                  }
                </div>
              </section>
              <div className="esg-graph">
                {/* <div className="bg-navy">
                  <h5 className="cl-white fs-24 m-0">ESG Impacts</h5>
                </div> */}
                <div className='container main-wrapper'>
                  <div className='tabs tabs-dashboard'>
                    <a href={undefined} className={`tab-head-options tab-dashboard ${activeTab === 1 ? 'active' : ''} `} onClick={(e) => setActiveTab(1)}>Overview</a>
                    <a href={undefined} className={`tab-head-options tab-dashboard ${activeTab === 2 ? 'active' : ''} `} onClick={(e) => setActiveTab(2)}>My ESG Impact</a>
                    <a href={undefined} className={`tab-head-options tab-dashboard ${activeTab === 3 ? 'active' : ''} `} onClick={(e) => setActiveTab(3)}>My SDG Performance</a>
                  </div>
                </div>
              </div>

              {
                activeTab === 1 && 
                <DasboardOverview objectives={objectives} report={report} goals={goals} setGoals={setGoals} companyDocuments={companyDocuments} canSave={canSave} loadData={loadData} />
              }
              {
                activeTab === 2 && 
                <DashboardEsgImpact objectives={objectives} report={report} goals={goals} setGoals={setGoals} companyDocuments={companyDocuments} canSave={canSave} loadData={loadData} />
              }
              {
                activeTab === 3 && 
                <DashboardPerformance objectives={objectives} report={report} goals={goals} setGoals={setGoals} companyDocuments={companyDocuments} canSave={canSave} loadData={loadData} />
              }
            </div>
          </div>

        </> : <>
          <div className='blank-page'>
            <img src="/assets/images/dashboard-blank.png" />
            <h2>No Data Yet</h2>
            <p>Please click the button below to configure objectives</p>
            <button onClick={(e) => history.push("/sustainability/unsdg/goals")} className='navy-btn'>Configure Objectives</button>
          </div>
        </>
      }
    </>
  );
};

export default SustainabilityDashboard;