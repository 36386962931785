import { SELECTED_CONTACT, ALL_CONTACTS } from '../redux.constants';

const initialState = {
  selected: null,
  contacts: {}
};

const ContactsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SELECTED_CONTACT:
      return { ...state, selected: action.payload };
    case ALL_CONTACTS: 
      return { ...state, contacts: action.payload };
    default:
      return state;
  }
}

export default ContactsReducer;