import {
  Button,
  MessageTitle,
  OverlayContext,
  showDocumentTransferMessage,
  LoaderSpinner,
} from "@govtechsg/tradetrust-ui-components";
import React, { useContext, useEffect, useState } from "react";
import { FormState } from "../../FormState";
import { isEthereumAddress } from "../../utils";
import { AssetInformationPanel } from "../../AssetInformationPanel";
import { AssetManagementTitle } from "../../AssetManagementTitle";
import { EditableAssetTitle } from "./../EditableAssetTitle";
// import ToggleButton from '@material-ui/material/ToggleButton';
// import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
// import InputLabel from '@mui/material/InputLabel';
// import MenuItem from '@mui/material/MenuItem';
// import  from '@material-ui/core/FormControl';
import { MenuItem , Select , FormControl, InputLabel, FormLabel, FormControlLabel, Radio, RadioGroup} from '@material-ui/core';
export const TransferHolderForm = ({
  formAction,
  tokenRegistryAddress,
  beneficiary,
  holder,
  handleTransfer,
  holderTransferringState,
  setFormActionNone,
  setShowEndorsementChain,
  corporateList,
  holderName,
  beneficiaryName,
  loadingEndrosmentChain
}) => {
  const [newHolder, setNewHolder] = useState("");
  const [isTruDocUser, setIsTruDocUser] = useState("true");
  const [corporateId, setCorporateId] = useState("");
  const isPendingConfirmation = holderTransferringState === FormState.PENDING_CONFIRMATION;
  const isConfirmed = holderTransferringState === FormState.CONFIRMED;
  const isEditable =
    holderTransferringState !== FormState.PENDING_CONFIRMATION && holderTransferringState !== FormState.CONFIRMED;
  const { showOverlay } = useContext(OverlayContext);
  useEffect(() => {
    if (isConfirmed) {
      showOverlay(
        showDocumentTransferMessage(MessageTitle.TRANSFER_HOLDER_SUCCESS, { isSuccess: true, holderAddress: newHolder })
      );
      setFormActionNone();
    }
  }, [isConfirmed, newHolder, showOverlay, setFormActionNone]);
  const isValidTransfer = () => {
    if (!newHolder && !corporateId) return false;
    if (newHolder && newHolder === holder) return false;
    if (newHolder && !isEthereumAddress(newHolder)) return false;
    return true;
  };
  const handleTransferTypeChange = (event) => {
    setIsTruDocUser(event.target.value);
    setNewHolder("");
    setCorporateId("");
  };
  const handleChange = (event) => {
    setCorporateId(event.target.value);
  };
  return (
    <>
      <AssetManagementTitle
        setFormActionNone={setFormActionNone}
        formAction={formAction}
        disabled={isPendingConfirmation}
      />
      <div className="flex flex-wrap justify-between mb-4 -mx-4">
        <div className="w-full px-4 lg:w-1/3">
          <AssetInformationPanel
            setShowEndorsementChain={setShowEndorsementChain}
            tokenRegistryAddress={tokenRegistryAddress}
            loadingEndrosmentChain={loadingEndrosmentChain}
          />
        </div>
        <div className="w-full px-4 lg:w-1/3 feildLabel holder-wrp" >
          <EditableAssetTitle role="Owner" value={beneficiary} isEditable={false} corporateName={beneficiaryName}/>
        </div>
        
        <FormControl component="fieldset" className="border-top-line"
        
        >
          <div className="borderTop"></div>
          <FormLabel component="legend">Transfer To</FormLabel>
          <RadioGroup
            aria-label="isTruDocUser"
            defaultValue={isTruDocUser}
            name="radio-buttons-group"
            onChange={handleTransferTypeChange}
          >
            <FormControlLabel value={"true"} control={<Radio />} label="TruDoc User" />
            <FormControlLabel value={"false"} control={<Radio />} label=" Public address" />
          </RadioGroup>
        </FormControl>

        {
          isTruDocUser === "true"
          ? <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">User</InputLabel>
              <Select
                labelId="corporateId"
                id="corporateId"
                value={corporateId}
                label="User"
                onChange={handleChange}
              >
                <MenuItem value="">Select</MenuItem>
                {
                  corporateList && corporateList.map((corporate) => {
                    return <MenuItem value={corporate.id}>{corporate.name}</MenuItem>
                  })
                }
                
              </Select>
          </FormControl>
          : <div className="w-full px-4 lg:w-1/3 input-owner-wrp">
            <EditableAssetTitle
              role="Holder"
              value={holder}
              newValue={newHolder}
              isEditable={isEditable}
              onSetNewValue={setNewHolder}
              error={holderTransferringState === FormState.ERROR}
            />
          </div>
        }

        
      </div>
      <div className="flex flex-wrap pb-4">
        <div className="w-auto lg:ml-auto" 
        style={{
          paddingTop:"15px",
          display:"flex",

        }}>
          <div className="flex flex-wrap" 
          style={{
            display:"flex"
          }}>
            <div className="w-auto">
              <Button
                className="bg-white rounded-xl text-lg py-2 px-3 border-cloud-100 text-cloud-900 shadow-none hover:bg-cloud-200"
                onClick={setFormActionNone}
                disabled={isPendingConfirmation}
                data-testid={"cancelTransferBtn"}
                style={{
                  background:"#66CFA4;",
                  borderRadius:"20px",
                  color:"#000",
                  textTransform:"capitalize",
                  border:"unset",
                  padding:"7.5px 15px",
                  marginRight:"15px"
                }}
              >
                Cancel
              </Button>
            </div>
            <div className="w-auto ml-2">
              <Button
                className="bg-cerulean rounded-xl text-lg text-white py-2 px-3 shadow-none hover:bg-cerulean-300"
                disabled={!isValidTransfer() || isPendingConfirmation}
                onClick={() => handleTransfer(newHolder, corporateId)}
                data-testid={"transferBtn"}
                style={{
                  background:"#66CFA4;",
                  borderRadius:"20px",
                  color:"#000",
                  textTransform:"capitalize",
                  border:"unset",
                  padding:"7.5px 15px"
                }}
              >
                {isPendingConfirmation ? <LoaderSpinner data-testid={"loader"} /> : <>Transfer</>}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};