import { MessageTitle, OverlayContext, showDocumentTransferMessage } from "@govtechsg/tradetrust-ui-components";
import React, { useContext } from "react";
import { Box, Button, Container, Grid } from '@material-ui/core';

import { TagBorderedLg } from "../../Tag";
import { AssetInformationPanel } from "../../AssetInformationPanel";
import { AssetManagementDropdown } from "../../AssetManagementDropdown";
import { EditableAssetTitle } from "./../EditableAssetTitle";

import { makeStyles } from '@material-ui/core';
const useStyles = makeStyles((theme) => { 
  return {
    demo: {
     
    },
    feildLabel: {
      color: "#000",
      fontSize:"18px",
      margin:"10px 0px",
      fontFamily: "Poppins"
    }, 
   
   
  }
});


export const ActionSelectionForm = ({
  onSetFormAction,
  tokenRegistryAddress,
  beneficiary,
  holder,
  account,
  canSurrender,
  canHandleSurrender,
  onConnectToWallet,
  canChangeHolder,
  canEndorseBeneficiary,
  isSurrendered,
  isTokenBurnt,
  canNominateBeneficiaryHolder,
  canEndorseTransfer,
  setShowEndorsementChain,
  isTitleEscrow,
  holderName,
  beneficiaryName,
  loadingEndrosmentChain,
}) => {
  const classes = useStyles();
  const canManage =
    canHandleSurrender ||
    canSurrender ||
    canChangeHolder ||
    canEndorseBeneficiary ||
    canNominateBeneficiaryHolder ||
    canEndorseTransfer;

  const { showOverlay } = useContext(OverlayContext);
  const handleNoAccess = () => {
    showOverlay(showDocumentTransferMessage(MessageTitle.NO_MANAGE_ACCESS, { isSuccess: false }));
  };

  const handleMetamaskError = (errorMesssage, errorCode) => {
    const isUserDeniedAccountAuthorization = errorCode === 4001;

    showOverlay(
      showDocumentTransferMessage(errorMesssage, {
        isSuccess: false,
        isButtonMetamaskInstall: !isUserDeniedAccountAuthorization,
      })
    ); // there is 2 type of errors that will be handled here, 1st = NO_METAMASK (error thrown from provider.tsx), 2nd = NO_USER_AUTHORIZATION (error from metamask extension itself).
  };

  const handleConnectWallet = async () => {
    try {
      await onConnectToWallet();
    } catch (error) {
      handleMetamaskError(error.message, error.code);
    }
  };

  console.log(beneficiary, holder);

  return (
    <>
      <Container maxWidth={false}>
        <Grid container spacing={2}    
        > 
          <Grid item xs={4}>
            <Box>
              <AssetInformationPanel
                tokenRegistryAddress={tokenRegistryAddress}
                setShowEndorsementChain={setShowEndorsementChain}
                loadingEndrosmentChain={loadingEndrosmentChain}
              />
            </Box>
          </Grid>
          <Grid item xs={8}>
            {isSurrendered && (
              <div className="w-full px-4 lg:w-auto self-end">
                <div className="py-4">
                  <TagBorderedLg id="surrender-sign" className="bg-white rounded-xl text-rose border-rose">
                    <h3 className="text-4xl">Surrendered To Issuer</h3>
                  </TagBorderedLg>
                </div>
              </div>
            )}
            {isTokenBurnt && (
              <div className="w-full px-4 lg:w-auto self-end">
                <div className="py-4">
                  <TagBorderedLg id="surrendered-sign" className="bg-white rounded-xl text-rose border-rose">
                    <h3 className="text-4xl">Surrendered</h3>
                  </TagBorderedLg>
                </div>
              </div>
            )}
            {!isSurrendered && !isTokenBurnt && isTitleEscrow && (
              <>
                <div className={`${classes.feildLabel} `}>
                  <EditableAssetTitle role="Owner" value={beneficiary} isEditable={false} corporateName={beneficiaryName}/>
                </div>
                <div className={`${classes.feildLabel} `}>
                  <EditableAssetTitle role="Holder" value={holder} isEditable={false} corporateName={holderName}/>
                </div>
              </>
            )}
          </Grid>
        </Grid>
      </Container>
      {!isTokenBurnt && (
        <div className="flex flex-wrap pb-4 doc-viewer">
          <div className="w-auto lg:ml-auto">
            {account ? (
              <>
                {canManage ? (
                  <AssetManagementDropdown
                    onSetFormAction={onSetFormAction}
                    canSurrender={canSurrender}
                    canChangeHolder={canChangeHolder}
                    canEndorseBeneficiary={canEndorseBeneficiary}
                    canNominateBeneficiaryHolder={canNominateBeneficiaryHolder}
                    canEndorseTransfer={canEndorseTransfer}
                    canHandleSurrender={canHandleSurrender}
                  />
                ) : (
                  <Button
                    className="bg-cerulean text-white rounded-xl text-lg py-2 px-3 hover:bg-cerulean-300"
                    onClick={handleNoAccess}
                  >
                    No Access
                  </Button>
                )}
              </>
            ) : (
              <Button
                className="bg-cerulean text-white rounded-xl text-lg py-2 px-3 hover:bg-cerulean-300"
                data-testid={"connectToWallet"}
                onClick={handleConnectWallet}
                color="primary"
                size="medium"
                variant="contained"
              >
                Connect Wallet
              </Button>
            )}
          </div>
        </div>
      )}
    </>
  );
};
