import React from "react";
import { makeEtherscanAddressURL } from "./utils";


export const ExternalLink= ({ name, href, corporateName, ...props}) => {
  return (
    <a href={href} target="_blank" rel="noopener noreferrer" {...props}>
      <h5>{name} {corporateName? <span> {"(" + corporateName + ")"}</span>: null }</h5>
    </a>
  );
};

export const ExternalLinkEtherscanAddress = ({
  name,
  address,
  ...props
}) => {
  const href = makeEtherscanAddressURL(address);

  return <ExternalLink name={name} href={href} {...props} />;
};
