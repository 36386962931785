import {
  Box,
  Grid,
  makeStyles,
  Paper,
  SvgIcon,
  Typography,
} from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  customBorderRadius: {
    borderRadius: 25,
    margin: theme.spacing(3),
  },
  icon: {
    height: "23.333328247070312px",
    width: "23.333332061767578px",
    left: "5.833251953125px",
    top: "5.8330078125px",
    borderRadius: "0px",
  },
  count1: {
    width: "32px",
    height: "75px",
    fontWeight: 500,
    fontSize: "50px",
    letterSpacing: "0.12em",
    marginLeft: theme.spacing(1),
    color: "#C0A5ED",
  },
  count2: {
    width: "32px",
    height: "75px",
    fontWeight: 500,
    fontSize: "50px",
    letterSpacing: "0.12em",
    marginLeft: theme.spacing(1),
    color: "#FF92B9",
  },
  count3: {
    width: "32px",
    height: "75px",
    fontWeight: 500,
    fontSize: "50px",
    letterSpacing: "0.12em",
    marginLeft: theme.spacing(1),
    color: "#74BCFF",
  },
  description: {
    textAlign: "left",
    width: "72px",
    height: "38px",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "135.3%",
    letterSpacing: "0.05em",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(2),
    color: "#6F6D74",
  },
}));

function InfoPaper({ title, value, dataLink }) {

  const classes = useStyles();
  const history = useHistory();

  const openLink = (linkPath) => {
    history.replace("dashboard/" + dataLink + "/" + linkPath);
  }

  return (
    <Grid item xs={4}>
      <Paper className={classes.customBorderRadius} elevation={3}>
        <Box p={1}>
          {title === "Awaiting others" && (
            <Grid
              onClick={(e) => openLink("awaiting_others_sign")}
              className="stats-box-link"
              container
              direction="row"
              alignItems="center"
              justify="center"
            >
              <Grid item xs={2}>
                <SvgIcon
                  className={classes.icon}
                  width="25"
                  height="25"
                  viewBox="0 0 25 25"
                >
                  <path
                    d="M12.5 3.60384C14.1917 3.60384 15.5625 4.97467 15.5625 6.66634C15.5625 8.35801 14.1917 9.72884 12.5 9.72884C10.8083 9.72884 9.43751 8.35801 9.43751 6.66634C9.43751 4.97467 10.8083 3.60384 12.5 3.60384ZM12.5 16.7288C16.8313 16.7288 21.3958 18.858 21.3958 19.7913V21.3955H3.60418V19.7913C3.60418 18.858 8.16876 16.7288 12.5 16.7288ZM12.5 0.833008C9.27709 0.833008 6.66668 3.44342 6.66668 6.66634C6.66668 9.88926 9.27709 12.4997 12.5 12.4997C15.7229 12.4997 18.3333 9.88926 18.3333 6.66634C18.3333 3.44342 15.7229 0.833008 12.5 0.833008ZM12.5 13.958C8.60626 13.958 0.833344 15.9122 0.833344 19.7913V24.1663H24.1667V19.7913C24.1667 15.9122 16.3938 13.958 12.5 13.958Z"
                    fill="#C0A5ED"
                  />
                </SvgIcon>
              </Grid>
              <Grid item xs={3}>
                <Typography className={classes.count1}>{value}</Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography className={classes.description}>{title}</Typography>
              </Grid>
            </Grid>
          )}
          {title === "Awaiting my sign" && (
            <Grid
              onClick={(e) => openLink("awaiting_my_sign")}
              className="stats-box-link"
              container
              direction="row"
              alignItems="center"
              justify="center"
            >
              <Grid item xs={2}>
                <SvgIcon
                  className={classes.icon}
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                >
                  <path
                    d="M14.9875 2.5C8.0875 2.5 2.5 8.1 2.5 15C2.5 21.9 8.0875 27.5 14.9875 27.5C21.9 27.5 27.5 21.9 27.5 15C27.5 8.1 21.9 2.5 14.9875 2.5ZM15 25C9.475 25 5 20.525 5 15C5 9.475 9.475 5 15 5C20.525 5 25 9.475 25 15C25 20.525 20.525 25 15 25Z"
                    fill="#FF92B9"
                  />
                  <path
                    d="M15.625 8.75H13.75V16.25L20.3125 20.1875L21.25 18.65L15.625 15.3125V8.75Z"
                    fill="#FF92B9"
                  />
                </SvgIcon>
              </Grid>
              <Grid item xs={3}>
                <Typography className={classes.count2}>{value}</Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography className={classes.description}>{title}</Typography>
              </Grid>
            </Grid>
          )}
          {title === "Signing complete" && (
            <Grid
              onClick={(e) => openLink("completed")}
              className="stats-box-link"
              container
              direction="row"
              alignItems="center"
              justify="center"
            >
              <Grid item xs={2}>
                <SvgIcon
                  className={classes.icon}
                  width="35"
                  height="35"
                  viewBox="0 0 35 35"
                >
                  <path
                    d="M12.1188 6.74268H5.99902C5.43266 6.74268 4.97363 7.2017 4.97363 7.76807C4.97363 8.33443 5.43266 8.79346 5.99902 8.79346H12.1188C12.6849 8.79346 13.1442 8.33443 13.1442 7.76807C13.1442 7.2017 12.6849 6.74268 12.1188 6.74268Z"
                    fill="#74BCFF"
                  />
                  <path
                    d="M12.1188 10.8447H5.99902C5.43266 10.8447 4.97363 11.3037 4.97363 11.8701C4.97363 12.4365 5.43266 12.8955 5.99902 12.8955H12.1188C12.6849 12.8955 13.1442 12.4365 13.1442 11.8701C13.1442 11.3037 12.6849 10.8447 12.1188 10.8447Z"
                    fill="#74BCFF"
                  />
                  <path
                    d="M12.1188 14.9463H5.99902C5.43266 14.9463 4.97363 15.4053 4.97363 15.9717C4.97363 16.538 5.43266 16.9971 5.99902 16.9971H12.1188C12.6849 16.9971 13.1442 16.538 13.1442 15.9717C13.1442 15.4053 12.6849 14.9463 12.1188 14.9463Z"
                    fill="#74BCFF"
                  />
                  <path
                    d="M12.1188 19.0483H5.99902C5.43266 19.0483 4.97363 19.5074 4.97363 20.0737C4.97363 20.6401 5.43266 21.0991 5.99902 21.0991H12.1188C12.6849 21.0991 13.1442 20.6401 13.1442 20.0737C13.1442 19.5074 12.6849 19.0483 12.1188 19.0483Z"
                    fill="#74BCFF"
                  />
                  <path
                    d="M12.1188 23.2188H5.99902C5.43266 23.2188 4.97363 23.678 4.97363 24.2441C4.97363 24.8105 5.43266 25.2695 5.99902 25.2695H12.1188C12.6849 25.2695 13.1442 24.8105 13.1442 24.2441C13.1442 23.678 12.6849 23.2188 12.1188 23.2188Z"
                    fill="#74BCFF"
                  />
                  <path
                    d="M15.5625 8.79346H21.6823C22.2486 8.79346 22.7077 8.33443 22.7077 7.76807C22.7077 7.2017 22.2486 6.74268 21.6823 6.74268H15.5625C14.9964 6.74268 14.5371 7.2017 14.5371 7.76807C14.5371 8.33443 14.9964 8.79346 15.5625 8.79346Z"
                    fill="#74BCFF"
                  />
                  <path
                    d="M15.5625 12.8955H21.6823C22.2486 12.8955 22.7077 12.4365 22.7077 11.8701C22.7077 11.3037 22.2486 10.8447 21.6823 10.8447H15.5625C14.9964 10.8447 14.5371 11.3037 14.5371 11.8701C14.5371 12.4365 14.9964 12.8955 15.5625 12.8955Z"
                    fill="#74BCFF"
                  />
                  <path
                    d="M19.5354 15.9717C19.5354 15.4053 19.0763 14.9463 18.51 14.9463H15.5625C14.9964 14.9463 14.5371 15.4053 14.5371 15.9717C14.5371 16.538 14.9964 16.9971 15.5625 16.9971H18.51C19.0763 16.9971 19.5354 16.538 19.5354 15.9717Z"
                    fill="#74BCFF"
                  />
                  <path
                    d="M29.2492 23.6514C28.8076 23.297 28.1624 23.3678 27.8078 23.8095L25.1669 27.1019L23.5319 25.9174C23.0734 25.5849 22.432 25.6875 22.0998 26.146C21.7676 26.6047 21.8702 27.2459 22.3286 27.5781L24.7541 29.3354C24.9356 29.4667 25.1461 29.5306 25.3551 29.5306C25.6561 29.5306 25.9536 29.3984 26.1554 29.1466L29.4076 25.0928C29.7619 24.6511 29.6911 24.0057 29.2492 23.6514Z"
                    fill="#74BCFF"
                  />
                  <path
                    d="M31.8408 20.5038C30.5051 19.1446 28.7889 18.288 26.9293 18.0357V4.1296C26.9293 1.85265 25.0767 0 22.7997 0H4.88182C2.6046 0 0.751953 1.85265 0.751953 4.1296V27.8826C0.751953 30.1598 2.6046 32.0125 4.88155 32.0125H19.2928C20.9145 33.9175 23.2462 35 25.7688 35C30.4645 35 34.2846 31.1772 34.2846 26.4783C34.2846 24.2289 33.4168 22.107 31.8408 20.5038ZM2.80273 27.8826V4.1296C2.80273 2.98351 3.73547 2.05078 4.88182 2.05078H22.7997C23.9461 2.05078 24.8785 2.98351 24.8785 4.1296V18.0031C20.5997 18.4498 17.253 22.0801 17.253 26.4783C17.253 27.6973 17.5072 28.876 17.9948 29.9614H4.88182C3.73547 29.9614 2.80273 29.029 2.80273 27.8826ZM25.7688 32.9492C23.721 32.9492 21.8384 32.009 20.6034 30.37C19.7532 29.2413 19.3035 27.8957 19.3035 26.4783C19.3035 22.9103 22.204 20.0074 25.7688 20.0074C25.8025 20.0074 25.8364 20.0079 25.87 20.0085L25.8858 20.0087C27.5875 20.0389 29.183 20.7252 30.3783 21.9412C31.5748 23.1586 32.2338 24.7701 32.2338 26.4783C32.2338 30.0463 29.3336 32.9492 25.7688 32.9492Z"
                    fill="#74BCFF"
                  />
                </SvgIcon>
              </Grid>
              <Grid item xs={3}>
                <Typography className={classes.count3}>{value}</Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography className={classes.description}>{title}</Typography>
              </Grid>
            </Grid>
          )}
        </Box>
      </Paper>
    </Grid>
  );
}

export default InfoPaper;
