import { combineReducers } from 'redux';
import authReducer from './Auth.reducer';
import toggleModal from './Modal.reducer';
import toggleLoading from './Loading.reducer';
import buttonLoading from './ButtonLoading.reducer';
import ContactsReducer from './Contacts.reducer';
import CustomersReducer from './Customers.reducer';
import SidePanelReducer from './Sidepanel.reducer';
import WalletReducer from './Wallet.reducer';
import DomainReducer from './Domain.reducer';
import SignUpReducer from './SignUp.reducer';
import fetchSignature from './Sign.reducer';
import viewerReducer from './Viewer.reducer';

const rootReducer = combineReducers({
  modal: toggleModal,
  user: authReducer,  
  loading: toggleLoading,
  contact: ContactsReducer,
  customer: CustomersReducer,
  sidePanel: SidePanelReducer,
  domain: DomainReducer,
  wallet: WalletReducer,
  signup: SignUpReducer,
  buttonLoading: buttonLoading,
  fetchSignature: fetchSignature,
  selectedDoc: viewerReducer,
});

export default rootReducer;