import { Box, TextField, InputAdornment } from "@material-ui/core";
import Pagination from '@material-ui/lab/Pagination';
import { makeStyles } from "@material-ui/styles"
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    width: '35ch',
  },
}));

const FilterBar = () => {
  const classes = useStyles();
  return (
    <Box display="flex" justifyContent="space-between" alignItems="center" mb="30px">
      {/* <Pagination count={10} color="primary" /> */}
      <TextField 
        size="large"
        className={classes.textField}
        label="Search " 
        variant="outlined" 
        color="primary"
        InputProps={{
          endAdornment: <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>,
        }}
      />
    </Box>
  )
}

export default FilterBar;