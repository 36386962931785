import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Typography, makeStyles, Snackbar } from '@material-ui/core';
import DomainList from '../../components/admin/domains/DomainList';
import SelectedDomain from '../../components/admin/domains/SelectedDomain';
import AddDomain from '../../components/admin/domains/AddDomain';
import { getAllDomains } from '../../redux/actions/Domain.action';
import AdminLayout from './Admin.layout';
import { useSocketContext } from '../../services/socket';
import Toast from '../../utils/Toast';
import { LinearProgress } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";

const useStyles = makeStyles((theme) => {
  return {
    heading: {
      fontWeight: '500'
    },
    domainBox: {
      marginTop: 30
    },
    notify: {
      position: 'fixed',
      bottom: 0,
      margin: '0 auto',
      width: '100%',
      height: '75px',
      background: 'red',
    },
    progress: {
      backgroundColor: '#fff'
    }
  }
});

const Domains = () => {

  const toastInitialValues = {
    isOpen: false,
    isSuccess: false,
    isError: false,
    message: ""
  }
  const classes = useStyles();
  const [openAddDomain, setOpenAddDomain] = useState(false);
  const [selectedDomain, setSelectedDomain] = useState(null);
  const dispatch = useDispatch();
  const { domain } = useSelector(state => state);
  const socket = useSocketContext();
  const [toast, setToast] = useState(toastInitialValues);
  const [progress, setProgress] = useState(false);

  const closeToast = () => {
    return setToast((prev) => ({
      ...prev,
      isOpen: false,
      isSuccess: false,
      isError: false,
      message: ""
    }));
  };

  const socketProgressHandler = (data) => {
    setProgress(data);
  }

  const socketSuccessHandler = (data) => {
    setToast((prev) => ({
      ...prev,
      isOpen: true,
      isSuccess: true,
      message: data
    }));
    dispatch(getAllDomains());
  }

  const socketFailedHandler = (data) => {
    setToast((prev) => ({
      ...prev,
      isOpen: true,
      isError: true,
      message: data
    }));
  }

  useEffect(() => {
    dispatch(getAllDomains());
  }, [])

  useEffect(() => {
    socket.on("domain_response_in_process", socketProgressHandler);
    socket.on("domain_response_success", socketSuccessHandler);
    socket.on("domain_response_failed", socketFailedHandler);
  }, [socket]);

  const Alert = (props) => {
    return <MuiAlert elevation={6} variant="standard" {...props} />;
  };  

  const refreshDomainList = () => {
    setSelectedDomain(null);
    dispatch(getAllDomains());
  }

  const onCloseAddDomain = () => {
    setOpenAddDomain(false);
    refreshDomainList();
  }

  return (
    <AdminLayout>
      {toast.isOpen && <Toast message={toast.message} isError={toast.isError} isSuccess={toast.isSuccess} closeToast={closeToast} />}
      <Typography variant="h5" gutterBottom className={classes.heading}>DOMAINS</Typography>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        spacing={5}
        className={classes.domainBox}
      >
        <Grid item xs={selectedDomain != null ? 7 : 12} align="center">
          <DomainList
            openAddDomainModal={() => setOpenAddDomain(true)}
            setSelectedDomain={setSelectedDomain}
            domains={domain && domain.domains}
          />
        </Grid>
        {
          selectedDomain != null ?
            <>
              <Grid item xs={5} align="center">
                <SelectedDomain onRefresh={refreshDomainList} domain={selectedDomain} />
              </Grid>
            </> : null
        }
      </Grid>
      <AddDomain
        handleClose={() => onCloseAddDomain()}
        open={openAddDomain}
      />
      {progress && <Snackbar
        open={progress}
        autoHideDuration={null}
        onClose={() => { }}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={() => setProgress(false)} severity="info">
          Domain verification in progress
          <LinearProgress className={classes.progress} />
        </Alert>
      </Snackbar>}
    </AdminLayout>
  )
}

export default Domains
