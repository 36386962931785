import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  LinearProgress,
  Paper,
  Slider,
  Snackbar,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography
} from "@material-ui/core";
import { makeStyles, styled } from "@material-ui/core/styles";
import Toast from "../../utils/Toast";
import GoalBox from "./goal-box";
import UnsdgGoals from "./unsdg-goals";
import UnsdgRelevance from "./unsdg-relevance";
import { useHistory, useParams } from "react-router-dom";
import { sustainabilityLogs } from "../../services/admin.service";
import { printTime } from "../../utils/Generic";
import { UNSDG_GOALS } from "../../utils/unsdg-goals";

const useStyles = makeStyles((theme) => ({
  root: {
    width: `calc(100% - ${300}px)`,
    marginLeft: 280,
    minHeight: "88vh",
  },
}));

const KnwoSdgs = (props) => {

  const classes = useStyles();
  let history = useHistory();

  const { goals, setGoals, companyDocuments, canSave } = props;

  const toastInitialValues = {
    isOpen: false,
    isSuccess: false,
    isError: false,
    message: ""
  }

  const [toast, setToast] = useState(toastInitialValues);

  const closeToast = () => {
    return setToast((prev) => ({
      ...prev,
      isOpen: false,
      isSuccess: false,
      isError: false,
      message: ""
    }));
  };

  const showError = (errorMsg) => {
    setToast((prev) => ({
      ...prev,
      isOpen: true,
      isError: true,
      isSuccess: false,
      message: errorMsg
    }));
  }

  const showSuccess = (succMsg) => {
    setToast((prev) => ({
      ...prev,
      isOpen: true,
      isError: false,
      isSuccess: true,
      message: succMsg
    }));
  }

  let { activeTab } = useParams();

  const handleGoNext = (fromWhichTab) => {
    history.push("/sustainability/unsdg/goals");
  }

  const handleGoBack = (fromWhichTab) => {
    let lastIndex = (companyDocuments.length - 1);
    history.push("/sustainability/tab/" + lastIndex);
  }

  const handleHashClick = (hashId) => {
    let element = document.getElementById(hashId);
    if (element != null) {
      var scrollTopData = element.offsetTop;
      scrollTopData = scrollTopData - 75;
      window.scrollTo({ top: scrollTopData, behavior: 'smooth' });
    }
  }

  return (
    <>

      {toast.isOpen && <Toast message={toast.message} isError={toast.isError} isSuccess={toast.isSuccess} closeToast={closeToast} />}

      <div className='about_wrapper unsdg-template'>

        {/* <h1>Know your SDG's</h1> */}

        <div className="main_sdgs_div">
          <div className="header_sdgs">
            <div className="after_line">
              <h1>The 17 Goals</h1>
            </div>
            <div>
              <h2>169</h2>
              <p>Targets</p>
            </div>
            <div>
              <h2>3818</h2>
              <p>events</p>
            </div>
            <div>
              <h2>1344</h2>
              <p>Publications</p>
            </div>
            <div>
              <h2>7548</h2>
              <p>Actions</p>
            </div>
          </div>
          <main>
            <div className="goals_wrapper" id="card_input">

              {
                Object.keys(UNSDG_GOALS).map((goal) => {
                  let unsdgGoal = UNSDG_GOALS[goal];
                  return <>
                    <div className="main_card_div">
                      <div className="goal_card" style={{ "backgroundImage": 'url("' + unsdgGoal.image + '")' }}>
                        <div className="goal_image">
                          <img src={`/assets/images/sdg-icons/E-WEB-Goal-${goal}.png`} />
                        </div>
                      </div>
                      <div className="hover_card" style={{ "background": unsdgGoal.color }}>
                        <div className="upper_div">
                          <h1 className="goalnumber">{goal}</h1>
                          <h2 className="goaltitle">Goal {goal}</h2>
                          <p className="goaltext">{unsdgGoal.info}</p>
                        </div>
                        <div className="lower_div">
                          <div className="dflex_card_lower">
                            <div className="number_div">
                              <h2>{unsdgGoal?.items?.targets}</h2>
                              <p>Targets</p>
                            </div>
                            <div className="number_div">
                              <h2>{unsdgGoal?.items?.events}</h2>
                              <p>Events</p>
                            </div>
                          </div>
                          <div className="dflex_card_lower">
                            <div className="number_div">
                              <h2>{unsdgGoal?.items?.publications}</h2>
                              <p>Publications</p>
                            </div>
                            <div className="number_div">
                              <h2>{unsdgGoal?.items?.actions}</h2>
                              <p>Actions</p>
                            </div>
                          </div>
                          <div>
                            <a href={`https://sdgs.un.org/goals/goal${goal}`} target="_blank">More Info</a>
                          </div>

                        </div>

                      </div>
                    </div>
                  </>;
                })
              }

              <div className="main_card_div goal_card_last">
                <div className="goal_card">
                  <img src="https://sdgs.un.org/themes/custom/porto/assets/goals/global-goals.png" alt="" />
                  <a href="https://sdgs.un.org/goals" target="_blank">See All</a>
                </div>
              </div>

            </div>

          </main>
          <section className="maxw_lesser history_section_sdgs" id="about">
            <h2>Do you know all 17 SDGs?</h2>
            <div className="video_section_sdgs">
              <div className="video_div_sdgs">
                <iframe width="735" height="413" src="https://www.youtube.com/embed/0XTBYMfZyrM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

              </div>
              <div className="accordion_section_sdgs">
                <div>
                  <div className="bg_black_accordion_sdgs">
                    <a href={undefined} onClick={(e) => handleHashClick("about")}>Do you know all 17 SDGs?
                    </a>
                  </div>
                  <div className="bg_black_accordion_sdgs">
                    <a href={undefined} onClick={(e) => handleHashClick("history")}>History
                    </a>
                  </div>

                </div>

              </div>
            </div>
          </section>
          <section id="history" className="maxw_lesser history_section_sdgs ">
            <div className="">

              <h2 className="mt-4">History</h2>

              <p><a href="https://sdgs.un.org/2030agenda" target="_blank">The 2030 Agenda for Sustainable Development,</a>&nbsp;adopted by all United Nations Member States in 2015, provides a shared blueprint for peace and prosperity for people and the planet, now and into the future. At its heart are the 17 Sustainable Development Goals (SDGs), which are an urgent call for action by all countries - developed and developing - in a global partnership. They recognize that ending poverty and other deprivations must go hand-in-hand with strategies that improve health and education, reduce inequality, and spur economic growth – all while tackling climate change and working to preserve our oceans and forests.</p>

              <p>The SDGs build on decades of work by countries and the UN, including the&nbsp;<a target="_blank" href="https://www.un.org/development/desa/en/">UN Department of Economic and Social Affairs</a></p>

              <ul>
                <li>In June 1992, at the&nbsp;<a href="https://www.un.org/en/conferences/environment/rio1992" target="_blank">Earth Summit</a>&nbsp;in Rio de Janeiro, Brazil, more than 178 countries adopted&nbsp;<a target="_blank" href="https://sdgs.un.org/publications/agenda21">Agenda 21</a>, a comprehensive plan of action to build a global partnership for sustainable development to improve human lives and protect the environment.</li>
                <li>Member States unanimously adopted the Millennium Declaration at the&nbsp;<a target="_blank" href="https://www.un.org/en/conferences/environment/newyork2000">Millennium Summit</a>&nbsp;in September 2000 at UN Headquarters in New York. The Summit led to the elaboration of eight&nbsp;<a target="_blank" href="http://www.un.org/millenniumgoals/">Millennium Development Goals (MDGs)</a>&nbsp;to reduce extreme poverty by 2015.</li>
                <li>The Johannesburg Declaration on Sustainable Development and the Plan of Implementation, adopted at the&nbsp;<a target="_blank" href="https://sustainabledevelopment.un.org/milesstones/wssd">World Summit on Sustainable Development</a>&nbsp;in South Africa in 2002, reaffirmed the global community's commitments to poverty eradication and the environment, and built on Agenda 21 and the Millennium Declaration by including more emphasis on multilateral partnerships.</li>
                <li>At the&nbsp;<a target="_blank" href="https://sustainabledevelopment.un.org/rio20">United Nations Conference on Sustainable Development (Rio+20)</a>&nbsp;in Rio de Janeiro, Brazil, in June 2012, Member States adopted the outcome document&nbsp;<a target="_blank" href="https://sustainabledevelopment.un.org/index.php?menu=1298">"The Future We Want"</a>&nbsp;in which they decided, inter alia, to launch a process to develop a set of SDGs to build upon the MDGs and to establish the&nbsp;<a target="_blank" href="https://sustainabledevelopment.un.org/hlpf">UN High-level Political Forum on Sustainable Development</a>. The Rio +20 outcome also contained other measures for implementing sustainable development, including mandates for future programmes of work in development financing, small island developing states and more.</li>
                <li>In 2013, the General Assembly set up a 30-member&nbsp;<a target="_blank" href="https://sustainabledevelopment.un.org/post2015/owg">Open Working Group</a>&nbsp;to develop a proposal on the SDGs.</li>
                <li>In January 2015, the General Assembly began the negotiation process on the&nbsp;<a target="_blank" href="https://sustainabledevelopment.un.org/post2015/negotiations">post-2015 development agenda</a>. The process culminated in the subsequent adoption of the&nbsp;<a target="_blank" href="https://sustainabledevelopment.un.org/post2015/transformingourworld">2030 Agenda for Sustainable Development</a>, with&nbsp;<a target="_blank" href="https://sustainabledevelopment.un.org/sdgs">17 SDGs</a>&nbsp;at its core, at the&nbsp;<a target="_blank" href="https://sustainabledevelopment.un.org/post2015/summit">UN Sustainable Development Summit</a>&nbsp;in September 2015.</li>
                <li>2015 was a landmark year for multilateralism and international policy shaping, with the adoption of several major agreements:
                  <ul>
                    <li><a target="_blank" href="https://sustainabledevelopment.un.org/frameworks/sendaiframework">Sendai Framework for Disaster Risk Reduction</a>&nbsp;(March 2015)</li>
                    <li><a target="_blank" href="https://sustainabledevelopment.un.org/frameworks/addisababaactionagenda">Addis Ababa Action Agenda on Financing for Development</a>&nbsp;(July 2015)</li>
                    <li><a target="_blank" href="https://sdgs.un.org/2030agenda">Transforming our world: the 2030 Agenda for Sustainable Development</a>&nbsp;with its 17 SDGs was adopted at the&nbsp;<a target="_blank" href="https://sustainabledevelopment.un.org/post2015/summit">UN Sustainable Development Summit</a>&nbsp;in New York in September 2015.</li>
                    <li><a target="_blank" href="https://sdgs.un.org/frameworks/parisagreement">Paris Agreement on Climate Change</a>&nbsp;(December 2015)</li>
                  </ul>
                </li>
                <li>Now, the annual&nbsp;<a target="_blank" href="https://sustainabledevelopment.un.org/hlpf">High-level Political Forum on Sustainable Development</a>&nbsp;serves as the central UN platform for the follow-up and review of the SDGs.</li>
              </ul>

              <p>Today, the&nbsp;<a target="_blank" href="https://sdgs.un.org/about">Division for Sustainable Development Goals (DSDG)</a>&nbsp;in the United Nations&nbsp;<a href="https://www.un.org/development/desa/en/">Department of Economic and Social Affairs (UNDESA)</a>&nbsp;provides substantive support and capacity-building for the SDGs and their related thematic issues, including&nbsp;<a href="https://sdgs.un.org/topics/water-and-sanitation">water</a>,&nbsp;<a href="https://sdgs.un.org/topics/energy">energy</a>,&nbsp;<a href="https://sdgs.un.org/topics/climate-change">climate</a>,&nbsp;<a href="https://sdgs.un.org/topics/oceans-and-seas">oceans</a>,&nbsp;<a href="https://sdgs.un.org/topics/sustainable-cities-and-human-settlements">urbanization</a>,&nbsp;<a href="https://sdgs.un.org/topics/sustainable-transport">transport</a>,&nbsp;<a href="https://sdgs.un.org/topics/science">science and technology</a>, the&nbsp;<a href="https://sdgs.un.org/gsdr">Global Sustainable Development Report (GSDR)</a>,&nbsp;<a href="https://sustainabledevelopment.un.org/sdinaction">partnerships</a>&nbsp;and&nbsp;<a href="https://sdgs.un.org/topics/small-island-developing-states">Small Island Developing States</a>. DSDG plays a key role in the evaluation of UN systemwide implementation of the 2030 Agenda and on advocacy and outreach activities relating to the SDGs. In order to make the 2030 Agenda a reality, broad ownership of the SDGs must translate into a strong commitment by all stakeholders to implement the global goals. DSDG aims to help facilitate this engagement.</p>

              <p>Follow DSDG on Facebook at&nbsp;<a target="_blank" href="http://www.facebook.com/sustdev">www.facebook.com/sustdev</a>&nbsp;and on Twitter at&nbsp;<a href="https://twitter.com/sustdev">@SustDev</a>.</p>

            </div>
          </section>


        </div>

        <Box className="btn-sustainability-box">
          <div className="left">
            {
              (canSave) ? <>
                <button onClick={(e) => props?.onSave()} className="btn-sustainability secondary">
                  Save
                </button>
              </> : null
            }
          </div>
          <div className="right">
            <button onClick={(e) => handleGoBack()} className="btn-sustainability primary-out me-10px">
              Back
            </button>
            <button onClick={(e) => handleGoNext()} className="btn-sustainability primary">
              Next
            </button>
          </div>
        </Box>

      </div>



    </>
  );
};

export default KnwoSdgs;