import { useState } from 'react';
import {
  Box,
  makeStyles,
  AppBar,
  Button,
  Toolbar,
  SvgIcon,
  Menu,
  MenuItem,
  withStyles,
  ListItemText,
  Switch,
  Typography,
  Grid,
  FormControlLabel
} from '@material-ui/core';
import { NavLink, useHistory } from 'react-router-dom';
import SettingsIcon from '@material-ui/icons/Settings';
import DashboardIcon from '@material-ui/icons/Dashboard';
import { useAuth } from '../../hooks';
import bg from '../../static/images/bg.png';
import logo from "../../static/images/logo.png";
import { btoaToBlobUrl, isNotEmpty } from '../../utils/Generic';
import { useCorporateState } from '../../redux/context/corporate-context';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
    backgroundColor: '#fff',
    backgroundImage: `url(${bg})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover"
  },
  appBar: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  toolbar: theme.mixins.toolbar,
  button: {
    margin: theme.spacing(1),
    color: "inherit",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "18px",
    textDecoration: "none",
    '&:hover': {
      color: theme.palette.admin.main,
    }
  },
  heading: {
    fontWeight: 400,
    fontSize: "32px",
  },
  icon: {
    margin: theme.spacing(1),
    '&:hover': {
      color: theme.palette.admin.main,
    }
  },
  link: {
    color: "#262727",
    textDecoration: "none",
  },
  logo: {
    width: "200px",
  },
  childrenStyle: {
    padding: '30px 60px'
  }
}));

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
    width: 190,
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:hover": {
      backgroundColor: theme.palette.admin.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: theme.palette.admin.main,
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#52d869',
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

function AdminLayout({ children }) {
  const classes = useStyles();
  const history = useHistory();
  const { user, removeUser } = useAuth();
  const activeTextColor = { color: "#BF85FA" };
  const [anchorEl, setAnchorEl] = useState(null);
  const [settingEl, setSettingEl] = useState(null);
  const logout = () => {
    removeUser();
    history.replace("/login");
  };

  const corporateState = useCorporateState();

  return (
    <Box className={classes.root}>
      <AppBar
        position="sticky"
        className={classes.appBar}
        elevation={0}
        color="secondary"
        gutterBottom
      >
        <Toolbar gutterBottom>
          <Box flexGrow={1}>
            <NavLink to="/admin/dashboard">
              {
                isNotEmpty(corporateState?.business_logo) ? <>
                  <img src={btoaToBlobUrl(corporateState?.business_logo)} alt={corporateState?.business_name} className={classes.logo} />
                </> : <>
                  <img src={logo} alt="TruDoc" className={classes.logo} />
                </>
              }
            </NavLink>
          </Box>
          <NavLink
            className={classes.link}
            to="/admin/dashboard"
            activeStyle={activeTextColor}
          >
            <Button className={classes.button}>
              <DashboardIcon />
            </Button>
          </NavLink>
          <Button className={classes.button} onClick={(e) => setSettingEl(e.currentTarget)}>
            <SettingsIcon />
          </Button>
          <Button>
            <SvgIcon
              className={classes.icon}
              fill="#262727"
              width="27"
              height="35"
              viewBox="0 0 27 35"
            >
              <path d="M13.499 34.057C15.2865 34.057 16.749 32.5002 16.749 30.5974H10.249C10.249 32.5002 11.7115 34.057 13.499 34.057ZM23.249 23.678V15.0288C23.249 9.71822 20.6003 5.27253 15.9365 4.09624V2.91995C15.9365 1.48419 14.8478 0.325195 13.499 0.325195C12.1503 0.325195 11.0615 1.48419 11.0615 2.91995V4.09624C6.41402 5.27253 3.74902 9.70092 3.74902 15.0288V23.678L0.499023 27.1377V28.8675H26.499V27.1377L23.249 23.678ZM19.999 25.4079H6.99902V15.0288C6.99902 10.7388 9.45277 7.24455 13.499 7.24455C17.5453 7.24455 19.999 10.7388 19.999 15.0288V25.4079Z" />
            </SvgIcon>
          </Button>

          <SvgIcon
            className={classes.icon}
            height="43"
            viewBox="0 0 1 43"
            fill="#262727"
          >
            <line
              x1="0.1"
              y1="4.37114e-09"
              x2="0.0999981"
              y2="43"
              stroke="#7E8D90"
              strokeWidth="2"
            />
          </SvgIcon>

          <Typography component="div">
            <Grid component="label" container alignItems="center" spacing={1} justify="flex-end">
              <Grid item>User </Grid>
              <Grid item>
                <FormControlLabel
                  control={<IOSSwitch name="checkedB" checked={true} onChange={() => history.replace("/dashboard")} />}
                  label="Admin"
                />
              </Grid>
            </Grid>
          </Typography>

          <Button
            className={classes.button}
            color="primary"
            onClick={(e) => setAnchorEl(e.currentTarget)}
          >
            <SvgIcon
              style={{ marginRight: 10 }}
              width="27"
              height="35"
              viewBox="0 0 27 30"
              fill="#262727"
            >
              <path d="M9.99984 13.667C9.07984 13.667 8.33317 14.4137 8.33317 15.3337C8.33317 16.2537 9.07984 17.0003 9.99984 17.0003C10.9198 17.0003 11.6665 16.2537 11.6665 15.3337C11.6665 14.4137 10.9198 13.667 9.99984 13.667ZM17.9998 13.667C17.0798 13.667 16.3332 14.4137 16.3332 15.3337C16.3332 16.2537 17.0798 17.0003 17.9998 17.0003C18.9198 17.0003 19.6665 16.2537 19.6665 15.3337C19.6665 14.4137 18.9198 13.667 17.9998 13.667ZM13.9998 0.666992C6.63984 0.666992 0.666504 6.64033 0.666504 14.0003C0.666504 21.3603 6.63984 27.3337 13.9998 27.3337C21.3598 27.3337 27.3332 21.3603 27.3332 14.0003C27.3332 6.64033 21.3598 0.666992 13.9998 0.666992ZM13.9998 24.667C8.11984 24.667 3.33317 19.8803 3.33317 14.0003C3.33317 13.6137 3.35984 13.227 3.39984 12.8537C6.5465 11.4537 9.03984 8.88033 10.3465 5.69366C12.7598 9.10699 16.7332 11.3337 21.2265 11.3337C22.2665 11.3337 23.2665 11.2137 24.2265 10.987C24.5065 11.9337 24.6665 12.947 24.6665 14.0003C24.6665 19.8803 19.8798 24.667 13.9998 24.667Z" />
            </SvgIcon>
            {user?.username}
          </Button>
          <StyledMenu
            id="customized-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
          >
            <StyledMenuItem onClick={() => history.push("/profile")}>
              <ListItemText primary="Profile" />
            </StyledMenuItem>
            <StyledMenuItem onClick={() => logout()}>
              <ListItemText primary="Logout" />
            </StyledMenuItem>
          </StyledMenu>

          <StyledMenu
            id="customized-menu"
            anchorEl={settingEl}
            keepMounted
            open={Boolean(settingEl)}
            onClose={() => setSettingEl(null)}
          >
            <StyledMenuItem onClick={() => history.push("/admin/domains")}>
              <ListItemText primary="Domains" />
            </StyledMenuItem>
            <StyledMenuItem onClick={() => history.push("/admin/contacts")}>
              <ListItemText primary="Contacts" />
            </StyledMenuItem>
            <StyledMenuItem onClick={() => history.push("/admin/settings")}>
              <ListItemText primary="Settings" />
            </StyledMenuItem>
          </StyledMenu>
        </Toolbar>
      </AppBar>

      <Box className={classes.childrenStyle}>
        {children}
      </Box>
    </Box>
  )
}

export default AdminLayout;
