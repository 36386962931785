export const UNSDG_GOAL_CATEGORIES = {
    environment: {
        id: "environment",
        title: "Environment",
        goals: [6, 7, 11, 12, 13, 14, 15, 17],
        color: "#3F91CE"
    },
    social: {
        id: "social",
        title: "Social",
        goals: [1, 2, 3, 4, 5, 10],
        color: "#6C55AB"
    },
    governance: {
        id: "governance",
        title: "Governance",
        goals: [8, 9, 16],
        color: "#F14593"
    },
};

export const UNSDG_GOALS = {
    1: {
        color: '#E6233C',
        title: 'No Poverty',
        info: 'End poverty in all its forms everywhere',
        image: 'https://sdgs.un.org/themes/custom/porto/assets/goals/images/img-goal-1.jpg',
        items: {
            targets: 7,
            events: 74,
            publications: 48,
            actions: 1353,
        },
        category: UNSDG_GOAL_CATEGORIES.social.id,
        subjects: [
            "Income poverty",
            "Access to basic services",
            "Social protection",
            "Equality and Equity",
            "Job creation and Decent work",
            "Rural development",
            "Financial inclusion",
            "Sustainable economic growth",
            "Vulnerable and Marginalised groups",
            "Global Partnerships"
        ]
    },
    2: {
        color: '#DDA739',
        title: 'Zero Hunger',
        info: 'End hunger, achieve food security and improved nutrition and promote sustainable agriculture',
        image: 'https://sdgs.un.org/themes/custom/porto/assets/goals/images/img-goal-2.jpg',
        items: {
            targets: 8,
            events: 64,
            publications: 16,
            actions: 1303,
        },
        category: UNSDG_GOAL_CATEGORIES.social.id,
        subjects: [
            "Food Security",
            "Nutrition",
            "Agricultural Productivity",
            "Smallholder Farmers",
            "Resilience and Adaptation",
            "Food waste and Loss",
            "Rural development",
            "Trade and market access",
            "Gender equality",
            "Global partnerships"
        ]
    },
    3: {
        color: '#4BA145',
        title: 'Good Health and Well-Being',
        info: 'Ensure healthy lives and promote well-being for all at all ages',
        image: 'https://sdgs.un.org/themes/custom/porto/assets/goals/images/img-goal-3.jpg',
        items: {
            targets: 13,
            events: 29,
            publications: 46,
            actions: 1217,
        },
        category: UNSDG_GOAL_CATEGORIES.social.id,
        subjects: [
            "Maternal and child health",
            "Infectious diseases",
            "Non Communicable diseases(NCD's)",
            "Access to healthcare services",
            "Vaccination and Immunization",
            "Mental Health",
            "Substance Abuse",
            "Access to Safe Water and Sanitation",
            "Health Research and Innovation",
            "Sexual and Reproductive Health",
            "Health Data and Monitoring"
        ]
    },
    4: {
        color: '#C51A2D',
        title: 'Quanlity Education',
        info: 'Ensure inclusive and equitable quality education and promote lifelong learning opportunities for all',
        image: 'https://sdgs.un.org/themes/custom/porto/assets/goals/images/img-goal-4.jpg',
        items: {
            targets: 10,
            events: 33,
            publications: 10,
            actions: 1705,
        },
        category: UNSDG_GOAL_CATEGORIES.social.id,
        subjects: [
            "Access to education",
            "Teacher Quality and Training",
            "Curriculum Development",
            "Gender Equality in Education",
            "Literacy and Numeracy",
            "Technical and Vocational Education and Training (TVET)",
            "Education Financing",
            "Quality Assurance and Monitoring",
            "Digital and Distance Learning",
            "Cultural Heritage and Indigenous Knowledge",
            "Partnerships and Collaboration"
        ]
    },
    5: {
        color: '#EE402D',
        title: 'Gender Equality',
        info: 'Achieve gender equality and empower all women and girls',
        image: 'https://sdgs.un.org/themes/custom/porto/assets/goals/images/img-goal-5.jpg',
        items: {
            targets: 9,
            events: 52,
            publications: 45,
            actions: 1535,
        },
        category: UNSDG_GOAL_CATEGORIES.social.id,
        subjects: [
            "Gender pay equity",
            "Work life balance policies",
            "Work leadership and empowerment",
            "Condition of work and social protection",
            "Fundamenal principles and rights at work",
            "Respectful and inclusive work environment",
            "Family-friendly policies and flexible work arrangements",
            "Economic empowerment",
            "Political Participation",
            "Reproductive Health and Rights",
            "Access to Technology",
            "Partnerships and Advocacy",
            "Gender-Based Violence",
            "Gender equity efforts",
            "Legal Rights and Protections"
        ]
    },
    6: {
        color: '#26BFE5',
        title: 'Clean Water and Sanitation',
        info: 'Ensure availability and sustainable management of water and sanitation for all',
        image: 'https://sdgs.un.org/themes/custom/porto/assets/goals/images/img-goal-6.jpg',
        items: {
            targets: 8,
            events: 264,
            publications: 35,
            actions: 1742,
        },
        category: UNSDG_GOAL_CATEGORIES.environment.id,
        subjects: [
            "Access to Safe Drinking Water",
            "Sanitation Facilities",
            "Water Quality and Pollution",
            "Wastewater Management",
            "Water Conservation",
            "Water Resource Management",
            "Climate Change Resilience",
            "Hygiene and Health Education",
            "Water Governance",
            "Water-Related Ecosystems",
            "Water-Related Disasters"
        ]
    },
    7: {
        color: '#FAC40F',
        title: 'Afforable and Clean Energy',
        info: 'Ensure access to affordable, reliable, sustainable and modern energy for all',
        image: 'https://sdgs.un.org/themes/custom/porto/assets/goals/images/img-goal-7.jpg',
        items: {
            targets: 5,
            events: 51,
            publications: 44,
            actions: 987,
        },
        category: UNSDG_GOAL_CATEGORIES.environment.id,
        subjects: [
            "Access to Electricity",
            "Clean Energy Sources",
            "Energy Efficiency",
            "Modern Cooking Solutions",
            "Energy Access for Rural and Remote Areas",
            "Investment in Renewable Energy",
            "Energy Affordability",
            "Access for Small and Medium-sized Enterprises (SMEs)",
            "Clean Transport",
            "Energy Security",
            "Rural Electrification"
        ]
    },
    8: {
        color: '#A31C44',
        title: 'Decent Work and Economic Growth',
        info: 'Promote sustained, inclusive and sustainable economic growth, full and productive employment and decent work for all',
        image: 'https://sdgs.un.org/themes/custom/porto/assets/goals/images/img-goal-8.jpg',
        items: {
            targets: 12,
            events: 70,
            publications: 47,
            actions: 1787,
        },
        category: UNSDG_GOAL_CATEGORIES.governance.id,
        subjects: [
            "Employment Generation",
            "Labour Practices",
            "Labor Rights",
            "Youth Employment",
            "Gender Equality in the Workplace",
            "Skills Development and Training",
            "Small and Medium-sized Enterprises (SMEs)",
            "Economic Diversification",
            "Entrepreneurship and Innovation",
            "Fair Trade Practices",
            "Global Economic Governance",
            "Sustainable Tourism",
            "Social Protection"
        ]
    },
    9: {
        color: '#F3692F',
        title: 'Industry, Innovation and Infrastructure',
        info: 'Build resilient infrastructure, promote inclusive and sustainable industrialization and foster innovation',
        image: 'https://sdgs.un.org/themes/custom/porto/assets/goals/images/img-goal-9.jpg',
        items: {
            targets: 8,
            events: 74,
            publications: 16,
            actions: 965,
        },
        category: UNSDG_GOAL_CATEGORIES.governance.id,
        subjects: [
            "Employment fairness",
            "Labour practices",
            "Social dialogue",
            "Wealth and income fairness",
            "Discrimination and vulnerable groups",
            "Condition of work and social protection",
            "Fundamenal principles and rights at work",
            "Respect for employees rights",
            "Diversity and inclusion initiatives",
            "Gender and racial equity efforts",
            "Employee engagement diversiy and inclusion"
        ]
    },
    10: {
        color: '#E11583',
        title: 'Required Inequalities',
        info: 'Reduce inequality within and among countries',
        image: 'https://sdgs.un.org/themes/custom/porto/assets/goals/images/img-goal-10.jpg',
        items: {
            targets: 10,
            events: 51,
            publications: 13,
            actions: 915,
        },
        category: UNSDG_GOAL_CATEGORIES.social.id,
        subjects: [
            "Employment fairness",
            "Labour practices",
            "Social dialogue",
            "Wealth and income fairness",
            "Discrimination and vulnerable groups",
            "Condition of work and social protection",
            "Fundamenal principles and rights at work",
            "Respect for employees rights",
            "Social Protection",
            "Equal Opportunities",
            "Diversity and inclusion initiatives",
            "Access to Information and Technology",
            "Access to Financial Services",
            "Human Rights Protections",
            "Disability Rights",
            "Progressive Taxation",
            "Gender and racial equity efforts",
            "Employee engagement diversiy and inclusion"
        ]
    },
    11: {
        color: '#F89D29',
        title: 'Sustainable Cities and Communities',
        info: 'Make cities and human settlements inclusive, safe, resilient and sustainable',
        image: 'https://sdgs.un.org/themes/custom/porto/assets/goals/images/img-goal-11.jpg',
        items: {
            targets: 10,
            events: 75,
            publications: 19,
            actions: 1169,
        },
        category: UNSDG_GOAL_CATEGORIES.environment.id,
        subjects: [
            "Urban Planning and Design",
            "Affordable Housing",
            "Public Transportation",
            "Sustainable Mobility",
            "Waste Management",
            "Air Quality",
            "Energy Efficiency in Buildings",
            "Smart Cities",
            "Heritage Preservation",
            "Basic Services",
            "Green and Open Spaces"
        ]
    },
    12: {
        color: '#C08D2C',
        title: 'Responsible Consumption and Production',
        info: 'Ensure sustainable consumption and production patterns',
        image: 'https://sdgs.un.org/themes/custom/porto/assets/goals/images/img-goal-12.jpg',
        items: {
            targets: 11,
            events: 21,
            publications: 15,
            actions: 1491,
        },
        category: UNSDG_GOAL_CATEGORIES.environment.id,
        subjects: [
            "Resource Efficiency",
            "Waste Reduction and Recycling",
            "Sustainable Production Practices",
            "Product Lifecycle Management",
            "Energy Efficiency",
            "Consumer Awareness",
            "Food Sustainability",
            "Sustainable Agriculture",
            "Water Efficiency",
            "Sustainable Development Integration",
            "Measuring and Reporting",
            "Supply Chain Sustainability",
            "Circular Economy"
        ]
    },
    13: {
        color: '#3F8046',
        title: 'Climate Action',
        info: 'Take urgent action to combat climate change and its impacts',
        image: 'https://sdgs.un.org/themes/custom/porto/assets/goals/images/img-goal-13.jpg',
        items: {
            targets: 5,
            events: 32,
            publications: 35,
            actions: 2000,
        },
        category: UNSDG_GOAL_CATEGORIES.environment.id,
        subjects: [
            "Climate Mitigation",
            "Renewable Energy Transition",
            "Energy Efficiency",
            "Transportation Emissions",
            "Sustainable Land Use",
            "Waste Reduction",
            "Climate Education and Awareness",
            "Community Engagement",
            "Carbon Pricing",
            "Biodiversity Conservation",
            "Climate Policy and Legislation"
        ]
    },
    14: {
        color: '#1F98D4',
        title: 'Life Below Water',
        info: 'Conserve and sustainably use the oceans, seas and marine resources for sustainable development',
        image: 'https://sdgs.un.org/themes/custom/porto/assets/goals/images/img-goal-14.jpg',
        items: {
            targets: 10,
            events: 73,
            publications: 40,
            actions: 2786,
        },
        category: UNSDG_GOAL_CATEGORIES.environment.id,
        subjects: [
            "Marine Pollution",
            "Sustainable Aquaculture",
            "Marine Habitat Restoration",
            "Climate Change and Sea-Level Rise",
            "Sustainable Tourism",
            "Economic Benefits of Healthy Oceans",
            "Indigenous and Local Community Involvement",
            "Plastic Waste Reduction",
            "Marine Education and Awareness",
            "Ocean Cleanup and Restoration Technologies",
            "Coral Reef Protection"
        ]
    },
    15: {
        color: '#59BA47',
        title: 'Life on Land',
        info: 'Protect, restore and promote sustainable use of terrestrial ecosystems, sustainably manage forests, combat desertification, and halt and reverse land degradation and halt biodiversity loss',
        image: 'https://sdgs.un.org/themes/custom/porto/assets/goals/images/img-goal-15.jpg',
        items: {
            targets: 12,
            events: 27,
            publications: 33,
            actions: 1232,
        },
        category: UNSDG_GOAL_CATEGORIES.environment.id,
        subjects: [
            "Forest Conservation",
            "Afforestation and Reforestation",
            "Biodiversity Conservation",
            "Sustainable Land Use",
            "Protected Areas and Reserves",
            "Ecosystem Restoration",
            "Sustainable Agriculture",
            "Habitat Conservation",
            "Climate Change and Ecosystems",
            "Sustainable Wildlife Management",
            "Global Biodiversity Conservation"
        ]
    },
    16: {
        color: '#116A9E',
        title: 'Peace, Justice and Strong Institutions',
        info: 'Promote peaceful and inclusive societies for sustainable development, provide access to justice for all and build effective, accountable and inclusive institutions at all levels',
        image: 'https://sdgs.un.org/themes/custom/porto/assets/goals/images/img-goal-16.jpg',
        items: {
            targets: 12,
            events: 30,
            publications: 13,
            actions: 987,
        },
        category: UNSDG_GOAL_CATEGORIES.governance.id,
        subjects: [
            "Rule of Law",
            "Access to Justice",
            "Effective and Accountable Institutions",
            "Good Governance",
            "Anti-Corruption Measures",
            "Human Rights Protection",
            "Conflict Prevention and Resolution",
            "Access to Information",
            "Data and Reporting Systems",
            "Cybersecurity and Digital Rights",
            "Peaceful and Inclusive Societies"
        ]
    },
    17: {
        color: '#15496B',
        title: 'Partnerships for the Goals',
        info: 'Strengthen the means of implementation and revitalize the Global Partnership for Sustainable Development',
        image: 'https://sdgs.un.org/themes/custom/porto/assets/goals/images/img-goal-17.jpg',
        items: {
            targets: 19,
            events: 185,
            publications: 73,
            actions: 2068,
        },
        category: UNSDG_GOAL_CATEGORIES.environment.id,
        subjects: [
            "Knowledge Sharing",
            "Innovation and Research",
            "Civil Society Engagement",
            "Public-Private Partnerships (PPPs)",
            "Global Economic Governance",
            "Data and Monitoring",
            "Technology Transfer",
            "Development Financing",
            "Trade and Market Access",
            "Capacity Building",
            "Policy Coherence"
        ]
    },
};