import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import Slide from "@material-ui/core/Slide";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useModal, useAuth, useLocalStorage } from "../../hooks";
import { verifyLoginOtp, requestOtp } from "../../services/auth.service";
import OTPInput from "./otp";
import "./login.css";
import { IconButton } from "@material-ui/core";
import { Cancel } from "@material-ui/icons";
import jwt from 'jwt-decode';
import { useSocketContext } from "../../services/socket";

const useStyles = makeStyles(theme => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    maxWidth: "650px",
    margin: "0 auto"
  },
  paper: {
    borderRadius: "10px",
    backgroundColor: theme.palette.primary.main,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(6),
    textAlign: "center"
  },
  heading: {
    fontSize: "24px",
    fontWeight: "500",
    lineHeight: theme.spacing(0.2),
    marginBottom: theme.spacing(5)
  },
  button: {
    marginTop: "20px",
    marginBottom: "10px",
    width: 150,
    height: 45,
    borderRadius: 25
  },
  buttonRight: {
    marginTop: "20px",
    marginBottom: "10px",
    border: '1px solid gray',
    backgroundColor: '#fff',
    width: 150,
    height: 45,
    color: "#6a6a6a",
    borderRadius: 25,
    '&:hover': {
      backgroundColor: "#fff", 
      color: '#363636',
      border: '1px solid #464646'
   }
  },
  buttonText: {
    fontWeight: "bold",
    fontSize: 16,
    letterSpacing: "0.15em",
    color: "#FFFDFD",
  },
  buttonTextCancel: {
    fontWeight: "bold",
    fontSize: 16,
    letterSpacing: "0.15em",
  },
  otpInput: {
    width: "3rem !important",
    height: "3rem",
    margin: "0 1rem",
    fontSize: "2rem",
    textAlign: "center",
    borderRadius: "4px",
    border: "1px solid rgba(0, 0, 0, 0.3)"
  },
  btnWrapper:{
    display: 'flex',
    justifyContent: 'center',
    gap: '15px'
  },
  closeIcon:{
    position: 'absolute',
    right: '0',
    padding: '10px'
  },
  title:{
    marginRight: '3rem'
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" timeout={10000} ref={ref} {...props} />;
});

const VerifyModal = props => {
  const classes = useStyles();
  const history = useHistory();
  const { onClose, isOpen } = useModal();
  const { saveUser } = useAuth();
  const [otp, setOtp] = useState("");
  const [otpError, setOtpError] = useState(false);
  const { data } = useSelector(state => state.modal);
  const { setItem } = useLocalStorage();
  const socket = useSocketContext();

  const closeHandler = ()=>{
    onClose();
  }

  const handleSubmit = e => {
    e.preventDefault();
    setOtpError(false);
    if (data && data?.modalType === "loginVerify") {
      verifyLoginOtp({
        otp,
        username: data?.email,
        sessionId: data?.sessionId
      }).then(res => {
        if (res && res.status === 200 && res?.data?.isOTPValid) {
          const token = jwt(res?.data?.accessToken);
          setItem("userId",token.sub);
          socket.emit('info', {userId: token.sub});
          saveUser(res?.data?.accessToken);
          onClose();
          history.push("/dashboard");
        } else {
          setOtpError("Invalid OTP");
        }
      });
    } else {
    }
  };

  return (
    <Dialog
      open={isOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      align="center"
    >
      <div className={classes.closeIcon}>
      <IconButton
        onClick={onClose}
      >
        <Cancel/>
      </IconButton>  
      </div>
      <DialogTitle id="alert-dialog-slide-title" className={classes.title}>
        {"Enter the OTP sent to your registered email ID."}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <OTPInput
            autoFocus
            isNumberInput
            length={6}
            className="otpContainer"
            inputClassName="otpInput"
            onChangeOTP={otp => setOtp(otp)}
          />
          {otpError && (
            <Typography component="p" color="error">
              {otpError}
            </Typography>
          )}
        </DialogContentText>
        <div className={classes.btnWrapper}>
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          className={classes.button}
        >
          <Typography className={classes.buttonText}>Submit</Typography>
        </Button>
        <Button
          onClick={closeHandler}
          // variant="contained"
          className={classes.buttonRight}
        >
          <Typography className={classes.buttonTextCancel}>Cancel</Typography>
        </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};
export default VerifyModal;

// return (
//   <Modal
//     className={classes.modal}
//     open={isOpen}
//     onClose={onClose}
//     closeAfterTransition
//     BackdropComponent={Backdrop}
//   >
//     <Fade in={isOpen}>
//       <div className={classes.paper}>
//         <Typography
//           variant="h2"
//           component="h2"
//           align="center"
//           className={classes.heading}
//         >
//             Enter the OTP sent to your registered email ID.
//         </Typography>
//         <form noValidate autoComplete="off" onSubmit={handleSubmit}>
//           <TextField
//             label="Enter the OTP"
//             variant="outlined"
//             color="secondary"
//             onChange={(e) => setOtp(e.target.value)}
//             inputProps={{ minLength: 6, maxLength: 6 }}
//             error={otpError}
//           />
//           <Box component="div">
//             <Button
//               size="large"
//               type="submit"
//               variant="contained"
//               style={{ marginTop: 10 }}
//             >
//               Submit
//             </Button>
//           </Box>
//         </form>
//       </div>
//     </Fade>
//   </Modal>
// );

// import React from 'react';
// import {
//   Modal,
//   ModalOverlay,
//   ModalContent,
//   ModalBody,
//   ModalHeader,
//   ModalCloseButton,
//   Button,
//   PinInput,
//   PinInputField,
//   Heading,
//   HStack
// } from '@chakra-ui/react';

// const Verification = ({ isOpen, onClose }) => {
//   return (
//     <Modal isOpen={isOpen} onClose={onClose} isCentered motionPreset="slideInBottom" size="xl" closeOnOverlayClick={false} closeOnEsc={false}>
//       <ModalOverlay />
//       <ModalContent>
//         <ModalHeader></ModalHeader>
//         <ModalCloseButton fontSize="xl"/>
//         <ModalBody textAlign="center" p="10">
//           <Heading as="h2" fontSize="3xl" textAlign="center" lineHeight="1.5">Enter the OTP sent to your registered email ID.</Heading>
//           <HStack justifyContent="center" my="10" border="1px solid #222" p="5" borderRadius="50px" width="65%" mx="auto">
//             <PinInput size="lg" otp type="numeric" variant="flushed" placeholder="" autoFocus>
//               <PinInputField />
//               <PinInputField />
//               <PinInputField />
//               <PinInputField />
//             </PinInput>
//           </HStack>
//           <Button
//             size="lg"
//             fontSize="xl"
//             p="7"
//           >
//             Submit
//           </Button>
//         </ModalBody>
//       </ModalContent>
//     </Modal>
//   )
// }

// export default Verification;
