import * as Joi from "joi-browser";
import { networkChainId, pickerLang } from "../constants";
import { toLocalDateTime } from '../utils/moment-utils'
import { MessageConstants } from "./MessageConstants";
import { UNSDG_GOALS, UNSDG_GOAL_CATEGORIES } from "./unsdg-goals";

export const getFirstChar = (string) => {
    if (string && string?.trim()?.length > 0) {
        return string.match(/\b(\w)/g)?.join('');
    }
}

export const getInitials = (string) => {
    var names = string.split(' '),
        initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
        initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
}

export const concatStrings = (separator, ...strings) => {
    let finalStrings = [];
    for (let string of strings) {
        if (string !== undefined && string !== null) {
            string = string.toString().trim();
            if (string !== '') {
                finalStrings.push(string);
            }
        }
    }
    let finalString = finalStrings.join(separator);
    return finalString;
}

export const printWithDefault = (value, defaultValue = '-') => {
    if (value !== '' && value !== undefined && value !== null) {
        return value;
    }
    return defaultValue;
}

export const isEmpty = (data) => {
    if (data !== undefined && data !== "undefined" && data !== '' && data !== null && data !== 'null') {
        return false;
    }
    return true;
}

export const isNotEmpty = (data) => {
    if (isEmpty(data)) {
        return false;
    }
    return true;
}

export const printTime = (timeString) => {
    if (isNotEmpty(timeString)) {
        return toLocalDateTime(timeString);
    }
    return '-';
}

export const printUnderScoreText = (text) => {
    text = text.toString().replace(/_/g, ' ');
    return text;
}

export const validateJOIProperty = (schema, event) => {
    const { name, value } = event.target;
    const obj = {
        [name]: value
    };
    if (schema.hasOwnProperty(name)) {
        const subSchema = {
            [name]: schema[name]
        };
        const result = Joi.validate(obj, subSchema, {
            stripUnknown: true
        });
        const { error } = result;
        return error ? error.details[0].message : null;
    } else {
        return null;
    }
};

export const validateJOIFormField = (formFields, schema) => {
    const result = Joi.validate(
        formFields,
        schema, {
        abortEarly: false,
        stripUnknown: true
    }
    );
    const { error } = result;
    if (!error) {
        return null;
    } else {
        const errorData = {};
        // console.log(error.details);
        for (let item of error.details) {
            const name = item.path[0];
            errorData[name] = item.message;
        }
        return errorData;
    }
};

export const getImageFromMimeType = (mimeType = '', isFolder = false) => {
    let fileTypeImage = "documents.png";
    if (isFolder) {
        fileTypeImage = "folder.png";
    } else {

        var iconImages = {
            // Media
            image: "gallery.png",
            audio: "audio.png",
            video: "video.png",
            // Documents
            "application/pdf": "pdf.png",
            "application/msword": "word.png",
            "application/vnd.ms-word": "word.png",
            "application/vnd.oasis.opendocument.text": "word.png",
            "application/vnd.openxmlformatsfficedocument.wordprocessingml": "word.png",
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document": "word.png",
            "application/vnd.ms-excel": "excel.png",
            "text/csv": "excel.png",
            "application/vnd.openxmlformatsfficedocument.spreadsheetml": "excel.png",
            "application/vnd.oasis.opendocument.spreadsheet": "excel.png",
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": "excel.png",
            "application/vnd.ms-powerpoint": "ppt.png",
            "application/vnd.openxmlformatsfficedocument.presentationml": "ppt.png",
            "application/vnd.oasis.opendocument.presentation": "ppt.png",
            "application/vnd.openxmlformats-officedocument.presentationml.presentation": "ppt.png",
            "application/json": "code.png",
            // Archives
            "application/gzip": "zip.png",
            "application/zip": "zip.png"
        };

        if (isNotEmpty(mimeType)) {
            for (var key in iconImages) {
                if (iconImages.hasOwnProperty(key)) {
                    if (mimeType.search(key) === 0) {
                        fileTypeImage = iconImages[key];
                        break;
                    }
                }
            }
        }

    }
    return '/assets/images/file-types/' + fileTypeImage;
};

export const hexToNumber = (hexString) => {
    return parseInt(hexString, 'radix');
};

export const pdfBase64ToBlob = (base64) => {
    const binaryString = window.atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; ++i) {
        bytes[i] = binaryString.charCodeAt(i);
    }

    return new Blob([bytes], { type: 'application/pdf' });
};

export const parseWeb3Error = (err) => {
    console.log("err", err);
    return "Blockchain transaction Failed";
    // try {
    //     var keys = Object.keys(err); 
    //     console.log('obj contains ' + keys.length + ' keys: ' + keys);

    //     err = err?.message;
    //     console.log("11", err);
    //     err = err.toString().trim();
    //     err = err.split("{");
    //     err = err[0];
    //     err = err.toString().trim();
    //     err = err.replace("Error:", "");
    //     err = err.toString().trim();
    // } catch (error) {
    //     console.log("err", err);
    //     console.log(typeof error);
    //     console.log(error);
    // }
    // return err;
};

export const normalizeAddr = (addr) => {
    if (isNotEmpty(addr)) {
        addr = addr.toString().trim().toLowerCase();
    }
    return addr;
}

export const svgToImage = async (base64svg, height, width, format, cb) => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    canvas.width = width;
    canvas.height = height;
    const image = new Image();
    image.src = base64svg;
    image.onload = () => {
        context.clearRect(0, 0, width, height);
        context.drawImage(image, 0, 0, width, height);
        const pngData = canvas.toDataURL('image/' + format);
        cb(pngData);
    };
}

export const base64MimeType = (encoded) => {
    var result = null;
    if (typeof encoded !== 'string') {
        return result;
    }
    var mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);
    if (mime && mime.length) {
        result = mime[1];
    }
    return result;
}


export const btoaToBlob = (btoaData) => {
    const base64Data = window.atob(btoaData);
    return base64ToBlob(base64Data);
};


export const btoaToBlobUrl = (btoaData) => {
    const blobData = btoaToBlob(btoaData);
    return URL.createObjectURL(blobData);
};


export const base64ToBlob = (dataURI) => {
    var byteString = atob(dataURI.split(',')[1]);
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    var blob = new Blob([ab], { type: mimeString });
    return blob;
};

export const withAppUrl = (path) => {
    if (isNotEmpty(path)) {
        path = path.toString();
        let mainDomain = window.location.origin;
        return mainDomain + "/" + path;
    } else {
        return null;
    }
}

export const fileToBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
        return resolve(reader.result);
    };
    reader.onerror = error => {
        console.log(error);
        return resolve(null);
    };
});

export const base64ImageDimensions = (base64Image) => {
    return new Promise(async (resolve) => {
        let im = new Image;
        im.src = base64Image;
        im.onload = () => {
            return resolve({
                width: im.width,
                height: im.height
            });
        };
    });
}

export const updateDimensionsAsPerSizes = (dimensions, maxWidth, maxHeight) => {
    var ratio = 0;  // Used for aspect ratio
    var width = dimensions.width;    // Current image width
    var height = dimensions.height;  // Current image height

    // Check if the current width is larger than the max
    if (width > maxWidth) {
        ratio = maxWidth / width;   // get ratio for scaling image
        height = height * ratio;    // Reset height to match scaled image
        width = width * ratio;    // Reset width to match scaled image
    }

    // Check if current height is larger than max
    if (height > maxHeight) {
        ratio = maxHeight / height; // get ratio for scaling image
        width = width * ratio;    // Reset width to match scaled image
        height = height * ratio;    // Reset height to match scaled image
    }

    return {
        height,
        width
    };
}

export const inWordsWithoutDecimal = (amount) => {
    if (isNotEmpty(amount)) {
        var words = new Array();
        words[0] = '';
        words[1] = 'One';
        words[2] = 'Two';
        words[3] = 'Three';
        words[4] = 'Four';
        words[5] = 'Five';
        words[6] = 'Six';
        words[7] = 'Seven';
        words[8] = 'Eight';
        words[9] = 'Nine';
        words[10] = 'Ten';
        words[11] = 'Eleven';
        words[12] = 'Twelve';
        words[13] = 'Thirteen';
        words[14] = 'Fourteen';
        words[15] = 'Fifteen';
        words[16] = 'Sixteen';
        words[17] = 'Seventeen';
        words[18] = 'Eighteen';
        words[19] = 'Nineteen';
        words[20] = 'Twenty';
        words[30] = 'Thirty';
        words[40] = 'Forty';
        words[50] = 'Fifty';
        words[60] = 'Sixty';
        words[70] = 'Seventy';
        words[80] = 'Eighty';
        words[90] = 'Ninety';
        amount = amount.toString();
        var atemp = amount.split(".");
        var number = atemp[0].split(",").join("");
        var n_length = number.length;
        var words_string = "";
        if (n_length <= 9) {
            var n_array = new Array(0, 0, 0, 0, 0, 0, 0, 0, 0);
            var received_n_array = new Array();
            for (var i = 0; i < n_length; i++) {
                received_n_array[i] = number.substr(i, 1);
            }
            for (var i = 9 - n_length, j = 0; i < 9; i++, j++) {
                n_array[i] = received_n_array[j];
            }
            for (var i = 0, j = 1; i < 9; i++, j++) {
                if (i == 0 || i == 2 || i == 4 || i == 7) {
                    if (n_array[i] == 1) {
                        n_array[j] = 10 + parseInt(n_array[j]);
                        n_array[i] = 0;
                    }
                }
            }
            var value = "";
            for (var i = 0; i < 9; i++) {
                if (i == 0 || i == 2 || i == 4 || i == 7) {
                    value = n_array[i] * 10;
                } else {
                    value = n_array[i];
                }
                if (value != 0) {
                    words_string += words[value] + " ";
                }
                if ((i == 1 && value != 0) || (i == 0 && value != 0 && n_array[i + 1] == 0)) {
                    words_string += "Crores ";
                }
                if ((i == 3 && value != 0) || (i == 2 && value != 0 && n_array[i + 1] == 0)) {
                    words_string += "Lakhs ";
                }
                if ((i == 5 && value != 0) || (i == 4 && value != 0 && n_array[i + 1] == 0)) {
                    words_string += "Thousand ";
                }
                if (i == 6 && value != 0 && (n_array[i + 1] != 0 && n_array[i + 2] != 0)) {
                    words_string += "Hundred ";
                } else if (i == 6 && value != 0) {
                    words_string += "Hundred ";
                }
            }
            words_string = words_string.split("  ").join(" ");
        }
        return words_string;
    } else {
        return '';
    }
}

export const inWords = (n) => {
    if (isNotEmpty(n)) {
        n = n.toString();
        n = n.replace('.00', '');
        var nums = n.split('.')
        var whole = inWordsWithoutDecimal(nums[0])
        if (nums.length == 2) {
            var fraction = inWordsWithoutDecimal(nums[1])
            return whole + 'and ' + fraction;
        } else {
            return whole;
        }
    } else {
        return '';
    }
}

export const isValidEmailAddress = (email) => {
    if (isNotEmpty(email)) {
        return email.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    }
    return false;
}

export const generateUniqueFileNameFromName = (fileName, randomString = null) => {
    if (fileName != null) {
        fileName = fileName.toString().toLowerCase();
        //pop will return whole string back if no extension find in string
        var fileExt = fileName.split('.').pop();
        if (fileExt === fileName) {
            fileExt = "";
        } else {
            fileExt = "." + fileExt;
        }

        fileName = fileName.slice(0, fileName.length - fileExt.length);

        if (randomString == null) {
            randomString = new Date().getTime().toString();
        }
        return (fileName + "-" + randomString + fileExt);
    }
    return null;
}


export const goalsParseCategories = (goals) => {
    let dataItems = [];
    for (let goal of Object.keys(goals)) {
        let goalItem = UNSDG_GOALS[goal];
        let val = goalItem["category"];
        if (!dataItems.includes(val)) {
            dataItems.push(val)
        }
    }
    let finalItems = [];
    if (dataItems.includes("environment")) {
        finalItems.push("environment");
    }
    if (dataItems.includes("social")) {
        finalItems.push("social");
    }
    if (dataItems.includes("governance")) {
        finalItems.push("governance");
    }
    return finalItems;
}

export const goalsParseSubjects = (goals) => {
    let dataItems = [];
    let keyIndex = 0;
    for (let goal of Object.keys(goals)) {
        let goalItem = goals[goal];
        let goalDetails = UNSDG_GOALS[goal];
        if (goalItem?.subjects) {
            for (let subject of Object.keys(goalItem?.subjects)) {
                let subjectItem = goalItem?.subjects[subject];
                subjectItem.keyIndex = keyIndex;
                subjectItem.goal = goalDetails;
                subjectItem.goalNumber = goal;
                subjectItem.category = UNSDG_GOAL_CATEGORIES[subjectItem.goal.category];
                dataItems.push(subjectItem);
                keyIndex++;
            }
        }
    }
    return dataItems;
}

export const getObjectRelKey = (object) => {
    return object?.peopleEnvironmentImpactRelevance?.textValue + "-" + object?.businessImpactRelevance?.textValue;;
}

export const getGridKeys = () => {
    return [
        "major-negligible",
        "major-moderate",
        "major-significant",
        "major-major",

        "significant-negligible",
        "significant-moderate",
        "significant-significant",
        "significant-major",

        "moderate-negligible",
        "moderate-moderate",
        "moderate-significant",
        "moderate-major",

        "negligible-negligible",
        "negligible-moderate",
        "negligible-significant",
        "negligible-major"
    ];
}

export const parseGridData = (subjects) => {
    let gridMainData = {};
    for (let getGridKey of getGridKeys()) {
        gridMainData[getGridKey] = {};
    }
    for (let key of Object.keys(subjects)) {
        let object = subjects[key];
        if (isNotEmpty(object?.peopleEnvironmentImpactRelevance?.textValue) && isNotEmpty(object?.businessImpactRelevance?.textValue)) {
            let key = getObjectRelKey(object);
            if (!gridMainData[key].hasOwnProperty(object.goalNumber)) {
                gridMainData[key][object.goalNumber] = [];
            }
            gridMainData[key][object.goalNumber].push(object);
        }
    }
    return gridMainData;
}

export const parseObjectiveSubjects = (objective, goals) => {
    let mainData = {};
    let subjects = goalsParseSubjects(goals);
    if (isNotEmpty(objective?.subjects)) {
        if (objective?.subjects.length > 0) {
            for (let subjectItem of objective?.subjects) {
                let goalNumber = subjectItem?.goal;
                let subjectTitle = subjectItem?.subject;
                if (!mainData.hasOwnProperty(goalNumber)) {
                    mainData[goalNumber] = [];
                }
                //match subject
                for (let subjectData of subjects) {
                    // console.log(subjectData);
                    if (
                        subjectData?.goalNumber.toString() === goalNumber.toString() &&
                        subjectData?.title.toString() === subjectTitle.toString()
                    ) {
                        mainData[goalNumber].push(subjectData);
                    }
                }
            }
        }
    }
    return mainData;
}

export const toTitleCase = (str) => {
    // Split the string into words
    var words = str.split(' ');

    // Capitalize the first letter of each word
    var titleCaseWords = words.map(function (word) {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    });

    // Join the title-cased words back into a string
    var titleCasedStr = titleCaseWords.join(' ');

    return titleCasedStr;
}

export const generateSusChart = (report) => {
    let chartData = [
        ['Task', 'Total Actions', { type: 'string', role: 'tooltip', 'p': { 'html': true } }]
    ];
    let catData = {
        Environment: 0,
        Social: 0,
        Governance: 0
    };
    let catDesc = {
        Environment: [],
        Social: [],
        Governance: []
    }
    let goals = report?.sustainability_goals;
    if (isNotEmpty(goals) && goals.length > 0) {
        for (let goalItem of goals) {
            let goalNumber = goalItem.goal_number;
            let goalIt = UNSDG_GOALS[goalNumber];
            let goalCat = toTitleCase(goalIt?.category);
            if (!catData.hasOwnProperty(goalCat)) {
                catData[goalCat] = 1;
            } else {
                catData[goalCat] = parseInt(catData[goalCat]) + 1;
            }

            var goalObjectives = report?.sustainability_goal_objectives.map(obj => {
                if (obj?.sustainability_goal_subjects.length > 0) {
                    const check = obj?.sustainability_goal_subjects.map(sub => {
                        if (sub.goal_title == goalIt.title) {
                            return goalIt.title
                        }
                        else {
                            return null
                        }

                    }).filter(Boolean)
                    if (check.length > 0) {
                        return obj.objective
                    }
                    else {
                        return null
                    }
                }
            }).filter(Boolean)

            catDesc[goalCat].push(...goalObjectives)
        }
    }

    for (let catKey in catData) {
        console.log(catDesc[catKey])
        var unique = catDesc[catKey].filter((value, index, array) => array.indexOf(value) === index);
        unique = unique.map(element => `<p>${element}</p>`).join('');
        console.log(unique)
        const tooltip = `<div class='tooltip-chart'>
                                <div>
                                    <h2>ESG</h2>
                                    <p>${catKey}</p>
                                </div>
                                <div class='col-4'>
                                    <p>Amount of selected ${catKey} SDG's: ${catData[catKey]}</p>
                                </div>
                                <div class='col-4'>
                                    <h2>Objectives</h2> 
                                    ${unique}
                                </div>
                        </div>`

        chartData.push([catKey, catData[catKey], tooltip]);
    }
    return chartData;
}

export const parseByGoals = (subjects) => {
    let goalsData = {};
    for (let subjectItem of subjects) {
        if (!goalsData.hasOwnProperty(subjectItem.goal_number)) {
            goalsData[subjectItem.goal_number] = {
                goal_number: subjectItem.goal_number,
                goal_title: subjectItem.goal_title,
                subjects: []
            };
        }
        goalsData[subjectItem.goal_number].subjects.push(subjectItem);
    }
    return goalsData;
}

export const makeText = m => {
    if (m && m.year && m.month) return (pickerLang.months[m.month - 1] + '. ' + m.year)
    return '?'
}