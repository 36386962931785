import React, { useEffect, useState } from "react";
import { CorporateStatus } from "../../constants";
import { useAuth } from "../../hooks";
import { getCorporateInfo } from "./../../services/admin.service";

const CorporateStateContext = React.createContext();
const CorporateDispatchContext = React.createContext();

function reducer(currentState, newState) {
  return { ...currentState, ...newState };
}

function useCorporateState() {
  const context = React.useContext(CorporateStateContext);
  if (!context) throw new Error("useCorporateState must be used in CorporateProvider");

  return context;
}

function useCorporateDispatch() {
  const context = React.useContext(CorporateDispatchContext);
  if (!context) throw new Error("useCorporateDispatch must be used in CorporateProvider");

  return context;
}

const initialState = {
  status: CorporateStatus.ACTIVE
};

//console.log("coporate context works");

function CorporateProvider(props) {

  const [corporateState, corporateDispatch] = React.useReducer(reducer, initialState);
  const { user } = useAuth();

  const getCorporate = () => {
    getCorporateInfo().then(res => {
      corporateDispatch(res?.data?.data);
    }).catch(err => {

    });
  }

  useEffect(() => {
    if (user != null) {
      getCorporate();
    } else {
      if (corporateState.status != initialState.status) {
        corporateDispatch(initialState); 
      }
    }
  }, [user]);

  return (
    <CorporateStateContext.Provider value={corporateState}>
      <CorporateDispatchContext.Provider value={corporateDispatch}>
        {props.children}
      </CorporateDispatchContext.Provider>
    </CorporateStateContext.Provider>
  );
}

export { CorporateProvider, useCorporateState, useCorporateDispatch };
