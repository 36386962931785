import { endpoint } from "./services.constant";
import { getFormData } from "./utils";
import apiCall, { userApiCall } from "./api";

export const getAllContacts = () => {
    return userApiCall(endpoint.admin.CONTACTS);
}

export const getAllCustomers = () => {
    return userApiCall(endpoint.admin.CUSTOMERS);
}

export const getDomains = () => {
    return apiCall(endpoint.admin.DOMAIN);
}

export const getDomainSecret = (domainId) => {
    return apiCall(`${endpoint.admin.DOMAIN_SECRET}/${domainId}`);
}

export const updateDomainCallbackURL = (domainId, callbackurl) => {
    return apiCall(`${endpoint.admin.DOMAIN}/${domainId}`, 'PUT', { callbackurl });
}

export const addUser = (payload) => {
    return userApiCall(endpoint.admin.CONTACTS, 'POST', payload);
}

export const updateUser = (payload) => {
    return userApiCall(endpoint.admin.CONTACTS, 'PUT', payload);
}

export const deleteUser = (payload) => {
    return userApiCall(endpoint.admin.CONTACTS, 'DELETE', payload);
}

export const addCustomer = (payload) => {
    return userApiCall(endpoint.admin.CUSTOMERS, 'POST', payload);
}

export const updateCustomer = (payload) => {
    return userApiCall(endpoint.admin.CUSTOMERS, 'PUT', payload);
}

export const deleteCustomer = (payload) => {
    return userApiCall(endpoint.admin.CUSTOMERS, 'DELETE', payload);
}

export const uploadclientDocument = (payload) => {
    const formData = getFormData(payload);
    return apiCall(endpoint.client.uploadDocument,
        "POST",
        null,
        formData,
        null,
        "multipart/form-data");
}

export const fetchclientDocuments = () => {
    return apiCall(endpoint.client.uploadDocument, "GET");
}

export const downloadDocument = (docId, token) => {
    return apiCall(`${endpoint.client.downloadDocument}/${docId}`, "GET", null, null, null, "application/json", { 'x-access-token': token });
}

export const blockUnblockUser = (payload) => {
    return userApiCall(endpoint.admin.BLOCK_UNBLOCK_USER, 'POST', payload);
}

export const resendDomainVerification = (id) => {
    return userApiCall(`${endpoint.admin.RESEND_DOMAIN_VERIFICATION}/${id}`, 'GET');
}

export const configureDomainRequest = (id) => {
    return userApiCall(`${endpoint.admin.CONFIGURE_DOMAIN}/${id}`, 'GET');
}

export const configureDomainSaveRequest = (id, payload) => {
    return userApiCall(`${endpoint.admin.CONFIGURE_DOMAIN_SAVE}/${id}`, 'POST', payload);
}

export const getCorporateSettings = () => {
    return apiCall(endpoint.admin.CORPORATE_SETTINGS, "GET");
}

export const saveCorporateSettings = (payload) => {
    return apiCall(endpoint.admin.CORPORATE_SETTINGS_SAVE, 'POST', payload);
}

export const saveCorporateSession = (payload) => {
    return userApiCall(endpoint.admin.CORPORATE_SESSION_UPDATE, 'PUT', payload);
}

export const getCorporateSession = () => {
    return userApiCall(endpoint.admin.GET_CORPORATE_SESSION, 'GET');
}

export const updateWalletType = (payload) => {
    return apiCall(endpoint.admin.UPDATE_WALLET_TYPE, 'POST', payload);
}

export const linkWallet = (payload) => {
    return apiCall(endpoint.admin.LINK_WALLET, 'POST', payload);
}

export const createWallet = (payload) => {
    return apiCall(endpoint.admin.CREATE_WALLET, 'POST', payload);
}

export const getCorporateInfo = (payload) => {
    return apiCall(endpoint.admin.CORPORATE_INFO, 'GET', payload);
}

export const createToDo = (payload) => {
    return apiCall(endpoint.todo.CREATE, 'POST', payload);
}

export const updateToDo = (payload) => {
    return apiCall(endpoint.todo.UPDATE, 'POST', payload);
}

export const markToDoCompleted = (payload) => {
    return apiCall(endpoint.todo.MARK_COMPLETED, 'POST', payload);
}

export const markToDoUnCompleted = (payload) => {
    return apiCall(endpoint.todo.MARK_UNCOMPLETED, 'POST', payload);
}

export const deleteToDoItem = (payload) => {
    return apiCall(endpoint.todo.DELETE, 'POST', payload);
}

export const ToDoList = (payload) => {
    return apiCall(endpoint.todo.LIST, 'POST', payload);
}

export const currentSustainabilityReport = (payload = {}) => {
    return apiCall(endpoint.sustainability.CURRENT_REPORT, 'POST', payload);
}

export const saveSustainabilityReport = (payload) => {
    return apiCall(endpoint.sustainability.SAVE_REPORT, 'POST', payload);
}

export const saveKpiDataType = (payload) => {
    return apiCall(endpoint.sustainability.SAVE_KPI_DATA_TYPE, 'POST', payload);
}

export const saveKpiData = (payload) => {
    return apiCall(endpoint.sustainability.SAVE_KPI_DATA, 'POST', payload);
}

export const uploadSustainabilityFile = (payload) => {
    const formData = getFormData(payload);
    return apiCall(endpoint.sustainability.UPLOAD, "POST", null, formData, "multipart/form-data");
}

export const deleteSustainabilityFile = (options = {}) => {
    return apiCall(endpoint.sustainability.DELETE_DOCUMENT, 'POST', options);
}

export const sustainabilityLogs = (options = {}) => {
    return apiCall(endpoint.sustainability.LOGS, 'POST', options);
}

export const addKPIData = (payload) => {
    return apiCall(endpoint.sustainability.ADD_KPI_DATA, 'POST', payload);
}

export const editKPIData = (payload) => {
    return apiCall(endpoint.sustainability.EDIT_KPI_DATA, 'POST', payload);
}

export const deleteKPI = (payload) => {
    return apiCall(endpoint.sustainability.DELETE_KPI, 'POST', payload);
}

export const deleteObjective = (payload) => {
    return apiCall(endpoint.sustainability.DELETE_OBJECTIVE, 'POST', payload);
}