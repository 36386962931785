import { isNotEmpty } from "./Generic";

export const getCollaborator = (documentCollborators, userId) => {
    if (documentCollborators != null && documentCollborators.length > 0) {
        for (let documentCollborator of documentCollborators) {
            if (documentCollborator.user_id.toString() === userId.toString()) {
                return documentCollborator;
            }
        }
    }
    return null;
}

export const matchCollaboratorRole = (documentCollborator, roles) => {
    if (documentCollborator != null) {
        let collaboratorRole = documentCollborator.role.toString().toLowerCase();
        for (let role of roles) {
            role = role.toString().toLowerCase();
            if (collaboratorRole === role) {
                return true;
            }
        }
    }
    return false;
}

export const haveCollaboratorRole = (documentCollborators, roles, userId) => {
    let collaborator = getCollaborator(documentCollborators, userId);
    if (collaborator !== null) {
        return matchCollaboratorRole(collaborator, roles);
    }
    return false;
}

export const getFileExtensionFromName = (name) => {
    var re = /(?:\.([^.]+))?$/;
    let extension = re.exec(name)[1];
    if (isNotEmpty(extension)) {
        return extension;
    } else {
        return null;
    }
}

export const haveViewer = (mimeType, fileName = null) => {

    if (isNotEmpty(fileName)) {
        let extension = getFileExtensionFromName(fileName);
        if (isNotEmpty(extension)) {
            switch (extension) {
                case "pdf":
                case "doc":
                case "docx":
                case "xls":
                case "xlsx":
                case "ppt":
                case "pptx":
                case "png":
                case "jpg":
                case "jfif":
                    return true;
                    break;
            }
        }
    }

    if (isNotEmpty(mimeType)) {
        mimeType = mimeType.toString().toLowerCase();
        switch (mimeType) {
            case "application/pdf":
                return true;
                break;
        }
    }

    return false;
}

export const canBeSentToSign = (mimeType) => {
    if (isNotEmpty(mimeType)) {
        mimeType = mimeType.toString().toLowerCase();
        if (mimeType == "application/pdf") {
            return true;
        }
    }
    return false;
}