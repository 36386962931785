import React, { useState, useEffect, useRef } from 'react';
import Chart from 'react-google-charts';

function KpiChart(props) {
    // var options = props.chartData
    var monthSelect = props.monthType
    const [chartData, setChartData] = useState([])

    useEffect(() => {

        async function getchartdata(vals) {
            var temp_data = [["Year", "Value"]]
            for await (const val of vals) {
                var date
                if (props?.kpiData.track_duration != 'yearly')
                    date = `${monthSelect[+val.date - 1]?.label} ${val.year}`
                else
                    date = `${val.year}`
                temp_data.push([date, +val.value])
            }
            // console.log(temp_data)
            setChartData(temp_data)
        }

        getchartdata(props.chartData)

    }, [monthSelect, props.chartData])

    return (
        <>
            <Chart
                chartType={props.graphType}
                width="-webkit-fill-available"
                height="400px"
                data={chartData}
            />
        </>
    );
}

export default KpiChart;
