import { useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { Alert } from "@material-ui/lab";
import {
  Snackbar,
} from "@material-ui/core";

import { getData } from "@govtechsg/open-attestation";
import tick from "../../static/svg/tick.svg";
import download from "../../static/images/download.png";
import CloseIcon from "@material-ui/icons/Close";
import EndrosmentChain from "../../components/EndrosmentChain";

import { accessTokenDetails } from "../../services/api.service";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

import AttachmentLink from "../../components/AttachmentLink";
import DecentralisedRendererContainer from "../../components/DecentralisedTemplateRenderer/DecentralisedRenderer";
import { List } from "@material-ui/core";
import { ListItem } from "@material-ui/core";
import { ListItemAvatar } from "@material-ui/core";
import { Avatar } from "@material-ui/core";
import { ListItemText } from "@material-ui/core";
import { Box } from "@material-ui/core";
import { CircularProgress } from "@material-ui/core";
import { Button } from "@material-ui/core";
import { useCallback } from "react";

import { utils } from "@govtechsg/open-attestation";

import { verifyDoc, getEndrosmentChain, uploadTt, verifyDocByDocID, getPickerFileData } from "../../services/api.service";

const useStyles = makeStyles((theme) => {
  return {
    root: {
      minHeight: "100vh",
      backgroundColor: "transparent",
    },
    logo: {
      marginTop: theme.spacing(5),
      height: "50px",
      borderRadius: "0px",
      marginLeft: theme.spacing(8),
    },
    number: {
      height: "30px",
      width: "30px",
      borderRadius: "15px",
      backgroundColor: "#66CFA4",
      marginRight: theme.spacing(1),
    },
    num: {
      color: "#ffffff",
      fontSize: "18px",
      fontWeight: 600,
      fontFamily: "Poppins",
      lineHeight: "27px",
    },
    verify: {
      color: "#262727",
      fontSize: 30,
      fontWeight: 600,
      fontFamily: "Poppins",
      lineHeight: "27px",
      textAlign: "center",
    },
    text2: {
      color: "#7E8D90",
      fontSize: "16px",
      fontWeight: 500,
      fontFamily: "Poppins",
      height: "48px",
      lineHeight: "27px",
      marginLeft: "119px",
    },
    text1: {
      color: "#7E8D90",
      fontSize: "16px",
      fontWeight: 500,
      height: "48px",
      fontFamily: "Poppins",
      lineHeight: "27px",
    },
    text2: {
      color: "#7E8D90",
      fontSize: "20px",
      fontWeight: 500,
      fontFamily: "Poppins",
      lineHeight: "27px",
    },
    heading: {
      fontWeight: 400,
      fontSize: "32px",
    },
    innerContainer: {
      marginTop: theme.spacing(7),
      marginBottom: theme.spacing(1),
      minHeight: "40px",
    },
    divider: {
      borderColor: "#E5E5E5",
      height: "477px",
      width: "1px",
      borderWidth: "1px",
    },
    Container: {
      width: "967px",
      height: "477px",
      borderRadius: "10px",
      borderWidth: "1px",
      alignItems: "center",
      borderColor: "#7E8D90",
      marginTop: theme.spacing(3),
    },
    fileNameContainer: {
      height: "40px",
      borderRadius: "10px",
      borderWidth: "1px",
      alignItems: "center",
      borderColor: "#7E8D90",
      marginLeft: theme.spacing(2),
    },
    buttonLeft: {
      width: "214px",
      height: "48px",
      borderRadius: "20px",
      marginTop: "21px",
      backgroundColor: "#66CFA4",
    },
    buttonRight: {
      width: "214px",
      height: "48px",
      marginTop: "21px",
      marginLeft: "135px",
      borderRadius: "20px",
      backgroundColor: "#66CFA4",
    },
    dragDrop: {
      width: "117px",
      height: "181px",
    },
    dragDropRight: {
      width: "117px",
      height: "181px",
      marginTop: "-56px",
      marginLeft: "177px",
    },
    qrscan: {
      width: "400px",
      height: "400px",
      borderRadius: "10px",
      borderWidth: "1px",
      alignItems: "center",
      borderColor: "#7E8D90",
      marginTop: theme.spacing(3),
    },
    uploadIcon: {
      width: "40px",
      height: "35px",
      marginRight: "34px",
    },
    uploadContainer: {
      marginLeft: "85px",
      marginTop: "27.15px",
    },
    buttonText: {
      color: "#ffffff",
      fontFamily: "Poppins",
      fontWeight: 600,
      paddingTop: "12px",
      fontSize: "16px",
      textAlign: "center",
      lineHeight: "24px",
    },
    fileName: {
      color: "#7E8D90",
      fontFamily: "Poppins",
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "24px",
      paddingRight: "10px",
      paddingLeft: "18px",
    },
    verificationLabel: {
      color: "#66CFA4",
      fontFamily: "Poppins",
      fontWeight: 600,
      fontSize: "30px",
      lineHeight: "45px",
      textAlign: "center",
    },
    surrenderLabel: {
      color: "#f03737",
      border: "2px solid #f03737",
      borderRadius: "5px",
      padding: "5px 12px",
      fontFamily: "Poppins",
      fontWeight: 600,
      fontSize: "15px",
      textAlign: "center",
    },
    subheading: {
      fontSize: "20px",
      fontWeight: 500,
      fontFamily: "Poppins",
      lineHeight: "17.9px",
      color: "#262727",
      marginRight: "10px",
    },
    sideHeading: {
      fontSize: "20px",
      fontWeight: 500,
      fontFamily: "Poppins",
      lineHeight: "17.9px",
      color: "#66CFA4",
    },
    rootList: {
      alignItems: "center",
      justifyContent: "center",
      display: "flex",
    },
    labelFLex: {
      alignItems: "center",
      marginTop: "40px",
      height: "40px",
      justifyContent: "space-around",
      display: "flex",
    },
    tick: {
      width: "33.33px",
      height: "33.33px",
    },
    listHeading: {
      fontSize: "17px",
      fontWeight: 500,
      fontFamily: "Poppins",
      lineHeight: "23px",
      color: "#262727",
    },
    listHeadingColor: {
      fontSize: "17px",
      fontWeight: 500,
      fontFamily: "Poppins",
      lineHeight: "15.21px",
      color: "#66CFA4",
      marginLeft: "5px",
    },
    listHeadingColorMain: {
      fontSize: "17px",
      fontWeight: 500,
      paddingTop: "2px",
      fontFamily: "Poppins",
      lineHeight: "15.21px",
      color: "#66CFA4",
    },
    listHeadingColor: {
      fontSize: "17px",
      fontWeight: 500,
      fontFamily: "Poppins",
      lineHeight: "15.21px",
      color: "#66CFA4",
      marginLeft: "5px",
    },
    listHeadingColorMain: {
      fontSize: "17px",
      fontWeight: 500,
      paddingTop: "2px",
      fontFamily: "Poppins",
      lineHeight: "15.21px",
      color: "#66CFA4",
    },
    notvalid: {
      fontSize: "20px",
      fontWeight: 500,
      fontFamily: "Poppins",
      lineHeight: "15.21px",
      color: "#262727",
      textAlign: "center",
    },
    button: {
      width: "306px",
      height: "48px",
      backgroundColor: "#66CFA4",
      borderRadius: "20px",
      alignItems: "center",
      marginBottom: "50px",
      justifyContent: "center",
    },
    buttonModal: {
      width: "183px",
      height: "36px",
      backgroundColor: "#66CFA4",
      borderRadius: "20px",
      alignSelf: "center",
      marginTop: "50px",
      alignItems: "center",
      justifyContent: "center",
    },
    buttonModal: {
      width: "183px",
      height: "36px",
      backgroundColor: "#66CFA4",
      borderRadius: "20px",
      alignSelf: "center",
      marginTop: "50px",
      alignItems: "center",
      justifyContent: "center",
    },
    anotherDoc: {
      fontSize: "16px",
      fontWeight: 600,
      fontFamily: "Poppins",
      lineHeight: "24px",
      color: "#ffffff",
      paddingTop: "12px",
      textAlign: "center",
    },
    transferDoc: {
      fontSize: "12px",
      fontWeight: 600,
      fontFamily: "Poppins",
      lineHeight: "18px",
      color: "#ffffff",
      textAlign: "center",
    },
    transferDoc: {
      fontSize: "12px",
      fontWeight: 600,
      fontFamily: "Poppins",
      lineHeight: "18px",
      color: "#ffffff",
      textAlign: "center",
    },
    verifyText: {
      fontSize: "18px",
      fontWeight: 600,
      textDecoration: "none",
      fontFamily: "Poppins",
      lineHeight: "27px",
      color: "#66CFA4",
    },
    loginText: {
      fontSize: "18px",
      textDecoration: "none",
      fontWeight: 600,
      fontFamily: "Poppins",
      lineHeight: "27px",
      color: "#262727",
    },
    download: {
      width: "20px",
      height: "20px",
      marginLeft: "10px",
    },
    labelName: {
      paddingRight: "10px",
      fontSize: "17px",
      fontWeight: 500,
      fontFamily: "Poppins",
      lineHeight: "15.21px",
      cursor: "pointer",
    },
    table: {
      minWidth: 700,
    },
    table: {
      minWidth: 700,
    },
    labelActive: {
      color: "#66CFA4",
      paddingRight: "10px",
      fontSize: "17px",
      fontWeight: 500,
      fontFamily: "Poppins",
      lineHeight: "15.21px",
      cursor: "pointer",
    },
    notThere: {
      color: "#000000",
      fontFamily: "Poppins",
      fontWeight: 600,
      fontSize: "30px",
      lineHeight: "45px",
      paddingTop: "150px",
      textAlign: "center",
    },
    endrosmentStyle: {
      color: "#7E8D90",
      fontFamily: "Poppins",
      fontWeight: 600,
      fontSize: "12px",
      lineHeight: "18px",
      textDecorationLine: "underline",
      marginLeft: "5px",
      cursor: "pointer",
    },
    benef: {
      color: "#74BCFF",
      fontFamily: "Poppins",
      fontWeight: 500,
      fontSize: "11px",
      lineHeight: "16.5px",
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      backgroundColor: "#ffffff",
      borderRadius: "8px",
      outline: "none",
      padding: theme.spacing(2, 4, 3),
    },
    innerModal: {
      display: "flex",
      justifyContent: "space-around",
    },
    endrosmentChain: {
      textAlign: "center",
      color: "#424343",
      fontFamily: "Poppins",
      fontWeight: 600,
      fontSize: "20px",
      lineHeight: "30px",
      marginTop: "20px",
      marginBottom: "20px",
    },
    circle: {
      width: "13px",
      height: "13px",
      borderRadius: "6.5px",
      backgroundColor: "#66CFA4",
    },
    circle2: {
      width: "13px",
      height: "13px",
      marginRight: "-20px",
      borderRadius: "6.5px",
      backgroundColor: "#66CFA4",
    },
    controls: {
      margin: theme.spacing(1),
    }
  };
});

export default function VerifyDocumentByToken() {
  const { token } = useParams();

  const [openNotification, setOpenNotification] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);
  const [alertType, setAlertType] = useState("error");

  const [templates, setTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState("");

  const [doc, setDoc] = useState([]);

  const originalData = getData(doc);
  const attachments = originalData?.attachments;
  const hasAttachments = attachments ? attachments.length > 0 : false;
  const childRef = React.useRef();
  const updateTemplates = useCallback((templateList) => {
    // filter all templates that are renderable currently
    let templatesModified = templateList.filter((item) => {
      return (
        (item.type === "custom-template" && item.label !== "Default") ||
        item.type === "application/pdf" ||
        !item.type
      ); // !item.type caters to renderers that still has decentralized-renderer-react-components dependency at <2.3.0, where type does not exists
    });

    templatesModified = templateList.filter((item) => {
      switch (item.id) {
        case 'covering-letter-template':
          item.label = "Cover";
          break;
        case 'attachment-0':
          item.label = "Preview";
          break;
        default:
          break;
      }
      return item;
    });

    // set modified templates
    setTemplates(templatesModified);
    setSelectedTemplate(templatesModified[0].id);
  }, []);

  const [tokenId, setTokenId] = useState("");
  const [tokenRegistryAddress, setTokenRegistryAddress] = useState("");
  const [endrosmentChainData, setEndrosmentChain] = useState([]);
  const [endrosloader, setEndrosLoader] = useState(true);

  const [loading, setLoading] = useState(false);
  const [verifyResponse, setVerifyResponse] = useState([]);

  const [verifyDocRes, setVerifyDocRes] = useState({});

  const [open, setOpen] = React.useState(false);
  const [visible, setVisible] = useState(false);
  const [verfieidDoc, setVerifiedDoc] = useState(false);

  const [valid, setValid] = useState(true);
  const [exist, setExist] = useState(false);

  const [verificationScreen, setVerificationScreen] = useState(false);

  const handleOpen = () => {
    if (endrosloader === true) {
      return (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          style={{ height: "50%" }}
        >
          <CircularProgress size={30} color="inherit" />
        </Box>
      );
    } else {
      setOpen(true);
    }
  };

  const handleNotificationClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenNotification(false);
  };

  const showError = (msg) => {
    setAlertMessage(msg);
    setAlertType("error");
    setOpenNotification(true);
  }

  const EndrosmentChainAPI = (document) => {
    setTokenId(`0x${utils.getAssetId(document)}`);
    setTokenRegistryAddress(
      utils.isTransferableAsset(document) === true
        ? utils.getIssuerAddress(document)[0]
        : "");
    setVisible(utils.isTransferableAsset(document));

    getEndrosmentChain({
      tokenRegistryAddress: utils.isTransferableAsset(document) === true
        ? utils.getIssuerAddress(document)[0]
        : "",
      tokenId: `0x${utils.getAssetId(document)}`,
    })
      .then((res) => {
        console.log("Response", res.data.endorsementChain);
        setEndrosLoader(false);
        setEndrosmentChain(res.data.endorsementChain);
      })
      .catch((err) => {
        console.log("Error I get--->", err.message);
      });
  };

  const getTokenDetails = () => {
    accessTokenDetails({ token }).then(res => {
      let response = res?.data;
      if (response.success.toString() === 'true') {
        console.log(response);
        let tokenData = JSON.parse(response?.data);

        let verifyDocRes = tokenData?.verifyResponse;
        let wrappedDocument = tokenData?.wrappedDocument;

        console.log(tokenData);

        setVerifyDocRes(verifyDocRes);
        if (verifyDocRes.valid === true) {
          setDoc(wrappedDocument);
          try {
            EndrosmentChainAPI(wrappedDocument);
          } catch (error) {
            console.log("EndrosmentChainAPI");
            console.log(error);
          }
          setVerifyResponse({
            data: verifyDocRes
          });
          setExist(true);
          setLoading(false);
          setVerificationScreen(true);
          setValid(true);
          setVerifiedDoc(true);
        } else {
          setDoc([]);
          setLoading(false);
          setVerifyResponse([]);
          setExist(false);
          setVerificationScreen(true);
        }
      } else {
        showError(response?.message);
      }
    }).catch(err => {
      showError("Failed to complete your request");
    });
  }

  useEffect(() => {
    if (token != null) {
      getTokenDetails();
    }
  }, [token]);

  const handleClose = () => {
    setOpen(false);
  };

  const classes = useStyles();

  return (
    <>
      <div
        className="App"
        style={{
          minHeight: "100vh",
          width: `100%`,
          marginLeft: `0px`,
        }}
      >
        <Snackbar
          open={openNotification}
          autoHideDuration={6000}
          onClose={handleNotificationClose}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert onClose={handleNotificationClose} severity={alertType}>
            {alertMessage}
          </Alert>
        </Snackbar>

        {exist && (
          <div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography className={classes.verificationLabel}>
                Document verification complete!
              </Typography>
              <img src={download} className={classes.download} />
            </div>

            {
              verifyDocRes?.isSurrendered ? <>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography className={classes.surrenderLabel}>
                    Surrendered To Issuer
                  </Typography>
                </div>
              </> : null
            }

            {open && (
              <EndrosmentChain
                handleCloseModal={handleClose}
                openModal={open}
                endorsementChain={endrosmentChainData}
                verifyResponse={verifyResponse}
                tokenId={tokenId}
                tokenRegistryAddress={tokenRegistryAddress}
              ></EndrosmentChain>
            )}
            {visible && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                {/* <Typography className={classes.subheading} noWrap>
              Created by
            </Typography>
            <Typography className={classes.sideHeading} noWrap>
              TRUDOC.TK
            </Typography> */}
                {/* Adding A new api call to update the uploaded document */}
                {true && (
                  <Button
                    className={classes.controls}
                    variant="contained"
                    color="primary"
                    onClick={handleOpen}
                    disableElevation
                  >
                    VIEW ENDORSEMENT CHAIN
                  </Button>
                )}
              </div>
            )}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {verifyResponse && (
                <List
                  style={{
                    display: "flex",
                  }}
                >
                  {[0, 1, 2].map((value) => {
                    return (
                      <ListItem key={value}>
                        <ListItemAvatar>
                          <Avatar src={tick} className={classes.tick} />
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            value === 2 ? (
                              <Typography className={classes.listHeading}>
                                Document has not been tampered with
                              </Typography>
                            ) : value === 1 ? (
                              <Typography className={classes.listHeading}>
                                Document verified on blockchain
                              </Typography>
                            ) : (
                              verifyResponse?.data.data.map((res) => {
                                return res?.data && res.data[0] ? (
                                  <div
                                    style={{
                                      display: "flex",
                                    }}
                                  >
                                    <Typography className={classes.listHeading}>
                                      Document has been issued by
                                      <Typography
                                        className={classes.listHeadingColorMain}
                                      >
                                        {res?.data[0].location}
                                      </Typography>
                                    </Typography>
                                  </div>
                                ) : null;
                              })
                            )
                          }
                        />
                      </ListItem>
                    );
                  })}
                </List>
              )}
            </div>
          </div>
        )}
        {exist ? (
          <div>
            <div className={classes.labelFLex}>
              {templates.map(({ id, label, type }) => (
                <div key={id}>
                  <div
                    onClick={() => {
                      setSelectedTemplate(id);
                    }}
                  >
                    <Typography
                      className={
                        id === selectedTemplate
                          ? classes.labelActive
                          : classes.labelName
                      }
                    >
                      {label}
                    </Typography>
                  </div>
                </div>
              ))}
              {hasAttachments && (
                <div>
                  <div
                    className={classes.rootList}
                    onClick={() => {
                      setSelectedTemplate("attachmentTab");
                    }}
                  >
                    <div
                      className={
                        selectedTemplate === "attachmentTab"
                          ? classes.labelActive
                          : classes.labelName
                      }
                    >
                      Attachment
                    </div>
                    {/* <div  
                    className={
                      selectedTemplate === "attachmentTab"
                        ? classes.labelActive
                        : classes.labelName
                    }
                    data-testid="attachment-number"
                  >
                    {attachments && attachments.length}
                  </div> */}
                  </div>
                </div>
              )}
            </div>
            {attachments && selectedTemplate === "attachmentTab" && (
              <div>
                {attachments.map(({ filename, data, type }, index) => (
                  <div key={index} style={{ minHeight: "30vh" }}>
                    <AttachmentLink filename={filename} data={data} type={type} />
                  </div>
                ))}
              </div>
            )}
            {selectedTemplate !== "attachmentTab" && (
              <div>
                <DecentralisedRendererContainer
                  rawDocument={doc}
                  updateTemplates={updateTemplates}
                  selectedTemplate={selectedTemplate}
                  ref={childRef}
                />
              </div>
            )}
          </div>
        ) : (
          <div
            style={{
              minHeight: "50vh",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography className={classes.notThere}>
              Your document doesn't exist in blockchain!!!
            </Typography>
          </div>
        )}


      </div>
    </>
  )

}
