import React from 'react'
import { MenuItem, Select, InputBase, FormControl, Grid, TextField, Typography, makeStyles, withStyles, Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      padding: 20
    },
    contentHeight: {
      minHeight: '500px'
    },
    field: {
      borderRadius: 10,
      color: theme.palette.admin.main,
    },
    textFieldInput: {
      color: theme.palette.admin.main,
    },
    button: {
      color: theme.palette.admin.main,
    },
    subHeading: {
      fontSize: 17,
      fontWeight: '500',
    }
  }
});

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 10,
    position: 'relative',
    backgroundColor: '#fff',
    border: '1px solid #BF85FA',
    fontSize: 18,
    padding: '18px 14px',
    color: theme.palette.admin.main,
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      backgroundColor: '#fff',
      borderColor: theme.palette.admin.main,
      color: theme.palette.admin.main,
      borderRadius: 10,
    },
  },
}))(InputBase);

const CssTextField = withStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      borderColor: "#BF85FA",
      color: "#BF85FA",
      "& fieldset": {
        color: "#BF85FA",
        borderColor: "#BF85FA",
      },
      "&:focus": {
        color: "#BF85FA",
        borderColor: "#BF85FA",
      },
      "&:hover fieldset": {
        borderColor: "#BF85FA",
      },
      borderRadius: 10,
    },
  },
})(TextField);


const AddDomainStep1 = (props) => {
  const classes = useStyles();
  const { domainName, setDomainName } = props
  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      spacing={5}
    >
      <Grid
        container
        direction="row"
        alignItems="center"
        className={classes.root}
        spacing={5}
      >
        <Grid item xs={3} align="right">
          <Typography className={classes.subHeading}>Domain Address</Typography>
        </Grid>
        <Grid item xs={9} align="center">
          <CssTextField
            label="your_domain.com"
            className={classes.field}
            variant="outlined"
            fullWidth
            InputLabelProps={{ className: classes.textFieldInput }}
            InputProps={{ className: classes.textFieldInput }}
            value={domainName}
            onChange={(e) => {
              setDomainName(e.target.value)
            }}
          />
        </Grid>
      </Grid>

      {/* <Grid
        container
        direction="row"
        alignItems="center"
        className={classes.root}
        spacing={5}
      >
        <Grid item xs={3} align="right">
          <Typography className={classes.subHeading}>Wallets</Typography>
        </Grid>
        <Grid item xs={7} align="left">
          <FormControl variant="outlined" className={classes.formControl} fullWidth>
            <Select input={<BootstrapInput />}>
              <MenuItem value={10}>Ten</MenuItem>
              <MenuItem value={20}>Twenty</MenuItem>
              <MenuItem value={30}>Thirty</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={2} align="left">
          <Button className={classes.button}>Connect</Button>
        </Grid>
      </Grid> */}
    </Grid>
  )
}

export default AddDomainStep1;
