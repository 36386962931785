import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Chip from "@material-ui/core/Chip";
import { getFirstChar } from "../../utils/Generic";
import { SelectCustomerAction } from "../../redux/actions/Customers.action";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    width: "100%",
    minWidth: 200,
    maxWidth: "100%",
    textAlign: "center",
    padding: 20,
    margin: "0 auto",
    borderWidth: 2,
    borderColor: theme.palette.primary.main,
    cursor: "pointer",
    overflow: "hidden",
    height: "auto",
  },
  title: {
    marginTop: 10,
  },
  position: {
    marginTop: 0,
    marginBottom: 10,
  },
  avatar: {
    fontWeight: 500,
    fontSize: "24px",
    margin: "0 auto",
    width: theme.spacing(8),
    height: theme.spacing(8),
    textTransform: "uppercase"
  },
  banner: {
    position: "absolute",
    top: "-15px",
    left: "-50px",
    backgroundColor: "red",
    height: 50,
    width: 100,
    transform: "rotate(135deg)",
  },
}));

export default function CustomerCard({ data, isSelected }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const showDetails = () => {
    dispatch(SelectCustomerAction(data));
  };

  useEffect(() => {
    if (isSelected)
      dispatch(SelectCustomerAction(data));
  }, [])


  return (
    <Paper
      elevation={1}
      className={classes.root}
      onClick={showDetails}
      variant={isSelected ? "outlined" : "elevation"}
    >
      {data.role === 'admin' && <div className={classes.banner}></div>}
      <Avatar className={classes.avatar}>
        {getFirstChar(data?.full_name)}
      </Avatar>
      <Typography
        className={classes.title}
        color="textPrimary"
        variant="h6"
        component="h6"
      >
        {data?.full_name}
      </Typography>
      <Typography
        className={classes.position}
        color="textSecondary"
        component="p"
        variant="body2"
      >
        {data?.email}
      </Typography>
    </Paper>
  );
}
