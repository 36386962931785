import { useState, Fragment, forwardRef, useEffect } from 'react';
import { Slide, Typography, makeStyles, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, CircularProgress } from '@material-ui/core';
import { getPdf, getPdfImages, signPDFDoc } from "../../services/api.service";
import { LoaderSpinner } from "@govtechsg/tradetrust-ui-components";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { signDoc, downloadTt } from '../../services/api.service';
import { useAuth } from '../../hooks';
import { getDocuments } from "../../services/other.service";
import { sidePanelFilterMapping } from '../../constants';
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import SidePanelAction from "../../redux/actions/Sidepanel.action";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from '@material-ui/lab/Alert';
import PdfImagesViewer from './PdfImagesViewer';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="top" ref={ref} {...props} />;
});
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => {
  return {
    dialogRoot: {
      width: '75%',
      borderRadius: 50,
      margin: '0 auto',
    },
    loader: {
      position: "absolute",
      left: "50%",
      top: "50%"
    },
    // modalTitleFlex:{
    //   display:"flex",
    //   justifyContent:"space-between"
    // },
    contentHeight: {
      // minWidth:'100%',
      minHeight: '350px',
      // maxWidth: '100%',
      margin: '0 auto'
    },
    title: {
      paddingTop: 20,
      textAlign: 'center',
      fontWeight: '600',
      textTransform: 'uppercase',
      letterSpacing: 0.5,
      wordSpacing: 2,
    },
    stepText: {
      fontWeight: '600',
      paddingBottom: 30,
      textAlign: 'center',
      color: theme.palette.admin.main,
      letterSpacing: 1,
    },
    actionBar: {
      justifyContent: 'center',
      marginBottom: 50
    },
    nextButton: {
      width: 220,
      height: 50,
      borderRadius: 25,
      color: '#fff',
      letterSpacing: 1,
      backgroundColor: theme.palette.admin.main,
      '&:hover': {
        backgroundColor: theme.palette.admin.main,
      }
    },
    draftButton: {
      width: 220,
      height: 50,
      borderRadius: 25,
      color: '#fff',
      letterSpacing: 1,
      backgroundColor: 'gray',
      '&:hover': {
        backgroundColor: 'gray',
      }
    },
    // titleRed:{
    //   color:"red"
    // },
    // titleGreen:{
    //   color:"green"
    // }
  }
});

const EDocumentPdfViewer = ({ data, open, handleClose, pdfName, isEdocs }) => {
  const [basePdf, setBasePdf] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [loading, setLoading] = useState();
  const { user } = useAuth();
  const { filterType } = useParams();
  const dispatch = useDispatch();
  const [showToast, setShowToast] = useState();
  const [signatures, setSignatures] = useState([]);
  const [signLocation, setSignLocation] = useState([]);
  const [signForImage, setSignForImage] = useState();
  const [signIdForImage, setSignIdForImage] = useState("");

  const handleToastClose = () => {
    setShowToast('');
  }

  useEffect(() => {
    getPdfImages({ docId: data.id, email: user.email })
      .then((res) => {
        setBasePdf(res.data.data.img);
        setSignatures(res.data.data.signature)
        setSignLocation(res.data.data.signLocation)
        setIsLoading(false);
        console.log("res.data.data.img:", res.data.data.img);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  const handleSignDocument = (type = "SIGNED") => {
    let valid = true;
    basePdf.map((e) => {
      e.images.map((img) => {
        if (img.signed == false) {
          valid = false;
        }
      })
    })
    if (valid) {
      if (data) {
        setLoading(type);
        signPDFDoc({
          document_id: data.id,
          email: user.email,
          status: type,
          signIdForImage,
          basePdf
        }).then(res => {
          if (res && res?.status === 200 && res?.data) {
            setShowToast(res?.data?.message);
            setLoading(null);
            setTimeout(() => {
              updateSidePanel(true)
            }, 1000);
          }
        })
      }
    } else {
      alert("Please confirm your signature on all pages");
    }

  }
  const updateSidePanel = () => {
    getDocuments({
      email: user.email,
      condition: sidePanelFilterMapping[filterType]
    }).then((res) => {
      if (res && res?.status === 200 && res?.data)
        dispatch(SidePanelAction([filterType], [...res?.data]));
    });
    handleClose();
  }

  const handleChange = (event) => {
    console.log("handleChange:", window.atob(event.target.id))
    setSignForImage(event.target.id);
    setSignIdForImage(event.target.value);
  };
  const classes = useStyles();
  console.log("signForImage:", signForImage, "signIdForImage:", signIdForImage);
  return (
    <Fragment> <Dialog
      fullWidth={true}
      maxWidth='md'
      open={open}
      onClose={handleClose}
      className={classes.dialogRoot}
      keepMounted={false}
      TransitionComponent={Transition}
      PaperProps={{
        style: { borderRadius: 20 }
      }}
    >
      <DialogTitle className="modalTitleFlex">
        <div style={{ display: "flex", overflow: "auto" }}>
          {signatures.length > 0 ? signatures.map((e) => {
            return (<> <label><input type="radio" value={e.id} id={e.data} name="sign" onChange={handleChange} /> <div dangerouslySetInnerHTML={{ __html: window.atob(e.data) }}></div> </label> <br /> </>)
          }) : <label>Please Add Signatures to continue signing.</label>}
        </div>
        {signIdForImage != "" &&
          <Typography variant='p' className="titleGreen">
            <Button onClick={() => handleSignDocument("SIGNED")}>
              Sign   {loading === "SIGNED" ? <CircularProgress color="primary" size={20} /> : <CheckCircleOutlineIcon
                style={{
                  marginLeft: "10px"
                }} />}
            </Button>
          </Typography>}
      </DialogTitle>
      <DialogContent className={classes.contentHeight} fullHeight={true} maxHeight={"xl"}>
        {isLoading ? <CircularProgress color="primary" size={20} className={classes.loader} /> :
          <>
            <PdfImagesViewer pdfListToRender={basePdf} signLocation={signLocation} signForImage={signForImage} />
          </>}
      </DialogContent>

    </Dialog>
      <Snackbar open={Boolean(showToast)} autoHideDuration={6000} onClose={() => handleToastClose()}>
        <Alert onClose={() => handleToastClose()} severity="success">
          {showToast}
        </Alert>
      </Snackbar></Fragment>
  );
}

export default EDocumentPdfViewer;