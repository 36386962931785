import { AWAITING_OTHER_SIGN, AWAITING_MY_SIGN, ISSUED, REJECTED, EXPIRY, MY_WALLET } from '../redux.constants';

const initialState = {
  awaiting_my_sign: [],
  awaiting_others_sign: [],
  completed: [],
  declined: [],
  expired: [],
};

const SidePanelReducer = (state = initialState, action) => {
  switch (action.type) {
    case AWAITING_OTHER_SIGN:
      return { 
        ...state, 
        awaiting_others_sign: [
          ...action.payload
        ] 
      };
    case AWAITING_MY_SIGN:
      return { 
        ...state, 
        awaiting_my_sign: [
          ...action.payload
        ] 
      };
    case ISSUED:
      return { 
        ...state, 
        completed: [
          ...action.payload
        ] 
      };
    case REJECTED:
      return { 
        ...state, 
        declined: [
          ...action.payload
        ] 
      };
    case EXPIRY:
      return { 
        ...state, 
        expired: [
          ...action.payload
        ] 
      };
    case MY_WALLET:
      return {
        condition: 'issued',
        issued: [
          ...action.payload
        ] 
      }
    default:
      return state;
  }
}

export default SidePanelReducer;