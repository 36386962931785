import React, { useCallback, useState, useEffect, createRef, useRef } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import { useParams } from "react-router-dom";
import {
  TextField,
  CircularProgress,
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogContent,
  Slide,
  DialogActions,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { isNotEmpty, isValidEmailAddress, pdfBase64ToBlob } from "./../utils/Generic";
import MUIDataTable from "mui-datatables";
import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  LinearProgress,
  NativeSelect,
  Radio,
  RadioGroup,
  Snackbar,
  SvgIcon,
  Typography,
} from "@material-ui/core";
import DragDrop4 from "../static/svg/drag-drop-4.svg";
import { useDropzone } from "react-dropzone";
import { Alert, Autocomplete, createFilterOptions } from "@material-ui/lab";
import {
  uploadDoc,
  signDoc,
  getSignatories,
  uploadEDoc,
  processEDoc,
  getUserDetails,
  getCorporateMembers,
} from "../services/api.service";
import { useHistory, useLocation } from "react-router";
import { useModal } from "../hooks";
import { useAuth } from "../hooks";
import VerifyModal from "./auth/Verification";
import Pdf from "../static/svg/pdf.svg";
import CreateSignature from "../utils/WebViewer/CreateSignature";
import { getDocumentDetails, getDocumentPreview } from "../services/dms.service";
import { Cancel } from "@material-ui/icons";
import Toast, { toastInitialValues } from "../utils/Toast";
import { MessageConstants } from "../utils/MessageConstants";
import DropBoxChooser from "./DropBoxChooser";
import GoogleDriveChooser from "./GoogleDriveChooser";
import { getFileData } from "../utils/picker";
import DmsChooser from "./DmsChooser";
import useQuery from "../hooks/useQuery";

const useStyles = makeStyles((theme) => ({
  dialogRoot: {
    width: "600px",
    borderRadius: 50,
    margin: "0 auto",
  },
  contentHeight: {
    minHeight: "100px",
    maxWidth: "100%",
    margin: "0 auto",
    minWidth: "300px",
  },
  root: {
    width: "100%",
    backgroundColor: "transparent",
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  paper1: {
    display: "flex",
    flexWrap: "wrap",
    backgroundColor: "transparent",
    "& > *": {
      margin: theme.spacing(3),
      padding: theme.spacing(2),
      width: "100%",
      textAlign: "center",
    },
  },

  paper2: {
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(3),
      padding: theme.spacing(2),
      width: "100%",
    },
  },
  paper3: {
    backgroundColor: "transparent",
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(3),
      padding: theme.spacing(2),
      width: "100%",
      height: 320,
    },
  },
  dropIcon: {
    width: "150px",
    padding: theme.spacing(2),
  },
  icon: {
    margin: theme.spacing(1),
  },
  formControl: {
    minWidth: "100%",
  },
  contentMargin: {
    marginTop: theme.spacing(3),
  },
  content: {
    display: "flex",
    width: `calc(100%)`,
    minHeight: "80vh",
    background: "transparent",
  },
  paper: {
    padding: theme.spacing(1),
    margin: theme.spacing(2),
    textAlign: "center",
  },
  text2: {
    color: "#7E8D90",
    fontSize: "16px",
    fontWeight: 500,
    fontFamily: "Poppins",
    height: "48px",
    lineHeight: "27px",
  },
  text2: {
    color: "#7E8D90",
    fontSize: "20px",
    fontWeight: 500,
    fontFamily: "Poppins",
    lineHeight: "27px",
  },
  flex: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: theme.spacing(1),
  },
  heading: {
    // width: "643px",
    // height: "45px",
    left: "498px",
    top: "253px",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "30px",
    lineHeight: "150%",
    letterSpacing: "0.1em",
    marginBottom: 20,
  },
  dialogRoot: {
    width: '700px',
    borderRadius: 50,
    margin: '0 auto',
  },
  contentHeight: {
    minHeight: '100px',
    maxWidth: '100%',
    margin: '0 auto',
    minWidth: '300px'
  },
  title: {
    paddingTop: 20,
    textAlign: 'center',
    fontWeight: '600',
    textTransform: 'uppercase',
    letterSpacing: 0.5,
    wordSpacing: 2,
    paddingBottom: 10
  },
  stepText: {
    fontWeight: '600',
    paddingBottom: 30,
    textAlign: 'center',
    color: theme.palette.admin.main,
    letterSpacing: 1,
  },
  actionBar: {
    justifyContent: 'center',
    marginBottom: 50
  },
  nextButton: {
    width: 220,
    height: 50,
    borderRadius: 25,
    color: '#fff',
    letterSpacing: 1,
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    }
  },
  draftButton: {
    width: 220,
    height: 50,
    borderRadius: 25,
    letterSpacing: 1,
    backgroundColor: '#fff',
    color: "#6a6a6a",
    border: '1px solid gray',
    '&:hover': {
      backgroundColor: "#fff",
      color: '#363636',
      border: '1px solid #464646'
    }
  },
  closeIcon: {
    position: 'absolute',
    right: '0',
    padding: '10px'
  },
  buttonTextCancel: {
    fontWeight: "bold",
    fontSize: 16,
    letterSpacing: "0.15em",
  },
  buttonRight: {
    marginTop: "20px",
    marginBottom: "10px",
    border: '1px solid gray',
    backgroundColor: '#fff',
    width: 150,
    height: 45,
    color: "#6a6a6a",
    borderRadius: 25,
    '&:hover': {
      backgroundColor: "#fff",
      color: '#363636',
      border: '1px solid #464646'
    }
  },
  searchContainer: {
    marginTop: "18px",
    width: "calc(100% - 46px)",
    padding: "0 23px",
  },
  searchContainerTextField: {
    width: "100%"
  }
}));

const CssTextField = withStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#66CFA4",
      },
      "&:hover fieldset": {
        borderColor: "#66CFA4",
      },
      borderRadius: 50,
    },
  },
})(TextField);

const usersFilter = createFilterOptions();

export default function VerticalLinearStepperEdoc(props) {
  const [file, setFiles] = useState(null);
  const [selectionModel, setSelectionModel] = React.useState([]);
  const [openNotification, setOpenNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState(false);
  const [progress, setProgress] = useState(false);

  const history = useHistory();
  const location = useLocation();


  const { isOpen, onOpen } = useModal();
  const { user } = useAuth();
  const [activeStep, setActiveStep] = useState(0);

  const [defaultUsers, setDefaultUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [signatoriesDataList, setSignatoriesDataList] = useState([]);
  const tableRef = useRef(null);

  const columns = ["email", {
    name: "order",
    options: {
      customBodyRender: (value, tableMeta, updateValue) => {
        let { rowData, rowIndex, columnIndex } = tableMeta;
        if (selectionModel?.length && selectionModel.indexOf(rowIndex) !== -1) {
          return (
            <div>
              <select value={rowData[columnIndex] || selectionModel.indexOf(rowIndex) + 1} onChange={(event) => {
                let rowsData = [...signatoriesDataList]
                rowsData[rowIndex].order = event.target.value
                setSignatoriesDataList([...rowsData]);
              }}>
                {
                  selectionModel?.map((value, index) => {
                    return <option value={index + 1}>{index + 1}</option>
                  })
                }
              </select>
            </div>
          );
        } else {
          return null;
        }
      }
    }
  }];
  const [rows, setRows] = useState([]);
  const [pdfUpdatedList, setPdfUpdatedList] = useState([]);
  const [isloading, setIsloading] = useState(false);
  const [isloadingFinal, setIsloadingFinal] = useState(false);
  const [signatoryList, setSignatoryList] = useState([]);
  const [sendFile, setSendFile] = useState(false);
  const [uploadFile, setUploadFile] = useState(false);
  let [replaceFile, setReplaceFile] = useState(false);
  const [replaceLoading, setReplaceLoading] = useState(false);
  const [toast, setToast] = useState(toastInitialValues);

  const [fileName, setFileName] = useState(null);

  let { documentId } = useParams();

  const [loadingDocument, setLoadingDocument] = useState(false);
  const [documentData, setDocumentData] = useState(null);

  const [newUpload, setNewUpload] = useState(true);

  let [replacedName, setReplacedName] = useState(null);
  const [replaceConfirmModal, setReplaceConfirmModal] = useState(false);

  const [showNewNameModel, setShowNewNameModel] = useState(false);

  let [submitFile, setSubmitFile] = useState(null);
  let [submitSignatory, setSubmitSignatory] = useState(null);
  let [submitAnnotationId, setSubmitAnnotationId] = useState(null);
  let [submitSignPriority, setSubmitSignPriority] = useState(null);

  let query = useQuery();

  useEffect(() => {
    console.log("documentId", documentId);
    if (isNotEmpty(documentId)) {

      setLoadingDocument(true);

      getDocumentPreview({
        documentId: documentId
      }).then((res) => {
        let document = res?.data?.document;
        setLoadingDocument(false);
        setDocumentData(document);
        setNewUpload(false);
        setFiles(pdfBase64ToBlob(res?.data?.fileData?.data));
        setFileName(document?.name);
      }).catch(err => {
        console.log(err);
        setLoadingDocument(false);
      });

    } else {
      setDocumentData(null);
      setLoadingDocument(false);
      setNewUpload(true);
    }
  }, [documentId]);

  useEffect(() => {
    getCorporateMembers()
      .then((res) => {
        let data = res.data.data;
        setDefaultUsers(data);
        setRows(data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  useEffect(() => {

  }, [location]);

  useEffect(() => {
    return () => setSendFile(false);
  }, []);

  const handleReset = () => {
    setSubmitFile(null);
    setSubmitSignatory(null);
    setSubmitAnnotationId(null);
    setSubmitSignPriority(null);
    // setSignatoryList([])
    // setFiles(null);
    // setFileName(null);
    setSendFile(false);
    setActiveStep(1);
  };

  const closeToast = () => {
    return setToast((prev) => ({
      ...prev,
      isOpen: false,
      isSuccess: false,
      isError: false,
      message: ""
    }));
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handlePreviewNext = () => {
    const signatories = selectionModel.map((value, index) => {
      return {
        email: signatoriesDataList[value].email,
        username: signatoriesDataList[value].email,
        user_id: signatoriesDataList[value].email,
        placeholders: [],
        order: signatoriesDataList[value].order || index + 1
      };
    });
    signatories.sort((a, b) => {
      return a.order - b.order;
    });
    console.log(signatories);
    setSignatoryList(signatories);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const uploadDocument = async (fileBlob, signIds, signPriority) => {
    const t = signatoryList.filter(e => e.user_id in signPriority);

    let sortedSignatories = t;
    sortedSignatories.sort((a, b) => {
      return parseFloat(a.order) - parseFloat(b.order);
    });

    const fileData = new File([fileBlob], fileName, {
      type: "application/pdf",
    });

    submitFile = fileData;
    setSubmitFile(submitFile);

    submitSignatory = sortedSignatories;
    setSubmitSignatory(submitSignatory);

    submitAnnotationId = signIds;
    setSubmitAnnotationId(submitAnnotationId);

    submitSignPriority = signPriority;
    setSubmitSignPriority(submitSignPriority);

    submitUpload();
  };

  const submitUpload = async (overwrite = false) => {
    setIsloading(true);
    uploadEDoc({
      file: submitFile,
      signatory: submitSignatory,
      annotationId: submitAnnotationId,
      signPriority: submitSignPriority,
      overwrite: overwrite,
      replace_name: replacedName,
    }).then((response) => {
      let responseData = response?.data;
      if (isNotEmpty(responseData?.error?.duplicate)) {
        setReplaceConfirmModal(true);
        setIsloading(false);
      } else {
        history.push({ pathname: "/e-doc-success", state: { message: 'E-Document has been sent for signing' } });
      }
    }).catch(error => {
      console.log("error", error);
      handleReset();
      setToast((prev) => ({
        ...prev,
        isOpen: true,
        isError: true,
        message: MessageConstants.FAILED_TO_SEND_EDOC
      }));
      setIsloading(false);
    });
  }

  const keyPress = async (e) => {
    if (e.keyCode == 13) {
      console.log("value", e.target.value);
      let response = await getUserDetails({ email: e.target.value });
      console.log("response", response);
      if (response.data.success == false) {
        setOpenNotification(true);
        setNotificationMessage("No user with this email.");
      } else {
        let row = [...rows];
        let index = row.findIndex(
          (age) => age.user_id == response.data.data.user_id
        );
        if (index != -1) {
          setOpenNotification(true);
          setNotificationMessage("User With Email Already In List!");
        } else {
          setOpenNotification(true);
          setNotificationMessage("User With Email Added To List!");
          row.push(response.data.data);
          setRows(row);
        }
      }
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenNotification(false);
  };

  const options = {
    filter: true,
    filterType: "dropdown",
    tableBodyHeight: "240px",
    responsive: "scroll",
    onRowSelectionChange: (
      currentRowsSelected,
      allRowsSelected,
      rowsSelected
    ) => {
      setSelectionModel(rowsSelected);
    },
    rowsSelected: selectionModel
  };

  const onDrop = useCallback((acceptedFiles) => {
    // Do something with the files
    acceptedFiles.forEach((f) => {
      // console.log(f);
      if (f.type === "application/pdf") {
        const reader = new FileReader();
        reader.onabort = () => console.log("file reading was aborted");
        reader.onerror = () => console.log("file reading has failed");
        reader.onload = () => {
          // Do whatever you want with the file contents
          // const binaryStr = reader.result;
          setFiles(f);
          setFileName(f?.name);
          setNewUpload(true);
          setOpenNotification(true);
          setNotificationMessage("File Uploaded!");
          setTimeout(e => {
            handleNext();
          }, 500);
        };
        reader.readAsText(f);
      } else {
        console.log("file format unaccepted");
        console.log(f);
      }
    });
  }, []);


  const chooserFileSelect = async (data) => {
    if (data != null) {
      let fileData = await getFileData(data);
      if (fileData != null) {
        // console.log("fileData", fileData);
        onDrop([fileData]);
      }
    }
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const firstNextBtnDisableState = () => {
    if (!newUpload && documentData != null) {
      return false;
    }
    return !file || isloading;
  }

  const confirmReplace = (e, replaceIt) => {
    if (replaceIt) {
      setReplaceConfirmModal(false);
      submitUpload(true);
    } else {
      setReplaceConfirmModal(false);
      setShowNewNameModel(true);
      setReplacedName(fileName);
    }
  };

  const hideNewNameModel = () => {
    setShowNewNameModel(false);
  }

  const submitNewNameModel = () => {
    if (isNotEmpty(replacedName)) {
      setShowNewNameModel(false);
      submitUpload(false);
    }
  }

  const addToSignatoriesList = (emailAdd) => {
    console.log(emailAdd);
    if (isNotEmpty(emailAdd)) {
      setSignatoriesDataList([...signatoriesDataList, {
        email: emailAdd
      }]);
      setSelectedUser(null);
    }
  }

  const classes = useStyles();
  return (
    <>
      {toast.isOpen && <Toast message={toast.message} isError={toast.isError} isSuccess={toast.isSuccess} closeToast={closeToast} />}
      {uploadFile && (
        <>
          <Box className={classes.content}>
            <Grid container spacing={0} alignItems="center">
              <Grid item xs={12}>
                <Paper
                  className={`${classes.paper} doc-sign-com-wrp`}
                  elevation={0}
                >
                  <Grid
                    container
                    spacing={2}
                    alignItems="center"
                    justify="center"
                  >
                    <Grid item xs={12} className={classes.flex}>
                      <Typography className={classes.heading} color="primary">
                        {/* nothing here */}
                      </Typography>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </Box>
        </>
      )}
      {!uploadFile && (
        <>
          <div className={classes.root}>
            <Snackbar
              open={openNotification}
              autoHideDuration={6000}
              onClose={handleClose}
              anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            >
              <Alert onClose={handleClose} severity="success">
                {notificationMessage}
              </Alert>
            </Snackbar>
            <Stepper
              activeStep={activeStep}
              orientation="vertical"
              style={{ backgroundColor: "transparent" }}
            >
              <Step color="secondary">
                <StepLabel className="upl-step-label">
                  Upload Document
                </StepLabel>
                <StepContent color="secondary">
                  <div {...getRootProps()}>
                    <input type="file" name="file" {...getInputProps()} />
                    <Grid container spacing={0}>
                      <Grid item xs={12}>
                        <div className={classes.paper1}>
                          <Paper
                            className="upl-wrp-style"
                            elevation={0}
                            style={{ backgroundColor: "transparent" }}
                          >

                            {
                              newUpload ? <>

                                {file ? (
                                  <img
                                    src={Pdf}
                                    alt="TruDoc"
                                    className={classes.dropIcon}
                                  />
                                ) : (
                                  <img
                                    src={DragDrop4}
                                    alt="TruDoc"
                                    className={classes.dropIcon}
                                  />
                                )}
                                <Box m={2}>
                                  {file ? (
                                    <Typography variant="h6">{fileName}</Typography>
                                  ) : (
                                    <Typography className="fOne-doc-text">
                                      Drop a document here to get started
                                    </Typography>
                                  )}
                                </Box>

                              </> : <>

                                <img
                                  src={Pdf}
                                  alt="TruDoc"
                                  className={classes.dropIcon}
                                />

                                <Box m={2}>
                                  <Typography variant="h6">{fileName}</Typography>
                                </Box>

                              </>
                            }

                            <Typography
                              align="center"
                              className={classes.text2}
                            >
                              OR
                            </Typography>

                            <Box m={2}>
                              <Button
                                className="upl-btn mt-0px"
                                variant="contained"
                                color="primary"
                              >
                                {file ? 'Choose Another Document' : 'UPLOAD DOCUMENT'}
                              </Button>
                            </Box>

                            <Box className="upl-icon-list file-choose-icons" mt={2}>

                              <DmsChooser onSelected={chooserFileSelect} />
                              <DropBoxChooser onSelected={chooserFileSelect} />
                              <GoogleDriveChooser onSelected={chooserFileSelect} />

                            </Box>

                          </Paper>
                        </div>
                      </Grid>
                    </Grid>
                  </div>

                  <div className={classes.actionsContainer}>
                    <div>
                      <Button
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        className={`${classes.button} back-upl-btn`}
                      >
                        Back
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                        // onClick={uploadDocument} //----------------upload later
                        className={`${classes.button} next-upl-btn`}
                        disabled={firstNextBtnDisableState()}
                      >
                        Next
                      </Button>
                      {isloading ? (
                        <CircularProgress color="primary" size={20} />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </StepContent>
              </Step>

              {/* Step 2 */}
              <Step>
                <StepLabel className="upl-step-label">
                  Select Signatories
                </StepLabel>
                <StepContent>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    className={classes.searchContainer}
                  >

                    <Autocomplete
                      style={{ width: "100%" }}
                      value={selectedUser}
                      onChange={(event, newValue) => {
                        if (typeof newValue === 'string') {
                          setSelectedUser({
                            title: newValue,
                          });
                          addToSignatoriesList(newValue);
                        } else if (newValue && newValue.inputValue) {
                          let userVal = newValue.inputValue;
                          if (isValidEmailAddress(userVal)) {
                            setSelectedUser({
                              title: userVal
                            });
                            addToSignatoriesList(userVal);
                          } else {
                            setToast((prev) => ({
                              ...prev,
                              isOpen: true,
                              isError: true,
                              message: 'Email address is not valid'
                            }));
                          }
                        } else {
                          addToSignatoriesList(newValue?.email);
                        }
                      }}
                      filterOptions={(options, params) => {
                        const filtered = usersFilter(options, params);
                        if (params.inputValue !== '') {
                          filtered.push({
                            inputValue: params.inputValue,
                            email: `Add "${params.inputValue}"`,
                          });
                        }
                        return filtered;
                      }}
                      selectOnFocus
                      clearOnBlur
                      handleHomeEndKeys
                      id="free-solo-with-text-demo"
                      options={defaultUsers}
                      getOptionLabel={(option) => {
                        if (typeof option === 'string') {
                          return option;
                        }
                        if (option.inputValue) {
                          return option.inputValue;
                        }
                        return option.email;
                      }}
                      renderOption={(option) => option.email}
                      freeSolo
                      renderInput={(params) => (
                        <TextField {...params} label="Search/Add Users" variant="outlined" />
                      )}
                    />
                  </Box>
                  <div className={`${classes.paper3} select-seg-table`}>
                    <MUIDataTable
                      ref={tableRef}
                      data={signatoriesDataList}
                      columns={columns}
                      options={options}
                    />
                  </div>
                  <div className={classes.actionsContainer}>
                    <div>
                      <Button
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        className={`${classes.button} back-upl-btn`}
                      >
                        Back
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handlePreviewNext}
                        className={`${classes.button} next-upl-btn`}
                        disabled={selectionModel.length < 1}
                      >
                        Next
                      </Button>
                    </div>
                  </div>
                  {progress && <LinearProgress />}
                  {isOpen && <VerifyModal />}
                </StepContent>
              </Step>

              {/* Step 3 */}
              <Step>
                <StepLabel className="upl-step-label">
                  Add Signature Field
                </StepLabel>
                <StepContent>
                  <Grid container spacing={0}>
                    <Grid item xs={10}>
                      <div className={classes.paper2}>
                        <Paper
                          elevation={0}
                          style={{ backgroundColor: "transparent" }}
                        >
                          <Grid
                            container
                            spacing={2}
                            alignItems="center"
                            justify="start"
                          >
                            <CreateSignature
                              uploadDocument={uploadDocument}
                              isloading={isloading}
                              sendFile={sendFile}
                              file={file}
                              fileName={fileName}
                              signatoryList={signatoryList}
                            />
                          </Grid>
                        </Paper>
                      </div>
                    </Grid>
                  </Grid>
                  <div className={classes.actionsContainer}>
                    <div>
                      <Button
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        className={`${classes.button} back-upl-btn`}
                      >
                        Back
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setSendFile(true)}
                        // onClick={handleNext}
                        className={`${classes.button} next-upl-btn`}
                      // disabled={isloadingFinal}
                      >
                        Send
                      </Button>
                      {isloadingFinal ? (
                        <CircularProgress color="primary" size={20} />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </StepContent>
              </Step>
            </Stepper>
          </div>

          <Dialog
            fullWidth={true}
            maxWidth="md"
            open={replaceConfirmModal}
            className={classes.dialogRoot}
            keepMounted={false}
            PaperProps={{
              style: { borderRadius: 20, maxHeight: "calc(100% - 44px)" },
            }}
          >
            <DialogContent className={classes.contentHeight}>
              <Typography variant="h6" className="confirmModalTitle">
                File with same name already exist in Store. Please choose how you want to Continue.
              </Typography>

              <Box className="confirmModalBox">

                <Button
                  variant="contained"
                  color="primary"
                  onClick={(e) => confirmReplace(e, true)}
                  className={`${classes.button} next-upl-btn`}
                >
                  Replace
                </Button>

                <Button
                  variant="contained"
                  color="primary"
                  onClick={(e) => confirmReplace(e, false)}
                  className={`${classes.button} back-upl-btn`}
                >
                  Keep Both
                </Button>

              </Box>
            </DialogContent>
          </Dialog>


          <Dialog
            fullWidth={true}
            maxWidth='md'
            open={showNewNameModel}
            className={classes.dialogRoot}
            keepMounted={false}
            onClose={hideNewNameModel}
            PaperProps={{
              style: { borderRadius: 20, maxHeight: 'calc(100% - 44px)' }
            }}
          >
            <div className={classes.closeIcon}>
              <IconButton
                onClick={hideNewNameModel}
              >
                <Cancel />
              </IconButton>
            </div>
            <DialogTitle gutterBottom>
              <Typography variant='h5' className={classes.title}>
                Enter New Name
              </Typography>
            </DialogTitle>

            <DialogContent className={classes.contentHeight}>

              <Grid
                container
                direction="row"
                alignItems="center"
                className={classes.root}
                spacing={2}
              >
                <Grid item xs={3} align="right">
                  <Typography className={classes.subHeading}>Name*</Typography>
                </Grid>
                <Grid item xs={9} align="center">
                  <CssTextField
                    className={classes.field}
                    variant="outlined"
                    fullWidth
                    InputLabelProps={{ className: classes.textFieldInput }}
                    InputProps={{ className: classes.textFieldInput }}
                    value={replacedName}
                    onChange={e => setReplacedName(e.target.value)}
                  />
                </Grid>
              </Grid>

            </DialogContent>

            <DialogActions className={classes.actionBar} gutterBottom>

              <Box className="w-100 text-center">

                <Button className={classes.nextButton + ' me-10px'} onClick={submitNewNameModel}>
                  <Typography variant='h6'>Continue</Typography>
                </Button>
                <Button className={classes.draftButton} onClick={hideNewNameModel}>
                  <Typography variant='h6'>{"Cancel"}</Typography>
                </Button>

              </Box>

            </DialogActions>


          </Dialog>

        </>
      )}
    </>
  );
}
