import React, { useState } from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { Grid, makeStyles, withStyles, Box, CircularProgress } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import MuiAlert from "@material-ui/lab/Alert";
import { signupApi } from "../../services/auth.service";
// import * as ethers from "ethers";
import { newtwork } from "../../constants"
import Toast from "../../utils/Toast";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import SignUpActions from "../../redux/actions/SignUp.action";
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Checkbox } from "@material-ui/core";
import { FormControlLabel } from "@material-ui/core";
import { CheckBox } from "@material-ui/icons";
import { TERMS_LINK } from "../../utils/constants";

// const bip39 = require("bip39");
// const ethUtil = require("ethereumjs-util");
// const hdkey = require("hdkey");

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const useStyles = makeStyles((theme) => ({
  field: {
    marginBottom: 20,
    borderRadius: "50px 50px 50px 50px",
    color: theme.palette.primary.main,
  },
  button: {
    marginTop: "20px",
    marginBottom: "10px",
    width: '100%',
    height: 45,
  },
  buttonText: {
    fontWeight: "bold",
    fontSize: 16,
    letterSpacing: "0.15em",
    color: "#FFFDFD",
  },
  textFieldInput: {
    color: "#ffffff",
  },
  form: {
    width: "100%",
  }
}));

const CssTextField = withStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#ffffff",
      },
      "&:hover fieldset": {
        borderColor: "#66CFA4",
      },
      borderRadius: 50,
    },
  },
})(TextField);

function RegisterForm() {
  const passwordRegx = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;

  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);
  const [state, setState] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    loading: false
  });
  const toastInitialValues = {
    isOpen: false,
    isSuccess: false,
    isError: false,
    message: ""
  }
  const [toast, setToast] = useState(toastInitialValues);
  const history = useHistory();
  const dispatch = useDispatch();

  const [termsAccepted, setTermsAccepted] = useState(false);

  const setData = (type, value) => {
    setState((prev) => ({
      ...prev,
      [type]: value,
    }));
  };

  const closeToast = () => {
    return setToast((prev) => ({
      ...prev,
      isOpen: false,
      isSuccess: false,
      isError: false,
      message: ""
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setState((prev) => ({
        ...prev,
        loading: true,
      }));
      const { email, password } = state;
      // create wallet
      // const mnemonic = bip39.generateMnemonic();
      // const seed = await bip39.mnemonicToSeed(mnemonic);

      // ether address
      // const addrNode = hdkey.fromMasterSeed(seed).derive("m/44'/1'/0'/0/0"); //line 1
      // const pubKey = ethUtil.privateToPublic(addrNode._privateKey);
      // const addr = ethUtil.publicToAddress(pubKey).toString("hex");
      // const etherAddress = ethUtil.toChecksumAddress(`0x${addr}`);
      // const ethKey = `0x${addrNode._privateKey.toString("hex")}`;

      // Encrypy private key 
      // let provider = ethers.getDefaultProvider(newtwork);
      // const wallet = new ethers.Wallet(ethKey, provider)
      // let encryptedWallet = await wallet.encrypt(password);
      // let newtworkDetails = await provider.getNetwork();
      // const payload ={ email, username, password , realm, wallet : { content: JSON.parse(encryptedWallet), provider: newtworkDetails.name, chain_id: newtworkDetails.chainId }};
      const payload = { email, password };
      signupApi(payload).then(async (res) => {
        console.log("res", res)
        setState((prev) => ({
          ...prev,
          loading: false,
        }));
        if (res && res.status === 200) {
          if (res.data) {
            // setToast((prev) => ({
            //   ...prev,
            //   isOpen: true,
            //   isSuccess: true,
            //   message: "Successfully signed up! Please login to continue"
            // }));
            resetInput();
            // sessionStorage.setItem("ethKey", ethKey);
            // sessionStorage.setItem("mnemonic", mnemonic);
            history.push("/login")
            dispatch(SignUpActions(res.data));
            // alert(mnemonic)
          }
        } else if (res && res.data && res.data.success === false) {
          setToast((prev) => ({
            ...prev,
            isOpen: true,
            isError: true,
            message: res.data.message
          }));
          setState((prev) => ({
            ...prev,
            loading: false,
          }));
        }
      });
    } catch (error) {
      console.log("Error While creating the wallet", error)
      setState((prev) => ({
        ...prev,
        loading: false,
      }));
    }
  };

  const resetInput = () => {
    setState((prev) => ({
      ...prev,
      email: "",
      password: "",
      confirmPassword: "",
    }));
  };

  const enableSubmit = () => {
    return (
      state.password &&
      state.email &&
      state.confirmPassword === state.password &&
      state?.password?.match(passwordRegx) &&
      termsAccepted
    );
  };


  return (
    <>
      {toast.isOpen && <Toast message={toast.message} isError={toast.isError} isSuccess={toast.isSuccess} closeToast={closeToast} />}

      <Box width="100%" mt="20px">
        <form autoComplete="off" onSubmit={handleSubmit} className={classes.form}>

          <TextField
            value={state.email}
            className={classes.field}
            onChange={(e) => setData("email", e.target.value)}
            label="Email Address"
            variant="outlined"
            color="primary"
            type="email"
            fullWidth
            required
          />
          <TextField
            error={state.password && !state.password.match(passwordRegx)}
            label="Password"
            type={showPassword ? 'text' : 'password'}
            helperText="Password should contain at least 1 special character (!@#$%^&*), 1 number, 1 uppercase alphabet and 1 lowercase alphabet"
            value={state.password}
            className={classes.field}
            onChange={(e) => setData("password", e.target.value)}
            variant="outlined"
            color="primary"
            fullWidth
            required
            InputProps={{
              endAdornment: <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }}
          />
          <TextField
            label="Confirm Password"
            type={showPassword ? 'text' : 'password'}
            value={state.confirmPassword}
            className={classes.field}
            onChange={(e) => setData("confirmPassword", e.target.value)}
            variant="outlined"
            color="primary"
            fullWidth
            required
            helperText={state.confirmPassword?.length > 0 &&
              state.password !== state.confirmPassword ? "New Password and Confirm New Password must be same" : ""}
            error={
              state.confirmPassword?.length > 0 &&
              state.password !== state.confirmPassword
            }
            InputProps={{
              endAdornment: <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }}
          />

          <FormControlLabel
            className="theme-checkbox theme-checkbox-small"
            control={<Checkbox color="primary" checked={termsAccepted} onChange={(e) => setTermsAccepted(!termsAccepted)} name="gilad" />}
            label={<Typography>By Checking this box, you are agreeing to all of our <a href={TERMS_LINK} target="_blank">Terms and Conditions</a></Typography>}
          />

          <Grid item xs={12} align="center">
            {
              state.loading
                ? <Button
                  type="button"
                  variant="contained"
                  className={classes.button}
                  size="large"
                  color="primary"
                >
                  <CircularProgress size={30} color="inherit" />
                </Button>
                : <Button
                  type="submit"
                  variant="contained"
                  className={classes.button}
                  size="large"
                  color="primary"
                  disabled={!enableSubmit()}
                >
                  <Typography className={classes.buttonText}>Sign up</Typography>
                </Button>
            }

          </Grid>
        </form>
      </Box>
    </>
  );
}

export default RegisterForm;
