import { useState, forwardRef } from 'react';
import { Slide, Typography, makeStyles, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton } from '@material-ui/core';
import Form from './Form';
import { Cancel } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import LoaderButton from '../../../utils/Loader/LoaderButton';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="top" ref={ref} {...props} />;
});


const useStyles = makeStyles((theme) => {
  return {
    dialogRoot: {
      width: '700px',
      borderRadius: 50,
      margin: '0 auto',
    },
    contentHeight: {
      minHeight: '35px',
      maxWidth: '100%',
      margin: '0 auto',
    },
    title: {
      paddingTop: 20,
      textAlign: 'center',
      fontWeight: '600',
      textTransform: 'uppercase',
      letterSpacing: 0.5,
      wordSpacing: 2,
      paddingBottom: 10
    },
    stepText: {
      fontWeight: '600',
      paddingBottom: 30,
      textAlign: 'center',
      color: theme.palette.admin.main,
      letterSpacing: 1,
    },
    actionBar: {
      justifyContent: 'center',
      marginBottom: 20
    },
    nextButton: {
      width: 220,
      height: 50,
      borderRadius: 25,
      color: '#fff',
      letterSpacing: 1,
      backgroundColor: theme.palette.admin.main,
      '&:hover': {
        backgroundColor: theme.palette.admin.main,
      }
    },
    draftButton: {
      width: 220,
      height: 50,
      borderRadius: 25,
      letterSpacing: 1,
      backgroundColor: '#fff',
      color: "#6a6a6a",
      border: '1px solid gray',
      '&:hover': {
        backgroundColor: "#fff", 
        color: '#363636',
        border: '1px solid #464646'
     }
    },
    closeIcon:{
      position: 'absolute',
      right: '0',
      padding: '10px'
    },
    buttonText: {
      fontWeight: "bold",
      fontSize: 16,
      letterSpacing: "0.15em",
      color: "#FFFDFD"
    }
  }
});

const BlockUnblockModal = ({ open, handleClose, handleSubmit, id }) => {
  const classes = useStyles();
  const isLoading = useSelector(state => state.buttonLoading);
  return (
    <Dialog
      fullWidth={true}
      maxWidth='md'
      open={open}
      onClose={handleClose}
      className={classes.dialogRoot}
      keepMounted={false}
      TransitionComponent={Transition}
      PaperProps={{
        style: { borderRadius: 20 }
      }}
    >
      <div className={classes.closeIcon}>
      <IconButton
        onClick={handleClose}
      >
        <Cancel/>
      </IconButton>  
      </div>
      <DialogTitle gutterBottom>
        {/* <Typography variant='h5' className={classes.title}>
          Delete Contact
        </Typography> */}
      </DialogTitle>
      <DialogContent className={classes.contentHeight}>
      <Typography variant='h6'>{id === 0 ? "Are you sure you want to block user" : "Are you sure you want to unblock user"}</Typography>
      </DialogContent>
      <DialogActions className={classes.actionBar} gutterBottom>
      <LoaderButton
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          className={classes.nextButton}
          textClass={classes.buttonText}
          isLoading={isLoading}
          text={id === 0 ? "Block" : "Unblock" }
        />
        {/* <Button className={classes.nextButton} onClick={handleSubmit}>
          <Typography variant='h6'>{id === 0 ? "Block" : "Unblock" }</Typography>
        </Button> */}
        <Button className={classes.draftButton} onClick={handleClose}>
          <Typography variant='h6'>Cancel</Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default BlockUnblockModal;