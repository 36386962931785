import { Dropdown, DropdownItem } from "@govtechsg/tradetrust-ui-components";
import React from "react";
import { AssetManagementActions } from "./AssetManagementActions";

export const AssetManagementDropdown = ({
  onSetFormAction,
  canSurrender,
  canHandleSurrender,
  canChangeHolder,
  canEndorseBeneficiary,
  canNominateBeneficiaryHolder,
  canEndorseTransfer,
}) => {
  return (
    <Dropdown
      data-testid="manageAssetDropdown"
      dropdownButtonText="Manage Assets"
      className="bg-cerulean font-bold text-white rounded-xl text-lg py-2 px-3 hover:bg-cerulean-300 w-30 MuiButton-root"
      classNameMenu="lg:right-0"
      style={{
        background:"#66CFA4",
        padding:"7.5px 15px",
        borderRadius:"20px",
        color:"#000",
        fontSize:"14px",
        height:"38px",
        display:"flex",
        alignItems:"center",
        textTransform:"capitalize",
        border:"unset",
        cursor:"pointer"
      }}
    >
      <div 
      style={{
        padding:"15px",
        border:"1px solid #ccc",
        width:"250px",
        borderRadius:"8px",
        marginTop:"10px"
      }}>
      {canChangeHolder && (
        <DropdownItem
          className="active:bg-cloud-200 active:text-white"
          data-testid={"transferHolderDropdown"}
          onClick={() => onSetFormAction(AssetManagementActions.TransferHolder)}
          style={{
            fontFamily: "Poppins",
            fontSize:"14px",
            color:"#333",
            borderBottom:"1px solid #eee",
            paddingBottom:"5px",
            marginBottom:"5px"
          }}
        >
          Transfer holdership
        </DropdownItem>
      )}
      {canEndorseBeneficiary && (
        <DropdownItem
          className="active:bg-cloud-200 active:text-white"
          data-testid={"endorseBeneficiaryDropdown"}
          onClick={() => onSetFormAction(AssetManagementActions.EndorseBeneficiary)}
          style={{
            fontFamily: "Poppins",
            fontSize:"14px",
            color:"#333",
            borderBottom:"1px solid #eee",
            paddingBottom:"5px",
            marginBottom:"5px"
          }}
        >
          Endorse change of ownership
        </DropdownItem>
      )}
      {canNominateBeneficiaryHolder && (
        <DropdownItem
          className="active:bg-cloud-200 active:text-white"
          data-testid={"nominateBeneficiaryHolderDropdown"}
          onClick={() => onSetFormAction(AssetManagementActions.NominateBeneficiaryHolder)}
          style={{
            fontFamily: "Poppins",
            fontSize:"14px",
            color:"#333",
            borderBottom:"1px solid #eee",
            paddingBottom:"5px",
            marginBottom:"5px"
          }}
        >
          Nominate change of ownership
        </DropdownItem>
      )}
      {canSurrender && (
        <DropdownItem
          className="active:bg-cloud-200 active:text-white"
          data-testid={"surrenderDropdown"}
          onClick={() => onSetFormAction(AssetManagementActions.Surrender)}
          style={{
            fontFamily: "Poppins",
            fontSize:"14px",
            color:"#333",
            borderBottom:"1px solid #eee",
            paddingBottom:"5px",
            marginBottom:"5px"
          }}
        >
          Surrender document
        </DropdownItem>
      )}
      {canHandleSurrender && (
        <>
          <DropdownItem
            className="active:bg-cloud-200 active:text-white"
            data-testid={"acceptSurrenderDropdown"}
            onClick={() => onSetFormAction(AssetManagementActions.AcceptSurrendered)}
            style={{
              fontFamily: "Poppins",
              fontSize:"14px",
              color:"#333",
              borderBottom:"1px solid #eee",
              paddingBottom:"5px",
              marginBottom:"5px"
            }}
          >
            Accept surrender of document
          </DropdownItem>
          <DropdownItem
            className="active:bg-cloud-200 active:text-white"
            data-testid={"rejectSurrenderDropdown"}
            onClick={() => onSetFormAction(AssetManagementActions.RejectSurrendered)}
            style={{
              fontFamily: "Poppins",
              fontSize:"14px",
              color:"#333",
              borderBottom:"1px solid #eee",
              paddingBottom:"5px",
              marginBottom:"5px"
            }}
          >
            Reject surrender of document
          </DropdownItem>
        </>
      )}

      {canEndorseTransfer && (
        <DropdownItem
          className="active:bg-cloud-200 active:text-white"
          data-testid={"endorseTransferDropdown"}
          onClick={() => onSetFormAction(AssetManagementActions.EndorseTransfer)}
          style={{
            fontFamily: "Poppins",
            fontSize:"14px",
            color:"#333",
            borderBottom:"1px solid #eee",
            paddingBottom:"5px",
            marginBottom:"5px"
          }}
        >
          Endorse Transfer of ownership
        </DropdownItem>
      )}
      </div>    </Dropdown>
  );
};
