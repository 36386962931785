import { Button, Popover } from "antd";
import React, { useEffect, useState } from "react";
import { getGridKeys, goalsParseSubjects, parseGridData } from "../../utils/Generic";
import UnsdgObjectiveInfo from "./unsdg-objective-info";
import UnsdgSubjectNumber from "./unsdg-subject-number";

const UnsdgGrid = (props) => {

    const { goals, objectives } = props;

    const [subjects, setSubjects] = useState([]);
    const [gridData, setGridData] = useState({});

    useEffect(() => {
        setSubjects(goalsParseSubjects(goals));
    }, [goals]);

    useEffect(() => {
        setGridData(parseGridData(subjects));
    }, [subjects]);

    return (
        <>

            <div className={`main-grid-box ${props?.className}`}>
                <div className="up-side">
                    <div className="right-bar">Impact on people and the environment</div>
                    <div className="chart-names">
                        <p>Negligible</p>
                        <p>Moderate</p>
                        <p>Significant</p>
                        <p>Major</p>
                    </div>
                </div>
                <div class="grid-container">
                    {
                        Object.keys(gridData).map((gridKey) => {
                            let gridItems = gridData[gridKey];
                            return <>
                                <div class="grid-item">
                                    <div className="round-numbers-box">
                                        {
                                            Object.keys(gridItems).map((goalNumber) => {
                                                let lObjectives = gridItems[goalNumber];
                                                if (lObjectives.length > 0) {
                                                    return <>
                                                        {
                                                            lObjectives.map((lObjective) => {
                                                                return <>
                                                                    <UnsdgSubjectNumber goals={goals} objectives={objectives} subject={lObjective} subjects={lObjectives} />
                                                                </>
                                                            })
                                                        }
                                                    </>
                                                    // let objective = objectives[0];
                                                    // return <>
                                                    //     <UnsdgSubjectNumber goals={goals} objectives={objectives} showMulti={true} subject={lObjective} subjects={lObjectives} />
                                                    // </>
                                                }
                                            })
                                        }
                                    </div>
                                </div>
                            </>
                        })
                    }
                </div>
                <div className="down-side">
                    <div className="chart-names">
                        <p>Negligible</p>
                        <p>Moderate</p>
                        <p>Significant</p>
                        <p>Major</p>
                    </div>
                    <div className="right-bar">Impact on business success</div>
                </div>
            </div>
        </>
    );
};

export default UnsdgGrid;