import { SIGNUP_USER_SUCCESS } from '../redux.constants';

const SignUpReducer = (state = false, action) => {
  switch (action.type) {
    case SIGNUP_USER_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}

export default SignUpReducer;