import React, { useEffect, useRef, useState } from "react";
import { makeStyles, styled } from "@material-ui/core/styles";
import Toast from "../../utils/Toast";
import GoalBox from "./goal-box";
import UnsdgGoals from "./unsdg-goals";
import UnsdgRelevance from "./unsdg-relevance";
import { useHistory, useParams } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    width: `calc(100% - ${300}px)`,
    marginLeft: 280,
    minHeight: "88vh",
  },
}));

const Unsdg = (props) => {

  const classes = useStyles();
  let history = useHistory();

  const { goals, objectives, setGoals, companyDocuments } = props;

  const toastInitialValues = {
    isOpen: false,
    isSuccess: false,
    isError: false,
    message: ""
  }

  const [toast, setToast] = useState(toastInitialValues);

  const closeToast = () => {
    return setToast((prev) => ({
      ...prev,
      isOpen: false,
      isSuccess: false,
      isError: false,
      message: ""
    }));
  };

  const showError = (errorMsg) => {
    setToast((prev) => ({
      ...prev,
      isOpen: true,
      isError: true,
      isSuccess: false,
      message: errorMsg
    }));
  }

  const showSuccess = (succMsg) => {
    setToast((prev) => ({
      ...prev,
      isOpen: true,
      isError: false,
      isSuccess: true,
      message: succMsg
    }));
  }

  let { tabId } = useParams();

  const handleNext = (fromWhichTab) => {
    if (fromWhichTab == "unsdg") {
      if (Object.keys(goals).length > 0) {
        history.push("/sustainability/unsdg/relevance");
      } else {
        showError("Select atleast one Goal to Continue");
      }
    } else {  
      history.push("/sustainability/objectives");
    }
  }

  const handlePrevious = (fromWhichTab) => {
    if (fromWhichTab == "unsdg") {
      // let lastIndex = (companyDocuments.length - 1);
      // history.push("/sustainability/tab-" + lastIndex);
      history.push("/sustainability/know-sdgs");
    } else {
      history.push("/sustainability/unsdg/goals");
    }
  }

  return (
    <>

      {toast.isOpen && <Toast message={toast.message} isError={toast.isError} isSuccess={toast.isSuccess} closeToast={closeToast} />}

      {
        (tabId == "goals") ? <>
          <UnsdgGoals canSave={props?.canSave} onSave={props?.onSave} onNext={handleNext} onPrevious={handlePrevious} goals={goals} objectives={objectives} setGoals={setGoals} />
        </> : null
      }

      {
        tabId == "relevance" ? <>
          <UnsdgRelevance canSave={props?.canSave} onSave={props?.onSave} onNext={handleNext} onPrevious={handlePrevious} goals={goals} objectives={objectives} setGoals={setGoals} />
        </> : null
      }

    </>
  );
};

export default Unsdg;