import React, { useEffect } from "react";
import {
  makeStyles,
} from "@material-ui/core";
import { useHistory } from "react-router";
import AllDocuments from "../dms/AllDocuments";
import { useAuth } from "../../hooks";
import { UserRoles } from "../../constants";


const useStyles = makeStyles((theme) => {
  return {

  };
});

function Wallet() {

  const history = useHistory();
  const { user } = useAuth();

  useEffect(() => {
    if (user?.role === UserRoles.ADMIN) {
      history.push({ pathname: "/dms" });
    }
  }, []);

  return (
    <>
      <AllDocuments dmsType="folderView" dmsDefaultFolder="__TruSign" />
    </>
  );
}

export default Wallet;
