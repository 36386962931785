import { Box, Card, CardContent, Typography, makeStyles, Button, TextField, FormHelperText, CircularProgress } from '@material-ui/core';
import { getDomainSecret, updateDomainCallbackURL } from './../../../services/admin.service';
import { toLocalDateTime } from './../../../utils/moment-utils';
import { useState, useEffect } from 'react';
import Toast from '../../../utils/Toast';
import { useMetaMask } from 'metamask-react';
import { MessageConstants } from '../../../utils/MessageConstants';
import { hexToNumber, isNotEmpty, parseWeb3Error } from '../../../utils/Generic';
const Web3 = require('web3');

const useStyles = makeStyles((theme) => {
  return {
    card: {
      padding: 10
    },
    borderBottom: {
      borderBottom: '1px solid lightgray',
    },
    heading: {
      color: theme.palette.admin.main,
      textTransform: "uppercase",
      letterSpacing: 1.5,
      paddingBottom: 10,
      borderBottom: '1px solid lightgray',
    },
    previewBox: {
      border: '1px solid lightgray',
      borderRadius: 5,
      height: 200,
      margin: '20px auto'
    },
    listItem: {
      padding: '1rem 0px',
      borderBottom: '1px solid lightgray',
    },
    listItemSubHeading: {
      textTransform: 'uppercase',
      fontWeight: '500',
      fontSize: 15
    },
    listItemtext: {
      color: 'gray',
      fontSize: 15
    },
    listItemtextSecret: {
      color: '#000000',
      fontSize: 15,
      wordBreak: 'break-all',
      background: '#b5b5b5',
      padding: '1rem',
      borderRadius: '0.3rem'
    },
    button: {
      width: '100%',
      height: 45,
      borderRadius: 25,
      backgroundColor: theme.palette.admin.main,
      '&:hover': {
        backgroundColor: theme.palette.admin.main,
      }
    },
    buttonSm: {
      width: 'auto',
      height: 35,
      borderRadius: 25,
      backgroundColor: theme.palette.admin.main,
      '&:hover': {
        backgroundColor: theme.palette.admin.main,
      }
    },
    buttonTextSm: {
      fontWeight: "bold",
      fontSize: 12,
      letterSpacing: "0.15em",
      color: "#FFFDFD",
    },
    buttonSave: {
      width: 'auto',
      padding: '0.3rem 2rem',
      borderRadius: 5,
      margin: '0 3px'
    },
    buttonText: {
      fontWeight: "bold",
      fontSize: 14,
      letterSpacing: "0.15em",
      color: "#FFFDFD",
    },
    buttonTextSave: {
      fontWeight: "bold",
      fontSize: 14,
      letterSpacing: "0.15em",
    },
  }
});

const SelectedDomain = (props) => {
  const httpRegex = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;

  let domain = props.domain;
  const [clientSecret, setClientSecret] = useState(null);

  const [editCallbackUrl, setEditCallbackUrl] = useState(props?.domain?.callbackurl ? false : true);

  const [callbackurl, setCallbackurl] = useState(props?.domain?.callbackurl || '');

  const [loading, setLoading] = useState(false);
  const [loadingcallbackurl, setLoadingcallbackurl] = useState(false);
  const [fileName, setFileName] = useState('');
  const [href, setHref] = useState('');
  const downloadLinkId = 'download-clientSecret-link';
  const classes = useStyles();

  const toastInitialValues = {
    isOpen: false,
    isSuccess: false,
    isError: false,
    message: ""
  }
  const [toast, setToast] = useState(toastInitialValues);

  const closeToast = () => {
    return setToast((prev) => ({
      ...prev,
      isOpen: false,
      isSuccess: false,
      isError: false,
      message: ""
    }));
  };

  useEffect(() => {
    setCallbackurl(props?.domain?.callbackurl || '');
    setEditCallbackUrl(props?.domain?.callbackurl ? false : true);
  }, [domain]);

  const previewURL = domain?.name?.startsWith('http') ? domain?.name : `https://${domain?.name}`;

  const saveCallbackurlChange = async () => {

    if (!callbackurl) {
      return window.alert('Please enter a valid api end point')
    }

    if (!httpRegex.test(callbackurl)) {
      return window.alert('Please enter a valid api end point')
    }
    setEditCallbackUrl(true);
    setLoadingcallbackurl(true);
    await updateDomainCallbackURL(domain?.id, callbackurl);
    setCallbackurl(callbackurl || '');
    domain = { ...domain, callbackurl };

    setEditCallbackUrl(false);
    setLoadingcallbackurl(false);
  };

  const generateSecret = async (domainId) => {
    if (!domainId) { return }
    setLoading(true)
    try {
      const res = await getDomainSecret(domainId);
      setClientSecret(res?.data?.authToken);
      setFileName(`clientSecret_${domain?.name}${(new Date()).toISOString()}.txt`);
      setHref('data:text/plain;charset=utf-11,' + encodeURIComponent(res?.data?.authToken));
      setLoading(false)
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <>
      {toast.isOpen && <Toast message={toast.message} isError={toast.isError} isSuccess={toast.isSuccess} closeToast={closeToast} />}
      <Box width="500px" minWidth="400px">
        <Card className={classes.card}>
          <CardContent align="left">
            <Typography variant="h6" textTransform="uppercase" className={classes.heading}>
              {domain?.name || 'Select Domain'}
            </Typography>
            {/* <Box className={classes.previewBox}>
            <iframe src={previewURL} width={'100%'} height={'100%'} frameborder="0"></iframe>
          </Box> */}

            {
              domain?.id && (domain?.created_at || domain?.createdAt) &&
              <Box display="flex" alignItems="center" justifyContent="space-between" className={classes.listItem}>
                <Typography className={classes.listItemSubHeading}>Created At</Typography>
                <Typography className={classes.listItemtext}>{toLocalDateTime(domain?.created_at || domain?.createdAt)}</Typography>
              </Box>
            }

            {
              domain?.domain_configs.length > 0 ?
                <>
                  {
                    domain?.domain_configs?.map(conf => <Box display="block" alignItems="center" justifyContent="space-between" className={classes.listItem}>
                      <Typography className={classes.listItemSubHeading}>{conf?.type} | {conf?.network} | {conf?.blockchain}</Typography>
                      <Typography className={classes.listItemtext}>{conf?.store}</Typography>
                    </Box>
                    )
                  }
                </> :
                null
            }



            {
              domain?.id && domain?.email &&
              <Box display="flex" alignItems="center" justifyContent="space-between" className={classes.listItem}>
                <Typography className={classes.listItemSubHeading}>Email</Typography>
                <Typography className={classes.listItemtext}>{domain?.email || 'n/a'}</Typography>
              </Box>
            }
            {
              domain?.id && domain?.status &&
              <Box display="flex" alignItems="center" justifyContent="space-between" className={classes.listItem}>
                <Typography className={classes.listItemSubHeading}>Status</Typography>
                <Typography className={classes.listItemtext}>{domain?.status || 'n/a'}</Typography>
              </Box>
            }
            {
              domain?.id && !editCallbackUrl &&
              <Box display="block" alignItems="center" justifyContent="space-between" className={classes.listItem}>
                <Typography className={classes.listItemSubHeading} style={{ display: 'flex', width: ' 100%', justifyContent: 'space-between' }}>Callback URL <Button
                  className={classes.buttonSave}
                  color="primary"
                  size="small"
                  onClick={() => setEditCallbackUrl(true)}
                >
                  <Typography className={classes.buttonTextSave}>Edit</Typography>
                </Button></Typography>
                <Typography style={{ wordBreak: 'break-all', padding: '0rem 0rem' }} className={classes.listItemtext}>{callbackurl}</Typography>
              </Box>
            }
            {
              domain?.id && editCallbackUrl &&
              <Box display="block" alignItems="center" justifyContent="space-between" className={classes.listItem}>
                <TextField style={{ marginTop: '1rem', marginBottom: '1rem' }}
                  size="small" fullWidth label="Callback URL"
                  variant="outlined" id="callbackurl"
                  defaultValue={domain?.dcallbackurlomain}
                  helperText="Callback-url is being used to notify once event is done"
                  value={callbackurl}
                  onChange={(e) => setCallbackurl(e?.target?.value)}
                />
                <Box display="flex" alignItems="center" justifyContent="end">
                  <Button
                    color="primary"
                    variant="outlined"
                    className={classes.buttonSave}
                    size="small"
                    disabled={loadingcallbackurl}
                    onClick={saveCallbackurlChange}
                  >
                    <Typography className={classes.buttonTextSave}> {!loadingcallbackurl ? 'Save' : ''} {loadingcallbackurl && <CircularProgress />}</Typography>
                  </Button>
                  <Button
                    className={classes.buttonSave}
                    variant="outlined"
                    size="small"
                    disabled={loadingcallbackurl}
                    onClick={() => setEditCallbackUrl(false)}
                  >
                    <Typography className={classes.buttonTextSave}>Cancel</Typography>
                  </Button>
                </Box>

              </Box>
            }
            {
              domain?.id && !clientSecret &&
              <Box display="flex" alignItems="center" justifyContent="space-between" className={classes.listItem}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  size="large"
                  disabled={loading}
                  onClick={(e) => generateSecret(domain?.id)}
                >
                  <Typography className={classes.buttonText}> {!loading ? 'Generate Access Token' : ''} {loading && <CircularProgress />}</Typography>
                </Button>
              </Box>
            }
            {
              domain?.id && clientSecret &&
              <Box display="block" className={classes.listItem}>
                <Typography className={classes.listItemSubHeading}>Access Token <a href={href} id={downloadLinkId} download={fileName}> {fileName ? 'Download' : ''} </a></Typography>
                <Typography className={classes.listItemtextSecret}>{clientSecret}</Typography>
                <FormHelperText focused={true}>Please donwload and save access token for access the client-api of tudoc. Once Page is closed/refreshed, token will disappear.</FormHelperText>

              </Box>
            }
          </CardContent>
        </Card>
      </Box>

    </>
  )
}

export default SelectedDomain
