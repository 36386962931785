import React, { useRef, useEffect, useState } from "react";
import WebViewer from "@pdftron/webviewer";

import { makeStyles } from "@material-ui/core";
import Toast from "../Toast";
import "./Viewer.css";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: "transparent",
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  paper1: {
    display: "flex",
    flexWrap: "wrap",
    backgroundColor: "transparent",
    "& > *": {
      margin: theme.spacing(3),
      padding: theme.spacing(2),
      width: "100%",
      textAlign: "center",
    },
  },
  edocuments: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  paper2: {
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(3),
      padding: theme.spacing(2),
      width: "100%",
    },
  },
  paper3: {
    backgroundColor: "transparent",
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(3),
      padding: theme.spacing(2),
      width: "100%",
      height: 350,
    },
  },
  dropIcon: {
    width: "150px",
    padding: theme.spacing(2),
  },
  docOptions: {
    cursor: "pointer",
    background: "#66cfa3",
    padding: "5px 20px",
    color: "#fff",
    borderRadius: "20px",
  },
  icon: {
    margin: theme.spacing(1),
  },
  formControl: {
    minWidth: "100%",
  },
  contentMargin: {
    marginTop: theme.spacing(3),
  },
  addSign: {
    color: "#3da543",
    background: "#d9d9d940",
    padding: "3px 8px",
    borderRadius: "10px",
  },
  selectBox: {
    marginRight: "20px",
    display: "flex",
    flexDirection: "column",
    rowGap: "10px",
  },
  webViewerStyle: {
    overflow: "hidden",
    width: "calc(100vw - 620px)",
    height: "75vh !important",
  },
  Select: {
  },
  SelectImg: {
    height: "100px",
    width: "auto"
  }
}));

const AllDocumentViewer = (props) => {
  const toastInitialValues = {
    isOpen: false,
    isSuccess: false,
    isError: false,
    message: "",
  };

  let [instance, setInstance] = useState(null);
  const [toast, setToast] = useState(toastInitialValues);
  const classes = useStyles();
  const viewer = useRef(null);
  const filePicker = useRef(null);

  const [webViewerInitiated, setWebViewerInitiated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (instance == null) {
      if (!webViewerInitiated) {
        setWebViewerInitiated(true);
        startDocViewer();
      }
    }
  }, [instance]);

  const closeToast = () => {
    return setToast((prev) => ({
      ...prev,
      isOpen: false,
      isSuccess: false,
      isError: false,
      message: "",
    }));
  };


  const startDocViewer = () => {
    console.log(props);
    WebViewer(
      {
        path: "/webviewer/lib",
        css: "/style.css",
        fullAPI: true,
        disableLogs: true,
        disabledElements: [
          "ribbons",
          "toggleNotesButton",
          "searchButton",
          // "menuButton",
          "rubberStampToolGroupButton",
          "stampToolGroupButton",
          "fileAttachmentToolGroupButton",
          "calloutToolGroupButton",
          // "undo",
          "redo",
          "eraserToolButton",
          // "toolsHeader",
          "signatureToolGroupButton",
          "leftPanelButton",
          "viewControlsButton",
          "selectToolButton",
          "annotationPopup",
          "toolsOverlay",
          "defaultSignatureDeleteButton",
          "contextMenuPopup",
          "signatureModal",
          "underlineToolGroupButton",
          "highlightToolGroupButton",
          "shapeToolGroupButton",
          "freeTextToolGroupButton",
          "freeHandHighlightToolGroupButton",
          "freeHandToolGroupButton",
          "stickyToolGroupButton",
          "squigglyToolGroupButton",
          "strikeoutToolGroupButton",
          "redoButton"
        ],
      },
      viewer.current
    ).then((instance) => {
      instance = instance;
      setInstance(instance);
      const { documentViewer } = instance.Core;

      instance.UI.loadDocument(props.documentUrl, { filename: props?.document?.name });

      documentViewer.addEventListener('documentLoaded', async () => {
        await documentViewer.getDocument().documentCompletePromise();
        documentViewer.updateView();
        setIsLoading(false);
      });

    });
  };

  return (
    <>
      {toast.isOpen && (
        <Toast
          message={toast.message}
          isError={toast.isError}
          isSuccess={toast.isSuccess}
          closeToast={closeToast}
        />
      )}

      {/* {
        isLoading ? <Grid container>
          <Grid container item xs={12}>
            <Box width="100%" className="text-center">
              <CircularProgress color="primary" size={50} />
            </Box> 
          </Grid>
        </Grid> : null
      } */}

      <div className={"prepareDocument"}>
        <div style={{ display: "flex", flexDirection: "row", flex: "grow", justifyContent: "center", alignContent: "center" }}>
          <div
            className={`${classes.webViewerStyle} webviewer`}
            ref={viewer}
          ></div>
        </div>
        <input type="file" ref={filePicker} style={{ display: "none" }} />
      </div>

    </>
  );
};

export default AllDocumentViewer;