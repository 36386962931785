import logo from "../../static/images/logo.png";
import { fetchclientDocuments, uploadclientDocument, downloadDocument } from '../../services/admin.service'
import React, { } from "react";
import { Link } from "react-router-dom";
import "./styles.css";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import CloudDownload from '@material-ui/icons/CloudDownload';

import {
  Box,
  makeStyles,
  Typography,
  TextField,
  Divider,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button

} from "@material-ui/core";
import { useState } from "react";


const useStyles = makeStyles((theme) => {
  return {
    table: {
      minWidth: 650,
    },
    root: {
      minHeight: "100vh",
      backgroundColor: "transparent",
    },
    logo: {
      marginTop: theme.spacing(5),
      height: "50px",
      borderRadius: "0px",
      marginLeft: theme.spacing(8),
    },
    verifyText: {
      fontSize: "18px",
      fontWeight: 600,
      textDecoration: "none",
      fontFamily: "Poppins",
      lineHeight: "27px",
      color: "#66CFA4",
    },
    innerContainer: {
      marginTop: theme.spacing(7),
      marginBottom: theme.spacing(1),
      minHeight: "40px",
    },
    divider: {
      borderColor: "#E5E5E5",
      height: "477px",
      width: "1px",
      borderWidth: "1px",
    },
    verify: {
      color: "#262727",
      fontSize: 30,
      fontWeight: 600,
      fontFamily: "Poppins",
      lineHeight: "27px",
      textAlign: "center",
      marginBottom: "2rem"
    },
    loginText: {
      fontSize: "18px",
      textDecoration: "none",
      fontWeight: 600,
      fontFamily: "Poppins",
      lineHeight: "27px",
      color: "#262727",
    },
  };
});


const DocRow = ({ id, document_id, filename, status, clientSecret }) => {
  const downloadFile = async () => {
    try {
      if (!clientSecret) {
        return window.confirm('Please provide clientSecret');
      }
      const res = await downloadDocument(document_id, clientSecret)
      console.log('no res',res)

      if (!res) {
        console.log('no res')
      }
      else if (res?.data) {
        if (res?.data?.event === 'PDFFILE') {
          const response =
            "data:application/pdf;base64," + (res?.data.data)
          const element = document.createElement("a");
          element.setAttribute("href", response);
          element.setAttribute("download", 'fileName');
          document.body.appendChild(element);
          element.click();
        }

        if (res?.data?.event === 'TTFILE') {
          const response =
            "data:text/json;charset=utf-8," +
            encodeURIComponent(JSON.stringify(res?.data?.data));
          const element = document.createElement("a");
          element.setAttribute("href", response);
          element.setAttribute("download", 'fileName');
          document.body.appendChild(element);
          element.click();
        }

      }

    } catch (e) {
      console.log('Error', e);
      window.alert(JSON.stringify(e));
    }
  }

  return (
    <TableRow>
      <TableCell component="th" scope="row">
        {id}
      </TableCell>
      <TableCell>{document_id}</TableCell>
      <TableCell>{filename}</TableCell>
      <TableCell>{status}</TableCell>
      <TableCell>{status === 'ISSUED' && <IconButton onClick={downloadFile} color="primary" aria-label="upload picture" component="span">
        <CloudDownload />
      </IconButton>}</TableCell>
    </TableRow>
  )
}

const ClientUpload = () => {
  const classes = useStyles();
  const [document_type, setDocument_type] = useState("VERIFIABLE_DOCUMENT");
  const [upload_method, setUpload_method] = useState("blockchain");
  const [clientSecret, setClientSecret] = useState("");
  const [file, setFile] = useState(null);
  const [response, setResponse] = useState(null);
  const [docs, setDocs] = useState(null);

  function reset() { window.location.reload() };
  function onSelectFile(e) {
    if (e?.target?.files?.length) {
      setFile(e?.target?.files[0]);
    }

    if (e?.target) {
      e.target.value = null;
    }

  };

  async function upload() {
    if (!clientSecret) {
      return window.confirm('Please provide clientSecret');
    }
    if (!file || !file.name) {
      return window.confirm('Please attache file');
    }

    try {
      const res = await uploadclientDocument({ file, document_type, clientSecret, upload_method })
      if (!res) {
        console.log('no res')
      }
      else {
        console.log(res?.data || res);
      }
      setResponse(res?.data || res);

    } catch (e) {
      window.alert(JSON.stringify(e));
    }
  };

  async function fetchDocs() {

    try {
      const res = await fetchclientDocuments()
      if (!res) {
        console.log('no res')
      }
      else {
        console.log(res?.data || res);
      }
      setDocs(res?.data || res);

    } catch (e) {
      window.alert(JSON.stringify(e));
    }
  };

  return (
    <div
      className="App"
      style={{
        height: "150vh",
        width: "100%"
      }}
    >

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginRight: "137px",
        }}
      >
        <img src={logo} alt="TruDoc" className={classes.logo} />
        <Box display="flex" alignItems="center" justifyContent="center">
          <Typography className={classes.verifyText}>CLIENT UPLOAD</Typography>
          <Divider
            orientation="vertical"
            style={{
              marginLeft: "40px",
              marginRight: "40px",
              height: "43px",
            }}
          ></Divider>
          <Typography component={Link} to="/" className={classes.loginText}>
            Login
          </Typography>
          <Divider
            orientation="vertical"
            style={{
              marginLeft: "40px",
              marginRight: "40px",
              height: "43px",
            }}
          ></Divider>
          <Typography component={Link} to="/verify-doc" className={classes.loginText}>
            VERIFY
          </Typography>
        </Box>
      </div>

      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        padding="10rem"
        className={classes.innerContainer}
      >
        <Typography className={classes.verify}>Client Upload Demo </Typography>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          width="50rem"
        >
          <TextField style={{ marginTop: '1rem', marginBottom: '1rem' }}
            size="small" fullWidth label="Client Secret"
            variant="outlined"
            helperText="Client Secret (x-access-token) for authorization"
            onChange={(e) => setClientSecret(e.target.value)}
          />
          <Typography className="fileupl-title">
            Create a title/transferable document?
          </Typography>
          <FormControl component="fieldset">
            <RadioGroup
              row
              aria-label="position"
              name="position"
              defaultValue="top" value={document_type}

            >
              <FormControlLabel
                value="TRANSFERABLE_RECORD"
                control={<Radio color="primary" />}
                label="Yes"
                onClick={() =>
                  setDocument_type("TRANSFERABLE_RECORD")
                }
              />
              <FormControlLabel
                value="VERIFIABLE_DOCUMENT"
                control={<Radio color="primary" />}
                label="No"
                onClick={() => { setDocument_type("VERIFIABLE_DOCUMENT"); setUpload_method("blockchain") }
                }
              />
            </RadioGroup>
          </FormControl>

          {
            document_type === 'VERIFIABLE_DOCUMENT' && <>
              <Typography className="fileupl-title">
                Upload document to Blockchain?
              </Typography>
              <FormControl component="fieldset">
                <RadioGroup
                  row
                  aria-label="position"
                  name="position"
                  defaultValue="top" value={upload_method}

                >
                  <FormControlLabel
                    value="blockchain"
                    control={<Radio color="primary" />}
                    label="Yes"
                    onClick={() =>
                      setUpload_method("blockchain")
                    }
                  />
                  <FormControlLabel
                    value="did"
                    control={<Radio color="primary" />}
                    label="No"
                    onClick={() =>
                      setUpload_method("did")
                    }
                  />
                </RadioGroup>
              </FormControl>
            </>
          }


          <Button
            variant="contained"
            component="label"
            style={{ margin: "2rem" }}

          >
            Select File
            <input
              type="file"
              hidden onChange={(e) => onSelectFile(e)}
            />
          </Button>
          {file?.name && <Typography className="fileupl-title">
            Selected File: {file?.name}
          </Typography>}
          {file?.name && <Button
            variant="contained"
            component="label"
            onClick={() => upload()}
            style={{ margin: "2rem" }}
          >
            Upload
          </Button>}
          {response && <Typography className="fileupl-title">
            Response: {JSON.stringify(response)}
          </Typography>}

          {docs && <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Id</TableCell>
                  <TableCell>TruDoc Id</TableCell>
                  <TableCell >File</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {docs?.map(row => (<DocRow {...row} clientSecret={clientSecret} key={row.id} />))}
              </TableBody>
            </Table>
          </TableContainer>
          }
          <Button
            variant="contained"
            component="label"
            onClick={reset}
            style={{ margin: "2rem" }}
          >
            Reset
          </Button>

          <Button
            variant="contained"
            component="label"
            onClick={fetchDocs}
            style={{ margin: "2rem" }}
          >
            Check client Docs
          </Button>



        </Box>


      </Box>

    </div>
  );
}



export default ClientUpload;
