import { useEffect } from "react";
import {
  makeStyles,
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Collapse,
  Box,
} from "@material-ui/core";
import { useHistory, NavLink, useLocation } from "react-router-dom";

import DescriptionIcon from "@material-ui/icons/Description";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import AssignmentIcon from "@material-ui/icons/Assignment";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import ThumbDownIcon from "@material-ui/icons/ThumbDown";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import FolderSpecialOutlined from "@material-ui/icons/FolderSpecialOutlined";

import CancelIcon from "@material-ui/icons/Cancel";
import InsertInvitationIcon from "@material-ui/icons/InsertInvitation";
import { useAuth } from "../hooks";

import React from "react";
import logo from "../static/images/logo.png";
import { btoaToBlob, btoaToBlobUrl, isNotEmpty } from "../utils/Generic";
import { useState } from "react";
import { AccountCircleOutlined, AcUnit, Dashboard, ExpandLess, ExpandMore, LibraryAddCheckOutlined, PeopleOutline, Security, SecurityOutlined, Settings, StarBorder } from "@material-ui/icons";
import CreateSignature from "./CreateSignature";

import { useCorporateState } from "./../redux/context/corporate-context";
import { UserRoles } from "../constants";
import { Dvr } from "@material-ui/icons";
import { PeopleAlt } from "@material-ui/icons";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => {
  return {
    page: {
      background: "#f9f9f9",
      width: "100%",
      padding: theme.spacing(1),
    },
    root: {
      display: "flex",
    },
    drawer: {
      width: drawerWidth,
      color: "#FFFFFF",
    },
    drawerPaper: {
      width: drawerWidth,
      color: "#FFFFFF",
      background: "#424343",
      padding: "10px 0",
    },
    active: {
      background: "#f4f4f4",
    },
    title: {
      padding: theme.spacing(2),
    },
    appBar: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
    name: {
      flexGrow: 1,
    },
    logo: {
      width: "150px",
      margin: "20px auto 50px auto",
    },
    toolbar: theme.mixins.toolbar,
    avatar: {
      marginLeft: theme.spacing(2),
    },
    link: {
      color: "#ffffff",
      textDecoration: "none",
    },
  };
});

function SidePanel({ children }) {
  const classes = useStyles();
  const activeTextColor = { color: "#66CFA4" };

  const location = useLocation();
  const history = useHistory();

  const { removeUser, user } = useAuth();

  const [openMenu, setOpenMenu] = useState(null);
  const [openSubMenu, setOpenSubMenu] = useState(null);
  const [openSubSubMenu, setOpenSubSubMenu] = useState(null);

  const [openSignModal, setOpenSignModal] = React.useState(false);

  const corporateState = useCorporateState();

  useEffect(() => {
    document.title = "Dashboard | Trudoc";
  }, []);

  const handleSignModalOpen = () => {
    setOpenSignModal(true);
  };

  const handleSignModalClose = () => {
    setOpenSignModal(false);
  };

  const getMenuIndex = (...indexData) => {
    return indexData.join("_");
  }

  const handleMenuClick = (...indexData) => {
    let menuNeededIndex = getMenuIndex(...indexData);
    if (openMenu === menuNeededIndex) {
      setOpenMenu(null);
    } else {
      setOpenMenu(menuNeededIndex);
    }
    setOpenSubMenu(null);
    setOpenSubSubMenu(null);
  }

  const handleSubMenuClick = (...indexData) => {
    let menuNeededIndex = getMenuIndex(...indexData);
    if (openSubMenu === menuNeededIndex) {
      setOpenSubMenu(null);
    } else {
      setOpenSubMenu(menuNeededIndex);
    }
    setOpenSubSubMenu(null);
  }

  const handleSubSubMenuClick = (...indexData) => {
    let menuNeededIndex = getMenuIndex(...indexData);
    if (openSubSubMenu === menuNeededIndex) {
      setOpenSubSubMenu(null);
    } else {
      setOpenSubSubMenu(menuNeededIndex);
    }
  }

  const logout = () => {
    removeUser();
    history.replace("/login");
  };

  const handleLinkClick = (linkPath) => {
    if (isNotEmpty(linkPath)) {
      switch (linkPath) {
        // case "create-esigns":
        //   handleSignModalOpen();
        //   break;      
        case "logout":
          logout();
          break;
        default:
          history.push({ pathname: linkPath });
          break;
      }
    }
  }

  const isActivePath = (linkPath) => {
    // console.log(location);
    if (isNotEmpty(linkPath)) {
      let pathName = window.location.pathname.toString();
      if (pathName == linkPath) {
        return true;
      }
    }
    return false;
  }

  const openSignModalCall = () => {
    return <CreateSignature openValue={openSignModal} handleCloseModal={handleSignModalClose} />;
  };

  const getMenu = () => {
    let profileMenuName = user?.username.toString().toUpperCase();

    let menuItems = [
      // {
      //   text: "DOCUMENTS",
      //   icon: <DescriptionIcon color="secondary" />,
      //   path: "/documents",
      // },
      {
        text: "My Workspace",
        icon: <Dashboard color="secondary" />,
        menu: [
          {
            text: "Dashboard",
            path: "/dashboard"
          },
          {
            text: "Calendar",
            path: "/calendar"
          },
          {
            text: "Chat",
            path: "/chat/list"
          },
          {
            text: "To-Do List",
            path: "/to-do"
          },
        ]
      },
      {
        text: "Store",
        icon: <FolderSpecialOutlined color="secondary" />,
        path: "/dms"
      },
      {
        text: "Trust",
        icon: <SecurityOutlined color="secondary" />,
        menu: [
          {
            text: "TruSign",
            menu: [
              {
                text: "Sign a Document",
                path: "/sign-document"
              },
              {
                text: "Awaiting My Sign",
                path: "/dashboard/tru-sign/awaiting_my_sign",
              },
              {
                text: "Request Signature",
                menu: [
                  {
                    text: "Upload",
                    path: "/e-document"
                  },
                  {
                    text: "Awaiting Others",
                    path: "/dashboard/tru-sign/awaiting_others_sign",
                  },
                  {
                    text: "Completed",
                    path: "/dashboard/tru-sign/completed",
                  },
                  {
                    text: "Declined",
                    path: "/dashboard/tru-sign/declined",
                  },
                ]
              },
            ]
          },
          {
            text: "Verifiable Document",
            menu: [
              {
                text: "Upload",
                path: "/upload"
              },
              {
                text: "My Wallet",
                path: "/dashboard/verifiable/my_wallet"
              },
              {
                text: "Awaiting My Sign",
                path: "/dashboard/verifiable/awaiting_my_sign",
              },
              {
                text: "Awaiting Others",
                path: "/dashboard/verifiable/awaiting_others_sign",
              },
              {
                text: "Completed",
                path: "/dashboard/verifiable/completed",
              },
              {
                text: "Declined",
                path: "/dashboard/verifiable/declined",
              },
            ]
          },
        ]
      },
      {
        text: "Verify",
        icon: <LibraryAddCheckOutlined color="secondary" />,
        path: "/verify"
      },
      corporateState.is_sustainability == 1 ? {
        text: "Sustainability",
        icon: <AcUnit color="secondary" />,
        path: "/sustainability/dashboard"
      } : {},
      {
        text: "Templates",
        icon: <Dvr color="secondary" />,
        menu: [
          {
            text: "Invoice",
            menu: [
              {
                text: "Create Invoice",
                path: "/maker/invoice"
              },
              {
                text: "Customers",
                path: "/customers"
              },
            ]
          },
          {
            text: "Certificate of Authenticity (Art Work)",
            path: "/maker/certificate"
          },
        ]
      },
      {
        text: profileMenuName,
        icon: <AccountCircleOutlined color="secondary" />,
        menu: [
          {
            text: "Profile",
            menu: [
              {
                text: "Change Password",
                path: "/profile"
              },
              {
                text: "Create E-Signature",
                path: "/create-esign"
              },
            ]
          },
          {
            text: "Logout",
            path: "logout"
          },
        ]
      },
    ];

    if (user?.role === UserRoles.USER) {
      menuItems = [
        {
          text: "My Workspace",
          icon: <Dashboard color="secondary" />,
          menu: [
            {
              text: "Dashboard",
              path: "/dashboard"
            },
            {
              text: "Chat",
              path: "/chat/list"
            },
          ]
        },
        {
          text: "TruSign",
          icon: <Dvr color="secondary" />,
          menu: [
            {
              text: "Sign a Document",
              path: "/sign-document"
            },
            {
              text: "Awaiting My Sign",
              path: "/dashboard/tru-sign/awaiting_my_sign",
            },
            {
              text: "My Wallet",
              path: "/dashboard/tru-sign/my_wallet"
            },
            {
              text: "Request Signature",
              menu: [
                {
                  text: "Upload",
                  path: "/e-document"
                },
                {
                  text: "Awaiting Others",
                  path: "/dashboard/tru-sign/awaiting_others_sign",
                },
                {
                  text: "Completed",
                  path: "/dashboard/tru-sign/completed",
                },
                {
                  text: "Declined",
                  path: "/dashboard/tru-sign/declined",
                },
              ]
            },
          ]
        },
        {
          text: profileMenuName,
          icon: <AccountCircleOutlined color="secondary" />,
          menu: [
            {
              text: "Profile",
              menu: [
                {
                  text: "Change Password",
                  path: "/profile"
                },
                {
                  text: "Create E-Signature",
                  path: "/create-esign"
                },
              ]
            },
            {
              text: "Logout",
              path: "logout"
            },
          ]
        },
      ];
    }

    if (user?.role === UserRoles.SUPER_ADMIN) {
      menuItems = [
        {
          text: "Dashboard",
          icon: <Dashboard color="secondary" />,
          path: "/super/dashboard"
        },
        {
          text: "Corporates",
          icon: <PeopleOutline color="secondary" />,
          path: "/super/corporates"
        },
        {
          text: "Settings",
          icon: <Settings color="secondary" />,
          path: "/super/settings"
        },
        {
          text: profileMenuName,
          icon: <AccountCircleOutlined color="secondary" />,
          menu: [
            {
              text: "Change Password",
              path: "/profile"
            },
            {
              text: "Logout",
              path: "logout"
            },
          ]
        },
      ];
    }

    return menuItems;

  }


  return (
    <>
      <Drawer
        className={`app-drawer-main ${classes.drawer}`}
        variant="permanent"
        classes={{ paper: classes.drawerPaper }}
        anchor="left"
      >
        {/* <Typography variant="h5" className={classes.title}>
          TruDoc
        </Typography> */}
        <NavLink to="/" style={{ textAlign: "center" }}>
          {
            isNotEmpty(corporateState?.business_logo) ? <>
              <img src={btoaToBlobUrl(corporateState?.business_logo)} alt={corporateState?.business_name} className={classes.logo} />
            </> : <>
              <img src={logo} alt="TruDoc" className={classes.logo} />
            </>
          }
        </NavLink>

        <List component="ul" disablePadding>
          {getMenu().map((item, index) => (
            <>
              {
                isNotEmpty(item.menu) ? <>
                  <Box className={`level-menu mainMenu ${openMenu === getMenuIndex(index) ? 'opened' : ''}`}>
                    <ListItem className={`menu-box`} button onClick={(e) => handleMenuClick(index)}>
                      {isNotEmpty(item.icon) ? <ListItemIcon>{item.icon}</ListItemIcon> : null}
                      <ListItemText primary={item.text} />
                      {openMenu === getMenuIndex(index) ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={openMenu === getMenuIndex(index)} timeout="auto" unmountOnExit>
                      <List component="ul" disablePadding>
                        {item.menu.map((subItem, subIndex) => (
                          <>
                            {
                              isNotEmpty(subItem.menu) ? <>
                                <Box className={`level-menu subMenu ${openSubMenu === getMenuIndex(index, subIndex) ? 'opened' : ''}`}>
                                  <ListItem className={`menu-box`} button onClick={(e) => handleSubMenuClick(index, subIndex)}>
                                    {isNotEmpty(subItem.icon) ? <ListItemIcon>{subItem.icon}</ListItemIcon> : null}
                                    <ListItemText className="menu-text" primary={subItem.text} />
                                    {openSubMenu === getMenuIndex(index, subIndex) ? <ExpandLess /> : <ExpandMore />}
                                  </ListItem>
                                  <Collapse in={openSubMenu === getMenuIndex(index, subIndex)} timeout="auto" unmountOnExit>
                                    <List component="ul" disablePadding>
                                      {subItem.menu.map((subSubItem, subSubIndex) => (
                                        <>
                                          {
                                            isNotEmpty(subSubItem.menu) ? <>
                                              <Box className={`level-menu subSubMenu ${openSubSubMenu === getMenuIndex(index, subIndex, subSubIndex) ? 'opened' : ''}`}>
                                                <ListItem className={`menu-box`} button onClick={(e) => handleSubSubMenuClick(index, subIndex, subSubIndex)}>
                                                  {isNotEmpty(subSubItem.icon) ? <ListItemIcon>{subSubItem.icon}</ListItemIcon> : null}
                                                  <ListItemText className="menu-text" primary={subSubItem.text} />
                                                  {openSubSubMenu === getMenuIndex(index, subIndex, subSubIndex) ? <ExpandLess /> : <ExpandMore />}
                                                </ListItem>
                                                <Collapse in={openSubSubMenu === getMenuIndex(index, subIndex, subSubIndex)} timeout="auto" unmountOnExit>
                                                  <List component="ul" disablePadding>
                                                    {subSubItem.menu.map((subSubSubItem, subSubSubIndex) => (
                                                      <>
                                                        <Box className={`level-menu subSubSubMenu`}>
                                                          <ListItem className={`menu-box menu-link ${isActivePath(subSubSubItem?.path) ? 'active' : ''}`} button key={subSubSubItem.text} onClick={(e) => handleLinkClick(subSubSubItem?.path)}>
                                                            {isNotEmpty(subSubSubItem.icon) ? <ListItemIcon>{subSubSubItem.icon}</ListItemIcon> : null}
                                                            <ListItemText className="menu-text" primary={subSubSubItem.text} />
                                                          </ListItem>
                                                        </Box>
                                                      </>
                                                    ))}
                                                  </List>
                                                </Collapse>
                                              </Box>
                                            </>
                                              : <>
                                                <Box className={`level-menu subSubMenu`}>
                                                  <ListItem className={`menu-box menu-link ${isActivePath(subSubItem?.path) ? 'active' : ''}`} button key={subSubItem.text} onClick={(e) => handleLinkClick(subSubItem?.path)}>
                                                    {isNotEmpty(subSubItem.icon) ? <ListItemIcon>{subSubItem.icon}</ListItemIcon> : null}
                                                    <ListItemText className="menu-text" primary={subSubItem.text} />
                                                  </ListItem>
                                                </Box>
                                              </>
                                          }
                                        </>
                                      ))}
                                    </List>
                                  </Collapse>
                                </Box>
                              </>
                                : <>
                                  <Box className={`level-menu subMenu`}>
                                    <ListItem className={`menu-box menu-link ${isActivePath(subItem?.path) ? 'active' : ''}`} button key={subItem.text} onClick={(e) => handleLinkClick(subItem?.path)}>
                                      {isNotEmpty(subItem.icon) ? <ListItemIcon>{subItem.icon}</ListItemIcon> : null}
                                      <ListItemText className="menu-text" primary={subItem.text} />
                                    </ListItem>
                                  </Box>
                                </>
                            }
                          </>
                        ))}
                      </List>
                    </Collapse>
                  </Box>
                </>
                  : 
                  <>
                  {
                    Object.keys(item).length > 0 &&
                    <Box className={`level-menu mainMenu`}>
                      <ListItem className={`menu-box menu-link ${isActivePath(item?.path) ? 'active' : ''}`} button key={item.text} onClick={(e) => handleLinkClick(item?.path)}>
                        {isNotEmpty(item.icon) ? <ListItemIcon>{item.icon}</ListItemIcon> : null}
                        <ListItemText primary={item.text} />
                      </ListItem>
                    </Box>
                  }
                  </>
              }
            </>
          ))}
        </List>
      </Drawer>

      <div className={classes.content}>{children}</div>

      {openSignModal && openSignModalCall()}

    </>
  );
}

export default SidePanel;
