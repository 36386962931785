import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
  AppBar,
  Box,
  Grid,
  Slider,
  TableCell,
  TableRow
} from "@material-ui/core";
import { makeStyles, styled } from "@material-ui/core/styles";
import { BiCheck } from 'react-icons/bi';
import { UNSDG_GOALS, UNSDG_GOAL_CATEGORIES } from "../../utils/unsdg-goals";
import { goalsParseCategories, goalsParseSubjects } from "../../utils/Generic";
import UnsdgGrid from "./unsdg-grid";

const useStyles = makeStyles((theme) => ({
  root: {
    width: `calc(100% - ${300}px)`,
    marginLeft: 280,
    minHeight: "88vh",
  },
}));

const UnsdgRelevance = (props) => {

  const { goals, setGoals, canSave, objectives } = props;

  const [activeObjectiveIndex, setActiveObjectiveIndex] = useState(0);
  const [subjects, setSubjects] = useState([]);

  useEffect(() => {
    setSubjects(goalsParseSubjects(goals));
  }, [goals]);

  const activeIndexNext = () => {
    if (activeObjectiveIndex < (subjects.length - 1)) {
      setActiveObjectiveIndex((activeObjectiveIndex + 1));
    } else {
      props.onNext('unsdg-relevance')
    }
  }

  const activeIndexPrev = () => {
    if (activeObjectiveIndex > 0) {
      setActiveObjectiveIndex((activeObjectiveIndex - 1));
    } else {
      props.onPrevious('unsdg-relevance')
    }
  }

  const marks = [
    {
      value: 0
    },
    {
      value: 25
    },
    {
      value: 50
    },
    {
      value: 75
    },
    {
      value: 100
    },
  ];

  const getRelevanceLevelVal = (relevanceLevel) => {
    switch (relevanceLevel) {
      case 'negligible':
        return 25;
        break;
      case 'moderate':
        return 50;
        break;
      case 'significant':
        return 75;
        break;
      case 'major':
        return 100;
        break;
    }
    return 0;
  }

  const getRelevanceLevelText = (relevanceLevelVal) => {
    switch (relevanceLevelVal) {
      case 25:
        return 'negligible';
        break;
      case 50:
        return 'moderate';
        break;
      case 75:
        return 'significant';
        break;
      case 100:
        return 'major';
        break;
    }
    return '';
  }

  const handleRelevanceChange = (e, subject, type) => {
    if (canSave) {
      let tempGoals = { ...goals };
      if (tempGoals.hasOwnProperty(subject.goalNumber)) {
        tempGoals[subject.goalNumber]['subjects'][subject.title][type] = {
          value: getRelevanceLevelVal(e.target.value),
          textValue: e.target.value,
        };
      }
      setGoals(tempGoals);
    }
  }

  const handleRelevanceSlideChange = (e, value, subject, type) => {
    if (canSave) {
      let relevanceLevel = getRelevanceLevelText(value);
      let tempGoals = { ...goals };
      if (tempGoals.hasOwnProperty(subject.goalNumber)) {
        tempGoals[subject.goalNumber]['subjects'][subject.title][type] = {
          value: value,
          textValue: relevanceLevel,
        };
      }
      setGoals(tempGoals);
    }
  }

  return (
    <>

      <div className='undsg_main'>
        <h1 className='text-gradient text-center'>UNSDG Relevance</h1>
        <div className='steps text-center'>
          <p className="completed">Step 1 <BiCheck size={25} color='var(--link)' /></p>
          <p><span></span></p>
          <p className="active">Step 2</p>
        </div>

        <Box sx={{ flexGrow: 1 }}>

          {
            Object.keys(subjects).length <= 0 ? <>
              <p className="no-results">No Material Topics Found</p>
            </> : <>


              <div className="new-design">
                <div className="main-discrimination-box">

                  <div className="left-side-box">
                    {
                      subjects.map((subject, index) => {
                        if (index === activeObjectiveIndex) {
                          return <>
                            <div>
                              <h2 className="numbering"> <span>{index + 1}</span> out of {subjects.length} <span className="d-none">{subject?.goalNumber}-{subject?.category?.id}</span></h2>
                              <p># {subject?.title}</p>
                              <div className="input-group-discrimination">
                                <p>Impact on people and the environment</p>
                                <div className="ds-flex item-center item-between">
                                  <p className="relevance">Relevance Level</p>
                                  <div className="select-padded">
                                    <select value={subject?.peopleEnvironmentImpactRelevance.textValue} onChange={(e) => handleRelevanceChange(e, subject, "peopleEnvironmentImpactRelevance")} defaultValue={'medium'}>
                                      <option value="">
                                        Select
                                      </option>
                                      <option value="negligible">
                                        Negligible
                                      </option>
                                      <option value="moderate">
                                        Moderate
                                      </option>
                                      <option value="significant">
                                        Significant
                                      </option>
                                      <option value="major">
                                        Major
                                      </option>
                                    </select>
                                  </div>
                                </div>
                                <div className="goal-range">
                                  <Slider className="unsdg-range" style={{ color: subject.goal.color }} onChange={(e, value) => handleRelevanceSlideChange(e, value, subject, "peopleEnvironmentImpactRelevance")} marks={marks} step={null} value={subject?.peopleEnvironmentImpactRelevance?.value} aria-label="Default" valueLabelDisplay="auto" />
                                </div>
                              </div>
                              <div className="input-group-discrimination">
                                <p>Impact on business success</p>
                                <div className="ds-flex item-center item-between">
                                  <p className="relevance">Relevance Level</p>
                                  <div className="select-padded">
                                    <select value={subject?.businessImpactRelevance.textValue} onChange={(e) => handleRelevanceChange(e, subject, "businessImpactRelevance")} defaultValue={'medium'}>
                                      <option value="">
                                        Select
                                      </option>
                                      <option value="negligible">
                                        Negligible
                                      </option>
                                      <option value="moderate">
                                        Moderate
                                      </option>
                                      <option value="significant">
                                        Significant
                                      </option>
                                      <option value="major">
                                        Major
                                      </option>
                                    </select>
                                  </div>
                                </div>
                                <div className="goal-range">
                                  <Slider className="unsdg-range" style={{ color: subject.goal.color }} onChange={(e, value) => handleRelevanceSlideChange(e, value, subject, "businessImpactRelevance")} marks={marks} step={null} value={subject?.businessImpactRelevance?.value} aria-label="Default" valueLabelDisplay="auto" />
                                </div>
                              </div>
                              <div className="buttons-div">
                                <button onClick={activeIndexPrev} className="white-btn">Go Back</button>
                                <button onClick={activeIndexNext} className="navy-btn">Next</button>
                              </div>

                              <Box className="btn-sustainability-box">
                                <div className="left w-100">
                                  {
                                    canSave ? <>
                                      <button onClick={(e) => props?.onSave()} className="w-100 btn-sustainability secondary">
                                        Save
                                      </button>
                                    </> : null
                                  }
                                </div>
                                {/* <div className="right">
            <button onClick={(e) => props.onPrevious('unsdg-relevance')} className="btn-sustainability primary-out me-10px">
              Back
            </button>
            <button onClick={(e) => props.onNext('unsdg-relevance')} className="btn-sustainability primary">
              Next
            </button>
          </div> */}
                              </Box>

                            </div>
                          </>
                        }
                      })
                    }
                  </div>

                  <div className="right-side-box">
                    <UnsdgGrid goals={goals}  objectives={objectives}/>
                  </div>
                </div>

              </div>

            </>
          }

        </Box>



      </div>

    </>
  );
};

export default UnsdgRelevance;