import { useState, Fragment, forwardRef, useEffect } from 'react';
import { Slide, Typography, makeStyles, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, CircularProgress } from '@material-ui/core';
import { getPdf, updateSignTransaction } from "../../services/api.service";
import { LoaderSpinner } from "@govtechsg/tradetrust-ui-components";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { signDoc, downloadTt, getSignTransaction } from '../../services/api.service';
import { useAuth } from '../../hooks';
import { getDocuments } from "../../services/other.service";
import { DocStatus, sidePanelFilterMapping } from '../../constants';
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import SidePanelAction from "../../redux/actions/Sidepanel.action";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from '@material-ui/lab/Alert';
import FillSignature from '../../utils/WebViewer/FillSignature';
import { useMetaMask } from 'metamask-react';
import MetaMaskTransaction from '../MetaMaskTransaction';
import { parseWeb3Error } from '../../utils/Generic';
import { MessageConstants } from '../../utils/MessageConstants';
const Web3 = require('web3');

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="top" ref={ref} {...props} />;
});
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => {
  return {
    dialogRoot: {
      width: '75%',
      borderRadius: 50,
      margin: '0 auto',
    },
    loader: {
      position: "absolute",
      left: "50%",
      top: "50%"
    },
    // modalTitleFlex:{
    //   display:"flex",
    //   justifyContent:"space-between"
    // },
    contentHeight: {
      minWidth: '100%',
      minHeight: '350px',
      maxWidth: '100%',
      margin: '0 auto'
    },
    title: {
      paddingTop: 20,
      textAlign: 'center',
      fontWeight: '600',
      textTransform: 'uppercase',
      letterSpacing: 0.5,
      wordSpacing: 2,
    },
    stepText: {
      fontWeight: '600',
      paddingBottom: 30,
      textAlign: 'center',
      color: theme.palette.admin.main,
      letterSpacing: 1,
    },
    actionBar: {
      justifyContent: 'center',
      marginBottom: 50
    },
    nextButton: {
      width: 220,
      height: 50,
      borderRadius: 25,
      color: '#fff',
      letterSpacing: 1,
      backgroundColor: theme.palette.admin.main,
      '&:hover': {
        backgroundColor: theme.palette.admin.main,
      }
    },
    draftButton: {
      width: 220,
      height: 50,
      borderRadius: 25,
      color: '#fff',
      letterSpacing: 1,
      backgroundColor: 'gray',
      '&:hover': {
        backgroundColor: 'gray',
      }
    },
    // titleRed:{
    //   color:"red"
    // },
    // titleGreen:{
    //   color:"green"
    // }
  }
});

const PdfViewer = ({ data, open, handleClose, pdfName, edocs, radio }) => {
  const [basePdf, setBasePdf] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [loading, setLoading] = useState();
  const { user } = useAuth();
  const { filterType } = useParams();
  const dispatch = useDispatch();
  
  const [showToast, setShowToast] = useState();
  const [showErrorToast, setShowErrorToast] = useState();

  const { status, connect, account, chainId, ethereum } = useMetaMask();
  const [openMetaMaskModal, setOpenMetaMaskModal] = useState(null);
  const [walletAddr, setWalletAddr] = useState(null);
  const [metaMaskTransactionDetails, setMetaMaskTransactionDetails] = useState(null);
  const [metaMaskTansactionStarted, setMetaMaskTansactionStarted] = useState(null);
  const [metaMaskTransactionProgressText, setMetaMaskTransactionProgressText] = useState(false);

  const handleToastClose = () => {
    setShowToast('');
  }

  const handleErrorToastClose = () => {
    setShowErrorToast('');
  }

  useEffect(() => {
    console.log('use effect callll', pdfName, "data:", data);
    getPdf({ filename: data.storage_key, edocs, docId: data?.id })
      .then((res) => {
        setBasePdf(res.data.data.data ? `data:application/pdf;base64,${res.data.data.data}` : res.data.data);
        setIsLoading(false);
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  const handleSignDocument = (type = "SIGNED") => {
    console.log("data", data);
    if (data.status === DocStatus.AWAITING_APPROVAL) {
      handleTransaction();
    } else {
      setLoading(type);
      if (data) {
        signDoc({
          document_id: data.id,
          email: user.email,
          status: type,
        }).then(res => {
          if (res && res?.status === 200 && res?.data) {
            setShowToast(res?.data?.message);
            setLoading(null);
            setTimeout(() => {
              updateSidePanel(true);
            }, 1000);
          }
        })
      }
    }
  }

  const updateSidePanel = () => {
    getDocuments({
      email: user.email,
      condition: sidePanelFilterMapping[filterType]
    }).then((res) => {
      if (res && res?.status === 200 && res?.data)
        dispatch(SidePanelAction([filterType], [...res?.data]));
    });
    handleClose();
  }

  const classes = useStyles();

  const showError = (err) => {
    setLoading(null);
    setMetaMaskTansactionStarted(false);
    let errorMsg = err?.message;
    if (typeof err == "string") {
      errorMsg = err;
    }
    setShowErrorToast(errorMsg);
    // setToast((prev) => ({
    //   ...prev,
    //   isOpen: true,
    //   isError: true,
    //   isSuccess: false,
    //   message: errorMsg
    // }));
  }

  const handleTransaction = () => {
    setLoading('SIGNED');
    getSignTransaction({ docId: data.id })
      .then((res) => {
        let data = res?.data;
        setMetaMaskTransactionDetails(data);
        setWalletAddr(data?.walletAddress);
        setOpenMetaMaskModal(true);
      }) 
      .catch((err) => {
        showError(err);
        setLoading(null);
      });
  }

  const closeModalForTransaction = (e) => {
    setLoading(null);
    setMetaMaskTansactionStarted(false);  
    setOpenMetaMaskModal(false);
  }

  const markDocumentIssued = (contractTransactionHashes = {}) => {
    let transactionDetails = metaMaskTransactionDetails;
    updateSignTransaction({
      docId: data.id,
      contractTransactionHashes,
      wrappedDocument: transactionDetails?.wrappedDocument
    })
      .then((res) => {
        let data = res?.data;
        if (data.success) {
          setMetaMaskTransactionDetails(null);
          setMetaMaskTransactionProgressText(null);
          setMetaMaskTansactionStarted(false);

          setShowToast(MessageConstants.DOCUMENT_ISSUED);
          setLoading(null);
          setTimeout(() => {
            updateSidePanel(true);
          }, 1000);

        } else {
          throw data?.message;
        }
      })
      .catch((err) => {
        showError(err);
      });
  }

  const startMetaMaskTransaction = () => {
    try {
      let transactionDetails = metaMaskTransactionDetails;
      if (transactionDetails != null) {
        //setIsLoading(true);
        const web3Instance = new Web3(ethereum);
        let contracts = transactionDetails?.contracts;

        setMetaMaskTansactionStarted(true);
        setMetaMaskTransactionProgressText("Initializing MetaMask...");

        if (transactionDetails?.documentType === "TRANSFERABLE_RECORD") {

          const titleEscrowCreatorContract = new web3Instance.eth.Contract(JSON.parse(contracts?.titleEscrowCreator?.abi), contracts?.titleEscrowCreator?.address);
          //console.log(titleEscrowCreatorContract.methods);

          setMetaMaskTransactionProgressText("Deploying to Transferable Contract...");

          titleEscrowCreatorContract.methods.deployNewTitleEscrow(
            transactionDetails?.contractAddress,
            transactionDetails?.ownership?.beneficiaryAddress,
            transactionDetails?.ownership?.holderAddress
          ).send({
            from: transactionDetails?.walletAddress
          })
            .on('receipt', function (receipt) {
              console.log(receipt);
              let deployedTitleEscrowAddress = receipt.events[0].address;

              const tokenRegistryContract = new web3Instance.eth.Contract(JSON.parse(contracts?.tokenRegistry?.abi), contracts?.tokenRegistry?.address);
              //console.log(tokenRegistryContract.methods);

              setMetaMaskTransactionProgressText("Deploying to Token Registry...");

              tokenRegistryContract.methods.safeMint(
                deployedTitleEscrowAddress,
                transactionDetails?.merkleRoot
              ).send({
                from: transactionDetails?.walletAddress
              })
                .on('receipt', function (receipt2) {
                  markDocumentIssued({
                    tokenRegistry: receipt2?.transactionHash,
                    titleEscrow: receipt2?.transactionHash
                  });
                })
                .on('error', function (error, receipt2) {
                  showError(parseWeb3Error(error));
                });

            })
            .on('error', function (error, receipt) {
              showError(parseWeb3Error(error));
            });

        } else if (transactionDetails?.documentType === "VERIFIABLE_DOCUMENT") {

          const documentStoreContract = new web3Instance.eth.Contract(JSON.parse(contracts?.documentStore?.abi), contracts?.documentStore?.address);
          // console.log(documentStoreContract.methods);

          setMetaMaskTransactionProgressText("Deploying to Document Store...");

          documentStoreContract.methods.issue(
            transactionDetails?.merkleRoot
          ).send({
            from: transactionDetails?.walletAddress
          })
            .on('receipt', function (receipt) {
              markDocumentIssued({
                documentStore: receipt?.transactionHash
              });
            })
            .on('error', function (error, receipt) {
              showError(parseWeb3Error(error));
            });
        }
      }
    } catch (err) {
      showError(err);
    }
  }

  return (
    <Fragment>

      <Dialog
        fullWidth={true}
        maxWidth='md'
        open={open}
        onClose={handleClose}
        className={classes.dialogRoot}
        keepMounted={false}
        TransitionComponent={Transition}
        PaperProps={{
          style: { borderRadius: 20 }
        }}
      >
        <DialogTitle className="modalTitleFlex">
          {
            data.status === DocStatus.AWAITING_APPROVAL ?
              <>

                {filterType === 'awaiting_my_sign' &&
                  <Typography variant='p' className="titleGreen">
                    <Button onClick={() => handleSignDocument("SIGNED")}>
                      Approve   {loading === "SIGNED" ? <CircularProgress color="primary" size={20} /> : <CheckCircleOutlineIcon
                        style={{
                          marginLeft: "10px"
                        }} />}
                    </Button>
                  </Typography>}

              </> : <>

                {!radio && filterType !== 'completed' && filterType !== 'declined' && filterType !== 'expired' && filterType !== 'awaiting_others_sign' && <Typography variant='p' className="titleRed">
                  <Button onClick={() => handleSignDocument("REJECTED")}> {filterType !== 'my_wallet' ? "Reject" : "Remove"}
                    {loading === 'REJECTED' ? <CircularProgress color="primary" size={20} /> : <HighlightOffIcon
                      style={{
                        marginLeft: "10px"
                      }}
                    />}
                  </Button>
                </Typography>}
                {filterType === 'awaiting_my_sign' &&
                  <Typography variant='p' className="titleGreen">
                    <Button onClick={() => handleSignDocument("SIGNED")}>
                      Approve   {loading === "SIGNED" ? <CircularProgress color="primary" size={20} /> : <CheckCircleOutlineIcon
                        style={{
                          marginLeft: "10px"
                        }} />}
                    </Button>
                  </Typography>}
                {/* <Button onClick={() => handleSignDocument("SIGNED")}>
          {loading === "SIGNED" ?  <CircularProgress color="primary" size={20}/> : <CheckCircleOutlineIcon />}
        </Button> */}

              </>
          }
        </DialogTitle>

        <DialogContent className={classes.contentHeight} fullWidth={true} fullHeight={true} maxWidth={"xl"} maxHeight={"xl"}>
          {/* Show The PDF here */}
          {isLoading ? <CircularProgress color="primary" size={20} className={classes.loader} /> :
            <iframe src={`${basePdf}`} height="500px" width="95%"></iframe>
          }
        </DialogContent>

      </Dialog>


      <MetaMaskTransaction
        open={openMetaMaskModal}
        walletAddress={walletAddr}
        transactionStarted={metaMaskTansactionStarted}
        transactionProgressText={metaMaskTransactionProgressText}

        handleClose={closeModalForTransaction}
        handleStart={startMetaMaskTransaction}
      />

      <Snackbar open={Boolean(showToast)} autoHideDuration={6000} onClose={() => handleToastClose()}>
        <Alert onClose={() => handleToastClose()} severity="success">
          {showToast}
        </Alert>
      </Snackbar>

      <Snackbar open={Boolean(showErrorToast)} autoHideDuration={6000} onClose={() => handleErrorToastClose()}>
        <Alert onClose={() => handleErrorToastClose()} severity="error">
          {showErrorToast}
        </Alert>
      </Snackbar>

    </Fragment>



  );
}

export default PdfViewer;