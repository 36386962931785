import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  LinearProgress,
  Paper,
  Slider,
  Snackbar,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography
} from "@material-ui/core";
import { makeStyles, styled } from "@material-ui/core/styles";
import Toast from "../../utils/Toast";
import { Alert, Autocomplete, createFilterOptions, TabContext, TabPanel } from "@material-ui/lab";
import { AiOutlineUpload, AiFillDelete, AiFillCheckSquare, AiFillPlusCircle } from 'react-icons/ai';
import { IoArrowBackCircleSharp } from 'react-icons/io5';
import { AiOutlineHistory, AiOutlineLink } from 'react-icons/ai';
import { AiOutlineMenu } from 'react-icons/ai';
import { FiPaperclip } from 'react-icons/fi';
import { BsFillInfoCircleFill } from 'react-icons/bs'
import { SlideshowRounded } from "@material-ui/icons";
import { BiCheck } from 'react-icons/bi';
import { UNSDG_GOALS, UNSDG_GOAL_CATEGORIES } from "../../utils/unsdg-goals";
import GoalBox from "./goal-box";
import GoalBoxSubjects from "./goal-box-subjects";
import UnsdgSubjectSelector from "./unsdg-subject-selectors";

const useStyles = makeStyles((theme) => ({
  root: {
    width: `calc(100% - ${300}px)`,
    marginLeft: 280,
    minHeight: "88vh",
  },
}));

const UnsdgGoals = (props) => {

  const classes = useStyles();

  const { goals, setGoals, canSave } = props;

  const toastInitialValues = {
    isOpen: false,
    isSuccess: false,
    isError: false,
    message: ""
  }

  const [toast, setToast] = useState(toastInitialValues);

  const closeToast = () => {
    return setToast((prev) => ({
      ...prev,
      isOpen: false,
      isSuccess: false,
      isError: false,
      message: ""
    }));
  };

  const showError = (errorMsg) => {
    setToast((prev) => ({
      ...prev,
      isOpen: true,
      isError: true,
      isSuccess: false,
      message: errorMsg
    }));
  }

  const showSuccess = (succMsg) => {
    setToast((prev) => ({
      ...prev,
      isOpen: true,
      isError: false,
      isSuccess: true,
      message: succMsg
    }));
  }

  return (
    <>

      {toast.isOpen && <Toast message={toast.message} isError={toast.isError} isSuccess={toast.isSuccess} closeToast={closeToast} />}

      <div className='undsg_main'>
        <h1 className='.text-gradient text-center'>UNSDG Relevance</h1>
        <div className='steps text-center'>
          <p className="active">Step 1</p>
          <p><span></span></p>
          <p>Step 2</p>
        </div>

        <UnsdgSubjectSelector canSave={canSave} goals={goals} setGoals={setGoals}/>

      </div>

      <div className='undsg_main'>

        <Box className="btn-sustainability-box">
          <div className="left">
            {
              canSave ? <>
                <button onClick={(e) => props?.onSave()} className="btn-sustainability secondary">
                  Save
                </button>
              </> : null
            }
          </div>
          <div className="right">
            <button onClick={(e) => props.onPrevious('unsdg')} className="btn-sustainability primary-out me-10px">
              Back
            </button>
            <button onClick={(e) => props.onNext('unsdg')} className="btn-sustainability primary">
              Next
            </button>
          </div>
        </Box>

      </div>

    </>
  );
};

export default UnsdgGoals;