import React, { useRef, useEffect, useState } from "react";
import WebViewer from "@pdftron/webviewer";


import { CircularProgress, makeStyles } from "@material-ui/core";
import {
  Box,
  Grid,
} from "@material-ui/core";
import Toast from "../Toast";
import { useAuth } from "../../hooks";
import "./Viewer.css";
import { base64ImageDimensions, inWords, isNotEmpty, svgToImage, updateDimensionsAsPerSizes, withAppUrl } from "../Generic";
import { MenuItem } from "@material-ui/core";
import { Select } from "@material-ui/core";
import { getSignList } from "../../services/api.service";
import { MessageConstants } from "../MessageConstants";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: "transparent",
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  paper1: {
    display: "flex",
    flexWrap: "wrap",
    backgroundColor: "transparent",
    "& > *": {
      margin: theme.spacing(3),
      padding: theme.spacing(2),
      width: "100%",
      textAlign: "center",
    },
  },
  edocuments: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  paper2: {
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(3),
      padding: theme.spacing(2),
      width: "100%",
    },
  },
  paper3: {
    backgroundColor: "transparent",
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(3),
      padding: theme.spacing(2),
      width: "100%",
      height: 350,
    },
  },
  dropIcon: {
    width: "150px",
    padding: theme.spacing(2),
  },
  docOptions: {
    cursor: "pointer",
    background: "#66cfa3",
    padding: "5px 20px",
    color: "#fff",
    borderRadius: "20px",
  },
  icon: {
    margin: theme.spacing(1),
  },
  formControl: {
    minWidth: "100%",
  },
  contentMargin: {
    marginTop: theme.spacing(3),
  },
  addSign: {
    color: "#3da543",
    background: "#d9d9d940",
    padding: "3px 8px",
    borderRadius: "10px",
  },
  selectBox: {
    marginRight: "20px",
    display: "flex",
    flexDirection: "column",
    rowGap: "10px",
  },
  webViewerStyle: {
    overflow: "hidden",
    width: "calc(100vw - 620px)",
    height: "100vh",
  },
  Select: {
  },
  SelectImg: {
    height: "100px",
    width: "auto"
  }
}));

const InvoiceStepperDocument = (props) => {
  const toastInitialValues = {
    isOpen: false,
    isSuccess: false,
    isError: false,
    message: "",
  };

  let [instance, setInstance] = useState(null);
  const [toast, setToast] = useState(toastInitialValues);
  const classes = useStyles();
  const viewer = useRef(null);
  const filePicker = useRef(null);

  const [webViewerInitiated, setWebViewerInitiated] = useState(false);

  const { fields } = props;

  useEffect(() => {
    if (instance == null) {
      if (!webViewerInitiated) {
        setWebViewerInitiated(true);
        startPdfViewer();
      }
    } else {
      addFieldToDoc();
    }
  }, [fields, instance]);

  const closeToast = () => {
    return setToast((prev) => ({
      ...prev,
      isOpen: false,
      isSuccess: false,
      isError: false,
      message: "",
    }));
  };


  const startPdfViewer = () => {
    WebViewer(
      {
        path: "/webviewer/lib",
        css: "/style.css",
        fullAPI: true,
        disableLogs: true,
        initialDoc: withAppUrl('templates/invoice.docx'),
        disabledElements: [
          "ribbons",
          "toggleNotesButton",
          "searchButton",
          "menuButton",
          "rubberStampToolGroupButton",
          "stampToolGroupButton",
          "fileAttachmentToolGroupButton",
          "calloutToolGroupButton",
          // "undo",
          "redo",
          "eraserToolButton",
          // "toolsHeader",
          "signatureToolGroupButton",
          "leftPanelButton",
          "viewControlsButton",
          "selectToolButton",
          "annotationPopup",
          "toolsOverlay",
          "defaultSignatureDeleteButton",
          "contextMenuPopup",
          "signatureModal",
          "underlineToolGroupButton",
          "highlightToolGroupButton",
          "shapeToolGroupButton",
          "freeTextToolGroupButton",
          "freeHandHighlightToolGroupButton",
          "freeHandToolGroupButton",
          "stickyToolGroupButton",
          "squigglyToolGroupButton",
          "strikeoutToolGroupButton",
          "redoButton"
        ],
      },
      viewer.current
    ).then((instance) => {
      instance = instance;
      setInstance(instance);
      const { documentViewer } = instance.Core;
      documentViewer.addEventListener('documentLoaded', async () => {
        await documentViewer.getDocument().documentCompletePromise();
        documentViewer.updateView();
      });
    });
  };

  const addFieldToDoc = async () => {
    const { documentViewer, annotationManager, Annotations } = instance.Core;

    documentViewer.addEventListener("documentLoaded", async () => {
      let autofillMap = {};
      autofillMap = JSON.parse(JSON.stringify(fields));
      let subTotal = 0;

      for (let productItemIndex in autofillMap.items) {
        let productItem = autofillMap.items[productItemIndex];
        let price = parseFloat(productItem?.price);
        let quantity = parseFloat(productItem?.quantity);

        let total = price * quantity;
        total = total.toFixed(2);
        subTotal = subTotal + total;

        autofillMap.items[productItemIndex]['total'] = (autofillMap['currency'] + " " + total.toString());
        autofillMap.items[productItemIndex]['quantity'] = productItem.quantity + "/" + productItem.unit;
        autofillMap.items[productItemIndex]['price_per_item'] = (autofillMap['currency'] + " " + productItem.price);
      }

      let haveTax = false;

      subTotal = parseFloat(subTotal);
      let tax = parseFloat(autofillMap?.tax);
      let totalPayable = subTotal;
      if (isNotEmpty(tax) && tax > 0) {
        totalPayable = (subTotal + tax);
        haveTax = true;
      }

      totalPayable = totalPayable.toFixed(2).toString();
      subTotal = subTotal.toFixed(2).toString();

      autofillMap['sub_total'] = subTotal;
      autofillMap['payable_amount'] = totalPayable;

      let logoBase64 = autofillMap['logo'];

      let dimensions = await base64ImageDimensions(logoBase64);
      if (dimensions != null) {
        dimensions = updateDimensionsAsPerSizes(dimensions, 150, 75);
      }

      autofillMap['logo'] = {
        image_url: logoBase64,
        width: dimensions.width,
        height: dimensions.height
      };

      let signature64 = autofillMap['signatureImage'];

      dimensions = await base64ImageDimensions(signature64);
      if (dimensions != null) {
        dimensions = updateDimensionsAsPerSizes(dimensions, 200, 80);
      }

      autofillMap['signature'] = {
        image_url: signature64,
        width: dimensions.width,
        height: dimensions.height
      };

      let subTotalLabel = 'Sub Total';
      if (haveTax) {
        subTotalLabel = 'Sub Total (excl. ' + autofillMap['tax_label'] + ')';
      }

      let paymentItems = [
        {
          label: subTotalLabel,
          value: (autofillMap['currency'] + " " + autofillMap['sub_total'])
        }
      ];

      if (haveTax) {
        paymentItems.push({
          label: 'Total ' + autofillMap['tax_label'],
          value: (autofillMap['currency'] + " " + autofillMap['tax'])
        });
      }

      autofillMap['payment_items'] = paymentItems;
      autofillMap['payable_amount_words'] = inWords(autofillMap['payable_amount']);
      autofillMap['payable_amount'] = (autofillMap['currency'] + " " + autofillMap['payable_amount']);

      await documentViewer.getDocument().applyTemplateValues(autofillMap);

    });
  }

  const upload = async () => {
    const { docViewer, annotManager } = instance;
    const doc = docViewer.getDocument();
    const xfdfString = await annotManager.exportAnnotations({
      widgets: true,
      fields: true,
    });
    const options = {
      xfdfString,
      downloadType: "pdf"
    };
    const data = await doc.getFileData(options);
    const arr = new Uint8Array(data);
    const blob = new Blob([arr], { type: "application/pdf" });

    props.uploadDocument(blob);
  };

  useEffect(() => {
    if (props.sendFile) {
      upload();
    }
  }, [props.sendFile]);

  return (
    <>
      {toast.isOpen && (
        <Toast
          message={toast.message}
          isError={toast.isError}
          isSuccess={toast.isSuccess}
          closeToast={closeToast}
        />
      )}
      {
        props.isloading ? <Grid container>
          <Grid container item xs={12}>
            <Box width="100%" className="text-center">
              <CircularProgress color="primary" size={50} />
            </Box>
          </Grid>
        </Grid> :
          <div className={"prepareDocument"}>
            <div style={{ display: "flex", flexDirection: "row", flex: "grow" }}>
              <div
                className={`${classes.webViewerStyle} webviewer`}
                ref={viewer}
              ></div>
            </div>
            <input type="file" ref={filePicker} style={{ display: "none" }} />
          </div>
      }
    </>
  );
};

export default InvoiceStepperDocument;