export function getFormData (payload) {
    const formData = new FormData();
    for ( var key in payload ) {
      if (payload[key] instanceof File) {
        formData.append(key, payload[key]);
      } else if (typeof payload[key] === "string" || typeof payload[key] === "number")  {
        formData.append(key, payload[key]);
      } else {
        formData.append(key, JSON.stringify(payload[key]));
      }
    }
    // for (var pair of formData.entries()) {
    //   console.log(pair[0]+ ', ' + pair[1]); 
    // }
  
    return formData
  }