import { createSelector } from "reselect";

const stateAllFiles = (state) => state.sidePanel.awaiting_my_sign;
const getUser = (state) => state.user;
const selectedDocId = (state) => state.selectedDoc;

export const annotationId = createSelector(
    stateAllFiles,
    getUser,
    selectedDocId,
    (files, user, docId) => {
        if( files !== undefined && files.length > 0){
            const selectedDoc = files?.find(e => e.id === docId);
            const selectUserData = selectedDoc?.document_signatures.find(e => e.user.email === user.email);
            return selectUserData.annotation_id;
        }
        return [];
    }
)

export const isLastSigner = createSelector(
    stateAllFiles,
    getUser,
    selectedDocId,
    (files, user, docId) => {
        if( files !== undefined && files.length > 0){
            const selectedDoc = files?.find(e => e.id === docId);
            const selectUserData = selectedDoc?.document_signatures.find(e => e.user.email === user.email);
            return selectUserData.is_last_signer;
        }
        return false;
    }
)
