import React, { useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import {
  Grid,
  makeStyles,
  withStyles,
  Box
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import VerifyModal from "./Verification";
import MultipleCorporateModal from "./MultipleCorporateModal";
import { loginApi } from "../../services/auth.service";
import { useLocalStorage, useModal } from "../../hooks";

import "./login.css";
import { MessageConstants } from "../../utils/MessageConstants";
import Toast from "../../utils/Toast";
import { useDispatch, useSelector } from "react-redux";
import SignUpActions from "../../redux/actions/SignUp.action";
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import { FormControlLabel } from "@material-ui/core";
import { Checkbox } from "@material-ui/core";

import { isNotEmpty } from "./../../utils/Generic";

const useStyles = makeStyles((theme) => ({
  field: {
    marginBottom: 20,
    borderRadius: "50px 50px 50px 50px",
    color: theme.palette.primary.main,
  },
  heading: {
    fontWeight: 400,
    fontSize: "32px",
    marginBottom: "2em",
  },
  button: {
    marginTop: "20px",
    marginBottom: "10px",
    width: '100%',
    height: 45,
  },
  buttonText: {
    fontWeight: "bold",
    fontSize: 16,
    letterSpacing: "0.15em",
    color: "#FFFDFD",
  }

}));

const CssTextField = withStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#ffffff",
      },
      "&:hover fieldset": {
        borderColor: "#66CFA4",
      },
      borderRadius: 50,
    },
  },
})(TextField);


function LoginForm() {
  const toastInitialValues = {
    isOpen: false,
    isSuccess: false,
    isError: false,
    message: ""
  }
  const classes = useStyles();
  const { isOpen, onOpen } = useModal();
  const [clearSelectValue, setClearSelectValue] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [corporateData, setCorporateData] = useState([]);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [usernameError, setUsernameError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [toast, setToast] = useState(toastInitialValues);
  const signUpState = useSelector(state => state.signup);
  const { setItem } = useLocalStorage();
  const dispatch = useDispatch();

  const [rememberMe, setRememberMe] = useState(false);

  const closeToast = () => {
    return setToast((prev) => ({
      ...prev,
      isOpen: false,
      isSuccess: false,
      isError: false,
      message: ""
    }));
  };

  useEffect(() => {
    // console.log("signUpState", signUpState);
    if (isNotEmpty(signUpState) && isNotEmpty(signUpState?.username)) {
      let message = "Successfully signed up! Please login to continue";
      if (signUpState.verificationRequird.toString() === "true") {
        message = "Sign up request submitted, You will hear from admin@trudoc.io shortly";
      }
      setToast((prev) => ({
        ...prev,
        isOpen: true,
        isSuccess: true,
        message: message
      }));
      dispatch(SignUpActions(false));
    }
  }, [signUpState])

  const MultipleCorporateHandler = (data) => {
    if (data) {
      loginApi({ username, password, rememberMe, name: data })
        .then((loginRes) => {
          if (loginRes && loginRes.status === 200) {
            setOpenDialog(false);
            setClearSelectValue(!clearSelectValue);
            if (loginRes?.data?.sessionId) {
              // alert("OTP is : " + loginRes.data.otp);    
              setItem("userType", loginRes?.data?.userType);
              onOpen({
                email: username,
                modalType: "loginVerify",
                sessionId: loginRes.data.sessionId,
              });
            }
          } else if (loginRes.status === 400) {
            setToast((prev) => ({
              ...prev,
              isOpen: true,
              isError: true,
              message: loginRes.data.message
            }));
          } else if (loginRes.status === 500) {
            setToast((prev) => ({
              ...prev,
              isOpen: true,
              isError: true,
              message: MessageConstants.SERVER_ERROR
            }));
          } else {
            setToast((prev) => ({
              ...prev,
              isOpen: true,
              isError: true,
              message: MessageConstants.INVALID_PASSWORD
            }));
          }
        })
        .catch((err) => {
          setToast((prev) => ({
            ...prev,
            isOpen: true,
            isError: true,
            message: MessageConstants.SERVER_ERROR
          }));
        });
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setUsernameError(false);
    setPasswordError(false);

    if (username === "") {
      setUsernameError(true);
    }
    if (password === "") {
      setPasswordError(true);
    }
    if (username && password) {

      loginApi({ username, password, rememberMe })
        .then((loginRes) => {
          if (loginRes && loginRes.status === 200) {
            if (loginRes?.data?.message === MessageConstants.MULTIPLE_CORPORATE) {
              setCorporateData(loginRes?.data?.corporates);
              setOpenDialog(true);
            }
            else if (loginRes?.data?.sessionId) {
              // alert("OTP is : " + loginRes.data.otp);  
              setItem("userType", loginRes?.data?.userType);
              onOpen({
                email: username,
                modalType: "loginVerify",
                sessionId: loginRes.data.sessionId,
              });
            }
          } else if (loginRes.status === 400) {
            setToast((prev) => ({
              ...prev,
              isOpen: true,
              isError: true,
              message: loginRes.data.message
            }));
          } else {
            setToast((prev) => ({
              ...prev,
              isOpen: true,
              isError: true,
              message: MessageConstants.INVALID_EMAIL_PASSWORD
            }));
          }
        })
        .catch((err) => {
          setToast((prev) => ({
            ...prev,
            isOpen: true,
            isError: true,
            message: MessageConstants.ERROR_OCCURED
          }));
        });
    }
  };

  return (
    <>
      {toast.isOpen && <Toast message={toast.message} isError={toast.isError} isSuccess={toast.isSuccess} closeToast={closeToast} />}
      <Box width="100%" mt="20px">
        <form autoComplete="off" onSubmit={handleSubmit}>
          <TextField
            defaultValue={username}
            className={classes.field}
            onChange={(e) => setUsername(e.target.value)}
            label="Email Address"
            variant="outlined"
            color="primary"
            type="email"
            fullWidth
            required
            error={usernameError}
            InputLabelProps={{ className: classes.textFieldInput }}
          />
          <TextField
            label="Password"
            type={showPassword ? 'text' : 'password'}
            defaultValue={password}
            className={classes.field}
            onChange={(e) => setPassword(e.target.value)}
            variant="outlined"
            color="primary"
            fullWidth
            required
            error={passwordError}
            InputLabelProps={{ className: classes.textFieldInput }}
            InputProps={{
              className: classes.textFieldInput,
              endAdornment: <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }}
          />

          {/* <FormControlLabel
            className="theme-checkbox theme-checkbox-small"
            control={<Checkbox color="primary" checked={rememberMe} onChange={(e) => setRememberMe(!rememberMe)} name="gilad" />}
            label={<Typography>Remember Me</Typography>}
          /> */}

          <Grid item xs={12} align="center">
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.button}
              size="large"
              disabled={!(username && password)}
            >
              <Typography className={classes.buttonText}>Sign in</Typography>
            </Button>
          </Grid>
        </form>
        {isOpen && <VerifyModal />}
        <MultipleCorporateModal
          open={openDialog}
          corporateData={corporateData}
          handleClose={() => setOpenDialog(false)}
          handleSubmit={MultipleCorporateHandler}
          clearSelectValue={clearSelectValue}
          title="Select Corporate you want to login"
        />
      </Box>
    </>
  );
}

export default LoginForm;
