import React from "react";
import { Box, Chip } from '@material-ui/core';

export const AssetManagementTags = () => {
  return (
    <>
      <Box component="span" m={1}>
        <Chip
          size="Large"
          label="Transferable"
          color="primary"
        />
      </Box>
      <Box component="span" m={1}>
        <Chip
          size="Large"
          label="Negotiable"
          color="primary"
        />
      </Box>
    </>
  );
};
