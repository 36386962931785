import { Box, CircularProgress, Grid } from "@material-ui/core";
import {
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import ReactPaginate from 'react-paginate';
import { Close } from "@material-ui/icons";
import { UNSDG_GOALS } from "../../utils/unsdg-goals";
import { goalsParseSubjects, isNotEmpty, parseByGoals } from "../../utils/Generic";

function EsgImpactSubjectModal(props) {

    const { closeModal, openModal, objectiveKey, objectives, report, goals } = props
    const [activeTab, setActiveTab] = useState('environment')
    const [topicList, setTopicList] = useState({
        environment: [],
        social: [],
        governance: [],
    })

    const [goalDataItems, setGoalDataItems] = useState({})
    useEffect(() => {
        let goalDataItemsTemp = parseByGoals(report.sustainability_goal_objectives[objectiveKey]?.sustainability_goal_subjects);
        setGoalDataItems(goalDataItemsTemp);
    },[])

    return (
        <>
            <Dialog
                onClose={e => closeModal()}
                fullWidth={true}
                maxWidth='sm'
                open={openModal}
                className={`theme-base-modal sustainability-layout NewUi_modals max-w-600`}
                keepMounted={false}
                PaperProps={{
                    style: { borderRadius: 20, maxHeight: 'calc(100% - 44px)' }
                }}
            >
                <DialogTitle>
                    <div className="modal-head">
                        <span className="capital-case">Goals</span>
                        <IconButton onClick={e => closeModal()} style={{ marginLeft: 'auto' }}>
                            <Close />
                        </IconButton>
                    </div>
                </DialogTitle>
                <DialogContent dividers >
                    <DialogContentText>
                        <div className="sus-objective-box">
                            <main className='new-design'>
                                <div className='container main-wrapper'>
                                    {
                                        isNotEmpty(report?.sustainability_goals) && report?.sustainability_goals.length > 0 ?

                                            Object.keys(goalDataItems).map((key) => {
                                                const goalItem = goalDataItems[key]
                                                return (
                                                    <div className="sdg-popup" style={{ backgroundColor: `${UNSDG_GOALS[goalItem?.goal_number].color}` }}>
                                                        <img width="100px" height="100px" src={`/assets/images/sdg-icons/E-WEB-Goal-${goalItem?.goal_number}.png`} alt="goal-img" />
                                                        <div>
                                                            <div>
                                                                <span>SDG {goalItem?.goal_number}</span>
                                                            </div>
                                                            <div className="topics">
                                                                {UNSDG_GOALS[goalItem?.goal_number].title}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                            :
                                            "No Goals Found!"
                                    }
                                </div>
                            </main>
                        </div>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </>
    );
}

export default EsgImpactSubjectModal;
