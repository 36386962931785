import React, { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
import {
  Box,
  Grid,
  Typography,
  TextField,
  InputAdornment,
  FormControl,
  Select,
  MenuItem,
  InputBase,
  InputLabel,
  Dialog,
  DialogContent,
  CircularProgress,
  Button,
  LinearProgress,
  Snackbar
} from "@material-ui/core";
import { makeStyles, styled, withStyles } from "@material-ui/core/styles";
import SimpleDocumentViewer from "../components/documents/SimpleDocumentViewer";
import DocumentCard from "../components/documents/DocumentCard";
import DetailedCard from "../components/documents/DetailedCard";
import ErrorIcon from "@material-ui/icons/Error";
import { getDocumentPreview , previewShareLink} from "../services/dms.service";
import { getImageFromMimeType, isNotEmpty, printTime } from "../utils/Generic";
import DocumentViewer from "../components/documents/DocumentViewer";
import Toast from "../utils/Toast";
import { haveViewer } from "../utils/document";
import { Alert } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "88vh",
    padding: "10px 36px"
  },
  filterBar: {
    marginTop: 20,
  },
  filterBar2: {
    marginTop: 5,
    marginBottom: 30,
  },
}));

const ViewDocument = () => {

  const classes = useStyles();

  const toastInitialValues = {
    isOpen: false,
    isSuccess: false,
    isError: false,
    message: ""
  }
  const { id, share_id } = useParams();

  const [toast, setToast] = useState(toastInitialValues);

  const [parentDocumentId, setParentDocumentId] = useState(null);

  const [viewBy, setViewBy] = useState('');
  const [pane, setPane] = useState('');
  const [sortBy, setSortBy] = useState('');
  const [filesLoading, setFilesLoading] = useState(true);
  const [openPdfViewer, setOpenPdfViewer] = useState(false);
  const [certificates, setCertificates] = useState([]);
  const [parentAnchestors, setParentAnchestors] = useState([]);
  const [parentDocument, setParentDocument] = useState(null);
  const [downloadedDocument, setDownloadedDocument] = useState(null);
  const [downloadUrl, setDownloadUrl] = useState(null);
  const [certData, setCertData] = useState();
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [progress, setProgress] = useState(false);
  const [progressText, setProgressText] = useState('');
  const downloadElemRef = useRef(null);

  const loadDocuments = () => {
    setSelectedDocument(null);
    setCertificates([]);
    setFilesLoading(true);
    previewShareLink({
      id: atob(id),
      share_id,
      parentDocumentId,
      sortBy
    }).then((res) => {
      setCertificates(res?.data?.documents);
      setParentAnchestors(res?.data?.parentAnchestors);
      setParentDocument(res?.data?.parentDocument);
      setFilesLoading(false);
    }).catch(error => {
      setFilesLoading(false);
    });
  }

  useEffect(() => {
    document.title = `TruDoc | DMS`;
  }, []);

  useEffect(() => {
    loadDocuments();
  }, [parentDocumentId]);

  useEffect(() => {
    loadDocuments();
  }, [sortBy]);

  useEffect(() => {
    if (downloadUrl != null) {
      downloadElemRef.current.click();
    }
  }, [downloadUrl]);
  
  const handleDocumentSelected = (e) => {
    setSelectedDocument(e);
  }

  const handleDocumentOpened = (e) => {
    if (e.is_folder) {
      setParentDocumentId(e.id);
    } else {
      handleOnView(e);
    }
  }

  const handleOnView = (e) => {
    if (haveViewer(e?.file_type, e?.name)) {
      setOpenPdfViewer(true);
      setCertData(e);
    } else {
      setToast((prev) => ({
        ...prev,
        isOpen: true,
        isError: true,
        message: 'File viewer is not available hence file will be downloaded'
      }));
      handleOnDownload(e);
    }
  }

  const handleOnDownload = (e) => {
    setProgress(true);
    setProgressText('Downloading ' + e.name);
    console.log("download link");
    setDownloadedDocument(e);
    getDocumentPreview({
      documentId: e?.id,
      id: atob(id), 
      share_id
    }).then((res) => {
      setProgress(false);
      let fileData = res?.data?.fileData;
      if (isNotEmpty(fileData)) { 
        let fileDataRaw = fileData?.data;
        
        let downloadUrl = `data:${fileData?.mimetype};base64,${fileDataRaw}`;

        if(fileData?.mimetype == "wrapped"){
          fileData.mimetype = "text/json";
          downloadUrl = `data:${fileData?.mimetype};charset=utf-8,` + encodeURIComponent(fileDataRaw);
        } 

        //console.log(downloadUrl);  
        setDownloadUrl(downloadUrl);
      }
    }).catch(err => {
      console.log(err);
      setProgress(false);
    });
  }

  const closeToast = () => {
    return setToast((prev) => ({
      ...prev,
      isOpen: false,
      isSuccess: false,
      isError: false,
      message: ""
    }));
  };

  const updateSortValue = (val) => {
    setSortBy(val);
  }

  return (
    <>

      {toast.isOpen && <Toast message={toast.message} isError={toast.isError} isSuccess={toast.isSuccess} closeToast={closeToast} />}
      <a target="_blank" className="d-none" filename={downloadedDocument?.name} ref={downloadElemRef} href={downloadUrl} download={downloadedDocument?.name}>Download File</a>
      <Box className={classes.root}>
        <Grid
          className={classes.filterBar2}
          container
          direction="row"
          alignItems="center"
          spacing={3}
        >

          <Grid className="dms-s-fiters" item xs={12}>

            <a href={undefined} onClick={e => { loadDocuments() }} className="theme-form-element">Refresh</a>

            <select value={viewBy} onChange={e => setViewBy(e.target.value)} className="theme-form-element">
              <option value="">View By</option>
              <option value="list">List</option>
              <option value="tiles">Tiles</option>
            </select>

            <select value={pane} onChange={e => setPane(e.target.value)} className="theme-form-element">
              <option value="">Pane</option>
              <option value="details">Details</option>
              <option value="preview">Preview</option>
            </select>

            <select value={sortBy} onChange={e => updateSortValue(e.target.value)} className="theme-form-element">
              <option value="">Sort By</option>
              <option value="date">Date</option>
              <option value="name">Name</option>
            </select>

          </Grid>

          {
            parentDocument != null && <>

              <Grid className="dms-breadcrumbs" item xs={12}>

                <ul>
                  <li>
                    <a href={undefined} onClick={e => setParentDocumentId(null)}>Home</a>
                  </li>

                  {
                    (parentAnchestors != null && parentAnchestors.length > 0) && <>

                      {
                        parentAnchestors.map((parentAnchestor) => {
                          return (
                            <li>
                              <a className={`${parentAnchestor?.is_default.toString() === "true" ? 'default' : ''}`} href={undefined} onClick={e => setParentDocumentId(parentAnchestor.id)}>{parentAnchestor.name}</a>
                            </li>
                          );
                        })
                      }

                    </>
                  }
                  {
                    parentDocument != null && <>
                      <li>
                        <a className={`active ${parentDocument?.is_default.toString() === "true" ? 'default' : ''}`} href={undefined} onClick={e => setParentDocumentId(parentDocument.id)}>{parentDocument.name}</a>
                      </li>
                    </>
                  }
                </ul>

              </Grid>

            </>
          }

        </Grid>

        <Box className={"dms-files-area truDrop " }>

          {
            !filesLoading ?
              <>
                <Grid container>

                  <Grid container item xs={selectedDocument != null ? 8 : 12}>

                    {
                      (viewBy === 'list' || viewBy === '') && <>
                        <div className="table-responsive">
                          <table className="table table-default table-green-head">
                            <thead>
                              <tr>
                                <th>Name</th>
                                <th>Date Created</th>
                              </tr>
                            </thead>
                            <tbody>
                              {(certificates && certificates.length > 0) ?
                                certificates.map((cert) => {
                                  return (
                                    <>
                                      <tr
                                        className={`dms-table-row ${cert?.is_default.toString() === "true" ? 'default' : ''} ${selectedDocument?.id === cert.id ? 'selected' : ''}`}
                                        onClick={e => handleDocumentSelected(cert)}
                                        onDoubleClick={e => handleDocumentOpened(cert)}
                                      >
                                        <td className="table-row-title-main">
                                          <Box className="table-row-title">
                                            <img src={getImageFromMimeType(cert?.file_type, cert?.is_folder)} />
                                            <span>{cert.name}</span>
                                          </Box>
                                        </td>
                                        <td>{printTime(cert?.created_at)}</td>
                                      </tr>
                                    </>
                                  );
                                }) : <>
                                  <tr>
                                    <td colspan="2">No Documents Found</td>
                                  </tr>
                                </>}
                            </tbody>
                          </table>
                        </div>
                      </>
                    }

                    {
                      (viewBy === 'tiles') && <>
                        <Grid container item xs={selectedDocument != null && 12} spacing={3} className="documents-container">
                          {(certificates && certificates.length > 0) ?
                            certificates.map((cert) => {
                              return (
                                <Grid
                                  container
                                  item
                                  xs={selectedDocument != null ? 4 : 3}
                                  key={cert.id}
                                >
                                  <Box width="90%">
                                    <DocumentCard
                                      data={cert}
                                      isSelected={
                                        selectedDocument != null && selectedDocument?.id === cert.id
                                      }
                                      onSelected={e => handleDocumentSelected(e)}
                                      onOpen={e => handleDocumentOpened(e)}
                                    />
                                  </Box>
                                </Grid>
                              );
                            }) : <>
                              <Box width="100%" align="center" padding={8}>
                                <ErrorIcon className={classes.emptyIcon} color="primary" />
                                <Typography variant="h6">No Documents Found</Typography>
                              </Box>
                            </>}
                        </Grid>
                      </>
                    }


                  </Grid>

                  {selectedDocument != null && (
                    <Grid container item xs={4}>
                      <Box width="100%">

                        {(pane === 'details' || pane === '') && <>

                          <DetailedCard
                            data={selectedDocument}
                            onView={e => handleOnView(e)}
                            onDownload={e => handleOnDownload(e)}
                            // onDelete={e => handleOnDelete(e)}
                            onRefresh={e => loadDocuments()}
                            isSharePreview={true}
                            shareData={{id: atob(id), share_id}}
                          />

                        </>
                        }

                        {(pane === 'preview') && <>

                          <DocumentViewer data={selectedDocument} />

                        </>
                        }

                      </Box>
                    </Grid>
                  )}

                </Grid>
              </> :
              <>
                <Grid container>
                  <Grid container item xs={12}>
                    <Box width="100%" className="text-center">
                      <CircularProgress color="primary" size={50} />
                    </Box>
                  </Grid>
                </Grid>
              </>
          }

        </Box>

      </Box>

      <Dialog
        fullWidth={true}
        maxWidth='lg'
        open={openPdfViewer}
        className={classes.dialogRoot + ' doc-viewer-modal'}
        PaperProps={{
          style: { borderRadius: 20, maxHeight: 'calc(100% - 44px)', height: '80vh' }
        }}
        onClose={e => setOpenPdfViewer(false)}
      >

        <DialogContent className={classes.contentHeight}>

          <DocumentViewer data={selectedDocument} shareData={{id: atob(id), share_id}}/>

        </DialogContent>
      </Dialog>

      {progress && <Snackbar
        open={progress}
        autoHideDuration={null}
        onClose={() => { }}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={() => setProgress(false)} severity="info">
          {progressText}
          <LinearProgress className={classes.progress} />
        </Alert>
      </Snackbar>}

    </>
  );
};

export default ViewDocument; 
