import { TOGGLE_MODAL } from '../redux.constants';

const toggleModal = (payload) => {
  return dispatch => {
    dispatch({
      type: TOGGLE_MODAL,
      payload
    })
  }
}

export default toggleModal;