import { useState } from 'react';
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import VisibilityIcon from '@material-ui/icons/Visibility';
import GetAppIcon from '@material-ui/icons/GetApp';
import { Button, Box, CircularProgress } from '@material-ui/core';
import { sidePanelFilterMapping } from '../../constants';
import { getDocuments } from "../../services/other.service";
import { signDoc, downloadTt, getPdf } from '../../services/api.service';
import SidePanelAction from "../../redux/actions/Sidepanel.action";
import { useAuth } from '../../hooks';


const ActionPanel = ({ data, setShowToast, handleViewPdf, edocs }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState();
  const { filterType } = useParams();
  const { user } = useAuth();

  const handleSignDocument = (type = "SIGNED") => {
    setLoading(type);
    if (data) {
      signDoc({
        document_id: data.id,
        email: user.email,
        status: type,
      }).then(res => {
        if (res && res?.status === 200 && res?.data) {
          setShowToast(res?.data?.message);
          setLoading(null);
          updateSidePanel(true);
        }
      })
    }
  }

  const updateSidePanel = () => {
    getDocuments({
      email: user.email,
      condition: sidePanelFilterMapping[filterType]
    }).then((res) => {
      if (res && res?.status === 200 && res?.data)
        dispatch(SidePanelAction([filterType], [...res?.data]));
    });
  }

  const handleFileDownload = () => {
    console.log("data:datadatadata:", data, "edocs", edocs);

    const bucketName = data?.storage_bucket;

    if (edocs == true) {
      const fileName = data.storage_key;
      getPdf({ filename:fileName, edocs, docId: data?.id })
      .then(res => {
        if (res && res?.status === 200 && res?.data) {
          const response =
            "data:application/pdf;base64,"+(res?.data.data.data)
          const element = document.createElement("a");
          element.setAttribute("href", response);
          element.setAttribute("download", fileName);
          document.body.appendChild(element);
          element.click();
        }
      })
    } else {
      const fileName = data?.name?.replace('.pdf', '.tt');
      downloadTt({
        docId: data?.id,
        bucketName: bucketName,
        fileName
      }).then(res => {
        if (res && res?.status === 200 && res?.data) {
          const response =
            "data:text/json;charset=utf-8," +
            encodeURIComponent(JSON.stringify(res?.data));
          const element = document.createElement("a");
          element.setAttribute("href", response);
          element.setAttribute("download", fileName);
          document.body.appendChild(element);
          element.click();
        }
      })
    }
  };
  return (
    <Box onClick={(event) => event.stopPropagation()}>
      {filterType === 'completed' &&
        <Button onClick={handleFileDownload}>
          <GetAppIcon />
        </Button>
      }

      <Button onClick={handleViewPdf}>
        <VisibilityIcon />
      </Button>
      {/* 
      {filterType !== 'completed' &&  filterType !== 'declined' &&  filterType !== 'expired' &&
        <Button onClick={() => handleSignDocument("REJECTED")}>
          {loading === 'REJECTED' ? <CircularProgress color="primary" size={20}/> : <HighlightOffIcon />}
        </Button>
      }

      {filterType === 'awaiting_my_sign' &&
        <Button onClick={() => handleSignDocument("SIGNED")}>
          {loading === "SIGNED" ?  <CircularProgress color="primary" size={20}/> : <CheckCircleOutlineIcon />}
        </Button>
      } */}

    </Box>
  )
}

export default ActionPanel;