import React, { Fragment, useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import {Checkbox} from "@material-ui/core";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Snackbar from "@material-ui/core/Snackbar";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import DescriptionIcon from "@material-ui/icons/Description";
import MuiAlert from '@material-ui/lab/Alert';
import ActionPanel from "./ActionPanel";
import SigningProgress from "./SigningProgress";
import TransferablePanel from "./TransferablePanel";
import { TokenInformationContextProvider } from './transferablePanelFiles/contexts/TokenInformationContext';
import { ProviderContextProvider } from "./transferablePanelFiles/contexts/provider";
import { OverlayContextProvider } from "@govtechsg/tradetrust-ui-components";
import { toLocalDateTime } from "../../utils/moment-utils";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(20),
    marginLeft: "15px",
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

const AccordionSummary = withStyles({
  content: {
    alignItems: "center",
    justifyContent: "space-between",
  },
})(MuiAccordionSummary);


const Certificate = ({ expanded, handleChange, data, filterType, handleViewPdf,radio,handleChangeCheckBox,edocs }) => {
  const classes = useStyles();
  const [showToast, setShowToast] = useState('');

  const handleToastClose = () => {
    setShowToast('');
  }
  return (
    <Fragment>
      {data && (
        <Accordion
          expanded={expanded === `panel${data.id}`}
          onChange={handleChange(`panel${data.id}`)}
          TransitionProps={{ unmountOnExit: true }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            className={classes.accordinFlex}
          >
            <Box display="flex" alignItems="center" width="25%">
              <DescriptionIcon />
              <Typography className={classes.heading}>{data.name}</Typography>
            </Box>
            <Box width="15%">
              <Typography className={classes.secondaryHeading}>
                {toLocalDateTime(data.created_at)}
              </Typography>
            </Box>
            <Box width="10%">
              <Typography className={classes.secondaryHeading}>
                {data?.creator_user?.first_name}
              </Typography>
            </Box>
            <Box width="20%">
              <Typography className={classes.secondaryHeading}>
                {data?.domain_config?.domain?.name}
              </Typography>
            </Box>
            <Box width="20%" align="right">
              <ActionPanel data={data} setShowToast={setShowToast} handleToastClose={handleToastClose} handleViewPdf={handleViewPdf} edocs={edocs}/>
            </Box>
            {radio == true ? <Box width="20%" align="right"><Checkbox color="primary" label={data.name} value={data.name} onChange={handleChangeCheckBox} /></Box> : ""}
          </AccordionSummary>
          {data.document_signatures && data.document_signatures.length > 0 && filterType !== 'my_wallet' && (
            <AccordionDetails >
              <SigningProgress documentSignData={data.document_signatures} />
            </AccordionDetails>
          )}
          {filterType === 'my_wallet' && (
            <OverlayContextProvider>
              <ProviderContextProvider>
                <TokenInformationContextProvider>
                  <AccordionDetails>
                    <TransferablePanel data={data} />
                  </AccordionDetails>
                  </TokenInformationContextProvider>
                </ProviderContextProvider>
            </OverlayContextProvider>
          )}
        </Accordion>
      )}
      <Snackbar open={Boolean(showToast)} autoHideDuration={6000} onClose={() => handleToastClose()}>
        <Alert onClose={() => handleToastClose()} severity="success">
          {showToast}
        </Alert>
      </Snackbar> 
    </Fragment>
  );
};

export default Certificate;
