import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  makeStyles,
  Paper,
  SvgIcon,
  Typography,
} from "@material-ui/core";
import { useLocation } from "react-router";
import { getStatus } from "../services/api.service";
import { useAuth } from "../hooks";
import InfoPaper from "../components/InfoPaper";


const useStyles = makeStyles((theme) => {
  return {
    paper: {
      padding: theme.spacing(1),
      margin: theme.spacing(2),
      backgroundColor: 'transparent',
    },
    dialogRoot: {
      width: "600px",
      borderRadius: 50,
      margin: "0 auto",
    },
    contentHeight: {
      minHeight: "100px",
      maxWidth: "100%",
      margin: "0 auto",
      minWidth: "300px",
    },
    button: {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    actionsContainer: {
      marginBottom: theme.spacing(2),
    },
    resetContainer: {
      padding: theme.spacing(3),
    },
    paper1: {
      display: "flex",
      flexWrap: "wrap",
      backgroundColor: "transparent",
      "& > *": {
        margin: theme.spacing(3),
        padding: theme.spacing(2),
        width: "100%",
        textAlign: "center",
      },
    },

    paper2: {
      display: "flex",
      flexWrap: "wrap",
      "& > *": {
        margin: theme.spacing(3),
        padding: theme.spacing(2),
        width: "100%",
      },
    },
    paper3: {
      backgroundColor: "transparent",
      display: "flex",
      flexWrap: "wrap",
      "& > *": {
        margin: theme.spacing(3),
        padding: theme.spacing(2),
        width: "100%",
        height: 320,
      },
    },
    dropIcon: {
      width: "150px",
      padding: theme.spacing(2),
    },
    icon: {
      margin: theme.spacing(1),
    },
    formControl: {
      minWidth: "100%",
    },
    contentMargin: {
      marginTop: theme.spacing(3),
    },
    content: {
      width: `calc(100% - ${300}px)`,
      marginLeft: 280,
      display: "flex",
      minHeight: "80vh",
      background: "transparent",
    },
    contentInner: {
      width: "100%",
      display: "flex",
      minHeight: "80vh",
      background: "transparent",
    },
    paper: {
      padding: theme.spacing(1),
      margin: theme.spacing(2),
      textAlign: "center",
    },
    text2: {
      color: "#7E8D90",
      fontSize: "16px",
      fontWeight: 500,
      fontFamily: "Poppins",
      height: "48px",
      lineHeight: "27px",
    },
    text2: {
      color: "#7E8D90",
      fontSize: "20px",
      fontWeight: 500,
      fontFamily: "Poppins",
      lineHeight: "27px",
    },
    flex: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      margin: theme.spacing(1),
    },
    heading: {
      // width: "643px",
      // height: "45px",
      left: "498px",
      top: "253px",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "30px",
      lineHeight: "150%",
      letterSpacing: "0.1em",
      marginBottom: 20,
    },
    dialogRoot: {
      width: '700px',
      borderRadius: 50,
      margin: '0 auto',
    },
    contentHeight: {
      minHeight: '100px',
      maxWidth: '100%',
      margin: '0 auto',
      minWidth: '300px'
    },
    title: {
      paddingTop: 20,
      textAlign: 'center',
      fontWeight: '600',
      textTransform: 'uppercase',
      letterSpacing: 0.5,
      wordSpacing: 2,
      paddingBottom: 10
    },
    stepText: {
      fontWeight: '600',
      paddingBottom: 30,
      textAlign: 'center',
      color: theme.palette.admin.main,
      letterSpacing: 1,
    },
    actionBar: {
      justifyContent: 'center',
      marginBottom: 50
    },
    nextButton: {
      width: 220,
      height: 50,
      borderRadius: 25,
      color: '#fff',
      letterSpacing: 1,
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
      }
    },
    draftButton: {
      width: 220,
      height: 50,
      borderRadius: 25,
      letterSpacing: 1,
      backgroundColor: '#fff',
      color: "#6a6a6a",
      border: '1px solid gray',
      '&:hover': {
        backgroundColor: "#fff",
        color: '#363636',
        border: '1px solid #464646'
      }
    },
    closeIcon: {
      position: 'absolute',
      right: '0',
      padding: '10px'
    },
    buttonTextCancel: {
      fontWeight: "bold",
      fontSize: 16,
      letterSpacing: "0.15em",
    },
    buttonRight: {
      marginTop: "20px",
      marginBottom: "10px",
      border: '1px solid gray',
      backgroundColor: '#fff',
      width: 150,
      height: 45,
      color: "#6a6a6a",
      borderRadius: 25,
      '&:hover': {
        backgroundColor: "#fff",
        color: '#363636',
        border: '1px solid #464646'
      }
    },
    searchContainer: {
      marginTop: "18px",
      width: "calc(100% - 46px)",
      padding: "0 23px",
    },
    searchContainerTextField: {
      width: "100%"
    }
  };
});

function EDocumentSuccess() {

  const classes = useStyles();
  const { user } = useAuth();
  const location = useLocation();
  const [status, setStatus] = useState();

  useEffect(() => {
    loadStatuses();
  }, []);

  const loadStatuses = () => {
    getStatus({ email: user.email, type: 'tru-sign' })
      .then((res) => {
        setStatus(res.data.data);
        console.log(res.data.data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }

  const handleUpdate = () => {
    loadStatuses();
  }

  return (
    <Box className={classes.content}>
      <Grid
        container
        spacing={0}
        // direction="column"
        alignItems="center"
        justify="center"
      >
        <Grid item xs={12}>
          <Paper className={`${classes.paper} doc-sign-com-wrp`} elevation={0}>
            <Box className={classes.contentInner}>
              <Grid container spacing={0} alignItems="center">
                <Grid item xs={12}>
                  <Paper
                    className={`${classes.paper} doc-sign-com-wrp`}
                    elevation={0}
                  >
                    <Grid
                      container
                      spacing={2}
                      alignItems="center"
                      justify="center"
                    >
                      <Grid item xs={12} className={classes.flex}>
                        <Typography className={classes.heading} color="primary">
                          {location?.state?.message}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Grid>
        {status?.map((_, index) => (
          <InfoPaper dataLink="tru-sign" key={index} title={_.title} value={_.value} />
        ))}
      </Grid>
    </Box>
  );
}

export default EDocumentSuccess;
