import React from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { Avatar, Box, Button } from "@material-ui/core";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import ShareOutlinedIcon from "@material-ui/icons/ShareOutlined";
import { getFirstChar } from "../../utils/Generic";
import { VpnKeyOutlined } from "@material-ui/icons";
import { Lock, LockOpen } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 300,
    width: "100%",
    textAlign: "center",
    paddingTop: 20,
  },
  title: {
    marginTop: 15,
    marginBottom: 10,
  },
  position: {
    fontSize: 18,
    marginBottom: 20,
  },
  avatar: {
    fontWeight: 500,
    fontSize: 30,
    margin: "0 auto",
    width: theme.spacing(10),
    height: theme.spacing(10),
    textTransform: "uppercase",
  },
  actionBar: {
    paddingBottom: 20,
  },
}));

export default function OutlinedCard({ user, onEdit, onDelete }) {
  const classes = useStyles();
  const { selected } = useSelector((state) => state.customer);

  return (
    <Card className={classes.root} variant="outlined">
      <CardContent>
        <Avatar
          className={classes.avatar}
        >
          {getFirstChar(selected?.full_name)}
        </Avatar>
        <Typography
          className={classes.title}
          color="textPrimary"
          variant="h6"
          component="h6"
        >
          {selected?.full_name}
        </Typography>
        <Typography
          className={classes.pos}
          color="textSecondary"
          variant="subtitle1"
          gutterBottom
        >
          {selected?.email}
        </Typography>
        <Typography
          className={classes.pos}
          color="textSecondary"
          variant="subtitle1"
          gutterBottom
        >
          {selected?.address}
        </Typography>
      </CardContent>
      {user === 'admin' &&
        <Box className={classes.actionBar}>
          <Button onClick={onEdit}>
            <EditOutlinedIcon />
          </Button>
          <Button onClick={onDelete}>
            <DeleteOutlineOutlinedIcon />
          </Button>
        </Box>
      }
    </Card>
  );
}
