export const MessageConstants = {
    MULTIPLE_CORPORATE: 'Multiple corporates exist',
    USER_ADDED_FAILED: 'Failed to add user',
    CUSTOMER_ADDED_FAILED: 'Failed to add customer',
    USER_ADDED_SUCCESS: 'User added successfully',
    CUSTOMER_ADDED_SUCCESS: 'Customer added successfully',
    USER_UPDATE_FAILED: 'Failed to update user',
    CUSTOMER_UPDATE_FAILED: 'Failed to update customer',
    USER_UPDATE_SUCCESS: 'User updated successfully',
    CUSTOMER_UPDATE_SUCCESS: 'Customer updated successfully',
    USER_DELETE_FAILED: 'Failed to delete user',
    CUSTOMER_DELETE_FAILED: 'Failed to delete customer',
    USER_DELETE_SUCCESS: 'User deleted successfully',
    CUSTOMER_DELETE_SUCCESS: 'Customer deleted successfully',
    USER_ALREADY_EXIST: 'User already exist',
    INVALID_EMAIL_PASSWORD: 'Invalid email address or password',
    INVALID_PASSWORD: 'Password is incorrect',
    INVALID_EMAIL: 'Invalid email address',
    FIELD_REQUIRED: 'All fields are mandatory',
    SOMETHING_WENT_WRONG: 'Something went wrong',
    ERROR_OCCURED: 'Error occured',
    EMAIL_ALREADY_EXIST: 'Email already exist',
    EMAIL_ALREADY_EXIST: 'Email already exist',
    DOMAIN_ALREADY_EXIST: 'Domain name already exist',
    WALLET_NOT_FOUND: 'Wallet not found',
    FAILED_TO_ADD_DOMAIN: 'Failed to add domain',
    PASSWORD_UPDATE_SUCCESS: 'Password updated successfully',
    PASSWORD_UPDATE_FAILED: 'Failed to update password',
    USER_ACCOUNT_DISABLED_SUCCESS: 'User blocked successfully',
    USER_ACCOUNT_ENABLED_SUCCESS: 'User unblocked successfully',
    USER_ACCOUNT_DISABLED_FAILED: 'Failed to block user',
    USER_ACCOUNT_ENABLED_FAILED: 'Failed to unblock user',

    DOCUMENT_VERSION_REVERT: 'Document has been updated',
    DOCUMENT_DETAILS_LOAD_FAILED: 'Failed to load document details',

    COLLABORATOR_ADDED_FAILED: 'Failed to add Collaborator',
    COLLABORATOR_ADDED_SUCCESS: 'Collaborator has been added',
    COLLABORATOR_DELETED_FAILED: 'Failed to delete Collaborator',
    COLLABORATOR_DELETED_SUCCESS: 'Collaborator has been deleted',
    COLLABORATOR_UPDATE_FAILED: 'Failed to update Collaborator',
    COLLABORATOR_UPDATE_SUCCESS: 'Collaborator has been updated',
    SIGN_DELETE_SUCCESS: 'Signature deleted successfully',
    SIGN_DELETE_FAILED: 'Failed to delete signature',
    EMAIL_SEND_SUCCESS: 'Email sent successfully',
    EMAIL_SEND_FAILED: 'Failed to send email',
    SERVER_ERROR: 'Error occured',
    PASSWORD_RESET_SUCCESS: 'Password reset successfully',
    PASSWORD_RESET_FAILED: 'Failed to reset password',
    REQUIRED_ATLEAST_ONE_SIGNATURE: 'Add atleast one signature',
    REQUIRED_ATLEAST_ONE_CUSTOMER: 'Add atleast one customer',
    REQUIRED_FILL_ALL_SIGNATURE: 'Please fill all signature input',
    FAILED_TO_LOAD_SIGNATURE: 'Failed to load signature',
    BUSINESS_DETAILS_NOT_FOUND: 'Business details not found, please update them first from corporate settings',
    FAILED_TO_LOAD_CUSTOMERS: 'Failed to load customers',
    FAILED_TO_LOAD: 'Failed to Load',
    FAILED_TO_SEND_EDOC: 'Failed to send e-document',
    SETTINGS_SAVED: 'Settings has been saved',
    WALLET_CONNECTED: 'Wallet has been Connected',
    REQUEST_COMPLTED: 'Request completed',
    REQUEST_FAILED: 'Request failed',
    REQUIRED_FULLNAME_INITIAL: 'Please add fullname or initial',
    REQUIRED_SIGNATURE_DRAW: 'Please draw signature',
    REQUIRED_SIGNATURE_UPLOAD: 'Please upload signature',
    SIGNATURE_CREATED_SUCCESS: 'Signature created successfully',
    SIGNATURE_CREATE_FAILED: 'Failed to create signature',
    SESSION_UPDATE_SUCCESS: 'Session updated successfully',
    SESSION_UPDATE_FAILED: 'Failed to update session',
    SESSION_LOAD_FAILED: 'Failed to load session detail',
    DOMAIN_CONFIGURED_SUCCESS: 'Domain has been configured successfully',

    METAMASK_NOT_CONNECTED: 'MetaMask not connected',
    INCORRECT_NETWORK: 'You are connected to wrong network.',
    INCORRECT_WALLET_CONNECTED: 'MetaMask is connected to wrong address.',
    DOCUMENT_ISSUED: 'Document successfully issued',
    GET_LINK_FAILED: "Get link failed.",
    DOCUMENT_SAVED_TO_DMS: "Document has been saved to DMS"
}