import { useState, useEffect } from "react";
import {
  Box,
  makeStyles,
  Avatar,
  Typography,
  Grid,
  withStyles,
  TextField,
  Button,
  Snackbar,
  IconButton,
  InputAdornment,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { useAuth } from "../hooks";
import { resetPassword } from "../services/auth.service";
import { getFirstChar } from "../utils/Generic";
import { getSignList, getSignData, deleteSignature } from "../services/api.service";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import Toast from "../utils/Toast";
import { MessageConstants } from "../utils/MessageConstants";
import { useHistory } from "react-router-dom";
import { Cancel } from "@material-ui/icons";
import DeleteModal from "./DeleteModal";
import { useDispatch, useSelector } from "react-redux";
import { ResetFetchSignature } from "../redux/actions/Sign.action";


const useStyles = makeStyles((theme) => ({
  root: {
    boxSizing: "border-box",
    width: `calc(100% - ${300}px)`,
    marginLeft: 280,
    marginTop: 40,
    backgroundColor: "#fff",
    minHeight: "100vh",
  },
  large: {
    width: theme.spacing(12),
    height: theme.spacing(12),
    fontSize: 50,
    fontWeight: 600,
    margin: "0 auto 30px auto",
    textTransform: "uppercase",
    backgroundColor: theme.palette.primary.main,
  },
  button: {
    marginBottom: "10px",
    width: 300,
    height: 50,
    borderRadius: 25,
  },
  buttonText: {
    fontWeight: "bold",
    fontSize: 16,
    letterSpacing: "0.1em",
    color: "#FFFDFD",
  },
  field: {
    width: "40vw",
    marginTop: 20,
    marginBottom: 20,
    borderRadius: "50px 50px 50px 50px",
    color: theme.palette.secondary.main,
  },
  textFieldInput: {
    color: "#222",
  },
  detailWrap: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginLeft: '20px',
  },
  showSign: {
    display: 'inline-block'
  },
  signDiv: {
    float: 'left'
  },
  closeIcon: {
    top: '-75px',
    left: '90px',
    padding: '0',
    position: 'relative',
  },
}));

const CssTextField = withStyles({
  root: {
    display: "block",
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#66CFA4",
      },
      "&:hover fieldset": {
        borderColor: "#66CFA4",
      },
      borderRadius: 50,
    },
  },
})(TextField);

const Profile = () => {
  const toastInitialValues = {
    isOpen: false,
    isSuccess: false,
    isError: false,
    message: ""
  }
  const passwordRegx = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;

  const history = useHistory()
  const { user, removeUser } = useAuth();
  const classes = useStyles();
  const dispatch = useDispatch();
  const fetchSignature = useSelector(state => state.fetchSignature);
  const [toast, setToast] = useState(toastInitialValues);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [show, setShow] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [signature, setSignature] = useState([]);
  const [selected, setSelected] = useState(-1);
  const [state, setState] = useState({
    newPassword: "",
    confirmNewPassword: "",
  });

  const getSignature = () => {
    getSignList()
      .then((res) => {
        setSignature(res.data);
        console.log("Response", res.data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }

  useEffect(() => {
    getSignature();
  }, []);

  useEffect(() => {
    if (fetchSignature) {
      getSignature();
      dispatch(ResetFetchSignature());
    }
  }, [fetchSignature]);

  const setData = (e) => {
    setState((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const enableSubmit = () => {
    return (
      (state.confirmNewPassword === state.newPassword &&
        state.confirmNewPassword?.length > 0 &&
        state.newPassword?.length > 0) && (state?.newPassword?.match(passwordRegx))
    );
  };

  const logout = () => {
    removeUser();
    history.replace("/login");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    resetPassword({
      username: user?.email,
      password: state?.newPassword,
    }).then((res) => {
      if (res && res.status === 200 && res?.data?.message) {
        setToast((prev) => ({
          ...prev,
          isOpen: true,
          isSuccess: true,
          message: MessageConstants.PASSWORD_UPDATE_SUCCESS
        }));
        setState((prev) => ({
          ...prev,
          newPassword: "",
          confirmNewPassword: "",
        }));
        setTimeout(() => {
          logout();
        }, 2000)
      }
    }).catch((e) => {
      const Error = JSON.parse(e.message);
      let msg = MessageConstants.PASSWORD_UPDATE_FAILED;
      if (Error.status === 400)
        msg = Error.message;
      setToast((prev) => ({
        ...prev,
        isOpen: true,
        isError: true,
        message: msg
      }));
    })
  };

  const closeToast = () => {
    return setToast((prev) => ({
      ...prev,
      isOpen: false,
      isSuccess: false,
      isError: false,
      message: ""
    }));
  };

  const deleteHandler = () => {
    deleteSignature(selected).then(res => {
      if (res && res.status === 200) {
        setToast((prev) => ({
          ...prev,
          isOpen: true,
          isSuccess: true,
          message: MessageConstants.SIGN_DELETE_SUCCESS
        }));
        const filterSign = signature.filter(e => e.id !== selected)
        setSignature(filterSign);
        setDeleteDialog(false);
        setSelected(-1);
      }
    }).catch(e => {
      setToast((prev) => ({
        ...prev,
        isOpen: true,
        isError: true,
        message: MessageConstants.SIGN_DELETE_FAILED
      }));
    })
  }

  return (
    <>
      <Box className={classes.root}>
        {toast.isOpen && <Toast message={toast.message} isError={toast.isError} isSuccess={toast.isSuccess} closeToast={closeToast} />}
        <Grid container alignItems="center" justify="center" spacing={5}>
          <Grid item xs={5} style={{ textAlign: "center" }}>
            <Avatar className={classes.large}>
              {getFirstChar(user?.username)}
            </Avatar>
            <div className={classes.detailWrap}>
              <Typography variant="h6" gutterBottom>
                Corporate: {user?.corporate}
              </Typography>
              <Typography variant="h6" gutterBottom>
                Username: {user?.username}
              </Typography>
              <Typography variant="h6" gutterBottom>
                Email: {user?.email}
              </Typography>
              <Typography variant="h6" gutterBottom>
                Role: {user?.role}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={7}>
            <Typography variant="h6" gutterBottom>
              Change Password
            </Typography>
            <form>
              <CssTextField
                error={state.newPassword && !state.newPassword.match(passwordRegx)}
                type={show ? "text" : "password"}
                label="New Password"
                name="newPassword"
                value={state.newPassword || ""}
                className={classes.field}
                onChange={(e) => setData(e)}
                variant="outlined"
                color="primary"
                required
                fullWidth
                InputLabelProps={{ className: classes.textFieldInput }}
                helperText="Password should contain at least 1 special character (!@#$%^&*), 1 number, 1 uppercase alphabet and 1 lowercase alphabet"
                InputProps={{
                  endAdornment: <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShow(!show)}
                      edge="end"
                    >
                      {show ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>, className: classes.textFieldInput
                }}
              />
              <CssTextField
                type={showConfirm ? "text" : "password"}
                label="Confirm New Password"
                name="confirmNewPassword"
                value={state.confirmNewPassword || ""}
                className={classes.field}
                onChange={(e) => setData(e)}
                variant="outlined"
                color="primary"
                required
                fullWidth
                helperText={state.confirmNewPassword?.length > 0 &&
                  state.newPassword !== state.confirmNewPassword ? "New Password and Confirm New Password must be same" : ""}
                error={
                  state.confirmNewPassword?.length > 0 &&
                  state.newPassword !== state.confirmNewPassword
                }
                InputLabelProps={{ className: classes.textFieldInput }}
                InputProps={{
                  endAdornment: <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowConfirm(!showConfirm)}
                      edge="end"
                    >
                      {showConfirm ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>, className: classes.textFieldInput
                }}
              />
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.button}
                size="large"
                onClick={handleSubmit}
                disabled={!enableSubmit()}
              >
                <Typography className={classes.buttonText}>
                  Change Password
                </Typography>
              </Button>
            </form>
          </Grid>
        </Grid>
        <Typography variant="h5" gutterBottom style={{ marginLeft: '20px' }}>
          Current Signature
        </Typography>
        <Grid className={classes.showSign}>
          {signature &&
            signature.map(
              (item, i) =>
                <div className={classes.signDiv} key={i} >
                  <div
                    dangerouslySetInnerHTML={{
                      __html: atob(item.data),
                    }}
                  ></div>
                  <IconButton className={classes.closeIcon} onClick={() => {
                    setDeleteDialog(!deleteDialog); setSelected(item.id)
                  }}>
                    <Cancel />
                  </IconButton>
                </div>
            )}
        </Grid>
      </Box>

      <DeleteModal
        open={deleteDialog}
        handleClose={() => setDeleteDialog(false)}
        handleSubmit={deleteHandler}
        message="Are you sure you want to delete signature"
      />

    </>
  );
};

export default Profile;

{/* {item.status === "ACTIVE" && ( */ }
{/* <img src={atob(item.data)}/> */ }
{/* )} */ }