import { useLocation, Route, Redirect } from 'react-router-dom';
import { useAuth } from '../hooks';
import { useCorporateState } from "./../redux/context/corporate-context";
import { CorporateStatus } from "./../constants";

const ProtectedRoute = (props) => {
    const location = useLocation();

    const { user } = useAuth();
    const corporateState = useCorporateState();
 
    return (
        <>
            {
                user ? <>
                    {
                        corporateState?.status == CorporateStatus.ACTIVE ?
                            <>

                                < Route {...props} />

                            </> : <>

                                <Redirect to={{ pathname: "/corporate-inactive", state: { from: location } }} />

                            </>
                    }
                </> : <>
                    <Redirect to={{ pathname: "/login", state: { from: location } }} />
                </>
            }
        </>
    );
}

export default ProtectedRoute;