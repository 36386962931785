import {
  makeStyles,
  Grid,
  Typography,
  AppBar,
  Paper,
  Toolbar,
  Button,
  Box,
} from "@material-ui/core";
import React, { useState } from "react";
import { Link, NavLink, useHistory, useLocation } from "react-router-dom";
import { useAuth } from "../hooks";
import LoginBg from "../static/images/login-bg.png";
import logo from "../static/images/logo.png";
import LoginForm from "../components/auth/LoginForm";
import SignupForm from "../components/auth/SignupForm";
import LoginBg1 from "../static/images/login-bg-1.jpeg";
import LoginBg2 from "../static/images/login-bg-2.jpeg";
import LoginBg3 from "../static/images/login-bg-3.jpeg";
import LoginBg4 from "../static/images/login-bg-4.jpeg";
import LoginBg5 from "../static/images/login-bg-5.jpeg";
import CreateSignature from "../components/CreateSignature";
import ForgotPasswordModal from "./ForgotPasswordModal";
import { FormControlLabel } from "@material-ui/core";
import { Checkbox } from "@material-ui/core";
const bg = [LoginBg1, LoginBg2, LoginBg3, LoginBg4, LoginBg5];
const imageUrl = bg[Math.floor(Math.random() * bg.length)];

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "80%",
    justifyContent: "center",
    marginTop: -20,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(2),
  },
  toolbar: theme.mixins.toolbar,
  logo: {
    height: "80px",
    width: "auto",
    borderRadius: "0px",
    marginLeft: 60,
  },
  appBar: {
    marginTop: 30,
  },
  link: {
    cursor: "pointer",
    textDecoration: "none",
    color: "#000000d6",
    fontSize: "0.875rem",
    fontFamily: "Poppins",
    fontWeight: "400",
    lineHeight: "1.43",
  },
  simpleText: {
    cursor: "pointer",
    textDecoration: "none",
    color: "#000000d6",
    fontSize: "0.875rem",
    fontFamily: "Poppins",
    fontWeight: "400",
    lineHeight: "1.43",
    position: 'absolute'
  }
}));

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://material-ui.com/">
        TruDoc.io
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const Login = () => {
  const location = useLocation();
  const history = useHistory();
  const classes = useStyles();
  const { user } = useAuth();
  const [openDialog, setOpenDialog] = useState(false);

  

  // const [formType, setFormType] = useState("login");
  // const [open, setOpen] = React.useState(false);

  if (user) {
    const { from } = location.state || { from: { pathname: "/dashboard" } };
    history.replace(from);
  }
  // const handleOpen = () => {
  //   setOpen(true);
  // };
  // const handleClose = () => {
  //   setOpen(false);
  // };
  // const updateForm = () => {
  //   setFormType(formType === "signup" ? "login" : "signup");
  // };
  return (
    <>
      <Grid container component="main" className={classes.root}>
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          className={classes.image}
          style={{ backgroundImage: `url(${imageUrl})` }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <AppBar
            position="static"
            className={classes.appBar}
            elevation={0}
            color="transparent"
          >
            <Toolbar>
              <Box flexGrow={1} align="center">
                <img src={logo} alt="TruDoc" className={classes.logo} />
              </Box>
              <Button component={Link} to="/verify-doc" color="inherit">
                Verify
              </Button>
              <Button component={Link} to="/client-upload" color="inherit">
                client Upload
              </Button>
            </Toolbar>
          </AppBar>

          <div className={classes.paper}>
            <Box align="center" width="100%">
              <Typography component="h1" variant="h5" gutterBottom>
                Sign In
              </Typography>
              <LoginForm />
              <Grid container className={classes.form}>
                <Grid item align="left" xs={12}>
                  <div className={classes.simpleText} onClick={() => setOpenDialog(!openDialog)}>
                    Forgot Password?
                  </div>
                </Grid>
                <Grid item align="right" xs={12}>
                  <NavLink to="/signUp" className={classes.link}>
                    Don't have an account? Sign Up
                  </NavLink>
                  {/* <Typography
                  onClick={updateForm}
                  variant="body2"
                  style={{ cursor: "pointer" }}
                >
                  {formType === "signup"
                    ? "Already have a account? Login"
                    : `Don't have an account? Sign Up`}
                </Typography> */}
                </Grid>
              </Grid>
            </Box>
          </div>
        </Grid>
      </Grid>

      <ForgotPasswordModal
        open={openDialog}
        handleClose={() => setOpenDialog(false)}
      />

    </>
  );
};

export default Login;

// import React fromLogin 'react';
// import { Flex, Box, Image, Heading } from '@chakra-ui/react';
// import LoginBg from '../static/images/login-bg.png';
// import trudocInline from '../static/svg/trudoc-inline.svg';
// import LoginForm from '../components/auth/LoginForm';

// const Login = () => {
//   return (
//     <Box
//       bgImage={`url('${LoginBg}')`}
//       bgPosition="center"
//       bgRepeat="no-repeat"
//       bgSize="cover"
//       minH="100vh"
//       p="10"
//     >
//       <Flex alignItems="flex-start" justifyContent="space-between">
//         <Image src={trudocInline} alt="Trudoc Logo" />
//         <Heading textTransform="uppercase" fontSize="xl" as="h3" letterSpacing="2px" color="white">Verify</Heading>
//       </Flex>

//       <Flex direction="column" mt="32" alignItems="center">
//         <Heading fontSize="4xl" as="h1" letterSpacing="2px" color="white">An easy way to create and verify your documents.</Heading>
//         <LoginForm />
//       </Flex>
//     </Box>
//   )
// }

// export default Login;
