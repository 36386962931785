import React from "react";
import moment from "moment";

import { isNotEmpty } from "./../../utils/Generic";

const ChatMessage = (props) => {

    const { message, username } = props;

    const printChatTime = (time) => {
        const today = new Date();
        let date = new Date(time);
        let momentTime = moment(date);
        if (today.toDateString() === date.toDateString()) {
            return momentTime.format('LT');
        }
        return momentTime.format('lll');
    }

    const showAttachment = (document) => {
        props?.onView(document);
    }

    return (
        <>
            {
                message?.username === username ? <>
                    <div className="msg right-msg">
                        <div className="msg-bubble-main">
                            <div className="msg-bubble">
                                <div className="msg-text">
                                    {
                                        isNotEmpty(message.document) ?
                                            <>
                                                <a href={undefined} onClick={(e) => showAttachment(message.document)} className="chat-attachment-box">
                                                    <h6>{message.document?.name}</h6>
                                                </a>
                                            </> : <>
                                                {message?.message}
                                            </>
                                    }
                                </div>
                            </div>
                            <div className="msg-info">
                                <div className="msg-info-time">{printChatTime(message?.__createdtime__)}</div>
                                <div className="msg-info-name">{message?.username}</div>
                            </div>
                        </div>
                    </div>
                </> : <>
                    <div className="msg">
                        <div className="msg-bubble-main">
                            <div className="msg-bubble">
                                <div className="msg-text">
                                    {
                                        isNotEmpty(message.document) ?
                                            <>
                                                <a href={undefined} onClick={(e) => showAttachment(message.document)} className="chat-attachment-box">
                                                    <h6>{message.document?.name}</h6>
                                                </a>
                                            </> : <>
                                                {message?.message}
                                            </>
                                    }
                                </div>
                            </div>
                            <div className="msg-info">
                                <div className="msg-info-name">{message?.username}</div>
                                <div className="msg-info-time">{printChatTime(message?.__createdtime__)}</div>
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    );
};

export default ChatMessage; 
